import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { AppVersionService } from 'src/app/core/services/app-version.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-browser-refresh-message',
  templateUrl: './browser-refresh-message.component.html',
  styleUrls: ['./browser-refresh-message.component.css']
})
export class BrowserRefreshMessageComponent implements OnInit {

  showBrowserRefreshMessage: any;
  appVersionChangeSubscription: Subscription;
  constructor(private appVersionService: AppVersionService, private userService: UserService) {
    this.appVersionChangeSubscription = this.appVersionService.appVersionChanged$.subscribe(
      hasChanged => {
        this.showBrowserRefreshMessage = hasChanged;
        if(this.showBrowserRefreshMessage) {
          this.updateUserCache();
        }
      });
  }

  ngOnInit() {
    this.showBrowserRefreshMessage = false;
  }


  refreshBrowser() {
    window.location.reload();
  }

  updateUserCache() {
    this.userService.userCacheWithToken(() => {}, () => {});
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.appVersionChangeSubscription.unsubscribe();
  }
}

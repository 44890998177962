import { downgradeComponent } from "@angular/upgrade/static";

import { DashboardPageComponent } from "./dashboard-page.component";

export const DashboardPageAjsModule = angular
    .module('dashboardPage', [])
    .directive('dashboardPage', downgradeComponent({ component: DashboardPageComponent }))
    .config(
        ['$stateProvider', ($stateProvider) => {
            $stateProvider.state('missioncontrol.dashboard', {
                url: '/dashboard',
                component: DashboardPageComponent,
                data: {
                    requireLogin: true
                },
                params: {
                    loginParamsObject: null
                }
            });
        }
        ])
    .name;
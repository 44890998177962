import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CandidateCardIconsComponent } from './candidate-card-icons.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PipesModule } from 'src/app/core/pipes/pipes.module';


@NgModule({
  declarations: [CandidateCardIconsComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    PipesModule
  ],
  exports: [CandidateCardIconsComponent],
  entryComponents: [CandidateCardIconsComponent]
})
export class CandidateCardIconsModule { }

import { downgradeComponent } from "@angular/upgrade/static";
import { ClientOrBusPageComponent } from './client-or-bus-page.component';

export const ClientOrBusPageAjsModule = angular
  .module('clientsOrBusPage', [])
  .directive('clientsOrBusPage', downgradeComponent({ component: ClientOrBusPageComponent }))
  .config(
    ['$stateProvider', ($stateProvider) => {
      $stateProvider.state('missioncontrol.clientsOrBus', {
        url: '/clientsOrBus',
        component: ClientOrBusPageComponent,
        data: {
          requireLogin: true
        }
      });
    }
    ])
  .name;
import { Inject, Injectable } from '@angular/core';
import { StateManagementStorageService } from './state-management-storage.service';

@Injectable({
  providedIn: 'root'
})
export class StateHistoryService {

  constructor(@Inject(StateManagementStorageService) private stateManagementStorageService: StateManagementStorageService) { }

  set(key, state) {
    let history, _ref;
    _ref = this.stateManagementStorageService.stateHistory(null);
    history = (!_.isUndefined(_ref) && !_.isNull(_ref)) ? _ref : {};
    history[key] = state;
    return this.stateManagementStorageService.stateHistory(history);
}

get(key) {
    let _ref;
    _ref = this.stateManagementStorageService.stateHistory(null);
    return (!_.isUndefined(_ref) && !_.isNull(_ref)) ? _ref[key] : null;
}
}

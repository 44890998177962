import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeeManagementPageComponent } from './employee-management-page.component';
import { AddEditEmployeeModule } from './add-edit-employee/add-edit-employee.module';

@NgModule({
  declarations: [EmployeeManagementPageComponent],
  imports: [
    CommonModule,
    AddEditEmployeeModule
  ],
  exports: [EmployeeManagementPageComponent],
  entryComponents: [EmployeeManagementPageComponent]
})
export class EmployeeManagementPageModule { }

<div class="row">
	<div class="col-lg-12">
		<ol class="breadcrumb">
			<li><a (click)="redirectToUrl('missioncontrol.dashboard', true)">Home</a></li>
			<li class="active"><span>Notifications</span></li>
		</ol>
		<h1 class="heading-css">My Notifications</h1>
	</div>
</div>
<div class="row" id="user-profile">
	<div class="col-xs-12" *ngIf="contextLabel != ''">
		<h2 [innerHtml]="contextLabel"></h2>
	</div>
	<div class="col-xs-12">
		<user-notifications height="600px" width="100%" [searchObject]="searchObject"
			[viewAllFlag]="viewAllNotifications" [fullView]="true" context="{{context}}"
			[userNotificationControls]="userNotificationControls" [numberOfNewNotifications]="numberOfNewNotifications"
			[showFilters]="showFiltersFlag" [showShortNotification]="false">
		</user-notifications>
	</div>
</div>
import { Component, OnInit, Input } from '@angular/core';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AssessmentService } from 'src/app/core/services/assessment.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { DateTimeUtilityService } from 'src/app/core/services/date-time-utility.service';
import { JobMatchService } from 'src/app/core/services/job-match.service';
import { FourDotFiveTimeStampFormatPipe } from 'src/app/core/pipes/four-dot-five-time-stamp-format';

@Component({
  selector: 'app-assessment-info',
  templateUrl: './assessment-info.component.html',
  styleUrls: ['./assessment-info.component.css'],
  providers: [FourDotFiveTimeStampFormatPipe]
})
export class AssessmentInfoComponent implements OnInit {

  @Input() jobMatchId;
  @Input() assessmentType;

  companyId: any;
  assessmentTypeForService: any = 'Technical';
  assessmentInfoDetails: any = [];
  browserTimeZone: any;
  jobMatchData: any;
  loadingFlag: boolean = true;
  loadingJobMatchDetailsFlag: boolean = true;

  constructor(
    private authService: AuthService,
    private dateTimeUtilityService: DateTimeUtilityService,
    private jobMatchService: JobMatchService,
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private assessmentService: AssessmentService,
    private fourDotFiveTimeStampFormatPipe: FourDotFiveTimeStampFormatPipe) { }

  ngOnInit() {
    //variables
    this.companyId = this.authService.getUserDetails().company.companyId;
    this.browserTimeZone = null;
    this.jobMatchData = null;
    //flags
    this.getCandidateJobMatchCardInfo(this.jobMatchId, this.companyId, (data) => {
      this.loadingJobMatchDetailsFlag = false;
      this.jobMatchData = data;
      this.browserTimeZone = this.dateTimeUtilityService.guessBrowserTimeZoneName();
      if (this.assessmentType == 'Value Assessment') {
        this.assessmentTypeForService = 'Value';
      }
      this.getSentAssessmentInfo();
    });
  }

  getCandidateJobMatchCardInfo(jobMatchId, companyId, successCallback) {
    this.loadingJobMatchDetailsFlag = true;
    this.jobMatchService.getCandidateJobMatchCard(jobMatchId, companyId, (data) => {
      let candidateJobMatch = {};
      angular.merge(candidateJobMatch, data);
      candidateJobMatch = this._massageCandidateJobMatchData(candidateJobMatch);
      if (successCallback) {
        successCallback(candidateJobMatch);
      }
    }, (error) => {
      this.loadingJobMatchDetailsFlag = false;
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    }, undefined);
  }

  _massageCandidateJobMatchData(candidateJobMatch) {
    candidateJobMatch.name = candidateJobMatch.firstName + ' ' + candidateJobMatch.lastName;
    return candidateJobMatch;
  }

  getSentAssessmentInfo() {
    this.loadingFlag = true;
    this.assessmentService.getSentAssessmentInfo(this.jobMatchId, this.companyId, this.assessmentTypeForService, (data) => {
      this.loadingFlag = false;
      angular.forEach(data, (val, key) => {
        if (_.isNull(val.assessmentAccessTime)) {
          val.assessmentAccessTime = '-'
        } else if (val.assessmentAccessTime == 'anyTime') {
          val.assessmentAccessTime = 'Any Time';
        } else if (val.assessmentAccessTime == 'timeBound') {
          val.assessmentAccessTime = 'Time Bound';
        } else if (val.assessmentAccessTime == 'slotWise') {
          val.assessmentAccessTime = 'Slot Wise';
        }
        if (_.isNull(val.candidateCurrentJobState)) {
          val.candidateCurrentJobState = '-';
        }
        if (_.isNull(val.score)) {
          val.score = '-';
        }
        if (_.isNull(val.timeZone)) {
          val.timeZone = '-';
        }
        if (!_.isNull(val.startDateTime)) {
          if (val.timeZone == '-') {
            val.startDateTime = new Date(val.startDateTime);
            val.startDateTimeDisplayValue = this.fourDotFiveTimeStampFormatPipe.transform(val.startDateTime);
          } else {
            val.startDateTimeInBrowserTimezone = new Date(val.startDateTime);
            val.startDateTimeInBrowserTimezoneDisplayValue = this.fourDotFiveTimeStampFormatPipe.transform(val.startDateTimeInBrowserTimezone);
            let startDateTimeStringInSelectedTimeZone = moment(val.startDateTime).tz(val.timeZone).format("YYYY-MM-DDTHH:mm:ss.SSS");
            val.startDateTime = new Date(startDateTimeStringInSelectedTimeZone);
            val.startDateTimeDisplayValue = this.fourDotFiveTimeStampFormatPipe.transform(val.startDateTime);
          }
        } else {
          val.startDateTimeDisplayValue = '-';
        }
        if (!_.isNull(val.endDateTime)) {
          if (val.timeZone == '-') {
            val.endDateTime = new Date(val.endDateTime);
            val.endDateTimeDisplayValue = this.fourDotFiveTimeStampFormatPipe.transform(val.endDateTime);
          } else {
            val.endDateTimeInBrowserTimezone = new Date(val.endDateTime);
            val.endDateTimeInBrowserTimezoneDisplayValue = this.fourDotFiveTimeStampFormatPipe.transform(val.endDateTimeInBrowserTimezone);
            let endDateTimeStringInSelectedTimeZone = moment(val.endDateTime).tz(val.timeZone).format("YYYY-MM-DDTHH:mm:ss.SSS");
            val.endDateTime = new Date(endDateTimeStringInSelectedTimeZone);
            val.endDateTimeDisplayValue = this.fourDotFiveTimeStampFormatPipe.transform(val.endDateTime);
          }
        } else {
          val.endDateTimeDisplayValue = '-';
        }
        if (!_.isNull(val.sentDateTime)) {
          if (val.timeZone == '-') {
            val.sentDateTime = new Date(val.sentDateTime);
            val.sentDateTimeDisplayValue = this.fourDotFiveTimeStampFormatPipe.transform(val.sentDateTime);
          } else {
            val.sentDateTimeInBrowserTimezone = new Date(val.sentDateTime);
            val.sentDateTimeInBrowserTimezoneDisplayValue = this.fourDotFiveTimeStampFormatPipe.transform(val.sentDateTimeInBrowserTimezone);
            let sentDateTimeStringInSelectedTimeZone = moment(val.sentDateTime).tz(val.timeZone).format("YYYY-MM-DDTHH:mm:ss.SSS");
            val.sentDateTime = new Date(sentDateTimeStringInSelectedTimeZone);
            val.sentDateTimeDisplayValue = this.fourDotFiveTimeStampFormatPipe.transform(val.sentDateTime);
          }
        } else {
          val.sentDateTimeDisplayValue = '-';
        }
        if (!_.isNull(val.completedDateTime)) {
          if (val.timeZone == '-') {
            val.completedDateTime = new Date(val.completedDateTime);
            val.completedDateTimeDisplayValue = this.fourDotFiveTimeStampFormatPipe.transform(val.completedDateTime);
          } else {
            val.completedDateTimeInBrowserTimezone = new Date(val.completedDateTime);
            val.completedDateTimeInBrowserTimezoneDisplayValue = this.fourDotFiveTimeStampFormatPipe.transform(val.completedDateTimeInBrowserTimezone);
            let completedDateTimeStringInSelectedTimeZone = moment(val.completedDateTime).tz(val.timeZone).format("YYYY-MM-DDTHH:mm:ss.SSS");
            val.completedDateTime = new Date(completedDateTimeStringInSelectedTimeZone);
            val.completedDateTimeDisplayValue = this.fourDotFiveTimeStampFormatPipe.transform(val.completedDateTime);
          }
        } else {
          val.completedDateTimeDisplayValue = '-';
        }
        if (_.isNull(val.numberOfRemindersSet)) {
          val.numberOfRemindersSet = '-';
        }
        if (!_.isNull(val.mostRecentReminder)) {
          val.mostRecentReminder = new Date(val.mostRecentReminder);
        } else {
          val.mostRecentReminder = '-';
        }
        if (_.isNull(val.numberOfReminderSent)) {
          val.numberOfReminderSent = '-';
        }
        val.attemptText = null;
        // set the attempt number
        if (key == 0) {
          val.attemptText = 'Current Attempt';
        } else if (key != 0 && (data.length - key) > 3) {
          val.attemptText = (data.length - key) + 'th Attempt';
        } else if (key != 0 && (data.length - key) == 3) {
          val.attemptText = '3rd  Attempt';
        } else if (key != 0 && (data.length - key) == 2) {
          val.attemptText = '2nd Attempt';
        } else if (key != 0 && (data.length - key) == 1) {
          val.attemptText = '1st Attempt';
        }
        val.assessmentNote = this._getAssessmentNote(val);
        val.failedEmailDeliveryHtmlContent = this._getFailedEmailDeliveryHtmlContent(val.failedEmailDeliveryInfos);
        val.emailDeliveryHtmlContent = this._getEmailDeliveryHtmlContent(val.assessmentEmailDeliveryDTOs);
      });
      this.assessmentInfoDetails = data;
      setTimeout(() => {
        $('#sentAssessmentsTable_info').hide();
      }, 100);

    }, (error) => {
      this.loadingFlag = false;
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  _getAssessmentNote(assessmentInfo) {
    let assessmentNote = "";
    if (assessmentInfo.candidateCurrentJobState.toLowerCase().includes('sent')) {
      if (!_.isNull(assessmentInfo.internalNote) && !_.isNull(assessmentInfo.internalNote.send) && assessmentInfo.internalNote.send.length > 0) {
        if (this.jobMatchData.isSharedRequisition) {
          assessmentNote = assessmentNote + "<b>Internal Note: </b> <p>" + this._replaceSpecialCharacters(assessmentInfo.internalNote.send) + "</p>";
        } else {
          assessmentNote = assessmentNote + "<b>Note: </b><p>" + this._replaceSpecialCharacters(assessmentInfo.internalNote.send) + "</p>";
        }
      }
      if (!_.isNull(assessmentInfo.externalNote) && !_.isNull(assessmentInfo.externalNote.send) && assessmentInfo.externalNote.send.length > 0) {
        assessmentNote = assessmentNote + "<b>External Note: </b><br><p>" + this._replaceSpecialCharacters(assessmentInfo.externalNote.send) + "</p>";
      }
    } else if (assessmentInfo.candidateCurrentJobState.toLowerCase().includes('cancel')) {
      if (!_.isNull(assessmentInfo.internalNote) && !_.isNull(assessmentInfo.internalNote.cancel) && assessmentInfo.internalNote.cancel.length > 0) {
        if (this.jobMatchData.isSharedRequisition) {
          assessmentNote = assessmentNote + "<b>Internal Note: </b> <p>" + this._replaceSpecialCharacters(assessmentInfo.internalNote.cancel) + "</p>";
        } else {
          assessmentNote = assessmentNote + "<b>Note: </b><p>" + this._replaceSpecialCharacters(assessmentInfo.internalNote.cancel) + "</p>";
        }
      }
      if (!_.isNull(assessmentInfo.externalNote) && !_.isNull(assessmentInfo.externalNote.cancel) && assessmentInfo.externalNote.cancel.length > 0) {
        assessmentNote = assessmentNote + "<b>External Note: </b><br><p>" + this._replaceSpecialCharacters(assessmentInfo.externalNote.cancel) + "</p>";
      }
    }

    if (assessmentNote.length !== 0) {
      assessmentNote = "<div class='text-left'>" + assessmentNote + "</div>"
    }
    return assessmentNote;
  }

  _replaceSpecialCharacters(text) {
    let replacedText = text;
    for (let i = 0; i < replacedText.length; i++) {
      replacedText = replacedText.replace('<', '&lt;').replace('>', '&gt;');
    }
    replacedText = replacedText.replace(/&lt;br&gt;/g, '<br>');
    return replacedText;
  }

  _getEmailDeliveryHtmlContent(emailDeliveryInfos) {
    let htmlContent = `
    <table class="table table-bordered table-striped">
        <tr>
            <th>Name</th>
            <th>Role</th>
            <th>Status</th>
            <th>Reason</th>
        </tr>
    `;
    angular.forEach(emailDeliveryInfos, (info, key) => {
      info.deliveryIcon = info.isSuccess ? "<i class='fas fa-check green'></i>" : "<i class='fas fa-times red'></i>";
      info.reason = info.isSuccess || info.reason == null ? "Delivered successfully" : info.reason;
      htmlContent = htmlContent +
        `<tr>
            <td>
                ${info.name} 
            </td>
            <td>
                ${info.role} 
            </td>
            <td class="text-center">
                ${info.deliveryIcon} 
            </td>
            <td>
                ${info.reason} 
            </td>
        </tr>`;
    });
    htmlContent = htmlContent + "</table>";
    return htmlContent;
  }

  _getFailedEmailDeliveryHtmlContent(failedEmailDeliveryInfos) {
    let htmlContent = "";
    angular.forEach(failedEmailDeliveryInfos, (info, key) => {
      htmlContent = htmlContent +
        `<div>
                <div class='row'>
                    <div class='col-xs-12'>
                        <div class='row' class='candidate-job-match-row'>
                            <div class='col-xs-5 candidate-job-match-header-text'>Email</div>
                            <div class='col-xs-5 candidate-job-match-header-text'>Delivery Failure Reason</div>
                        </div>
                    </div>
                </div>
                <div class='row'>
                    <div class='col-xs-12'>
                        <div class='row candidate-job-match-row'>
                            <div class='col-xs-5 candidate-job-match-text'> 
                                ${info.email} 
                            </div>
                            <div class='col-xs-5 candidate-job-match-text'>
                                ${info.reason}
                            </div>
                        </div>
                    </div>
                </div>
            </div>`;
    });
    return htmlContent;
  }

}

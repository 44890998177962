import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangePasswordPageComponent } from './change-password-page.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [ChangePasswordPageComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  entryComponents: [ChangePasswordPageComponent]
})
export class ChangePasswordPageModule { }

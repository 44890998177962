import { downgradeComponent } from "@angular/upgrade/static";
import { BulkUploadPageComponent } from "./bulk-upload-page.component";


export const BulkUploadPageModuleAjsModule = angular
  .module('bulkUploadPage', [])
  .directive('bulkUploadPage', downgradeComponent({ component: BulkUploadPageComponent }))
  .config(
    ['$stateProvider', ($stateProvider) => {
      $stateProvider.state('missioncontrol.bulkUpload', {
        url: '/bulkUpload',
        component: BulkUploadPageComponent,
        data: {
          requireLogin: true
        },
        params: {
          uploadType: null
        }
      });
    }
    ])
  .name;
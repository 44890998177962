<div class="">
    <div class="main-box clearfix">
        <div class="main-box-body p-4">
            <div style="max-height: 300px; overflow: auto;">
                <div *ngIf="!isLoading && uploadedEntityDetails.length > 0">
                    <table class="table table-bordered table-responsive">
                        <thead>
                            <tr>
                                <th>File Name</th>
                                <th>Download {{multiEntityHeading}}</th>
                                <th>View Upload {{multiEntityHeading}}</th>
                                <th>Date Uploaded</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let entity of uploadedEntityDetails;">
                                <td>{{entity.originalFileName}}</td>
                                <td><a (click)="downloadEntityFile(entity)">Download File</a></td>
                                <td><a (click)="viewEntitiesResults(entity, viewUploadedSkillsResultsTemplate)">View
                                        Results</a></td>
                                <td>{{entity.createdDate |fourDotFiveTimeStampFormatPipe}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="!isLoading && (!uploadedEntityDetails || uploadedEntityDetails.length == 0)">
                    No Uploaded {{multiEntityHeading}}
                </div>
                <div *ngIf="isLoading" class="col-xs-12 loading-spinner text-center">
                    <span class="mr-2">Loading</span>
                    <span class="fa fa-spinner fa-spin"></span>
                </div>
            </div>

        </div>
    </div>
</div>

<ng-template #viewUploadedSkillsResultsTemplate>
    <div class="ngx-modal-header">
        <h4 class="modal-title pull-left">View Uploaded {{multiEntityHeading}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="viewEntityModal.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="">
            <div class="main-box clearfix">
                <div class="main-box-body p-4">
                    <div class="main-box-body p-4">
                        <app-upload-req-entities-results [entityType]="entityType"
                            [multiEntityHeading]="multiEntityHeading" [singleEntityHeading]="singleEntityHeading"
                            [uploadedEntities]="uploadedEntities">
                        </app-upload-req-entities-results>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
import { Component, OnInit, Inject, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { CandidateJobMatchCard } from 'src/app/core/models/candidateJobMatchCard';
import { ModalConfig } from 'src/app/core/models/modalConfig';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { CandidateService } from 'src/app/core/services/candidate.service';
import { JobMatchService } from 'src/app/core/services/job-match.service';
import { AngularModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-cancel-assessment',
  templateUrl: './cancel-assessment.component.html',
  styleUrls: ['./cancel-assessment.component.css']
})
export class CancelAssessmentComponent implements OnInit {
  @ViewChild('cancelAssessmentForm', null) cancelAssessmentForm: NgForm;

  @Input() jobMatchIdsArrayString;
  @Input() assessmentType;
  @Output() saveCallback = new EventEmitter<any>();

  openCancelAssessmentResultsModal: BsModalRef;

  //flags
  isSharedRequisition: boolean = false;
  savingFlag: boolean = false;
  loadingMultipleJobMatchesDataFlag: boolean = false;
  multipleJobMatchesFlag: boolean = false;
  isLoading: boolean = true;

  companyId: any;
  assessmentData: any;
  externalNotesAction: any = {};
  assessmentName: any = '';
  jobMatchIdsArray: any = [];
  jobMatchDetailsArray: any = [];
  jobMatchAssessmentsThatCanBeCancelled: any = [];
  jobMatchAssessmentsThatCannotBeCancelled: any = [];
  cancelAssessmentLabel: any = '';
  candidateJobMatch: any;
  copyNote: any;
  cancelResultsArray: any = [];

  constructor(
    @Inject("$rootScope") public $rootScope,
    private candidateService: CandidateService,
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private jobMatchService: JobMatchService,
    private angularModalService:AngularModalService,
    public modalService:BsModalService
    ) { }

  ngOnInit() {
    //variables
    this.companyId = this.$rootScope.userDetails.company.companyId;
    this.assessmentData = {
      companyId: this.companyId,
      jobMatchIds: null,
      notes: '',
      externalNote: ''
    };

    this.jobMatchIdsArray = JSON.parse(this.jobMatchIdsArrayString);
    this.multipleJobMatchesFlag = this.jobMatchIdsArray.length > 1 ? true : false;
    switch (this.assessmentType) {
      case 'Technical':
        this.cancelAssessmentLabel = 'Technical Assessment';
        break;
      case 'Value':
        this.cancelAssessmentLabel = 'Value Assessment';
        break;
    }
    this.candidateJobMatch = new CandidateJobMatchCard();

    this.loadingMultipleJobMatchesDataFlag = true;
    // initialize for each of the job matches
    this.jobMatchIdsArray.forEach((val, key) => {
      let tempObject: any = {};
      tempObject.jobMatchId = val;
      tempObject.loadingFlag = true;
      tempObject.cancelErrorMessage = null;
      tempObject.jobMatchData = {};
      this.jobMatchDetailsArray.push(tempObject);
    });

    // get details for each of the job matches to be shown
    this.getAllJobMatchDetails();
  }

  async getAllJobMatchDetails() {
    await this.jobMatchDetailsArray.map((val, key) => {
      this.getCandidateJobMatchCardInfo(val.jobMatchId, (data) => {
        val.jobMatchData = data;
        this.isSharedRequisition = data.isSharedRequisition;
        val.loadingFlag = false;
        if (this.assessmentType == "Technical" && (val.jobMatchData.jobMatchCurrentState == 'Tech Assessment Sent')) {
          let sendActionInfo:any = _.find(val.jobMatchData.availableActions, { actionType: "CANCEL_TECHNICAL_ASSESSMENT" });
          if (sendActionInfo.allowed) {
            this.jobMatchAssessmentsThatCanBeCancelled.push(val);
          } else {
            val.cancelErrorMessage = " (current status: '" + val.jobMatchData.jobMatchCurrentState + "') " + sendActionInfo.message;
            this.jobMatchAssessmentsThatCannotBeCancelled.push(val);
          }
        } else if (this.assessmentType == "Value" && (val.jobMatchData.jobMatchCurrentState == 'Value Assessment Sent')) {
          let sendActionInfo:any = _.find(val.jobMatchData.availableActions, { actionType: "CANCEL_VALUE_ASSESSMENT" });
          if (sendActionInfo.allowed) {
            this.jobMatchAssessmentsThatCanBeCancelled.push(val);
          } else {
            val.cancelErrorMessage = " (current status: '" + val.jobMatchData.jobMatchCurrentState + "') " + sendActionInfo.message;
            this.jobMatchAssessmentsThatCannotBeCancelled.push(val);
          }
        } else {
          val.cancelErrorMessage = " is not in a valid status (current status: '" + val.jobMatchData.jobMatchCurrentState + "').";
          this.jobMatchAssessmentsThatCannotBeCancelled.push(val);
        }
        if ((this.jobMatchAssessmentsThatCanBeCancelled.length + this.jobMatchAssessmentsThatCannotBeCancelled.length) == this.jobMatchDetailsArray.length) {
          this.loadingMultipleJobMatchesDataFlag = false;
          if (this.jobMatchIdsArray.length == 1 && this.jobMatchAssessmentsThatCannotBeCancelled.length == 1) {
            this.multipleJobMatchesFlag = true;
          }
        }
      }, (error) => {
        val.loadingFlag = false;
        this.loadingMultipleJobMatchesDataFlag = false;
      });
    });
  }

  cancelAssessment(template) {
    if (!this.cancelAssessmentForm.invalid) {
      this.savingFlag = true;
      this.assessmentData.jobMatchIds = this.jobMatchAssessmentsThatCanBeCancelled.map((jobMatch) => {
        return jobMatch.jobMatchId;
      });
      this.cancelResultsArray = [];
      this.candidateService.cancelAssessments(this.assessmentType, this.assessmentData, (cancelResultsArray) => {
        this.cancelResultsArray = [...cancelResultsArray];
        this.cancelResultsArray.map((val, key) => {
          let jobMatchInfo:any = _.find(this.jobMatchAssessmentsThatCanBeCancelled, { 'jobMatchId': val.jobMatchId });
              val.name = jobMatchInfo.jobMatchData.name;
        });
        this.savingFlag = false;
        if (this.multipleJobMatchesFlag) {
            const config = new ModalConfig();
            config.class = "modal-lg custom-ngx-modal";
            const modalData: any = {};
            modalData.title = "Cancel Assessments";
            this.openCancelAssessmentResultsModal = this.modalService.show(template, config);
        } else {
          let cancelResult = cancelResultsArray[0];
          if (!cancelResult.isSuccess) {
            let title = 'Error!';
            let message = cancelResult.status;
            this.alertsAndNotificationsService.showAlertWithCallBack(title, message,'danger',() => {
              this.angularModalService.addModalOpenClassToBodyIfAnyModalIsOpen();
                if (this.saveCallback) {
                  this.saveCallback.emit();
                }
            });
          } else {
            if (this.saveCallback) {
              this.saveCallback.emit();
            }
          }
        }
      }, (error) => {
        this.savingFlag = false;
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      });
    }
  }

  getCandidateJobMatchCardInfo(jobMatchId, successCallback, errorCallback) {
    this.jobMatchService.getCandidateJobMatchCard(jobMatchId, this.companyId, (data) => {
      data.name = data.firstName + ' ' + data.lastName;
      this.assessmentName = this.assessmentType == 'Technical' ? data.techAssessmentName : data.valueAssessmentName;
      if (successCallback) {
        successCallback(data);
      }
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      if (errorCallback) {
        errorCallback();
      }
    }, undefined);
  }

  onInternalNoteChanged() {
    this.assessmentData.externalNote = this.assessmentData.notes;
  }

  onExternalNoteChange(event) {
    this.assessmentData.externalNote = event.externalNote;
  }

  onCopyNoteChange(event) {
    this.copyNote = event.copyNote;
  }

  closeModal() {
    if(this.saveCallback) {
      this.saveCallback.emit();
    }
    this._closeModal();
  }

  _closeModal() {
      this.openCancelAssessmentResultsModal.hide();
      this.angularModalService.addModalOpenClassToBodyIfAnyModalIsOpen();  
  }

}

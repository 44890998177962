import { Component, OnInit, Output, EventEmitter, Inject, ViewChild, TemplateRef } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ModalConfig } from 'src/app/core/models/modalConfig';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { EmployeeService } from 'src/app/core/services/employee.service';

declare var Dropzone: any;
declare var $: any;
declare var libphonenumber: any;

@Component({
    selector: 'app-employee-file-upload',
    templateUrl: './employee-file-upload.component.html',
    styleUrls: ['./employee-file-upload.component.css']
})
export class EmployeeFileUploadComponent implements OnInit {

    @Output() completeCallback = new EventEmitter<any>();
    @ViewChild('viewSkillsSampleFormat', null) viewSkillsSampleFormatRef: TemplateRef<any>;
    showFileFormatModal: BsModalRef;

    uploadEmployeeFileResponse: any;
    employeeList: any = [];
    candidatesToAssociateList: any = [];
    fileFormatModal: any;
    uploadFileWizard: any;
    fileUploadDropZone: any;

    isFileUploaded: boolean = false;
    disableUploadButtonFlag: boolean = true;
    fileExistsFlag: boolean = false;
    disablePreviousButtonFlag: boolean = false;
    disableNextButtonFlag: boolean = true;
    fileUploadInProgressFlag: boolean = false;
    mandatoryFieldsError: boolean = false;
    isStaffingCompany: boolean;

    constructor(
        private companyService: CompanyService,
        private alertsAndNotificationsService: AlertAndNotificationsService,
        private employeeService: EmployeeService,
        private authService: AuthService,
        @Inject('$state') private $state: StateService,
        public bsModalService: BsModalService
    ) { }

    ngOnInit() {
        this.isStaffingCompany = this.companyService.isStaffingCompany();
        // wait for changes to take effect and then create wizard
        setTimeout(() => {
            //create the wizard
            this.uploadFileWizard = this.createFileUploadWizard();
            //initialize the drop zone configuration
            this.fileUploadDropZone = this.createFileUploadDropZone();
        }, 200);
    }

    /**
       * create the wizard for the upload resume workflow
       */
    createFileUploadWizard() {
        let uploadFileWizard = $("#uploadFileWizard").wizard();

        $('#uploadFileWizard').on('actionclicked.fu.wizard', (evt, data) => {
            if (data.direction == "next") {
                switch (data.step) {
                    case 1:
                        // remove the remove button from the file.
                        $('.dz-remove').remove();
                        break;
                    case 2:
                        // do nothing
                        // complete click
                        // handled in finish event
                        break;
                    default:
                        break;
                }
            } else if (data.direction == "previous") {
                switch (data.step) {
                    case 1:
                        // not possible
                        break;
                    case 2:
                        this.updateActionButtons();
                        break;
                    default:
                        break;
                }
            }
        });

        $('#uploadFileWizard').on('finished.fu.wizard', (evt, data) => {
            if (this.completeCallback) {
                this.completeCallback.emit();
            }
        });

        return uploadFileWizard;
    }

    /**
     * creates the resume upload drop zone.
     */
    createFileUploadDropZone() {
        let dropZoneSettings = this._getDropZoneSettings();
        let fileUploadDropzone = new Dropzone("form#fileUploadDropzone", dropZoneSettings);
        let vm: any = this;

        // add event handlers for the drop zone
        fileUploadDropzone.on('addedfile', (file, response) => {
            vm.fileExistsFlag = true;
            vm.updateActionButtons();
            $('.dz-progress').css({ top: '70%' });
        });

        fileUploadDropzone.on('removedfile', (file, response) => {
            if (vm.fileUploadDropZone.files.length === 0) {
                vm.fileExistsFlag = false;
                // enable adding of further files if it was disabled
                $(".dz-hidden-input").prop("disabled", false);
            }
            vm.updateActionButtons();
        });

        fileUploadDropzone.on('sending', (file, xhr, formData) => {
            // disable the upload button to avoid multiple clicks
            vm.disableUploadButtonFlag = true;
            vm.isFileUploaded = false;
            // indicate that file upload is in progress
            vm.fileUploadInProgressFlag = true;
        });

        fileUploadDropzone.on('success', (file, response) => {
            // remove file upload in progress flag
            vm.fileUploadInProgressFlag = false;
            vm.isFileUploaded = true;

            vm.alertsAndNotificationsService.showBannerMessage('Employee file uploaded successfully', 'success');
            // merge the response into the job object
            vm._setEmployees(response);
            vm.updateActionButtons();
            // disable adding of further files
            $(".dz-hidden-input").prop("disabled", true);
        });

        fileUploadDropzone.on("error", (file, error) => {
            let elements = document.querySelectorAll(".dz-file-preview");
            angular.forEach(elements, (element, index) => {
                let filename = element.querySelectorAll(".dz-filename span")[0].textContent;
                let errorMessage = element.querySelectorAll(".dz-error-message span")[0];
                if (filename === file.name) {
                    errorMessage.textContent = error.message == undefined ? error : error.message;
                }
            });
        });

        return fileUploadDropzone;
    }

    _getDropZoneSettings() {
        let dropZoneSettings = {};
        dropZoneSettings = {
            url: this.employeeService.getUploadEmployeesUrl() + '/' + this.authService.getUserDetails().company.companyId,
            paramName: () => {
                return "file";
            },
            acceptedFiles: ".csv,.xlsx",
            maxFiles: 1,
            maxFilesize: 10,
            maxThumbnailFilesize: 10,
            timeout: 300000,
            addRemoveLinks: true,
            autoProcessQueue: false,
            uploadMultiple: false
        }
        return dropZoneSettings;
    }

    _setEmployees(employeesResponse) {
        this.employeeList = employeesResponse;
        angular.forEach(this.employeeList, (employee, key) => {
            if (_.isNull(employee.employeeDTO.firstName) || employee.employeeDTO.firstName == "") {
                employee.employeeDTO.firstName = "-";
            }
            if (_.isNull(employee.employeeDTO.lastName) || employee.employeeDTO.lastName == "") {
                employee.employeeDTO.lastName = "-";
            }
            if (_.isNull(employee.employeeDTO.email) || employee.employeeDTO.email == "") {
                employee.employeeDTO.email = "-";
            }
            if (_.isNull(employee.employeeDTO.phoneNumber) || employee.employeeDTO.phoneNumber.trim() == "") {
                employee.employeeDTO.phoneNumber = "-";
            }
            if (_.isNull(employee.employeeDTO.title) || employee.employeeDTO.title == "") {
                employee.employeeDTO.title = '-';
            }
            employee.employeeDTO.name = employee.employeeDTO.firstName + ' ' + employee.employeeDTO.lastName;
            if (employee.employeeDTO.phoneNumber.startsWith("+")) {
                let phoneNumberObject = libphonenumber.parse(employee.employeeDTO.phoneNumber.toString());
                if (phoneNumberObject.phone !== undefined) {
                    employee.employeeDTO.phoneNumber = libphonenumber.format(phoneNumberObject.phone, phoneNumberObject.country, 'International');
                }
            }
            if (_.isNull(employee.employeeDTO.buName) || employee.employeeDTO.buName == "") {
                employee.employeeDTO.buName = '-';
            }
            if (_.isNull(employee.employeeDTO.organizationManagerName) || employee.employeeDTO.organizationManagerName == "") {
                employee.employeeDTO.organizationManagerName = '-';
            }
            if (this.isStaffingCompany) {
                employee.departmentName = "-";
                if (!_.isNull(employee.employeeDTO.department)) {
                    employee.departmentName = employee.employeeDTO.department.name;
                }
            }

            let message = "";
            angular.forEach(employee.messages, (responseMessage, key) => {
                if (key == 0) {
                    message = responseMessage;
                } else {
                    message = message + ". " + responseMessage;
                }
            });
            employee.message = message;
            // enable previous and next (complete) button after processing
            this.disableNextButtonFlag = false;
            this.disablePreviousButtonFlag = false;
        });
    }

    /**
     * update the action buttons
     */
    updateActionButtons() {
        let wizardCurrentStep = $('#uploadFileWizard').wizard('selectedItem').step;
        switch (wizardCurrentStep) {
            case 1:
                // if resumes exist and upload completed, then the functions on this tab are disabled.
                if (this.isFileUploaded) {
                    this.fileUploadDropZone.clickable = false;
                    this.disableUploadButtonFlag = true;
                    this.disableNextButtonFlag = false;
                } else {
                    // in else because no resumes have been uploaded yet
                    if (this.fileExistsFlag) {
                        // if files exist in drop zone
                        this.disableUploadButtonFlag = false;
                    } else {
                        // files do not exist in drop zone
                        this.disableUploadButtonFlag = true;
                        // this.disableSourceTypesFlag = false;
                    }
                    // disable the next button
                    this.disableNextButtonFlag = true;
                }
                break;
            case 2:
                break;
            default:
                break;
        }
    }

    uploadEmployeeFile() {
        this.fileUploadDropZone.processQueue();
    }

    showFileFormat(template: TemplateRef<any>) {
        const config = new ModalConfig();
        const modalData: any = {};
        config.class = "modal-lg custom-ngx-modal";
        config.initialState = modalData;
        this.showFileFormatModal = this.bsModalService.show(template, config);
    }

    wizardCancel() {
        this.$state.go("missioncontrol.employees");
    }

    closeFileFormateModal() {
        if (this.showFileFormatModal) {
            this.showFileFormatModal.hide();
        }
    }

}

import { Injectable } from "@angular/core";
import moment from 'moment';
import momentTImezone from 'moment-timezone';


@Injectable()
export class DateTimeUtilityService {

  constructor() { }

  getDateTimeInAPIFormat(javascriptDateObject) {
    return moment(javascriptDateObject).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  }

  getCurrentTimeStampInAPIFormat() {
    return this.getDateTimeInAPIFormat(new Date());
  }

  getTimeStampInDisplayFormat(javascriptDateObject) {
    return moment(javascriptDateObject).format("MMM DD, YYYY hh:mm A");
  }

  getDateInDisplayFormat(javascriptDateObject) {
    return moment(javascriptDateObject).format("MMM DD, YYYY");
  }

  getTimeInDisplayFormat(javascriptDateObject) {
    return moment(javascriptDateObject).format("hh:mm A");
  }

  guessBrowserTimeZoneName() {
    return momentTImezone.tz.guess();
  }

}
import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';
import moment from 'moment';
import { JobMatchService } from 'src/app/core/services/job-match.service';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { DateTimeUtilityService } from 'src/app/core/services/date-time-utility.service';
import { CandidateService } from 'src/app/core/services/candidate.service';
import { FourDotFiveDateFormatPipe } from 'src/app/core/pipes/four-dot-five-date-format.pipe';

@Component({
  selector: 'app-set-candidate-availability-or-job-left-date',
  templateUrl: './set-candidate-availability-or-job-left-date.component.html',
  styleUrls: ['./set-candidate-availability-or-job-left-date.component.css'],
  providers: [FourDotFiveDateFormatPipe]
})
export class SetCandidateAvailabilityOrJobLeftDateComponent implements OnInit {
  @ViewChild('setAvailabilityDateForm', null) setAvailabilityDateForm: NgForm;

  @Input() candidateId;
  @Input() availabilityType;
  @Input() availabilityDate;
  @Input() joinedDate;
  @Output() saveCallback = new EventEmitter<any>();

  availabilityObject: any = {};
  reasonCodes: any = [];
  reasonCode: any;
  savingFlag: boolean = false;

  constructor(
    private authService: AuthService,
    private jobMatchService: JobMatchService,
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private dateTimeUtilityService: DateTimeUtilityService,
    private candidateService: CandidateService,
    private fourDotFiveDateFormatPipe: FourDotFiveDateFormatPipe
  ) { }

  ngOnInit() {

    this.availabilityObject.candidateId = this.candidateId;
    this.availabilityObject.companyId = this.authService.getUserDetails().company.companyId;
    if (this.availabilityType == 'jobLeft') {
      this._getReasonCodes();
    }
    this._initializeDatePicker();

  }

  _initializeDatePicker() {
    ($(document) as any).ready(() => {
      let currentDate = new Date();
      if (this.availabilityDate && this.availabilityType == 'availability') {
        currentDate = moment(this.availabilityDate).toDate();
      }

      if (this.availabilityType == 'availability') {
        ($('#formAvailabilityDate') as any).datepicker({
          format: "M d, yyyy",
          startDate: currentDate,
          weekStart: 1,
          autoclose: true
        });
      } else if (this.availabilityType == 'jobLeft') {
        ($('#formAvailabilityDate') as any).datepicker({
          format: "M d, yyyy",
          startDate: moment(this.joinedDate).toDate(),
          endDate: new Date(),
          weekStart: 1,
          autoclose: true
        });
      }

      // set current date as default value
      ($("#formAvailabilityDate") as any).datepicker("setDate", currentDate);
      ($("#formAvailabilityDate") as any).datepicker("update", this.fourDotFiveDateFormatPipe.transform(currentDate));

      this.availabilityDate = $(('#formAvailabilityDate') as any).val();
    });
  }

  _getReasonCodes() {
    let steps = ['JOB_LEFT'];
    this.jobMatchService.getReasonCodes(steps, (data) => {
      this.reasonCodes = data['Job Left'];
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  setCandidateAvailability() {
    this.availabilityDate = ($('#formAvailabilityDate') as any).val();
    if (!this.setAvailabilityDateForm.invalid) {
      this.savingFlag = true;
      if (this.availabilityType == 'availability') {
        this.availabilityObject.availabilityDate = this.dateTimeUtilityService.getDateTimeInAPIFormat(new Date(this.availabilityDate));
        this.availabilityObject.jobLeftDate = null;
      } else if (this.availabilityType == 'jobLeft') {
        this.availabilityObject.jobLeftDate = this.dateTimeUtilityService.getDateTimeInAPIFormat(new Date(this.availabilityDate));
        this.availabilityObject.availabilityDate = null;
        this.availabilityObject.reasonCodeDTO = this.reasonCodes.filter(reason => reason.id == this.reasonCode)[0];
      }
      this.availabilityObject.dontHireAgain = false;
      this.availabilityObject.dontHireAgainCompanyIds = [];

      this.candidateService.setCandidateAvailability(this.availabilityObject, (data) => {
        this.savingFlag = false;
        let successMessage = this.availabilityType == 'availability' ? "Availability Date set successfully" : "Job Left Date set successfully";
        this.alertsAndNotificationsService.showBannerMessage(successMessage, 'success');
        if (this.saveCallback) {
          this.saveCallback.emit();
        }
      }, (error) => {
        this.savingFlag = false;
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      });
    }
  }
}

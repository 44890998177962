import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-upload-req-entities-results',
  templateUrl: './upload-req-entities-results.component.html',
  styleUrls: ['./upload-req-entities-results.component.css']
})
export class UploadReqEntitiesResultsComponent implements OnInit {

  @Input() entityType;
  @Input() multiEntityHeading;
  @Input() singleEntityHeading;
  @Input() uploadedEntities;

  constructor() { }

  ngOnInit() {

  }

}

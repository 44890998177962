import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { JobService } from 'src/app/core/services/job.service';

@Component({
  selector: 'app-delete-assessment-credential',
  templateUrl: './delete-assessment-credential.component.html',
  styleUrls: ['./delete-assessment-credential.component.css']
})
export class DeleteAssessmentCredentialComponent implements OnInit {

  @Input() context;
  @Input() companyId;
  @Input() buId;
  @Input() testOwnerEmail;
  @Input() assessmentId;
  @Input() isDeleteCredential;
  @Output() successCallback = new EventEmitter<any>();
  @Output() cancelCallback = new EventEmitter<any>();

  requisitions: any = [];
  message: any = "";
  assessmentDetails: any;
  confirmMessage: any = "";

  isDetailsFetched: boolean = false;
  showDetailsFlag: boolean = false;

  constructor(
    private jobService: JobService,
    private alertsAndNotificationsService: AlertAndNotificationsService
  ) { }

  ngOnInit() {
    this.assessmentDetails = {
      companyId: this.companyId,
      clientOrBuId: null,
      testOwner: this.testOwnerEmail,
      assessmentId: null
    };
    if (this.context == 'BU') {
      this.assessmentDetails.clientOrBuId = this.buId;
    }
    if (this.assessmentId !== undefined && this.assessmentId !== "") {
      this.assessmentDetails.assessmentId = this.assessmentId;
    }
    this.confirmMessage = this.isDeleteCredential ? "Are you sure you want to delete this assessment credential?" : "Are you sure you want to remove this assessment?";
    this._getRequisitions();
  }

  _getRequisitions() {
    this.jobService.getRequisitionsByAssessment(this.assessmentDetails, (data) => {
      this.requisitions = data;
      this._setMessageForUser();
      this.isDetailsFetched = true;
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  _setMessageForUser() {
    let message = this.isDeleteCredential ? `Requisition(s) will be affected if the assessment credentials are deleted.` : `Requisition(s) will be affected if the assessment is removed.`
    this.message = `${this.requisitions.length} ${message} <br> Please either change the assessment associated in the following Requisition(s) to another valid assessment or Complete/Cancel the Requisition(s) before performing this action.`
  }

  showDetails() {
    this.showDetailsFlag = true;
  }

  close() {
    if (this.cancelCallback) {
      this.cancelCallback.emit();
    }
  }

  deleteCredential() {
    if (this.successCallback) {
      this.successCallback.emit();
    }
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import intlTelInput from 'intl-tel-input';

@Component({
  selector: 'angular-four-dot-five-phone-number',
  templateUrl: './four-dot-five-phone-number.component.html',
  styleUrls: ['./four-dot-five-phone-number.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class FourDotFivePhoneNumberComponent implements OnInit {

  @Input() elementId;
  @Input() valueField;
  @Input() isRequired;
  @Input() readOnly;
  @Output() valueFieldChange = new EventEmitter<any>();
  @Output() isPhoneNumberInvalid = new EventEmitter<boolean>();
  @Output() phoneNumberFormat = new EventEmitter<any>();

  setInitialCountry:any = '';

  constructor() { }

  ngOnInit() {

    if(this.readOnly && !this.valueField) {
      this.setInitialCountry = "auto";
    } else {
      this.setInitialCountry = "us";
    }

    setTimeout(() => {
      ($(`#${this.elementId}`) as any).intlTelInput({ initialCountry: this.setInitialCountry, formatOnDisplay: true });
    }, 100);

    setTimeout(() => {
      if (!_.isNull(this.valueField) && !_.isUndefined(this.valueField)) {
        ($(`#${this.elementId}`) as any).intlTelInput("setNumber", this.valueField);
      }

      if (_.isNull(document.getElementById(this.elementId))) {
        let phoneNumberFormat = "";
        this.phoneNumberFormat.emit(phoneNumberFormat);
      } else {
        let phoneNumberFormat = document.getElementById(this.elementId).getAttribute('placeholder');
        this.phoneNumberFormat.emit(phoneNumberFormat);
      }

      // set up the handler for country change
      $(`#${this.elementId}`).on('countrychange', (e, countryData) => {
        setTimeout(() => {

          if (!_.isNull(this.valueField)) {
            this.valueField = ($(`#${this.elementId}`) as any).intlTelInput("getNumber");
            ($(`#${this.elementId}`) as any).intlTelInput("setNumber", this.valueField);
          }

          let phoneNumberFormat = document.getElementById(this.elementId).getAttribute('placeholder');
          this.phoneNumberFormat.emit(phoneNumberFormat);

        }, 100);
      });
    }, 1000);

  }

  phoneNumberChanged() {
    this.isPhoneNumberInvalid.emit(false);
    setTimeout(() => {
      this.valueField = ($(`#${this.elementId}`) as any).intlTelInput("getNumber");
      ($(`#${this.elementId}`) as any).intlTelInput("setNumber", this.valueField);

      this.valueFieldChange.emit(this.valueField);
      // Check Valid Phone Number
      if (!($(`#${this.elementId}`) as any).intlTelInput("isValidNumber")) {
        this.isPhoneNumberInvalid.emit(true);
        // this.addUpdateContactForm.control.get('candidateContactPhoneNumber').setErrors({'incorrect': true})
      } else {
        // this.addUpdateContactForm.control.get('candidateContactPhoneNumber').setErrors(null);
      }

    }, 100);
  }

}

import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CandidateJobMatchCard } from 'src/app/core/models/candidateJobMatchCard';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { JobMatchService } from 'src/app/core/services/job-match.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-assign-recruiter',
  templateUrl: './assign-recruiter.component.html',
  styleUrls: ['./assign-recruiter.component.css']
})
export class AssignRecruiterComponent implements OnInit {
  @ViewChild('assignRecruiterForm', null) assignRecruiterForm: NgForm;

  @Input() recruiterId;
  @Input() jobMatchIdArrayString;
  @Output() saveCallback = new EventEmitter<any>();

  companyId: any;
  jobMatchesDataArray: any = [];
  jobMatchIdToRecruiterIdMap: any = {};
  recruitersList: any = [];
  jobMatchIdArray: any;

  loadingRecruitersFlag: boolean = false;
  savingFlag: boolean = false;

  constructor(
    private authService:AuthService,
    private jobMatchService:JobMatchService,
    private alertsAndNotificationsService:AlertAndNotificationsService,
    private userService: UserService
    ) { }

  ngOnInit() {
    //variables
    this.companyId = this.authService.getUserDetails().company.companyId;
    this.jobMatchesDataArray = [];
    this.jobMatchIdToRecruiterIdMap = {};
    this.recruitersList = [];
    //flags
    this.loadingRecruitersFlag = false;
    this.savingFlag = false;

    this._setJobMatches(() => {
      this.getRecruitersList(() => {
        this.loadingRecruitersFlag = false;
      }, () => {
        this.loadingRecruitersFlag = false;
      });
    });
  }

  _setJobMatches(successCallback) {
    this.jobMatchIdArray = JSON.parse(this.jobMatchIdArrayString);
    this.jobMatchIdArray.map((val, key) => {
      let tempObject:any = {};
      tempObject.loadingFlag = true;
      tempObject.jobMatchId = val;
      tempObject.jobMatchData = {};
      this.jobMatchesDataArray.push(tempObject);
    });
    // angular.forEach(this.jobMatchIdArray, (val, key) => {
    //   let tempObject:any = {};
    //   tempObject.loadingFlag = true;
    //   tempObject.jobMatchId = val;
    //   tempObject.jobMatchData = {};
    //   this.jobMatchesDataArray.push(tempObject);
    // });
    this.jobMatchesDataArray.map((val, key) => {
      this.getCandidateJobMatchCardInfo(val.jobMatchId, (data) => {
        val.loadingFlag = false;
        val.jobMatchData = data;
      }, () => {
        val.loadingFlag = false;
      });
    });
    // angular.forEach(this.jobMatchesDataArray, (val, key) => {
    //   this.getCandidateJobMatchCardInfo(val.jobMatchId, (data) => {
    //     val.loadingFlag = false;
    //     val.jobMatchData = data;
    //   }, () => {
    //     val.loadingFlag = false;
    //   });
    // });
    this.loadingRecruitersFlag = true;
    if (successCallback) {
      successCallback();
    }

  }

  getRecruitersList(successCallback, errorCallback) {
    this.jobMatchService.getJobMatchRecruiters(this.jobMatchIdArray, this.companyId, (recruitersByJobMatchIdArray) => {
      let tempRecruitersList = [];
      recruitersByJobMatchIdArray.map((recruiterInfoByJobMatchId, key) => {
        recruiterInfoByJobMatchId.recruiterDtos.forEach((val, key) => {
          tempRecruitersList.push(val);
        })
      })
      // angular.forEach(recruitersByJobMatchIdArray, (recruiterInfoByJobMatchId, key) => {
      //   angular.forEach(recruiterInfoByJobMatchId.recruiterDtos, (val, key) => {
      //     tempRecruitersList.push(val);
      //   });
      // });
      // remove duplicates if any
      tempRecruitersList = _.uniq(tempRecruitersList, 'id');
      // sort on name
      this.recruitersList = _.sortBy(tempRecruitersList, 'name');
      if (!_.isUndefined(successCallback)) {
        successCallback();
      }
    }, (error) => {
      // if (this.$rootScope.isOnline) {
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      // }
      if (!_.isUndefined(errorCallback)) {
        errorCallback();
      }
    });
  }

  assignRecruiter() {
    if (this.assignRecruiterForm.valid) {
      this.savingFlag = true;
      this.jobMatchesDataArray.map((val, key) => {
        this.jobMatchIdToRecruiterIdMap[val.jobMatchId] = this.recruiterId;
      });

      // angular.forEach(this.jobMatchesDataArray, (val, key) => {
      //   this.jobMatchIdToRecruiterIdMap[val.jobMatchId] = this.recruiterId;
      // });

      this.jobMatchService.assignRecruiter(this.jobMatchIdToRecruiterIdMap, (data) => {
        this.savingFlag = false;
        if (this.saveCallback) {
          this.saveCallback.emit();
        }
        this.userService.userCache(null, () => {}, () => {});
      }, (error) => {
          this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      });
    }
  }

  getCandidateJobMatchCardInfo(jobMatchId, successCallback, errorCallback) {
    this.jobMatchService.getCandidateJobMatchCard(jobMatchId, this.companyId, (data) => {
      data.name = data.firstName + ' ' + data.lastName;
      let candidateJobMatchInfo = angular.merge(new CandidateJobMatchCard(), data);
      if (successCallback) {
        successCallback(candidateJobMatchInfo);
      }
    }, (error) => {
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      if (errorCallback) {
        errorCallback();
      }
    }, undefined);
  }

}

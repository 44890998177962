import { Component, Inject, OnInit, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { MESSAGECONSTANTS } from 'src/app/core/utils/constants';

@Component({
  selector: 'app-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.css']
})
export class ForgotPasswordPageComponent implements OnInit {
  @ViewChild('forgotPasswordForm', null) forgotPasswordForm: NgForm;

  @Input() $transition$:any;

  user:any = {};
  stateParams:any;
  message:any;
  errorMessage:any;
  confirmLink:any;
  MESSAGECONSTANTS:any = MESSAGECONSTANTS;
  
  isSettingPasswordRequestInProgress: boolean = false;

  constructor(@Inject('$state') public $state:any, private StorageService: StorageService, private authService:AuthService, 
    private alertsAndNotificationsService: AlertAndNotificationsService) { }
  
  ngOnInit() {
    this.stateParams = this.$transition$.params();
    // this.form = {};
    // this.user = {};
    // this.isSettingPasswordRequestInProgress = false;

    if(this.stateParams.message !== null) {
        this.message = angular.copy(this.stateParams.message);
        this.StorageService.set('forgotpasswordmessage', this.message, null);
    } else {
        let message = this.StorageService.get('forgotpasswordmessage');
        if(message !== null) {
            this.message = message;
        } else {
            /**
             * When both state params and backup data is null
             */
        }
    }
}

getPassword() {
    this.isSettingPasswordRequestInProgress = true;
    this.authService.forgotPassword(this.user.email, (data) => {
        this.isSettingPasswordRequestInProgress = false;
        if(data && (data.statusCode === '401' || data.statusCode === '400')) {
            this.errorMessage = data.message;
            this.confirmLink = null;
            // this.form.forgotPasswordForm.$setPristine();
            this.forgotPasswordForm.resetForm();
        } else {
            this.errorMessage = null;
            // this.form.forgotPasswordForm.$setPristine();
            this.forgotPasswordForm.resetForm();
            this.confirmLink = 'A link to reset your password has been sent to your email';
            this.isSettingPasswordRequestInProgress = false;
            this.alertsAndNotificationsService.showBannerMessage(this.confirmLink, 'success');
        }
    }, (error) => {
        // this.form.forgotPasswordForm.$setPristine();
        this.forgotPasswordForm.resetForm();
        this.confirmLink = null;
        this.errorMessage = error.message;
        this.alertsAndNotificationsService.showBannerMessage(this.errorMessage, 'danger');
        this.isSettingPasswordRequestInProgress = false;
    });
}

goBackToLogin() {
  this.$state.go('login', {reload: true});
}

}

import { Component, OnInit, Input, ViewChild, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { GenericService } from 'src/app/core/services/generic.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { UserService } from 'src/app/core/services/user.service';
import { MESSAGECONSTANTS } from 'src/app/core/utils/constants';

@Component({
  selector: 'app-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.css']
})
export class ResetPasswordPageComponent implements OnInit {
  @ViewChild('resetPasswordForm', null) resetPasswordForm: NgForm;

  @Input() $transition$: any;

  MESSAGECONSTANTS: any = MESSAGECONSTANTS;
  RESETPASSWORDCONSTANTS: any = {
    CONTROLLER: {
      SET_PASSWORD: 'auth/setpassword',
      CHANGE_PASSWORD: 'auth/changepassword'
    }
  }

  stateParams: any;
  user: any;
  token: any;
  passwordChangeType: any;
  message: any;
  passwordRestrictions: any;
  parsedRestrictions: any;
  validationPattern: any;
  errorMessage:any;

  isNewAndConfirmPasswordEqual: boolean = true;
  showValidation = false;
  isSettingPassword = false;

  constructor(@Inject('$rootScope') public $rootScope:any, @Inject('$location') public $location:any, @Inject('$state') public $state:any, private route: ActivatedRoute, private authService: AuthService,
    private alertsAndNotificationsService: AlertAndNotificationsService, private userService: UserService,
    private genericService: GenericService, private StorageService:StorageService) { }

  ngOnInit() {
    this.stateParams = this.$transition$.params();
    this.user = {};
    this.token = this.$location.search().token
    // this.token = this.route.snapshot.queryParams['token'];
    this.passwordChangeType = this.$location.search().type;
    // this.passwordChangeType = this.route.snapshot.queryParams['type'];
    this.message = _.isNull(this.stateParams.message) ? '' : this.stateParams.message;

    this.getPasswordRestrictions();

  }

  getPasswordRestrictions() {
    this.authService.getPasswordRestrictionsByUserToken(this.token, (data) => {
      this.passwordRestrictions = angular.copy(data);
      this.setPasswordRestrictions();
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, "danger");
    });
  }

  setPasswordRestrictions() {
    this.parsedRestrictions = angular.copy(this.userService.getParsedPasswordRestrictions(this.passwordRestrictions));
    this.validationPattern = new RegExp(this.parsedRestrictions.validationPattern);
    this.showValidation = true;
  }

  compare() {
    this.isNewAndConfirmPasswordEqual = this.user.verifyPassword === this.user.newPassword;
  }

  resetPassword() {
    this.user.confirmPassword = this.user.newPassword;
    this.user.setPasswordEmailType = this.passwordChangeType;
    this.isSettingPassword = true;
    this.genericService.addObject(this.RESETPASSWORDCONSTANTS.CONTROLLER.SET_PASSWORD + '?token=' + this.token, this.user).subscribe((data) => {
      if (data.body.statusCode == '400' || data.body.statusCode == '401') {
        this.resetPasswordForm.resetForm();
        this.errorMessage = data.body.message;
        this.isSettingPassword = false;
      } else {
        var message = this.passwordChangeType == 'CREATE_PASSWORD' ? this.MESSAGECONSTANTS.RESETPASSWORD.SET_PASSWORD_SUCCESSFULLY : this.MESSAGECONSTANTS.RESETPASSWORD.RESET_PASSWORD_SUCCESSFULLY;
        this.alertsAndNotificationsService.showBannerMessage(message, 'success');
        this.killActiveSession();
      }
    }, (error) => {
      if (error.statusCode == '401') {
        this.resetPasswordForm.resetForm();
        this.errorMessage = error.error.message;
        this.isSettingPassword = false;
      } else if (this.$rootScope.isOnline) {
        this.alertsAndNotificationsService.showBannerMessage(error.error.message, 'danger');
      }
    });
  }

  goToLoginPage() {
    this.killActiveSession();
  }

  killActiveSession() {
    var rememberMeStatus = angular.copy(this.StorageService.getCookie('rememberme'));
    var userDetails = angular.copy(this.StorageService.get('userDetails'));
    this.$rootScope.companyDeletedShowPopup = false;
    this.StorageService.resetBrowserStorage();
    if (angular.isDefined(rememberMeStatus) && (rememberMeStatus !== null) && (rememberMeStatus === true)) {
      if (angular.isDefined(userDetails) && (userDetails !== null)) {
        this.StorageService.setCookie('logoutemail', userDetails.emailId, null);
      }
    }
    this.authService.logout((data) => {
      this.StorageService.removeCookie('connect.sid', null);
      this.StorageService.remove('companyDetails');
      this.StorageService.remove('SelectedHostCompany');
      this.StorageService.remove('userDetails');
      this.$state.go('login', null, { reload: true });
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

}

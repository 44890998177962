<div class="row">
    <div class="col-lg-12">
        <div id="content-header" class="clearfix">
            <div class="pull-left">
                <ol class="breadcrumb">
                    <li><a (click)="redirectTo('missioncontrol.dashboard')">Home</a></li>
                    <li class="active"><span>Manage Master Lists</span></li>
                </ol>
                <h1 class="heading-css">Manage Master Lists</h1>
            </div>
        </div>
    </div>
</div>
<div class="col-xs-12">
    <div class="main-box clearfix">
        <div>
            <tabset>
                <tab *ngFor="let tab of entityTabs; let i = index"
                     [heading]="tab.title"
                     [active]="tab.active"
                     (selectTab)="tab.active = true"
                     (deselect)="tab.active = false">
                  <div *ngIf="tab.id === 'skill'">
                    <app-manage-skills-page></app-manage-skills-page>
                  </div>
                  <!-- <div *ngIf="tab.id === 'degree'">
                    <app-manage-requisition-entities-page
                        requisitionEntity="degrees"
                        [entitiesData]="degreesEntities"
                        (successCallback)="refreshDegreesCallback($event)">
                    </app-manage-requisition-entities-page>
                  </div>
                  <div *ngIf="tab.id === 'fieldOfStudy'">
                    <app-manage-requisition-entities-page
                        requisitionEntity="fieldOfStudy"
                        [entitiesData]="fieldOfStudyEntities"
                        (successCallback)="refreshFieldOfStudyCallback($event)">
                    </app-manage-requisition-entities-page>
                  </div>
                  <div *ngIf="tab.id === 'role'">
                    <app-manage-requisition-entities-page
                        requisitionEntity="roles"
                        [entitiesData]="rolesEntities"
                        (successCallback)="refreshRolesCallback($event)">
                    </app-manage-requisition-entities-page>
                  </div>
                  <div *ngIf="tab.id === 'certificates'">
                    <app-manage-requisition-entities-page
                        requisitionEntity="certificates"
                        [entitiesData]="certificatesEntities"
                        (successCallback)="refreshCertificatesCallback($event)">
                    </app-manage-requisition-entities-page>
                  </div> -->
                </tab>
              </tabset>
        </div>
    </div>
</div>
import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ModalConfig } from 'src/app/core/models/modalConfig';
import { SkillActivityFilter } from 'src/app/core/models/skillActivityFilter';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { ManageSkillsService } from 'src/app/core/services/manage-skills.service';

declare var saveAs: any;

@Component({
  selector: 'app-view-upload-req-entities',
  templateUrl: './view-upload-req-entities.component.html',
  styleUrls: ['./view-upload-req-entities.component.css']
})
export class ViewUploadReqEntitiesComponent implements OnInit {

  @Input() entityType;
  @Input() multiEntityHeading;
  @Input() singleEntityHeading;

  viewEntityModal: BsModalRef;

  uploadedEntityDetails: any = [];
  uploadedEntities: any;

  isLoading: Boolean = true;
  isEntitiesLoading: Boolean = true;

  constructor(
    private bsModalService: BsModalService,
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private skillsService: ManageSkillsService
  ) {

  }

  ngOnInit() {
    if (this.entityType === 'degrees') {
      this.getAllDegreesUploadedFiles();
    } else if (this.entityType === 'fieldOfStudy') {
      this.getAllFieldOfStudyUploadedFiles();
    } else if (this.entityType === 'roles') {
      this.getAllRolesUploadedFiles();
    } else if (this.entityType === 'certificates') {
      this.getAllCertificatesUploadedFiles();
    }
  }

  getAllDegreesUploadedFiles() {
    this.isLoading = true;
    let activityFilter = new SkillActivityFilter();
    activityFilter.activityOriginatorType = 'FILE';
    this.skillsService.getAllDegreesActivities(activityFilter, (data) => {
      this.isLoading = false;
      this.uploadedEntityDetails = data && data.entityActivities ? data.entityActivities : [];
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      this.isLoading = false;
    })
  }

  getAllFieldOfStudyUploadedFiles() {
    this.isLoading = true;
    let activityFilter = new SkillActivityFilter();
    activityFilter.activityOriginatorType = 'FILE';
    this.skillsService.getAllFieldOfStudyActivities(activityFilter, (data) => {
      this.isLoading = false;
      this.uploadedEntityDetails = data && data.entityActivities ? data.entityActivities : [];
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      this.isLoading = false;
    })
  }

  getAllRolesUploadedFiles() {
    this.isLoading = true;
    let activityFilter = new SkillActivityFilter();
    activityFilter.activityOriginatorType = 'FILE';
    this.skillsService.getAllCandidateRolesActivities(activityFilter, (data) => {
      this.isLoading = false;
      this.uploadedEntityDetails = data && data.entityActivities ? data.entityActivities : [];
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      this.isLoading = false;
    })
  }

  getAllCertificatesUploadedFiles() {
    this.isLoading = true;
    let activityFilter = new SkillActivityFilter();
    activityFilter.activityOriginatorType = 'FILE';
    this.skillsService.getAllCertificationsActivities(activityFilter, (data) => {
      this.isLoading = false;
      this.uploadedEntityDetails = data && data.entityActivities ? data.entityActivities : [];
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      this.isLoading = false;
    })
  }

  viewEntitiesResults(uploadedEntities, template: TemplateRef<any>) {
    this.uploadedEntities = { ...uploadedEntities };
    const config = new ModalConfig();
    const modalData: any = {};
    config.class = "custom-modal-xl custom-ngx-modal";
    config.initialState = modalData;
    config.ignoreBackdropClick = true;
    this.viewEntityModal = this.bsModalService.show(template, config);
  }

  downloadEntityFile(entity) {
    if (this.entityType === 'degrees') {
      this.skillsService.downloadUploadedDegreesFile(entity.activityId, (data) => {
        this.downloadUploadedEntity(data, entity);
      }, (error) => {
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      });
    } else if (this.entityType === 'fieldOfStudy') {
      this.skillsService.downloadUploadedFieldOfStudyFile(entity.activityId, (data) => {
        this.downloadUploadedEntity(data, entity);
      }, (error) => {
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      });
    } else if (this.entityType === 'roles') {
      this.skillsService.downloadUploadedRolesFile(entity.activityId, (data) => {
        this.downloadUploadedEntity(data, entity);
      }, (error) => {
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      });
    } else if (this.entityType === 'certificates') {
      this.skillsService.downloadUploadedCertificationsFile(entity.activityId, (data) => {
        this.downloadUploadedEntity(data, entity);
      }, (error) => {
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      });
    }
  }

  downloadUploadedEntity(data, entity) {
    const blob = new Blob([data.body], { type: 'application/octet-stream' });
    saveAs(blob, entity.originalFileName);
  }

}

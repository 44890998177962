import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeeFileUploadComponent } from './employee-file-upload.component';

@NgModule({
  declarations: [EmployeeFileUploadComponent],
  imports: [
    CommonModule,
    
  ],
  exports: [EmployeeFileUploadComponent],
  entryComponents: [EmployeeFileUploadComponent]
})
export class EmployeeFileUploadModule { }

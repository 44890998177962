import { Component, OnInit, Input, Inject } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-notifications-page',
  templateUrl: './notifications-page.component.html',
  styleUrls: ['./notifications-page.component.css']
})
export class NotificationsPageComponent implements OnInit {

  @Input() $transition$: any;

  stateParams: any;
  searchObject: any;
  context: String;
  contextLabel: String;
  userNotificationControls: any;
  numberOfNewNotifications: Number = 0;

  viewAllNotifications: boolean = false;
  showFiltersFlag: boolean = true;

  constructor(
    private authService: AuthService,
    @Inject('$state') private $state: any
  ) { }

  ngOnInit() {
    this.stateParams = this.$transition$.params();
    if (_.isNull(this.stateParams.searchObject)) {
      this.searchObject = {};
      this.searchObject.page = 1;
      this.searchObject.size = 20;
      this.searchObject.companyId = this.authService.getUserDetails().company.companyId;
    } else {
      this.searchObject = this.stateParams.searchObject;
      this.searchObject.page = 1;
      this.searchObject.size = 20;
    }
    if (_.isNull(this.stateParams.context)) {
      this.context = 'global';
    } else {
      this.context = this.stateParams.context;
    }
    this.contextLabel = '';
    if (this.context == 'candidateCard') {
      this.contextLabel = 'Candidate Card Notifications';
    } else if (this.context == 'candidateJobMatchCard') {
      this.contextLabel = 'Candidate Job Card Notifications';
    } else if (this.context == 'requisitionCard') {
      this.contextLabel = 'Requisition Card Notifications';
    }
    this.userNotificationControls = {};
    this.userNotificationControls.setNewNotificationsAsViewed = null;

  }

  redirectToUrl(url, isReloadRequired) {
    this.$state.go(url, null, { reload: isReloadRequired });
  }

}

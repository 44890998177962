<span *ngIf="!isRowViewActive">
    <span *ngIf="context === 'candidateCard' || context === 'candidateJobMatchCard'"
        class="candidate-card-icon candidate-card-icons-update-contact" (click)="launchUpdateModal()">
        <i class="fas fa-pencil-alt" tooltip="Update Candidate Details" container="body">
        </i>
    </span>
    <span>
        <span class="candidate-card-icon" [tooltip]="preferredJobTypeTooltipMessage" placement="{{tooltipPlacement}}"
            container="body"
            [ngClass]="{'candidate-card-icon-size': context === 'candidatePossibleJobMatchesPopover',
            'candidate-associate-card-icon': context === 'associateCandidates', 
            'candidate-current-job-match-card-icon': context === 'candidateCurrentJobMatches' || context === 'candidatePossibleJobMatches'}">
            <span containerClass="candidate-custom-popover candidate-card-icons-pop-over" container="body"
                [adaptivePosition]="false" [tooltip]="candidateCardPreferredJobTypes" placement="{{popoverPlacement}}">
                <i class="fas fa-suitcase" [ngClass]="{'green-yellow': candidateAdditionalDetails.preferredJobIcon == 'GREEN', 
                    'red': candidateAdditionalDetails.preferredJobIcon == 'RED'}">
                </i>
                <i *ngIf="!isCandidatePreferredJobTypeExists && context != 'candidatePossibleJobMatchesPopover'"
                    class="fa fa-times red candidate-card-ban-icon" style="left: 10px; right: -1px"></i>
                <i *ngIf="!isCandidatePreferredJobTypeExists && context === 'candidatePossibleJobMatchesPopover'"
                    class="fa fa-times red candidate-card-ban-icon-size" style="left: 8px; right: 0px"></i>
            </span>
        </span>
    </span>
    <span>
        <span class="candidate-card-icon" [tooltip]="salaryTooltipMessage" placement="{{tooltipPlacement}}"
            container="body"
            [ngClass]="{'candidate-card-icon-size': context === 'candidatePossibleJobMatchesPopover', 'candidate-associate-card-icon': context === 'associateCandidates',
            'candidate-current-job-match-card-icon': context === 'candidateCurrentJobMatches' || context === 'candidatePossibleJobMatches'}">
            <span containerClass="candidate-custom-popover candidate-card-icons-pop-over" container="body"
                [tooltip]="candidateCardIconSalary" placement="{{popoverPlacement}}">
                <i class="fas fa-dollar-sign"
                    [ngClass]="{'green-yellow': candidateAdditionalDetails.salaryIcon == 'GREEN', 
                    'red': candidateAdditionalDetails.salaryIcon == 'RED' || candidateAdditionalDetails.salaryIcon == 'REDCROSS'}">
                </i>
                <i *ngIf="(!isCandidateSalaryExists || candidateAdditionalDetails.salaryIcon == 'REDCROSS') && context != 'candidatePossibleJobMatchesPopover'"
                    class="fa fa-times red candidate-card-ban-icon" style="left: 4px"></i>
                <i *ngIf="(!isCandidateSalaryExists || candidateAdditionalDetails.salaryIcon == 'REDCROSS') && context === 'candidatePossibleJobMatchesPopover'"
                    class="fa fa-times red candidate-card-ban-icon-size" style="left: 4px;"></i>
            </span>
        </span>
    </span>
    <span>
        <span class="candidate-card-icon" [tooltip]="sponsorshipTooltipMessage" container="body"
            placement="{{tooltipPlacement}}"
            [ngClass]="{'candidate-card-icon-size': context === 'candidatePossibleJobMatchesPopover', 'candidate-associate-card-icon': context === 'associateCandidates',
                'candidate-current-job-match-card-icon': context === 'candidateCurrentJobMatches' || context === 'candidatePossibleJobMatches'}">
            <span container="body" containerClass="candidate-custom-popover candidate-card-icons-pop-over"
                [tooltip]="candidateCardIconSponsorships" placement="{{popoverPlacement}}">
                <i class="fas fa-award" [ngClass]="{'green-yellow': candidateAdditionalDetails.sponsorshipIcon == 'GREEN', 
                    'red': candidateAdditionalDetails.sponsorshipIcon == 'RED'}">
                </i>
                <i *ngIf="!isCandidateSponsorshipExists && context != 'candidatePossibleJobMatchesPopover'"
                    class="fa fa-times red candidate-card-ban-icon"></i>
                <i *ngIf="!isCandidateSponsorshipExists && context === 'candidatePossibleJobMatchesPopover'"
                    class="fa fa-times red candidate-card-ban-icon-size" style="left: 5px;"></i>
            </span>
        </span>
    </span>
    <span>
        <span class="candidate-card-icon" [tooltip]="workLocationTooltipMessage" container="body"
            placement="{{tooltipPlacement}}"
            [ngClass]="{'candidate-card-icon-size': context === 'candidatePossibleJobMatchesPopover', 'candidate-associate-card-icon': context === 'associateCandidates',
                'candidate-current-job-match-card-icon': context === 'candidateCurrentJobMatches' || context === 'candidatePossibleJobMatches'}">
            <span container="body" containerClass="candidate-custom-popover candidate-card-icons-pop-over"
                placement="{{popoverPlacement}}" popoverTitle="Work Locations"
                [tooltip]="candidateCardIconWorkLocations">
                <i class="far fa-building"
                    [ngClass]="{'red': isCandidateWorkLocationsExists && candidate.workLocationsMatchStatus === 'noMatch', 
                    'green-yellow':  isCandidateWorkLocationsExists && candidate.workLocationsMatchStatus === 'match'}">
                </i>
                <i *ngIf="!isCandidateWorkLocationsExists &&
                        context != 'candidatePossibleJobMatchesPopover'"
                    class="fa fa-times red candidate-card-ban-icon"></i>
                <i *ngIf="!isCandidateWorkLocationsExists &&
                        context === 'candidatePossibleJobMatchesPopover'"
                    class="fa fa-times red candidate-card-ban-icon-size" style="left: 6px;"></i>
            </span>
        </span>
    </span>
    <span>
        <span *ngIf="isCandidateAdditionalUrlsExists" class="candidate-card-icon candidate-card-additional-detail-icon"
            [ngClass]="{'candidate-card-icon-size': context === 'candidatePossibleJobMatchesPopover', 'candidate-associate-card-icon': context === 'associateCandidates',
            'candidate-current-job-match-card-icon': context === 'candidateCurrentJobMatches' || context === 'candidatePossibleJobMatches',
            'candidate-card-additional-details-icon-size': context != 'candidatePossibleJobMatchesPopover' && context != 'associateCandidates'}">
            <i class="far fa-address-card" popoverClass="candidate-card-icons-pop-over" [ngClass]="{'green-yellow': candidateAdditionalDetails.additionalInfoUrlIcon == 'GREEN', 
                'red': candidateAdditionalDetails.additionalInfoUrlIcon == 'RED'}"
                popoverTitle="Additional Information" container="body"
                [popover]="candidateCardIconAdditionalInformation" placement="bottom" triggers="click"
                [outsideClick]="true" [adaptivePosition]="false">
            </i>
        </span>
        <span *ngIf="!isCandidateAdditionalUrlsExists && !isVendorCandidate"
            class="candidate-card-icon candidate-card-additional-detail-ban-icon" [ngClass]="{'candidate-card-icon-size': context === 'candidatePossibleJobMatchesPopover', 
            'candidate-card-additional-details-icon-size': context != 'candidatePossibleJobMatchesPopover' && context != 'associateCandidates',
            'candidate-current-job-match-card-icon': context === 'candidateCurrentJobMatches' || context === 'candidatePossibleJobMatches',
            'candidate-associate-card-icon': context === 'associateCandidates'}"
            tooltip="Candidate's Additional Information not available" container="body">
            <i class="far fa-address-card"></i>
            <i *ngIf="context != 'candidatePossibleJobMatchesPopover'"
                class="fa fa-times red candidate-card-additional-ban-icon" [ngClass]="{'candidate-non-associate-card-additional-ban-icon': context != 'associateCandidates', 
                'candidate-associate-card-additional-ban-icon': context === 'associateCandidates'}"></i>
            <i *ngIf="context === 'candidatePossibleJobMatchesPopover'"
                class="fa fa-times red candidate-card-ban-icon-size" style="left: 10px;"></i>
        </span>
    </span>
    <span *ngIf="isManualOrAutomatedMatchEnabled && !isCandidateAutomatedMatch"
        class="cursor-pointer candidate-card-manual-match-icon mr-2" placement="{{tooltipPlacement}}"
        tooltip="Manual match" container="body"
        [ngClass]="{'candidate-job-match-popover-manual-match-icon': context === 'candidatePossibleJobMatchesPopover'}">
        <i class="fas fa-hand-paper"></i>
    </span>
    <span *ngIf="isManualOrAutomatedMatchEnabled && isCandidateAutomatedMatch"
        class="cursor-pointer candidate-card-automated-match-icon mr-2" placement="{{tooltipPlacement}}"
        tooltip="Automated match" container="body">
        <i class="fa fa-cogs fa-sm"></i>
    </span>
    <span
        *ngIf="context === 'candidatePossibleJobMatchesPopover' && isCorporateCompany && candidate.sharedJob && candidate.vendorsDetails && candidate.vendorsDetails.length > 0">
        <span class="candidate-card-icon requisition-recruiter-icon" style="bottom: 1px;" tooltip="Vendors"
            container="body" placement="{{tooltipPlacement}}">
            <span container="body" containerClass="candidate-custom-popover candidate-card-icons-pop-over"
                [tooltip]="requisitionVendors" placement="{{popoverPlacement}}">
                <i class="fas fa-network-wired"></i>
            </span>
        </span>
    </span>
    <span *ngIf="(context === 'candidatePossibleJobMatchesPopover' && candidate.poc) && isStaffingCompany">
        <span class="candidate-card-icon requisition-recruiter-icon purple" tooltip="Point of contact" container="body"
            placement="{{tooltipPlacement}}">
            <span container="body" containerClass="candidate-custom-popover candidate-card-icons-pop-over"
                [tooltip]="requisitionPointOfContact" placement="{{popoverPlacement}}">
                POC
            </span>
        </span>
    </span>
    <span
        *ngIf="(context === 'candidatePossibleJobMatchesPopover' && candidate.hiringManager && candidate.hiringManager.length > 0) && isCorporateCompany">
        <span class="candidate-card-icon requisition-recruiter-icon" tooltip="Hiring Managers" container="body"
            placement="{{tooltipPlacement}}">
            <span container="body" containerClass="candidate-custom-popover candidate-card-icons-pop-over"
                [tooltip]="requisitionHiringManagers" placement="{{popoverPlacement}}">
                HM
            </span>
        </span>
    </span>
    <span *ngIf="context === 'candidatePossibleJobMatchesPopover' && requisitionRecruiterList.length > 0">
        <span class="candidate-card-icon requisition-recruiter-icon" tooltip="Recruiters" container="body"
            placement="{{tooltipPlacement}}">
            <span [tooltip]="requisitionRecruiters" container="body" placement="{{popoverPlacement}}"
                containerClass="candidate-custom-popover">
                R
            </span>
        </span>
    </span>
</span>

<!-- Row View -->
<div *ngIf="isRowViewActive">
    <div class="d-flex flex-column">
        <!-- Preferred job type icon -->
        <div class="d-flex align-items-center candidate-card-icon-row-view text-overflow-ellipsis max-width-fit-content">
            <div class="test-nowrap">
                <span class="position-relative mr-1" placement="{{tooltipPlacement}}" container="body" [adaptivePosition]="false"
                    tooltip="{{!isCandidatePreferredJobTypeExists ? 'Candidates Preferred Job Type not available' : 'Candidates Preferred Job Type'}}">
                    <i class="fas fa-suitcase"></i>
                    <i *ngIf="!isCandidatePreferredJobTypeExists" class="fa fa-times red candidate-card-ban-icon-row-view"
                        style="left: 8px; right: -1px"></i>
                </span>
                <span class="text-muted"><b> Preferred job type:</b></span>
            </div>
            <div class="ml-2 text-overflow-ellipsis font-bold" [tooltip]="candidatePreferredJobTypeMessage" placement="top" container="body"
                [adaptivePosition]="false">
                <ng-container *ngTemplateOutlet="candidateCardPreferredJobTypes"></ng-container>
            </div>
        </div>
        <!-- Salary Icon -->
        <div class="d-flex align-items-center candidate-card-icon-row-view text-overflow-ellipsis max-width-fit-content">
            <div class="test-nowrap">
                <span class="position-relative mr-1" placement="{{tooltipPlacement}}" container="body" [adaptivePosition]="false"
                    tooltip="{{!isCandidateSalaryExists || candidateAdditionalDetails.salaryIcon == 'REDCROSS' ? 'Candidates Preferred Salary not available' : 'Candidates Preferred Salary'}}">
                    <i class="fas fa-dollar-sign"></i>
                    <i *ngIf="(!isCandidateSalaryExists || candidateAdditionalDetails.salaryIcon == 'REDCROSS')"
                        class="fa fa-times red candidate-card-ban-icon-row-view" style="left: 3px"></i>
                </span>
            </div>
            <div class="text-overflow-ellipsis font-bold">
                <ng-container *ngTemplateOutlet="candidateCardIconSalary"></ng-container>
            </div>
        </div>
        <!-- Sponsorship Icon -->
        <div class="d-flex align-items-center candidate-card-icon-row-view text-overflow-ellipsis max-width-fit-content">
            <div class="test-nowrap">
                <span class="position-relative mr-1" container="body" placement="top" [adaptivePosition]="false"
                    tooltip="{{!isCandidateSponsorshipExists ? 'Candidates Preferred Sponsorship not available' : 'Candidates Preferred Sponsorship'}}">
                    <i class="fas fa-award"></i>
                    <i *ngIf="!isCandidateSponsorshipExists" class="fa fa-times red candidate-card-ban-icon-row-view" style="left: 5px;"></i>
                </span>
                <span class="text-muted ml-1"><b>Sponsorship:</b></span>
            </div>
            <div class="ml-2 text-overflow-ellipsis font-bold" [tooltip]="candidateSponsorshipsMessage" placement="top" container="body"
                [adaptivePosition]="false">
                <ng-container *ngTemplateOutlet="candidateCardIconSponsorships"></ng-container>
            </div>
        </div>
        <div class="d-flex flex-row align-items-center text-overflow-ellipsis">
            <!-- Work Locations Icon -->
            <div class="d-flex align-items-center candidate-card-icon-row-view text-overflow-ellipsis">
                <div class="test-nowrap">
                    <span class="position-relative mr-1" container="body" placement="top" [adaptivePosition]="false" 
                        tooltip="{{!isCandidateWorkLocationsExists ? 'Candidates Preferred Work Locations not available' : 'Candidates Preferred Work Locations'}}">
                        <i class="far fa-building">
                        </i>
                        <i *ngIf="!isCandidateWorkLocationsExists" class="fa fa-times red candidate-card-ban-icon-row-view"
                            style="top: -5px; left: 5px"></i>
                    </span>
                    <span class="text-muted ml-1"><b>Work Locations:</b></span>
                </div>
                <div class="ml-2 text-overflow-ellipsis font-bold" [tooltip]="candidateWorkLocationsMessage" placement="top"
                    container="body" [adaptivePosition]="false">
                    <ng-container *ngTemplateOutlet="candidateCardIconWorkLocations"></ng-container>
                </div>
            </div>
            <!-- Relocation Icon -->
            <div class="d-flex align-items-center candidate-card-icon-row-view text-overflow-ellipsis">
                <div class="test-nowrap">
                    <span class="position-relative mr-1">
                        <i *ngIf="candidateAdditionalDetails.willingToRelocate && !isVendorCandidate"
                            class="fas fa-plane-departure" tooltip="Candidate's Relocation" container="body" placement="top"
                            [adaptivePosition]="false"></i>
                        <i *ngIf="!candidateAdditionalDetails.willingToRelocate" class="fas fa-plane-departure"
                            tooltip="Candidate's Relocation not available" container="body" placement="top"
                            [adaptivePosition]="false"></i>
                        <i *ngIf="!candidateAdditionalDetails.willingToRelocate"
                            class="fa fa-times red candidate-card-ban-icon-row-view" style="left: 10px;"></i>
                    </span>
                    <span class="text-muted ml-1"><b>Relocation:</b></span>
                </div>
                <div class="ml-2 text-overflow-ellipsis font-bold">
                    <span *ngIf="candidateAdditionalDetails.willingToRelocate && !isVendorCandidate" class="text-left"
                        [tooltip]="candidateRelocationMessage" placement="top" container="body" [adaptivePosition]="false">
                        <span>{{candidateRelocationMessage}}</span>
                    </span>
                    <span *ngIf="!candidateAdditionalDetails.willingToRelocate" class="text-left" tooltip="N/A" placement="top"
                        container="body" [adaptivePosition]="false">N/A</span>
                </div>
            </div>
        </div>
        <!-- URL Information -->
        <div class="d-flex align-items-center candidate-card-icon-row-view text-overflow-ellipsis max-width-fit-content">
            <div class="test-nowrap">
                <span class="position-relative mr-1">
                    <i *ngIf="isCandidateAdditionalUrlsExists" class="far fa-address-card" tooltip="Additional Information"
                        container="body" placement="top" [adaptivePosition]="false"></i>
                    <i *ngIf="!isCandidateAdditionalUrlsExists && !isVendorCandidate" class="far fa-address-card"
                        tooltip="Candidate's Additional Information not available" container="body" placement="top"
                        [adaptivePosition]="false"></i>
                    <i *ngIf="!isCandidateAdditionalUrlsExists && !isVendorCandidate"
                        class="fa fa-times red candidate-card-ban-icon-row-view" style="left: 10px; top: -4px;"></i>
                </span>
                <span class="text-muted ml-1"><b>URL Information:</b></span>
            </div>
            <div class="text-overflow-ellipsis ml-1 font-bold">
                <span *ngIf="isCandidateAdditionalUrlsExists">
                    <i class="fa fa-file-text text-muted ml-1 mr-2" style="font-size: 10px;"></i>
                    <a popoverClass="candidate-card-icons-pop-over" popoverTitle="Additional Information" container="body" [popover]="candidateCardIconAdditionalInformation"
                        placement="bottom" triggers="click" [outsideClick]="true" [adaptivePosition]="false">view</a>
                </span>
                <span *ngIf="!isCandidateAdditionalUrlsExists && !isVendorCandidate">N/A</span>
            </div>
        </div>
    </div>
</div>

<ng-template #candidateCardIconWorkLocations>
    <div class="candidate-custom-popover-container" *ngIf="!isRowViewActive">
        <div class="candidate-custom-popover-title">
            <p class="mb-0">Work Locations</p>
        </div>
        <div class="candidate-custom-popover-content">
            <div *ngIf="candidate.workLocations && candidate.workLocations.length > 0 && !isVendorCandidate" class="d-flex">
                <p *ngFor="let workLocation of candidate.workLocations; let i = index" class="text-left">
                    <span>{{workLocation.name}}</span>
                    <span *ngIf="i+1 < candidate.workLocations.length" class="pr-1">,</span>
                </p>
            </div>
            <p *ngIf="candidateAdditionalDetails.willingToRelocate && !isVendorCandidate" class="text-left">
                <span class="font-bold mb-1 d-inline-block">Relocation</span><br>
                <span>{{candidateRelocationMessage}}</span>
            </p>
            <p *ngIf="!candidateAdditionalDetails.willingToRelocate && !isVendorCandidate">
                <span class="candidate-card-icon"
                    [ngClass]="{'candidate-card-icon-size': context === 'candidatePossibleJobMatchesPopover', 'candidate-associate-card-icon': context === 'associateCandidates',
                        'candidate-current-job-match-card-icon': context === 'candidateCurrentJobMatches' || context === 'candidatePossibleJobMatches'}"
                    style="bottom: 0px">
                    <i class="fas fa-plane-departure"></i>
                    <i *ngIf="context != 'candidatePossibleJobMatchesPopover'"
                        class="fa fa-times red candidate-card-ban-icon" style="left: 14px;"></i>
                    <i *ngIf="context === 'candidatePossibleJobMatchesPopover'"
                        class="fa fa-times red candidate-card-ban-icon-size" style="left: 14px;"></i>
                </span>
                <span>
                    Relocation not available
                </span>
            </p>
            <div class="text-left">
                <hr class="my-2"
                    *ngIf="((candidate.workLocations && candidate.workLocations.length > 0) || candidateAdditionalDetails.willingToRelocate) && !isVendorCandidate">
                <span class="font-bold">Job:</span><br>
                <div *ngIf="candidateAdditionalDetails.requisitionWorkLocationModes && candidateAdditionalDetails.requisitionWorkLocationModes.length > 0"
                    class="d-flex">
                    <p *ngFor="let reqWorkLocations of candidateAdditionalDetails.requisitionWorkLocationModes; let i = index"
                        class="text-left mb-2">
                        <span>{{reqWorkLocations}}</span>
                        <span *ngIf="i+1 < candidateAdditionalDetails.requisitionWorkLocationModes.length"
                            class="pr-1">,</span>
                    </p>
                </div>
                <div
                    *ngIf="!candidateAdditionalDetails.requisitionWorkLocationModes || candidateAdditionalDetails.requisitionWorkLocationModes.length === 0">
                    <p class="text-left mb-2">
                        Not Available
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="isRowViewActive" class="d-flex flex-row align-items-center">
        <div class="text-overflow-ellipsis">
            {{candidateWorkLocationsMessage}}
        </div>
    </div>
</ng-template>

<ng-template #candidateCardIconSalary>
    <div class="candidate-custom-popover-container" *ngIf="!isRowViewActive">
        <div class="candidate-custom-popover-title">
            <p class="mb-0">Salary/Rate</p>
        </div>
        <div class="candidate-custom-popover-content text-left">
            <p *ngIf="candidateAdditionalDetails.preferredJobTypeDetails && candidateAdditionalDetails.preferredJobTypeDetails.salary && !isVendorCandidate">
                <span class="font-bold">Salary:</span><br>
                <span class="ml-2">{{candidateAdditionalDetails.salaryMessage}}</span>
            </p>
            <p *ngIf="candidateAdditionalDetails.preferredJobTypeDetails && candidateAdditionalDetails.preferredJobTypeDetails.rate && !isVendorCandidate">
                <span class="font-bold">Rate:</span><br>
                <span class="ml-2">{{candidateAdditionalDetails.rateMessage}}</span>
            </p>
            <div class="text-left">
                <hr class="my-2" *ngIf="candidateAdditionalDetails.preferredJobTypeDetails && 
                    (candidateAdditionalDetails.preferredJobTypeDetails.rate || candidateAdditionalDetails.preferredJobTypeDetails.salary) && !isVendorCandidate">
                <p class="text-left mb-2">
                    <span class="font-bold">Job:</span><br>
                    <span class="ml-2" *ngIf="candidateAdditionalDetails.requisitionSalary">{{candidateAdditionalDetails.requisitionSalaryMessage}}</span>
                    <span class="ml-2" *ngIf="!candidateAdditionalDetails.requisitionSalary">Not Available</span>
                </p>
            </div>
        </div>
    </div>

    <div *ngIf="isRowViewActive" class="d-flex flex-row align-items-center">
        <div class="d-flex flex-row text-overflow-ellipsis">
            <div class="text-overflow-ellipsis">
                <span *ngIf="candidateAdditionalDetails.preferredJobTypeDetails && candidateAdditionalDetails.preferredJobTypeDetails.salary && !isVendorCandidate"
                    [tooltip]="candidateAdditionalDetails.salaryMessage" placement="top" container="body" [adaptivePosition]="false">
                    <span class="font-bold text-muted ml-1">Salary:</span>
                    <span class="ml-2">{{candidateAdditionalDetails.salaryMessage}}</span>
                    <span *ngIf="candidateAdditionalDetails.preferredJobTypeDetails && candidateAdditionalDetails.preferredJobTypeDetails.rate && !isVendorCandidate">, </span>
                </span>
                <span *ngIf="candidateAdditionalDetails.preferredJobTypeDetails && candidateAdditionalDetails.preferredJobTypeDetails.rate && !isVendorCandidate"
                    [tooltip]="candidateAdditionalDetails.rateMessage" placement="top" container="body" [adaptivePosition]="false">
                    <span class="font-bold text-muted ml-1">Rate:</span>
                    <span class="ml-2">{{candidateAdditionalDetails.rateMessage}}</span>
                </span>
                <span *ngIf="!isCandidateSalaryExists || candidateAdditionalDetails.salaryIcon == 'REDCROSS'" class="ml-1"><b class="text-muted">Salary:</b> N/A</span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #candidateCardIconSponsorships>
    <div class="candidate-custom-popover-container" *ngIf="!isRowViewActive">
        <div class="candidate-custom-popover-title">
            <p class="mb-0">Sponsorships</p>
        </div>
        <div class="candidate-custom-popover-content">
            <div *ngIf="candidateAdditionalDetails.sponsorships && !isVendorCandidate" class="text-left">
                <p *ngFor="let sponsorship of candidateAdditionalDetails.sponsorships; let i = index">
                    {{sponsorship}}
                </p>
            </div>
            <div class="text-left">
                <hr class="my-2" *ngIf="candidateAdditionalDetails.sponsorships && candidateAdditionalDetails.sponsorships.length > 0 && !isVendorCandidate">
                <span class="font-bold">Job:</span><br>
                <div *ngIf="candidateAdditionalDetails.requisitionSponsorships && candidateAdditionalDetails.requisitionSponsorships.length > 0" class="d-flex flex-wrap candidate-card-icons-pop-over">
                    <p *ngFor="let reqSponsorship of candidateAdditionalDetails.requisitionSponsorships; let i = index"
                        class="text-left mb-2">
                        <span>{{reqSponsorship}}</span>
                        <span *ngIf="i+1 < candidateAdditionalDetails.requisitionSponsorships.length" class="pr-1">,</span>
                    </p>
                </div>
                <div *ngIf="!candidateAdditionalDetails.requisitionSponsorships || candidateAdditionalDetails.requisitionSponsorships.length === 0">
                    <p class="text-left mb-2">
                        Not Available
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="isRowViewActive" class="d-flex flex-row align-items-center">
        <div class="text-overflow-ellipsis">
            {{candidateSponsorshipsMessage}}
        </div>
    </div>
</ng-template>

<ng-template #candidateCardIconAdditionalInformation>
    <div *ngIf="candidateAdditionalDetails.urls && candidateAdditionalDetails.urls.length > 0" class="mb-2">
        <span class="font-bold">URL Information:</span><br>
        <p *ngFor="let url of candidateAdditionalDetails.urls; let i = index" class="mb-1 pl-2">
            <span>{{url.urlType}}: </span>
            <a href="{{_setUrlLink(url.urlLink)}}" target="_blank">
                {{(url.urlLink.length>30)? (url.urlLink | slice:0:30)+'...':(url.urlLink)}}
            </a>
        </p>
    </div>
    <div *ngIf="(!candidateAdditionalDetails.urls || candidateAdditionalDetails.urls.length === 0) && isVendorCandidate"
        class="mb-2">
        <p class="mb-1 pl-2">
            No Additional Url
        </p>
    </div>
    <div *ngIf="candidateAdditionalDetails.additionalInformation && !isVendorCandidate">
        <span class="font-bold">Additional Information:</span><br>
        <span [innerHTML]="candidateAdditionalDetails.additionalInformation" class="pl-2"></span>
    </div>
</ng-template>

<ng-template #candidateCardPreferredJobTypes>
    <div class="candidate-custom-popover-container" *ngIf="!isRowViewActive">
        <div class="candidate-custom-popover-title">
            <p class="mb-0">Preferred Job Type</p>
        </div>
        <div class="candidate-custom-popover-content">
            <div *ngIf="candidatePreferredJobType.length > 0 && !isVendorCandidate" class="d-flex flex-wrap candidate-card-icons-pop-over">
                <p *ngFor="let job of candidatePreferredJobType; let i = index" class="text-left mb-2">
                    <span>{{job.jobType}}</span>
                    <span *ngIf="job.jobSubType" class="ml-1">({{job.jobSubType}})</span>
                    <span *ngIf="i+1 < candidatePreferredJobType.length" class="pr-1">,</span>
                </p>
            </div>
            <div class="text-left">
                <hr class="my-2" *ngIf="candidatePreferredJobType.length > 0 && !isVendorCandidate">
                <span class="font-bold">Job:</span><br>
                <p class="text-left mb-2">
                    <span *ngIf="candidateAdditionalDetails.requisitionJobType">{{candidateAdditionalDetails.requisitionJobTypeMessage}}</span>
                    <span *ngIf="!candidateAdditionalDetails.requisitionJobType">Not Available</span>
                </p>
            </div>
        </div>
    </div>
    
    <div *ngIf="isRowViewActive" class="d-flex flex-row align-items-center">
        <div class="text-overflow-ellipsis">
            {{candidatePreferredJobTypeMessage}}
        </div>
    </div>
</ng-template>

<ng-template #requisitionPointOfContact>
    <div class="candidate-custom-popover-container">
        <div class="candidate-custom-popover-title">
            <p class="mb-0">Point of contact</p>
        </div>
        <div class="candidate-custom-popover-content">
            <p class="text-left mb-2 purple">
                <span *ngIf="candidate.poc">{{candidate.poc}}</span>
            </p>
        </div>
    </div>
</ng-template>

<ng-template #requisitionRecruiters>
    <div class="candidate-custom-popover-container">
        <div class="candidate-custom-popover-title">
            <p class="mb-0">Recruiters</p>
        </div>
        <div class="candidate-custom-popover-content">
            <div *ngIf="requisitionRecruiterList.length > 0"
                class="d-flex flex-wrap candidate-card-icons-pop-over">
                <p *ngFor="let recruiter of requisitionRecruiterList; let i = index" class="text-left mb-2">
                    <span [ngClass]="{'candidate-card-popover-vendor-recruiter': candidate.sharedJob && ((isCorporateCompany && recruiter.vendorRecruiter) || (!isCorporateCompany && !recruiter.vendorRecruiter)),
                        'emerald': candidate.sharedJob && ((isCorporateCompany && !recruiter.vendorRecruiter) || (!isCorporateCompany && recruiter.vendorRecruiter))}">{{recruiter.name}}
                        <sup *ngIf="recruiter.isPrimary" uib-tooltip="Assigned Recruiter of Job Match" class="fa fa-star emerald" style="font-size: 0.7em"></sup>
                        <sup class="ml-1" *ngIf="recruiter.isPoc && isCorporateCompany && candidate.sharedJob" uib-tooltip="Point of contact" style="font-size: 0.7em">POC</sup>
                    </span>
                    <span *ngIf="i+1 < candidate.recruitersInfo.length" class="pr-1">,</span>
                </p>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #requisitionHiringManagers>
    <div class="candidate-custom-popover-container">
        <div class="candidate-custom-popover-title">
            <p class="mb-0">Hiring Managers</p>
        </div>
        <div class="candidate-custom-popover-content">
            <div *ngIf="candidate.hiringManager && candidate.hiringManager.length > 0"
                class="d-flex flex-wrap candidate-card-icons-pop-over">
                <p *ngFor="let recruiter of candidate.hiringManager; let i = index" class="text-left mb-2">
                    <span [ngClass]="{'candidate-card-popover-vendor-recruiter': candidate.sharedJob && ((isCorporateCompany && recruiter.vendorRecruiter) || (!isCorporateCompany && recruiter.vendorRecruiter)),
                    'emerald': candidate.sharedJob && ((isCorporateCompany && !recruiter.vendorRecruiter))}">{{recruiter.name}}</span>
                    <span *ngIf="recruiter.isPrimary" class="requisition-candidate-card-primary-hiring-manager-icon emerald" uib-tooltip="Primary Hiring Manager" 
                        tooltip-append-to-body="true">P</span>
                    <span *ngIf="recruiter.isPoc && isCorporateCompany && candidate.sharedJob" class="requisition-candidate-card-poc-icon ml-1" uib-tooltip="Point of contact" 
                        tooltip-append-to-body="true">POC</span>
                    <span *ngIf="i+1 < candidate.hiringManager.length" class="pr-1">,</span>
                </p>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #requisitionVendors>
    <div class="candidate-custom-popover-container">
        <div class="candidate-custom-popover-title">
            <p class="mb-0">Vendors</p>
        </div>
        <div class="candidate-custom-popover-content">
            <div *ngIf="candidate.vendorsDetails && candidate.vendorsDetails.length > 0"
                class="d-flex flex-wrap candidate-card-icons-pop-over">
                <p *ngFor="let vendor of candidate.vendorsDetails; let i = index" class="text-left mb-2">
                    <span>{{vendor.name}}</span>
                    <span *ngIf="i+1 < candidate.vendorsDetails.length" class="pr-1">,</span>
                </p>
            </div>
        </div>
    </div>
</ng-template>
<div class="container">
	<div class="row">
		<div class="pull-right" style="margin-right:10px">
			<span title="Click to show / hide the filter" class="pull-right filter-icon">
				<i class="fa fa-filter fa-2x cursor-point" aria-hidden="true" id="open-filter"></i>
			</span>
		</div>
		<!-- Headings end -->

		<!--filter starts-->
		<div class="col-xs-12">
			<div class="row">
				<div class="col-xs-12" id="search-panel" style="display: none;">
					<div class="main-box" style="padding-top: 1%;">
						<div class="main-box-body clearfix">
							<form role="form" name="" novalidate autocomplete="off">
								<div class="row">
									<div class="col-lg-12 col-sm-12 col-md-12">
										<div class="row">
											<div class="col-lg-3 col-sm-3 col-md-3">
												<div class="form-group">
													<label for="startDate" class="control-label">
														Start Date:
													</label>
													<div class="input-group">
														<div class="input-group-addon">
															<div class="input-group-text">
																<i class="fa fa-calendar"></i>
															</div>
														</div>
														<input type="text" name="startDate" id="startDate"
															class="form-control" [(ngModel)]="startDate"
															placeholder="Choose Start Date to filter"
															autocomplete="off">
													</div>
												</div>
											</div>
											<div class="col-lg-3 col-sm-3 col-md-3">
												<div class="form-group">
													<label for="startTime" class="control-label">
														Start Time:
													</label>
													<div class="input-group">
														<div class="input-group-addon">
															<div class="input-group-text">
																<i class="far fa-clock"></i>
															</div>
														</div>
														<input type="text" name="startTime" id="startTime"
															class="form-control" [(ngModel)]="startTime">
													</div>
												</div>
											</div>
											<div class="col-lg-3 col-sm-3 col-md-3">
												<div class="form-group">
													<label for="endDate" class="control-label">
														End Date:
													</label>
													<div class="input-group">
														<div class="input-group-addon">
															<div class="input-group-text">
																<i class="fa fa-calendar"></i>
															</div>
														</div>
														<input type="text" name="endDate" id="endDate"
															class="form-control" [(ngModel)]="endDate"
															placeholder="Choose End Date to filter" autocomplete="off">
													</div>
												</div>
											</div>
											<div class="col-lg-3 col-sm-3 col-md-3">
												<div class="form-group">
													<label for="endTime" class="control-label">
														End Time:
													</label>
													<div class="input-group">
														<div class="input-group-addon">
															<div class="input-group-text">
																<i class="far fa-clock"></i>
															</div>
														</div>
														<input type="text" name="endTime" id="endTime"
															class="form-control" [(ngModel)]="endTime">
													</div>
												</div>
											</div>
											<div class="col-lg-3 col-sm-3 col-md-3">
												<div class="form-group">
													<label for="activityType">Activity Type</label>
													<select class="form-control access inputBoxBorder"
														[(ngModel)]="activityFilter.operation"
														(change)="getActivities()" name="activityType"
														id="activityType">
														<option value="">Any</option>
														<option
															*ngFor="let activityType of activityTypes; let i = index"
															value="{{activityType.value}}">{{activityType.name}}
														</option>
													</select>
												</div>
											</div>
											<div class="col-lg-3 col-sm-3 col-md-3">
												<div class="form-group">
													<label for="user">Activity Performed By:</label>
													<select class="form-control access inputBoxBorder"
														[(ngModel)]="activityFilter.performedBy"
														(change)="getActivities()" name="user" id="user">
														<option value="">Any</option>
														<option *ngFor="let user of users; let i = index"
															value="{{user.id}}">{{user.name}}</option>
													</select>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="row pull-right" style="margin-right:10px">
									<div class="col-lg-12 col-md-12 col-sm-12">
										<div class="form-group" id="resetFiltersButton">
											<button type="button" class="btn btn-danger"
												(click)="resetFilters()">Reset</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--filter ends-->
		<div class="col-xs-12 text-center" *ngIf="isFiltersEnabled">
			<span class="badge badge-primary px-3 py-2 mb-3">Filtered Results</span>
		</div>
	</div>
	<div style="height: 400px; overflow-y: scroll">
		<div *ngIf="!loadingFlag && activities.length > 0">
			<div class="col-xs-12 skill-entity-activities-container" *ngFor="let activity of activities; let i = index">
				<div class="col-xs-10 d-flex">
					<div class="text-right skill-entity-activities-icon-div mr-2">
						<i class="fa fa-pencil-alt"
							*ngIf="((activity.operation === 'updated' || activity.operation === 'update') && (activity.activityOriginatorType == 'SCREEN'))"></i>
						<i class="fa fa-plus"
							*ngIf="(activity.operation === 'added' && (activity.activityOriginatorType == 'SCREEN'))"></i>
						<i class="fa fa-minus"
							*ngIf="(activity.operation === 'deleted' && (activity.activityOriginatorType == 'SCREEN'))"></i>
						<i class="fa fa-file" *ngIf="activity.activityOriginatorType == 'FILE'"></i>
						<i class="fa fa-exclamation-circle error-skill-icon"
							*ngIf="(activity.operation === 'error')"></i>
					</div>
					<div style="width:95%; -webkit-box-orient: vertical; line-height: 2;"
						[ngClass]="{'two-line-text-overflow-ellipsis': activity.showShortMessageFlag, 'cursor-pointer': activity.isExpandable, 'cursor-default': !activity.isExpandable}">
						<strong [innerHtml]="activity.activityDisplayValue"></strong>
						<span [innerHtml]="activity.mainText"></span>
						<span *ngIf="activity.activityOriginatorType == 'FILE'"
							class="fas fa-clipboard notes-icon ml-2 cursor-pointer"
							tooltip="Click to View Upload {{multiEntityHeading}}" tooltip-placement="auto top-left"
							container="body"
							(click)="viewUploadEntities(activity, viewUploadedEntitiesResultsTemplate)">
						</span>
						<span *ngIf="activity.activityOriginatorType == 'SCREEN' && activity.multiUpdateFlow"
							class="fas fa-clipboard notes-icon ml-2 cursor-pointer"
							tooltip="Click to View Updated {{multiEntityHeading}}" tooltip-placement="auto top-left"
							container="body"
							(click)="ViewMultiEntitiesUpdateModal(activity, viewMultiEntitiesUpdateTemplate)">
						</span>
					</div>
				</div>
				<div class="col-xs-2 text-left">{{activity.lastModifiedDate | fourDotFiveTimeStampFormatPipe}}</div>
			</div>
		</div>
		<div *ngIf="!loadingFlag && activities.length == 0">
			<h2 class="text-center">No activities found</h2>
		</div>
		<div class="col-xs-12 loading-spinner text-center" *ngIf="loadingFlag">
			<span>Loading</span>
			<span class="fa fa-spinner fa-spin ml-2"></span>
		</div>
	</div>
</div>

<ng-template #viewUploadedEntitiesResultsTemplate>
	<div class="ngx-modal-header">
		<h4 class="modal-title pull-left">View Upload {{multiEntityHeading}}</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="viewActivityEntityModal.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="">
			<div class="main-box clearfix">
				<div class="main-box-body p-4">
					<app-upload-req-entities-results [entityType]="entityType" [multiEntityHeading]="multiEntityHeading"
						[singleEntityHeading]="singleEntityHeading" [uploadedEntities]="uploadedEntities">
					</app-upload-req-entities-results>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #viewMultiEntitiesUpdateTemplate>
	<div class="ngx-modal-header">
		<h4 class="modal-title pull-left">
			{{multiUploadEntityActivityHeading}} {{multiEntityHeading}} Activity
		</h4>
		<button type="button" class="close pull-right" aria-label="Close"
			(click)="viewMultiEntityUpdateTemplateModal.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="">
			<div class="clearfix">
				<div style="max-height: 350px; overflow: auto;">
					<div *ngIf="multiUploadEntityActivityDetails.length > 0">
						<table class="table table-bordered table-responsive">
							<thead>
							</thead>
							<tbody>
								<tr *ngFor="let entity of multiUploadEntityActivityDetails;">
									<td [innerHTML]="entity.activityMessage" class="font-bold" style="line-height: 2;">
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div *ngIf="!multiUploadEntityActivityDetails && multiUploadEntityActivityDetails.length == 0">
						No {{multiUploadEntityActivityHeading}} {{multiEntityHeading}}
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>
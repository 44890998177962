import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, from } from 'rxjs';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { EmployeeService } from 'src/app/core/services/employee.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-convert-employee-to-user',
  templateUrl: './convert-employee-to-user.component.html',
  styleUrls: ['./convert-employee-to-user.component.css']
})
export class ConvertEmployeeToUserComponent implements OnInit {

  @Input() employeeId;
  @Output() saveCallback = new EventEmitter<any>();
  @Output() cancelCallback = new EventEmitter<any>();

  @ViewChild('makeAppUserForm', null) makeAppUserForm: NgForm;

  employeeObject: any = null;
  selectedApplicationManagerObjectsArray: any = [];
  permissibleRolesArray: any = [];
  employeeToUserObject: any = {};
  companyId: String = '';
  companyType: String = '';

  loadingEmployeeFlag: boolean = true;
  makingAppUserFlag: boolean = false;

  constructor(
    private authService: AuthService,
    private employeeService: EmployeeService,
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private userService: UserService
  ) {

  }

  ngOnInit() {
    this.employeeToUserObject = {
      'id': this.employeeId,
      'roleId': null,
      'applicationManagerId': null
    };
    this.companyId = this.authService.getUserDetails().company.companyId;
    this.companyType = this.authService.getUserDetails().company.companyType;
    this.loadingEmployeeFlag = true;
    this._getEmployeeDetails();
  }

  _getEmployeeDetails() {
    this.employeeService.getEmployee(this.employeeId, (data) => {
      data.name = data.firstName;
      data.name = _.isNull(data.lastName) || !angular.isDefined(data.lastName) ? data.name : data.name + " " + data.lastName;
      if (data.hasManagerAssignableToUser && !_.isNull(data.organizationManager)) {
        data.organizationManager.name = data.organizationManager.firstName;
        data.organizationManager.name = _.isNull(data.organizationManager.lastName) || !angular.isDefined(data.organizationManager.lastName) ? data.organizationManager.name : data.organizationManager.name + " " + data.organizationManager.lastName;
        this.selectedApplicationManagerObjectsArray.push(data.organizationManager);
        this.applicationManagerTypeAheadManagerAdded(data.organizationManager);
      }
      this.loadingEmployeeFlag = false;
      this.employeeObject = data;
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, "danger");
      this.loadingEmployeeFlag = false;
    })
  }

  convertToApplicationUser() {
    if (this.makeAppUserForm.valid && !_.isNull(this.employeeToUserObject.applicationManagerId) && !_.isNull(this.employeeToUserObject.roleId)) {
      this.makingAppUserFlag = true;
      this.employeeService.setEmployeeToUser(this.employeeToUserObject, (data) => {
        this.alertsAndNotificationsService.showBannerMessage('Employee ' + this.employeeObject.name + ' has been converted to an Application User.', 'success');
        if (this.saveCallback) {
          this.saveCallback.emit({ userObject: data.userDetails });
        }
      }, (error) => {
        this.makingAppUserFlag = false;
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      });
    }
  }

  cancel() {
    if (this.cancelCallback) {
      this.cancelCallback.emit();
    }
  }


  public getApplicationManagers = (searchTerm: String): Observable<any[]> => {
    let searchObject = {
      'searchText': searchTerm,
      'companyId': this.authService.getUserDetails().company.companyId,
      'userId': this.employeeId,
      'page': 1,
      'size': 10
    };
    return from(
      this.employeeService.getPermissibleManagersPromiseForTypeAhead(searchObject).then((data: any) => {
        angular.forEach(data, (val, key) => {
          val.name = val.firstName;
          val.name = _.isNull(val.lastname) || !angular.isDefined(val.lastname) ? val.name : val.name + ' ' + val.lastname;
        });
        return data;
      })
    );
  }

  applicationManagerTypeAheadManagerAdded(tag) {
    this.employeeToUserObject.applicationManagerId = tag.id;
    this.userService.getUserCreateRoles(tag.roleId, this.companyType, (data) => {
      this.permissibleRolesArray = data;
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, "danger");
    });
  }

  applicationManagerTypeAheadManagerRemoved(tag) {
    this.employeeToUserObject.applicationManagerId = null;
    this.employeeToUserObject.roleId = null;
    this.permissibleRolesArray = [];
  }

  removeItem(item, index) {
    this.selectedApplicationManagerObjectsArray.splice(index, 1);
  }

}

import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CandidateService } from 'src/app/core/services/candidate.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { EmployeeService } from 'src/app/core/services/employee.service';
import { UserService } from 'src/app/core/services/user.service';
import { MESSAGECONSTANTS } from 'src/app/core/utils/constants';

import moment from 'moment';

@Component({
  selector: 'app-delete-entity',
  templateUrl: './delete-entity.component.html',
  styleUrls: ['./delete-entity.component.css']
})
export class DeleteEntityComponent implements OnInit {

  @ViewChild('actionform', null) actionform: NgForm;

  @Input() entity;
  @Input() deleteNote;
  @Input() candidateInfo;
  @Input() userInfo;
  @Input() employeeInfo;
  @Input() clientOrBuInfo;
  @Input() companyInfo;
  @Input() corpVendorInfo;
  @Input() buVendorInfo;
  @Output() deleteCallback = new EventEmitter<any>();
  @Output() cancelCallback = new EventEmitter<any>();

  MESSAGECONSTANTS: any = MESSAGECONSTANTS;

  deletingFlag: boolean = false;
  isLoading: boolean = true;

  date: any;
  time: any;
  note: any;
  availabilityDate: any = null;
  availabilityObject: any = {};
  employeeMeetingsInfo: any;
  companyId: any;
  belongsToBuOrDepartmentText: any;

  constructor(
    private authService: AuthService,
    private employeeService: EmployeeService,
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private candidateService: CandidateService,
    private userService: UserService,
    private companyService: CompanyService) { }

  ngOnInit() {
    //flags
    this.companyId = this.authService.getUserDetails().company.companyId;
    this.belongsToBuOrDepartmentText = this.isStaffingCompany() ? "Department" : "BU";
    this._setDateAndTime();
    if (this.entity == "employee") {
      this._getEmployeeDetails();
    } else {
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    }
  }

  isStaffingCompany() {
    if (this.authService.getUserDetails().company.companyType == this.MESSAGECONSTANTS.COMPANY_TYPES.STAFFING) {
      return true;
    }
    return false;
  }

  _setDateAndTime() {
    this.date = moment().format('ll');
    this.time = moment().format('hh:mm:ss a');
    setTimeout(() => {
      this._setDateAndTime();
    }, 1000);
  }

  _getEmployeeDetails() {
    this.employeeService.getEmployeeMeetings(this.employeeInfo.id, this.companyId, (data) => {
      this.employeeMeetingsInfo = data;
      this._massageEmployeeMeetingDetails();
      this.isLoading = false;
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  _massageEmployeeMeetingDetails() {
    this.employeeMeetingsInfo.newParticipant = null;
    this.employeeMeetingsInfo.newParticipantUserId = null;
    if (this.employeeMeetingsInfo.permissibleUsersToReassign !== null && this.employeeMeetingsInfo.permissibleUsersToReassign.length > 0) {
      this.employeeMeetingsInfo.newParticipant = this.employeeMeetingsInfo.permissibleUsersToReassign[0];
      this.employeeMeetingsInfo.newParticipantUserId = this.employeeMeetingsInfo.permissibleUsersToReassign[0].userId;
    }
    let phoneScreenText = this.employeeMeetingsInfo.phoneScreenCount > 1 ? "Phone Screens" : "Phone Screen";
    let interviewText = this.employeeMeetingsInfo.interviewCount > 1 ? "Interviews" : "Interview";
    let message = "";
    let existingMeetingMessage = "";

    if (this.employeeMeetingsInfo.phoneScreenCount > 0 && this.employeeMeetingsInfo.interviewCount > 0) {
      message = "The employee is assigned to <b>" + this.employeeMeetingsInfo.phoneScreenCount + " " + phoneScreenText + "</b> and <b>" + this.employeeMeetingsInfo.interviewCount + " " + interviewText + "</b>.";
      existingMeetingMessage = phoneScreenText + " and " + interviewText;
    } else if (this.employeeMeetingsInfo.phoneScreenCount > 0) {
      message = "The employee is assigned to <b>" + this.employeeMeetingsInfo.phoneScreenCount + " " + phoneScreenText + "</b>.";
      existingMeetingMessage = phoneScreenText;
    } else if (this.employeeMeetingsInfo.interviewCount > 0) {
      message = "The employee is assigned to <b>" + this.employeeMeetingsInfo.interviewCount + " " + interviewText + "</b>.";
      existingMeetingMessage = interviewText;
    }
    this.employeeMeetingsInfo.meetingsMainMessage = message;
    this.employeeMeetingsInfo.existingMeetingMessage = existingMeetingMessage;
  }

  cancel() {
    if (this.cancelCallback) {
      this.cancelCallback.emit();
    }
  }

  deleteEntity() {
    if (this.entity === "candidate") {
      let deleteCandidateObject = { id: this.candidateInfo.candidateId, note: this.note };
      this.deleteCandidate(deleteCandidateObject);
    } else if (this.entity === "user") {
      let deleteUserObject = { id: this.userInfo.userId, note: this.note, companyId: this.companyId };
      this.deleteUser(deleteUserObject);
    } else if (this.entity === "employee") {
      let deleteEmployeeObject = angular.copy(this.employeeMeetingsInfo);
      this._setEmployeeDeleteObject(deleteEmployeeObject);
      this.deleteEmployee(deleteEmployeeObject);
    } else if (this.entity === "clientOrBU") {
      let deleteClientOrBUObject = { id: this.clientOrBuInfo.clientOrBUId, note: this.note, hardDelete: false, isClientOrBuDelete: true };
      this.deleteClientOrBU(deleteClientOrBUObject);
    } else if (this.entity === "company") {
      let deleteCompanyObject = { id: this.companyInfo.companyId, note: this.note, hardDelete: false, isClientOrBuDelete: false };
      this.deleteCompany(deleteCompanyObject);
    } else if (this.entity === "corpVendorRelationship") {
      let deleteCorpVendorRelationship = { id: this.corpVendorInfo.id, note: this.note, hardDelete: false };
      this.deleteCorpVendorRelationship(deleteCorpVendorRelationship);
    } else if (this.entity === "buVendorRelationship") {
      this.deleteCallback.emit();
    }
  }

  _setEmployeeDeleteObject(deleteEmployeeObject) {
    if (deleteEmployeeObject.newParticipantUserId !== null) {
      deleteEmployeeObject.newParticipant = deleteEmployeeObject.permissibleUsersToReassign.filter(user => {
        return user.userId == deleteEmployeeObject.newParticipantUserId;
      })[0];
    }
    delete deleteEmployeeObject.existingMeetingMessage;
    delete deleteEmployeeObject.interviewCount;
    delete deleteEmployeeObject.meetingPresent;
    delete deleteEmployeeObject.phoneScreenCount;
    delete deleteEmployeeObject.meetingsMainMessage;
    delete deleteEmployeeObject.permissibleUsersToReassign;

    deleteEmployeeObject.id = this.employeeInfo.id;
    deleteEmployeeObject.note = this.note;
    deleteEmployeeObject.rescheduleToScheduledUser = !deleteEmployeeObject.usersToReassignAvailable;
  }

  deleteCandidate(deleteCandidateObject) {
    this.deletingFlag = true;
    this.candidateService.deleteCandidate(deleteCandidateObject, (data) => {
      this.deletingFlag = false;
      this.alertsAndNotificationsService.showBannerMessage("Candidate '" + this.candidateInfo.candidateName + "' deleted successfully", 'success');
      if (this.deleteCallback) {
        this.deleteCallback.emit();
      }
    }, (error) => {
      this.deletingFlag = false;
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  deleteUser(deleteUserObject) {
    this.deletingFlag = true;
    this.userService.deleteUser(deleteUserObject, (data) => {
      this.deletingFlag = false;
      this.alertsAndNotificationsService.showBannerMessage("User '" + this.userInfo.fullName + "' deleted successfully", 'success');
      if (this.deleteCallback) {
        this.deleteCallback.emit();
      }
    }, (error) => {
      this.deletingFlag = false;
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  deleteEmployee(deleteEmployeeObject) {
    this.deletingFlag = true;
    this.employeeService.deleteEmployee(deleteEmployeeObject, (data) => {
      this.deletingFlag = false;
      this.alertsAndNotificationsService.showBannerMessage("Employee '" + this.employeeInfo.name + "' deleted successfully", 'success');
      if (this.deleteCallback) {
        this.deleteCallback.emit(this.employeeInfo);
      }
    }, (error) => {
      this.deletingFlag = false;
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  deleteClientOrBU(deleteClientOrBUObject) {
    this.deletingFlag = true;
    this.companyService.deleteCompany(deleteClientOrBUObject, (data) => {
      this.deletingFlag = false;
      if (this.isStaffingCompany()) {
        this.alertsAndNotificationsService.showBannerMessage("Client '" + this.clientOrBuInfo.clientOrBUName + "' deleted successfully", 'success');
      } else {
        this.alertsAndNotificationsService.showBannerMessage("BU '" + this.clientOrBuInfo.clientOrBUName + "' deleted successfully", 'success');
      }
      if (this.deleteCallback) {
        this.deleteCallback.emit();
      }
    }, (error) => {
      this.deletingFlag = false;
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  deleteCompany(deleteCompanyObject) {
    this.deletingFlag = true;
    this.companyService.deleteCompany(deleteCompanyObject, (data) => {
      this.deletingFlag = false;
      this.alertsAndNotificationsService.showBannerMessage("Company '" + this.companyInfo.companyName + "' deleted successfully", 'success');
      if (this.deleteCallback) {
        this.deleteCallback.emit();
      }
    }, (error) => {
      this.deletingFlag = false;
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  deleteCorpVendorRelationship(corpVendorRelationshipObject) {
    this.deletingFlag = true;
    this.companyService.deleteCorpVendorRelationship(corpVendorRelationshipObject, (data) => {
      this.deletingFlag = false;
      this.alertsAndNotificationsService.showBannerMessage("Vendor '" + this.corpVendorInfo.vendorName + "' disassociated successfully", 'success');
      if (this.deleteCallback) {
        this.deleteCallback.emit();
      }
    }, (error) => {
      this.deletingFlag = false;
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

}

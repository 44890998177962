import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityActivitiesComponent } from './entity-activities.component';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { TooltipModule } from 'ngx-bootstrap';

@NgModule({
  declarations: [EntityActivitiesComponent],
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    TooltipModule.forRoot()
  ],
  entryComponents: [EntityActivitiesComponent],
  exports: [EntityActivitiesComponent]
})
export class EntityActivitiesModule { }

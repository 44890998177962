<form #additionalDetailsForm="ngForm" novalidate>
    <div class="row p-3">
        <div class="col-xs-12">
            <!-- Preferred Job Type and Salary/Rate -->
            <div class="col-xs-12 additional-detail" style="padding-top:10px" *ngIf="showSalary || isSuperUser">
                <div class="col-xs-12">
                    <p><i class="fas fa-dollar-sign"></i> <span class="section-header">Preferred Job Type and
                            Salary/Rate:</span></p>
                </div>
                <div class="col-xs-12">
                    <div class="form-group col-xs-6 position-relative mb-3">
                        <ng-select *ngIf="isPreferredJobTypeSet" [loading]="!isPreferredJobTypeSet"
                            [ngClass]="{'custom-ng-select-container': context != 'candidateCard' 
                                && (!additionalDetailsCopy || !additionalDetailsCopy.preferredJobTypeDetails 
                                || !additionalDetailsCopy.preferredJobTypeDetails.jobType 
                                || additionalDetailsCopy.preferredJobTypeDetails.jobType.length == 0)}"
                            name="preferredJobTypeList" [items]="preferredJobTypeList" bindLabel="name"
                            placeholder="None selected" [(ngModel)]="selectedPreferredJobType" [closeOnSelect]="false"
                            [multiple]="true" (change)="onPreferredJobTypeSelected()">
                            <ng-template ng-header-tmp>
                                <div class="d-flex">
                                    <div class="mr-2 custom-ng-select-btn" (click)="selectAllPreferredJobType()">Select
                                        All
                                    </div>
                                    <div class="custom-ng-select-btn" (click)="deSelectAllPreferredJobType()">Select
                                        None
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                <div class="ng-value" *ngFor="let item of items | slice:0:2">
                                    <span class="ng-value-label">{{item.name}}</span>
                                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                </div>
                                <div class="ng-value" *ngIf="items.length > 2">
                                    <span class="ng-value-label">{{items.length - 2}} more...</span>
                                </div>
                            </ng-template>
                        </ng-select>
                        <span *ngIf="context != 'candidateCard' && 
                            (!additionalDetailsCopy || !additionalDetailsCopy.preferredJobTypeDetails
                            || !additionalDetailsCopy.preferredJobTypeDetails.jobType || additionalDetailsCopy.preferredJobTypeDetails.jobType.length == 0)"
                            class="fa fa-exclamation-circle red additional-missing-info-icon ml-2 pl-1" 
                            placement="top" tooltip="Update Missing Preferred Job type" container="body">
                        </span>
                    </div>

                    <div class="col-xs-12 d-lg-flex">
                        <!-- Full time Salary -->
                        <div class="col-lg-6 col-xs-12 p-4 mb-3 border rounded-lg mr-2" *ngIf="isPreferredJobTypeSet && additionalDetails.preferredJobTypeDetails.salary">
                            <div class="col-xs-12">
                                <p>
                                    <span style="margin-right:10px; font-weight: bold; font-size: 13px;">
                                        Salary for <span *ngFor="let jobType of selectedPreferredJobType; let i = index;"
                                            class="font-bold">
                                            <span *ngIf="jobType.value === 'FULLTIME'">{{jobType.name}} </span>
                                        </span>
                                    </span>
                                </p>
                                <div class="form-group mb-3">
                                    <div>
                                        <div class="radio" style="display: inline;">
                                            <input type="radio" name="salaryType" id="salaryTypeSpecific" value="SPECIFIC"
                                                (change)="onSalaryTypeChange('SPECIFIC')"
                                                [(ngModel)]="additionalDetails.preferredJobTypeDetails.salary.salaryType"
                                                [disabled]="!isPreferredJobTypeFullTime">
                                            <label for="salaryTypeSpecific" [ngClass]="{'set-salary-disabled': !isPreferredJobTypeFullTime}">
                                                Specific
                                            </label>
                                        </div>
                                        <div class="radio" style="display: inline;">
                                            <input type="radio" name="salaryType" id="salaryTypeRange" value="RANGE"
                                                (change)="onSalaryTypeChange('RANGE')"
                                                [(ngModel)]="additionalDetails.preferredJobTypeDetails.salary.salaryType"
                                                [disabled]="!isPreferredJobTypeFullTime">
                                            <label for="salaryTypeRange" [ngClass]="{'set-salary-disabled': !isPreferredJobTypeFullTime}">
                                                Range
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-4" [ngClass]="{'has-error': (additionalDetailsForm.submitted && (amount.invalid || amount.errors?.pattern))}"
                                *ngIf="additionalDetails.preferredJobTypeDetails.salary.salaryType == 'SPECIFIC'">
                                <div class="form-group">
                                    <label class="control-label" for="amount">
                                        Value:
                                    </label>
                                    <input #amount="ngModel" type="number" class="form-control pr-1" id="amount" name="amount"
                                        [(ngModel)]="additionalDetails.preferredJobTypeDetails.salary.maxSalary" min="1"
                                        [required]="additionalDetails.preferredJobTypeDetails.salary.salaryType == 'SPECIFIC'"
                                        [pattern]="MESSAGECONSTANTS.VALIDATIONPATTERNS.NUMERIC_GREATER_THAN_ZERO"
                                        [disabled]="!isPreferredJobTypeFullTime">
                                </div>
                            </div>
                            <div class="form-group col-xs-4"
                                *ngIf="additionalDetails.preferredJobTypeDetails.salary.salaryType == 'SPECIFIC'">
                                <label class="control-label" for="currency">
                                    Currency:
                                </label>
                                <select class="form-control" name="currency" id="currency"
                                    [(ngModel)]="additionalDetails.preferredJobTypeDetails.salary.currency" required
                                    [disabled]="!isPreferredJobTypeFullTime">
                                    <option *ngFor="let currency of currencyCodes; let i = index;" [value]="currency"
                                        [title]="currency">
                                        {{currency}}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group col-xs-4"
                                *ngIf="additionalDetails.preferredJobTypeDetails.salary.salaryType == 'SPECIFIC'">
                                <label class="control-label" for="salaryDuration">
                                    Per:
                                </label>
                                <select class="form-control" name="salaryDuration" id="salaryDuration"
                                    [(ngModel)]="additionalDetails.preferredJobTypeDetails.salary.salaryDuration"
                                    [required]="(additionalDetails.preferredJobTypeDetails.salary.salaryType == 'RANGE')"
                                    [disabled]="!isPreferredJobTypeFullTime">
                                    <option value="PER_YEAR" title="Per Year">
                                        Year
                                    </option>
                                </select>
                            </div>
                            <div class="col-xs-12 px-0"
                                *ngIf="additionalDetails.preferredJobTypeDetails.salary.salaryType == 'RANGE'">
                                <div class="form-group col-xs-3" [ngClass]="{'has-error': (additionalDetailsForm.submitted && (minSalary.invalid || minSalary.errors?.pattern))}">
                                    <label class="control-label" for="minSalary">
                                        From:
                                    </label>
                                    <input #minSalary="ngModel" type="number" class="form-control pr-1" id="minSalary" name="minSalary" min="1"
                                        [(ngModel)]="additionalDetails.preferredJobTypeDetails.salary.minSalary"
                                        [required]="additionalDetails.preferredJobTypeDetails.salary.salaryType == 'RANGE'"
                                        [pattern]="MESSAGECONSTANTS.VALIDATIONPATTERNS.NUMERIC_GREATER_THAN_ZERO"
                                        [disabled]="!isPreferredJobTypeFullTime">
                                </div>
                                <div class="form-group col-xs-3" [ngClass]="{'has-error': (additionalDetailsForm.submitted && (maxSalary.invalid || maxSalary.errors?.pattern))}">
                                    <label class="control-label" for="maxSalary">
                                        To:
                                    </label>
                                    <input #maxSalary="ngModel" type="number" class="form-control pr-1" id="maxSalary" name="maxSalary"
                                        min="{{additionalDetails.preferredJobTypeDetails.salary.minSalary+1}}"
                                        [(ngModel)]="additionalDetails.preferredJobTypeDetails.salary.maxSalary"
                                        [required]="additionalDetails.preferredJobTypeDetails.salary.salaryType == 'RANGE'"
                                        [pattern]="MESSAGECONSTANTS.VALIDATIONPATTERNS.NUMERIC_GREATER_THAN_ZERO"
                                        [disabled]="!isPreferredJobTypeFullTime">
                                </div>

                                <div class="form-group col-xs-3">
                                    <label class="control-label" for="currency">
                                        Currency:
                                    </label>
                                    <select class="form-control" name="currency" id="currency"
                                        [(ngModel)]="additionalDetails.preferredJobTypeDetails.salary.currency" required
                                        [disabled]="!isPreferredJobTypeFullTime">
                                        <option *ngFor="let currency of currencyCodes; let i = index;" [value]="currency"
                                            [title]="currency">
                                            {{currency}}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group col-xs-3">
                                    <label class="control-label" for="salaryDuration">
                                        Per:
                                    </label>
                                    <select class="form-control" name="salaryDuration" id="salaryDuration"
                                        [(ngModel)]="additionalDetails.preferredJobTypeDetails.salary.salaryDuration"
                                        [required]="(additionalDetails.preferredJobTypeDetails.salary.salaryType == 'RANGE')"
                                        [disabled]="!isPreferredJobTypeFullTime">
                                        <option value="PER_YEAR" title="Per Year">
                                            Year
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <!-- Salary End -->

                        <!-- Rate Start -->
                        <div class="col-lg-6 col-xs-12 p-4 mb-3 border rounded-lg" *ngIf="isPreferredJobTypeSet && additionalDetails.preferredJobTypeDetails.rate">
                            <div class="col-xs-12">
                                <p>
                                    <span style="margin-right:10px; font-weight: bold; font-size: 13px;">
                                        Rate for <span *ngFor="let jobType of setPreferredJobTypeFilter(); let i = index;">
                                            <span>
                                                {{jobType.name}}<span
                                                    *ngIf="setPreferredJobTypeFilter().length-1 != i">,</span>
                                                <span
                                                    *ngIf="setPreferredJobTypeFilter().length>1 && setPreferredJobTypeFilter().length-1 == i-1">
                                                    and </span>
                                            </span>
                                        </span>
                                    </span>
                                </p>
                                <div class="form-group mb-3">
                                    <div>
                                        <div class="radio" style="display: inline;">
                                            <input type="radio" name="rateType" id="rateTypeSpecific" value="SPECIFIC"
                                                (change)="onRateTypeChange('SPECIFIC')"
                                                [(ngModel)]="additionalDetails.preferredJobTypeDetails.rate.rateType"
                                                [disabled]="!isPreferredJobTypeNotFullTime">
                                            <label for="rateTypeSpecific" [ngClass]="{'set-salary-disabled': !isPreferredJobTypeNotFullTime}">
                                                Specific
                                            </label>
                                        </div>
                                        <div class="radio" style="display: inline;">
                                            <input type="radio" name="rateType" id="rateTypeRange" value="RANGE"
                                                (change)="onRateTypeChange('RANGE')"
                                                [(ngModel)]="additionalDetails.preferredJobTypeDetails.rate.rateType"
                                                [disabled]="!isPreferredJobTypeNotFullTime">
                                            <label for="rateTypeRange" [ngClass]="{'set-salary-disabled': !isPreferredJobTypeNotFullTime}">
                                                Range
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-4"
                                *ngIf="additionalDetails.preferredJobTypeDetails.rate.rateType == 'SPECIFIC'">
                                <div class="form-group" [ngClass]="{'has-error': (additionalDetailsForm.submitted && (maxAmount.invalid || maxAmount.errors?.pattern))}">
                                    <label class="control-label" for="maxAmount">
                                        Value:
                                    </label>
                                    <input #maxAmount="ngModel" type="number" class="form-control pr-1" id="maxAmount" name="maxAmount"
                                        [(ngModel)]="additionalDetails.preferredJobTypeDetails.rate.maxRate" min="1"
                                        [required]="additionalDetails.preferredJobTypeDetails.rate.rateType == 'SPECIFIC'"
                                        [pattern]="MESSAGECONSTANTS.VALIDATIONPATTERNS.NUMERIC_GREATER_THAN_ZERO"
                                        [disabled]="!isPreferredJobTypeNotFullTime">
                                </div>
                            </div>
                            <div class="form-group col-xs-4"
                                *ngIf="additionalDetails.preferredJobTypeDetails.rate.rateType == 'SPECIFIC'">
                                <label class="control-label" for="rateCurrency">
                                    Currency:
                                </label>
                                <select class="form-control" name="rateCurrency" id="rateCurrency"
                                    [(ngModel)]="additionalDetails.preferredJobTypeDetails.rate.currency" required
                                    [disabled]="!isPreferredJobTypeNotFullTime">
                                    <option *ngFor="let currency of currencyCodes; let i = index;" [value]="currency"
                                        [title]="currency">
                                        {{currency}}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group col-xs-4"
                                *ngIf="additionalDetails.preferredJobTypeDetails.rate.rateType == 'SPECIFIC'">
                                <label class="control-label" for="rateDuration">
                                    Per:
                                </label>
                                <select class="form-control" name="rateDuration" id="rateDuration"
                                    [(ngModel)]="additionalDetails.preferredJobTypeDetails.rate.rateDuration"
                                    [required]="(additionalDetails.preferredJobTypeDetails.rate.rateType == 'RANGE')"
                                    [disabled]="!isPreferredJobTypeNotFullTime">
                                    <option *ngFor="let salaryDuration of salaryDurationOptions; let i = index;"
                                        [value]="salaryDuration.value" [title]="salaryDuration.name">
                                        {{salaryDuration.name}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-xs-12 px-0"
                                *ngIf="additionalDetails.preferredJobTypeDetails.rate.rateType == 'RANGE'">
                                <div class="form-group col-xs-3" [ngClass]="{'has-error': (additionalDetailsForm.submitted && (minRate.invalid || minRate.errors?.pattern))}">
                                    <label class="control-label" for="minRate">
                                        From:
                                    </label>
                                    <input #minRate="ngModel" type="number" class="form-control pr-1" id="minRate" name="minRate" min="1"
                                        [(ngModel)]="additionalDetails.preferredJobTypeDetails.rate.minRate"
                                        [required]="additionalDetails.preferredJobTypeDetails.rate.rateType == 'RANGE'"
                                        [pattern]="MESSAGECONSTANTS.VALIDATIONPATTERNS.NUMERIC_GREATER_THAN_ZERO"
                                        [disabled]="!isPreferredJobTypeNotFullTime">
                                </div>
                                <div class="form-group col-xs-3" [ngClass]="{'has-error': (additionalDetailsForm.submitted && (maxRate.invalid || maxRate.errors?.pattern))}">
                                    <label class="control-label" for="maxRate">
                                        To:
                                    </label>
                                    <input #maxRate="ngModel" type="number" class="form-control pr-1" id="maxRate" name="maxRate"
                                        min="{{additionalDetails.preferredJobTypeDetails.rate.minRate+1}}"
                                        [(ngModel)]="additionalDetails.preferredJobTypeDetails.rate.maxRate"
                                        [required]="additionalDetails.preferredJobTypeDetails.rate.rateType == 'RANGE'"
                                        [pattern]="MESSAGECONSTANTS.VALIDATIONPATTERNS.NUMERIC_GREATER_THAN_ZERO"
                                        [disabled]="!isPreferredJobTypeNotFullTime">
                                </div>

                                <div class="form-group col-xs-3">
                                    <label class="control-label" for="rateCurrency">
                                        Currency:
                                    </label>
                                    <select class="form-control" name="rateCurrency" id="rateCurrency"
                                        [(ngModel)]="additionalDetails.preferredJobTypeDetails.rate.currency" required
                                        [disabled]="!isPreferredJobTypeNotFullTime">
                                        <option *ngFor="let currency of currencyCodes; let i = index;" [value]="currency"
                                            [title]="currency">
                                            {{currency}}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group col-xs-3">
                                    <label class="control-label" for="rateDuration">
                                        Per:
                                    </label>
                                    <select class="form-control pr-1" name="rateDuration" id="rateDuration"
                                        [(ngModel)]="additionalDetails.preferredJobTypeDetails.rate.rateDuration"
                                        [required]="(additionalDetails.preferredJobTypeDetails.rate.rateType == 'RANGE')"
                                        [disabled]="!isPreferredJobTypeNotFullTime">
                                        <option *ngFor="let salaryDuration of salaryDurationOptions; let i = index"
                                            [value]="salaryDuration.value" [title]="salaryDuration.name">
                                            {{salaryDuration.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <!-- Rate end -->
                    </div>

                    <!-- Company Start -->
                    <div class="col-xs-12 p-4 mb-3 border rounded-lg" *ngIf="(checkPreferredJobType('CONTRACTTOHIRE') || checkPreferredJobType('CONTRACTOR')) && selectedPreferredJobType !== undefined && selectedPreferredJobType !== null 
                            && selectedPreferredJobType.length > 0 && isPreferredJobTypeNotFullTime">
                        <div class="col-xs-12">
                            <div class="row d-flex align-items-center" style="font-weight: bold; font-size: 13px;">
                                <div class="col-xs-3">
                                    <span *ngFor="let jobType of setPreferredJobTypeFilter(); let i = index;">
                                        <span
                                            *ngIf="jobType.value === 'CONTRACTOR' || jobType.value === 'CONTRACTTOHIRE'">
                                            {{jobType.name}}<span
                                                *ngIf="setPreferredJobTypeFilter().length-1 != i">,</span>
                                            <span
                                                *ngIf="setPreferredJobTypeFilter().length>1 && setPreferredJobTypeFilter().length-1 == i-1">
                                                and </span>
                                        </span>
                                    </span> Type
                                </div>
                                <div class="col-xs-3 position-relative">
                                    <ng-select name="preferredJobTypeAdditionalList"
                                        [items]="preferredJobTypeAdditionalList" bindLabel="name"
                                        placeholder="None selected" [clearable] = "false"
                                        [(ngModel)]="selectedPreferredJobTypeAdditionalList"
                                        (change)="onPreferredJobTypeAdditionalSelected()">
                                    </ng-select>
                                </div>
                            </div>

                            <div class="col-xs-12 my-3" *ngIf="selectedPreferredJobTypeAdditionalList && selectedPreferredJobType.length > 0 
                                        && (selectedPreferredJobTypeAdditionalList.value === 'CORPTOCORP')">
                                <div class="d-flex justify-content-between align-items-center">
                                    <ng-template #customItemTemplate let-model="item" let-index="index">
                                        <div>
                                            <span>{{model.companyName}},</span>
                                            <i *ngIf="model.firstname"> {{model.firstname}} <span *ngIf="model.lastname"> {{model.lastname}}</span></i>
                                            <i *ngIf="model.phonenumber">, {{model.phonenumber}}</i>
                                            <i *ngIf="model.email">, {{model.email}}</i>
                                        </div>
                                    </ng-template>
                                    <input
                                        [ngClass]="{'border-danger': isCompanySelected}" class="form-control" name="selectedCompany"
                                        placeholder="Search by company name or contact" [(ngModel)]="selectedCompany" autocomplete="off"
                                        [typeahead]="companiesSearchList" [typeaheadOptionsInScrollableView]="5" typeaheadOptionField="companyName"
                                        [typeaheadScrollable]="true" (typeaheadOnSelect)="onCompanySelected($event)"
                                        [typeaheadItemTemplate]="customItemTemplate" (keyup)="onSelectCompanyFieldChange()">
                                    <div class="py-2">
                                        <button type="button" class="btn btn-info" (click)="addEditCompanyDetails('add')"
                                            *ngIf="!selectedCompany || !companyDetails.companyContactDetails || !companyDetails.companyContactDetails.companyName">
                                            <span class="fa fa-plus-circle"></span> Add contact
                                        </button>
                                        <button type="button" class="btn btn-info" (click)="addEditCompanyDetails('edit')"
                                            *ngIf="selectedCompany && companyDetails.companyContactDetails && companyDetails.companyContactDetails.companyName">
                                            <span class="fa fa-pencil-alt"></span> Edit contact
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xs-12 px-4" *ngIf="isAddNewCompanyDetails">
                            <app-add-new-company-details [readOnly]="true" [contactDetails]="companyDetails.companyContactDetails" elementId="candidateAdditionalDetails"></app-add-new-company-details>
                        </div>

                    </div>
                    <!-- Company end -->

                </div>
            </div>
            <!-- Employment Eligibility -->
            <div class="col-xs-12 additional-detail" style="padding-top:10px" *ngIf="showSalary || isSuperUser">
                <div class="col-xs-12">
                    <i class="fas fa-award"></i>
                    <span class="section-header">Employment Eligibility:</span>
                </div>
                <div class="col-xs-12" style="margin-top:10px">
                    <div class="col-xs-2">
                        Current Status:
                    </div>
                    <div class="col-xs-6">
                        <ng-select
                            [ngClass]="{'custom-ng-select-container': context != 'candidateCard' 
                                && (!additionalDetailsCopy || !additionalDetailsCopy.sponsorships 
                                || additionalDetailsCopy.sponsorships.length == 0)}" 
                            name="sponsorshipList" [items]="sponsorshipList" bindLabel="name"
                            placeholder="None selected" [(ngModel)]="selectedSponsorships"
                            (change)="onSponsorshipSelected()">
                        </ng-select>
                        <span *ngIf="context != 'candidateCard' && 
                            (!additionalDetailsCopy || !additionalDetailsCopy.sponsorships || additionalDetailsCopy.sponsorships.length == 0)" 
                            class="fa fa-exclamation-circle red additional-missing-info-icon ml-2 pl-1" 
                            placement="top" tooltip="Update Missing Preferred Sponsorship" container="body">
                        </span>
                    </div>
                </div>
                <div class="col-xs-12">
                    <div class="form-group">
                        <label class="control-label" for="employmentEligibilityNotes">
                            Notes:
                        </label>
                        <div style="display:inline">
                            <textarea name="employmentEligibilityNotes" class="form-control"
                                style="height: 50px; width: 100%;resize: none;" maxlength="503"
                                [(ngModel)]="additionalDetails.sponsorshipsNote">
                            </textarea>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Work Location -->
            <div class="col-xs-12 additional-detail" style="padding-top:10px" *ngIf="showSalary || isSuperUser">
                <div class="col-xs-12">
                    <p><i class="far fa-building"></i> <span class="section-header">Preferred Work Locations:</span>
                    </p>
                </div>
                <div class="col-xs-12">
                    <div class="form-group col-xs-5">
                        <ng-select *ngIf="isWorkLocationsSet" 
                            [ngClass]="{'custom-ng-select-container': context != 'candidateCard' 
                                && (!additionalDetailsCopy || !additionalDetailsCopy.workLocations 
                                || additionalDetailsCopy.workLocations.length == 0)}"    
                            name="workLocationList" [items]="workLocationList"
                            bindLabel="name" placeholder="None selected" [(ngModel)]="selectedWorkLocations"
                            [closeOnSelect]="false" [multiple]="true" (change)="onWorkLocationsSelected()">
                            <ng-template ng-header-tmp>
                                <div class="d-flex">
                                    <div class="mr-2 custom-ng-select-btn" (click)="selectAllWorkLocations()">Select All
                                    </div>
                                    <div class="custom-ng-select-btn" (click)="deSelectAllWorkLocations()">Select None
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                <div class="ng-value" *ngFor="let item of items | slice:0:2">
                                    <span class="ng-value-label">{{item.name}}</span>
                                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                </div>
                                <div class="ng-value" *ngIf="items.length > 2">
                                    <span class="ng-value-label">{{items.length - 2}} more...</span>
                                </div>
                            </ng-template>
                        </ng-select>
                        <span *ngIf="context != 'candidateCard' && 
                            (!additionalDetailsCopy || !additionalDetailsCopy.workLocations || additionalDetailsCopy.workLocations.length == 0)" 
                            class="fa fa-exclamation-circle red additional-missing-info-icon ml-2 pl-1" 
                            placement="top" tooltip="Update Missing Preferred Work location" container="body">
                        </span>
                    </div>

                    <div class="form-group col-xs-12">
                        <label class="control-label">
                            <span>Willing to relocate?</span>
                        </label>
                        <div style="display:inline; margin-left:5px;">
                            <div class="radio" style="display: inline;">
                                <input type="radio" name="relocate" id="relocateYes" value="YES"
                                    [(ngModel)]="additionalDetails.willingToRelocate">
                                <label for="relocateYes">
                                    Yes
                                </label>
                            </div>
                            <div class="radio" style="display: inline;">
                                <input type="radio" name="relocate" id="relocateNo" value="NO"
                                    [(ngModel)]="additionalDetails.willingToRelocate">
                                <label for="relocateNo">
                                    No
                                </label>
                            </div>
                            <div class="radio" style="display: inline;">
                                <input type="radio" name="relocate" id="relocateNotApplicable" value="NOT_APPLICABLE"
                                    [(ngModel)]="additionalDetails.willingToRelocate">
                                <label for="relocateNotApplicable">
                                    Not Applicable
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12">
                    <div class="form-group">
                        <label class="control-label" for="relocationNotes">
                            Notes:
                        </label>
                        <div style="display:inline">
                            <textarea name="relocationNotes" class="form-control"
                                style="height: 50px; width: 100%;resize: none;" maxlength="503"
                                [(ngModel)]="additionalDetails.relocationNote">
                            </textarea>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Additional URL's -->
            <div class="col-xs-12 additional-detail" style="padding-top:10px">
                <div class="col-xs-12">
                    <span class="pb-3 d-block section-header">
                        <i class="fa fa-link mr-2"></i>
                        Additional URL's:
                    </span>
                </div>
                <div class="col-xs-12" *ngIf="additionalDetails.urls && additionalDetails.urls.length > 0">
                    <div class="form-group">
                        <div class="col-xs-12 mb-3 d-flex align-items-center"
                            *ngFor="let additionalUrl of additionalDetails.urls; let i = index;">
                            <div class="col-xs-4">
                                <div>
                                    <select [ngClass]="{'border-danger': additionalUrl.isUrlSourceType}" class="form-control" [name]="'editCandidateUrlSourceType'+additionalUrl.id"
                                        [id]="'editCandidateUrlSourceType'+additionalUrl.id"
                                        [(ngModel)]="additionalUrl.urlType"
                                        (change)="candidateSourceTypeChanged(additionalUrl, i)"
                                        [disabled]="!showSalary && !isSuperUser">
                                        <option *ngFor="let urlType of candidateUrlSourceTypes" [value]="urlType">
                                            {{urlType}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-xs-4">
                                <div>
                                    <input [ngClass]="{'border-danger': additionalUrl.isUrlLink}" type="text" [name]="'editCandidateUrl'+additionalUrl.id"
                                        [id]="'editCandidateUrl'+additionalUrl.id" class="form-control"
                                        [(ngModel)]="additionalUrl.urlLink" (keyup)="candidateUrlLinkChanged(additionalUrl, i)"
                                        [disabled]="!showSalary && !isSuperUser">
                                </div>
                            </div>
                            <div class="col-xs-4" *ngIf="showSalary || isSuperUser">
                                <a (click)="deleteUrl(additionalUrl)" tooltip="delete" container="body" placement="top">
                                    <span class="fa-stack delete-social-profile-url" style="font-size: 12px;">
                                        <i class="fa fa-square fa-stack-2x"></i>
                                        <i class="fa fa-trash-alt fa-stack-1x fa-inverse"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 text-left mb-3 ml-4" *ngIf="!additionalDetails.urls || additionalDetails.urls.length == 0">
                    <span>No Additional URL</span>
                </div>
                <div class="col-xs-12 text-left mb-3 ml-4" *ngIf="showSalary || isSuperUser">
                    <a class="text-primary add-social-profile-url" (click)="addUrl()">
                        <span class="fa fa-plus-circle pr-2"></span>Add Url
                    </a>
                </div>
            </div>
            <!-- Additional Information -->
            <div class="col-xs-12 additional-detail" style="padding-top:10px" *ngIf="showSalary || isSuperUser">
                <div class="col-xs-12">
                    <span class="pb-3 d-block section-header">Additional Information:</span>
                </div>
                <div class="col-xs-12">
                    <div class="form-group">
                        <div class="d-inline">
                            <textarea name="additionalInfo" id="additionalInfo" class="form-control"
                                style="height: 50px; width: 100%;resize: none;" maxlength="503"
                                [(ngModel)]="additionalDetails.additionalInformation">
                        </textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 text-right" *ngIf="showSalary || isSuperUser">
                <div class="clearfix">
                    <button type="button" class="btn btn-danger mr-2" (click)="cancel()" [disabled]="savingFlag">
                        <span>Cancel</span>
                    </button>
                    <button type="submit" class="btn btn-success" (click)="updateAdditionalDetails()"
                        [disabled]="savingFlag">
                        <span *ngIf="!savingFlag">
                            Update
                        </span>
                        <span *ngIf="savingFlag">Updating..</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>
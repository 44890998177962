import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardPageComponent } from './dashboard-page.component';
import { RequisitionsModule } from 'src/app/features/requisitions/requisitions.module';

@NgModule({
  declarations: [DashboardPageComponent],
  imports: [
    CommonModule,
    RequisitionsModule
  ],
  exports: [],
  entryComponents: [DashboardPageComponent]
})

export class DashboardPageModule { }
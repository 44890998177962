import { Component, OnInit, Inject, Input } from '@angular/core';
import { StateService } from '@uirouter/angular';

@Component({
  selector: 'app-bulk-upload-page',
  templateUrl: './bulk-upload-page.component.html',
  styleUrls: ['./bulk-upload-page.component.css']
})
export class BulkUploadPageComponent implements OnInit {

  @Input() $transition$: any;

  stateParams: any;
  uploadLabel: any = '';
  uploadType: any = '';

  constructor(
    @Inject('$state') private $state: StateService
  ) { }

  ngOnInit() {
    this.stateParams = this.$transition$.params();
    this.uploadType = this.stateParams.uploadType;
    if (this.uploadType == "candidates") {
      this.uploadLabel = "Upload Candidates";
    } else if (this.uploadType == "employees") {
      this.uploadLabel = "Upload Employees";
    }
  }

  saveCallback() {
    if (this.uploadType == "candidates") {
      this.$state.go('missioncontrol.candidates');
    } else {
      this.$state.go('missioncontrol.employees', { reload: true });
    }
  }

  redirectToUrl(url, isReloadRequired) {
    this.$state.go(url, null, { reload: isReloadRequired });
  }

}

<div style="font-size: 13px;" *ngIf="!loadingFlag">
    <div class="row" *ngIf="candidateJobMatches.length > 0">
        <div class="row">
            <div class="col-xs-12">
                <div class="job-match-row">
                    <div class="candidate-job-match-header-text">
                        Rank
                    </div>
                    <div class="candidate-job-match-header-text">
                        Match Date
                    </div>
                    <div class="candidate-job-match-header-text">
                        Job Title
                    </div>
                    <div class="candidate-job-match-header-text">
                        Job Match Details
                    </div>
                    <div class="candidate-job-match-header-text">
                        {{clientOrBuLabel}}
                    </div>
                    <div class="candidate-job-match-header-text">
                        Status
                    </div>
                    <div class="candidate-job-match-header-text">
                        Req#
                    </div>
                    <div class="candidate-job-match-header-text" tooltip="Job Match Score">
                        Job Match Score
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12" *ngFor="let jobMatch of candidateJobMatches; let i = index;"
                [ngClass]="{'candidate-card-job-match-disabled-row': jobMatch.jobMatchDisabled, 'job-match-restricted-access': jobMatch.hasMeetingScheduleAndNoScopeForLoggedInUser}">
                <div class="job-match-row">
                    <div class="candidate-job-match-text text-left" tooltip="Job Match Rank" placement="top-left"
                        [ngClass]="{'job-match-popover-shared-requisition-color': jobMatch.sharedJob}">
                        <span [innerHtml]="jobMatch.jobMatchRank"></span>
                    </div>
                    <div class="candidate-job-match-text text-left" [ngClass]="{'job-match-popover-shared-requisition-color': jobMatch.sharedJob}"
                        [tooltip]="jobMatch.jobMatchDate | fourDotFiveDateFormatPipe">
                        <span [innerHtml]="jobMatch.jobMatchDate | fourDotFiveDateFormatPipe"></span>
                    </div>
                    <div class="candidate-job-match-text text-left" [tooltip]="jobMatch.title" [ngClass]="{'job-match-popover-shared-requisition-color': jobMatch.sharedJob}">
                        <span [innerHtml]="jobMatch.title"></span>
                    </div>
                    <div class="candidate-job-match-text text-left">
                        <app-candidate-card-icons context="candidatePossibleJobMatchesPopover" [candidate]="jobMatch"
                            [candidateAdditionalDetails]="jobMatch.candidateAdditionalDetailsCard"
                            [candidateSalaryDurationOptions]="salaryDurationOptions" [jobTypeList]="jobTypeList">
                        </app-candidate-card-icons>
                    </div>
                    <div class="candidate-job-match-text text-left" [tooltip]="jobMatch.jobDetailsText" [ngClass]="{'job-match-popover-shared-requisition-color': jobMatch.sharedJob}">
                        <span [innerHtml]="jobMatch.jobDetailsText"></span>
                    </div>
                    <div class="candidate-job-match-text text-left" [tooltip]="jobMatch.currentState" [ngClass]="{'job-match-popover-shared-requisition-color': jobMatch.sharedJob}">
                        <span [innerHtml]="jobMatch.currentState"></span>
                    </div>
                    <div class="candidate-job-match-text text-left"
                        tooltip="{{jobMatch.requisitionNumber}} ({{jobMatch.requisitionStatus}})">
                        <span (click)="goToWorkflow(jobMatch, false)" *ngIf="!isAssociateCandidates">
                            <span class="emerald cursor-pointer"
                                *ngIf="!jobMatch.jobMatchDisabled && !jobMatch.hasMeetingScheduleAndNoScopeForLoggedInUser">
                                {{jobMatch.requisitionNumber}}
                            </span>
                            <span
                                *ngIf="jobMatch.jobMatchDisabled || jobMatch.hasMeetingScheduleAndNoScopeForLoggedInUser">
                                {{jobMatch.requisitionNumber}}
                            </span>
                        </span>
                        <span *ngIf="isAssociateCandidates">
                            {{jobMatch.requisitionNumber}}
                        </span>
                        <span>
                            :: {{ jobMatch.requisitionStatus}}
                        </span>
                    </div>
                    <div class="candidate-job-match-text pl-2">
                        <span [innerHtml]="jobMatch.fourDotFiveIntelligentScore"
                            (click)="jobCompare(jobMatch)"
                            tooltip="Threshold score is {{jobMatch.fourDotFiveIntelligentThresholdScore}}"
                            class="candidate-job-match-card-intelligence-score-success cursor-pointer mr-2"
                            [ngClass]="{'dark-orange-bg': jobMatch.fourDotFiveIntelligentScore<jobMatch.fourDotFiveIntelligentThresholdScore, 'green-bg': jobMatch.fourDotFiveIntelligentScore>=jobMatch.fourDotFiveIntelligentThresholdScore, 'grey-bg': jobMatch.fourDotFiveIntelligentScore == 'N/A' }">
                        </span>
                        <span class="cursor-pointer" *ngIf="!jobMatch.jobMatchDisabled && !jobMatch.hasMeetingScheduleAndNoScopeForLoggedInUser && !isAssociateCandidates" (click)="goToWorkflow(jobMatch,true)">
                            <img src="assets/app-content/img/workflow3.svg" class="workflow-icon" alt="Go to filtered workflow" tooltip="Go to filtered workflow">
                            <span class="fas fa-filter workflow-filter-icon"></span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!isAssociateCandidates && candidateInfo.context != 'uploadResumesTransactionModal'">
            <div class="col-xs-12 text-center candidate-job-match-footer cursor-pointer">
                <a (click)="viewAllJobMatches()" class="popover-footer-link custom-popover-footer">View all job matches and perform actions</a>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="candidateJobMatches.length == 0">
        <div class="col-xs-12 text-center">
            No Job Matches found!
        </div>
        <div class="col-xs-12 text-center candidate-job-match-footer cursor-pointer" *ngIf="!isAssociateCandidates && candidateInfo.context != 'uploadResumesTransactionModal'">
            <a (click)="viewAllPossibleJobMatches()" class="popover-footer-link custom-popover-footer">View all possible job matches</a>
        </div>
    </div>
</div>

<div class="col-xs-12 text-center popover-loading-spinner" *ngIf="loadingFlag">
    <span>Loading</span>
    <span class="fa fa-spinner fa-spin"></span>
</div>
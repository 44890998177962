import { Inject, Injectable } from '@angular/core';

import { Aes } from 'browserify-aes/aes';
import { CookieService } from 'ngx-cookie-service';

// Cookies is pending

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    // cookieService = Inject(CookieService);

    storageKeyPrefix: any = 'myStorage-';
    prefixLength: any = this.storageKeyPrefix.length;
    constructor(@Inject(CookieService) public cookieService: CookieService,
    @Inject('$cookies') public $cookies: any) {
        this._init();
    }

    _init() {
        setInterval(() => {
            this.clearExceptionsStorage();
        }, 1000 * 60 * 15); //15 minutes
    }

    serializer(data) {
        let stringifiedData = JSON.stringify(data);
        return stringifiedData
    }

    deserializer(data) {
        let parsedData = _.isEmpty(data) ? data : (this._isJSONObject(data) ? JSON.parse(data) : data);
        return parsedData;
    }

    _isJSONObject(data) {
        let jsonObj = this.copy(data);
        try {
            JSON.parse(jsonObj);
            return true;
        } catch(error) {
            return false;
        }
    }

    copy(data) {
        let copiedData = angular.copy(data);
        return copiedData;
    }

    set(key, item, expires) {
        let expiryDate = null;
        if (angular.isNumber(expires)) {
            let time = new Date();
            time.setMinutes(time.getMinutes() + expires);
            let encrypted = Aes.Ctr.encrypt(time, 'spaneos$123', 256);
            angular.merge(item, { expire: encrypted });
        }
        // if(this.isSupported && this.isSupportedInIncognito) {
        window.localStorage.setItem(this.storageKeyPrefix + key, this.serializer(this.copy(item)));
        // } else {

        // }
    };

    get(key) {
        let obj = null;
        // if(this.isSupported && this.isSupportedInIncognito) {
        obj = this.copy(this.deserializer(window.localStorage.getItem(this.storageKeyPrefix + key)));
        // } else {

        // }
        if (obj !== null && angular.isDefined(obj.expire)) {
            let time = Date.parse(new Date().toDateString());
            let decrypted = Date.parse(Aes.Ctr.decrypt(obj.expire, 'spaneos$123', 256));
            // delete the key if it timed out
            if (decrypted < time) {
                this.remove(key);
                return null;
            }
        }
        return obj;
    }

    reset() {
        // if(this.isSupported && this.isSupportedInIncognito) {
        let localStorageLength = window.localStorage.length;
        for (let i = 0; i < localStorageLength; i++) {
            let key = window.localStorage.key(0);
            if (key.slice(0, this.prefixLength) == this.storageKeyPrefix) {
                window.localStorage.removeItem(key);
            }
        }
        this.set('baseurl', '', '');
        this.set('outOfPage', 'movingOut', '');
        // } else {

        // }
    }

    remove(key) {
        // if(this.isSupported && this.isSupportedInIncognito) {
        window.localStorage.removeItem(this.storageKeyPrefix + key);
        // } else {

        // }
    }

    extract(key) {
        let obj = null;
        // if(this.isSupported && this.isSupportedInIncognito) {
        obj = this.copy(this.deserializer(window.localStorage.getItem(this.storageKeyPrefix + key)));
        window.localStorage.removeItem(this.storageKeyPrefix + key);
        // } else {

        // }
        if (obj !== null && angular.isDefined(obj.expire)) {
            let time = Date.parse(new Date().toDateString());
            let decrypted = Date.parse(Aes.Ctr.decrypt(obj.expire, 'spaneos$123', 256));
            // delete the key if it timed out
            if (decrypted < time) {
                this.remove(key);
                return null;
            }
        }
        return obj;
    }

    /**
     * This Code is For Cookie Operations
     */

    setCookie(key, item, options) {
        // if(this.isSupported && this.isSupportedInIncognito) {
        // console.log(key, item, options)
        this.$cookies.put(this.storageKeyPrefix + key, this.serializer(this.copy(item)), options);
        // } else {

        // }
    }

    updateCookie(key, cookie) {
        // if(this.isSupported && this.isSupportedInIncognito) {
        cookie = this.copy(this.deserializer(this.cookieService.get(this.storageKeyPrefix + key)));
        // } else {

        // }
    }

    setCookieObject(key, item, options) {
        // if(this.isSupported && this.isSupportedInIncognito) {
        this.cookieService.set(this.storageKeyPrefix + key, this.copy(item), options);
        // } else {

        // }
    }

    getCookie(key) {
        let obj = null;
        // if(this.isSupported && this.isSupportedInIncognito) {
        // obj = this.copy(this.deserializer(this.cookieService.get(this.storageKeyPrefix + key)));
        obj = this.copy(this.deserializer(this.$cookies.get(this.storageKeyPrefix + key)));
        // } else {

        // }
        return obj;
    }

    getCookieObject(key) {
        let obj = null;
        // if(this.isSupported && this.isSupportedInIncognito) {
        obj = this.copy(this.cookieService.get(this.storageKeyPrefix + key));
        // } else {

        // }
        return obj;
    }

    getAllCookies() {
        let obj = null;
        // if(this.isSupported && this.isSupportedInIncognito) {
        obj = this.copy(this.deserializer(this.cookieService.getAll()));
        // } else {

        // }
        return obj;
    }

    removeCookie(key, options) {
        // if(this.isSupported && this.isSupportedInIncognito) {
        this.$cookies.remove(this.storageKeyPrefix + key);
        // } else {

        // }
    }

    resetCookies() {
        let cookieKeysArray = this.copy(Object.keys(this.copy(this.deserializer(this.$cookies.getAll()))));
        let cookieLength = this.copy(cookieKeysArray.length);
        for(let i = 0; i < cookieLength; i++) {
            this.$cookies.remove(cookieKeysArray[i]);
        }
    }

    resetBrowserStorage() {
        this.resetCookies();
        this.reset();
    }

    extractAllCookies(key, options) {
        let obj = null;
        // if(this.isSupported && this.isSupportedInIncognito) {
        obj = this.cookieService.getAll();
        //this.$cookies.remove(this.storageKeyPrefix + key);
        // } else {

        // }
        if (obj !== null) {

        }
    }

    clearExceptionsStorage() {
        window.localStorage.removeItem("exceptions");
    }
}

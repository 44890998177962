import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-info-icon',
  templateUrl: './info-icon.component.html',
  styleUrls: ['./info-icon.component.css']
})
export class InfoIconComponent implements OnInit {

  @Input() info;
  @Input() infoTitle;
  @Input() iconClass;
  @Input() isAddEditUser;

  constructor() {
    if (!this.iconClass) {
      this.iconClass = "text-primary";
    }
  }

  ngOnInit() {
    if(this.isAddEditUser) {
      this.info = this.info.split(',');
    }
  }


}

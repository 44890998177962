import { Component, OnInit, Inject, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { UserService } from 'src/app/core/services/user.service';
import { SharedAjsService } from 'src/app/public/app/services/sharedAjsService';

@Component({
  selector: 'app-validate-otp-page',
  templateUrl: './validate-otp-page.component.html',
  styleUrls: ['./validate-otp-page.component.css']
})
export class ValidateOtpPageComponent implements OnInit {
  @ViewChild('form.validateOtpForm', null) form: NgForm;

  @Input() $transition$: any;

  $stateParams: any;
  // form: any = {};
  otp: string = "";
  timeLeft: number = 300;
  errorMessage: string = "";
  showErrorMessage: boolean = false;
  userObject: any;
  message: any;
  timer: any;
  formattedTimeLeft: string;

  constructor(@Inject('$interval') public $interval: any,
    @Inject('sharedAjsService') public sharedAjsService: SharedAjsService,
    private userService: UserService,
    private alertsAndNotificationsService: AlertAndNotificationsService) {
  }

  ngOnInit() {
    this.$stateParams = this.$transition$.params();
    this.userObject = this.$stateParams.userObject;
    this._setFormattedTime();
    this._startTimer();
    if (this.$stateParams.message !== null) {
      this.message = angular.copy(this.$stateParams.message);
    }
  }

  _setFormattedTime() {
    let minutes = Math.floor(this.timeLeft / 60);
    let seconds = this.timeLeft % 60;
    let secondsInString: string = seconds.toString();
    if (seconds < 10) {
      secondsInString = "0" + secondsInString;
    }
    this.formattedTimeLeft = minutes + ":" + secondsInString;
  }

  _sendOtp() {
    this._startTimer();
    this.userService.sendOTP(this.userObject.id, (isSent) => {
      if (isSent) {
        this.alertsAndNotificationsService.showBannerMessage("OTP has been sent to your email", 'success');
      }
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  _startTimer() {
    this._stopTimer();
    this.timeLeft = 300;
    this._setFormattedTime();
    this.timer = this.$interval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        this._setFormattedTime();
      } else {
        this._stopTimer();
      }
    }, 1000);
  }

  _stopTimer() {
    this.$interval.cancel(this.timer);
  }

  validate() {
    this.showErrorMessage = false;
    this.userService.verifyOTP(this.otp, this.userObject.id, (isValid) => {
      if (isValid) {
        this.showErrorMessage = false;
        // this.userService.navigateUserAfterLogin(this.userObject);
        this.sharedAjsService.navigateUserAfterLogin(this.userObject, this.$stateParams.loginParamsObject);
      } else {
        this.showErrorMessage = true;
        this.errorMessage = "The OTP entered is invalid";
      }
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, "danger");
    });
  }

  resendOtp() {
    this._sendOtp();
  }

  onOtpEnter(event) {
    if (this.otp && this.otp.length < 6) {
      this.showErrorMessage = false;
    }
  }
}

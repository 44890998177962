import { Component, OnInit, Inject, Input } from '@angular/core';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { CandidateService } from 'src/app/core/services/candidate.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { JobService } from 'src/app/core/services/job.service';
import { SharedAjsService } from 'src/app/public/app/services/sharedAjsService';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.css']
})
export class DashboardPageComponent implements OnInit {

  @Input() $transition$:any;

  stateParams: any;
  userId: string;
  companyId: string;
  requisitionCount: any;
  candidateCounts: any;
  countsVisibilityFilter: string;
  //flags
  showDetails: boolean;
  isCorporate: boolean = false;
  candidateCountsLoadingFlag: boolean = true;
  requisitionCountsLoadingFlag: boolean = true;

  constructor(
    @Inject("$rootScope") public $rootScope,
    @Inject("$state") public $state,
    @Inject('sharedAjsService') public sharedAjsService: SharedAjsService,
    private candidateService: CandidateService,
    private jobService: JobService,
    private companyService: CompanyService,
    private alertsAndNotificationsService: AlertAndNotificationsService
  ) { }

  ngOnInit() {
    this.stateParams = this.$transition$.params();
    this.showDetails = this.$rootScope.userDetails.company.companyType !== 'Host';
    if (this.showDetails) {
      this.init();
    }
  }

  init() {
    this.userId = this.$rootScope.userDetails.id;
    this.companyId = this.$rootScope.userDetails.company.companyId;
    this.isCorporate = this.companyService.isCorporateCompany();
    this.requisitionCount = "";
    this.candidateCounts = {};
    this.countsVisibilityFilter = 'unassigned,assignedToMe,assignedToOthers';

    //flags
    this.candidateCountsLoadingFlag = true;
    this.requisitionCountsLoadingFlag = true;
    this.getCandidateCounts(() => {
      setTimeout(() => {
        this.candidateCountsLoadingFlag = false;
      }, 200);
    });
    this.getRequisitionsCount(() => {
      setTimeout(() => {
        this.requisitionCountsLoadingFlag = false;
      }, 200);
    });
    // If login params exists open documents tab after user login
    if(this.stateParams && this.stateParams.loginParamsObject && this.stateParams.loginParamsObject.redirect == 'documents') {
      this._openCandidateBookletDocumentsModal();
    }
  }

  _openCandidateBookletDocumentsModal() {
    let actionType = 'candidateBooklet';
    let actionLabel = this.stateParams.loginParamsObject.candidateName + "'s" + ' Booklet';
    this.sharedAjsService.goToCandidateDocuments(actionType, actionLabel, this.stateParams.loginParamsObject);
  }

  getCandidateCounts(successCallback) {
    this.candidateService.getCandidateCounts(this.userId, this.companyId, this.countsVisibilityFilter, (data) => {
      this.candidateCounts = data;
      if (successCallback) {
        successCallback();
      }
    }, (error) => {
      if (this.$rootScope.isOnline) {
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      }
    });
  }

  getRequisitionsCount(successCallback) {
    this.jobService.getJobCountByStatus(this.userId, this.companyId, (data) => {
      this.requisitionCount = data;
      if (successCallback) {
        successCallback();
      }
    }, (error) => {
      if (this.$rootScope.isOnline) {
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      }
    });
  }

  redirectToCandidates(candidateStatus) {
    let stateObject: any = {};
    stateObject.candidateStatuses = [];
    stateObject.candidateStatuses.push(candidateStatus);
    this.$state.go('missioncontrol.candidates', stateObject);
  }

  redirectToRequisitions() {
    let stateObject: any = {};
    stateObject.requisitionStatesArray = ['Open', 'Sourced', 'Filled', 'Joined', 'Paused'];
    this.$state.go('missioncontrol.requisitions', stateObject);
  }
}

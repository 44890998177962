<div class="approw">
    <div class="row">
        <div class="col-lg-12">
            <ol class="breadcrumb">
                <li><a (click)="redirectToUrl('missioncontrol.dashboard', true)">Home</a></li>
                <li class="active"><span>Manage Assessments</span></li>
            </ol>
            <h1 class="heading-css">Manage Assessments
                <div class="pull-right">
                    <i class="fa fa-list mr-2 cursor-pointer fontsize" tooltip="View Activities" container="body"
                        (click)="viewActivities()"></i>
                </div>
            </h1>

        </div>
    </div>
    <div class="row" *ngIf="allowSyncAssessments">
        <div class="col-xs-12" style="padding: 20px;">
            <!--<div class="main-box clearfix">-->
            <!--<div class="main-box-body clearfix" style="padding-top: 20px;">-->
            <!--<div class="row">-->
            <!--<div class="col-xs-12 col-md-8">-->
            <!-- <div class="pull-left">
                                <h2>
                                    <b>Sync Assessments</b>
                                </h2>
                            </div> -->
            <div class="pull-right">
                <div class="btn-group pull-right">
                    <button class="btn btn-primary" (click)="syncAssessments()"
                        [disabled]="disableSyncAssessmentsButtonFlag">
                        <span *ngIf="syncInProgressFlag">Syncing ...</span>
                        <span *ngIf="!syncInProgressFlag">Sync Assessments</span>
                    </button>
                </div>
            </div>
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-sm-12 col-md-12">
            <div class="main-box clearfix">
                <div class="main-box-body clearfix" style="margin-top: 20px;">
                    <div class="row">
                        <div class="col-xs-6">
                            <div class="row">
                                <div class="col-xs-12" style="height: 40px; padding-left: 30px; padding-right: 30px;">
                                    <div class="pull-left">
                                        <h2>
                                            <b>Available Assessments</b>
                                        </h2>
                                    </div>
                                    <div class="pull-right">
                                        <div class="btn-group pull-right">
                                            <button class="btn btn-primary" (click)="assignAssessments()"
                                                [disabled]="disableAssignAssessmentsButtonFlag">
                                                <span *ngIf="assignInProgressFlag">Assigning ...</span>
                                                <span *ngIf="!assignInProgressFlag">Assign Assessments
                                                    ({{assessmentsToAssign.length}})</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12">
                                    <div class="main-box table-responsive un-assigned-assessment-table"
                                        *ngIf="!unAssignedSyncInProgressFlag"
                                        style="padding: 5px; margin: 10px; height: 610px;">
                                        <table class="table" id="unAssignedAssessmentsTable" datatable="ng">
                                            <thead>
                                                <tr>
                                                    <th class="text-center">
                                                        Id
                                                    </th>
                                                    <th class="text-center">
                                                        Type
                                                    </th>
                                                    <th class="text-center" style="width: 30%;">
                                                        Name
                                                    </th>
                                                    <th class="text-center un-assigned-assessments-table">
                                                        Duration
                                                    </th>
                                                    <th class="text-center un-assigned-assessments-table">
                                                        Questions
                                                    </th>
                                                    <th class="un-assigned-assessments-table">
                                                        Select
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let assessment of unAssignedAssessments; let $index = index"
                                                    [ngClass]="{'unassigned-add-row': assessment.addFlag}">
                                                    <td class="text-center">{{assessment.testId}}</td>
                                                    <td class="text-center">{{assessment.testType}}</td>
                                                    <td class="text-center" style="width: 30%;">
                                                        <span tooltip="{{assessment.testName}}">
                                                            <span>{{assessment.testName}}</span>
                                                        </span>
                                                    </td>
                                                    <td class="text-center">{{assessment.duration}}</td>
                                                    <td class="text-center">{{assessment.questions}}</td>
                                                    <td class="text-center">
                                                        <input type="checkbox" id="assessmentAddCheckbox"
                                                            [(ngModel)]="assessment.addFlag"
                                                            (click)="addAssessment(assessment)" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="sync-loader-div text-center" *ngIf="unAssignedSyncInProgressFlag">
                                        <span class="mr-2">Loading</span>
                                        <span class="fa fa-spinner fa-spin"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-6">
                            <div class="row">
                                <div class="col-xs-12" style="height: 40px; padding-left: 30px; padding-right: 30px;">
                                    <div class="pull-left">
                                        <h2>
                                            <b>Assigned Assessments</b>
                                        </h2>
                                    </div>
                                    <div class="pull-right">
                                        <div class="btn-group pull-right">
                                            <button class="btn btn-primary" (click)="removeAssessments()"
                                                [disabled]="disableRemoveAssessmentsButtonFlag">
                                                <span *ngIf="removeInProgressFlag">Removing ...</span>
                                                <span *ngIf="!removeInProgressFlag">Remove Assessments
                                                    ({{assessmentsToRemove.length}})</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12">
                                    <div class="main-box table-responsive un-assigned-assessment-table"
                                        *ngIf="!assignedSyncInProgressFlag"
                                        style="padding: 5px; margin: 10px; height: 610px;">
                                        <table class="table" id="assignedAssessmentsTable" datatable="ng">
                                            <thead>
                                                <tr>
                                                    <th class="text-center">
                                                        Id
                                                    </th>
                                                    <th class="text-center">
                                                        Type
                                                    </th>
                                                    <th class="text-center" style="width: 30%;">
                                                        Name
                                                    </th>
                                                    <th class="text-center un-assigned-assessments-table">
                                                        Duration
                                                    </th>
                                                    <th class="text-center un-assigned-assessments-table">
                                                        Questions
                                                    </th>
                                                    <th class="un-assigned-assessments-table">
                                                        Remove
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let assessment of assignedAssessments; let $index = index;">
                                                    <td class="text-center">{{assessment.testId}}
                                                    </td>
                                                    <td class="text-center">{{assessment.testType}}
                                                    </td>
                                                    <td class="text-center" style="width: 30%;">
                                                        <span tooltip="{{assessment.testName}}">
                                                            {{assessment.testName}}
                                                        </span>
                                                        <i *ngIf="assessment.clientOrBu"
                                                            class="fa fa-info-circle lightblue"
                                                            tooltip="{{assessment.companyName}}"></i>
                                                    </td>
                                                    <td class="text-center">{{assessment.duration}}
                                                    </td>
                                                    <td class="text-center">{{assessment.questions}}
                                                    </td>
                                                    <td class="text-center">
                                                        <input type="checkbox" id="assessmentRemoveCheckbox"
                                                            [(ngModel)]="assessment.removeFlag"
                                                            (click)="openDeleteAssessmentModal(assessment)" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="sync-loader-div text-center" *ngIf="assignedSyncInProgressFlag">
                                        <span>Loading</span>
                                        <span class="fa fa-spinner fa-spin"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit, Inject, TemplateRef, Input, EventEmitter, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ModalConfig } from 'src/app/core/models/modalConfig';
import { SkillActivityFilter } from 'src/app/core/models/skillActivityFilter';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { DateTimeUtilityService } from 'src/app/core/services/date-time-utility.service';
import { JobService } from 'src/app/core/services/job.service';
import { ManageSkillsService } from 'src/app/core/services/manage-skills.service';
import { UserService } from 'src/app/core/services/user.service';
import { MESSAGECONSTANTS } from 'src/app/core/utils/constants';
import { SharedAjsService } from 'src/app/public/app/services/sharedAjsService';

@Component({
  selector: 'app-skills-entity-activities',
  templateUrl: './skills-entity-activities.component.html',
  styleUrls: ['./skills-entity-activities.component.css']
})
export class SkillsEntityActivitiesComponent implements OnInit {

  MESSAGECONSTANTS: any = MESSAGECONSTANTS;
  @Input() isSkillActivityModal;
  @Input() allSkillsActivitiesList;
  @Output() loadingCallBack = new EventEmitter<any>();

  viewActivitySkillModal: BsModalRef;
  viewMultiSkillUpdateTemplateModal: BsModalRef;

  startTime: any;
  startDate: any;
  endDate: any;
  endTime: any;
  activityFilter: any;
  activityFilterCopy: any;
  activities: any = [];
  users: any = [];
  activityTypes: any = [];
  uploadedSkills: any = [];
  multiUploadSkillActivityDetails: any = [];
  multiUploadSkillActivityHeading: String = "Updated";

  loadingFlag: boolean = true;
  isFiltersEnabled: boolean = false;

  constructor(
    private dateTimeUtilityService: DateTimeUtilityService,
    private jobService: JobService,
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private skillsService: ManageSkillsService,
    private userService: UserService,
    @Inject('sharedAjsService') public sharedAjsService: SharedAjsService,
    public bsModalService: BsModalService
  ) { }

  ngOnInit() {
    this.activityFilter = new SkillActivityFilter();
    this.activityFilterCopy = new SkillActivityFilter();

    this.resetFilters();
    this._getFilters();
    this._setSearchPanel();
    this.initializeDatePickers(() => {
    if(!this.isSkillActivityModal) {
      this.getActivities();
    } else {
      this.loadingFlag = true;
      const activities = this.allSkillsActivitiesList.results.map(activity => ({ ...activity }));
      let data = { skillActivities: activities };
      this.setActivities(data);
    }
    });
  }

  initializeDatePickers(successCallback) {
    ($(document) as any).ready(() => {
      let currentDate = new Date();

      ($('#startDate') as any).datepicker({
        format: "M d, yyyy",
        endDate: currentDate,
        weekStart: 1,
        autoclose: true
      });

      ($("#startTime") as any).timepicker("setTime", "12:00 AM");
      this.startTime = ($('#startTime') as any).val();

      ($('#endDate') as any).datepicker({
        format: "M d, yyyy",
        endDate: currentDate,
        weekStart: 1,
        autoclose: true
      });

      ($('#startTime') as any).timepicker({
        minuteStep: 5,
        showSeconds: false,
        showMeridian: true,
        disableFocus: true,
        defaultTime: '12:00 AM'
      });

      ($('#endTime') as any).timepicker({
        minuteStep: 5,
        showSeconds: false,
        showMeridian: true,
        disableFocus: true,
        defaultTime: '11:59 PM'
      });

      ($('#endTime') as any).timepicker("setTime", "11:59 PM");
      this.endTime = ($('#endTime') as any).val();


      ($('#startDate') as any).datepicker().on('changeDate', (e) => {
        this.startDate = ($('#startDate') as any).val();
        this.activityFilter.startDate = this.dateTimeUtilityService.getDateTimeInAPIFormat(new Date(this.startDate + ' ' + this.startTime));
        this.getActivities();
      });

      ($('#endDate') as any).datepicker().on('changeDate', (e) => {
        this.endDate = ($('#endDate') as any).val();
        this.activityFilter.endDate = this.dateTimeUtilityService.getDateTimeInAPIFormat(new Date(this.endDate + ' ' + this.endTime));
        this.getActivities();
      });

      ($('#startTime') as any).timepicker().on('changeTime.timepicker', (e) => {
        this.startTime = ($('#startTime') as any).val();
        this.activityFilter.startDate = this.dateTimeUtilityService.getDateTimeInAPIFormat(new Date(this.startDate + ' ' + this.startTime));
        setTimeout(() => {
          this.getActivities();
        }, 100);
      });

      ($('#endTime') as any).timepicker().on('changeTime.timepicker', (e) => {
        this.endTime = ($('#endTime') as any).val();
        this.activityFilter.endDate = this.dateTimeUtilityService.getDateTimeInAPIFormat(new Date(this.endDate + ' ' + this.endTime));
        setTimeout(() => {
          this.getActivities();
        }, 100);
      });
      if (successCallback) {
        successCallback();
      }
    });
  }

  _getFilters() {
    this.getActivityTypes();
    this.getUsers();
  }

  getActivityTypes() {
    this.jobService.getActivityTypesForFilter((data) => {
      data.forEach((activityType) => {
        if(activityType.value.includes('SKILL')) {
          this.activityTypes.push(activityType);
        }
      });
      this.activityTypes.forEach(activity => {
        if(activity.name == 'Skill Added') {
          activity.value = 'added'
        } else if(activity.name == 'Skill Updated') {
          activity.value = 'updated'
        } else if(activity.name == 'Skill Deleted') {
          activity.value = 'deleted'
        } else if(activity.name == 'Skill Uploaded') {
          activity.name = 'Skills Uploaded'
          activity.value = 'uploaded'
        }
      })
    }, (error) => {
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  _checkIfMultiDelete(activity) {
    if (activity.results && activity.results.length > 1) {
      return activity.results.every(result => result.operation === 'deleted');
    }
    return false;
  }

  ViewMultiSkillUpdateModal(activity, multiSkillUpdateTemplate: TemplateRef<any>) {
    this.multiUploadSkillActivityDetails = [];
    const config = new ModalConfig();
    config.ignoreBackdropClick = true;
    config.backdrop = true;
    const modalData: any = {};
    config.initialState = modalData;
    config.ignoreBackdropClick = true;
    this.multiUploadSkillActivityDetails = activity.results;
    if (activity.results[0].operation == 'deleted') {
      config.class = "custom-ngx-modal";
      this.multiUploadSkillActivityHeading = "Deleted";
    } else {
      config.class = "modal-lg custom-ngx-modal";
      this.multiUploadSkillActivityHeading = "Updated";
    }
    this.viewMultiSkillUpdateTemplateModal = this.bsModalService.show(multiSkillUpdateTemplate, config);
  }

  _setActivityMessageBadges(activity) {
    if (activity.results && activity.results.length > 0) {
      activity.results.forEach(result => {
        this.setSkillBadges(result);
      });
    }
  }

  setActivities(data:any ) {
    this.activities = [];
    if (data && data.skillActivities) {
      let activities = [];
      data.skillActivities.forEach(activity => {
        if (this.isSkillActivityModal) {
          this.setSkillBadges(activity);
          activity.activityDisplayValue = this._getSkillText([activity]);
          activity.mainText = this._getCreatedByText(activity);
          activities.push(activity);
        } else {
          if (activity.activityOriginatorType == 'SCREEN') {
            this._setActivityMessageBadges(activity);
            if (activity.results && activity.results.length > 0) {
              activity.mainText = this._getCreatedByText(activity.results[0]);
              activity.operation = activity.results[0].operation;
              if (activity.multiUpdateFlow && activity.results.length > 1) {
                let updatedActivities = [];
                activity.results.forEach(skill => {
                  if (skill.operation == 'update') {
                    updatedActivities.push(`<span class="badge badge-primary">${skill.skill}</span>`);
                  }
                });
                let updatedSkillActivityMessage = updatedActivities.length > 10 ? `${updatedActivities.slice(0, 10).join(" ")} ...` : updatedActivities.join(" ");
                activity.activityDisplayValue = `${updatedActivities.length} Skills Updated : ${updatedSkillActivityMessage}`;
              } else if (this._checkIfMultiDelete(activity)) {
                activity.multiUpdateFlow = true;
                let deletedSkills = [];
                activity.results.forEach(skill => {
                  deletedSkills.push(`<span class="badge badge-danger">${skill.skill}</span>`);
                });
                let deletedSkillActivityMessage = deletedSkills.length > 10 ? `${deletedSkills.slice(0, 10).join(" ")} ...` : deletedSkills.join(" ");
                activity.activityDisplayValue = `${activity.results.length} Skills Deleted: ${deletedSkillActivityMessage}`;
              } else {
                activity.activityDisplayValue = this._getSkillText(activity.results);
              }
              activities.push(activity);
            }
          } else {
            activity.activityDisplayValue = `File Uploaded <span class="badge badge-primary">${activity.originalFileName}</span>`;
            activity.mainText = this._getCreatedByText(activity);
            activities.push(activity);
          }
        }
      });
      this.activities = [...activities];
    } else {
      this.activities = [];
    }
    this.loadingFlag = false;
    if (this.loadingCallBack) {
      this.loadingCallBack.emit(this.loadingFlag);
    }
  }

  setSkillBadges(result) {
    let badge = "badge-primary";
    if (result.operation == 'added') {
      badge = "badge-success";
    } else if (result.operation == 'deleted' || result.operation == 'error') {
      badge = "badge-danger";
      if (result.activityMessage && result.activityMessage != "") {
        result.activityMessage = result.activityMessage.charAt(0).toUpperCase() + result.activityMessage.slice(1);
      }
    }
    if (result.activityMessage && result.activityMessage != "") {
      result.activityMessage = result.activityMessage.replace(/"([^"]*)"/g, `<span class="badge ${badge}">$1</span>`);
    }
  }

  getActivities() {
    this.setFilters();
    this.loadingFlag = true;
    if (this.activityFilter.operation == 'uploaded') {
      this.activityFilter.activityOriginatorType = 'FILE';
    } else if (this.activityFilter.operation != '') {
      this.activityFilter.activityOriginatorType = 'SCREEN';
    } else {
      this.activityFilter.activityOriginatorType = '';
    }
    this.skillsService.getAllActivities(this.activityFilter, (data) => {
      this.setActivities(data);
    }, (error) => {
      this.loadingFlag = false;
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  _getCreatedByText(activity) {
    return activity.createdBy !== '' ?
      `<span> by <span class="emerald"><i>${activity.createdBy}</i></span></span>`
      : '';
  }

  _getSkillText(activities) {
    let activity:any = {
      activityMessage: ""
    };

    activities.forEach((data, key) => {
      let addBreak = "";
      if(data && data.activityMessage) {
        addBreak = activities.length > 1 ? '<br>' : "";
        activity.activityMessage = activity.activityMessage + data.activityMessage + addBreak;
      }
    });

    return activity.activityMessage;
  }

  getUsers() {
    this.userService.getCompanyUsersForActivities((data) => {
      this.users = data;
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  resetFilters() {
    this.activityFilter.activityOriginatorType = "";
    this.activityFilter.operation = "";
    this.activityFilter.performedBy = "";
    this.activityFilter.startDate = "";
    this.activityFilter.endDate = "";
    this.startDate = '';
    this.endDate = '';
    this._resetDateTimePicker();
  }

  setFilters() {
    //settings filter 
    if (this.activityFilter.startDate == 'Invalid date') {
      delete this.activityFilter.startDate;
      delete this.activityFilterCopy.startDate;
    } else {
      this.activityFilterCopy.startDate = this.activityFilter.startDate;
    }
    if (this.activityFilter.endDate == 'Invalid date') {
      delete this.activityFilter.endDate;
      delete this.activityFilterCopy.endDate;
    } else {
      this.activityFilterCopy.endDate = this.activityFilter.endDate;
    }
    if ((this.activityFilterCopy.activityOriginatorType == "" || !this.activityFilterCopy.activityOriginatorType)
      && (this.activityFilterCopy.operation == "" || !this.activityFilterCopy.operation)
      && (this.activityFilterCopy.performedBy == "" || !this.activityFilterCopy.performedBy)
      && (this.activityFilterCopy.startDate == "" || !this.activityFilterCopy.startDate)
      && (this.activityFilterCopy.endDate == "" || !this.activityFilterCopy.endDate))
       {
      this.isFiltersEnabled = false;
    } else {
      this.isFiltersEnabled = true;
    }
  }

  _resetDateTimePicker() {
    ($("#startTime") as any).timepicker("setTime", "12:00 AM");
    ($('#endTime') as any).timepicker("setTime", "11:59 PM");
  }

  expandText($event, activity) {
    if (activity.showShortMessageFlag) {
      activity.showShortMessageFlag = false;
    } else {
      activity.showShortMessageFlag = true;
    }
    $event.stopPropagation();
  }

  _setSearchPanel() {
    $(document).ready(() => {
      $("#search-panel").hide();
      $(document).off("click", "#open-filter").on("click", "#open-filter",
        () => {
          $("#search-panel").animate({
            height: 'toggle'
          });
        });
    });
  }

  viewUploadSkills(uploadedSkills, template:TemplateRef<any>) {
    this.uploadedSkills = uploadedSkills;
    const config = new ModalConfig();
    config.ignoreBackdropClick = true;
    config.backdrop = true;
    const modalData: any = {};
    config.class = "custom-modal-xl custom-ngx-modal";
    config.initialState = modalData;
    config.ignoreBackdropClick = true;
    this.viewActivitySkillModal = this.bsModalService.show(template, config);
  }

}

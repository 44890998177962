import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddEditEmployeeComponent } from './add-edit-employee.component';
import { FormsModule } from '@angular/forms';
import { FourDotFivePhoneNumberModule } from 'src/app/shared/four-dot-five-phone-number/four-dot-five-phone-number.module';
import { TagInputModule } from 'ngx-chips';

@NgModule({
  declarations: [AddEditEmployeeComponent],
  imports: [
    CommonModule,
    FormsModule,
    FourDotFivePhoneNumberModule,
    TagInputModule
  ],
  exports: [AddEditEmployeeComponent],
  entryComponents: [AddEditEmployeeComponent]
})
export class AddEditEmployeeModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CancelAssessmentComponent } from './cancel-assessment.component';
import { ExternalNotesModule } from '../../external-notes/external-notes.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [CancelAssessmentComponent],
  imports: [
    CommonModule,
    FormsModule,
    ExternalNotesModule
  ],
  exports: [CancelAssessmentComponent],
  entryComponents: [CancelAssessmentComponent]
})
export class CancelAssessmentModule { }

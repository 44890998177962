<div class="approw">
    <div class="row">
        <div class="col-lg-12">
            <ol class="breadcrumb">
                <li><a (click)="redirectToUrl('missioncontrol.dashboard', true)">Home</a></li>
                <li class="active" *ngIf="!isLoading">
                    <span *ngIf="isLoggedInUser(transactionNotificationPreferences.user.userDetails)">My
                        Notification Preferences</span>
                    <span *ngIf="!isLoggedInUser(transactionNotificationPreferences.user.userDetails)">Notification
                        Preferences</span>
                </li>
            </ol>
            <h1 class="heading-css">
                <span *ngIf="isLoading" class="fa fa-spinner fa-spin"></span>
                <span *ngIf="!isLoading">
                    <span *ngIf="isLoggedInUser(transactionNotificationPreferences.user.userDetails)">My
                        Notification Preferences</span>
                    <span *ngIf="!isLoggedInUser(transactionNotificationPreferences.user.userDetails)">
                        Edit
                        '<i
                            [innerHTML]="handleLongName(getFullName(transactionNotificationPreferences.user.userDetails.firstName,transactionNotificationPreferences.user.userDetails.lastName),50)"></i>'s
                        Notification Preferences
                    </span>
                </span>
            </h1>
        </div>
    </div>
    <div class="row" id="user-profile">
        <div class="main-box clearfix">
            <div *ngIf="isLoading" style="height: 400px; font-size: 22px;"
                class="d-flex justify-content-center align-items-center">
                <span class="pr-2">Loading</span>
                <span class="fa fa-spinner fa-spin"></span>
            </div>
            <div *ngIf="!isLoading">
                <div style="margin-top: 2%;">
                    <div class="col-lg-offset-4 col-lg-2 col-md-offset-5 col-md-2 col-sm-offset-6 col-sm-2 text-center">
                        <label class="labelHeadings"> Application </label>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2 text-center">
                        <label class="labelHeadings"> Email </label>
                    </div>
                </div>
                <div id="tab-activity" *ngIf="transactionNotificationPreferences">
                    <form #notificationPrefsForm="ngForm" novalidate>
                        <div *ngFor="let group of transactionNotificationPreferences.groups; let i = index">
                            <ul class="package-features notList clearfix">
                                <h2 class="nothead">{{group.name}}</h2>
                                <div *ngFor="let orderedPreference of group.orderedNotificationPreferences; let i = index"
                                    style="margin-left: 10px;">
                                    <h2 class="nothead">{{orderedPreference.notificationPreferenceType}}</h2>
                                    <li style="display:inline-block; width: 100%;"
                                        *ngFor="let preference of orderedPreference.notificationPreferencesListDtos; let $index = index">
                                        <div class="row">
                                            <div class="col-sm-6 col-md-5 col-lg-4">
                                                <h5 [ngClass]="{'disabledpreference' : !preference.enabled }"
                                                    id="preferencename">{{preference.name}}</h5>
                                            </div>
                                            <div class="col-lg-2 col-md-2 col-sm-2 align-items-center">
                                                <div class="onoffswitch onoffswitch-success topheight"
                                                    [ngClass]="{'disabledpreference preference-disable-cursor' : !preference.enabled }">
                                                    <input type="checkbox" name="{{preference.name}}_App_{{$index}}"
                                                        class="onoffswitch-checkbox"
                                                        id="{{preference.name}}_App_{{$index}}"
                                                        [(ngModel)]="preference.onForApp"
                                                        [disabled]="(!preference.enabled) || (!preference.enabledForApp)">
                                                    <label class="onoffswitch-label lbl"
                                                        [ngClass]="{'disabledToggle preference-disable-cursor' : (!preference.enabled) || (!preference.enabledForApp)}"
                                                        for="{{preference.name}}_App_{{$index}}">
                                                        <div class="onoffswitch-inner"
                                                            [ngClass]="{'toggleDisabled' : (!preference.enabled) || (!preference.enabledForApp)}">
                                                        </div>
                                                        <div class="onoffswitch-switch swch"></div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-md-2 col-sm-2 align-items-center">
                                                <div class="onoffswitch onoffswitch-success topheight"
                                                    [ngClass]="{'disabledpreference preference-disable-cursor' : !preference.enabled }">
                                                    <input type="checkbox" name="{{preference.name}}_Email_{{$index}}"
                                                        class="onoffswitch-checkbox"
                                                        id="{{preference.name}}_Email_{{$index}}"
                                                        [(ngModel)]="preference.onForEmail"
                                                        [disabled]="(!preference.enabled) || (!preference.enabledForEmail)">
                                                    <label class="onoffswitch-label lbl"
                                                        [ngClass]="{'disabledToggle preference-disable-cursor' : (!preference.enabled) || (!preference.enabledForEmail)}"
                                                        for="{{preference.name}}_Email_{{$index}}">
                                                        <div class="onoffswitch-inner"
                                                            [ngClass]="{'toggleDisabled' : (!preference.enabled) || (!preference.enabledForEmail)}">
                                                        </div>
                                                        <div class="onoffswitch-switch swch"></div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="(preference.message !== null)">
                                            <div class="col-sm-9 col-md-8 col-lg-7">
                                                <small class="disabledpreference" id="preferencemessage"><i> {{"Note: "
                                                        +
                                                        preference.message }} </i></small>
                                            </div>
                                        </div>
                                    </li>
                                </div>
                            </ul>
                        </div>
                        <div class="col-md-offset-8 col-lg-offset-6 text-center ">
                            <div class="btn-group-single  wbcbuttons">
                                <button class="btn btn-danger btn-cancel mr-2" (click)="cancelChanges()"
                                    type="button">Cancel</button>
                                <button class="btn wizard-next btn-primary" (click)="saveNotificationPreferences()"
                                    type="submit">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
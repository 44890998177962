import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ForgotPasswordPageComponent } from './forgot-password-page.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [ForgotPasswordPageComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  entryComponents: [ForgotPasswordPageComponent],
})
export class ForgotPasswordPageModule { }

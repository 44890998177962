import { Component, OnInit, Input, Inject } from '@angular/core';
import { ActivityFilter } from 'src/app/core/models/activityFilter';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AssessmentService } from 'src/app/core/services/assessment.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CandidateService } from 'src/app/core/services/candidate.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { DateTimeUtilityService } from 'src/app/core/services/date-time-utility.service';
import { EntityActivitiesService } from 'src/app/core/services/entity-activities.service';
import { JobService } from 'src/app/core/services/job.service';
import { SettingService } from 'src/app/core/services/setting.service';
import { UserService } from 'src/app/core/services/user.service';
import { RequisitionService } from 'src/app/public/app/services/requisitionService';
import { MESSAGECONSTANTS } from 'src/app/core/utils/constants';
import { SharedAjsService } from 'src/app/public/app/services/sharedAjsService';

@Component({
  selector: 'app-entity-activities',
  templateUrl: './entity-activities.component.html',
  styleUrls: ['./entity-activities.component.css']
})
export class EntityActivitiesComponent implements OnInit {

  @Input() candidateId;
  @Input() jobMatchId;
  @Input() jobId;
  @Input() userId;
  @Input() companyId;
  @Input() candidateName;
  @Input() userName;
  @Input() requisitionNumber;
  @Input() jobTitle;
  @Input() clientOrBuName;
  @Input() isSharedClient;
  @Input() requisitionInfo;
  @Input() context;
  @Input() activityMap;

  MESSAGECONSTANTS: any = MESSAGECONSTANTS;

  startTime: any;
  startDate: any;
  endDate: any;
  endTime: any;
  activityTypes: any = [];
  activities: any = [];
  clientsOrBu: any = [];
  requisitionIds: any = [];
  requisitionIdsCopy: any = [];
  uniqueRoles: any = [];
  allRoles: any = [];
  users: any = [];
  companies: any = [];
  companyType: any = null;
  activityFilter: any;
  activityFilterCopy: any;
  isHost: any;
  loadingFlag: boolean = true;
  isFiltersEnabled: boolean = false;

  constructor(
    @Inject('$rootScope') public $rootScope,
    @Inject('requisitionService') private requisitionService: RequisitionService,
    private companyService: CompanyService,
    private authService: AuthService,
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private dateTimeUtilityService: DateTimeUtilityService,
    private jobService: JobService,
    private candidateService: CandidateService,
    private activitiesService: EntityActivitiesService,
    private userService: UserService,
    private settingService: SettingService,
    private assessmentService: AssessmentService,
    @Inject('sharedAjsService') public sharedAjsService: SharedAjsService
  ) { }

  ngOnInit() {
    this.activityFilter = new ActivityFilter();
    this.activityFilterCopy = new ActivityFilter();

    if (_.isUndefined(this.activityMap)) {
      this.activityMap = [];
    }
    if (_.isUndefined(this.isSharedClient)) {
      this.isSharedClient = false;
    }

    this.isHost = this.companyService.isHostCompany();
    this.companyType = this.authService.getUserDetails().company.companyType;
    if (this.context == 'clientOrBuSettings') {
      this.activityFilter.companyId = this.companyId;
    } else {
      this.activityFilter.companyId = this.authService.getUserDetails().company.companyId;
    }
    // this.initializeDatePickers();
    this.getActivities = _.debounce(this.getActivities, 500);

    if (this.isHost) {
      this._getCompanies();
    }
    this.resetCandidateFilters();
    this._getFilters();

    this._setSearchPanel();
    this.initializeDatePickers(() => {
      this.getActivities();
    });
  }

  _getCompanies() {
    this.companyService.getAllCompanies((data) => {
      this.companies = data;
    }, error => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  initializeDatePickers(successCallback) {
    ($(document) as any).ready(() => {
      let currentDate = new Date();

      ($('#startDate') as any).datepicker({
        format: "M d, yyyy",
        endDate: currentDate,
        weekStart: 1,
        autoclose: true
      });

      ($("#startTime") as any).timepicker("setTime", "12:00 AM");
      this.startTime = ($('#startTime') as any).val();

      ($('#endDate') as any).datepicker({
        format: "M d, yyyy",
        endDate: currentDate,
        weekStart: 1,
        autoclose: true
      });

      ($('#startTime') as any).timepicker({
        minuteStep: 5,
        showSeconds: false,
        showMeridian: true,
        disableFocus: true,
        defaultTime: '12:00 AM'
      });

      ($('#endTime') as any).timepicker({
        minuteStep: 5,
        showSeconds: false,
        showMeridian: true,
        disableFocus: true,
        defaultTime: '11:59 PM'
      });

      ($('#endTime') as any).timepicker("setTime", "11:59 PM");
      this.endTime = ($('#endTime') as any).val();


      ($('#startDate') as any).datepicker().on('changeDate', (e) => {
        this.startDate = ($('#startDate') as any).val();
        this.activityFilter.startDate = this.dateTimeUtilityService.getDateTimeInAPIFormat(new Date(this.startDate + ' ' + this.startTime));
        this.getActivities();
      });

      ($('#endDate') as any).datepicker().on('changeDate', (e) => {
        this.endDate = ($('#endDate') as any).val();
        this.activityFilter.endDate = this.dateTimeUtilityService.getDateTimeInAPIFormat(new Date(this.endDate + ' ' + this.endTime));
        this.getActivities();
      });

      ($('#startTime') as any).timepicker().on('changeTime.timepicker', (e) => {
        this.startTime = ($('#startTime') as any).val();
        this.activityFilter.startDate = this.dateTimeUtilityService.getDateTimeInAPIFormat(new Date(this.startDate + ' ' + this.startTime));
        setTimeout(() => {
          this.getActivities();
        }, 100);
      });

      ($('#endTime') as any).timepicker().on('changeTime.timepicker', (e) => {
        this.endTime = ($('#endTime') as any).val();
        this.activityFilter.endDate = this.dateTimeUtilityService.getDateTimeInAPIFormat(new Date(this.endDate + ' ' + this.endTime));
        setTimeout(() => {
          this.getActivities();
        }, 100);
      });
      if (successCallback) {
        successCallback();
      }
    });
  }

  _getFilters() {
    this.getActivityTypes();
    this.getUsers();
    if (this.context.includes('candidate')) {
      this.getCandidateClientsOrBu();
      this.getCandidateJobs();
    }
  }

  getActivityTypes() {
    this.jobService.getActivityTypesForFilter((data) => {
      data.forEach((activityType) => {
        if ((this.context.includes('candidate') && (activityType.value.startsWith("CANDIDATE_") || activityType.value.includes("CHIME_COMPLETED"))) ||
          (this.context === 'user' && activityType.value.startsWith("USER_")) ||
          (this.context === 'employee' && activityType.value.startsWith("EMPLOYEE_")) ||
          (this.context === 'assessments' && activityType.value.startsWith("ASSESSMENT_")) ||
          ((this.context === 'companySettings' || this.context === 'clientOrBuSettings') && activityType.value.startsWith("SETTINGS_"))) {
          this.activityTypes.push(activityType);
        }
      });
    }, (error) => {
      if (this.$rootScope.isOnline) {
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      }
    });
  }

  getActivities() {
    if (this.context === 'candidate' || this.context === 'candidateJobMatch') {
      this.setFilters();
      this.loadingFlag = true;
      this.candidateService.getCandidateActivities(this.activityFilterCopy, (data) => {
        this.activities = this.activitiesService.getMassagedActivities(data, this.activityMap, this.context, null);
        this.loadingFlag = false;
      }, (error) => {
        if (this.$rootScope.isOnline) {
          this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
        }
      });
    } else if (this.context == 'requisition') {
      this.loadingFlag = true;
      this.requisitionService.getRequisitionActivity(this.jobId, 1, 250, (data) => {
        this.activities = this.activitiesService.getMassagedActivities(data, this.activityMap, this.context, this.requisitionInfo);
        this.loadingFlag = false;
      }, (error) => {
        if (this.$rootScope.isOnline) {
          this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
        }
      });
    }
    else if (this.context === 'user' || this.context === 'employee') {
      this.setFilters();
      this.loadingFlag = true;
      this.userService.getUserActivities(this.activityFilterCopy, (data) => {
        this.activities = this.activitiesService.getMassagedActivities(data, this.activityMap, this.context, null);
        this.loadingFlag = false;
      }, (error) => {
        if (this.$rootScope.isOnline) {
          this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
        }
      });
    } else if (this.context == 'clientOrBuSettings' || this.context == 'companySettings') {
      this.setFilters();
      this.loadingFlag = true;
      this.settingService.getSettingsActivity(this.activityFilterCopy, this.isSharedClient, (data) => {
        this.activities = this.activitiesService.getMassagedActivities(data, this.activityMap, this.context, null);
        this.loadingFlag = false;
      }, (error) => {
        if (this.$rootScope.isOnline) {
          this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
        }
      });
    } else if (this.context == 'assessments') {
      this.setFilters();
      this.loadingFlag = true;
      this.assessmentService.getAssessmentsActivity(this.activityFilterCopy, (data) => {
        this.activities = this.activitiesService.getMassagedActivities(data, this.activityMap, this.context, null);
        this.loadingFlag = false;
      }, (error) => {
        if (this.$rootScope.isOnline) {
          this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
        }
      });
    }

  }

  getCandidateClientsOrBu() {
    this.candidateService.getCandidateClientsOrBu(this.candidateId, (data) => {
      _.forOwn(data, (val, key) => {
        this.clientsOrBu.push({ id: key, val: val });
      });
    }, (error) => {
      if (this.$rootScope.isOnline) {
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      }
    });
  }

  getCandidateJobs() {
    this.candidateService.getCandidateJobs(this.candidateId, (data) => {
      _.forOwn(data, (val, key) => {
        this.requisitionIds.push({ id: key, requisitionNumber: val.requisitionNumber, role: val.role });
        this.allRoles.push(val.role);
      });
      this.requisitionIdsCopy = this.requisitionIds;
      $.each(this.allRoles, (i, el) => {
        if ($.inArray(el, this.uniqueRoles) === -1) {
          this.uniqueRoles.push(el);
        }
      });

    }, (error) => {
      if (this.$rootScope.isOnline) {
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      }
    });
  }

  getUsers() {
    this.userService.getCompanyUsersForActivities((data) => {
      this.users = data;
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  onRoleChange() {
    let currentRequisitions = this.requisitionIds;
    this.requisitionIds = this.requisitionIdsCopy;
    if (this.activityFilter.title !== "any") {
      this.requisitionIds = this.requisitionIds.filter((requisition) => { return requisition.role === this.activityFilter.title });
      let isRolePresent = false;
      currentRequisitions.forEach((requisitionId) => {
        if (requisitionId.role == this.activityFilter.title)
          isRolePresent = true;
      });
      if (!isRolePresent) {
        this.activityFilter.requisitionId = "any";
      }
    }
    this.getActivities();
  }

  resetCandidateFilters() {
    this.activityFilter.activityType = "any";
    this.activityFilter.clientOrBuId = "any";
    this.activityFilter.requisitionId = "any";
    this.activityFilter.title = "any";
    this.activityFilter.createdBy = "any";
    this.startDate = '';
    this.endDate = '';
    this.requisitionIds = this.requisitionIdsCopy;
    this._resetDateTimePicker();
  }

  _resetDateTimePicker() {
    ($("#startTime") as any).timepicker("setTime", "12:00 AM");
    ($('#endTime') as any).timepicker("setTime", "11:59 PM");
  }

  expandText($event, activity) {
    if (activity.showShortMessageFlag) {
      activity.showShortMessageFlag = false;
    } else {
      activity.showShortMessageFlag = true;
    }
    $event.stopPropagation();
  }

  setFilters() {

    this.activityFilter.candidateId = this.candidateId;
    this.activityFilter.jobMatchId = this.jobMatchId;
    this.activityFilter.page = 1;
    this.activityFilter.size = 20000;
    this.activityFilter.userId = this.userId;
    //settings filter 
    this.activityFilter.isClientCompanySettingsActivity = false;

    this.activityFilterCopy = _.cloneDeep(this.activityFilter);
    if (this.activityFilter.activityType === "any") {
      this.activityFilterCopy.activityType = null;
    }
    if (this.activityFilter.clientOrBuId === "any") {
      this.activityFilterCopy.clientOrBuId = null;
    }
    if (this.activityFilter.title === "any") {
      this.activityFilterCopy.title = null;
    }
    if (this.activityFilter.requisitionId === "any") {
      this.activityFilterCopy.requisitionId = null;
    }
    if (this.activityFilter.createdBy === "any") {
      this.activityFilterCopy.createdBy = null;
    }
    if (this.activityFilter.startDate == 'Invalid date') {
      delete this.activityFilter.startDate;
      delete this.activityFilterCopy.startDate;
    } else {
      this.activityFilterCopy.startDate = this.activityFilter.startDate;
    }
    if (this.activityFilter.endDate == 'Invalid date') {
      delete this.activityFilter.endDate;
      delete this.activityFilterCopy.endDate;
    } else {
      this.activityFilterCopy.endDate = this.activityFilter.endDate;
    }
    if (this.activityFilterCopy.activityType == null
      && this.activityFilterCopy.clientOrBuId == null
      && this.activityFilterCopy.createdBy == null
      && this.activityFilterCopy.requisitionId == null
      && this.activityFilterCopy.title == null
      && this.activityFilterCopy.startDate == undefined
      && this.activityFilterCopy.endDate == undefined) {
      this.isFiltersEnabled = false;
    } else {
      this.isFiltersEnabled = true;
    }
  }

  _setSearchPanel() {
    $(document).ready(() => {
      $("#search-panel").hide();
      $(document).off("click", "#open-filter").on("click", "#open-filter",
        () => {
          $("#search-panel").animate({
            height: 'toggle'
          });
        });
    });
  }

  goToCandidateDocuments(activity) {
    let actionType = 'emailNotification';
    let actionLabel = 'Details of Documents Emailed';
    let documentDtoMap = {
      listOfDocumentsSent: activity.listOfDocumentsSent,
      listOfUsersDocumentsSent: activity.listOfUsersDocumentsSent
    }
    this.sharedAjsService.goToCandidateDocuments(actionType, actionLabel, documentDtoMap);
  }

}

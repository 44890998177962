import { Inject, Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AssessmentService {

  urlConstants: any = {
    GET_ALL_TECH_ASSESSMENTS: 'api/assessment/getalltechassessments',
    GET_ALL_VALUE_ASSESSMENTS: 'api/assessment/getallvalueassessments',
    GET_ALL_UNASSIGNED_ASSESSSMENTS: 'api/assessment/getallunassignedassessments',
    SYNC_ASSESSMENTS: 'api/assessment/syncassessments',
    ASSIGN_ASSESSMENTS: 'api/assessment/assignassessments',
    REMOVE_ASSIGNMENT: 'api/assessment/removeassignedassessment',
    GET_CANDIDATE_ASSESSMENT_DETAILS: 'api/candidate/getcandidateassessmentdetail',
    GET_SENT_ASSESSMENT_INFO: 'api/candidate/sentassessmentdetails',
    GET_ASSESSMENT_DETAILS: 'api/assessment/getassessmentdetails',
    GET_ASSESSMENT_ACTIVITIES: 'api/assessment/getassessmentactivity',
  };

  constructor(@Inject(StorageService) private StorageService:StorageService, @Inject(GenericService) private genericService:GenericService) { }

  getAllTechAssessments(companyId, clientOrBuId, isJobWorkflow, successCallback, errorCallback) {
    let url = this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_TECH_ASSESSMENTS + '/' + companyId;
    if (!_.isNull(clientOrBuId)) {
      url = url + '?clientOrBuId=' + clientOrBuId + '&isJobWorkflow=' + isJobWorkflow;
    } else {
      url = `${url}?isJobWorkflow=${isJobWorkflow}`;
    }
    this.genericService.getObjects(url).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllValueAssessments(companyId, clientOrBuId, isJobWorkflow, successCallback, errorCallback) {
    let url = this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_VALUE_ASSESSMENTS + '/' + companyId;
    if (!_.isNull(clientOrBuId)) {
      url = url + '?clientOrBuId=' + clientOrBuId + '&isJobWorkflow=' + isJobWorkflow;
    } else {
      url = `${url}?isJobWorkflow=${isJobWorkflow}`;
    }
    this.genericService.getObjects(url).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  syncAssessments(companyId, successCallback, errorCallback) {
    let url = this.StorageService.get('baseurl') + this.urlConstants.SYNC_ASSESSMENTS;
    if (!_.isUndefined(companyId) && !_.isNull(companyId)) {
      url += ('?companyId=' + companyId);
    }
    this.genericService.putObject(url, null).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  assignAssessments(assignArray, successCallback, errorCallback) {
    this.genericService.putObject(this.StorageService.get('baseurl') + this.urlConstants.ASSIGN_ASSESSMENTS, assignArray).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllUnassignedAssessments(companyId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_UNASSIGNED_ASSESSSMENTS + '/' + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  removeAssignment(companyId, assessmentId, buId, successCallback, errorCallback) {
    let url = this.StorageService.get('baseurl') + this.urlConstants.REMOVE_ASSIGNMENT + '/' + companyId + '/' + assessmentId;
    if (buId !== null) {
      url = `${url}?clientOrBuId=${buId}`;
    }
    this.genericService.deleteObject(url, null).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getCandidateAssessmentDetails(assessmentDetailsObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_CANDIDATE_ASSESSMENT_DETAILS, assessmentDetailsObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getSentAssessmentInfo(jobMatchId, companyId, assessmentType, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_SENT_ASSESSMENT_INFO + '/' + companyId + '/' + jobMatchId + '?assessmentType=' + assessmentType).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAssessmentDetails(assessmentId, jobId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ASSESSMENT_DETAILS + '/' + assessmentId + '/' + jobId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAssessmentsActivity(filter, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_ASSESSMENT_ACTIVITIES, filter).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }
}

import { Inject, Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { StorageService } from './storage.service';
import moment from 'moment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class MeetingService {

  urlConstants: any = {
    GET_MEETING_MODES: 'chime/meeting/getmeetingmodes',
    GET_MEETING_LINK: 'chime/meeting/getmeetinglink',
    JOIN_MEETING: 'chime/meeting/joinmeeting',
    CREATE_ATTENDEE: 'chime/meetings/createattendee',
    INVITE_PARTICIPANT: 'chime/meeting/inviteparticipant',
    ADD_PARTICIPANT: 'chime/meeting/addparticipanttomeeting',
    GET_SCHEDULE_DETAILS: 'chime/meeting/getmeetingscheduledetails',
    GIVE_FEEDBACK_EDIT_APPROVAL: 'chime/meeting/givefeedbackeditapproval',
    REMOVE_ATTENDEE_FROM_MEETING: 'chime/meeting/removeparticipantfrommeeting',
    GET_FEEDBACK_EDIT_ENABLED_ATTENDEE_ID: 'chime/meeting/getFeedbackEditEnabledUser',
    REQUEST_FEEDBACK_EDIT: 'chime/meeting/requestFeedbackEditApproval',
    GET_PRIMARY_HOST_ATTENDEE_ID: 'chime/meeting/getPrimaryHostUser',
    MAKE_USER_PRIMARY_HOST: 'chime/meeting/makeUserAsPrimaryHost',
    CHECK_STEP_CHANGE_POSSIBILITY: 'chime/meeting/checkStepChangePossibility'
  };

  enableFeedbackEditForUserId: any = null;

  constructor(@Inject(StorageService) private StorageService:StorageService, @Inject(GenericService) private genericService:GenericService,
  @Inject(AuthService) private authService:AuthService) { }

  getMeetingModes(successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_MEETING_MODES).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getMeetingLink(scheduleId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_MEETING_LINK + '/' + scheduleId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  joinMeeting(scheduleId, participantId, participantType, successCallback, errorCallback) {
    this.genericService.addObjectById(this.StorageService.get('baseurl') + this.urlConstants.JOIN_MEETING + '/' + participantType + '/' + scheduleId + '/' + participantId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  createAttendee(meetingInfo, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + 'chime/meeting/createattendee', meetingInfo).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  inviteParticipant(participantInfo, isMeetingInProgress, successCallback, errorCallback) {
    let url = this.StorageService.get('baseurl') + this.urlConstants.INVITE_PARTICIPANT + '?isMeetingInProgress=' + isMeetingInProgress;
    this.genericService.addObject(url, participantInfo).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  addParticipant(participantsInfo, successCallback, errorCallback) {
    let url = this.StorageService.get('baseurl') + this.urlConstants.ADD_PARTICIPANT;
    this.genericService.addObject(url, participantsInfo).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getScheduleDetails(scheduleId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_SCHEDULE_DETAILS + '/' + scheduleId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  giveFeedbackEditApproval(approvalDetails, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GIVE_FEEDBACK_EDIT_APPROVAL, approvalDetails).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  removeAttendeeFromMeeting(attendeeDetails, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.REMOVE_ATTENDEE_FROM_MEETING, attendeeDetails).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getFeedbackEditEnabledAttendeeId(meetingId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_FEEDBACK_EDIT_ENABLED_ATTENDEE_ID + '/' + meetingId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getFeedbackEditRequestApproval(requestDetails, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.REQUEST_FEEDBACK_EDIT, requestDetails).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getPrimaryHostAttendeeId(externalMeetingCallId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_PRIMARY_HOST_ATTENDEE_ID + '/' + externalMeetingCallId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  makeUserPrimaryHost(requestDetails, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.MAKE_USER_PRIMARY_HOST, requestDetails).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getMoveToInterviewStepPossibility(externalMeetingCallId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.CHECK_STEP_CHANGE_POSSIBILITY + '/' + externalMeetingCallId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  //common methods
  getMeetingUrl(scheduleId, workflowStep, mode) {
    return this.StorageService.get('baseurl') + '/#/conference?sh=' + scheduleId + '&ws=' + workflowStep + '&mode=' + mode;
  }
  getMassagedMeetingDetails(meetingDetails) {
    let meeting:any = {};
    meeting.id = meetingDetails.meetingScheduleId;
    meeting.candidateDetail = meetingDetails.candidateDetail;
    meeting.roleName = meetingDetails.roleName;
    meeting.reqNumber = meetingDetails.reqNumber;
    meeting.notesDisplay = "";
    meeting.status = meetingDetails.meetingStatus;
    meeting.candidateDetail = meetingDetails.candidateDetail;
    meeting.company = meetingDetails.company;
    meeting.jobMatchId = meetingDetails.jobMatchId;
    meeting.jobType = meetingDetails.jobType;
    meeting.contractDuration = meetingDetails.contractDuration;
    meeting.requisitionBelongsTo = meetingDetails.requisitionBelongsTo ? meetingDetails.requisitionBelongsTo.name : "";
    meeting.requisitionNote = meetingDetails.requisitionNote;
    meeting.clientNote = meetingDetails.clientNote;
    // if(!_.isNull(meetingDetails.internalNote) && meetingDetails.internalNote.length > 0) {
    //     if(this.candidateJobMatch.isSharedRequisition) {
    //         meeting.notesDisplay = meeting.notesDisplay + "<b>Internal Note: </b> <p>" + this._replaceSpecialCharacters(meetingDetails.internalNote) + "</p>";
    //     } else {
    //         meeting.notesDisplay = meeting.notesDisplay + "<b>Note: </b><p>" + this._replaceSpecialCharacters(meetingDetails.internalNote) + "</p>";
    //     }
    // }
    // if(!_.isNull(meetingDetails.externalNote) && meetingDetails.externalNote.length > 0) {
    //     meeting.notesDisplay = meeting.notesDisplay + "<b>External Note: </b><br><p>" + this._replaceSpecialCharacters(meetingDetails.externalNote) + "</p>";
    // }
    // if(meeting.notesDisplay.length > 0) {
    //     meeting.notesDisplay = "<div class='text-left' style='padding-top:8px'>" + meeting.notesDisplay + "</div>"
    // }
    // meeting.notesTooltipIsOpen = false;

    // switch(meeting.status) {
    //     case 'SCHEDULED':
    //         meeting.statusClass = 'fa fa-calendar job-match-meetings-meeting-icon-scheduled';
    //         meeting.statusLabel = 'Scheduled';
    //         meeting.feedbackEnabledFlag = true;
    //         break;
    //     case 'IN_PROGRESS':
    //         meeting.statusClass = 'fa fa-hourglass-half job-match-meetings-meeting-icon-in-progress';
    //         meeting.statusLabel = 'In Progress';
    //         meeting.feedbackEnabledFlag = true;
    //         break;
    //     case 'COMPLETED':
    //         meeting.statusClass = 'fa fa-check job-match-meetings-meeting-icon-completed';
    //         meeting.statusLabel = 'Completed';
    //         meeting.feedbackEnabledFlag = true;
    //         break;
    //     case 'OVERDUE':
    //         meeting.statusClass = 'fas fa-clock job-match-meetings-meeting-icon-overdue';
    //         meeting.statusLabel = 'Overdue';
    //         meeting.feedbackEnabledFlag = true;
    //         break;
    //     case 'CANCELLED':
    //         meeting.statusClass = 'fa fa-ban job-match-meetings-meeting-icon-cancelled';
    //         meeting.statusLabel = 'Canceled';
    //         meeting.feedbackEnabledFlag = false;
    //         meeting.feedbackTooltip = 'Feedback is disabled.';
    //         break;
    //     default:
    //         // set defaults
    //         meeting.statusClass = 'fa fa-question job-match-meetings-meeting-icon-in-progress';
    //         meeting.statusLabel = 'Status Unknown';
    //         meeting.feedbackEnabledFlag = false;
    //         meeting.feedbackTooltip = 'Feedback is disabled.';
    // }
    let startDateTime = new Date(meetingDetails.startTime);
    let endDateTime = new Date(meetingDetails.endTime);
    meeting.timeZone = _.isNull(meetingDetails.timeZoneName) ? '-' : meetingDetails.timeZoneName;
    if (meeting.timeZone != '-') {
      meeting.scheduledDateInBrowserTimeZone = startDateTime;
      // meeting.scheduledTimeLabelInBrowserTimeZone = this.$filter('fourdotfiveTimeFormat')(startDateTime) + ' to ' + this.$filter('fourdotfiveTimeFormat')(endDateTime);
      meeting.scheduledTimeLabelInBrowserTimeZone = moment(startDateTime).format("hh:mm a") + ' to ' + moment(endDateTime).format("hh:mm a");
      let startDateTimeStringInSelectedTimeZone = moment(startDateTime).tz(meeting.timeZone).format("YYYY-MM-DDTHH:mm:ss.SSS");
      let startDateTimeInSelectedTimeZone = new Date(startDateTimeStringInSelectedTimeZone);
      let endDateTimeStringInSelectedTimeZone = moment(endDateTime).tz(meeting.timeZone).format("YYYY-MM-DDTHH:mm:ss.SSS");
      let endDateTimeInSelectedTimeZone = new Date(endDateTimeStringInSelectedTimeZone);
      meeting.scheduledDate = new Date(startDateTimeInSelectedTimeZone);
      // meeting.scheduledTimeLabel = this.$filter('fourdotfiveTimeFormat')(startDateTimeInSelectedTimeZone) + ' to ' + this.$filter('fourdotfiveTimeFormat')(endDateTimeInSelectedTimeZone);
      meeting.scheduledTimeLabel = moment(startDateTimeInSelectedTimeZone).format('hh:mm a') + ' to ' + moment(endDateTimeInSelectedTimeZone).format('hh:mm a');
    } else {
      meeting.scheduledDate = startDateTime;
      // meeting.scheduledTimeLabel = this.$filter('fourdotfiveTimeFormat')(startDateTime) + ' to ' + this.$filter('fourdotfiveTimeFormat')(endDateTime);
      meeting.scheduledTimeLabel = moment(startDateTime).format('hh:mm a') + ' to ' + moment(endDateTime).format('hh:mm a')
    }
    meeting.usersList = [];
    meeting.usersListToDisplay = [];
    meeting.showPhoneNumbers = meetingDetails.participants.some(participant => participant.showPhoneNumber);
    angular.forEach(meetingDetails.participants, (meetingUser, key) => {
      let user:any = {};
      user.userId = meetingUser.id;
      user.userName = meetingUser.firstName;
      if (!_.isUndefined(meetingUser.lastName) && !_.isNull(meetingUser.lastName)) {
        user.userName += ' ' + meetingUser.lastName;
      }
      user.role = meetingUser.role;
      user.isPoc = meetingUser.isPoc;
      user.email = _.isNull(meetingUser.email) ? '-' : meetingUser.email;
      user.phoneNumber = _.isNull(meetingUser.phoneNumber) || !meetingUser.showPhoneNumber ? '-' : meetingUser.phoneNumber;
      user.title = _.isNull(meetingUser.title) ? '-' : meetingUser.title;

      // if(this.isCorporate) {
      //     meeting.showFeedback = true;
      // } else {
      //     meeting.showFeedback = this._getUserAccess(meetingUser.company.id);
      // }
      meeting.usersList.push(user);
      // if(!_.isNull(meetingUser.participantMeetingEmailDeliveryDetails)) {
      //     let emailDeliveryDetails = meetingUser.participantMeetingEmailDeliveryDetails[0];
      //     if(emailDeliveryDetails.participantEmail == user.email) {
      //         user.emailSendToParticipant = emailDeliveryDetails.emailSendToParticipant;
      //         user.emailDeliveryReason = emailDeliveryDetails.reason;
      //     }
      //     if(user.emailSendToParticipant) {
      //         user.emailDeliveryReason = "Email delivered.";
      //     } else if(_.isNull(user.emailDeliveryReason) || user.emailDeliveryReason == "") {
      //         user.emailDeliveryReason = "Email not delivered.";
      //     }
      // }
      // if(this.candidateJobMatch.isSharedRequisition && !this.isCorporate) {
      //     if(user.isPoc) {
      //         meeting.usersListToDisplay.push(user);
      //     }
      // } else {
      meeting.usersListToDisplay.push(user);
      // }

    });

    // if(!_.isNull(meetingDetails.candidateMeetingEmailDeliveryInfo)) {
    //     let candidateEmailDeliveryDetails = meetingDetails.candidateMeetingEmailDeliveryInfo[0];
    //     meeting.emailSendToCandidate = candidateEmailDeliveryDetails.emailSendToCandidate;
    //     meeting.emailDeliveryReason = candidateEmailDeliveryDetails.reason;
    //     if(meeting.emailSendToCandidate) {
    //         meeting.emailDeliveryReason = "Email delivered.";
    //     } else if(_.isNull(meeting.emailDeliveryReason) || meeting.emailDeliveryReason == "") {
    //         meeting.emailDeliveryReason = "Email not delivered.";
    //     }
    // }
    return meeting;
  }

  getUserIdOfFeedbackEditor() {
    if (this.enableFeedbackEditForUserId == null) {
      this.enableFeedbackEditForUserId = this.authService.getUserDetails().id;
    }
    return this.enableFeedbackEditForUserId;
  }
}

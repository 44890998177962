import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddEditCandidateContactOneComponent } from './add-edit-candidate-contact-one.component';
import { FormsModule } from '@angular/forms';
import { FourDotFivePhoneNumberModule } from '../four-dot-five-phone-number/four-dot-five-phone-number.module';
import { ManageEntityLocationModule } from '../manage-entity-location/manage-entity-location.module';
import { TooltipModule } from 'ngx-bootstrap';

@NgModule({
  declarations: [AddEditCandidateContactOneComponent],
  imports: [
    CommonModule,
    FormsModule,
    FourDotFivePhoneNumberModule,
    ManageEntityLocationModule,
    TooltipModule.forRoot()
  ],
  exports: [AddEditCandidateContactOneComponent],
  entryComponents: [AddEditCandidateContactOneComponent]
})
export class AddEditCandidateContactOneModule { }

<style>
	.modal-backdrop.fade {
		opacity: 0;
	}
	
	.modal-backdrop {
		position: initial;
	}

	#content-wrapper{
		min-height: 90vh !important;
	}

	#theme-wrapper
	{
		height: 100vh;
		/* overflow: hidden; */
	}

	.footer-content-bar
	{
		position: fixed !important;
		bottom: 0;
		width: 100%;
	}

	#page-wrapper
	{
		height: 100%;
	}

	/* .login-bg{
		background-image: url('app-content/img/wave-bg.svg');
		background-repeat: no-repeat;
		background-position: center;
		background-size:cover;
		height: 200px;
		position: fixed;
		bottom: 35px;
		width: 100%;
	} */

</style>

<div id="content-wrapper">
	<!-- <div class="login-bg"></div> -->
	<div class="row">
		<div class="col-lg-12">
			<div class="slide-main-container">
				<!-- <div ui-view class=""></div> -->
				<div class="container slide-main-animation">
					<!-- <div class="row d-flex">
						<div class="col-xs-6 d-flex flex-column justify-content-center align-items-center">
							<img src="https://static.wixstatic.com/media/f06c7f_aafff65341764743a60bc0362337c8ba~mv2.png/v1/fill/w_363,h_138,al_c,q_85,usm_0.66_1.00_0.01/f06c7f_aafff65341764743a60bc0362337c8ba~mv2.webp" alt="logo" style="width:200px;margin-right: 60px;" class="mb-5">
						<img src="../../../app-content/img/undraw_hire_te5y.svg" style="width:350px" alt="Hiring">
						</div> -->
						<!-- <div class="col-xs-6" style="background-color: #03a9f4;"> -->
							<!-- <div class="col-xs-6" > -->
					<div class="row">
						<div class="col-xs-12">
							<div id="login-box">
								<div id="login-box-holder">
									<div class="row">
										<div class="col-xs-12">
											<header id="login-header">
												<div id="login-logo">
													<img src="assets/app-content/img/logo-symbol.png" alt="" />
												</div>
											</header>
											<div id="login-box-inner">
												<form #loginform="ngForm" (ngSubmit)="loginform.form.valid && validateUser()" novalidate>
													<div>
														<div class="error" *ngIf="loginform.pristine">
															<small class="error" *ngIf="message !==''">
																{{message}}
															</small>
														</div>
													</div>
													<div class="input-group">
														<span class="input-group-addon">
															<i class="fa fa-user"></i>
														</span> 
														<input #email="ngModel" class="form-control" type="text" placeholder="Email address"
															name="email" [(ngModel)]="user.email" required email>
													</div>
													<div class="error text-center" *ngIf="loginform.submitted && email.errors">
														<small class="error" *ngIf="email.errors?.required">
																{{MESSAGECONSTANTS.COMMONVALIDATIONS.EMAIL_MANDATORY}}
														</small> 
														<small class="error" *ngIf="loginform.dirty && email.errors?.email">
																{{MESSAGECONSTANTS.COMMONVALIDATIONS.NOT_VALID_EMAIL}} 
														</small>
													</div>
													<div class="input-group">
														<span class="input-group-addon"><i
																class="fa fa-key"></i></span> 
														<input #password="ngModel" type="password" class="form-control" placeholder="Password"
															name="password" [(ngModel)]="user.password" required>

													</div>
													<div class="error text-center" *ngIf="loginform.submitted && password.errors">
														<small class="error" *ngIf="password.errors?.required">
																{{MESSAGECONSTANTS.COMMONVALIDATIONS.PASSWORD_MANDATORY}}
														</small> 
														<!-- <small class="error" *ngIf="form.loginform.password.errors.minlength">
																{{MESSAGECONSTANTS.COMMONVALIDATIONS.PASSWORD_MINLENGTH}}
														</small>
														<small class="error" *ngIf="form.loginform.password.errors.maxlength">
																{{MESSAGECONSTANTS.COMMONVALIDATIONS.PASSWORD_MAXLENGTH}}
														</small> -->
													</div>
													<div class="error text-center" *ngIf="loginform.pristine">
														<small class="error" *ngIf="exception !=='' && password.valid">
															{{exception}}
														</small>
													</div>
													<div id="remember-me-wrapper">
														<div class="row">
															<div class="col-xs-6">
																<div class="checkbox-nice">
																	<input type="checkbox" [(ngModel)]="rememberMe" id="remember-me" name="rememberMe"/> 
																	<label for="remember-me">
																		{{MESSAGECONSTANTS.LOGIN.REMEMBER_ME}}
																	</label>
																</div>
															</div>
															<a id="login-forgot-link" class="col-xs-6" (click) = goToForgotPasswordPage()> {{MESSAGECONSTANTS.LOGIN.FORGOT_PASSWORD}} </a>
														</div>
													</div>
													<div class="row">
														<div class="col-xs-12">
															<button id="loginButton" type="submit" class="btn btn-success col-xs-12">{{MESSAGECONSTANTS.BUTTONS.LOGIN_PAGE.LOGIN}}</button>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="ngx-modal-header">
    <h4 class="modal-title pull-left">{{modalData.title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal(null)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div *ngIf="modalData.type === 'makeApplicationUser'">
        <app-convert-employee-to-user [employeeId]="modalData.employeeId" (saveCallback)="saveCallback($event)"
            (cancelCallback)="cancelCallback()">
        </app-convert-employee-to-user>
    </div>
    <div *ngIf="modalData.type === 'activities'">
        <app-entity-activities [userId]="modalData.employeeId" [userName]="modalData.employeeInfo.name"
            [activityMap]="modalData.activityMap" [context]="modalData.context">
        </app-entity-activities>
    </div>
    <div *ngIf="modalData.type === 'deleteEmployee'">
        <app-delete-entity entity="employee" [employeeInfo]="modalData.employeeInfo" (cancelCallback)="cancelCallback()"
            (deleteCallback)="saveCallback($event)">
        </app-delete-entity>
    </div>

</div>
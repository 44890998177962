import { Injectable } from '@angular/core';
import { CompanyService } from './company.service';
import { MeetingMode } from '../utils/meeting-mode';
import { FourDotFiveTimeStampFormatPipe } from '../pipes/four-dot-five-time-stamp-format';

@Injectable({
  providedIn: 'root'
})
export class EntityActivitiesService {

  constructor(
    private companyService: CompanyService, 
    public fourDotFiveTimeStampFormatPipe: FourDotFiveTimeStampFormatPipe
    ) { 

  }

  getMassagedActivities(data, activityMap, context, info) {
    let activities = [];
    angular.forEach(data, (val, key) => {
      val.activityDisplayValue = activityMap[val.activityType];
      val.miniStatement = activityMap[val.activityType];
      if (!_.isNull(val.activityMessage) && val.activityMessage !== undefined) {
        val.activityDisplayValue = val.activityMessage;
        val.miniStatement = val.activityMessage;
      }
      val.createdBy = val.createdBy.trim();
      val.statement = '';
      if (context.includes('candidate')) {
        this._massageCandidateActivity(val, context);
      } else if (context == 'requisition') {
        this._massageRequisitionActivity(val, info);
      }
      else if (context === 'user' || context === 'employee' || context === 'userCard' || context === 'employeeCard' || context === 'assessments') {
        this._massageUserActivity(val);
      } else if (context.includes('Settings')) {
        this._massageSettingsActivity(val);
      }
      //additional sentences
      val.mainText = this._getMainText(val, context);
      //end
      val.displayDate = new Date(val.createdDate);

      val.showShortMessageFlag = true;
      if (val.note !== null && val.note.length > 120) {
        val.isExpandable = true;
      }
      //chime meeting details
      if (val.activityType === 'meeting' && val.chimeCallDetails && val.chimeCallDetails.length > 0) {
        val.mainText = `${val.mainText} ${this._getChimeMeetingDetailsText(val)}`;
      }
      activities.push(val);
    });

    if (context == 'requisition') {
      angular.forEach(activities, (activity, index) => {
        if (activity.activityType == 'addCandidate' || activity.activityType == 'associateCandidate' || activity.activityType == 'pullCandidate' || (!_.isNull(activity.candidateNameList) && activity.candidateNameList.length > 0)) {
          this.setSmallText(index, activities, info);
        }
      });
    }

    return activities;
  }

  _massageCandidateActivity(val, context) {
    if (val.activityType.includes('RE_RUN_SCORE')) {
      val.activityType = 'candidateScoreChanged';
      val.activityDisplayValue = "Score has changed";
    }

    if (val.activityType.includes('DOCUMENT')) {
      // Check if activity is Document Emailed
      val.isDocumentEmailActivity = false;
      if (val.activityType == 'DOCUMENT_EMAILED' || val.activityType == 'DOCUMENT_FAILED') {
        if (val.listOfDocumentsSent || val.listOfUsersDocumentsSent) {
          val.isDocumentEmailActivity = true;
        }
      }
      //Set all document activity to 'documentActivity'
      val.activityType = 'documentActivity';
    }

    if (val.activityType.includes("CHANGED") || val.activityType.includes("STATE_CHANGE")) {
      if (val.activityType.includes("CANDIDATE_NAME_CHANGED")
        || val.activityType.includes("CANDIDATE_PHONE_NUMBER_CHANGED")
        || val.activityType.includes("CANDIDATE_EMAIL_CHANGED")
        || val.activityType.includes("CANDIDATE_LOCATION_CHANGED")
        || val.activityType.includes("CANDIDATE_SALARY_CHANGED")
        || val.activityType.includes("CANDIDATE_SPONSORSHIP_CHANGED")
        || val.activityType.includes("CANDIDATE_RELOCATION_CHANGED")
        || val.activityType.includes("CANDIDATE_SPONSORSHIP_NOTE_CHANGED")
        || val.activityType.includes("CANDIDATE_RELOCATION_NOTE_CHANGED")
        || val.activityType.includes("CANDIDATE_WORK_LOCATION_CHANGED")) {
        val.activityType = 'candidateChange';
      } else {
        val.activityType = 'change';
      }
      if (_.isNull(val.toState)) {
        val.toState = "-";
      } else {
        if (val.toState == 'Filled' || val.toState == 'Joined') {
          val.CandidateToStateDisplayText = val.toState;
        }
      }
      val.statement = val.miniStatement + ' from ' + val.fromState + ' to ' + val.toState;
      if (!_.isNull(val.fromState)) {
        if (val.fromState.includes("Assessment") || val.toState.includes("Assessment")) {
          val.activityDisplayValue = "Assessment Status Changed";
        }
        else if (val.fromState.includes("Phone Screen") || val.toState.includes("Phone Screen")) {
          val.activityDisplayValue = "Phone Screen Status Changed";
        } else if (val.fromState.includes("Interview") || val.toState.includes("Interview")) {
          val.activityDisplayValue = "Interview Status Changed";
        }
      }
    }
    if (val.activityType.includes("STEP_CHANGE")) {
      val.activityType = 'change';
      val.activityDisplayValue = "Candidate Moved";
    } else if (val.activityType.includes("CANDIDATE_NAME_ADDED")
      || val.activityType.includes("CANDIDATE_PHONE_NUMBER_ADDED")
      || val.activityType.includes("CANDIDATE_EMAIL_ADDED")
      || val.activityType.includes("CANDIDATE_LOCATION_ADDED")
      || val.activityType.includes("CANDIDATE_SALARY_ADDED")
      || val.activityType.includes("CANDIDATE_SPONSORSHIP_ADDED")
      || val.activityType.includes("CANDIDATE_RELOCATION_ADDED")
      || val.activityType.includes("CANDIDATE_SPONSORSHIP_NOTE_ADDED")
      || val.activityType.includes("CANDIDATE_RELOCATION_NOTE_ADDED")
      || val.activityType.includes("CANDIDATE_WORK_LOCATION_ADDED")) {
      val.activityType = 'candidateAdd';
    } else if (val.activityType.includes("ADDED") || val.activityType.includes("COMMUNICATIONS")) {
      val.activityType = 'add';
    } else if (val.activityType.includes("UNASSIGNED")) {
      val.activityType = 'unAssign';
    } else if (val.activityType.includes("ASSIGNED")) {
      val.activityType = 'assign';
    } else if (val.activityType.includes("REMOVED") || val.activityType.includes("DELETED")) {
      val.activityType = 'remove';
    } else if (val.activityType.includes("REMINDER")) {
      val.activityType = 'reminder';
      val.activityDisplayValue = val.count == null ? val.activityDisplayValue : val.count + " " + val.activityDisplayValue;
    } else if (val.activityType.includes("PHONE_SCREEN") || val.activityType.includes("INTERVIEW")) {
      val.activityType = 'meeting';
    }

    if (val.jobLocation) {
      val.jobLocationText = this.companyService.getParsedCompanyLocationText(val.jobLocation, true);
    }

    // if meeting count exists and its not the final reminder activity
    if (!_.isNull(val.meetingCount) && _.isNull(val.activityMessage)) {
      val.activityDisplayValue = val.meetingCount + " " + val.activityDisplayValue;
    }
    if (!_.isNull(val.phoneScreenerOrInterviewerList) && val.phoneScreenerOrInterviewerList.length > 0) {
      val.phoneScreenOrInterviewText = this._getPhoneScreenersOrInterviewsList(val, context);
    }
    // val.activityDisplayValue = val.activityDisplayValue.replace("Candidate Note", "Note");
    // val.activityDisplayValue = val.activityDisplayValue.replace("Matched Candidate Recruiter", "Recruiter");
    // val.activityDisplayValue = val.activityDisplayValue.replace("Matched Candidate State Changed", "Candidate State Changed");
    // val.activityDisplayValue = val.activityDisplayValue.replace("Candidate Communications", "Communication");
    val.requisitionFilledDate = this._setFilledExpectedAndJoinedDate(val.filledDate);
    val.requisitionExpectedJoinDate = this._setFilledExpectedAndJoinedDate(val.expectedJoinDate);
    val.requisitionJoinedDate = this._setFilledExpectedAndJoinedDate(val.joinedDate);
  }

  _setFilledExpectedAndJoinedDate(val) {
    if (_.isNull(val)) {
      return val;
    } else {
      return new Date(val);
    }
  }

  _getPhoneScreenersOrInterviewsList(activity, context) {
    let usersList = activity.phoneScreenerOrInterviewerList;
    let meetingStartTime = activity.meetingStartTime;
    let meetingEndTime = activity.meetingEndTime;
    let meetingMode = activity.meetingMode;
    let activityType = activity.activityType;
    let via = meetingMode == null ? "" : ` via <b>${MeetingMode[meetingMode]}</b>`;
    let text = '';
    if (activityType == 'meeting') {
      text = `${via} ${this._getUsersText(usersList, activityType)} ${this._getFormattedStartAndEndDateText(meetingStartTime, meetingEndTime)}`;
    } else if (activityType == 'reminder') {
      let rolesText = this._getRoleText(activity, context);
      if (rolesText.length > 0) {
        text = `${via} ${rolesText} sent ${this._getUsersText(usersList, activityType)}`;
      } else {
        text = `${via} ${this._getFormattedStartAndEndDateText(meetingStartTime, meetingEndTime)} sent ${this._getUsersText(usersList, activityType)}`;
      }
    }
    return text;
  }

  _getUsersText(usersList, activityType) {
    let text = activityType.includes('meeting') ? "with user" : "to user";
    if (usersList.length > 1) {
      text = text + "s";
    }
    text = text + " <b>";
    for (let i = 0; i < usersList.length; i++) {
      if (i == usersList.length - 1) {
        text = text + usersList[i].fullName;
      } else if (i == usersList.length - 2) {
        text = text + usersList[i].fullName + ' and ';
      } else {
        text = text + usersList[i].fullName + ', ';
      }
    }
    text = text + "</b>";
    return text;
  }

  _getChimeMeetingDetailsText(val) {
    let callDetailsText = `<span class="emerald d-block pt-2">Call Details:</span>`;
    let parentText = "";
    const callDetails = [...val.chimeCallDetails.reverse()];
    callDetails.forEach(chimeCallDetails => {
      let attendeesList = chimeCallDetails.chimeParticipantActivityDetails.map(attendee => attendee.name);
      let attendeesListWithDuration = chimeCallDetails.chimeParticipantActivityDetails.map(attendee => `${attendee.name} ${attendee.duration} min(s)`);
      const betweenOrWith = attendeesList.length == 1 ? 'with' : 'between';
      let mainText = `<br><b>The ${chimeCallDetails.chimeCallCount} Conference call</b> lasted ${chimeCallDetails.totalDuration} min(s) ${betweenOrWith} `;
      let usersText = this._getConcatenatedAttendeesText(attendeesList);
      let usersWithDurationText = `<span class="pl-4 d-block"><b>Time in call</b> - ${this._getConcatenatedAttendeesText(attendeesListWithDuration)}.</span>`;
      parentText = `${parentText} <span class="pl-2 d-block">${mainText} ${usersText}.</span>${usersWithDurationText}`;
    });
    return `<span class="d-block">${callDetailsText}<span>${parentText}</span></span>`;
  }

  _getConcatenatedAttendeesText(attendeesList) {
    let usersText = "";
    for (let i = 0; i < attendeesList.length; i++) {
      if (i == attendeesList.length - 1) {
        usersText = usersText + attendeesList[i];
      } else if (i == attendeesList.length - 2) {
        usersText = usersText + attendeesList[i] + ' and ';
      } else {
        usersText = usersText + attendeesList[i] + ', ';
      }
    }
    return usersText;
  }

  _getFormattedStartAndEndDateText(meetingStartTime, meetingEndTime) {
    return `from <b>${this.fourDotFiveTimeStampFormatPipe.transform(meetingStartTime)}</b> to <b> ${this.fourDotFiveTimeStampFormatPipe.transform(meetingEndTime)}</b>`;
    // return `from <b>${this.$filter('fourdotfiveTimeStampFormat')(meetingStartTime)}</b> to <b> ${this.$filter('fourdotfiveTimeStampFormat')(meetingEndTime)}</b>`;
  }

  _massageRequisitionActivity(val, info) {
    let isSharedRequisition = info.isSharedRequisition;
    let currentCompanyName = info.currentCompanyName;

    if (val.activityType.includes('DOCUMENT')) {
      // Check if activity is Document Emailed
      val.isDocumentEmailActivity = false;
      if (val.activityType == 'DOCUMENT_EMAILED' || val.activityType == 'DOCUMENT_FAILED') {
        if (val.listOfDocumentsSent || val.listOfUsersDocumentsSent) {
          val.isDocumentEmailActivity = true;
        }
      }
      //Set all document activity to 'documentActivity'
      val.activityType = 'documentActivity';
    }

    if (val.activityType.includes("CHANGED")) {
      val.activityType = 'change';
      if (_.isNull(val.fromState) || val.fromState == "") {
        val.fromState = "-";
      }
      if (_.isNull(val.toState) || val.toState == "") {
        val.toState = "-";
      }
      val.statement = val.miniStatement + ' from ' + val.fromState + ' to ' + val.toState;
    } else if (val.activityType.includes("ADDED") || val.activityType.includes("CREATED")) {
      val.activityType = 'add';
      val.statement = val.miniStatement + ' - ' + val.toState;
    } else if (val.activityType.includes("UPDATE")) {
      val.activityType = 'edit';
    } else if (val.activityType.includes("REMOVED") || val.activityType.includes("DELETED")) {
      val.activityType = 'remove';
      val.statement = val.miniStatement + ' - ' + val.toState;
    } else if (val.activityType.includes("DAXTRA_PULL_FROM_JOB")) {
      val.activityType = 'pull';
      val.activityDisplayValue = "Job boards resume pull initiated from " + this.getJobBoardsNamesText(val.jobBoardNames);
    } else if (val.activityType.includes("ADD_CANDIDATE")) {
      val.activityType = 'addCandidate';
    } else if (val.activityType.includes("ASSOCIATE_CANDIDATE")) {
      val.activityType = 'associateCandidate';
    } else if (val.activityType.includes("ACTIVITY_JOB_BOARD_PULL_FINISHED")) {
      val.activityType = 'pullCandidate';
    } else if (val.activityType.includes("JOINED")) {
      val.activityType = 'joined';
    } else if (val.activityType.includes("FILLED")) {
      val.activityType = 'filled';
    } else {
      val.statement = val.miniStatement;
    }
    if ((val.note !== null && val.note.length > 80)) {
      val.isExpandable = true;
    }
    let companyName = isSharedRequisition && val.companyName !== currentCompanyName ? "<span class='emerald'>" + val.companyName + "</span>: " : "";
    val.activityDisplayValue = companyName + val.activityDisplayValue;
    val.requisitionFilledDate = this._setFilledExpectedAndJoinedDate(val.filledDate);
    val.requisitionExpectedJoinDate = this._setFilledExpectedAndJoinedDate(val.expectedJoinDate);
    val.requisitionJoinedDate = this._setFilledExpectedAndJoinedDate(val.joinedDate);
  }

  _massageUserActivity(val) {
    if (val.activityType.includes("CHANGE")) {
      val.activityType = 'change';
    } else if (val.activityType.includes("ADDED") || val.activityType.includes("CREATED")) {
      val.activityType = 'add';
    } else if (val.activityType.includes("UNASSIGNED")) {
      val.activityType = 'unAssign';
    } else if (val.activityType.includes("ASSIGNED")) {
      val.activityType = 'assign';
    } else if (val.activityType.includes("REMOVED") || val.activityType.includes("DELETED")) {
      val.activityType = 'remove';
    } else if (val.activityType.includes("TO_USER") || val.activityType.includes("TO_EMPLOYEE")) {
      val.activityType = 'userType';
    }

    // val.activityDisplayValue = val.activityDisplayValue.replace("Candidate Note", "Note");
    // val.activityDisplayValue = val.activityDisplayValue.replace("Matched Candidate Recruiter", "Recruiter");
    // val.activityDisplayValue = val.activityDisplayValue.replace("Matched Candidate State Changed", "Candidate State Changed");
    // val.activityDisplayValue = val.activityDisplayValue.replace("Candidate Communications", "Communication");
  }

  _massageSettingsActivity(val) {
    if (val.activityType.includes("ADDED") || val.activityType.includes("CREATED")) {
      val.activityType = 'add';
    } else if (val.activityType.includes("REMOVED") || val.activityType.includes("DELETED")) {
      val.activityType = 'remove';
    } else {
      val.activityType = 'change';
    }
    if (val.title !== null) {
      val.activityDisplayValue = `${val.activityDisplayValue} - ${val.title}`;
    }
  }

  _getMainText(val, context) {
    let mainText = `${val.testName !== null && val.testName !== undefined ? `<span>for Assessment '<span class="emerald">${val.testName}</span>'</span>` : ''}`;
    let activityBasedText = this._getTextBasedOnActivityType(val, context);
    let createdByText = this._getCreatedByText(val);
    mainText = mainText + activityBasedText;
    if (context !== 'userCard' && context !== 'employeeCard') {
      mainText = mainText + createdByText;
    }
    return mainText;
  }

  _getTextBasedOnActivityType(activity, context) {
    let activityType = activity.activityType;
    let activityText = ``;
    if (activityType === 'add' || activityType === 'remove' || (activityType === 'reminder' && activity.phoneScreenerOrInterviewerList == null) || activityType.includes('meeting')) {
      activityType = 'general';
    }
    switch (activityType) {
      case 'change':
        if (context !== 'userCard' && context !== 'employeeCard') {
          activityText =
            `
                    <span>
                        &nbsp;from 
                        <span class="badge badge-warning">${activity.fromState}</span> 
                        to <span class="badge badge-primary">${activity.toState}</span>
                        ${this._getRoleText(activity, context)}
                    </span>
                `;
        } else {
          activityText =
            `
                    <span>
                        to '<span class="emerald">${activity.toState}</span>'
                        ${this._getRoleText(activity, context)}
                    </span>
                `;
        }

        break;
      case 'candidateChange':
        activityText =
          `
                &nbsp;from <span class="badge badge-warning">${activity.fromState}</span> 
                to 
                <span class="badge badge-primary">${activity.toState}</span>
            
            `
        break;
      case 'general':
        if (context !== 'userCard' && context !== 'employeeCard') {
          let badgeClass = "";
          if (activity.activityType === 'add' || (activity.activityType === 'reminder')) {
            badgeClass = "badge-success";
          } else if (activity.activityType === 'remove') {
            badgeClass = "badge-danger";
          }
          let toState = activity.activityType !== 'reminder' && activity.toState !== null ? activity.toState : '';
          activityText =
            `
                    <span class="badge ${badgeClass}">${toState}</span>
                    ${this._getRoleText(activity, context)}
                `
        } else {
          activityText =
            `
                    ${activity.toState == null ? '' : "'<span class='emerald'>" + activity.toState + "</span>'"}
                    ${this._getRoleText(activity, context)}
                `
        }
        break;
      case 'candidateAdd':
        activityText = `
        <span class="badge badge-success">${activity.toState}</span>
                `
        break;
      case 'assign':
        if (context !== 'userCard' && context !== 'employeeCard') {
          activityText =
            `
                    &nbsp;- <span class="badge">${activity.toState}</span>
                    ${this._getRoleText(activity, context)}
                `;
        } else {
          activityText =
            `
                    &nbsp;- '<span class="emerald">${activity.toState}</span>'
                    ${this._getRoleText(activity, context)}
                `;
        }
        break;
      case 'unAssign':
        if (context !== 'userCard' && context !== 'employeeCard') {
          activityText =
            `
                    &nbsp;- <span class="badge">${activity.toState}</span>
                    ${this._getRoleText(activity, context)}
                `;
        } else {
          activityText =
            `
                    &nbsp;- '<span class="emerald">${activity.toState}</span>'
                    ${this._getRoleText(activity, context)}
                `;
        }
        break;
        case 'candidateScoreChanged':
          let activityTitleMessage = '';
          if(activity.title && activity.title != '' && activity.requisitionNumber && activity.requisitionNumber != '' && context === 'candidate') {
            activityTitleMessage = ` for <span class="emerald">${activity.title} (${activity.requisitionNumber})</span>`;
          }
          activityText =
            `
                  &nbsp;from <span class="badge badge-warning">${activity.fromState}</span> 
                  to 
                  <span class="badge badge-primary">${activity.toState}</span>
                  ${activityTitleMessage}
              `
          break;
      default:
        break;
    }
    return activityText;
  }

  _getCreatedByText(activity) {
    return activity.createdBy !== '' ?
      `
        <span> by <span class="emerald"><i>${activity.createdBy}</i></span></span>
    `
      : '';
  }

  _getRoleText(val, context) {
    return context === 'candidate' ?
      `
        <span>
            <span>for the role </span> 
            <span class="emerald">${val.title}</span>
           ${this._getRequisitionNumberText(val)} 
           ${this._getClientOrBuText(val)}
        </span>
        `
      : '';
  }

  _getRequisitionNumberText(val) {
    return val.requisitionNumber !== null ?
      `<span> with Job ID 
        <span class="emerald">${val.requisitionNumber}</span>
    </span>
    `: '';
  }

  _getClientOrBuText(val) {
    return val.clientOrBuName !== null ?
      `
        <span> for 
           <span class="emerald">${val.clientOrBuName} </span>
           ${this._getJobLocationText(val)}
           
       </span>
        `
      : '';
  }

  _getJobLocationText(val) {
    return val.jobLocation !== null ?
      `
    <span>${val.jobLocationText}</span>
    `
      : '';
  }

  //requisitions activities methods
  setSmallText(index, activities, info) {
    let activity = activities[index];
    if (activity.activityType == "addCandidate") {
      this._setAddCandidateSmallText(activity, info);
    } else if (activity.activityType == "associateCandidate") {
      this._setAssociateCandidateSmallText(activity, info);
    } else if (activity.activityType == "pullCandidate") {
      this._setPullCandidateSmallText(activity, info);
    } else {
      activity.activityDisplayValue = activity.activityDisplayValue + " (" + activity.candidateNameList[0] + ")";
    }
    activities[index] = activity;
  }

  _setAddCandidateSmallText(activity, info) {
    let isSharedRequisition = info.isSharedRequisition;
    let currentCompanyName = info.currentCompanyName;
    if (_.isNull(activity.candidateNameList)) {
      activity.activityDisplayValue = 'Candidate added';
    } else if (activity.candidateNameList.length == 1) {
      activity.activityDisplayValue = activity.candidateNameList.length + " candidate added (" + activity.candidateNameList[0] + ")";
    } else if (activity.candidateNameList.length > 1) {
      activity.activityDisplayValue = activity.candidateNameList.length + " candidates added (" + this._getAppendedCandidateNamesList(activity.candidateNameList) + ")";
    }
    let companyName = isSharedRequisition && activity.companyName !== currentCompanyName ? "<span class='emerald'>" + activity.companyName + "</span>: " : "";
    activity.activityDisplayValue = companyName + activity.activityDisplayValue;
    activity.title = "Added Candidates";
  }

  _getAppendedCandidateNamesList(candidateNames) {
    let text = '';
    for (let i = 0; i < candidateNames.length; i++) {
      if (i == candidateNames.length - 1) {
        text = text + candidateNames[i];
      } else if (i == candidateNames.length - 2) {
        text = text + candidateNames[i] + ' and ';
      } else if (i == 9 && candidateNames.length > 11) {
        text = text + candidateNames[i] + '...';
        break;
      } else {
        text = text + candidateNames[i] + ', ';
      }
    }
    return text;
  }

  _setAssociateCandidateSmallText(activity, info) {
    let isSharedRequisition = info.isSharedRequisition;
    let currentCompanyName = info.currentCompanyName;
    if (_.isNull(activity.candidateNameList)) {
      activity.activityDisplayValue = 'Candidate associated';
    } else if (activity.candidateNameList.length == 1) {
      activity.activityDisplayValue = activity.candidateNameList.length + " candidate associated (" + activity.candidateNameList[0] + ")";
    } else if (activity.candidateNameList.length > 1) {
      activity.activityDisplayValue = activity.candidateNameList.length + " candidates associated (" + this._getAppendedCandidateNamesList(activity.candidateNameList) + ")";
    }
    let companyName = isSharedRequisition && activity.companyName !== currentCompanyName ? "<span class='emerald'>" + activity.companyName + "</span>: " : "";
    activity.activityDisplayValue = companyName + activity.activityDisplayValue;
    activity.title = "Associated Candidates";
  }

  _setPullCandidateSmallText(activity, info) {
    let isSharedRequisition = info.isSharedRequisition;
    let currentCompanyName = info.currentCompanyName;
    let pullCountsMap = activity.jobBoardResumesCountMap;
    let text = '';
    let jobBoardsCount = Object.keys(pullCountsMap).length;
    let index = 0;
    angular.forEach(pullCountsMap, (count, jobBoard) => {
      if (index == jobBoardsCount - 1) {
        text = text + count + ' candidates pulled from ' + jobBoard;
      } else if (index == jobBoardsCount - 2) {
        text = text + count + ' candidates pulled from ' + jobBoard + ' and ';
      } else {
        text = text + count + ' candidates pulled from ' + jobBoard + ', ';
      }
      index++;
    });
    activity.activityDisplayValue = text;
    if (activity.candidateNameList !== null && activity.candidateNameList.length > 0) {
      if (activity.candidateNameList.length == 1) {
        activity.activityDisplayValue = activity.activityDisplayValue + " (" + activity.candidateNameList[0] + ")";
      } else if (activity.candidateNameList.length > 1) {
        activity.activityDisplayValue = activity.activityDisplayValue + " (" + this._getAppendedCandidateNamesList(activity.candidateNameList) + ")";
      }
    }

    let companyName = isSharedRequisition && activity.companyName !== currentCompanyName ? "<span class='emerald'>" + activity.companyName + "</span>: " : "";
    activity.activityDisplayValue = companyName + activity.activityDisplayValue;
    activity.title = "Candidates Pulled From JobBoards";
  }

  getJobBoardsNamesText(jobBoardsNames) {
    let text = '';
    for (let i = 0; i < jobBoardsNames.length; i++) {
      if (i == jobBoardsNames.length - 1) {
        text = text + jobBoardsNames[i];
      } else if (i == jobBoardsNames.length - 2) {
        text = text + jobBoardsNames[i] + ' and ';
      } else {
        text = text + jobBoardsNames[i] + ', ';
      }
    }
    return text;
  }
}

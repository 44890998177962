import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { AngularModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-assessments-actions-modal',
  templateUrl: './assessments-actions-modal.component.html',
  styleUrls: ['./assessments-actions-modal.component.css']
})
export class AssessmentsActionsModalComponent implements OnInit {


  modalData: any;
  public onClose: Subject<any>;

  constructor(
    private bsModalRef: BsModalRef,
    public bsModalService: BsModalService,
    private angularModalService: AngularModalService
  ) {
    this.onClose = new Subject();
    if (this.bsModalService.config.initialState) {
      this.modalData = this.bsModalService.config.initialState;
    }
  }

  ngOnInit() {
  }

  closeModal(data) {
    this.onClose.next(data);
    this.bsModalRef.hide();
  }

  saveCallback(event) {
    this.closeModal(event);
  }

  cancelCallback() {
    this.closeModal(null)
  }

  deleteAssessment() {
    let callbackObject = {
      callbackType: 'delete'
    }
    this.closeModal(callbackObject);
  }

}

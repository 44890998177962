<div class="container">
    <div class="row">
        <div class="col-xs-12">
            <form #assignRecruiterForm="ngForm" novalidate>
                <div class="row" style="margin-bottom: 10px">
                    <div class="col-xs-12" *ngFor="let jobMatch of jobMatchesDataArray; let i = index" style="padding: 10px; padding-top: 5px;">
                        <span>
                            <span>Assign Recruiter to the candidate </span>
                            <span *ngIf="jobMatch.loadingFlag" style="padding-left: 10px;">
                                <span class="fa fa-spinner fa-spin"></span>
                            </span>
                            <span *ngIf="!(jobMatch.loadingFlag)">
                                '<span class="emerald">{{jobMatch.jobMatchData.name}}</span>' for the role of '<span class="emerald">{{jobMatch.jobMatchData.jobMatchTitle}}</span>' 
                                <span *ngIf="jobMatch.jobMatchData.jobMatchClientName">at '<span class="emerald">{{jobMatch.jobMatchData.jobMatchClientName}}</span>'</span>
                            </span>
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-sm-8 col-md-6">
                        <div class="form-group" [ngClass]="{'has-error': (assignRecruiterForm.submitted && recruiter.invalid)}">
                            <label for="recruiterSelect" class="control-label">
                                Recruiter<em>*</em> :
                            </label>
                            <!-- <span *ngIf="loadingRecruitersFlag" style="padding-left: 10px;">
                                <span class="fa fa-spinner fa-spin"></span>
                            </span> -->
                            <select #recruiter="ngModel" name="recruiter" class="form-control" [(ngModel)]="recruiterId" required>
                                <option [value]=""></option>
                                <option *ngFor="let recruiter of recruitersList" [value]="recruiter.id">{{recruiter.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 text-right">
                        <div class="clearfix">
                            <button type="submit" class="btn btn-success" (click)="assignRecruiter()" [disabled]="savingFlag">
                                <span *ngIf="!savingFlag">Assign</span>
                                <span *ngIf="savingFlag">Assigning..</span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
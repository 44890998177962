import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {$uibModal} from 'angular-ui-bootstrap/dist/ui-bootstrap';

import { AuthService } from './auth.service';
import { GenericService } from './generic.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  urlConstants: any = {
    GET_COMPANY: 'api/company/getcompanydetails',
    DELETE_COMPANY: 'api/company/deletecompanyorclientorbu',
    GET_DELETE_COMPANY_INFO: 'api/company/companyorclientorbudeleteinfos',
    CHANGE_COMPANY_STATE: 'api/company/changeCompanyState',
    GET_ALL_STAFFING_COMPANIES: 'api/company/getallstaffingcompanies',
    GET_ALL_CORPORATE_VENDORS: 'api/company/findallcorporatevendors',
    SAVE_CORPORATE_VENDOR: 'api/company/saveCorporateVendor',
    SAVE_CORPORATE_VENDORS: 'api/company/saveCorporateVendors',
    GET_ALL_BU_VENDORS: 'api/company/getallbuvendors',
    SAVE_BU_VENDORS: 'api/company/savebuvendors',
    GET_VENDOR_CONTACTS: 'api/company/getvendorcontacts',
    GET_ALL_BUS_OF_CLIENT: 'api/company/getclientbulist',
    GET_CLIENT_CORPORATE_CONTACT_DETAILS: 'api/company/getcorporatecompanycontactlist',
    UPDATE_CLIENT_CORPORATE_CONTACT_DETAILS: 'api/company/updatecorporatecompanycontactlist',
    GET_SHARED_REQ_AND_BU_COUNT: 'api/company/getsharedrequisitionandbucounts',
    DELETE_CORP_VENDOR_RELATIONSHIP: 'api/company/deletecorporatevendorrelationship',
    GET_ALL_CANDIDATES_REPORT_DETAILS: 'api/company/getallcandidatereportdetails',
    GET_WORKFLOW_STEP_CLIENT_OR_BU_DETAILS: 'api/company/getclientorbudetails',
    HAS_ACCESS_TO_UPLOAD_RESUME: 'api/company/hasaccesstoaddcandidatewithorwithoutresume',
    GET_SHARED_CLIENT_DETAILS: 'api/company/getsharedclientdetails',
    GET_VENDORS_BY_USER_SCOPE: 'api/company/getvendorsbyuserscope',
    GET_CLIENT_OR_BU_POCS: 'api/company/getclientorbupointofcontacts',
    GET_SHARED_CLIENT_BUS: 'api/company/getsharedclientbus',
    UPLOAD_COMPANY_LOGO: 'api/company/uploadcompanylogo',
    GET_USER_CONFIGURATION: 'api/company/getDefaultUserConfiguration',
    SAVE_USER_CONFIGURATION: 'api/company/saveUserConfiguration',
    GET_CLIENTS_OR_BUS_HAVING_CUSTOM_SETTINGS: 'api/company/getclientsorbuwithoutglobalsetting/',
    CREATE_ROLE_ENTITY: 'api/company/createroleentityscope',
    UPDATE_ROLE_ENTITY: 'api/company/updateroleentityscope',
    GET_COMPANIES_FOR_USER: 'api/company/getallcompanies',
    GET_ALL_CLIENTS_OR_BUS_BY_SCOPE: 'api/company/getAllClientOrBusByScope',
    GET_ALL_VENDORS_BY_SCOPE: 'api/company/getVendorsByScope',
    GET_COMPANY_REPORTS: 'api/company/getCompanyReports',
    GET_ALL_STAFFING_COMPANIES_FOR_REPORT: 'api/company/getAllStaffingCompanies',
    GET_ALL_CORPORATE_COMPANIES_FOR_REPORT: 'api/company/getAllCorporateCompanies',
    CHECK_SYNC_ASSESSMENT_AVAILABLE: 'api/company/checkSyncAssessmentAvailable',
    IS_ASSESSMENT_CREDENTIALS_VALID: 'api/company/verifyAssessmentCredentials',
    SET_SHARED_CLIENT_DESCRIPTION: 'api/company/saveCorporateClientDescription',
    GET_SHARED_CLIENT_DESCRIPTION: 'api/company/getCorporateClientDescription',
    GET_ALL_C2C_COMPANIES: 'api/company/getc2ccompanies',
    GET_C2C_COMPANY_DETAILS: 'api/company/getc2ccompanies'
  }

  constructor(@Inject('$rootScope') public $rootScope:any, @Inject(GenericService) private genericService: GenericService,
    @Inject(StorageService) private StorageService: StorageService, @Inject(AuthService) private authService: AuthService) { }

  getCompanyDetails(companyId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_COMPANY + '/' + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getDeleteCompanyInfo(companyId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_DELETE_COMPANY_INFO + '/' + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  deleteCompany(deleteCompanyObject, successCallback, errorCallback) {
    this.genericService.deleteObject(this.StorageService.get('baseurl') + this.urlConstants.DELETE_COMPANY, deleteCompanyObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllStaffingCompanies(filterObject, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_STAFFING_COMPANIES + '?name=' + filterObject.searchTerm + '&page=' + filterObject.page + '&size=' + filterObject.size + '&sort=' + filterObject.sort + '&sortDir=' + filterObject.sortDir).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllCorporateVendors(companyId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_CORPORATE_VENDORS + '/' + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  saveCorporateVendor(associationObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.SAVE_CORPORATE_VENDOR, associationObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  saveCorporateVendors(associationObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.SAVE_CORPORATE_VENDORS, associationObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllBuVendors(companyId, buId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_BU_VENDORS + '/' + companyId + '/' + buId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  saveBuVendors(associationObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.SAVE_BU_VENDORS, associationObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getVendorContacts(vendorId, corporateId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_VENDOR_CONTACTS + "/" + vendorId + "/" + corporateId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllBusOfClient(vendorId, corporateCompanyId, userId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_BUS_OF_CLIENT + '?vendorId=' + vendorId + '&corporateCompanyId=' + corporateCompanyId + '&userId=' + userId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getClientContactDetails(vendorId, clientId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_CLIENT_CORPORATE_CONTACT_DETAILS + '?vendorId=' + vendorId + '&corporateCompanyId=' + clientId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  updateClientCorporateContactDetails(contactObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.UPDATE_CLIENT_CORPORATE_CONTACT_DETAILS, contactObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  changeCompanyState(companyStateChangeObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.CHANGE_COMPANY_STATE, companyStateChangeObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getSharedReqAndBuCount(infoObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_SHARED_REQ_AND_BU_COUNT, infoObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  deleteCorpVendorRelationship(deleteObject, successCallback, errorCallback) {
    this.genericService.deleteObject(this.StorageService.get('baseurl') + this.urlConstants.DELETE_CORP_VENDOR_RELATIONSHIP, deleteObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllCandidatesReportDetails(reportsFilterObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_CANDIDATES_REPORT_DETAILS, reportsFilterObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  hasAccessToUploadResume(companyId, clientOrBUID, successCallback, errorCallback) {
    let url = this.StorageService.get('baseurl') + this.urlConstants.HAS_ACCESS_TO_UPLOAD_RESUME + '/' + companyId;
    if (!_.isNull(clientOrBUID)) {
      url = url + '?clientOrBuId=' + clientOrBUID;
    }
    this.genericService.getObjects(url).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }


  getVendorsByUserScope(companyId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_VENDORS_BY_USER_SCOPE + '/' + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getWorkFlowStepClientOrBuDetails(stepDetails, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_WORKFLOW_STEP_CLIENT_OR_BU_DETAILS, stepDetails).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getSharedClientDetails(companyId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_SHARED_CLIENT_DETAILS + '/' + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getClientOrBuPocs(corporateId, clientOrBuId, successCallback, errorCallback) {
    let url = this.urlConstants.GET_CLIENT_OR_BU_POCS;
    if (!_.isNull(corporateId)) {
      url = url + '?corporateId=' + corporateId;
    }
    if (!_.isNull(clientOrBuId)) {
      let clientOrBuIdQueryParam = !_.isNull(corporateId) ? '&clientOrBuId=' + clientOrBuId : '?clientOrBuId=' + clientOrBuId;
      url = url + clientOrBuIdQueryParam;
    }
    this.genericService.getObjects(this.StorageService.get('baseurl') + url).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getSharedClientBus(currentCompanyId, sharedClientId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_SHARED_CLIENT_BUS + '/' + currentCompanyId + '/' + sharedClientId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  uploadCompanyLogo(imageInfo, companyId, successCallback, errorCallback) {
    let headers = new HttpHeaders()
    .set('content-Type', undefined)
    let url = this.StorageService.get('baseurl') + this.urlConstants.UPLOAD_COMPANY_LOGO + '/' + companyId;
    this.genericService.addObjectWithHeaders(url, imageInfo, headers
    //   {
    //   transformRequest: angular.identity,
    //   headers: { 'Content-Type': undefined }
    // }

    ).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getUserConfiguration(successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_USER_CONFIGURATION).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  saveUserConfiguration(userConfiguration, companyId, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.SAVE_USER_CONFIGURATION + '/' + companyId, userConfiguration).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getClientsOrBusHavingCustomSettings(companyId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_CLIENTS_OR_BUS_HAVING_CUSTOM_SETTINGS + '/' + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  createRoleEntityScope(companyId, roleEntityScope, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.CREATE_ROLE_ENTITY + "/" + companyId, roleEntityScope).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  updateRoleEntityScope(companyId, roleEntityScope, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.UPDATE_ROLE_ENTITY + "/" + companyId, roleEntityScope).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllCompanies(successCallback, errorCallback) {
    let page = 0;
    let size = 2000;
    let sort = 'name';
    let sortDir = 'asc';
    let companiesForLoggedInUser = [];
    this.genericService.getObjects(this.urlConstants.GET_COMPANIES_FOR_USER + '/' + this.authService.getUserDetails().roleScreenRef.role.id + '/' + this.authService.getUserDetails().id + '?page=' + page + '&size=' + size + '&sort=' + sort + '&sortDir=' + sortDir).subscribe((data) => {
      successCallback(data.body);
    }, function (error) {
      errorCallback(error.error);
    });
  }

  getAllStaffingCompaniesForReport(companiesFilter, successCallback, errorCallback) {
    this.genericService.addObject(this.urlConstants.GET_ALL_STAFFING_COMPANIES_FOR_REPORT, companiesFilter).subscribe((data) => {
      successCallback(data.body);
    }, function (error) {
      errorCallback(error.error);
    });
  }

  getAllCorporateCompaniesForReport(companiesFilter, successCallback, errorCallback) {
    this.genericService.addObject(this.urlConstants.GET_ALL_CORPORATE_COMPANIES_FOR_REPORT, companiesFilter).subscribe((data) => {
      successCallback(data.body);
    }, function (error) {
      errorCallback(error.error);
    });
  }

  getAllClientsOrBusByScope(clientsOrBusFilter, successCallback, errorCallback) {
    this.genericService.addObject(this.urlConstants.GET_ALL_CLIENTS_OR_BUS_BY_SCOPE, clientsOrBusFilter).subscribe((data) => {
      successCallback(data.body);
    }, function (error) {
      errorCallback(error.error);
    });
  }

  getAllVendorsByScope(buIds, companyId, successCallback, errorCallback) {
    this.genericService.addObject(this.urlConstants.GET_ALL_VENDORS_BY_SCOPE + '/' + companyId, buIds).subscribe((data) => {
      successCallback(data.body);
    }, function (error) {
      errorCallback(error.error);
    });
  }

  getCompanyReports(filters, successCallback, errorCallback) {
    this.genericService.addObject(this.urlConstants.GET_COMPANY_REPORTS, filters).subscribe((data) => {
      successCallback(data.body);
    }, function (error) {
      errorCallback(error.error);
    });
  }

  isSyncAssessmentsAvailable(companyId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.CHECK_SYNC_ASSESSMENT_AVAILABLE + '/' + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  isAssessmentCredentialsValid(companyId, assessmentCredentials, successCallback, errorCallback) {
    this.genericService.addObject(this.urlConstants.IS_ASSESSMENT_CREDENTIALS_VALID + '/' + companyId, assessmentCredentials).subscribe((data) => {
      successCallback(data.body);
    }, function (error) {
      errorCallback(error.error);
    });
  }

  setSharedClientDescription(descriptionDetails, successCallback, errorCallback) {
    this.genericService.addObject(this.urlConstants.SET_SHARED_CLIENT_DESCRIPTION, descriptionDetails).subscribe((data) => {
      successCallback(data.body);
    }, function (error) {
      errorCallback(error.error);
    });
  }

  getSharedClientDescription(companyId, clientId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_SHARED_CLIENT_DESCRIPTION + '/' + companyId + '/' + clientId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  // common methods
  // uib modal services changed to uibModalServices and should add here after migrating $uibModal to NgbModal
  // showAllCompanies() {
  //   $uibModal.open({
  //     animation: true,
  //     ariaLabelledBy: 'modal-title',
  //     ariaDescribedBy: 'modal-body',
  //     windowTopClass: 'four-dot-five-modal-extra-small',
  //     component: 'companies',
  //     resolve: {}
  //   });
  // }

  getParsedCompanyLocationText(location, isHtml) {
    let locationText = "";
    if (isHtml) {
      if (location.city && location.city.name) {
        locationText = '<span><b> @ </b>' + location.city.name + '</span>';
      }
      if (location.state) {
        locationText = locationText + '<span>, ' + location.state + '</span>';
      }
    } else {
      if (!(_.isNull(location.city) && _.isNull(location.city.name))) {
        locationText = " @ " + location.city.name;
      }
      if (!_.isNull(location.state)) {
        locationText = locationText + ", " + location.state;
      }
    }
    return locationText;
  }

  isHostCompany() {
    return this.authService.getUserDetails() && this.authService.getUserDetails().company.companyType === 'Host';
  }

  isStaffingCompany() {
    return this.authService.getUserDetails() && this.authService.getUserDetails().company.companyType === 'StaffingCompany';
  }

  isCorporateCompany() {
    return this.authService.getUserDetails() && this.authService.getUserDetails().company.companyType === 'Corporation';
  }

  isCompanyVendor() {
    return this.authService.getUserDetails() && this.authService.getUserDetails().plan.planName === 'Vendor Only';
  }

  setCompanyStateLabel() {
    let userDetails = this.authService.userDetails ? this.authService.userDetails : this.authService.getUserDetails();
    if (userDetails.company.companyState === 'SoftHold' || this.$rootScope.userDetails.company.companyState === 'SoftHold') {
      this.$rootScope.userDetails.company.companyStateLabel = this.$rootScope.userDetails.company.companyState === 'SoftHold' ? 'Soft Hold' : this.$rootScope.userDetails.company.companyState; 
      this.authService.setCompanyStateLabel('Soft Hold');
    } else {
      this.$rootScope.userDetails.company.companyStateLabel = this.$rootScope.userDetails.company.companyState;
      this.authService.setCompanyStateLabel(userDetails.company.companyState);
    }
  }

  getAllC2CCompanies(companyId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_C2C_COMPANIES + '/' + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getC2CCompanyDetails(companyId, c2cCompanyName, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_C2C_COMPANY_DETAILS + '/' + companyId + "?c2ccompanyName=" + c2cCompanyName).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }
}

import { downgradeComponent } from "@angular/upgrade/static";
import { ChangePasswordPageComponent } from "./change-password-page.component";

export const ChangePasswordPageAjsModule = angular
    .module('changePasswordPage', [])
    .directive('changePasswordPage', downgradeComponent({ component: ChangePasswordPageComponent }))
    .config(
        ['$stateProvider', ($stateProvider) => {
            $stateProvider.state('missioncontrol.changePassword', {
                url: '/changePassword',
                component: ChangePasswordPageComponent,
                data: {
                    requireLogin: true
                },
                params: {
                    message: null,
                    policyChangePasswordUpdate: false
                }
            });
        }
        ])
    .constant('CHANGE_PASSWORD_CONSTANTS', {
        CONTROLLER: {
            CHANGE_PASSWORD: 'api/access/changepassword',
            AUTHENTICATE_OTP: 'auth/verifyonetimepassword'
        }
    })
    .name; 
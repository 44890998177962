import { Component, OnInit, ViewChild, Inject, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CompanyContactDetails } from 'src/app/core/models/companyContactDetails';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AngularModalService } from 'src/app/core/services/modal.service';
import { MESSAGECONSTANTS } from 'src/app/core/utils/constants';

@Component({
  selector: 'app-add-new-company-details',
  templateUrl: './add-new-company-details.component.html',
  styleUrls: ['./add-new-company-details.component.css']
})
export class AddNewCompanyDetailsComponent implements OnInit {
  @ViewChild('addNewCompanyModalForm', null) addNewCompanyModalForm: NgForm;
  @Input() readOnly;
  @Input() contactDetails;
  @Input() elementId;
  @Output() saveCallback = new EventEmitter<any>();
  @Output() cancelCallback = new EventEmitter<any>();

  title: string;
  closeBtnName: string;
  addEditCompanyObject: any = {};
  phonenumber: any = '';
  phoneNumberFormat: any = '';
  contactPhoneNumber: any = '';
  isPhoneNumberInvalid: boolean = false;
  isCompanyName: boolean = true;
  MESSAGECONSTANTS: any = MESSAGECONSTANTS;

  constructor(private modalService: AngularModalService, private alertsAndNotificationsService: AlertAndNotificationsService) { }

  ngOnInit() {
    if (!this.contactDetails) {
      this.addEditCompanyObject = { ...new CompanyContactDetails() }
    } else {
      this.addEditCompanyObject = this.contactDetails;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.contactDetails) {
        this.addEditCompanyObject = changes.contactDetails.currentValue;
      }
    }
  }

  AddEditCompanyDetails(state) {
    this.isCompanyName = false;
    if (this.addEditCompanyObject.companyName && this.addEditCompanyObject.companyName.length > 0) {
      this.saveCallback.emit(this.addEditCompanyObject);
      if(state === 'add') {
        this.alertsAndNotificationsService.showBannerMessage("Company added successfully", "success");
      }
      this.modalService.addModalOpenClassToBodyIfAnyModalIsOpen();
    } else {
      this.isCompanyName = false;
      // this.addNewCompanyModalForm.onSubmit(undefined)
    }
  }

  cancel() {
    this.cancelCallback.emit();
  }

}

import { Component, OnInit, Inject, Input } from '@angular/core';

@Component({
  selector: 'app-candidate-job-comparison-page',
  templateUrl: './candidate-job-comparison-page.component.html',
  styleUrls: ['./candidate-job-comparison-page.component.css']
})
export class CandidateJobComparisonPageComponent implements OnInit {

  @Input() $transition$: any;

  stateParams: any;
  jobId: String;
  jobMatchId: String;
  candidateId: String;

  constructor(
    @Inject('$state') public $state: any
  ) { }

  ngOnInit() {
    this.stateParams = this.$transition$.params();
    this.jobId = this.stateParams.jobId;
    this.jobMatchId = this.stateParams.jobMatchId;
    this.candidateId = this.stateParams.candidateId;
  }

  redirectToUrl(url, isReloadRequired) {
    this.$state.go(url, null, { reload: isReloadRequired });
  }

}

<!-- External note choices -->
<div class="row form-group" style="margin-left:0px;">
    <div class="col-xs-4 checkbox-nice">
        <input type="checkbox" name="addExternalNotes" id="addExternalNotes" [checked]="addExternalNotes"
            [(ngModel)]="addExternalNotes" (change)="toggleAddExternalNotes()" />
        <label class="license-pref-label" for="addExternalNotes">{{addNoteLabel}}</label>
    </div>
    <div class="col-xs-8 checkbox-nice">
        <input type="checkbox" name="copyNote" id="copyNote" [checked]="copyNote" [(ngModel)]="copyNote"
            (change)="toggleCopyToExternalNotes()" />
        <label class="license-pref-label" for="copyNote">{{copyNoteLabel}}</label>
    </div>
</div>

<!-- External note -->
<div class="form-group" *ngIf="addExternalNotes" [ngClass]="{'has-error': form.submitted && formExternalNote.invalid }">
    <label>{{externalNoteLabel}}<em *ngIf="externalNoteLabel=='External Notes'"> * </em></label>
    <div>
        <textarea #formExternalNote="ngModel" name="formExternalNote" class="form-control external-notes"
            maxlength="503" [(ngModel)]="externalNote" required (change)="onExternalNoteChanged()">
       </textarea>
    </div>

    <div class="error error-gap" *ngIf="form.submitted">
        <small *ngIf="formExternalNote.errors?.required" class=""><em>*</em>
            External Note is required
        </small>
    </div>
</div>
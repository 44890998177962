<div class="container">
    <div class="row">
        <div class="col-xs-12 candidate-job-match-card-report-label">
            <span *ngIf="!loadingFlag">
                <span>{{reportTitle}}</span> of '<span class="emerald">{{jobMatch.name}}</span>' for the role of '<span class="emerald">{{jobMatch.jobMatchTitle}}</span>'
                 <span *ngIf="jobMatch.jobMatchClientName">at '<span class="emerald">{{jobMatch.jobMatchClientName}}</span>'</span>
            </span>
        </div>
    </div>
    <div class="row" *ngIf="reportLoadingFlag || loadingFlag">
        <div class="col-xs-12 loading-spinner text-center">
            <span>Loading </span>
            <span class="fa fa-spinner fa-spin"></span>
        </div>
    </div>
    <div class="row" *ngIf="!reportLoadingFlag">
        <div class="col-xs-12">
            <object [data]="embedUrl" type="application/pdf" style="width:100%; height:500px;">
                <embed [src]="url" type="application/pdf" width="100%" height="500px" />
            </object>
        </div>
    </div> 
</div>
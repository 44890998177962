import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'fourDotFiveDateFormatPipe'
})
export class FourDotFiveDateFormatPipe implements PipeTransform {

  transform(value: any): any {
    return moment(value).format("MMM DD, YYYY");
  }

}

import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { AngularModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-employee-card-actions-modal',
  templateUrl: './employee-card-actions-modal.component.html',
  styleUrls: ['./employee-card-actions-modal.component.css']
})
export class EmployeeCardActionsModalComponent implements OnInit {

  modalData: any;
  public onClose: Subject<any>;

  constructor(
    private bsModalRef: BsModalRef,
    private modalService: AngularModalService,
    public bsModalService: BsModalService
  ) {
    this.onClose = new Subject();
    if (this.bsModalService.config.initialState) {
      this.modalData = this.bsModalService.config.initialState;
    }
  }

  ngOnInit() {
  }

  closeModal(data) {
    this.onClose.next(data);
    this.bsModalRef.hide();
  }

  saveCallback(event) {
    this.closeModal(event);
  }

  cancelCallback() {
    this.closeModal(null)
  }

}

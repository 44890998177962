import {
  AsyncScheduler,
  AudioVideoFacade,
  AudioVideoObserver,
  ClientMetricReport,
  ConsoleLogger,
  ContentShareObserver,
  DataMessage,
  DefaultActiveSpeakerPolicy,
  DefaultAudioMixController,
  DefaultDeviceController,
  DefaultMeetingSession,
  DefaultModality,
  Device,
  DefaultBrowserBehavior,
  DeviceChangeObserver,
  LogLevel,
  Logger,
  MultiLogger,
  MeetingSession,
  MeetingSessionConfiguration,
  MeetingSessionPOSTLogger,
  MeetingSessionStatus,
  MeetingSessionStatusCode,
  MeetingSessionVideoAvailability,
  TimeoutScheduler,
  Versioning,
  VideoTileState,
  ClientVideoStreamReceivingReport,
} from 'amazon-chime-sdk-js';

declare var bootbox:any;
declare var global: any;

class AudioVideoConference {

  //bindings
  private candidateId: string;
  private jobId: string;
  private jobMatchId: string;
  private scheduleId: string;
  private meetingLink: string;
  private participantName: string;
  private participantType: string;
  private type: string;
  private mode: string;
  private workflowStep: string;
  private scheduleInfo: any;
  private scheduleObject: any;
  private jobMatchObject: any;

  participantId: string;
  workflowStepName: string;
  app: any;
  test: any;
  videoCount: Array<number> =[];
  chimeWebsocketConnectEvent: any;


  /*@ngInject*/
  constructor(private $rootScope: any, 
      private $state: any, 
      private $timeout: ng.ITimeoutService, 
      // private $uibModal: ng.ui.bootstrap.IModalService){
      private ngToast: any,
      private $uibModal: any,
      private webSocketsService: any,
      private userRoleService: any,
      private StorageService: any,
      private meetingService: any,
      private utilityService: any,
      private alertsAndNotificationsService: any,
      private jobMatchService: any){
  }

  $onDestroy(){
    this.webSocketsService.disconnectForChime();
    if(this.chimeWebsocketConnectEvent) {
      this.chimeWebsocketConnectEvent();
    }
  }

  $onInit(){
    // this.ngToast.success({
    //   content: '<a href="#" class="">a message</a>',
    //   verticalPosition: 'bottom',
    //   horizontalPosition: 'right'
    // });
    // const waitingParticipants =   [
    //   {
    //     meetingScheduleId: "5faca367766e6d4cd8edc2fa",
    //   name: "JosephJames",
    //   participantId: "5eb51f2f80ee224552f3e5ab",
    //   status: "CANDIDATE_IN_WAIT"
    //   },
    //   {
    //     meetingScheduleId: "5faca367766e6d4cd8edc2fa",
    //   name: "re James",
    //   participantId: "5eb51f2f80ee224552f3e5a1b",
    //   status: "REC_WAIT"
    //   }
    // ];
  //   let actionType = "waitingParticipants";
  //   let modal = this.$uibModal.open({
  //     animation: true,
  //     ariaLabelledBy: 'modal-title',
  //     ariaDescribedBy: 'modal-body',
  //     backdrop: 'static',
  //     windowTopClass: 'four-dot-five-modal-medium',
  //     component: "addParticipantModal",
  //     size: 'lg',
  //     resolve: {
  //       waitingParticipants: () => {
  //         return waitingParticipants;
  //       },
  //       actionType: () => {
  //         return actionType;
  //       }
  //     }
  // });
  //   let video = $('#video-16') as any; 
  //   video.on('mousedown', function (e) {

  //     var wrapper = $('#tile-16') as any,      // calc relative mouse pos
  //         rect = wrapper[0].getBoundingClientRect(),
  //         y = e.clientY - rect.top;
  // let el = video[0] as any;
  //     if (y > el.height - 40) {   // if in ctrl zone disable drag
  //         wrapper.draggable('disable');
  //     }
  // });
  
  // video.on('mouseup', function (e) {
  //   let tile = $('#tile-16') as any;
  //     tile.draggable('enable'); // always enable
  // });


    // this.utilityService.toggleHeaderNavDisplay(false);
    
    for(let i = 0; i<=17; i++){
      this.videoCount.push(i);
    }
    if(this.participantType == 'c'){
      this.participantId = this.candidateId;
    } else {
      this.participantId = this.$rootScope.userDetails.id;
    }
    if(this.workflowStep == 'ps'){
      this.workflowStepName = "Phone Screen";
    } else if(this.workflowStep == 'in'){
      this.workflowStepName = " Interview";
    } else if(this.workflowStep == 'rs'){
      this.workflowStepName = "Recruiter Screening";
    }
    this.scheduleInfo.candidateDetail.name = `${this.scheduleInfo.candidateDetail.firstName} ${this.scheduleInfo.candidateDetail.lastName}`;  
    this.$timeout(()=>{
      this.app = new MeetingApp(this.$rootScope, this.$state, this.$timeout, this.$uibModal, this.ngToast, this.webSocketsService, this.userRoleService, this.meetingService, this.utilityService, this.StorageService, this.alertsAndNotificationsService, this.mode, this.scheduleId, this.participantId, this.participantName, this.participantType, this.scheduleInfo.usersListToDisplay, this.scheduleInfo, this.jobMatchService, this.jobId, this.candidateId);
      
      this.chimeWebsocketConnectEvent = this.$rootScope.$on("CHIME_WEBSOCKET_CONNECTED", (event, message) => {
        this.app.refreshConference();
      });
    },500);
  }

  goToJoinMeeting(){
    this.app.switchToFlow('flow-authenticate');
    this.app.hideProgress('progress-authenticate');
  }

  showFullScreen(videoId){
    // const btn = event.target;
    // if (btn.tagName.toLowerCase() !== "button") return;
    // const id = btn.textContent;
    const div = document.getElementById(videoId) as any;
    if (div.requestFullscreen) 
        div.requestFullscreen();
    else if (div.webkitRequestFullscreen) 
        div.webkitRequestFullscreen();
    else if (div.msRequestFullScreen) 
      div.msRequestFullScreen();
  }
  addParticipant(){
    let scheduleType = 'phoneScreen';
    if(this.workflowStep == 'in'){
      scheduleType = "interview";
    }
    let modal = this.$uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        backdrop: 'static',
        windowTopClass: 'four-dot-five-modal-medium',
        component: "addParticipantModal",
        size: 'lg',
        resolve: {
            scheduleId: () => {
                return this.scheduleId;
            },
            jobMatchId: () =>{
              return this.jobMatchObject.jobMatchId;
            },
            jobMatchObject: () =>{
              return this.jobMatchObject;
            },
            scheduleObject: () => {
              return this.scheduleObject;
            },
            scheduleType: () => {
              return scheduleType;
            },
            actionType: () => {
              return "inviteUsers";
            }
        }
    });
  }
}

AudioVideoConference.$inject = ['$rootScope', '$state','$timeout', 'ngToast', '$uibModal', 'webSocketsService', 'userRoleService', 'StorageService', 'meetingService', 'utilityService', 'alertsAndNotificationsService', 'jobMatchService'];
export const AudioVideoConferenceComponent = {
  selector: 'audioVideoConference',
  component: {
      bindings:{
          candidateId: '@',
          jobId: '@',
          jobMatchId: '@',
          scheduleId: '@',
          scheduleInfo: '=',
          meetingLink: '@',
          participantType: '@',
          participantName: '@',
          // type: '@',
          workflowStep: '@',
          mode: '@',
          jobMatchObject: '=',
          scheduleObject: '='
      },
      templateUrl: 'app/public/app/partials/missioncontrol/conference/conference/audioVideoConference/audio-video-conference.html',
      controller: AudioVideoConference
  }
}

class DemoTileOrganizer {
  // this is index instead of length
  static MAX_TILES = 17;
  static LOCAL_VIDEO_INDEX = 16;
  public tiles: { [id: number]: number } = {};
  public tileStates: {[id: number]: boolean } = {};
  public remoteTileCount = 0;

  acquireTileIndex(tileId: number, isLocalTile: boolean): number {
    for (let index = 0; index <= DemoTileOrganizer.MAX_TILES; index++) {
      if (this.tiles[index] === tileId) {
        return index;
      }
    }
    for (let index = 0; index <= DemoTileOrganizer.MAX_TILES; index++) {
      if (!(index in this.tiles)) {
        if (isLocalTile) {
          this.tiles[DemoTileOrganizer.LOCAL_VIDEO_INDEX] = tileId;
          return DemoTileOrganizer.LOCAL_VIDEO_INDEX;
        }
        this.tiles[index] = tileId;
        this.remoteTileCount++;
        return index;
      }
    }
    throw new Error('no tiles are available');
  }

  releaseTileIndex(tileId: number): number {
    for (let index = 0; index <= DemoTileOrganizer.MAX_TILES; index++) {
      if (this.tiles[index] === tileId) {
        this.remoteTileCount--;
        delete this.tiles[index];
        return index;
      }
    }
    return DemoTileOrganizer.MAX_TILES;
  }
}

class TestSound {
  constructor(
    sinkId: string | null,
    frequency: number = 440,
    durationSec: number = 1,
    rampSec: number = 0.1,
    maxGainValue: number = 0.1    
  ) {
    // @ts-ignore
    const audioContext: AudioContext = new (window.AudioContext || window.webkitAudioContext)();
    const gainNode = audioContext.createGain();
    gainNode.gain.value = 0;
    const oscillatorNode = audioContext.createOscillator();
    oscillatorNode.frequency.value = frequency;
    oscillatorNode.connect(gainNode);
    const destinationStream = audioContext.createMediaStreamDestination();
    gainNode.connect(destinationStream);
    const currentTime = audioContext.currentTime;
    const startTime = currentTime + 0.1;
    gainNode.gain.linearRampToValueAtTime(0, startTime);
    gainNode.gain.linearRampToValueAtTime(maxGainValue, startTime + rampSec);
    gainNode.gain.linearRampToValueAtTime(maxGainValue, startTime + rampSec + durationSec);
    gainNode.gain.linearRampToValueAtTime(0, startTime + rampSec * 2 + durationSec);
    oscillatorNode.start();
    const audioMixController = new DefaultAudioMixController();
    // @ts-ignore
    audioMixController.bindAudioDevice({ deviceId: sinkId });
    audioMixController.bindAudioElement(new Audio());
    audioMixController.bindAudioStream(destinationStream.stream);
    new TimeoutScheduler((rampSec * 2 + durationSec + 1) * 1000).start(() => {
      audioContext.close();
    });
  }
}

export enum ContentShareType {
  ScreenCapture,
  VideoFile,
};


export class MeetingApp implements AudioVideoObserver, DeviceChangeObserver, ContentShareObserver {
static readonly DID: string = '+17035550122';
// static readonly BASE_URL: string = [location.protocol, '//', location.host, location.pathname.replace(/\/*$/, '/').replace('/v2', '')].join('');
static readonly BASE_URL: string =  '127.0.0.1:8081';
static testVideo: string = 'https://upload.wikimedia.org/wikipedia/commons/transcoded/c/c0/Big_Buck_Bunny_4K.webm/Big_Buck_Bunny_4K.webm.360p.vp9.webm';
static readonly LOGGER_BATCH_SIZE: number = 85;
static readonly LOGGER_INTERVAL_MS: number = 2000;
static readonly DATA_MESSAGE_TOPIC: string = "All";
static readonly DATA_MESSAGE_LIFETIME_MS: number = 300000;

showActiveSpeakerScores = false;
activeSpeakerLayout = true;
meeting: string | null = null;
allMeetingInfo:any = null;
allAttendeeInfo:any = null;
name: string | null = null;
voiceConnectorId: string | null = null;
sipURI: string | null = null;
region: string | null = null;
meetingSession: MeetingSession | null = null;
audioVideo: AudioVideoFacade | null = null;
tileOrganizer: DemoTileOrganizer = new DemoTileOrganizer();
canStartLocalVideo: boolean = true;
defaultBrowserBehaviour: DefaultBrowserBehavior;
// eslint-disable-next-line
roster: any = {};
tileIndexToTileId: { [id: number]: number } = {};
tileIdToTileIndex: { [id: number]: number } = {};
tileArea = document.getElementById('tile-area') as HTMLDivElement;

cameraDeviceIds: string[] = [];
microphoneDeviceIds: string[] = [];

buttonStates: { [key: string]: boolean } = {
  'button-microphone': true,
  'button-camera': false,
  'button-speaker': true,
  'button-content-share': false,
  'button-pause-content-share': false,
};

actionIcons = {
  'button-microphone': {iconId: 'icon-microphone', onIconClass: 'fa-microphone', offIconClass: 'microphone-slash-icon' },
  'button-camera': {iconId: 'icon-camera', onIconClass: 'fa-video', offIconClass: 'slash-icon' },
  'button-speaker': {iconId: 'icon-speaker', onIconClass: 'fa-volume-up', offIconClass: 'speaker-slash-icon' },
  'button-content-share': {iconId: 'icon-content-share', onIconClass: 'fa-tv', offIconClass: 'share-content-slash-icon' },
  'button-pause-content-share': {iconId: 'icon-pause-content-share', onIconClass: 'fa-tv', offIconClass: 'slash-icon' }
};

contentShareType: ContentShareType = ContentShareType.ScreenCapture;

// feature flags
enableWebAudio = false;
enableUnifiedPlanForChromiumBasedBrowsers = true;
enableSimulcast = false;

// markdown = require('markdown-it')({linkify: true});
lastMessageSender: string | null = null;
lastReceivedMessageTimestamp = 0;
// isMuteAll = false;

//bindings
// StorageService: any;
// scheduleId: string;
// participantId: string;
// participantType: string;
// let videoCount = [];
attendees: any;
displayTab: boolean;
displayProfile: boolean;
adjacentProfileViewEnabled: boolean = false;

isLoaded: boolean = false;
displaySchedule: boolean = false;
displayFeedback: boolean = false;
displayCommunication: boolean = false;
chatUsers: any = [];
usersToEditFeedback: any = [];
participantsInTestingRoom: any = [];
selectedChatTopic: string = "All";
isSingleTabChatMode: boolean = true;
lastChatTopic: string = "";
showSelectDevicesModal: boolean = false;
isMeetingInProgress: boolean = false;
allowEditFeedback: boolean = true;
isCandidateActive: boolean = false;
isMicroPhoneOn: boolean = false;
isCameraOn: boolean = false;
isSpeakerOn: boolean = false;
selectedMicroPhoneName: string = "";
selectedCameraName: string = "";
selectedSpeakerName: string = "";
isListeningToChime: boolean = false;
enableEditForAttendeeId: any;
inProgressCandidateCount: any = 0;

constructor(private $rootScope, private $state, private $timeout, private $uibModal, private ngToast, private webSocketsService, private userRoleService, private meetingService, private utilityService, private StorageService: any, private alertsAndNotificationsService, private mode, private scheduleId: string, private participantId: string, private participantName: string, private participantType: string, private usersWithMeetingLink, private scheduleInfo, private jobMatchService, private jobId, private candidateId) {
// constructor() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (global as any).app = this;
  // (document.getElementById('sdk-version') as HTMLSpanElement).innerText =
  //   "amazon-chime-sdk-js@" + Versioning.sdkVersion;  
  this.displayTab = true;
  this.displayProfile = false;
  if(this.mode == 'PHONE' || this.mode == 'FACE_TO_FACE' || this.mode == 'VIDEO'){
    this.displaySchedule = true;
    this.displayFeedback = true;
    this.displayCommunication = true;
  } else if(this.scheduleInfo.status == 'COMPLETED'){
    this.displayFeedback = true;
    this.displayCommunication = true;
  }  
  this.isLoaded = true;
  this.attendees = [];
  this.initEventListeners();
  this.initParameters();
  this.setMediaRegion();
  this.setUpVideoTileElementResizer();
  if (this.isRecorder() || this.isBroadcaster()) {
    new AsyncScheduler().start(async () => {
      this.meeting = new URL(window.location.href).searchParams.get('m');
      this.name = this.isRecorder() ? '«Meeting Recorder»' : '«Meeting Broadcaster»';
        await this.authenticate();
        await this.join();
        this.displayButtonStates();
        this.switchToFlow('flow-meeting');
    });
  } else {
    this.switchToFlow('flow-authenticate');
  }
}

onFeedbackComplete(): void{
  this.scheduleInfo.status = 'COMPLETED';
}

initParameters(): void {
  const meeting = new URL(window.location.href).searchParams.get('m');
  if (meeting) {
    (document.getElementById('inputMeeting') as HTMLInputElement).value = meeting;
    (document.getElementById('inputName') as HTMLInputElement).focus();
  } else {
    (document.getElementById('inputMeeting') as HTMLInputElement).focus();
  }
  this.defaultBrowserBehaviour = new DefaultBrowserBehavior();
}

initEventListeners(): void {
  document.getElementById('form-authenticate').addEventListener('submit', e => {
    e.preventDefault();
    this.meeting = (document.getElementById('inputMeeting') as HTMLInputElement).value;
    // this.name = (document.getElementById('inputName') as HTMLInputElement).value;
    this.name = this.participantName;
    this.region = (document.getElementById('inputRegion') as HTMLInputElement).value;
    new AsyncScheduler().start(
      async (): Promise<void> => {
        let chimeMeetingId: string = '';
        // this.showProgress('progress-authenticate');
        this.utilityService.showLoadingModal("Launching meeting");
          try {
            chimeMeetingId = await this.authenticate();
          } catch (error) {
              this.isLoaded = true;
            if(this.allMeetingInfo.waitForHostToJoin){
              this.switchToFlow('flow-wait-for-host');
            } else if(this.allMeetingInfo.waitForHostToApprove){
              this.switchToFlow('flow-wait-for-host-to-approve');
            } else {
              // const httpErrorMessage = 'UserMedia is not allowed in HTTP sites. Either use HTTPS or enable media capture on insecure sites.';
              const httpErrorMessage = '';
              // (document.getElementById(
              //   'failed-meeting'
              // ) as HTMLDivElement).innerText = `Meeting ID: ${this.meeting}`;
              (document.getElementById('failed-meeting-error') as HTMLDivElement).innerText =
                window.location.protocol === 'http:' ? httpErrorMessage : error.message;
              this.switchToFlow('flow-failed-meeting');
            }
            this.utilityService.hideLoadingModal();
            return;
          }
          // (document.getElementById(
          //   'meeting-id'
          // ) as HTMLSpanElement).innerText = `${this.meeting} (${this.region})`;
          // (document.getElementById(
          //   'chime-meeting-id'
          // ) as HTMLSpanElement).innerText = `Meeting ID: ${chimeMeetingId}`;
          // (document.getElementById(
          //   'mobile-chime-meeting-id'
          // ) as HTMLSpanElement).innerText = `Meeting ID: ${chimeMeetingId}`;
          // (document.getElementById(
          //   'mobile-attendee-id'
          // ) as HTMLSpanElement).innerText = `Attendee ID: ${this.meetingSession.configuration.credentials.attendeeId}`;
          // (document.getElementById(
          //   'desktop-attendee-id'
          // ) as HTMLSpanElement).innerText = `Attendee ID: ${this.meetingSession.configuration.credentials.attendeeId}`;
          // (document.getElementById('info-meeting') as HTMLSpanElement).innerText = this.meeting;
          // (document.getElementById('info-name') as HTMLSpanElement).innerText = this.name;
          // this.switchToFlow('flow-devices');
          // await this.openAudioInputFromSelection();
          await this._goToSelectDevices();
        }
    );
  });

  // document.getElementById('to-sip-flow').addEventListener('click', e => {
  //   e.preventDefault();
  //   this.switchToFlow('flow-sip-authenticate');
  // });

  document.getElementById('form-sip-authenticate').addEventListener('submit', e => {
    e.preventDefault();
    this.meeting = (document.getElementById('sip-inputMeeting') as HTMLInputElement).value;
    this.voiceConnectorId = (document.getElementById(
      'voiceConnectorId'
    ) as HTMLInputElement).value;

    new AsyncScheduler().start(
      async (): Promise<void> => {
        // this.showProgress('progress-authenticate');
        this.utilityService.showLoadingModal("Loading");
        const region = this.region || 'us-east-1';
        try {
          const response = await fetch(
            `${MeetingApp.BASE_URL}join?title=${encodeURIComponent(this.meeting)}&name=${encodeURIComponent(MeetingApp.DID)}&region=${encodeURIComponent(region)}`,
            {
              method: 'POST',
            }
          );
          const json = await response.json();
          const joinToken = json.JoinInfo.Attendee.Attendee.JoinToken;
          this.sipURI = `sip:${MeetingApp.DID}@${this.voiceConnectorId};transport=tls;X-joinToken=${joinToken}`;
          this.switchToFlow('flow-sip-uri');
        } catch (error) {
          (document.getElementById(
            'failed-meeting'
          ) as HTMLDivElement).innerText = `Meeting ID: ${this.meeting}`;
          (document.getElementById('failed-meeting-error') as HTMLDivElement).innerText =
            error.message;
          this.switchToFlow('flow-failed-meeting');
          this.utilityService.hideLoadingModal();
          return;
        }
        const sipUriElement = document.getElementById('sip-uri') as HTMLInputElement;
        sipUriElement.value = this.sipURI;
        // this.hideProgress('progress-authenticate');
        this.utilityService.hideLoadingModal();
      }
    );
  });

  document.getElementById('copy-sip-uri').addEventListener('click', () => {
    const sipUriElement = document.getElementById('sip-uri') as HTMLInputElement;
    sipUriElement.select();
    document.execCommand('copy');
  });

  const audioInput = document.getElementById('audio-input') as HTMLSelectElement;
  audioInput.addEventListener('change', async (_ev: Event) => {
    this.log('audio input device is changed');
    await this.openAudioInputFromSelection();
  });

  const videoInput = document.getElementById('video-input') as HTMLSelectElement;
  videoInput.addEventListener('change', async (_ev: Event) => {
    this.log('video input device is changed');
    try {
      await this.openVideoInputFromSelection(videoInput.value, true);
    } catch (err) {
      this.log('no video input device selected');
    }
  });

  const optionalFeatures = document.getElementById('optional-features') as HTMLSelectElement;
  optionalFeatures.addEventListener('change', async (_ev: Event) => {
    const collections = optionalFeatures.selectedOptions;
    this.enableSimulcast = false;
    this.enableWebAudio = false;
    this.enableUnifiedPlanForChromiumBasedBrowsers = true;
    this.log("Feature lists:");
    for (let i = 0; i < collections.length; i++) {
      // hard code magic
      if (collections[i].value === 'simulcast') {
        this.enableSimulcast = true;
        this.log('attempt to enable simulcast');
        const videoInputQuality = document.getElementById('video-input-quality') as HTMLSelectElement;
        videoInputQuality.value = '720p';
      }
      if (collections[i].value === 'webaudio') {
        this.enableWebAudio = true;
        this.log('attempt to enable webaudio');
      }
    }
  });

  const videoInputQuality = document.getElementById('video-input-quality') as HTMLSelectElement;
  videoInputQuality.addEventListener('change', async (_ev: Event) => {
    this.log('Video input quality is changed');
    switch (videoInputQuality.value) {
      case '360p':
        this.audioVideo.chooseVideoInputQuality(640, 360, 15, 600);
        break;
      case '540p':
        this.audioVideo.chooseVideoInputQuality(960, 540, 15, 1400);
        break;
      case '720p':
        this.audioVideo.chooseVideoInputQuality(1280, 720, 15, 1400);
        break;
    }
    try {
      await this.openVideoInputFromSelection(videoInput.value, true);
    } catch (err) {
      this.log('no video input device selected');
    }
  });

  const audioOutput = document.getElementById('audio-output') as HTMLSelectElement;
  audioOutput.addEventListener('change', async (_ev: Event) => {
    this.log('audio output device is changed');
    await this.openAudioOutputFromSelection();
  });

  document.getElementById('button-test-sound').addEventListener('click', e => {
    e.preventDefault();
    const audioOutput = document.getElementById('audio-output') as HTMLSelectElement;
    new TestSound(audioOutput.value);
  });

  document.getElementById('form-devices').addEventListener('submit', e => {
    e.preventDefault();
    new AsyncScheduler().start(async () => {
      try {
        this.showProgress('progress-join');
        await this.join();
        this.audioVideo.stopVideoPreviewForVideoInput(document.getElementById(
          'video-preview'
        ) as HTMLVideoElement);
        await this._setDeviceSelection("videoInput", null);
        this.audioVideo.chooseVideoInputDevice(null);
        this.hideProgress('progress-join');
        this.switchToFlow('flow-meeting');
        this.displayButtonStates();
        // this.switchToFlow('flow-meeting');
      } catch (error) {
        document.getElementById('failed-join').innerText = `Meeting ID: ${this.meeting}`;
        document.getElementById('failed-join-error').innerText = `Error: ${error.message}`;
      }
    });
  });

  const buttonMute = document.getElementById('button-microphone');
  buttonMute.addEventListener('mousedown', _e => {
    if (this.toggleButton('button-microphone')) {
      this.audioVideo.realtimeUnmuteLocalAudio();
    } else {
      this.audioVideo.realtimeMuteLocalAudio();
    }
  });

  const buttonVideo = document.getElementById('button-camera');
  buttonVideo.addEventListener('click', _e => {
    new AsyncScheduler().start(async () => {
      if (this.toggleButton('button-camera') && this.canStartLocalVideo) {
        try {
          let camera: string = videoInput.value;
          if (videoInput.value === 'None') {
            camera = this.cameraDeviceIds.length ? this.cameraDeviceIds[0] : 'None';
          }
          await this.openVideoInputFromSelection(camera, false);
          this.audioVideo.startLocalVideoTile();
        } catch (err) {
          this.log('no video input device selected');
        }
      } else {
        this.audioVideo.stopLocalVideoTile();
        this.audioVideo.stopVideoPreviewForVideoInput(document.getElementById(
          'video-preview'
        ) as HTMLVideoElement);
        this.hideTile(DemoTileOrganizer.LOCAL_VIDEO_INDEX);
      }
    });
  });

  const buttonPauseContentShare = document.getElementById('button-pause-content-share');
  buttonPauseContentShare.addEventListener('click', _e => {
    if (!this.isButtonOn('button-content-share')) {
      return;
    }
    new AsyncScheduler().start(async () => {
      if (this.toggleButton('button-pause-content-share')) {
        this.audioVideo.pauseContentShare();
      } else {
        this.audioVideo.unpauseContentShare();
      }
    });
  });

  const buttonContentShare = document.getElementById('button-content-share');
  buttonContentShare.addEventListener('click', _e => {
    new AsyncScheduler().start(() => {
      if (!this.isButtonOn('button-content-share')) {
        this.contentShareStart();
      } else {
        this.contentShareStop();
      }
    });
  });

  const buttonSpeaker = document.getElementById('button-speaker');
  buttonSpeaker.addEventListener('click', _e => {
    new AsyncScheduler().start(async () => {
      if (this.toggleButton('button-speaker')) {
        this.audioVideo.bindAudioElement(document.getElementById(
          'meeting-audio'
        ) as HTMLAudioElement);
      } else {
        this.audioVideo.unbindAudioElement();
      }
    });
  });

  const sendMessage = () => {
    new AsyncScheduler().start(() => {
      const textArea = document.getElementById('send-message') as HTMLTextAreaElement;
      let textToSend = textArea.value.trim();
      if (!textToSend) {
        return;
      }
      textArea.value = '';
      if(this.selectedChatTopic == 'All'){
        textToSend = textToSend + "#ALL_EVERYONE";
      } else {
        textToSend = textToSend + this.selectedChatTopic;
      }
      this.audioVideo.realtimeSendDataMessage(MeetingApp.DATA_MESSAGE_TOPIC, textToSend, MeetingApp.DATA_MESSAGE_LIFETIME_MS);
      // echo the message to the handler
      this.dataMessageHandler(new DataMessage(
        Date.now(),
        MeetingApp.DATA_MESSAGE_TOPIC,
        new TextEncoder().encode(textToSend),
        this.meetingSession.configuration.credentials.attendeeId,
        this.meetingSession.configuration.credentials.externalUserId
      ));
    });
  };

  const textAreaSendMessage = document.getElementById('send-message') as HTMLTextAreaElement;
  textAreaSendMessage.addEventListener('keydown', e => {
    if (e.keyCode === 13) {
      if (e.shiftKey) {
        textAreaSendMessage.rows++;
      } else {
        e.preventDefault();
        sendMessage();
        textAreaSendMessage.rows = 1;
      }
    }
  });

  const buttonMeetingEnd = document.getElementById('button-meeting-end');
  buttonMeetingEnd.addEventListener('click', _e => {
    // const confirmEnd = (new URL(window.location.href).searchParams.get('confirm-end')) === 'true';
    
    // if (!window.confirm(prompt)) {
      // if (confirmEnd && !window.confirm(prompt)) {
    //   return;
    // }
    bootbox.hideAll();
    let message = 'Are you sure you want to end the meeting for everyone?';
    if(this.scheduleInfo.status == 'COMPLETED'){
      message = 'The meeting cannot be used after ending it.<br><br>' + message;
    }
    bootbox.confirm({
      closeButton: false,
      title: "<div class='alert alert-warning' style='margin-bottom: 0px;'><i class='fas fa-exclamation-triangle fa-fw fa-lg'></i><strong>Confirm</strong></div>",
      message: message,
      className: "zIndex1060",
      backdrop: true,
      onEscape: false,
      buttons: {
          confirm: {
              label: 'Yes',
              className: 'btn-info'
          },
          cancel: {
              label: 'Cancel',
              className: 'btn-danger'
          }
      },
      callback: (result) => {
          if(result) {
            this._checkAndSaveFeedbackIfMeetingEnded();
            new AsyncScheduler().start(async () => {
              (buttonMeetingEnd as HTMLButtonElement).disabled = true;
              this._cleanUI();
              this.audioVideo.stop();
              await this.endMeeting();
              this.webSocketsService.disconnectForChime();
              (buttonMeetingEnd as HTMLButtonElement).disabled = false;
              // @ts-ignore
              // window.location = window.location.pathname;
              this._exitScreen('meetingEnded');
            }); 
          } else {
            //do nothing
          }
      }
    });
  });

  const buttonMeetingLeave = document.getElementById('button-meeting-leave');
  buttonMeetingLeave.addEventListener('click', _e => {
    new AsyncScheduler().start(async () => {
      (buttonMeetingLeave as HTMLButtonElement).disabled = true;
      await this.leave('meetingEnded');
      (buttonMeetingLeave as HTMLButtonElement).disabled = false;
      // @ts-ignore
      // window.location = window.location.pathname;
      // this.switchToFlow('flow-authenticate');
    });
  });
}

async _goToSelectDevices(){
  this.switchToFlow('flow-devices');
  await this.openAudioInputFromSelection();
  try {
    await this.openVideoInputFromSelection(
      (document.getElementById('video-input') as HTMLSelectElement).value,
      true
    );
  } catch (err) {
    this.log('no video input device selected');
  }
  await this.openAudioOutputFromSelection();
  this.hideProgress('progress-authenticate');
  this.displayButtonStates();
  this.utilityService.hideLoadingModal();
  this.getPrimaryHostAttendeeId();
  this.setFeedbackEnabledAttendeeId();
}


toggleMuteAndUnmuteAll(): void{
  this.showConfirm('Mute all', 'All participants except you will be muted.<br><br>Do you want to continue?', 'warning',() => {
    //send message to participants to mute all
    this.audioVideo.realtimeSendDataMessage(MeetingApp.DATA_MESSAGE_TOPIC, "MUTE_ALL", MeetingApp.DATA_MESSAGE_LIFETIME_MS);
  }, () => {
    //do nothing
  });
  // if(this.isMuteAll){
  //   this.toggleButton('button-microphone', 'on');
  //   this.audioVideo.realtimeUnmuteLocalAudio();
  //   //send message to participants to unmute all
  //   this.audioVideo.realtimeSendDataMessage(MeetingApp.DATA_MESSAGE_TOPIC, "UNMUTE_ALL", MeetingApp.DATA_MESSAGE_LIFETIME_MS);
  // } else {
    //mute self
    // this.toggleButton('button-microphone', 'off');
    // this.audioVideo.realtimeMuteLocalAudio();
  // }
  // this.isMuteAll = !this.isMuteAll;
}

getSupportedMediaRegions(): Array<string> {
  const supportedMediaRegions: Array<string> = [];
  const mediaRegion = (document.getElementById("inputRegion")) as HTMLSelectElement;
  for (var i = 0; i < mediaRegion.length; i++) {
    supportedMediaRegions.push(mediaRegion.value);
  }
  return supportedMediaRegions;
}

async getNearestMediaRegion(): Promise<string> {
  const nearestMediaRegionResponse = await fetch(
    `https://nearest-media-region.l.chime.aws`,
    {
      method: 'GET',
    }
  );
  const nearestMediaRegionJSON = await nearestMediaRegionResponse.json();
  const nearestMediaRegion = nearestMediaRegionJSON.region;
  return nearestMediaRegion;
}

setMediaRegion(): void {
  new AsyncScheduler().start(
    async (): Promise<void> => {
      try {
        const nearestMediaRegion = await this.getNearestMediaRegion();
        if (nearestMediaRegion === '' || nearestMediaRegion === null) {
          throw new Error('Nearest Media Region cannot be null or empty');
        }
        const supportedMediaRegions: Array<string> = this.getSupportedMediaRegions();
        if (supportedMediaRegions.indexOf(nearestMediaRegion) === -1 ) {
          supportedMediaRegions.push(nearestMediaRegion);
          const mediaRegionElement = (document.getElementById("inputRegion")) as HTMLSelectElement;
          const newMediaRegionOption = document.createElement("option");
          newMediaRegionOption.value = nearestMediaRegion;
          newMediaRegionOption.text = nearestMediaRegion + " (" + nearestMediaRegion + ")";
          mediaRegionElement.add(newMediaRegionOption, null);
        }
        (document.getElementById('inputRegion') as HTMLInputElement).value = nearestMediaRegion;
      } catch (error) {
        this.log('Default media region selected: ' + error.message);
      }
    });
}

toggleButton(button: string, state?: 'on' | 'off'): boolean {
  if (state === 'on') {
    this.buttonStates[button] = true;
  } else if (state === 'off') {
    this.buttonStates[button] = false;
  } else {
    this.buttonStates[button] = !this.buttonStates[button];
  }
  this.displayButtonStates();
  return this.buttonStates[button];
}

isButtonOn(button: string) {
  return this.buttonStates[button];
}

displayButtonStates(): void {
  for (const button in this.buttonStates) {
    const element = document.getElementById(button);
    let iconId = this.actionIcons[button].iconId;
    let iconElement = document.getElementById(iconId);
    let actionIndicatorIdElement = document.getElementById(iconId + '-indicator');
    const drop = document.getElementById(`${button}-drop`);
    const on = this.buttonStates[button];
    element.classList.add(on ? 'btn-success' : 'btn-outline-secondary');
    element.classList.remove(on ? 'btn-outline-secondary' : 'btn-success');
    if(this.actionIcons[button].onIconClass !== this.actionIcons[button].offIconClass){
      iconElement.classList.add(on ? this.actionIcons[button].onIconClass : this.actionIcons[button].offIconClass);
      // iconElement.classList.remove(on ? this.actionIcons[button].offIconClass : this.actionIcons[button].onIconClass);
      if(on){
        iconElement.classList.remove(this.actionIcons[button].offIconClass);
        actionIndicatorIdElement.classList.remove('d-none');
      } else{
        actionIndicatorIdElement.classList.add('d-none');
      }
    }
    
    // (element.firstElementChild as SVGElement).classList.add(on ? 'svg-active' : 'svg-inactive');
    // (element.firstElementChild as SVGElement).classList.remove(
    //   on ? 'svg-inactive' : 'svg-active'
    // );
    if (drop) {
      drop.classList.add(on ? 'btn-success' : 'btn-outline-secondary');
      drop.classList.remove(on ? 'btn-outline-secondary' : 'btn-success');
    }
  }
}

showProgress(id: string): void {
  (document.getElementById(id) as HTMLDivElement).style.visibility = 'visible';
}

hideProgress(id: string): void {
  (document.getElementById(id) as HTMLDivElement).style.visibility = 'hidden';
}

switchToFlow(flow: string): void {
  if(flow === 'flow-meeting'){
    // this.utilityService.toggleHeaderNavDisplay(false);
    if(this.allMeetingInfo.participantsOnWait !== null && this.allMeetingInfo.participantsOnWait.length>0){
      this._displayWaitingParticipants(this.allMeetingInfo.participantsOnWait, "waitingParticipants");
    }
  } 

  this.analyserNodeCallback = () => {};
  Array.from(document.getElementsByClassName('flow')).map(
    e => ((e as HTMLDivElement).style.display = 'none')
  );
  (document.getElementById(flow) as HTMLDivElement).style.display = 'block';
  if (flow === 'flow-devices') {
    this.startAudioPreview();
  }
}

audioInputsChanged(_freshAudioInputDeviceList: MediaDeviceInfo[]): void {
  new AsyncScheduler().start(async () => {
    this.selectedMicroPhoneName = "default";
    await this._setDeviceSelection("audioInput", this.selectedMicroPhoneName);
  });
  
  this.populateAudioInputList();
}

videoInputsChanged(_freshVideoInputDeviceList: MediaDeviceInfo[]): void {
  this.populateVideoInputList();
}

audioOutputsChanged(_freshAudioOutputDeviceList: MediaDeviceInfo[]): void {
  new AsyncScheduler().start(async () => {
    this.selectedSpeakerName = "default";
    await this._setDeviceSelection("audioOutput", this.selectedSpeakerName);
  });
  this.populateAudioOutputList();  
}

audioInputStreamEnded(deviceId: string): void {
  this.log(`Current audio input stream from device id ${deviceId} ended.`);
}

videoInputStreamEnded(deviceId: string): void {
  this.log(`Current video input stream from device id ${deviceId} ended.`);
}

estimatedDownlinkBandwidthLessThanRequired(estimatedDownlinkBandwidthKbps: number, requiredVideoDownlinkBandwidthKbps: number ): void {
  this.log(`Estimated downlink bandwidth is ${estimatedDownlinkBandwidthKbps} is less than required bandwidth for video ${requiredVideoDownlinkBandwidthKbps}`);
}

videoNotReceivingEnoughData(videoReceivingReports: ClientVideoStreamReceivingReport[]): void {
  this.log(`One or more video streams are not receiving expected amounts of data ${JSON.stringify(videoReceivingReports)}`);
}

metricsDidReceive(clientMetricReport: ClientMetricReport): void {
  const metricReport = clientMetricReport.getObservableMetrics();
  // if (typeof metricReport.availableSendBandwidth === 'number' && !isNaN(metricReport.availableSendBandwidth)) {
  //   (document.getElementById('video-uplink-bandwidth') as HTMLSpanElement).innerText =
  //     'Available Uplink Bandwidth: ' + String(metricReport.availableSendBandwidth / 1000) + ' Kbps';
  // } else if (typeof metricReport.availableOutgoingBitrate === 'number' && !isNaN(metricReport.availableOutgoingBitrate)) {
  //   (document.getElementById('video-uplink-bandwidth') as HTMLSpanElement).innerText =
  //     'Available Uplink Bandwidth: ' + String(metricReport.availableOutgoingBitrate / 1000) + ' Kbps';
  // } else {
  //   (document.getElementById('video-uplink-bandwidth') as HTMLSpanElement).innerText =
  //     'Available Uplink Bandwidth: Unknown';
  // }

  // if (typeof metricReport.availableReceiveBandwidth === 'number' && !isNaN(metricReport.availableReceiveBandwidth)) {
  //   (document.getElementById('video-downlink-bandwidth') as HTMLSpanElement).innerText =
  //     'Available Downlink Bandwidth: ' + String(metricReport.availableReceiveBandwidth / 1000) + ' Kbps';
  // } else if (typeof metricReport.availableIncomingBitrate === 'number' && !isNaN(metricReport.availableIncomingBitrate)) {
  //   (document.getElementById('video-downlink-bandwidth') as HTMLSpanElement).innerText =
  //     'Available Downlink Bandwidth: ' + String(metricReport.availableIncomingBitrate / 1000) + ' Kbps';
  // } else {
  //   (document.getElementById('video-downlink-bandwidth') as HTMLSpanElement).innerText =
  //     'Available Downlink Bandwidth: Unknown';
  // }
}

async initializeMeetingSession(configuration: MeetingSessionConfiguration): Promise<void> {
  let logger: Logger;
  const logLevel = LogLevel.INFO;
  const consoleLogger = logger = new ConsoleLogger('SDK', logLevel);
  // if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
    logger = consoleLogger;
  // } else {
  //   logger = new MultiLogger(
  //     consoleLogger,
  //     new MeetingSessionPOSTLogger(
  //       'SDK',
  //       configuration,
  //       MeetingApp.LOGGER_BATCH_SIZE,
  //       MeetingApp.LOGGER_INTERVAL_MS,
  //       `${MeetingApp.BASE_URL}logs`,
  //       logLevel
  //     ),
  //   );
  // }
  const deviceController = new DefaultDeviceController(logger);
  configuration.enableWebAudio = this.enableWebAudio;
  configuration.enableUnifiedPlanForChromiumBasedBrowsers = this.enableUnifiedPlanForChromiumBasedBrowsers;
  configuration.attendeePresenceTimeoutMs = 5000;
  configuration.enableSimulcastForUnifiedPlanChromiumBasedBrowsers = this.enableSimulcast;
  this.meetingSession = new DefaultMeetingSession(configuration, logger, deviceController);
  this.audioVideo = this.meetingSession.audioVideo;

  this.audioVideo.addDeviceChangeObserver(this);
  this.setupDeviceLabelTrigger();
  await this.populateAllDeviceLists();
  this.setupMuteHandler();
  this.setupCanUnmuteHandler();
  this.setupSubscribeToAttendeeIdPresenceHandler();
  this.setupDataMessage();
  this.audioVideo.addObserver(this);
  this.audioVideo.addContentShareObserver(this);
  this.initContentShareDropDownItems();
}

setClickHandler(elementId: string, f: () => void): void {
  document.getElementById(elementId).addEventListener('click', () => {
    f();
  });
}

async join(): Promise<void> {
  window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
    this.log(event.reason);
  });
  await this.openAudioInputFromSelection();
  await this.openAudioOutputFromSelection();
  this.audioVideo.start();
  this.$timeout(() => {
    this.displaySchedule = true;
    this.displayFeedback = true;
    this.displayCommunication = true;
  }, 100);
}

updatePhoneScreenOrInterviewInProgress() {
    let payloadObj = {
      jobMatchId: this.scheduleInfo.jobMatchId,
      meetingScheduleId: this.allMeetingInfo.meetingScheduleId,
      jobId: this.jobId,
      candidateId: this.candidateId
    }

    this.jobMatchService.updatePhoneScreenOrInterviewInProgress(payloadObj, (data) => {
      // DO Nothing
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
}

async leave(type): Promise<any> {
  const rosterCount = Object.keys(this.roster).length;
  if(rosterCount<2 || this.usersToEditFeedback.length == 1){
    if(this.participantType !== 'c'){
      document.getElementById('button-meeting-end').click();
    } else {
      this._checkAndSaveFeedbackIfMeetingEnded();
      this.audioVideo.stop();
      this._cleanUI();
      await this.endMeeting();
      this.webSocketsService.disconnectForChime();
      this._exitScreen(type);
    }
  } else if(this.allMeetingInfo.primaryHost && this.usersToEditFeedback.length > 1) {
    let primaryHostChangeDetails = {
      newHostAttendeeId: "",
      oldHostAttendeeId: this.meetingSession.configuration.credentials.attendeeId,
      meetingScheduleId: this.allMeetingInfo.meetingScheduleId,
      externalMeetingCallId: this.allMeetingInfo.externalMeetingCallId,
      attendeeIds: Object.keys(this.roster), // all attendees have to receive the primary host change request
      usersToEditFeedback: this.usersToEditFeedback,
      enableFeedbackEdit: this.allowEditFeedback
    }
    let modal = this.$uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title',
      ariaDescribedBy: 'modal-body',
      backdrop: 'static',
      windowTopClass: 'four-dot-five-modal-medium',
      component: "addParticipantModal",
      resolve: {
        primaryHostChangeDetails: () => {
          return primaryHostChangeDetails;
        },
          actionType: () => {
            return "primaryHostChange";
          }
      }
    });
    modal.result.then((callbackObject) => {
      if(callbackObject.callbackType == 'leaveMeeting') {
        this._checkAndSaveFeedbackIfMeetingEnded();
        new AsyncScheduler().start(async () => {
          await this.leave(type);
        });
      }
    }, dismiss => {
      //do nothing
    });
  } else if(this.participantType !== 'c' && this.allowEditFeedback && this.usersToEditFeedback.length > 1){
    let feedbackDetails = {
      usersToEditFeedback: this.usersToEditFeedback,
      externalMeetingCallId: this.allMeetingInfo.externalMeetingCallId,
      scheduleId: this.scheduleId
    }
    let modal = this.$uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title',
      ariaDescribedBy: 'modal-body',
      backdrop: 'static',
      windowTopClass: 'four-dot-five-modal-medium',
      component: "addParticipantModal",
      resolve: {
        feedbackDetails: () => {
          return feedbackDetails;
        },
          actionType: () => {
            return "enableFeedbackEdit";
          }
      }
    });
    modal.result.then((callbackObject) => {
      if(callbackObject.callbackType == 'leaveMeeting') {
        this._checkAndSaveFeedbackIfMeetingEnded();
        new AsyncScheduler().start(async () => {
          await this.leave(type);
        });
      }
    }, dismiss => {
      //do nothing
    });
  } else {
    this.audioVideo.stop();
    await this.leaveMeeting();
    this.webSocketsService.disconnectForChime();
    this._exitScreen(null);
  }
}

_checkAndSaveFeedbackIfMeetingEnded() {
  this.$rootScope.$emit('SAVE_FEEDBACK_ON_MEETING_END');
}

async leaveMeeting(): Promise<any> {
    let requestPayload = {
      meetingId: this.allMeetingInfo.meeting.meetingId,
      externalMeetingCallId: this.allMeetingInfo.externalMeetingCallId,
      meetingScheduleId: this.allMeetingInfo.meetingScheduleId,
      attendeeId: this.allAttendeeInfo.attendeeId,
      externalUserId: this.allAttendeeInfo.externalUserId
    };

    await fetch(
      this.StorageService.get('baseurl') + 'chime/meeting/deleteattendee',
      {
        headers: {'Content-Type': 'application/json'},
        method: 'POST',
        body: JSON.stringify(requestPayload)
      }
    );
}

setupMuteHandler(): void {
  const handler = (isMuted: boolean): void => {
    this.log(`muted = ${isMuted}`);
  };
  this.audioVideo.realtimeSubscribeToMuteAndUnmuteLocalAudio(handler);
  const isMuted = this.audioVideo.realtimeIsLocalAudioMuted();
  handler(isMuted);
}

setupCanUnmuteHandler(): void {
  const handler = (canUnmute: boolean): void => {
    this.log(`canUnmute = ${canUnmute}`);
  };
  this.audioVideo.realtimeSubscribeToSetCanUnmuteLocalAudio(handler);
  handler(this.audioVideo.realtimeCanUnmuteLocalAudio());
}

updateRoster(): void {
  this.updateWaitingRoster();
  this.attendees = [];
  const roster = document.getElementById('roster');
  // const newRosterCount = Object.keys(this.roster).filter(attendeeId => !attendeeId.includes("#content")).length;
  const newRosterCount = Object.keys(this.roster).length;
  while (roster.getElementsByTagName('li').length < newRosterCount) {
    const li = document.createElement('li');
    li.className = 'list-group-item d-flex justify-content-between align-items-center attendee-font participant-item';
    let nameContainer = document.createElement('span');
    nameContainer.appendChild(document.createElement('span'));
    nameContainer.appendChild(document.createElement('span'));
    li.appendChild(nameContainer);
    let statusContainer = document.createElement('span');
    statusContainer.appendChild(document.createElement('span'));
    statusContainer.appendChild(document.createElement('div'));
    li.appendChild(statusContainer);
    roster.appendChild(li);
  }
  while (roster.getElementsByTagName('li').length > newRosterCount) {
    roster.removeChild(roster.getElementsByTagName('li')[0]);
  }
  const entries = roster.getElementsByTagName('li');
  let i = 0;
  for (const attendeeId in this.roster) {
    let isSelf = attendeeId == this.meetingSession.configuration.credentials.attendeeId;
    let displayName = isSelf ? this.roster[attendeeId].name + ' (Me)' : this.roster[attendeeId].name;;
    let role = isSelf ? ` <b>(Me)</b>` : `<b><b>`;
    role = isSelf && this.allMeetingInfo.primaryHost ? ` <b>(Host, Me)</b>` : role;
    if(!isSelf && this.allMeetingInfo.primaryHostAttendeeId == attendeeId){
      role =  ` <b>(Host)</b>`;
    }
    this.attendees.push({ id: attendeeId, name: this.roster[attendeeId].name });
    
    const spanName = entries[i].getElementsByTagName('span')[1];
    const spanRole = entries[i].getElementsByTagName('span')[2];
    const spanStatusContainer = entries[i].getElementsByTagName('span')[3];
    let spanStatus = entries[i].getElementsByTagName('span')[4];
    const spanActions = entries[i].getElementsByTagName('div')[0];
    const attendeeSpeaking = document.getElementById('attendee-speaking');
    
    //volume indicator
    spanStatus.innerHTML = `
                  <i></i>
                  <i></i>
                  <i></i>`;
    // let statusClass = 'fas fa-microphone';
    let statusClass = 'volume-indicator';
    let statusText = '\xa0'; // &nbsp
    let muteUnMuteActionName = "Mute";
    if (this.roster[attendeeId].signalStrength < 1) {
      statusClass = 'volume-indicator inactive';
    } else if (this.roster[attendeeId].signalStrength === 0) {
      statusClass = 'volume-indicator';
    } else if (this.roster[attendeeId].muted) {
      statusText = '';
      statusClass = 'fas fa-microphone-slash';
      spanStatus.innerHTML = '';
      muteUnMuteActionName = "Ask to Unmute";
    } else if (this.roster[attendeeId].active) {
      statusText = '';
      statusClass = 'volume-indicator active';
      attendeeSpeaking.innerText = 'Speaking: ' +  displayName;
    } else if (this.roster[attendeeId].volume > 0) {
      statusClass = 'volume-indicator active';
      attendeeSpeaking.innerText = 'Speaking: ' +  displayName;
    }

    //Add 'Mute and Remove from meeting' action 
    let muteAttendeeElementId = `mute-attendee-${attendeeId}`;
    let muteAttendeeElement = document.getElementById(muteAttendeeElementId); 
    let removeAttendeeElementId = `remove-attendee-${attendeeId}`;
    let removeAttendeeElement = document.getElementById(removeAttendeeElementId); 
    let actionsDropdownElement = document.getElementById(`drop-down-${removeAttendeeElementId}-cont`);
    if(actionsDropdownElement !== null){
      actionsDropdownElement.remove();
    }
    //roster is any other participant other than self and content share
    if(this.participantName !== this.roster[attendeeId].name && this.participantType == 'u' && !this.roster[attendeeId].name.toLowerCase().includes('content')){
      spanActions.classList.remove('d-none');
      spanActions.classList.add('participant-overlay');
      let dropdownElement = document.createElement('div');
      dropdownElement.setAttribute('id', `drop-down-${removeAttendeeElementId}-cont`);
      dropdownElement.classList.add('participant-actions');
      dropdownElement.innerHTML = 
      `<button class="btn btn-primary attendee-action-item-mute-attendee" id="${muteAttendeeElementId}">${muteUnMuteActionName}</button>
        <button class="btn btn-danger  attendee-action-item-remove-attendee" id="${removeAttendeeElementId}">Remove</button>
      `;
      spanActions.appendChild(dropdownElement);
    } else {
      spanActions.classList.remove('participant-overlay');
      spanActions.classList.add('d-none');
    }
    //update mute/unmute realtime
    if(muteAttendeeElement !== null && muteAttendeeElement){
      muteAttendeeElement.innerText = muteUnMuteActionName;
    }


    let mutedAttendees = 0;
    let attendeesCount = 0;
    for(let attendeeId in this.roster){
      attendeesCount++;
      if(this.roster[attendeeId].muted){
        mutedAttendees++;
      }
    }

    if(attendeesCount == mutedAttendees){
      attendeeSpeaking.innerText = 'Speaking: ';
    }
    this.updateProperty(spanName, 'innerHTML', this.roster[attendeeId].name);
    this.updateProperty(spanName, 'className', 'attendee-name');
    this.updateProperty(spanRole, 'innerHTML', role);
    this.updateProperty(spanStatusContainer, 'className', 'd-flex');
    this.updateProperty(spanStatus, 'className', statusClass);
    this._addRosterActionsHandlers();
    i++;
  }
  this.isCandidateActive = this.attendees.some(attendee => attendee.name === this.scheduleInfo.candidateDetail.name);
  if(this.isCandidateActive && this.inProgressCandidateCount == 0) {
    this.updatePhoneScreenOrInterviewInProgress();
    this.inProgressCandidateCount++;
  }

  let removeFromMeetingActionsDropdown = document.getElementsByClassName('attendee-action-dropdown');
  Array.from(removeFromMeetingActionsDropdown).forEach(element => {
    let actionDropdownId  = element.id;
    let attendeeId = actionDropdownId.replace('drop-down-remove-attendee-', "");
    if(!Object.keys(this.roster).some(id => id == attendeeId)){
      element.remove();
    }
  });
  if(this.meetingSession.configuration){
    this._setChatUsers();
    if(this.participantType !== 'c'){
      this._setUsersToEditFeedback();
    }
  }
}

updateWaitingRoster() {
  for (const attendeeId in this.roster) {
    const index = this.participantsInTestingRoom.findIndex(participantName => participantName === this.roster[attendeeId].name);
    if (index > -1) {
      this.participantsInTestingRoom.splice(index, 1);
    }
  }
  const waitingRoster = document.getElementById('waiting-roster');
  const newRosterCount = this.participantsInTestingRoom.length;
  while (waitingRoster.getElementsByTagName('li').length < newRosterCount) {
    const li = document.createElement('li');
    li.className = 'list-group-item d-flex justify-content-between align-items-center attendee-font participant-item';
    let nameContainer = document.createElement('span');
    nameContainer.appendChild(document.createElement('span'));
    nameContainer.appendChild(document.createElement('span'));
    li.appendChild(nameContainer);
    waitingRoster.appendChild(li);
  }
  while (waitingRoster.getElementsByTagName('li').length > newRosterCount) {
    waitingRoster.removeChild(waitingRoster.getElementsByTagName('li')[0]);
  }
  const entries = waitingRoster.getElementsByTagName('li');
  let i = 0;
  for (const participantName of this.participantsInTestingRoom) {
    const spanName = entries[i].getElementsByTagName('span')[1];
  
    this.updateProperty(spanName, 'innerHTML', participantName);
    this.updateProperty(spanName, 'className', 'attendee-name');
    i++;
  }
  while (waitingRoster.getElementsByTagName('h5').length > 0) {
    waitingRoster.removeChild(waitingRoster.getElementsByTagName('h5')[0]);
  }
  if(entries.length > 0) {
    const waitingParticipantsTitle = document.createElement('h5');
    const inMeetingParticipantsTitle = document.createElement('h5');
    waitingParticipantsTitle.classList.add('meeting-room-title');
    inMeetingParticipantsTitle.classList.add('meeting-room-title');
    waitingParticipantsTitle.innerHTML = "In Device Testing Room <span style='font-size:12px'>(Joining shortly)</span>";
    inMeetingParticipantsTitle.innerText = "In Meeting Room";

    waitingRoster.insertBefore(waitingParticipantsTitle, waitingRoster.childNodes[0]);
    waitingRoster.appendChild(inMeetingParticipantsTitle);
  }
}

_addRosterActionsHandlers(){
  let muteAttendeeActionItems = document.getElementsByClassName('attendee-action-item-mute-attendee');
  Array.from(muteAttendeeActionItems).forEach(action => {
    action.removeEventListener('click', this.muteAttendeeHandler);
    action.addEventListener('click', this.muteAttendeeHandler);
  });

  let removeAttendeeActionItems = document.getElementsByClassName('attendee-action-item-remove-attendee');
  Array.from(removeAttendeeActionItems).forEach(action => {
    action.removeEventListener('click', this.removeAttendeeHandler);
    action.addEventListener('click', this.removeAttendeeHandler);
  });
}

muteAttendeeHandler = (element) => {
  const attendeeActionItemId  = (element.target as Element).id;
  let attendeeId = attendeeActionItemId.replace('mute-attendee-', "");
  let currentUserAttendeeId = this.meetingSession.configuration.credentials.attendeeId;
  let muteText = (element.target as HTMLElement).innerText == "Mute"? 'MUTE_YOU' : "UNMUTE_YOU";  
  let chatTopic = [currentUserAttendeeId, attendeeId].sort().join('&');
  let textToSend = `${muteText}${chatTopic}`;
  this.audioVideo.realtimeSendDataMessage(MeetingApp.DATA_MESSAGE_TOPIC, textToSend, MeetingApp.DATA_MESSAGE_LIFETIME_MS);
}

removeAttendeeHandler = (element) => {
  const attendeeActionItemId  = (element.target as Element).id;
  let attendeeId = attendeeActionItemId.replace('remove-attendee-', "");
  this.showConfirm('Confirm', 'The attendee will be removed from the meeting. <br><br> Do you want to continue?', 'warning',() => {
    this._removeAttendee(attendeeId);
  }, () => {
    //do nothing
  });
}

_removeAttendee(attendeeId){
  const attendeeDetails = {
    actionPerformedUserId: this.participantId,       
    externalMeetingCallId: this.allMeetingInfo.externalMeetingCallId,             
    attendeeId: attendeeId
  };
        //API call to remove the attendee
  this.meetingService.removeAttendeeFromMeeting(attendeeDetails, (data) => {
    bootbox.hideAll();
    let title = `<div class='alert alert-success mb-0'><i class='fa fa-check fa-fw fa-lg'></i><strong>Attendee Removed</strong></div>`;
    let message = `The attendee has been removed from the meeting.`;
    if(!data){
      title = `<div class='alert alert-danger mb-0'><i class='fas fa-exclamation-triangle fa-fw fa-lg'></i><strong>Attendee Not Removed</strong></div>`;
      message = `The attendee could not be removed from the meeting.`;
    }
    bootbox.alert({
      closeButton: false,
      title: title,
      message: message,
      className: "zIndex1060",
      buttons: {
          ok: {
              label: 'Ok',
              className: 'btn-info'
          }
      },
      callback: () => {
      }
    });
  }, error => {
    this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
  });
}

_setChatUsers(){
  this.chatUsers = [];
  let currentUserAttendeeId = this.meetingSession.configuration.credentials.attendeeId;
  let allAttendeeNames = this.attendees.map(attendee => attendee.name);
  this.attendees.forEach(attendee => {
    if(!attendee.name.includes('Content')){
      let chatUser = {name: attendee.name, attendeeId: attendee.id, chatTopic: ""};
      if(currentUserAttendeeId!== attendee.id){
        chatUser.chatTopic = [currentUserAttendeeId, attendee.id].sort().join('&');; 
        this.chatUsers.push(chatUser);
      }
    }
  });
  this.chatUsers.unshift({name: "All", attendeeId: "All", chatTopic: "All"});
}

_setUsersToEditFeedback(){
  let currentUserAttendeeId = this.meetingSession.configuration.credentials.attendeeId;
  let validUsersToEditFeedback = [];

  //add if attendees has 
  this.attendees.forEach(attendee => {
    if(!attendee.name.includes('Content') && !attendee.name.includes(this.scheduleInfo.candidateDetail.name)){
      let newAttendee = {name: attendee.name, displayName: attendee.name, attendeeId: attendee.id, userId: ''};
      if(currentUserAttendeeId == attendee.id){
        newAttendee.displayName = 'You';
      } 
      validUsersToEditFeedback.push(newAttendee);
    }
  });
  this.usersToEditFeedback = [...validUsersToEditFeedback];
}

updateProperty(obj: any, key: string, value: string) {
  if (value !== undefined && obj[key] !== value) {
    obj[key] = value;
  }
}

setupSubscribeToAttendeeIdPresenceHandler(): void {
  const handler = (attendeeId: string, present: boolean, externalUserId: string, dropped: boolean): void => {
    this.log(`${attendeeId} present = ${present} (${externalUserId})`);
    const isContentAttendee = new DefaultModality(attendeeId).hasModality(DefaultModality.MODALITY_CONTENT);
    const isSelfAttendee = new DefaultModality(attendeeId).base() === this.meetingSession.configuration.credentials.attendeeId;
    if (!present) {
      delete this.roster[attendeeId];
      this.updateRoster();
      this.log(`${attendeeId} dropped = ${dropped} (${externalUserId})`);
      return;
    }
    //If someone else share content, stop the current content share
    if (!this.allowMaxContentShare() && !isSelfAttendee && isContentAttendee && this.isButtonOn('button-content-share')) {
      this.contentShareStop();
    }
    if (!this.roster[attendeeId]) {
      this.roster[attendeeId] = {
        name: (externalUserId.split('#')[0]) + (isContentAttendee ? ' «Content»' : ''),
      };
    }
    this.audioVideo.realtimeSubscribeToVolumeIndicator(
      attendeeId,
      async (
        attendeeId: string,
        volume: number | null,
        muted: boolean | null,
        signalStrength: number | null
      ) => {
        if (!this.roster[attendeeId]) {
          return;
        }
        if (volume !== null) {
          this.roster[attendeeId].volume = Math.round(volume * 100);
        }
        if (muted !== null) {
          this.roster[attendeeId].muted = muted;
        }
        if (signalStrength !== null) {
          this.roster[attendeeId].signalStrength = Math.round(signalStrength * 100);
        }
        this.updateRoster();
      }
    );
  };
  this.audioVideo.realtimeSubscribeToAttendeeIdPresence(handler);
  const activeSpeakerHandler = (attendeeIds: string[]): void => {
    for (const attendeeId in this.roster) {
      this.roster[attendeeId].active = false;
    }
    for (const attendeeId of attendeeIds) {
      if (this.roster[attendeeId]) {
        this.roster[attendeeId].active = true;
        break; // only show the most active speaker
      }
    }
    this.layoutFeaturedTile();
  };
  this.audioVideo.subscribeToActiveSpeakerDetector(
    new DefaultActiveSpeakerPolicy(),
    activeSpeakerHandler,
    (scores: {[attendeeId:string]: number}) => {
      for (const attendeeId in scores) {
        if (this.roster[attendeeId]) {
          this.roster[attendeeId].score = scores[attendeeId];
        }
      }
      this.updateRoster();
    },
    this.showActiveSpeakerScores ? 100 : 0,
  );
}


async getStatsForOutbound(id: string): Promise<void> {
  const videoElement = document.getElementById(id) as HTMLVideoElement;
  const stream = videoElement.srcObject as MediaStream;
  const track = stream.getVideoTracks()[0];
  let basicReports: {[id: string]: number} =  {};

  let reports = await this.audioVideo.getRTCPeerConnectionStats(track);
  let duration: number;

  // reports.forEach(report => {
  //   if (report.type === 'outbound-rtp') {
  //     // remained to be calculated
  //     this.log(`${id} is bound to ssrc ${report.ssrc}`);
  //     basicReports['bitrate'] = report.bytesSent;
  //     basicReports['width'] = report.frameWidth;
  //     basicReports['height'] = report.frameHeight;
  //     basicReports['fps'] = report.framesEncoded;
  //     duration = report.timestamp;
  //   }
  // });

  await new TimeoutScheduler(1000).start(() => {
    this.audioVideo.getRTCPeerConnectionStats(track).then((reports) => {
      // reports.forEach(report => {
      //   if (report.type === 'outbound-rtp') {
      //     duration = report.timestamp - duration;
      //     duration = duration / 1000;
      //     // remained to be calculated
      //     basicReports['bitrate'] =  Math.trunc((report.bytesSent - basicReports['bitrate']) * 8 / duration);
      //     basicReports['width'] = report.frameWidth;
      //     basicReports['height'] = report.frameHeight;
      //     basicReports['fps'] = Math.trunc((report.framesEncoded - basicReports['fps']) / duration);
      //     this.log(JSON.stringify(basicReports));
      //   }
      // });
    });
  });
}

dataMessageHandler(dataMessage: DataMessage): void {
  let currentUserAttendeeId = this.meetingSession.configuration.credentials.attendeeId;
  if (!dataMessage.throttled) {
    const isSelf = dataMessage.senderAttendeeId === currentUserAttendeeId;
    if (dataMessage.timestampMs <= this.lastReceivedMessageTimestamp) {
      return;
    }
    let text = dataMessage.text();

    if((text === "UNMUTE_ALL" || (text.includes("UNMUTE_YOU") && text.includes(currentUserAttendeeId))) && this.roster[currentUserAttendeeId] !== undefined && this.roster[currentUserAttendeeId].muted){
      // let message = `${dataMessage.senderExternalUserId.split("#")[0]} has unmuted you.`;
      let message = `${dataMessage.senderExternalUserId.split("#")[0]} has asked you to unmute.`;
      bootbox.hideAll();
      bootbox.confirm({
        closeButton: false,
        title: "<div class='alert alert-warning mb-0'><i class='fas fa-exclamation-triangle fa-fw fa-lg'></i><strong>Request to unmute</strong></div>",
        message: message,
        className: "zIndex1060",
        backdrop: true,
        onEscape: false,
        buttons: {
            confirm: {
                label: 'Unmute',
                className: 'btn-info'
            },
            cancel: {
                label: "Don't unmute",
                className: 'btn-info'
            }
        },
        callback: (result) => {
            if(result) {
              this.toggleButton('button-microphone', 'on');
              this.audioVideo.realtimeUnmuteLocalAudio();
            } else {
                // this.modalService.addModalOpenClassToBodyIfAnyModalIsOpen();
            }
        }
      });

      if(text === "UNMUTE_ALL"){
        this.$timeout(() => {
          // this.isMuteAll = false;
        }, 200);
      }
      // this.audioVideo.realtimeUnmuteLocalAudio();
    } else if((text === "MUTE_ALL" || (text.includes("MUTE_YOU") && text.includes(currentUserAttendeeId))) && this.roster[currentUserAttendeeId] !== undefined && !this.roster[currentUserAttendeeId].muted){
      this.toggleButton('button-microphone', 'off');
      this.audioVideo.realtimeMuteLocalAudio();
      let message = `You have been muted by ${dataMessage.senderExternalUserId.split('#')[0]}.`;
      bootbox.hideAll();
      bootbox.alert({
        closeButton: false,
        title: "<div class='alert alert-warning mb-0'><i class='fas fa-exclamation-triangle fa-fw fa-lg'></i><strong>You are muted</strong></div>",
        message: message,
        className: "zIndex1060",
        buttons: {
            ok: {
                label: 'Ok',
                className: 'btn-info'
            }
        },
        callback: () => {
        }
      });

      if(text == "MUTE_ALL"){
        this.$timeout(() => {
          // this.isMuteAll = false;
        }, 200);
      }
    } else if(!text.includes("MUTE_YOU") && !text.includes("MUTE_ALL")) {
      let activeChatTopic = "";
      this.lastReceivedMessageTimestamp = dataMessage.timestampMs;
      let chatUsers = this.chatUsers.filter(chatUser => chatUser.name !== 'All');
      let messageDiv = document.getElementById("All") as HTMLDivElement;

      if(!this.isSingleTabChatMode){
        // this._setChatTabs(chatUsers, messageDiv, text);
        for(let i = 0; i< chatUsers.length; i++){
          if(text.includes(chatUsers[i].chatTopic)){
            text = text.replace(chatUsers[i].chatTopic, "");
            activeChatTopic = chatUsers[i].chatTopic;
            messageDiv = document.getElementById(chatUsers[i].chatTopic) as HTMLDivElement;
            if(!messageDiv){
              let messageContainer = document.getElementById('chat-container') as HTMLDivElement;
              messageDiv = document.createElement('div') as HTMLDivElement;
              messageDiv.setAttribute("id", chatUsers[i].chatTopic);
              messageDiv.classList.add('list-group');
              messageDiv.classList.add('receive-message');
              messageContainer.insertBefore(messageDiv, messageContainer.firstElementChild.nextSibling);
              // this.showChatContainer(chatUsers[i].chatTopic);
            }
          }
        }

        //start
        const messageNameSpan = document.createElement('div') as HTMLDivElement;
        messageNameSpan.classList.add('message-bubble-sender');
        messageNameSpan.innerHTML = isSelf? 'You' : (dataMessage.senderExternalUserId.split('#')[0]);
        const messageTextSpan = document.createElement('div') as HTMLDivElement;
        messageTextSpan.classList.add(isSelf ? 'message-bubble-self' : 'message-bubble-other');
        // messageTextSpan.innerHTML = this.markdown.render(dataMessage.text()).replace(/[<]a /g, '<a target="_blank" ');
        messageTextSpan.innerHTML = text;
        const appendClass = (element: HTMLElement, className: string) => {
          for (let i = 0; i < element.children.length; i++) {
            const child = element.children[i] as HTMLElement;
            child.classList.add(className);
            appendClass(child, className);
          }
        }
        appendClass(messageTextSpan, 'markdown');
        if (this.lastMessageSender !== dataMessage.senderAttendeeId || this.lastChatTopic !== activeChatTopic) {
          messageDiv.appendChild(messageNameSpan);
        }
        this.lastMessageSender = dataMessage.senderAttendeeId;
        this.lastChatTopic = activeChatTopic;
        messageDiv.appendChild(messageTextSpan);
        messageDiv.scrollTop = messageDiv.scrollHeight;
        //end
      } else { //Single tab
        let senderText = " to ";
        let isTopicAll = false;
        let chatUser = chatUsers.find(chatUser => text.includes(chatUser.chatTopic));
        let isMessageHasAttendeeIdOfAnyChatUser = chatUsers.some(chatUser => text.includes(chatUser.attendeeId));
        let isAllIndicatorExists = text.includes('#ALL_EVERYONE');
        text = isAllIndicatorExists ? text.replace('#ALL_EVERYONE',""): text;
        isTopicAll = isAllIndicatorExists || (chatUser == undefined && !isMessageHasAttendeeIdOfAnyChatUser && this.chatUsers.length > 0);
        if(isTopicAll){
          activeChatTopic = "All";
          senderText = senderText + "Everyone";
          if(this.chatUsers.length == 0){
            senderText = "";
          }
           //start
           const messageNameSpan = document.createElement('div') as HTMLDivElement;
           messageNameSpan.classList.add('message-bubble-sender');
           messageNameSpan.innerHTML = isSelf? 'Me' + senderText : (dataMessage.senderExternalUserId.split('#')[0]) + senderText;
           const messageTextSpan = document.createElement('div') as HTMLDivElement;
           messageTextSpan.classList.add(isSelf ? 'message-bubble-self' : 'message-bubble-other');
           // messageTextSpan.innerHTML = this.markdown.render(dataMessage.text()).replace(/[<]a /g, '<a target="_blank" ');
           messageTextSpan.innerHTML = text;
           const appendClass = (element: HTMLElement, className: string) => {
             for (let i = 0; i < element.children.length; i++) {
               const child = element.children[i] as HTMLElement;
               child.classList.add(className);
               appendClass(child, className);
             }
           }
           appendClass(messageTextSpan, 'markdown');
           if (this.lastMessageSender !== dataMessage.senderAttendeeId || this.lastChatTopic !== activeChatTopic) {
             messageDiv.appendChild(messageNameSpan);
           }
           this.lastMessageSender = dataMessage.senderAttendeeId;
           this.lastChatTopic = activeChatTopic;
           messageDiv.appendChild(messageTextSpan);
           messageDiv.scrollTop = messageDiv.scrollHeight;
           //end
        } else{ // private message
          for(let i = 0; i< chatUsers.length; i++){
            if(text.includes(chatUsers[i].chatTopic)){
              text = text.replace(chatUsers[i].chatTopic, "");
              activeChatTopic = chatUsers[i].chatTopic;
              let chatUserName = chatUsers[i].name;
             
                if(isSelf){
                  senderText = senderText + chatUserName + " <span class='text-danger'>(Privately)</span>";
                } else {
                  senderText = senderText + "You <span class='text-danger'>(Privately)</span>";
                }
              
              //start
              const messageNameSpan = document.createElement('div') as HTMLDivElement;
              messageNameSpan.classList.add('message-bubble-sender');
              messageNameSpan.innerHTML = isSelf? 'You' + senderText : (dataMessage.senderExternalUserId.split('#')[0]) + senderText;
              const messageTextSpan = document.createElement('div') as HTMLDivElement;
              messageTextSpan.classList.add(isSelf ? 'message-bubble-self' : 'message-bubble-other');
              // messageTextSpan.innerHTML = this.markdown.render(dataMessage.text()).replace(/[<]a /g, '<a target="_blank" ');
              messageTextSpan.innerHTML = text;
              const appendClass = (element: HTMLElement, className: string) => {
                for (let i = 0; i < element.children.length; i++) {
                  const child = element.children[i] as HTMLElement;
                  child.classList.add(className);
                  appendClass(child, className);
                }
              }
              appendClass(messageTextSpan, 'markdown');
              if (this.lastMessageSender !== dataMessage.senderAttendeeId || this.lastChatTopic !== activeChatTopic) {
                messageDiv.appendChild(messageNameSpan);
              }
              this.lastMessageSender = dataMessage.senderAttendeeId;
              this.lastChatTopic = activeChatTopic;
              messageDiv.appendChild(messageTextSpan);
              messageDiv.scrollTop = messageDiv.scrollHeight;
              //end
            }
          }
        }
      }
    }
  } else {
    this.log('Message is throttled. Please resend');
  }
}

_setChatTabs(chatUsers, messageDiv, text){
  for(let i = 0; i< chatUsers.length; i++){
    if(text.includes(chatUsers[i].chatTopic)){
      text = text.replace(chatUsers[i].chatTopic, "");
      messageDiv = document.getElementById(chatUsers[i].chatTopic) as HTMLDivElement;
      if(!messageDiv){
        let messageContainer = document.getElementById('chat-container') as HTMLDivElement;
        messageDiv = document.createElement('div') as HTMLDivElement;
        messageDiv.setAttribute("id", chatUsers[i].chatTopic);
        messageDiv.classList.add('list-group');
        messageDiv.classList.add('receive-message');
        // messageContainer.prependChild(messageDiv);
        messageContainer.insertBefore(messageDiv, messageContainer.firstElementChild.nextSibling);
        // this.showChatContainer(chatUsers[i].chatTopic);
      }
    }
  }
}

showChatContainer(chatTopic){
  if(!this.isSingleTabChatMode){
    this.chatUsers.forEach(chatUser => {
      let chatContainer = document.getElementById(chatUser.chatTopic) as HTMLDivElement;
        if(chatContainer){
          if(chatUser.chatTopic == chatTopic){
            chatContainer.classList.add('d-block');
            chatContainer.classList.remove('d-none');
          } else {
            chatContainer.classList.remove('d-block');
            chatContainer.classList.add('d-none');
          }
          
        }
      });
  }
}

setupDataMessage(): void {
  this.audioVideo.realtimeSubscribeToReceiveDataMessage(MeetingApp.DATA_MESSAGE_TOPIC, (dataMessage: DataMessage) => {
    this.dataMessageHandler(dataMessage);
  });
}

// eslint-disable-next-line
async joinMeeting(): Promise<any> {
  const response = await fetch(
    // `${MeetingApp.BASE_URL}join?title=${encodeURIComponent(this.meeting)}&name=${encodeURIComponent(this.name)}&region=${encodeURIComponent(this.region)}`,
    this.StorageService.get('baseurl') + 'chime/meeting/joinmeeting/' + this.participantType + '/' + this.scheduleId + '/' + this.participantId,
    {
      method: 'POST',
    }
  );
  const json = await response.json();
  if (json.error) {
    throw new Error(`Server error: ${json.error}`);
  }
  return json;
}

//create attendee
async createAttendee(meetingInfo): Promise<any> {

  let requestPayload = {
    meetingId: meetingInfo.meeting.meetingId,
    externalUserId: meetingInfo.participantId,
    externalMeetingCallId: meetingInfo.externalMeetingCallId,
    meetingScheduleId: meetingInfo.meetingScheduleId,
    type: meetingInfo.type
  }

  const response = await fetch(
    this.StorageService.get('baseurl') + 'chime/meeting/createattendee',
    {
      headers: {'Content-Type': 'application/json'},
      method: 'POST',
      body: JSON.stringify(requestPayload)
    }
  );
  const json = await response.json();
  if (json.error) {
    throw new Error(`Server error: ${json.error}`);
  }
  return json;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async endMeeting(): Promise<any> {
  // await fetch(`${MeetingApp.BASE_URL}end?title=${encodeURIComponent(this.meeting)}`, {
  //   method: 'POST',
  // });

    let requestPayload = {
      meetingId: this.allMeetingInfo.meeting.meetingId,
      externalMeetingCallId: this.allMeetingInfo.externalMeetingCallId,
      meetingScheduleId: this.allMeetingInfo.meetingScheduleId,
      loggedInUserId: this.participantId,
      jobMatchId: this.scheduleInfo.jobMatchId,
      companyId: this.scheduleInfo.company
    };

    await fetch(
      this.StorageService.get('baseurl') + 'chime/meeting/deletemeeting',
      {
        headers: {'Content-Type': 'application/json'},
        method: 'POST',
        body: JSON.stringify(requestPayload)
      }
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async getAttendee(attendeeId: string): Promise<any> {
  const response = await fetch(`${MeetingApp.BASE_URL}attendee?title=${encodeURIComponent(this.meeting)}&attendee=${encodeURIComponent(attendeeId)}`);
  const json = await response.json();
  if (json.error) {
    throw new Error(`Server error: ${json.error}`);
  }
  return json;
}

setupDeviceLabelTrigger(): void {
  // Note that device labels are privileged since they add to the
  // fingerprinting surface area of the browser session. In Chrome private
  // tabs and in all Firefox tabs, the labels can only be read once a
  // MediaStream is active. How to deal with this restriction depends on the
  // desired UX. The device controller includes an injectable device label
  // trigger which allows you to perform custom behavior in case there are no
  // labels, such as creating a temporary audio/video stream to unlock the
  // device names, which is the default behavior. Here we override the
  // trigger to also show an alert to let the user know that we are asking for
  // mic/camera permission.
  //
  // Also note that Firefox has its own device picker, which may be useful
  // for the first device selection. Subsequent device selections could use
  // a custom UX with a specific device id.
  this.audioVideo.setDeviceLabelTrigger(
    async (): Promise<MediaStream> => {
      if (this.isRecorder() || this.isBroadcaster()) {
        throw new Error('Recorder or Broadcaster does not need device labels');
      }
      this.switchToFlow('flow-need-permission');
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      this.switchToFlow('flow-devices');
      return stream;
    }
  );
}

populateDeviceList(
  elementId: string,
  genericName: string,
  devices: MediaDeviceInfo[],
  additionalOptions: string[]
): void {
  const list = document.getElementById(elementId) as HTMLSelectElement;
  while (list.firstElementChild) {
    list.removeChild(list.firstElementChild);
  }
  for (let i = 0; i < devices.length; i++) {
    const option = document.createElement('option');
    list.appendChild(option);
    option.text = devices[i].label || `${genericName} ${i + 1}`;
    option.value = devices[i].deviceId;
  }
  if (additionalOptions.length > 0) {
    const separator = document.createElement('option');
    separator.disabled = true;
    separator.text = '──────────';
    list.appendChild(separator);
    for (const additionalOption of additionalOptions) {
      const option = document.createElement('option');
      list.appendChild(option);
      option.text = additionalOption;
      option.value = additionalOption;
    }
  }
  if (!list.firstElementChild) {
    const option = document.createElement('option');
    option.text = 'Device selection unavailable';
    list.appendChild(option);
  }
}

populateInMeetingDeviceList(
  elementId: string,
  deviceType: string,
  genericName: string,
  heading: string,
  devices: MediaDeviceInfo[],
  additionalOptions: string[],
  callback: (name: string) => void
): void {
  const menu = document.getElementById(elementId) as HTMLDivElement;
  while (menu.firstElementChild) {
    menu.removeChild(menu.firstElementChild);
  }
  this.createDropdownMenuHeadingItem(menu, heading);

  for (let i = 0; i < devices.length; i++) {
    this.createDropdownMenuItem(menu, devices[i].label || `${genericName} ${i + 1}`, () => {
      callback(devices[i].deviceId);
    }, `${devices[i].deviceId}-${deviceType}`);
  }
  if (additionalOptions.length > 0) {
    this.createDropdownMenuItem(menu, '──────────', () => {}).classList.add('text-center');
    for (const additionalOption of additionalOptions) {
      this.createDropdownMenuItem(
        menu,
        additionalOption,
        () => {
          callback(additionalOption);
        },
        `${elementId}-${additionalOption.replace(/\s/g, '-')}`
      );
    }
  }
  if (!menu.firstElementChild) {
    this.createDropdownMenuItem(menu, 'Device selection unavailable', () => {});
  }
}

createDropdownMenuHeadingItem(
  menu: HTMLDivElement,
  title: string,
  id?: string
): HTMLButtonElement {
  const button = document.createElement('span') as HTMLButtonElement;
  menu.appendChild(button);
  button.innerText = title;
  button.classList.add('dropdown-item', 'drop-down-item-heading');
  this.updateProperty(button, 'id', id);
  return button;
}

createDropdownMenuItem(
  menu: HTMLDivElement,
  title: string,
  clickHandler: () => void,
  id?: string
): HTMLButtonElement {
  const button = document.createElement('span') as HTMLButtonElement;
  menu.appendChild(button);
  button.innerText = title;
  button.classList.add('dropdown-item');
  this.updateProperty(button, 'id', id);
  button.addEventListener('click', () => {
    clickHandler();
  });
  return button;
}

async populateAllDeviceLists(): Promise<void> {
  await this.populateAudioInputList();
  await this.populateVideoInputList();
  await this.populateAudioOutputList();
}

async populateAudioInputList(): Promise<void> {
  const genericName = 'Microphone';
  const heading = 'Select audio input device';
  const additionalDevices = ['None', '440 Hz'];
  this.populateDeviceList(
    'audio-input',
    genericName,
    await this.audioVideo.listAudioInputDevices(),
    []
  );
  this.populateInMeetingDeviceList(
    'dropdown-menu-microphone',
    'audioInput',
    genericName,
    heading,
    await this.audioVideo.listAudioInputDevices(),
    [],
    async (name: string) => {
      this.selectedMicroPhoneName = name;
      await this._setDeviceSelection("audioInput", name);
      await this.audioVideo.chooseAudioInputDevice(this.audioInputSelectionToDevice(name));
    }
  );
}

async _setDeviceSelection(deviceType, name){
  let devices = [];
  if(deviceType == "audioInput"){
    devices = await this.audioVideo.listAudioInputDevices();
    this.selectedMicroPhoneName = name;
  } else if(deviceType == "audioOutput"){
    devices = await this.audioVideo.listAudioOutputDevices();
    this.selectedSpeakerName = name;
  } else if(deviceType == "videoInput"){
    devices = await this.audioVideo.listVideoInputDevices();
    this.selectedCameraName = name;
  }
  for (let i = 0; i < devices.length; i++) {
    let dropdownItemElement = document.getElementById(`${devices[i].deviceId}-${deviceType}`);
    if(dropdownItemElement.childNodes[1]){
      dropdownItemElement.removeChild(dropdownItemElement.childNodes[1]);
    }
    if(devices[i].deviceId === name){
      let iconElement = document.createElement('i');
      iconElement.classList.add('fas', 'fa-check', 'green', 'pull-right');
      dropdownItemElement.appendChild(iconElement);
    }
  }
}

async populateVideoInputList(): Promise<void> {
  const genericName = 'Camera';
  const heading = 'Select video input device';
  const additionalDevices = ['None', 'Blue', 'SMPTE Color Bars'];
  this.populateDeviceList(
    'video-input',
    genericName,
    await this.audioVideo.listVideoInputDevices(),
    []
  );
  this.populateInMeetingDeviceList(
    'dropdown-menu-camera',
    'videoInput',
    genericName,
    heading,
    await this.audioVideo.listVideoInputDevices(),
    [],
    async (name: string) => {
      try {
        this.selectedCameraName = name;
        await this.openVideoInputFromSelection(name, false);
      } catch (err) {
        this.log('no video input device selected');
      }
    }
  );
  const cameras = await this.audioVideo.listVideoInputDevices();
  this.cameraDeviceIds = cameras.map((deviceInfo) => {
    return deviceInfo.deviceId;
  });
}

async populateAudioOutputList(): Promise<void> {
  const genericName = 'Speaker';
  const heading = 'Select speaker';
  const additionalDevices: string[] = [];
  this.populateDeviceList(
    'audio-output',
    genericName,
    await this.audioVideo.listAudioOutputDevices(),
    additionalDevices
  );
  this.populateInMeetingDeviceList(
    'dropdown-menu-speaker',
    'audioOutput',
    genericName,
    heading,
    await this.audioVideo.listAudioOutputDevices(),
    additionalDevices,
    async (name: string) => {
      this.selectedSpeakerName = name;
      await this._setDeviceSelection("audioOutput", name);
      await this.audioVideo.chooseAudioOutputDevice(name);
    }
  );
}

private analyserNodeCallback = () => {};

async openAudioInputFromSelection(): Promise<void> {
  const audioInput = document.getElementById('audio-input') as HTMLSelectElement;
  await this.audioVideo.chooseAudioInputDevice(
    this.audioInputSelectionToDevice(audioInput.value)
  );
  await this._setDeviceSelection("audioInput", audioInput.value);
  this.startAudioPreview();
}

setAudioPreviewPercent(percent: number): void {
  const audioPreview = document.getElementById('audio-preview');
  this.updateProperty(audioPreview.style, 'transitionDuration', '33ms');
  this.updateProperty(audioPreview.style, 'width', `${percent}%`);
  if (audioPreview.getAttribute('aria-valuenow') !== `${percent}`) {
    audioPreview.setAttribute('aria-valuenow', `${percent}`);
  }
}

startAudioPreview(): void {
  this.setAudioPreviewPercent(0);
  const analyserNode = this.audioVideo.createAnalyserNodeForAudioInput();
  if (!analyserNode) {
    return;
  }
  if (!analyserNode.getByteTimeDomainData) {
    document.getElementById('audio-preview').parentElement.style.visibility = 'hidden';
    return;
  }
  const data = new Uint8Array(analyserNode.fftSize);
  let frameIndex = 0;
  this.analyserNodeCallback = () => {
    if (frameIndex === 0) {
      analyserNode.getByteTimeDomainData(data);
      const lowest = 0.01;
      let max = lowest;
      for (const f of data) {
        max = Math.max(max, (f - 128) / 128);
      }
      let normalized = (Math.log(lowest) - Math.log(max)) / Math.log(lowest);
      let percent = Math.min(Math.max(normalized * 100, 0), 100);
      this.setAudioPreviewPercent(percent);
    }
    frameIndex = (frameIndex + 1) % 2;
    requestAnimationFrame(this.analyserNodeCallback);
  };
  requestAnimationFrame(this.analyserNodeCallback);
}

async openAudioOutputFromSelection(): Promise<void> {
  const audioOutput = document.getElementById('audio-output') as HTMLSelectElement;
  await this._setDeviceSelection("audioOutput", audioOutput.value);
  await this.audioVideo.chooseAudioOutputDevice(audioOutput.value);
  const audioMix = document.getElementById('meeting-audio') as HTMLAudioElement;
  await this.audioVideo.bindAudioElement(audioMix);
}

private selectedVideoInput: string | null = null;

async openVideoInputFromSelection(selection: string | null, showPreview: boolean): Promise<void> {
  if (selection) {
    this.selectedVideoInput = selection;
  }
  this.log(`Switching to: ${this.selectedVideoInput}`);
  const device = this.videoInputSelectionToDevice(this.selectedVideoInput);
  if (device === null) {
    if (showPreview) {
      this.audioVideo.stopVideoPreviewForVideoInput(document.getElementById(
        'video-preview'
      ) as HTMLVideoElement);
    }
    this.audioVideo.stopLocalVideoTile();
    this.hideTile(DemoTileOrganizer.LOCAL_VIDEO_INDEX);
    this.toggleButton('button-camera', 'off');
    // choose video input null is redundant since we expect stopLocalVideoTile to clean up
    await this._setDeviceSelection("videoInput", device);
    await this.audioVideo.chooseVideoInputDevice(device);
    throw new Error('no video device selected');
  }
  await this._setDeviceSelection("videoInput", device);
  await this.audioVideo.chooseVideoInputDevice(device);
  if (showPreview) {
    this.audioVideo.startVideoPreviewForVideoInput(document.getElementById(
      'video-preview'
    ) as HTMLVideoElement);
  }
}

private audioInputSelectionToDevice(value: string): Device {
  if (this.isRecorder() || this.isBroadcaster()) {
    return null;
  }
  if (value === '440 Hz') {
    return DefaultDeviceController.synthesizeAudioDevice(440);
  } else if (value === 'None') {
    return null;
  }
  return value;
}

private videoInputSelectionToDevice(value: string): Device {
  if (this.isRecorder() || this.isBroadcaster()) {
    return null;
  }
  if (value === 'Blue') {
    return DefaultDeviceController.synthesizeVideoDevice('blue');
  } else if (value === 'SMPTE Color Bars') {
    return DefaultDeviceController.synthesizeVideoDevice('smpte');
  } else if (value === 'None') {
    return null;
  }
  return value;
}

private initContentShareDropDownItems(): void {
  let item = document.getElementById('dropdown-item-content-share-screen-capture');
  item.addEventListener('click', () => {
    this.contentShareTypeChanged(ContentShareType.ScreenCapture);
  });

  item = document.getElementById('dropdown-item-content-share-screen-test-video');
  item.addEventListener('click', () => {
    this.contentShareTypeChanged(ContentShareType.VideoFile, MeetingApp.testVideo);
  });

  document.getElementById('content-share-item').addEventListener('change', () => {
    const fileList = document.getElementById('content-share-item') as HTMLInputElement;
    const file = fileList.files[0];
    if (!file) {
      this.log('no content share selected');
      return;
    }
    const url = URL.createObjectURL(file);
    this.log(`content share selected: ${url}`);
    this.contentShareTypeChanged(ContentShareType.VideoFile, url);
    fileList.value = '';
  });
}

private async contentShareTypeChanged(contentShareType: ContentShareType, videoUrl?: string): Promise<void> {
  if (this.isButtonOn('button-content-share')) {
    await this.contentShareStop();
  }
  this.contentShareType = contentShareType;
  await this.contentShareStart(videoUrl);
}

private async contentShareStart(videoUrl?: string): Promise<void> {
  switch (this.contentShareType) {
    case ContentShareType.ScreenCapture:
      this.audioVideo.startContentShareFromScreenCapture();
      break;
    case ContentShareType.VideoFile:
      const videoFile = document.getElementById('content-share-video') as HTMLVideoElement;
      if (videoUrl) {
        videoFile.src = videoUrl;
      }
      await videoFile.play();
      let mediaStream: MediaStream;
      if(this.defaultBrowserBehaviour.hasFirefoxWebRTC()) {
        // @ts-ignore
        mediaStream = videoFile.mozCaptureStream();
      } else {
        // @ts-ignore
        mediaStream = videoFile.captureStream();
      }
      this.audioVideo.startContentShare(mediaStream);
      break;
  }
}

private async contentShareStop(): Promise<void> {
  if (this.isButtonOn('button-pause-content-share')) {
    this.toggleButton('button-pause-content-share');
  }
  this.audioVideo.stopContentShare();
  if (this.contentShareType === ContentShareType.VideoFile) {
    const videoFile = document.getElementById('content-share-video') as HTMLVideoElement;
    videoFile.pause();
    videoFile.style.display = 'none';
  }
}

isRecorder(): boolean {
  return (new URL(window.location.href).searchParams.get('record')) === 'true';
}

isBroadcaster(): boolean {
  return (new URL(window.location.href).searchParams.get('broadcast')) === 'true';
}

async authenticate(): Promise<string> {
  this.webSocketsService.disconnectForChime();
    this.webSocketsService.connectForChime(this.participantId);
    if(!this.webSocketsService.isChimeListenerListening){
      this._listenToChimeNotification();
    }
  this.allMeetingInfo = (await this.joinMeeting());
  if(this.allMeetingInfo.type == 'INVITED_USER'){
    this.participantType = 'p';
  }
  this.allowEditFeedback = this.allMeetingInfo.allowedToEditFeedback;
  this.isMeetingInProgress = true;
  let meetingInfo = this._massageMeetingInfo(this.allMeetingInfo);
  let attendeeInfo;
  this.meeting = this.allMeetingInfo.meeting.meetingId;
  // window.location.href = window.location.href +"&m=" + this.meeting;
  if(this.allMeetingInfo.waitForHostToJoin){
    this.switchToFlow('flow-wait-for-host');
  } else if(this.allMeetingInfo.waitForHostToApprove){
    this.switchToFlow('flow-wait-for-host-to-approve');
  } else if(this.allMeetingInfo.alreadyJoined){
    let allAttendeeInfo = angular.copy(this.allMeetingInfo.attendeeDto[0]);
    attendeeInfo = this._massageAttendeeInfo(allAttendeeInfo);
    this.allAttendeeInfo = allAttendeeInfo;
  } else {
    let allAttendeeInfo = (await this.createAttendee(this.allMeetingInfo))
    attendeeInfo = this._massageAttendeeInfo(allAttendeeInfo.attendee);
    this.allAttendeeInfo = allAttendeeInfo.attendee;
  }
    
  const configuration = new MeetingSessionConfiguration(meetingInfo, attendeeInfo);
  await this.initializeMeetingSession(configuration);
  const url = new URL(window.location.href);
  // url.searchParams.append('m' , this.meeting);
  // window.location.href = window.location.href +"&m=" + this.meeting;
  // history.replaceState({}, `${this.meeting}`, url.toString());
  return configuration.meetingId;
}

getPrimaryHostAttendeeId(successCallback?: any){
  this.meetingService.getPrimaryHostAttendeeId(this.allMeetingInfo.externalMeetingCallId, (data) => {
    this.allMeetingInfo.primaryHostAttendeeId = data.attendeeId;
    if(successCallback) {
      successCallback();
    }
  }, error => {
    this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
  });
}

setFeedbackEnabledAttendeeId(successCallback?: any){
  this.meetingService.getFeedbackEditEnabledAttendeeId(this.allMeetingInfo.externalMeetingCallId, data => {
      this.enableEditForAttendeeId = data.attendeeId;
      if(successCallback){
        successCallback();
      }
  }, error => {
      //do nothing
  });
}

_massageMeetingInfo(meetingInfo){
  return {
    Meeting: {
      MeetingId: meetingInfo.meeting.meetingId,
      ExternalMeetingId: meetingInfo.meeting.externalMeetingId,
      MediaPlacement: {
        AudioHostUrl: meetingInfo.meeting.mediaPlacement.audioHostUrl,
        AudioFallbackUrl: meetingInfo.meeting.mediaPlacement.audioFallbackUrl,
        ScreenDataUrl: meetingInfo.meeting.mediaPlacement.screenDataUrl,
        ScreenSharingUrl: meetingInfo.meeting.mediaPlacement.screenSharingUrl,
        ScreenViewingUrl: meetingInfo.meeting.mediaPlacement.screenViewingUrl,
        SignalingUrl: meetingInfo.meeting.mediaPlacement.signalingUrl,
        TurnControlUrl: meetingInfo.meeting.mediaPlacement.turnControlUrl
      },
      MediaRegion: meetingInfo.meeting.mediaRegion
    }
  };
}

_massageAttendeeInfo(attendeeInfo){
  return {
      Attendee: {
        ExternalUserId: attendeeInfo.externalUserId,
        AttendeeId: attendeeInfo.attendeeId,
        JoinToken: attendeeInfo.joinToken
      }
  };
}

log(str: string): void {
  console.log(`[DEMO] ${str}`);
}

audioVideoDidStartConnecting(reconnecting: boolean): void {
  this.log(`session connecting. reconnecting: ${reconnecting}`);
}

audioVideoDidStart(): void {
  this.log('session started');
}

audioVideoDidStop(sessionStatus: MeetingSessionStatus): void {
  this.log(`session stopped from ${JSON.stringify(sessionStatus)}`);
  if (sessionStatus.statusCode() === MeetingSessionStatusCode.AudioCallEnded
  || sessionStatus.statusCode() === MeetingSessionStatusCode.AudioJoinedFromAnotherDevice
  || sessionStatus.statusCode() === MeetingSessionStatusCode.RealtimeApiFailed) {
    this.log(`meeting ended`);
    if(this.participantType == 'c' || this.userRoleService.isLoggedInUserEmployee()){
      this._exitScreen(null);
    } else {
      let meetingEndMessage = sessionStatus.statusCode() === MeetingSessionStatusCode.AudioJoinedFromAnotherDevice ? 'You have connected from a different device' : 'The meeting has ended.';
      bootbox.hideAll();
      bootbox.alert({
        closeButton: false,
        title: "<div class='alert alert-warning' style='margin-bottom: 0px;'><i class='fas fa-exclamation-triangle fa-fw fa-lg'></i><strong>Meeting End</strong></div>",
        message: meetingEndMessage,
        className: "zIndex1060",
        buttons: {
            ok: {
                label: 'Ok',
                className: 'btn-info'
            }
        },
        callback: () => {
          this._checkAndSaveFeedbackIfMeetingEnded();
          this._exitScreen('meetingEnded');
        }
      });
      this.$timeout(() => {
        this._checkAndSaveFeedbackIfMeetingEnded();
        this._exitScreen('meetingEnded');
      },3000);
    }
      // @ts-ignore
      // window.location = window.location.pathname;
     
  }
}

_exitScreen(type){
  bootbox.hideAll();
  if(this.participantType == 'c'){
    this.$state.go('exitMeeting', null, { reload: true });
    this.$timeout(() => {
      window.location.reload();
    }, 1000);
  } else if(this.userRoleService.isLoggedInUserEmployee()){
    this.$rootScope.$emit('logoutFromMeeting');
  } else {
    if(type && type == 'meetingEnded') {
      this.switchToFlow('flow-meeting-ended');
      this.usersToEditFeedback = [];
      this.$rootScope.$emit('REFRESH_FEEDBACK_TAB');
    } else {
      window.location.reload();
    }
  } 
}

videoTileDidUpdate(tileState: VideoTileState): void {
  this.log(`video tile updated: ${JSON.stringify(tileState, null, '  ')}`);
  if (!tileState.boundAttendeeId) {
    return;
  }
  const tileIndex = this.tileOrganizer.acquireTileIndex(tileState.tileId, tileState.localTile);
  const tileElement = document.getElementById(`tile-${tileIndex}`) as HTMLDivElement;
  const videoElement = document.getElementById(`video-${tileIndex}`) as HTMLVideoElement;
  const fullScreenElement = document.getElementById(`fullscreen-${tileIndex}`) as HTMLDivElement;
  const nameplateElement = document.getElementById(`nameplate-${tileIndex}`) as HTMLDivElement;
  // const pauseButtonElement = document.getElementById(`video-pause-${tileIndex}`) as HTMLButtonElement;

  // pauseButtonElement.addEventListener('click', () => {
  //   if (!tileState.paused) {
  //     this.audioVideo.pauseVideoTile(tileState.tileId);
  //     pauseButtonElement.innerText = 'Resume';
  //   } else {
  //     this.audioVideo.unpauseVideoTile(tileState.tileId);
  //     pauseButtonElement.innerText = 'Pause';
  //   }
  // });
  let nameOnTile = this._isCurrentUserTile(tileState)? "Me": tileState.boundExternalUserId.split('#')[0];
  this.log(`binding video tile ${tileState.tileId} to ${videoElement.id}`);
  this.audioVideo.bindVideoElement(tileState.tileId, videoElement);
  this.tileIndexToTileId[tileIndex] = tileState.tileId;
  this.tileIdToTileIndex[tileState.tileId] = tileIndex;
  this.updateProperty(nameplateElement, 'innerText', nameOnTile);
  if(tileState.active){
    this.showTile(tileElement, tileState);
  }
  this.updateGridClasses();
  this.layoutFeaturedTile();

  // if(tileState.isContent){
  //   let sharingAttendeeName = this.roster[tileState.boundAttendeeId].name;
    //  this.ngToast.success({
    //     content: "<span class=''>" + sharingAttendeeName + " is sharing content</span>",
    //     verticalPosition: 'bottom',
    //     horizontalPosition: 'right'
    //   });
    // fullScreenElement.classList.add('d-block');
    // if(tileState.boundAttendeeId !== this.meetingSession.configuration.credentials.attendeeId){
    //   const div = document.getElementById(`video-${tileIndex}`) as any;
    //   if (div.requestFullscreen) 
    //       div.requestFullscreen();
    //   else if (div.webkitRequestFullscreen) 
    //       div.webkitRequestFullscreen();
    //   else if (div.msRequestFullScreen) 
    //     div.msRequestFullScreen();
    //   }
  // } else {
    // fullScreenElement.classList.add('d-none');
  // }
  if(this._candidateActiveInMeeting()){
    this._setFixedVideoTileOnScroll();
  }
}

_isCurrentUserTile(tileState){
  return this.meetingSession.configuration.credentials.externalUserId == tileState.boundExternalUserId;
}

_isCurrentUserSharingContent(tileState){
  return tileState.isContent && this._isCurrentUserTile(tileState);
}

_candidateActiveInMeeting(){
  for (const tile of this.audioVideo.getAllVideoTiles()) {
    const state = tile.state();
    if (state.boundExternalUserId.includes('#candidate')) {
      return true;
    }
  }
  return false;
}

_setFixedVideoTileOnScroll(){
  let $window = $(window);
  
  $window.on('scroll', () => {
    const candidateTileIndex = this.candidateTileIndex();
    if(candidateTileIndex !== null){
      let $videoWrap = $('#tile-' + candidateTileIndex);
      let $video = $('#video-' + candidateTileIndex);
      let videoHeight = $video.outerHeight();

      let windowScrollTop = $window.scrollTop();
      let videoBottom = videoHeight + $videoWrap.offset().top;
      
      if (windowScrollTop > videoBottom) {
        $video.addClass('video-fixed-on-scroll');
      } else {
        $video.removeClass('video-fixed-on-scroll');
      }
    }
  });
}

videoTileWasRemoved(tileId: number): void {
  const tileIndex = this.tileOrganizer.releaseTileIndex(tileId);
  this.log(`video tileId removed: ${tileId} from tile-${tileIndex}`);
  this.hideTile(tileIndex);
  this.layoutFeaturedTile();
}

videoAvailabilityDidChange(availability: MeetingSessionVideoAvailability): void {
  this.canStartLocalVideo = availability.canStartLocalVideo;
  this.log(`video availability changed: canStartLocalVideo  ${availability.canStartLocalVideo}`);
}

showTile(tileElement: HTMLDivElement, tileState: VideoTileState) {
  if(tileState.isContent) {
    tileElement.classList.add('content');
    tileElement.classList.remove('featured');
    if(this._isCurrentUserTile(tileState)){
      tileElement.classList.remove(`active`);
    } else {
      tileElement.classList.add(`active`);
    }
  } else {
    tileElement.classList.add(`active`);
  }
}

hideTile(tileIndex: number): void {
  const tileElement = document.getElementById(`tile-${tileIndex}`) as HTMLDivElement;
  tileElement.classList.remove('active', 'featured', 'content','speaking');
}

tileIdForAttendeeId(attendeeId: string): number | null {
  for (const tile of this.audioVideo.getAllVideoTiles()) {
    const state = tile.state();
    if (state.boundAttendeeId === attendeeId) {
      return state.tileId;
    }
  }
  return null;
}

findContentTileId(): number | null {
  for (const tile of this.audioVideo.getAllVideoTiles()) {
    const state = tile.state();
    if (state.isContent) {
      return state.tileId;
    }
  }
  return null;
}

_hasCurrentUserSharingContentTile(): boolean {
  for (const tile of this.audioVideo.getAllVideoTiles()) {
    const state = tile.state();
    if (state.isContent && this._isCurrentUserSharingContent(state)) {
      return true;
    }
  }
  return false;
}

candidateTileId(): number | null {
  for (const tile of this.audioVideo.getAllVideoTiles()) {
    const state = tile.state();
    if (state.boundExternalUserId.includes('#candidate')) {
      return state.tileId;
    }
  }
  return null;
}

candidateTileIndex(): number | null {
  for (const tile of this.audioVideo.getAllVideoTiles()) {
    const state = tile.state();
    if (state.boundExternalUserId.includes('#candidate')) {
      return this.tileOrganizer.acquireTileIndex(state.tileId, state.localTile);
    }
  }
  return null;
}

activeTileId(): number | null {
  let contentTileId = this.findContentTileId();
  if (contentTileId !== null) {
    return contentTileId;
  }
  for (const attendeeId in this.roster) {
    if (this.roster[attendeeId].active) {
      return this.tileIdForAttendeeId(attendeeId);
    }
  }
  return null;
}

layoutFeaturedTile(): void {
  if (!this.meetingSession) {
    return;
  }
  const tilesIndices = this.visibleTileIndices();
  const localTileId = this.localTileId();
  const contentTile = this.findContentTileId();
  const isCurrentUserSharing = this._hasCurrentUserSharingContentTile();
  const candidateTile = this.candidateTileId();
  const activeSpeakingTile = this.activeTileId();
  let hasContentTile = contentTile !== null && !isCurrentUserSharing;
  let hasCandidateTile = candidateTile !== null;

  for (let i = 0; i < tilesIndices.length; i++) {
    const tileIndex = tilesIndices[i];
    const tileElement = document.getElementById(`tile-${tileIndex}`) as HTMLDivElement;
    const tileId = this.tileIndexToTileId[tileIndex];

    if(hasContentTile && tileId == contentTile && tileId !== localTileId && !hasCandidateTile){
      tileElement.classList.add('featured');
    } 
    
    if(hasCandidateTile && tileId == candidateTile && tileId !== localTileId && !hasContentTile){
      tileElement.classList.add('featured');
    } else {
      tileElement.classList.remove('featured');
    }

    if(activeSpeakingTile !== contentTile && tileId === activeSpeakingTile){
      tileElement.classList.add('speaking')
    } else {
      tileElement.classList.remove('speaking');
    }
  }
  this.updateGridClasses();
}

updateGridClasses() {
  const localTileId = this.localTileId();
  const contentTile = this.findContentTileId();
  const candidateTile = this.candidateTileId();
  const isCurrentUserSharing = this._hasCurrentUserSharingContentTile();
  let hasContentTile = contentTile !== null && !isCurrentUserSharing;
  let hasCandidateTile = candidateTile !== null;

  this.tileArea.className = `v-grid size-${this.availablelTileSize()}`;

  if((hasContentTile && contentTile !== localTileId) || (hasCandidateTile && candidateTile !== localTileId)){
    this.tileArea.classList.add('featured');
  } else {
    this.tileArea.classList.remove('featured');
  }
  if(this.displayProfile){
    this.tileArea.classList.add('half');
  } else {
    this.tileArea.classList.remove('half');
  }
}

availablelTileSize(): number {
  let count = this.tileOrganizer.remoteTileCount +
  (this.audioVideo.hasStartedLocalVideoTile() ? 1 : 0);
  for (const tile of this.audioVideo.getAllVideoTiles()) {
    if (this._isCurrentUserSharingContent(tile.state())) {
      count--;
      break;
    }
  }
  return count;
}

localTileId(): number | null {
  return this.audioVideo.hasStartedLocalVideoTile() ? this.audioVideo.getLocalVideoTile().state().tileId : null;
}

visibleTileIndices(): number[] {
  const tileKeys = Object.keys(this.tileOrganizer.tiles);
  const tiles = tileKeys.map(tileId => parseInt(tileId));
  return tiles;
}

setUpVideoTileElementResizer(): void {
  for (let i = 0; i <= DemoTileOrganizer.MAX_TILES; i++) {
    // const videoElem = document.getElementById(`video-${i}`) as HTMLVideoElement;
    const videoElem = document.getElementById(`video-${i}`) as any;
      videoElem.onresize = () => {
      if (videoElem.videoHeight > videoElem.videoWidth) {
        // portrait mode
        videoElem.style.objectFit = 'contain';
        this.log(`video-${i} changed to portrait mode resolution ${videoElem.videoWidth}x${videoElem.videoHeight}`);
      } else {
        videoElem.style.objectFit = 'cover';
      }
    };
  }
}

allowMaxContentShare(): boolean {
  const allowed = (new URL(window.location.href).searchParams.get('max-content-share')) === 'true';
  if (allowed) {
    return true;
  }
  return false;
}

connectionDidBecomePoor(): void {
  this.log('connection is poor');
  this.alertsAndNotificationsService.showToastMessage("Your internet connection is poor", "warning");
}

connectionDidSuggestStopVideo(): void {
  this.log('suggest turning the video off');
}

connectionDidBecomeGood(): void {
  this.log('connection is good now');
  this.alertsAndNotificationsService.showToastMessage("Your internet connection is good now", "success");
}

videoSendDidBecomeUnavailable(): void {
  this.log('sending video is not available');
}

contentShareDidStart(): void {
  this.toggleButton('button-content-share');
  this.log('content share started.');
}

contentShareDidStop(): void {
  this.toggleButton('button-content-share');
  this.log('content share stopped.');
  if (this.isButtonOn('button-content-share')) {
    this.buttonStates['button-content-share'] = false;
    this.buttonStates['button-pause-content-share'] = false;
    this.displayButtonStates();
  }
}

contentShareDidPause(): void {
  this.log('content share paused.');
}

contentShareDidUnpause(): void {
  this.log(`content share unpaused.`);
}


//custom methods
_displayWaitingParticipants(participants: any, actionType: string){
  let waitingParticipants = [];
  let joiningParticipant;
  if(actionType == "waitingParticipants"){
    waitingParticipants = [...participants];
  } else if(actionType === "joiningParticipant"){
    joiningParticipant = angular.copy(participants);
  }

  let modal = this.$uibModal.open({
    animation: true,
    ariaLabelledBy: 'modal-title',
    ariaDescribedBy: 'modal-body',
    backdrop: 'static',
    windowTopClass: 'four-dot-five-modal-small',
    component: "addParticipantModal",
    size: 'lg',
    resolve: {
      waitingParticipants: () => {
        return participants;
      },
      actionType: () => {
        return actionType;
      },
      joiningParticipant: () => {
        return joiningParticipant;
      },
      externalMeetingCallId: () => {
        return this.allMeetingInfo.externalMeetingCallId;
      }
    }
}); 
modal.result.then((callbackObject) => {
  if(callbackObject.callbackType == 'addParticipants' || callbackObject.callbackType == 'disApproveParticipants') {
      this.meetingService.addParticipant(callbackObject.entity, (data) => {
      //do nothing
      }, error =>{
        //do nothing;
      });
    }
  }, dismiss => {
  //do nothing
  });
}

_listenToChimeNotification(){
    this.webSocketsService.receiveChimeNotification().then(null, null, (message) => {
      console.log("PUSH NOTIFICATION FROM CHIME:");
      console.log(message);
      if(message.eventType == "PRIMARY_HOST_CHANGED"){
        // if(this.allMeetingInfo.primaryHost !== message.primaryHost){
          this.allMeetingInfo.primaryHost = message.primaryHost;
          this.allMeetingInfo.primaryHostAttendeeId = message.primaryHostAttendeeId;
          if(this.allMeetingInfo.primaryHost){
            this.showAlert('Primary Host', 'You are now the primary host', 'success')
          } else {
            this.showAlert('Primary Host Change', `${this.roster[this.allMeetingInfo.primaryHostAttendeeId].name} is now the primary host`, 'success')
          }
          this.updateRoster();
        // }
        // if(this.allowEditFeedback !== message.allowedToEditFeedback){
          this.$timeout(() => {
            if(this.allowEditFeedback && message.allowedToEditFeedback){
              //do nothing
            } else {
              this.allowEditFeedback = message.allowedToEditFeedback;
              this.$rootScope.$emit("REFRESH_FEEDBACK_TAB", message);
            }
          }, 1000);
        // }
        
      } else if(message.eventType == "PARTICIPANT_JOIN_MEETING") {
        if(message.participantJoinDetails.approved){
          if(this.allMeetingInfo.waitForHostToJoin || this.allMeetingInfo.waitForHostToApprove){
            new AsyncScheduler().start(async () => {
              await this.authenticate();
              await this._goToSelectDevices();
            });
          }
        } else {
          bootbox.hideAll();
          bootbox.alert({
            closeButton: false,
            title: "<div class='alert alert-warning' style='margin-bottom: 0px;'><i class='fas fa-exclamation-triangle fa-fw fa-lg'></i><strong>Join Request Disapproved</strong></div>",
            message: 'The host has not approved your request to join the meeting.',
            className: "zIndex1060",
            buttons: {
                ok: {
                    label: 'Ok',
                    className: 'btn-info'
                }
            },
            callback: () => {
              //do nothing
            }
          });
          this.$timeout(() => {
            this._exitScreen(null);
          },3000);
        }
      } else if(message.eventType == "PARTICIPANT_JOIN_APPROVAL"){
        let participant = angular.copy(message.participantJoinDetails);
        this._displayWaitingParticipants(participant, "joiningParticipant");
      } else if(message.eventType == "PERSON_IN_WAITING_ROOM"){
        if(!this.participantsInTestingRoom.includes(message.personInWaitingRoom)){
          this.participantsInTestingRoom.push(message.personInWaitingRoom);
          this.updateRoster();
        }
      } else if(message.eventType == "PARTICIPANT_FEEDBACK_EDIT_APPROVAL") {
        if(message.allowedToEditFeedback){
            if(!this.allowEditFeedback){
              let text = `You have been approved to edit`;
              bootbox.hideAll();
              bootbox.alert({
                closeButton: false,
                title: "<div class='alert alert-success mb-0'><i class='fa fa-check fa-fw fa-lg'></i><strong>Edit feedback</strong></div>",
                message: text,
                className: "zIndex1060",
                buttons: {
                    ok: {
                        label: 'Ok',
                        className: 'btn-info'
                    }
                },
              });
              this.allowEditFeedback = true;
            } else {
                //do nothing
            }
        } else if(this.allowEditFeedback){
            this.allowEditFeedback = false;
            let text = `${message.feedbackEditEnabledUserName} has been granted permission to edit the feedback`;
            bootbox.hideAll();
            bootbox.alert({
              closeButton: false,
              title: "<div class='alert alert-warning mb-0'><i class='fas fa-exclamation-triangle fa-fw fa-lg'></i><strong>Feedback Edit Control</strong></div>",
              message: text,
              className: "zIndex1060",
              buttons: {
                  ok: {
                      label: 'Ok',
                      className: 'btn-info'
                  }
              },
            });
        }
        this.$timeout(() =>{
          this.$rootScope.$emit("REFRESH_FEEDBACK_TAB", message);
        }, 1000);
      } else if(message.eventType == "PARTICIPANT_REMOVAL_ALERT") {
        const removalMessage = `You have been removed from the meeting by ${message.participantRemovedBy}.`;
        bootbox.hideAll();
        bootbox.alert({
          closeButton: false,
          title: "<div class='alert alert-danger mb-0'><i class='fa fa-warning fa-fw fa-lg'></i><strong>Removed from meeting</strong></div>",
          message: removalMessage,
          className: "zIndex1060",
          buttons: {
              ok: {
                  label: 'Ok',
                  className: 'btn-info'
              }
          },
        });
        this.$timeout(() => {
          new AsyncScheduler().start(async () => {
            await this.leave(null);
          });
        },3000)
      } else if(message.eventType == "REFRESH_CANDIDATE_BOOKLET") {
        this.$rootScope.$emit(message.eventType, message);
      } else if(message.eventType == "PARTICIPANT_FEEDBACK_EDIT_REQUEST" 
        || message.eventType == "PARTICIPANT_FEEDBACK_EDIT_REJECT"
        || message.eventType == "REFRESH_FEEDBACK_TAB") {
        this.$rootScope.$emit(message.eventType, message);
      }
  });
}

showAlert(title, message, type){
  bootbox.hideAll();
  let alertClass = `alert-${type}`;
  let iconClass= this._getBootBoxIconClass(type);
  bootbox.alert({
    closeButton: false,
    title: `<div class='alert ${alertClass} mb-0'><i class='${iconClass}'></i><strong>  ${title} </strong></div>`,
    message: message,
    className: "zIndex1060",
    buttons: {
        ok: {
            label: 'Ok',
            className: 'btn-info'
        }
    }
  });
}

showConfirm(title, message, type, successCallback, cancelCallback){
  bootbox.hideAll();
  let alertClass = `alert-${type}`;
  let iconClass= this._getBootBoxIconClass(type);

  bootbox.confirm({
    closeButton: false,
    title: `<div class='alert ${alertClass} mb-0'><i class='${iconClass}'></i><strong>  ${title} </strong></div>`,
    message: message,
    className: "zIndex1060",
    backdrop: true,
    onEscape: false,
    buttons: {
        confirm: {
            label: 'Ok',
            className: 'btn-info'
        },
        cancel: {
            label: 'Cancel',
            className: 'btn-danger'
        }
    },
    callback: (result) => {
        if(result) {
          if(successCallback){
            successCallback();
          }
        } else {
          if(cancelCallback){
            cancelCallback();
          }
        }
    }
  });
}

_getBootBoxIconClass(type) {
  if(type == 'warning'){
    return 'fas fa-exclamation-triangle fa-fw fa-lg';
  } else if(type == "danger"){
    return 'fa fa-times-circle fa-fw fa-lg';
  } else if(type == "success") {
    return 'fa fa-check fa-fw fa-lg';
  }
}

_cleanUI(){
  // const roster = document.getElementById('roster');
  // roster.innerHTML = "";
  // this.adjacentProfileViewEnabled = false;
}

toggleParticipantsView(){
  const tabPart = document.querySelector('.tab-part');
  const profilePart = document.querySelector('.profile-part');
  const mainContent = document.querySelector('.main-part');
  this.displayTab = !this.displayTab;
  this.displayProfile = false;
  this.updateGridClasses();
  mainContent.classList.remove('main-part_medium');
  mainContent.classList.remove('main-part_large');
  profilePart.classList.remove('profile-part_large');
  if(this.displayTab){
    tabPart.classList.remove('tab-part_small')
  } else {
    tabPart.classList.add('tab-part_small')
    mainContent.classList.add('main-part_large');
  }
}

toggleAdjacentProfileView(){
  const tabPart = document.querySelector('.tab-part');
  const profilePart = document.querySelector('.profile-part');
  const mainContent = document.querySelector('.main-part');
  this.displayTab = false;
  this.displayProfile = !this.displayProfile;
    mainContent.classList.remove('main-part_large');
    mainContent.classList.remove('main-part_medium');
    profilePart.classList.remove('profile-part_large');
    tabPart.classList.add('tab-part_small')
    if(this.displayProfile){
      profilePart.classList.add('profile-part_large')
      mainContent.classList.add('main-part_medium');
    } else {
      profilePart.classList.remove('profile-part_large')
      mainContent.classList.remove('main-part_medium');
      mainContent.classList.add('main-part_large');
  }
  this.updateGridClasses();
}

onProfileViewChange(viewAdjacentProfile){
  this.adjacentProfileViewEnabled = viewAdjacentProfile;
  if(viewAdjacentProfile && !this.displayProfile){
    this.toggleAdjacentProfileView();
  } else if (!viewAdjacentProfile && this.displayProfile){
    this.toggleAdjacentProfileView();
  }
}

async openTestModal(){
  this.showSelectDevicesModal = true;
  await this._initSelectDevicesModalValues();
  await this.populateAllDeviceLists();
}

closeSelectDevicesModal(){
  if(!this.isCameraOn){
    this.audioVideo.stopVideoPreviewForVideoInput(document.getElementById(
      'video-preview'
    ) as HTMLVideoElement);
  }
}

async _initSelectDevicesModalValues(){
  this.isMicroPhoneOn = this.isButtonOn('button-microphone'); 
  this.isCameraOn = this.isButtonOn('button-camera'); 
  this.isSpeakerOn = this.isButtonOn('button-speaker');

  const audioInput = document.getElementById('audio-input') as HTMLSelectElement;
  if(this.selectedMicroPhoneName.length > 0){
    this.$timeout(async () => {
      audioInput.value = this.selectedMicroPhoneName;
      if(this.isMicroPhoneOn){
        await this.openAudioInputFromSelection();
      } else {
        await this._setDeviceSelection("audioInput", this.selectedMicroPhoneName);
      }
    }, 500);
  } else {
    await this.openAudioInputFromSelection();
  }

  const audioOutput = document.getElementById('audio-output') as HTMLSelectElement;
  if(this.selectedSpeakerName.length > 0){
    this.$timeout(async () => {
      audioOutput.value = this.selectedSpeakerName;
      if(this.isSpeakerOn) {
        await this.audioVideo.chooseAudioOutputDevice(this.selectedSpeakerName);
      } 
      await this._setDeviceSelection("audioOutput", this.selectedSpeakerName);
    },500);
  } else {
    await this.openAudioOutputFromSelection();
  }

  if(this.isCameraOn){
    let videoInputSelection = document.getElementById('video-input') as HTMLSelectElement;
    this.$timeout(async () => {
      // videoInputSelection.value = this.selectedCameraName;
      await this.audioVideo.chooseVideoInputDevice(this.selectedCameraName);
      await this.openVideoInputFromSelection(this.selectedCameraName, true);
    }, 500);
  } else {
      this.audioVideo.stopVideoPreviewForVideoInput(document.getElementById(
        'video-preview'
      ) as HTMLVideoElement);
  }
  this._setEventListenersForSelectDevices();
}

_setEventListenersForSelectDevices(){
  const audioInput = document.getElementById('audio-input') as HTMLSelectElement;
  audioInput.addEventListener('change', async (_ev: Event) => {
    if(this.isMicroPhoneOn){
      this.log('audio input device is changed');
      await this.openAudioInputFromSelection();
    }
  });

  const videoInput = document.getElementById('video-input') as HTMLSelectElement;
  videoInput.addEventListener('change', async (_ev: Event) => {
    if(this.isCameraOn){
      this.log('video input device is changed');
      try {
        await this.openVideoInputFromSelection(videoInput.value, true);
      } catch (err) {
        this.log('no video input device selected');
      }
    }
  });

  const videoInputQuality = document.getElementById('video-input-quality') as HTMLSelectElement;
  videoInputQuality.addEventListener('change', async (_ev: Event) => {
    if(this.isCameraOn){
      this.log('Video input quality is changed');
      switch (videoInputQuality.value) {
        case '360p':
          this.audioVideo.chooseVideoInputQuality(640, 360, 15, 600);
          break;
        case '540p':
          this.audioVideo.chooseVideoInputQuality(960, 540, 15, 1400);
          break;
        case '720p':
          this.audioVideo.chooseVideoInputQuality(1280, 720, 15, 1400);
          break;
      }
      try {
        await this.openVideoInputFromSelection(videoInput.value, true);
      } catch (err) {
        this.log('no video input device selected');
      }
    }
  });

  const audioOutput = document.getElementById('audio-output') as HTMLSelectElement;
  audioOutput.addEventListener('change', async (_ev: Event) => {
    if(this.isSpeakerOn){
      this.log('audio output device is changed');
      await this.openAudioOutputFromSelection();
    }
  });

  document.getElementById('button-test-sound').addEventListener('click', e => {
    e.preventDefault();
    const audioOutput = document.getElementById('audio-output') as HTMLSelectElement;
    new TestSound(audioOutput.value);
  });
}

  refreshConference() {
    if(this.isMeetingInProgress && this.roster && Object.keys(this.roster).length > 0) {
      let primaryHostAttendeeId = this.allMeetingInfo.primaryHostAttendeeId;
      this.getPrimaryHostAttendeeId(() => {
        if(this.allMeetingInfo.primaryHostAttendeeId !== primaryHostAttendeeId) {
          if(this.allMeetingInfo.primaryHostAttendeeId == this.meetingSession.configuration.credentials.attendeeId) {
            this.allMeetingInfo.primaryHost = true;
          }
        }
        this.updateRoster();
        this.setFeedbackEnabledAttendeeId(() => {
          if(this.allowEditFeedback && this.enableEditForAttendeeId == this.meetingSession.configuration.credentials.attendeeId && this.participantType !== 'c') {
            //do nothing
          } else {
            this.$rootScope.$emit("REFRESH_CANDIDATE_BOOKLET", null);
          }
        });
      });
    }
  }
}
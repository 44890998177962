import { Inject, Injectable } from '@angular/core';
// import { filter } from 'angular-ui-router';
import moment from 'moment';

import { AuthService } from './auth.service';
import { CompanyService } from './company.service';
import { GenericService } from './generic.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class PlanService {

  urlConstants: any = {
    GET_ALL_PLANS: 'api/plan/getallplanfeatures',
    GET_CURRENT_PLAN_BY_COMPANY: 'api/plan/findcurrentplanbycompanyid',
    GET_EXPIRED_PLAN_BY_COMPANY: 'api/plan/findexpiredplanbycompanyid',
    GET_FUTURE_PLAN_BY_COMPANY: 'api/plan/findfutureplanbycompanyid',
    GET_PLAN: 'api/plan/findplanfeaturescapabilitybyid',
    GET_COMPANY_PLAN_DETAILS: 'api/plan/getplandetails',
    SAVE_PLAN: 'api/plan/saveplan',
    GET_AVAILABLE_TECH_ASSESSMENTS_COUNT: 'api/plan/getavailabletechassessmentscount',
    GET_AVAILABLE_JOB_BOARDS_PULL_COUNT: 'api/plan/getavailablejobboardresumescount'
  };

  planMessageCreationTimeTimer:any;

  constructor(@Inject(GenericService) private genericService:GenericService, @Inject(StorageService) private StorageService:StorageService,
    @Inject(AuthService) private authService:AuthService, @Inject(CompanyService) private companyService:CompanyService) { }

  getAllPlans(companyType, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_PLANS + '/' + companyType + '?companyId=' + this.authService.getUserDetails().company.companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getCurrentPlan(companyId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_CURRENT_PLAN_BY_COMPANY + '/' + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getExpiredPlan(companyId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_EXPIRED_PLAN_BY_COMPANY + '/' + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getFuturePlan(companyId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_FUTURE_PLAN_BY_COMPANY + '/' + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getPlan(companyId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_PLAN + '/' + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  savePlan(selectedPlan, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.SAVE_PLAN, selectedPlan).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getCompanyPlanDetails(companyId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_COMPANY_PLAN_DETAILS + '/' + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAvailableTechAssessmentsCount(companyId, jobId, successCallback, errorCallback) {
    let url = this.StorageService.get('baseurl') + this.urlConstants.GET_AVAILABLE_TECH_ASSESSMENTS_COUNT + '/' + companyId;
    if (jobId !== null) {
      url = url + '?jobId=' + jobId;
    }
    this.genericService.getObjects(url).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAvailableJobBoardsPullCount(companyId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_AVAILABLE_JOB_BOARDS_PULL_COUNT + '/' + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getPlanExpiryMessage(planType, planEndDate, setTimer) {
    // if (setTimer) {
    //   this.$timeout.cancel(this.planMessageCreationTimeTimer);
    // }

    let currentTime = moment(new Date());
    let endOfDateTime = moment(new Date().setHours(23, 59, 59, 999));
    let interval = moment.duration(endOfDateTime.diff(currentTime) + 5000);
    // let planEndDateDisplay = this.$filter('fourdotfiveDateFormat')(planEndDate);
    let planEndDateDisplay = moment(planEndDate).format("MMM DD, YYYY");
    let message = null;
    let diffDays = planEndDate.diff(currentTime, 'days') + 1;
    if (((planType == "Month to Month" || planType == "1 Month Only") && diffDays < 7) || (planType == "6 Months" && diffDays < 15)) {
      if (diffDays == 0) {
        message = "Your account has expired. Please contact your administrator immediately.";

        this.companyService.getCompanyDetails(this.authService.getUserDetails().company.companyId, (data) => {
          // this.authService.getUserDetails().company.companyState = data.companyState;
          this.authService.setCompanyStateLabel(data.companyState);
          this.companyService.setCompanyStateLabel();
        }, (error) => {
          //do nothing;
        });
      } else {
        message = "Your account will expire within " + "'" + diffDays + "' day(s) on '" + planEndDateDisplay + "'. Please contact your administrator immediately.";
      }
    }

    if (!_.isNull(message) && setTimer) {
      this.planMessageCreationTimeTimer = setTimeout(() => {
        this.getPlanExpiryMessage(planType, planEndDate, true);
      }, interval.asMilliseconds());

      // this.planMessageCreationTimeTimer = this.$timeout(() => {
      //   getPlanExpiryMessage(planType, planEndDate, true);
      // }, interval._milliseconds);
    }
    
    return message;
  }



}

<li class="pointer">
    <div class="pointer-inner">
        <div class="arrow"></div>
    </div>
</li>
<ng-container *ngIf="activities.length > 0">
    <li *ngFor="let activity of activities; let i = index;" class="item" role="menuitems"  style="padding:5px" 
        [ngClass]="{'activity-list-item': context =='candidateCard' || context =='candidateJobMatchCard'}">
        <div *ngIf="context =='candidateCard' || context =='candidateJobMatchCard' || context === 'requisitionCard' || context == 'userCard' || context === 'employeeCard'"
            (click)="expandText($event, activity)" style="color:black">
            <div class="col-xs-12 cursor-pointer">
                <div style="-webkit-box-orient: vertical" [ngClass]="{'two-line-text-overflow-ellipsis': activity.showShortMessageFlag}">
                    <div [innerHTML]="activity.activityDisplayValue" style="display:inline"></div>
                    <ng-template #activityNotesDisplayTemplate><span [innerHtml]="activity.notesDisplay"></span></ng-template>
                    <span *ngIf="activity.notesDisplay && !activity.isDocumentEmailActivity" class="fas fa-clipboard notes-icon ml-1" [tooltip]="activityNotesDisplayTemplate" 
                        tooltip-placement="auto top-left" container="body">
                    </span>
                    <span *ngIf="activity.isDocumentEmailActivity" class="fas fa-clipboard notes-icon ml-2" tooltip="Click to View Document Details" 
                        tooltip-placement="auto top-left" container="body" (click)="goToCandidateDocuments(activity)">
                    </span>
                </div>
            </div>
            <div class="col-xs-12 text-left" style="font-size:0.8em; padding-top:5px; padding-left: 10px;">
                <span [textContent]="activity.createdDate | fourDotFiveTimeStampFormatPipe"></span>
            </div>
            <div class="col-xs-12">
                <hr class="recent-activity-divider">
            </div>
        </div>
    </li>
</ng-container>
<li *ngIf="activities.length > 0" class="item text-center cursor-pointer">
    <div (click)="showAllActivity()" class="activity-item activity-item-info">
        Show All
    </div>
</li>
<li class="item text-center cursor-pointer" *ngIf="activities.length == 0 && !isLoading">
    <div class="activity-item activity-item-info">
        No activity recorded.
    </div>
</li>
<li class="item text-center cursor-pointer" *ngIf="activities.length == 0 && isLoading">
    <div class="activity-item activity-item-info">
        <span class="fa fa-spinner fa-spin"></span>
    </div>
</li>
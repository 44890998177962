<div class="skill">
    <div class="col-xs-12">
        <div class="main-box p-4">
            <div>
                <h5 class="mb-4 pb-1 mt-0 uploaded-skill-heading">Uploaded Skills Count</h5>
            </div>
            <div class="d-flex flex-wrap justify-content-between px-4">
                <p>All Skills : 
                    <span class="emerald cursor-pointer" (click)="showUploadedSKills('all')" *ngIf="uploadSkillsCount.allSkills > 0">{{uploadSkillsCount.allSkills}}</span>
                    <span *ngIf="uploadSkillsCount.allSkills == 0">{{uploadSkillsCount.allSkills}}</span>
                </p>
            </div>
            <div class="d-flex flex-wrap justify-content-between px-4">
                <p>Added Skills : 
                    <span class="emerald cursor-pointer" (click)="showUploadedSKills('add')" *ngIf="uploadSkillsCount.skillsAdded > 0">{{uploadSkillsCount.skillsAdded}}</span>
                    <span *ngIf="uploadSkillsCount.skillsAdded == 0">{{uploadSkillsCount.skillsAdded}}</span>
                </p>
                <p>Updated Skills : 
                    <span class="emerald cursor-pointer" (click)="showUploadedSKills('update')" *ngIf="uploadSkillsCount.skillsUpdated > 0">{{uploadSkillsCount.skillsUpdated}}</span>
                    <span *ngIf="uploadSkillsCount.skillsUpdated == 0">{{uploadSkillsCount.skillsUpdated}}</span>
                </p>
                <p>Deleted Skills : 
                    <span class="emerald cursor-pointer" (click)="showUploadedSKills('delete')" *ngIf="uploadSkillsCount.skillsDeleted > 0">{{uploadSkillsCount.skillsDeleted}}</span>
                    <span *ngIf="uploadSkillsCount.skillsDeleted == 0">{{uploadSkillsCount.skillsDeleted}}</span>
                </p>
                <p>Not Processed Skills : 
                    <span class="emerald cursor-pointer" (click)="showUploadedSKills('error')" *ngIf="uploadSkillsCount.skillsError > 0">{{uploadSkillsCount.skillsError}}</span>
                    <span *ngIf="uploadSkillsCount.skillsError == 0">{{uploadSkillsCount.skillsError}}</span>
                </p>
                <p>Unchanged Skills : 
                    <span class="emerald cursor-pointer" (click)="showUploadedSKills('unchanged')" *ngIf="uploadSkillsCount.skillsUnchanged > 0">{{uploadSkillsCount.skillsUnchanged}}</span>
                    <span *ngIf="uploadSkillsCount.skillsUnchanged == 0">{{uploadSkillsCount.skillsUnchanged}}</span>
                </p>
            </div>
        </div>
    </div>
    <div class="col-xs-12">
        <div class="main-box clearfix">
            <div class="main-box-body p-4">
                <h4 class="mb-4 mt-0 uploaded-skill-heading">{{uploadedSkillsHeading}}</h4>
                <div style="max-height: 300px; overflow: auto;">
                    <table class="table table-bordered table-responsive">
                        <thead>
                            <tr>
                                <th>Skill Name </th>
                                <th>Category</th>
                                <th>Alternate Skills</th>
                                <th>Parent Skills</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let skill of uploadedSkills; let i = index" [ngClass]="{'text-danger': skill.operation == 'error'}">
                                <td>{{skill.skill}}</td>
                                <td>{{skill.category}}</td>
                                <td>
                                    <div>
                                        <span
                                            *ngIf="!skill.alternateSkills || skill.alternateSkills.length == 0 || skill.alternateSkills[0] == null">-</span>
                                        <span *ngIf="skill.alternateSkills && skill.alternateSkills.length > 0">
                                            <span *ngFor="let alternateSkill of skill.alternateSkills; let j = index">
                                                <span *ngIf="alternateSkill">
                                                    <span>{{alternateSkill.skill}}</span>
                                                    <span *ngIf="j+1 < skill.alternateSkills.length">, </span>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <span *ngIf="!skill.parentSkills || skill.parentSkills.length == 0">-</span>
                                        <span *ngIf="skill.parentSkills && skill.parentSkills.length > 0">
                                            <span *ngFor="let parentSkill of skill.parentSkills; let j = index">
                                                <span>{{parentSkill.skill}}</span>
                                                <span *ngIf="j+1 < skill.parentSkills.length" class="pl-1">, </span>
                                            </span>
                                        </span>
                                    </div>
                                </td>
                                <td style="line-height: 2;">
                                    <span class="mr-2">
                                            <i class="fa fa-pencil-alt" *ngIf="skill.operation === 'updated' || skill.operation === 'update'"></i>
                                            <i class="fa fa-plus" *ngIf="skill.operation === 'added'"></i>
                                            <i class="fa fa-minus" *ngIf="skill.operation === 'deleted'"></i>
                                            <i class="fa fa-exclamation-circle error-skill-icon" *ngIf="skill.operation === 'error'"></i>
                                    </span>
                                    <span [innerHTML]="skill.activityMessage"></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
            </div>
        </div>
    </div>
</div>
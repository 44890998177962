import { downgradeComponent } from "@angular/upgrade/static";
import { NotificationPreferencePageComponent } from './notification-preference-page.component';

export const NotificationPreferencePageAjsModule = angular
  .module('notificationPreferencePage', [])
  .directive('notificationPreferencePage', downgradeComponent({ component: NotificationPreferencePageComponent }))
  .config(
    ['$stateProvider', ($stateProvider) => {
      $stateProvider.state('missioncontrol.notificationpreference', {
        url: '/notificationpreference',
        component: NotificationPreferencePageComponent,
        data: {
          requireLogin: true
        },
        params: {
          userId: null,
        },
        resolve: {
          myNotificationPreferenceData: ['$rootScope', '$stateParams', 'genericService', 'StorageService', function ($rootScope, $stateParams, genericService, StorageService) {
            return genericService.getObjects(StorageService.get('baseurl') + 'api/notification/getnotificationprefs' + '/' + angular.copy($stateParams.userId) + '/' + $rootScope.userDetails.company.companyId).subscribe((data) => {
              return data.body;
            })
          }]
        }
      });
    }
    ])
  .name;


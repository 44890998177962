<div class="approw">
    <!-- <div class="row">
        <div class="col-lg-12">
            <div id="content-header" class="clearfix">
                <div class="pull-left">
                    <ol class="breadcrumb">
                        <li><a (click)="navigateToHome()">Home</a></li>
                        <li class="active"><span>Manage Skills</span></li>
                    </ol>
                    <h1 class="heading-css">Manage Skills</h1>
                </div>
            </div>
        </div>
    </div> -->
    <div class="col-xs-12">
        <div class="main-box clearfix">
            <div class="main-box-body clearfix pt-5">
                <div class="col-xs-12">
                    <div class="mr-2 d-flex align-items-center pull-right">
                        <h1 class="mb-0">
                            <i class="fa fa-list mr-2 cursor-pointer" style="font-size: smaller;" placement="top" tooltip="View Activities" container="body"
                                [adaptivePosition]="false" (click)="viewSkillActivities(skillActivitiesTemplate)"></i>
                        </h1>
                    </div>
                    <!--filter starts-->
                    <div class="main-box" style="padding-top: 1%;" style="max-width: 1000px;">
                        <div class="main-box-body clearfix pb-3">
                            <p class="font-bold mt-4">Filters</p>
                            <div class="col-xs-12 px-0">
                                <div class="col-xs-12 col-md-8 col-lg-6 px-0 mb-2">
                                    <div class="d-flex border skills-filters mr-3" style="border-radius: 5px;">
                                        <div class="col-sm-6 pr-0">
                                            <div class="col-xs-12 my-2">
                                                Search by Skill/Alternate Skill:
                                            </div>
                                            <div class="col-xs-12">
                                                <div class="">
                                                    <input type="text" name="searchSkill" id="searchSkill" class="form-control"
                                                        [(ngModel)]="skillFilterObject.searchSkill" placeholder="Type to Filter"
                                                        autocomplete="off" (input)="onSearchTextChange($event)" (keyup.enter)="querySkillSearches()">
                                                </div>
                                            </div>
                                        </div>
                                        <span class="mx-1 pt-4 d-flex justify-content-center align-items-center">OR</span>
                                        <div class="col-sm-6 pl-0">
                                            <div class="col-xs-12 my-2">
                                                Search by Parent Skill:
                                            </div>
                                            <div class="col-xs-12">
                                                <div class="">
                                                    <input type="text" name="searchParentSkill" id="searchParentSkill" class="form-control"
                                                        [(ngModel)]="skillFilterObject.searchByParentSkill" placeholder="Type to Filter"
                                                        autocomplete="off" (input)="onSearchTextChange($event)" (keyup.enter)="querySkillSearches()">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 px-0 mt-2 skills-filters">
                                    <div class="col-xs-12 mb-2">
                                        Category:
                                    </div>
                                    <div class="col-xs-12">
                                        <ng-select name="categorySelect" [items]="skillCategories"
                                            dropdownPosition="bottom" bindLabel="name" placeholder="None selected"
                                            [(ngModel)]="selectedCategory" [closeOnSelect]="false" [multiple]="true"
                                            (change)="onCategorySelect()">
                                            <ng-template ng-header-tmp>
                                                <div class="d-flex">
                                                    <div class="mr-2 custom-ng-select-btn"
                                                        (click)="selectOrDeselectAllCategories('selectAll')">Select All
                                                    </div>
                                                    <div class="custom-ng-select-btn"
                                                        (click)="selectOrDeselectAllCategories('deSelectAll')">Select
                                                        None
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                                <div class="ng-value d-flex" *ngFor="let item of items">
                                                    <span class="ng-value-label">{{item.name}}</span>
                                                    <span class="ng-value-icon right" (click)="clear(item)"
                                                        aria-hidden="true">×</span>
                                                </div>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 px-0 mt-2">
                                    <div class="col-xs-12 mb-2">
                                        Sort By:
                                    </div>
                                    <div class="col-xs-12">
                                        <ng-select name="categorySelect" [items]="skillSorts"
                                            dropdownPosition="bottom" bindLabel="name" placeholder="None selected"
                                            [(ngModel)]="selectedSkillSort" [closeOnSelect]="true" [searchable]="false"
                                            (change)="onSKillSortSelected()" [clearable]="false">
                                            <ng-template ng-option-tmp let-item="item">
                                                <span class="mr-2">{{item.name}}</span>
                                                <span *ngIf="item.direction == 'ASC'"><i class="fa fa-arrow-up"></i></span>
                                                <span *ngIf="item.direction == 'DESC'"><i class="fa fa-arrow-down"></i></span>
                                            </ng-template>
                                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                                <div class="ng-value d-flex" *ngFor="let item of items">
                                                    <span class="ng-value-label">
                                                        <span class="mr-2">{{item.name}}</span>
                                                        <span *ngIf="item.direction == 'ASC'"><i class="fa fa-arrow-up"></i></span>
                                                        <span *ngIf="item.direction == 'DESC'"><i class="fa fa-arrow-down"></i></span>
                                                    </span>
                                                </div>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 px-0 py-2">
                                <div class="pull-left pl-3">
                                    <input class="position-relative" style="top: 1px" type="checkbox" id="includeAlternateSkills" name="includeAlternateSkills"
                                        [(ngModel)]="skillFilterObject.includeAlternateSkills" (change)="onIncludeAlternateSkillsChange()">
                                    <label for="includeAlternateSkills" class="ml-2">
                                        <strong>Include Alternate Skills</strong>
                                    </label>
                                </div>
                            </div>
                            <div class="row pull-right mt-3">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group" style="position:relative">
                                        <button type="button" class="btn btn-primary mr-2" [ngClass]="{'highlight-search-button': isFiltersChanged}" (click)="querySkillSearches()">Search</button>
                                        <button type="button" class="btn btn-danger" (click)="resetFilterSkills()">Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 px-0" style="max-width: 1000px;">
                        <div class="col-xs-12 px-0">
                            <div class="pull-right">
                                <div class="d-flex flex-row">
                                    <div class="mr-2" *ngIf="selectedTableRowCount > 0">
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-primary" data-toggle="dropdown">Selected {{selectedTableRowCount}}</button>
                                            <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false">
                                                <span class="caret"></span>
                                                <span class="sr-only">Toggle Dropdown</span>
                                            </button>
                                            <ul class="dropdown-menu">
                                                <li [ngClass]="{'pointer-events-none opacity-half' : !selectedTableRowCount || selectedTableRowCount == 0}">
                                                    <a class="pl-4" (click)="updateOrDeleteSelectedSkills('update', 'Update Skills', false)">
                                                        <span class="fas fa-pencil-alt skill-action"></span>
                                                        <span class="pl-3">Update Skills</span>
                                                    </a>
                                                </li>
                                                <li [ngClass]="{'pointer-events-none opacity-half': !selectedTableRowCount || selectedTableRowCount == 0}">
                                                    <a class="pl-4" (click)="updateOrDeleteSelectedSkills('delete', 'Delete Skills', false)">
                                                        <span class="far fa-trash-alt skill-action"></span>
                                                        <span class="pl-3">Delete Skills</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-primary" data-toggle="dropdown">Action</button>
                                        <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false">
                                            <span class="caret"></span>
                                            <span class="sr-only">Toggle Dropdown</span>
                                        </button>
                                        <ul class="dropdown-menu">
                                            <li>
                                                <a class="pl-4" (click)="addEditMoveKill(addEditSkillTemplate, 'addSkill', null)">
                                                    <span class="fa fa-plus skill-action"></span>
                                                    <span class="pl-3">Add New Skill</span>
                                                </a>
                                            </li>
                                            <li role="separator" class="divider my-1"></li>
                                            <li>
                                                <a class="pl-4" (click)="uploadNewSkillFile()">
                                                    <span class="fa fa-upload skill-action"></span>
                                                   <span class="pl-3">Upload Skills</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a class="pl-4" (click)="downloadAllSkills(downloadSkillsTemplate)">
                                                    <span class="fa fa-download skill-action"></span>
                                                    <span class="pl-3">Download Skills</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a class="pl-4" (click)="viewUploadedSkills()">
                                                    <span class="far fa-eye skill-action"></span>
                                                   <span class="pl-3">View Uploaded Files</span>
                                                </a>
                                            </li>
                                            <li role="separator" class="divider my-1"></li>
                                            <li>
                                                <a class="pl-4" (click)="deleteAllSkillsInPage()">
                                                    <span class="far fa-trash-alt skill-action"></span>
                                                    <span class="pl-3">Delete All Skills</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 px-0 mt-4">
                            <div class="main-box">
                                <div class="main-body px-4 py-2">
                                    <ngx-datatable class="material manage-skills-datatable" [rows]="allSkillsTablePaginationData" [columnMode]="'force'"
                                        [headerHeight]="43" [footerHeight]="50" [rowHeight]="'auto'" [loadingIndicator]="isLoading" [rowClass]="getRowClass"
                                        [scrollbarV]="true" [virtualization]="false" [externalPaging]="true" [count]="page.totalElements"
                                        [offset]="page.pageNumber" [limit]="page.size" (page)="setTablePage($event)">
                                        <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false" [draggable]="false"
                                            [resizeable]="false">
                                            <ng-template ngx-datatable-header-template>
                                                <input type="checkbox" [checked]="isAllTableRowsInPageSelected" class="table-input cursor-pointer pull-left"
                                                        [disabled]="!allSkillsTablePaginationData || allSkillsTablePaginationData.length == 0"
                                                        (change)="selectOrDeselectAllRowsInPage()" />
                                            </ng-template>
                                            <ng-template ngx-datatable-cell-template let-row="row">
                                                <input class="table-input cursor-pointer" type="checkbox" [checked]="row.isSelected"
                                                    (click)="selectOrDeselectRow(row)" />
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column prop="skill" [headerTemplate]="this.headerSkillTemplateRef" [sortable]="false">
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Alternate Skills" [cellTemplate]="this.alternateSkillTemplateRef" [sortable]="false">
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Category" prop="category" [cellTemplate]="this.categorySkillTemplateRef"
                                            [headerTemplate]="this.headerCategoryTemplateRef" [sortable]="false">
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Parent Skills" [cellTemplate]="this.parentSkillTemplateRef"
                                            [headerTemplate]="this.headerParentTemplateRef" [sortable]="false">
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Actions" [cellTemplate]="actionsSkillTemplateRef" [sortable]="false">
                                        </ngx-datatable-column>
                                    </ngx-datatable>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Tree view Start-->
                    <div class="col-xs-12 px-0" [hidden]="!skillsHierarchyData || skillsHierarchyData.length === 0">
                        <div class="col-xs-12 px-0 mt-4">
                            <div class="main-box">
                                <div class="col-xs-12 mb-2">
                                    <h2 class="tree-view-heading pt-1">Skills Hierarchy</h2>
                                    <div class="pull-right pr-3 d-flex">
                                        <div class="mr-3" *ngIf="selectedHierarchySkillCount > 0">
                                            <div class="btn-group">
                                                <button type="button" class="btn btn-primary" data-toggle="dropdown">Selected {{selectedHierarchySkillCount}}</button>
                                                <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false">
                                                    <span class="caret"></span>
                                                    <span class="sr-only">Toggle Dropdown</span>
                                                </button>
                                                <ul class="dropdown-menu">
                                                    <li [ngClass]="{'pointer-events-none opacity-half' : !selectedHierarchySkillCount || selectedHierarchySkillCount == 0}">
                                                        <a class="pl-4" (click)="updateOrDeleteSelectedSkills('update', 'Update Skills', true)">
                                                            <span class="fas fa-pencil-alt skill-action"></span>
                                                            <span class="pl-3">Update Skills</span>
                                                        </a>
                                                    </li>
                                                    <li [ngClass]="{'pointer-events-none opacity-half': !selectedHierarchySkillCount || selectedHierarchySkillCount == 0}">
                                                        <a class="pl-4" (click)="updateOrDeleteSelectedSkills('delete', 'Delete Skills', true)">
                                                            <span class="far fa-trash-alt skill-action"></span>
                                                            <span class="pl-3">Delete Skills</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="pr-4">
                                            <input type="text" name="searchSkill" id="hierarchySearchSkill" class="form-control mb-1"
                                                [(ngModel)]="hierarchySearchSkill" placeholder="Search by skill"
                                                autocomplete="off" (keyup.enter)="querySkillSearchesInHierarchy()">
                                            <span *ngIf="!isHierarchySkillExists" class="pl-2" style="font-size:12px; color: red;">Skill Name doesn't exists.</span>
                                        </div>
                                    </div>
                                </div>
                                <div style="overflow: auto; min-height: 500px; max-height: 750px;" class="pl-3" id="tree-scroll">
                                    <form id="form1" class="mt-5">
                                        <div class="tree" id="FamilyTreeDiv">
                                            <ul class="d-flex">
                                                <ng-template #horizontalHierarchyTemplate let-horizontalHierarchyList>
                                                    <li *ngFor="let skill of horizontalHierarchyList">
                                                        <div class="tree-skills-container d-flex justify-content-center"
                                                            [ngClass]="{'tree-multiple-skills': skill.skills && skill.skills.length > 1}">
                                                            <div *ngFor="let subSkill of skill.skills; let skillNameIndex=index" class="position-relative" 
                                                                (mouseover)="showSkillActions(subSkill, skillsHierarchyData, skill)" [id]="subSkill.skill+'id'">
                                                                <div class="tree-multiple-skills-actions-card d-flex" *ngIf="subSkill.showSkillActions">
                                                                    <a class="table-link primary cursor-pointer" [ngClass]="{'pointer-events-none opacity-half': subSkill.isRootSkill || subSkill.skill === 'ROOT'}"
                                                                        placement="top" tooltip="Edit Skill" container="body"
                                                                        [adaptivePosition]="false" (click)="addEditMoveKill(addEditSkillTemplate, 'editSkill', subSkill)">
                                                                        <span class="fa-stack">
                                                                            <i class="fa fa-square fa-stack-2x"></i>
                                                                            <i class="fa-stack-1x fa-inverse fas fa-pencil-alt"></i>
                                                                        </span>
                                                                    </a>
                                                                    <a class="table-link primary cursor-pointer" [ngClass]="{'pointer-events-none opacity-half': subSkill.alternate || subSkill.isRootSkill || subSkill.skill === 'ROOT'}" placement="top" tooltip="Add Child Skill" container="body"
                                                                        [adaptivePosition]="false" (click)="addEditMoveKill(addEditSkillTemplate, 'childSkill', subSkill)">
                                                                        <span class="fa-stack">
                                                                            <i class="fa fa-square fa-stack-2x"></i>
                                                                            <i class="fa-stack-1x fa-inverse fa fa-plus" style="font-size: 14px;"></i>
                                                                        </span>
                                                                    </a>
                                                                    <a class="table-link primary cursor-pointer" [ngClass]="{'pointer-events-none opacity-half': subSkill.alternate || subSkill.isRootSkill || subSkill.skill === 'ROOT'}" placement="top" tooltip="Move Skill" container="body"
                                                                        [adaptivePosition]="false" (click)="addEditMoveKill(addEditSkillTemplate, 'moveSkill', subSkill)">
                                                                        <span class="fa-stack">
                                                                            <i class="fa fa-square fa-stack-2x"></i>
                                                                            <i class="fa-stack-1x fa-inverse fas fa-arrows-alt" style="font-size: 14px;"></i>
                                                                        </span>
                                                                    </a>
                                                                    <a style="color: #e84e40;" class="table-link danger cursor-pointer" [ngClass]="{'pointer-events-none opacity-half': subSkill.isRootSkill || subSkill.skill === 'ROOT'}"
                                                                        placement="top" tooltip="Delete Skill"
                                                                        container="body" [adaptivePosition]="false" (click)="deleteSkill(subSkill)">
                                                                        <span class="fa-stack">
                                                                            <i class="fa fa-square fa-stack-2x"></i>
                                                                            <i class="far fa-trash-alt fa-stack-1x fa-inverse"></i>
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                                <span [ngClass]="{'tree-multiple-child-skills mx-2': skill.skills && skill.skills.length > 1, 
                                                                    'tree-child-skill-name': subSkill.showHierarchy || subSkill.showAlternateHierarchy, 
                                                                    'tree-child-searched-skill': subSkill.showSearchedSkillHierarchy || subSkill.showSearchedAlternateSkillHierarchy}" 
                                                                    class="d-flex tree-multiple-child-skill-name position-relative">
                                                                    <span class="d-block pr-1" *ngIf="!subSkill.isRootSkill">
                                                                        <input type="checkbox" [checked]="subSkill.isHierarchySkillSelected" class="m-0 table-input cursor-pointer position-relative hierarchy-selection"
                                                                        (change)="selectOrDeselectAllRowsInHierarchy(subSkill)"/>
                                                                    </span>
                                                                    <span style="font-size: 14px;" class="mr-3 cursor-pointer d-block pl-2" (click)="expandOrCollapseSkills(skill)" *ngIf="skill.subSkills.length > 0">
                                                                        <span *ngIf="!skill.isExpanded"><i class="fa fa-plus-circle" aria-hidden="true"></i></span>
                                                                        <span *ngIf="skill.isExpanded"><i class="fa fa-minus-circle" aria-hidden="true"></i></span>
                                                                    </span>
                                                                    <span class="d-flex">
                                                                        <span class="px-2">{{subSkill.skill}}</span>
                                                                    </span>
                                                                    <span *ngIf="subSkill.alternateSkills && subSkill.alternateSkills.length > 0" class="d-block pr-2">
                                                                        <a class="table-link primary cursor-pointer" (mouseenter)="showAlternateSkillTooltip(subSkill)">
                                                                             <span class="pr-2" containerClass="candidate-custom-popover" container="body" triggers="click:mouseleave"
                                                                                   [adaptivePosition]="false" [tooltip]="viewAlternateSkillTemplate" placement="bottom">
                                                                                 <span class="alternate-skill-icon" container="body" triggers="mouseenter:mouseleave"
                                                                                    [adaptivePosition]="false" tooltip="View Alternate Skill" placement="top">*</span>
                                                                             </span>
                                                                         </a>
                                                                    </span>
                                                                    <span class="position-relative d-block pl-2 cursor-default" style="top: -6px; right: -2px; font-size: 12px;" 
                                                                        container="body" triggers="mouseenter:mouseleave" [adaptivePosition]="false" tooltip="Category: {{subSkill.category | textSentenceCase}}" placement="bottom">
                                                                        {{subSkill.category | categoryInHierarchyPipe}}</span>
                                                                </span>
                                                                <span [ngClass]="{'tree-multiple-child-skills-bottom mx-2': skill.skills && skill.skills.length > 1}"></span>
                                                            </div>
                                                        </div>
                                                        <ul *ngIf="skill.subSkills && skill.subSkills.length > 0 && skill.isExpanded" class="d-flex justify-content-center">
                                                            <ng-container *ngTemplateOutlet="horizontalHierarchyTemplate; context:{ $implicit: skill.subSkills }"></ng-container>
                                                        </ul>
                                                    </li>
                                                </ng-template>
                                                <ng-container *ngTemplateOutlet="horizontalHierarchyTemplate; context:{ $implicit: skillsHierarchyData }"></ng-container>
                                            </ul>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Tree view End -->
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #categorySkillTemplate let-row="row">
    <span>{{this._getSkillCategoryDisplayName(row.category)}}</span>
</ng-template>

<ng-template #headerSkillTemplate let-row="row">
    <span>Skill Name</span>
    <span class="ml-2" style="font-size: 11px;" *ngIf="tableFilteredSort && tableFilteredSort.sortColumn == 'skill'">
        <span *ngIf="tableFilteredSort.sortDir == 'ASC'"><i class="fa fa-arrow-up"></i></span>
        <span *ngIf="tableFilteredSort.sortDir == 'DESC'"><i class="fa fa-arrow-down"></i></span>
    </span>
</ng-template>

<ng-template #headerCategoryTemplate let-row="row">
    <span>Category</span>
    <span class="ml-2" style="font-size: 11px;" *ngIf="tableFilteredSort && tableFilteredSort.sortColumn == 'category'">
        <span *ngIf="tableFilteredSort.sortDir == 'ASC'"><i class="fa fa-arrow-up"></i></span>
        <span *ngIf="tableFilteredSort.sortDir == 'DESC'"><i class="fa fa-arrow-down"></i></span>
    </span>
</ng-template>

<ng-template #headerParentTemplate let-row="row">
    <span>Parent Skill</span>
    <span class="ml-2" style="font-size: 11px;" *ngIf="tableFilteredSort && tableFilteredSort.sortColumn == 'parent'">
        <span *ngIf="tableFilteredSort.sortDir == 'ASC'"><i class="fa fa-arrow-up"></i></span>
        <span *ngIf="tableFilteredSort.sortDir == 'DESC'"><i class="fa fa-arrow-down"></i></span>
    </span>
</ng-template>

<ng-template #alternateSkillTemplate let-row="row">
    <span *ngIf="!row.alternateSkills || row.alternateSkills.length == 0 || row.alternateSkills[0] == null">-</span>
    <span *ngIf="row.alternateSkills && row.alternateSkills.length > 0">
        <span *ngFor="let alternateSkill of row.alternateSkills; let i = index">
            <span *ngIf="alternateSkill">
                <span>{{alternateSkill.skill}}</span>
                <span *ngIf="i+1 < row.alternateSkills.length">, </span>
            </span>
        </span>
    </span>
</ng-template>

<ng-template #parentSkillTemplate let-row="row">
    <span *ngIf="!row.parentSkills || row.parentSkills.length == 0">-</span>
    <span *ngIf="row.parentSkills && row.parentSkills.length > 0">
        <span *ngFor="let parentSkill of row.parentSkills; let i = index">
            <span>{{parentSkill.skill}}</span>
            <span *ngIf="i+1 < row.parentSkills.length" class="pl-1">, </span>
        </span>
    </span>
</ng-template>

<ng-template #actionsSkillTemplate let-row="row">
    <div class="d-flex flex-nowrap">
        <a class="table-link primary cursor-pointer" [ngClass]="{'pointer-events-none opacity-half': row.isRootSkill}"
            placement="top" tooltip="Edit Skill" container="body"
            [adaptivePosition]="false" (click)="addEditMoveKill(addEditSkillTemplate, 'editSkill', row)">
            <span class="fa-stack">
                <i class="fa fa-square fa-stack-2x"></i>
                <i class="fa-stack-1x fa-inverse"
                    [ngClass]="{'far fa-eye': nonEditable, 'fas fa-pencil-alt': !nonEditable}"></i>
            </span>
        </a>
        <a class="table-link primary cursor-pointer" [ngClass]="{'pointer-events-none opacity-half': row.alternate || row.isRootSkill}" placement="top" tooltip="Add Child Skill" container="body"
            [adaptivePosition]="false" (click)="addEditMoveKill(addEditSkillTemplate, 'childSkill', row)">
            <span class="fa-stack">
                <i class="fa fa-square fa-stack-2x"></i>
                <i class="fa-stack-1x fa-inverse fa fa-plus" style="font-size: 14px;"></i>
            </span>
        </a>
        <a class="table-link primary cursor-pointer" [ngClass]="{'pointer-events-none opacity-half': row.alternate || row.isRootSkill}" placement="top" tooltip="Move Skill" container="body"
            [adaptivePosition]="false" (click)="addEditMoveKill(addEditSkillTemplate, 'moveSkill', row)">
            <span class="fa-stack">
                <i class="fa fa-square fa-stack-2x"></i>
                <i class="fa-stack-1x fa-inverse fas fa-arrows-alt" style="font-size: 14px;"></i>
            </span>
        </a>
        <a class="table-link cursor-pointer" [ngClass]="{'primary': !row.showHierarchy, 'skill-hierarchy-color': row.showHierarchy}" placement="top" 
            tooltip="{{!row.showHierarchy ? 'Show Skill Hierarchy' : 'Hide Skill Hierarchy'}}" container="body"
            [adaptivePosition]="false" (click)="showSkillsHierarchy(row)">
            <span class="fa-stack">
                <i class="fa fa-square fa-stack-2x"></i>
                <i class="fa-stack-1x fa-inverse fa fa-sitemap"></i>
            </span>
        </a>
        <a style="color: #e84e40;" class="table-link danger cursor-pointer" [ngClass]="{'pointer-events-none opacity-half': row.isRootSkill}"
            placement="top" tooltip="Delete Skill"
            container="body" [adaptivePosition]="false" (click)="deleteSkill(row)">
            <span class="fa-stack">
                <i class="fa fa-square fa-stack-2x"></i>
                <i class="far fa-trash-alt fa-stack-1x fa-inverse"></i>
            </span>
        </a>
    </div>
</ng-template>

<ng-template #addEditSkillTemplate>
    <div class="ngx-modal-header">
        <h4 class="modal-title pull-left">
            <span *ngIf="manageSkillType == 'addSkill'">Add Skill</span>
            <span *ngIf="manageSkillType == 'editSkill' && !editSkillObject.alternate">Edit Skill</span>
            <span *ngIf="manageSkillType == 'editSkill' && editSkillObject.alternate">Edit Alternate Skill</span>
            <span *ngIf="manageSkillType == 'childSkill'">Add Child Skill</span>
            <span *ngIf="manageSkillType == 'moveSkill'">Move Skill</span>
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-add-edit-manage-skills-skill [manageSkillType]="manageSkillType" [editSkillObject]="editSkillObject"
            [allSkills]="allSkillsTableDataOriginal" [skillCategories]="skillCategories" (saveCallback)="saveSkillDetails($event)"
            (cancelCallback)="closeModal()">
        </app-add-edit-manage-skills-skill>
    </div>
</ng-template>

<ng-template #viewAlternateSkillTemplate>
    <div class="candidate-custom-popover-container">
        <div class="candidate-custom-popover-title">
            <p class="mb-0">Alternate Skills</p>
        </div>
        <div class="candidate-custom-popover-content">
            <div class="pt-3 d-flex flex-wrap">
                <span *ngFor="let alternateSkill of viewAlternateSkillData.alternateSkills; let i = index" class="mb-3">
                    <span class="label label-primary scope-badge mr-2 mb-2 view-alternate-skill">{{alternateSkill.skill}}</span>
                </span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #downloadSkillsTemplate>
    <div class="ngx-modal-header">
        <h4 class="modal-title pull-left">
            <Span>Download Skills</Span>
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeDownloadSkillsModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-xs-12 col-sm-6">
                <div class="form-group">
                    <label for="downloadFileFomart" class="control-label mb-3">
                        Select Download File Format :
                    </label>
                    <select #skillCategory="ngModel" name="downloadFileFormat" class="form-control"
                        [(ngModel)]="downloadSkillFormat">
                        <option value="xls">Excel
                        </option>
                        <option value="csv">CSV
                        </option>
                    </select>
                </div>
            </div>
            <div class="text-right col-xs-12">
                <div class="clearfix">
                    <button type="button" class="btn btn-danger mr-2" (click)="closeDownloadSkillsModal()">
                        <span>Cancel</span>
                    </button>
                    <button class="btn btn-success" (click)="downloadSkillFiles(downloadSkillFormat)">
                        Download
                    </button>
                </div>
            </div>
        </div>
    </div>
    
</ng-template>

<ng-template #skillActivitiesTemplate>
    <div class="ngx-modal-header">
        <h4 class="modal-title pull-left">
            Skills Activities
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="skillActivitiesModal.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-skills-entity-activities></app-skills-entity-activities>
    </div>
</ng-template>
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AngularModalService {

  constructor() { }

  addModalOpenClassToBodyIfAnyModalIsOpen() {
    // add back modal-open class to body to enable scrolling of the modal
    setTimeout(() => {
      $('body').addClass('modal-open');
    }, 500);
  }
}

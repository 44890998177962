import { Inject, Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { GenericService } from './generic.service';
import { StorageService } from './storage.service';
import { MESSAGECONSTANTS } from '../utils/constants';
import { UserRoleService } from './user-role.service';

import moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class UtilityService {


  UTILITYSERVICECONSTANTS: any = {
    SERVICE: {
      GET_METHOD: 'GET',
      POST_METHOD: 'POST',
      PUT_METHOD: 'PUT',
      DELETE_METHOD: 'DELETE'
    },
    COMPANY_MGMT: {
      GET_COMPANIES: 'api/company/getallcompanies'
    },
    USER_MGMT: {
      UPDATE_USER: 'api/user/updateuser',
      ASSIGN_MANAGER: 'api/user/assignmanager',
      GET_ENTITY_SCOPE_DETAILS: 'api/user/getuserentityscope',
      SET_ENTITY_SCOPE_DETAILS: 'api/user/setuserentityscope'
    },
    TEAM_MEMBERS: {
      GET_USER_DETAILS: 'api/user/getuserdetails'
    },
    CLIENT_OR_BU_MGMT: {
      GETCLIENT_OR_BU: 'api/company/getclientsorbu',
    },
    NOTIFICATION_PREFS_MGMT: {
      SET_NOTIFICATION_PREFS: 'api/notification/savenotificationprefs',
    },
    COMPANY_RES: {
      GET_RES: 'api/company/getroleentityscope',
    },
    FILTERS: {
      GET_FILTERS_DATA: 'api/filters/filterdata',
      GET_ROLEANDRECRITER_FROM_CLIENTS: 'api/filters/filterData/recruiterandrole/',
      GET_CLIENTANDROLE_FROM_RECRUITERS: 'api/filters/filterData/clientandrole/',
      GET_CLIENTANDRECRITER_FROM_ROLES: 'api/filters/filterData/clientandrecruiter/',
      GET_REQ_NUMBERS: 'api/requisitions/searchbynumber/'
    }
  }

  sponsorshipList: any = [{ "name": "CPT" }, { "name": "OPT" }, { "name": "H1" }, { "name": "B1" }, { "name": "L1" }, { "name": "H1 Transfer" }, { "name": "Permanent Resident/Green Card" }, { "name": "Citizen only" }, { "name": "Other" }];


  // this.assessmentsSyncMessageListener = this.$q.defer();
  assessmentsSyncMessageListener: Promise<any>;
  // this.toggleHeaderNavDisplayListener = this.$q.defer();
  toggleHeaderNavDisplayListener: Promise<any>;

  constructor(@Inject(AuthService) private authService: AuthService, @Inject(StorageService) private StorageService: StorageService,
    @Inject(GenericService) private genericService: GenericService, @Inject(UserRoleService) private userRoleService: UserRoleService) {

  }

  /*** This is a helper method written to change companies list object as needed by UI
    * @ called for checking 4dot5 user entity scope.
    * @ author Ashok*/

  companiesBasedOnType = (allcompanies) => {
    var companies: any = {};
    companies.staffingCompanies = [];
    companies.corporateCompanies = [];
    companies.hostCompanies = [];
    for (var i = 0; i < allcompanies.length; i++) {
      if (allcompanies[i].companyType === 'StaffingCompany') {
        companies.staffingCompanies.push(allcompanies[i]);
      } else if (allcompanies[i].companyType === 'Corporation') {
        companies.corporateCompanies.push(allcompanies[i]);
      } else if (allcompanies[i].companyType === 'Host') {
        companies.hostCompanies.push(allcompanies[i]);
      }
    }
    return companies;
  }

  /**
  * This is a helper method written to change client or BU list object as needed by UI
  * @ called when getting and setting of user entity scope to change api return object
  * @ author Ashok
  */
  changeUserScopeObject = (userscope) => {
    var ownscope = [];
    var forwardrecipientscope = [];
    // console.log('userscope : ' + angular.toJson(userscope));
    var scopeexists = angular.isDefined(userscope) && (userscope !== null) &&
      angular.isDefined(userscope.clientOrBUList) && (userscope.clientOrBUList !== null) && userscope.clientOrBUList.length;
    if (scopeexists) {
      for (var i = 0; i < userscope.clientOrBUList.length; i++) {
        if (userscope.clientOrBUList[i].clientOwner === 'USER') {
          ownscope.push(userscope.clientOrBUList[i]);
        } else if (userscope.clientOrBUList[i].clientOwner === 'FORWARDED_RECIPIENT') {
          forwardrecipientscope.push(userscope.clientOrBUList[i]);
        }
      }
      userscope.clientOrBUList = [];
      userscope.clientOrBUList[0] = ownscope;
      userscope.clientOrBUList[1] = forwardrecipientscope;
    }
    return userscope;
  }

  /**
  * This is a helper method written to change client or BU list object as needed by API
  * @ called when setting of user entity scope to change api return object
  * @ author Ashok
  */
  changeUserScopeObjectToSave = (userscope) => {
    var userscopearray = [];
    var scopeexiststosave = angular.isDefined(userscope) && (userscope !== null) &&
      angular.isDefined(userscope.clientOrBUList) && (userscope.clientOrBUList !== null) && userscope.clientOrBUList.length;
    if (scopeexiststosave) {
      if (userscope.clientOrBUList[0].length) {
        // console.log('userscope.clientOrBUList[0].length : ' + userscope.clientOrBUList[0].length);
        for (var i = 0; i < userscope.clientOrBUList[0].length; i++) {
          userscopearray.push(userscope.clientOrBUList[0][i]);
        }
      } else {
        delete userscope.clientOrBUList[0];
      }
      if (userscope.clientOrBUList.length > 1) {
        if (userscope.clientOrBUList[1].length) {
          // console.log('userscope.clientOrBUList[1].length : ' + userscope.clientOrBUList[1].length);
          for (var j = 0; j < userscope.clientOrBUList[1].length; j++) {
            userscopearray.push(userscope.clientOrBUList[1][j]);
          }
        } else {
          delete userscope.clientOrBUList[1];
        }
      }
      userscope.clientOrBUList = userscopearray;
    }
    return userscope;
  }

  /**
  * This is a helper method written to check null and empty conditions for forwardrecipient object.
  * @ author Ashok
  */
  forwardRecipientExistCheck = (userprofile) => {
    /**
     * Check for forward recipient obj exists in userprofile object
     */
    var exists = angular.isDefined(userprofile.userForwardRecipientDetails) && !angular.equals(userprofile.userForwardRecipientDetails, null) &&
      angular.isDefined(userprofile.userForwardRecipientDetails.forwardRecipient) && !angular.equals(userprofile.userForwardRecipientDetails.forwardRecipient, null) &&
      angular.isDefined(userprofile.userForwardRecipientDetails.forwardRecipient.firstName) && !angular.equals(userprofile.userForwardRecipientDetails.forwardRecipient.firstName, null) &&
      !angular.equals(userprofile.userForwardRecipientDetails.forwardRecipient.firstName, '') &&
      angular.isDefined(userprofile.userForwardRecipientDetails.fromDate) && !angular.equals(userprofile.userForwardRecipientDetails.fromDate, null);
    return exists;
  }

  /**
  * This is a helper method used to
  * convert userprofile object used in UI to user profile object needed to call saveuser api.
  */
  changeUserObjectBeforeApi = (userprofile) => {
    /**
     * change Manager (firstname = firstname + lastname) to (firstname = firstname) to save properly in db
     */
    // if (angular.isDefined(userprofile.userDetails.manager) && !angular.equals(userprofile.userDetails.manager, null) && !angular.equals(userprofile.userDetails.manager.lastName, null)) {
    //     userprofile.userDetails.manager.firstName = userprofile.userDetails.manager.firstName;
    // }

    /**
     * change forward recipients Normal date format to ISO string
     * which is accepted by severside api
     */
    if (this.forwardRecipientExistCheck(userprofile)) {
      var fromdate = new Date(userprofile.userForwardRecipientDetails.fromDate + ' ' + userprofile.userForwardRecipientDetails.fromTime).toISOString();
      userprofile.userForwardRecipientDetails.fromDate = fromdate;
      delete userprofile.userForwardRecipientDetails.fromTime;
      var todate = new Date(userprofile.userForwardRecipientDetails.toDate + ' ' + userprofile.userForwardRecipientDetails.toTime).toISOString();
      userprofile.userForwardRecipientDetails.toDate = todate;
      delete userprofile.userForwardRecipientDetails.toTime;
      delete userprofile.userForwardRecipientDetails.fullName;
    } else {
      userprofile.userForwardRecipientDetails.forwardRecipient = null;
      userprofile.userForwardRecipientDetails.fromDate = null;
      userprofile.userForwardRecipientDetails.toDate = null;
      if (angular.isDefined(userprofile.userForwardRecipientDetails.fromTime)) {
        delete userprofile.userForwardRecipientDetails.fromTime;
      }
      if (angular.isDefined(userprofile.userForwardRecipientDetails.toTime)) {
        delete userprofile.userForwardRecipientDetails.toTime;
      }
      if (angular.isDefined(userprofile.userForwardRecipientDetails.fullName)) {
        delete userprofile.userForwardRecipientDetails.fullName;
      }
    }

    if (this.userRoleService.isFourDotFiveAdmin(userprofile.userDetails.role) || this.userRoleService.isCompanyAdmin(userprofile.userDetails.role)) {
      userprofile.userScopeDetails = null;
    }

    userprofile.userScopeDetails = this.changeUserScopeObjectToSave(userprofile.userScopeDetails);

    return userprofile;
  };

  /**
  * This is a helper method used to
  * convert userprofile object got from api to user profile object used in UI.
  */
  changeUserObjectAfterApi = (data) => {
    /**
     * change Manager firstname to firstname + lastname to show on UI
     */
    if (angular.isDefined(data.userDetails) && !angular.equals(data.userDetails.manager, null) && !angular.equals(data.userDetails.manager.lastName, null)) {
      data.userDetails.manager.firstName = data.userDetails.manager.firstName + ' ' + data.userDetails.manager.lastName;
    }

    /**
     * change forward recipients date format to normal from ISO string to show on UI
     */
    if (this.forwardRecipientExistCheck(data)) {
      //console.log(data.userForwardRecipientDetails.fromDate);
      // data.userForwardRecipientDetails.fromTime = angular.copy(this.$filter('date')(data.userForwardRecipientDetails.fromDate, "HH:mm:ss"));
      data.userForwardRecipientDetails.fromTime = angular.copy(moment(data.userForwardRecipientDetails.fromDate).format("HH:mm:ss"));
      // data.userForwardRecipientDetails.toTime = angular.copy(this.$filter('date')(data.userForwardRecipientDetails.toDate, "HH:mm:ss"));
      data.userForwardRecipientDetails.toTime = angular.copy(moment(data.userForwardRecipientDetails.toDate).format("HH:mm:ss"));
      data.userForwardRecipientDetails.fromDate = moment(data.userForwardRecipientDetails.fromDate).format("MMM D, YYYY");
      data.userForwardRecipientDetails.toDate = moment(data.userForwardRecipientDetails.toDate).format("MMM D, YYYY");
      data.userForwardRecipientDetails.forwardRecipient.ticked = true;
      var toDate = new Date(data.userForwardRecipientDetails.toDate + ' ' + data.userForwardRecipientDetails.toTime);
      var isafter = moment(toDate).isAfter(new Date());
      if (isafter) {
        data.userForwardRecipientDetails.forwardRecipient.current = '(current)';
      } else {
        data.userForwardRecipientDetails.forwardRecipient.current = null;
      }

      var frfullname;
      if (data.userForwardRecipientDetails.forwardRecipient.lastName != null) {
        frfullname = data.userForwardRecipientDetails.forwardRecipient.firstName + " " + data.userForwardRecipientDetails.forwardRecipient.lastName;
      } else {
        frfullname = data.userForwardRecipientDetails.forwardRecipient.firstName;
      }
      data.userForwardRecipientDetails.forwardRecipient.fullName = frfullname;
    } else {
      data.userForwardRecipientDetails.forwardRecipient = null;
      data.userForwardRecipientDetails.fromDate = null;
      data.userForwardRecipientDetails.toDate = null;
    }

    /**
     * If loged in user changes his details.. Then updating in cookies and rootscope
     * for immediate change in header and other places.
     */
    if (data.userDetails.userId === this.authService.getUserDetails().id) {
      this.authService.getUserDetails().firstname = data.userDetails.firstName;
      this.authService.getUserDetails().lastname = data.userDetails.lastName;
      this.StorageService.set('userDetails', this.authService.getUserDetails(), null);
    }

    data.userScopeDetails = this.changeUserScopeObject(data.userScopeDetails);

    return data;
  };

  /**
  * This is Company Management Part of Utility Service
  */




  /**
  * This part of Utility service is used for Filters
  */

  getIDsfromArray = (objarray) => {
    // console.log('objarray : ' + angular.toJson(objarray));
    var idarray = [];
    for (var i = 0; i < objarray.length; i++) {
      idarray.push(objarray[i].id);
    }
    //console.log('idarray : ' + angular.toJson(idarray));
    return idarray;
  }

  getNamesfromArray = (objarray) => {
    // console.log('objarray : ' + angular.toJson(objarray));
    var namearray = [];
    for (var i = 0; i < objarray.length; i++) {
      namearray.push(objarray[i].name);
    }
    // console.log('namearray : ' + angular.toJson(namearray));
    return namearray;
  }

  handlefilterObjforClientorBUChanges = (data, filterObj) => {
    /**
     * Handling changes for recruiters
     */
    for (var i = 0; i < data.recruiters.length; i++) {
      for (var k = 0; k < filterObj.recruiters.length; k++) {
        if ((data.recruiters[i].id === filterObj.recruiters[k].id) && filterObj.recruiters[k].ticked) {
          data.recruiters[i].ticked = true;
          break;
        }
      }
    }

    /**
     * Handling changes for role titles
     */
    var obj: any = {};
    obj.roleList = [];
    if (filterObj.roleList.length > 0) {
      for (var i = 0; i < data.roleList.length; i++) {
        for (var k = 0; k < filterObj.roleList.length; k++) {
          obj.roleList[i] = {};
          obj.roleList[i].name = data.roleList[i];
          if ((data.roleList[i] === filterObj.roleList[k].name) && filterObj.roleList[k].ticked) {
            obj.roleList[i].ticked = true;
            break;
          }
        }
      }
      data.roleList = angular.copy(obj.roleList);
    } else {
      for (var i = 0; i < data.roleList.length; i++) {
        obj.roleList[i] = {};
        obj.roleList[i].name = data.roleList[i];
      }
      data.roleList = angular.copy(obj.roleList);
    }


    // console.log('data : ' + angular.toJson(data));
    return data;
  }

  handlefilterObjforRoleChanges = (data, filterObj) => {
    /**
     * Handling changes for recruiters
     */
    for (var i = 0; i < data.recruiters.length; i++) {
      for (var k = 0; k < filterObj.recruiters.length; k++) {
        if ((data.recruiters[i].id === filterObj.recruiters[k].id) && filterObj.recruiters[k].ticked) {
          data.recruiters[i].ticked = true;
          break;
        }
      }
    }

    /**
     * Handling changes for clients or bus
     */
    for (var i = 0; i < data.clientOrBU.length; i++) {
      for (var k = 0; k < filterObj.clientOrBU.length; k++) {
        if ((data.clientOrBU[i].id === filterObj.clientOrBU[k].id) && filterObj.clientOrBU[k].ticked) {
          data.clientOrBU[i].ticked = true;
          break;
        }
      }
    }


    return data;
  }

  handlefilterObjforRecruiterChanges = (data, filterObj) => {
    /**
     * Handling changes for clients or bus
     */
    for (var i = 0; i < data.clientOrBU.length; i++) {
      for (var k = 0; k < filterObj.clientOrBU.length; k++) {
        if ((data.clientOrBU[i].id === filterObj.clientOrBU[k].id) && filterObj.clientOrBU[k].ticked) {
          data.clientOrBU[i].ticked = true;
          break;
        }
      }
    }

    /**
     * Handling changes for role titles
     */
    var obj: any = {};
    obj.roleList = [];
    for (var i = 0; i < data.roleList.length; i++) {
      for (var k = 0; k < filterObj.roleList.length; k++) {
        obj.roleList[i] = {};
        obj.roleList[i].name = data.roleList[i];
        if ((data.roleList[i] === filterObj.roleList[k].name) && filterObj.roleList[k].ticked) {
          obj.roleList[i].ticked = true;
          break;
        }
      }
    }
    data.roleList = angular.copy(obj.roleList);
    // console.log('data : ' + angular.toJson(data));
    return data;
  }
  /* var changedFilterDataObject;*/
  changingFilterDataMethod = (changedFilterDataObject) => {
    var obj: any = {};
    obj.candidateStatus = [];
    obj.roleList = [];
    obj.requisitionStates = [];
    obj.requisitionNums = [];
    obj.workflowStates = [];
    for (var i = 0; i < changedFilterDataObject.candidateStatus.length; i++) {
      // console.log("entered ")
      obj.candidateStatus[i] = {};
      obj.candidateStatus[i].name = changedFilterDataObject.candidateStatus[i];
    }
    for (var i = 0; i < changedFilterDataObject.roleList.length; i++) {
      obj.roleList[i] = {};
      obj.roleList[i].name = changedFilterDataObject.roleList[i];
    }
    for (var i = 0; i < changedFilterDataObject.requisitionStates.length; i++) {
      obj.requisitionStates[i] = {};
      /* if((this.$rootScope.toState.name === 'missioncontrol.activecandidatesjobcardview' || this.$rootScope.toState.name === 'missioncontrol.passivecandidatesjobcardview') && changedFilterDataObject.requisitionStates[i] === "Draft"){
           continue;
       }else{
           obj.requisitionStates[i].name = changedFilterDataObject.requisitionStates[i];
       }*/
      obj.requisitionStates[i].name = changedFilterDataObject.requisitionStates[i];
    }
    for (var i = 0; i < changedFilterDataObject.requisitionNums.length; i++) {
      obj.requisitionNums[i] = {};
      obj.requisitionNums[i].id = changedFilterDataObject.requisitionNums[i];
    }
    for (var i = 0; i < changedFilterDataObject.workflowStates.length; i++) {
      obj.workflowStates[i] = {};
      obj.workflowStates[i].name = changedFilterDataObject.workflowStates[i];
    }
    changedFilterDataObject.candidateStatus = obj.candidateStatus;
    changedFilterDataObject.roleList = obj.roleList;
    changedFilterDataObject.requisitionStates = obj.requisitionStates;
    changedFilterDataObject.requisitionNums = obj.requisitionNums;
    changedFilterDataObject.workflowStates = obj.workflowStates;
    // console.log('changedFilterDataObject : ' + angular.toJson(changedFilterDataObject));
    return angular.copy(changedFilterDataObject);
  }
  /**
  * This service is used to get Filter Data
  */


  /**
  * Upload Requisition Part
  */

  getJobOpeningsArray = (requisitionObject) => {
    var jobOpeningsArray = [];
    /* console.log('get job openings array method--------------------------->'+angular.toJson(requisitionObject));*/
    if (requisitionObject.assignRecruiters.jobOpeningsAvailable === 'AllAvailable') {
      for (var i = 0; i < requisitionObject.assignRecruiters.availableRequisitions.length; i++) {
        var jobOpeningsObj = {
          jobOpeningId: null,
          openingNumber: 0,
          requisitionOpeningNumber: "",
          assignedRecruiter: null,
          fillCandidate: null,
          joinCandidate: null,
          assignedRecruiters: [],
          note: null
        };
        if (requisitionObject.assignRecruiters.jobOpenings[i] != null) {
          //console.log('job opening id: '+requisitionObject.assignRecruiters.jobOpenings[i].jobOpeningId);
          jobOpeningsObj.jobOpeningId = requisitionObject.assignRecruiters.jobOpenings[i].jobOpeningId;
        }
        jobOpeningsObj.openingNumber = i + 1;
        jobOpeningsObj.requisitionOpeningNumber = angular.copy(requisitionObject.assignRecruiters.availableRequisitions[i]);
        jobOpeningsObj.assignedRecruiters = angular.copy(requisitionObject.assignRecruiters.selectedrecruiters);
        jobOpeningsArray.push(angular.copy(jobOpeningsObj));
        /*jobOpeningsObj.assignedRecruiter = angular.copy(requisitionObject.assignRecruiters.jobOpenings.assignedRecruiter);
        jobOpeningsObj.fillCandidate = angular.copy(requisitionObject.assignRecruiters.jobOpenings.fillCandidate);
        jobOpeningsObj.joinCandidate = angular.copy(requisitionObject.assignRecruiters.jobOpenings.joinCandidate);
        jobOpeningsObj.note = angular.copy(requisitionObject.assignRecruiters.jobOpenings.note);*/
      }
    } else {
      for (var i = 0; i < requisitionObject.assignRecruiters.selectedRecReqObjects.length; i++) {
        for (var j = 0; j < requisitionObject.assignRecruiters.selectedRecReqObjects[i].requisitions.length; j++) {
          jobOpeningsObj = {
            jobOpeningId: null,
            openingNumber: 0,
            requisitionOpeningNumber: "",
            assignedRecruiter: null,
            fillCandidate: null,
            joinCandidate: null,
            assignedRecruiters: [],
            note: null
          }
          if (requisitionObject.assignRecruiters.jobOpenings[i] != null) {
            //console.log('job opening id: '+requisitionObject.assignRecruiters.jobOpenings[i].jobOpeningId);
            jobOpeningsObj.jobOpeningId = requisitionObject.assignRecruiters.jobOpenings[i].jobOpeningId;
          }
          jobOpeningsObj.openingNumber = parseInt(requisitionObject.assignRecruiters.selectedRecReqObjects[i].requisitions[j].split("-")[1]);
          jobOpeningsObj.requisitionOpeningNumber = angular.copy(requisitionObject.assignRecruiters.selectedRecReqObjects[i].requisitions[j])
          for (var k = 0; k < requisitionObject.assignRecruiters.selectedRecReqObjects[i].recruiters.length; k++) {
            jobOpeningsObj.assignedRecruiters.push(angular.copy(requisitionObject.assignRecruiters.selectedRecReqObjects[i].recruiters[k]));
          }
          jobOpeningsArray.push(angular.copy(jobOpeningsObj));
          /*jobOpeningsObj.assignedRecruiter = angular.copy(requisitionObject.assignedRecruiter.jobOpenings.assignedRecruiter);
          jobOpeningsObj.fillCandidate = angular.copy(requisitionObject.assignedRecruiter.jobOpenings.fillCandidate);
          jobOpeningsObj.joinCandidate = angular.copy(requisitionObject.assignedRecruiter.jobOpenings.joinCandidate);
          jobOpeningsObj.note = angular.copy(requisitionObject.assignedRecruiter.jobOpenings.note);*/
        }
      }
    }
    return jobOpeningsArray;
  };

  // getSponsorshipArray = (sponsorship) => {
  //   var sponsorshipArray = [];
  //   for (var i = 0; i < sponsorship.length; i++) {
  //     sponsorshipArray.push(sponsorship[i].name);
  //   }
  // };

  getWorkflowStepData = (requisitionObject) => {
    if (angular.isDefined(requisitionObject.licensePreferenceDetails) && angular.isDefined(requisitionObject.licensePreferenceDetails.fourDotFiveIntellList)) {
      delete requisitionObject.licensePreferenceDetails.fourDotFiveIntellList.sliderValue;
    }
    if (angular.isDefined(requisitionObject.licensePreferenceDetails) && angular.isDefined(requisitionObject.licensePreferenceDetails.techAssessmentList !== null)) {
      delete requisitionObject.licensePreferenceDetails.techAssessmentList.sliderValue;
    }
    return requisitionObject.licensePreferenceDetails;
  };

  changeReqObjBeforeApi = (requisitionObject) => {
    console.log("this.changeReqObjBeforeApi ", requisitionObject.currentJobState);
    var reqTempObj: any = {};

    reqTempObj = {
      id: angular.copy(requisitionObject.id),
      totalJobOpenings: angular.copy(requisitionObject.assignRecruiters.totalJobOpenings),
      title: angular.copy(requisitionObject.assignRecruiters.title),
      jobOpeningsAvailable: angular.copy(requisitionObject.assignRecruiters.jobOpeningsAvailable),
      requisitionNumber: angular.copy(requisitionObject.assignRecruiters.requisitionNumber),
      jobType: '',
      sponsorship: [],
      //     				sourceTypeDto : angular.copy(requisitionObject.uploadResumes.sourceTypeDto),
      clientorbu: angular.copy(requisitionObject.uploadReq.clientorbu),
      company: angular.copy(requisitionObject.uploadReq.company),
      jobProfile: angular.copy(requisitionObject.assignRecruiters.jobProfile),
      //licensePrefDtoList : this.getWorkflowStepData(angular.copy(requisitionObject)),
      jobOpenings: this.getJobOpeningsArray(requisitionObject),
      jobRequisitions: angular.copy(requisitionObject.jobRequisitions),
      techAssesmentSelect: angular.copy(requisitionObject.techAssesmentSelect),
      currentJobState: angular.copy(requisitionObject.currentJobState)
      //     				resumeList : []
    };
    console.log("reqTempObj " + reqTempObj);
    if (reqTempObj.clientorbu !== null) {
      if (angular.isDefined(reqTempObj.clientorbu.disabled)) {
        delete reqTempObj.clientorbu.disabled;
        delete reqTempObj.clientorbu.selected;
        delete reqTempObj.clientorbu.clientOwner;
      }
    }
    for (var i = 0; i < requisitionObject.assignRecruiters.sponsorship.length; i++) {
      reqTempObj.sponsorship.push(angular.copy(requisitionObject.assignRecruiters.sponsorship[i].name));
    }
    for (var i = 0; i < reqTempObj.jobOpenings.length; i++) {
      for (var j = 0; j < reqTempObj.jobOpenings[i].assignedRecruiters.length; j++) {
        delete reqTempObj.jobOpenings[i].assignedRecruiters[j].selected;
        delete reqTempObj.jobOpenings[i].assignedRecruiters[j].disabled;
      }
    }
    //	console.log("requisitionObject.techAssessmentMasterDto"+angular.toJson(requisitionObject.techAssesmentSelect));
    if (angular.isDefined(requisitionObject.techAssesmentSelect.assessmentName)) {
      reqTempObj.techAssessmentMasterDto = {
        id: null,
        assessmentName: angular.copy(requisitionObject.techAssesmentSelect.assessmentName),
        testURL: null,
        companyDto: angular.copy(requisitionObject.clientorbu)
      }
    } else {
      delete reqTempObj.techAssessmentMasterDto;
    }
    if (requisitionObject.assignRecruiters.jobType.length > 0) {
      reqTempObj.jobType = requisitionObject.assignRecruiters.jobType[0].name;
    } else {
      reqTempObj.jobType = '';
    }
    /* console.log('---------------------------------->'+angular.toJson(reqTempObj));*/
    return reqTempObj;
  };

  getAvailableRequisitions = (requisitionObject) => {
    var availableRequisitions = [];
    for (let i = 1; i <= requisitionObject.totalJobOpenings; i++) {
      //availableRequisitions.push(requisitionObject.requisitionNumber+'-' + i);
    }
    return availableRequisitions;
  };

  isAlreadyExists = (selectedrecruiters, recr) => {
    for (let i = 0; i < selectedrecruiters.length; i++) {
      if (selectedrecruiters[i].userId === recr.userId) {
        return true;
      }
    }
    return false;
  }

  getSelectedRecruiters = (requisitionObject) => {
    var selectedRecruiters = [];
    if (requisitionObject.jobOpenings) {
      for (let i = 0; i < requisitionObject.jobOpenings.length; i++) {
        for (var k = 0; k < requisitionObject.jobOpenings[i].assignedRecruiters.length; k++) {
          if (!this.isAlreadyExists(selectedRecruiters, requisitionObject.jobOpenings[i].assignedRecruiters[k])) {
            selectedRecruiters.push(requisitionObject.jobOpenings[i].assignedRecruiters[k]);
          }
        }
      }
    }
    return selectedRecruiters;
  };

  getSelectedRecReqObjects = (requisitionObject) => {
    var selectedRecReqObjects = [];
    var isExists = (recruiter) => {
      for (var k = 0; k < selectedRecReqObjects.length; k++) {
        for (var l = 0; l < selectedRecReqObjects[k].recruiters.length; l++) {
          if (recruiter.userId === selectedRecReqObjects[k].recruiters[l].userId) {
            return k;
          }
        }
      }
      return null;
    };
    var recReqObject: any = {};
    if (requisitionObject.jobOpenings) {
      for (var i = 0; i < requisitionObject.jobOpenings.length; i++) {
        recReqObject.requisitions = [];
        recReqObject.recruiters = [];
        for (var j = 0; j < requisitionObject.jobOpenings[i].assignedRecruiters.length; j++) {
          var indexExists = isExists(angular.copy(requisitionObject.jobOpenings[i].assignedRecruiters[j]));
          if (indexExists === null) {
            recReqObject.recruiters.push(requisitionObject.jobOpenings[i].assignedRecruiters[j]);
          } else {
            recReqObject.recruiters = [];
            selectedRecReqObjects[indexExists].requisitions.push(requisitionObject.jobOpenings[i].requisitionOpeningNumber);
            break;
          }
        }
        if (recReqObject.recruiters.length > 0) {
          recReqObject.requisitions.push(requisitionObject.jobOpenings[i].requisitionOpeningNumber);
          selectedRecReqObjects.push(angular.copy(recReqObject));
        }
      }
      //console.log("recReqObject.requisitions "+angular.toJson(recReqObject))
    }

    return selectedRecReqObjects;
  };

  getJobTypeObject = (requisitionObject) => {
    var jobTypeArray = [];
    var jobTypeObj: any = {};
    jobTypeObj.name = requisitionObject.jobType;
    jobTypeObj.selected = true;
    jobTypeArray.push(jobTypeObj);
    return jobTypeArray;
  };


  getSponsorshipArray = (requisitionObject) => {
    var sponsorshipArray = [];
    if (requisitionObject.sponsorships) {
      for (let s = 0; s < this.sponsorshipList.length; s++) {
        for (let t = 0; t < requisitionObject.sponsorships.length; t++) {
          var sponsorship: any = {};
          if (this.sponsorshipList[s].name == requisitionObject.sponsorships[t]) {
            sponsorship.name = requisitionObject.sponsorships[t];
            sponsorship.selected = true;
            sponsorshipArray.push(sponsorship);
          }
        }
      }
    }
    return sponsorshipArray;
  };

  changeReqObjAfterApi = (requisitionObject, oldRequisitionObject) => {
    var reqTempObj: any = {};
    reqTempObj = {
      id: angular.copy(requisitionObject.id),
      uploadReq: {},
      assignRecruiters: {},
      jobRequisitionList: angular.copy(requisitionObject.jobRequisitionList[0]),
      uploadResumes: {},
      viewResults: {},
      currentJobState: angular.copy(requisitionObject.currentJobState)
    };

    var clientorbu = requisitionObject.clientorbu;
    //? requisitionObject.clientCompany : requisitionObject.clientorbu;
    // var tempClientOrCompany = {};
    // tempClientOrCompany.aliasName = null;
    // tempClientOrCompany.companyId = clientorbu.id;
    // tempClientOrCompany.companyName = clientorbu.name;
    // tempClientOrCompany.companyState = clientorbu.companyState;
    // tempClientOrCompany.companyType = clientorbu.companyType;

    reqTempObj.uploadReq = {
      clientorbu: clientorbu,
      company: angular.copy(requisitionObject.company),
      uploadedFile: angular.copy(oldRequisitionObject.uploadReq.uploadedFile)
    };

    console.log('reqtempobj.uploadReq.clientorbu', reqTempObj.uploadReq.clientorbu);

    reqTempObj.assignRecruiters = {
      totalJobOpenings: angular.copy(requisitionObject.numberOfOpenings),
      title: angular.copy(requisitionObject.title),
      jobType: this.getJobTypeObject(angular.copy(requisitionObject)),
      jobOpeningsAvailable: angular.copy(requisitionObject.jobOpeningsAvailable),
      requisitionNumber: angular.copy(requisitionObject.requisitionNumber),
      sponsorship: this.getSponsorshipArray(angular.copy(requisitionObject)),
      jobProfile: angular.copy(requisitionObject.jobProfile),
      jobOpenings: requisitionObject.jobOpenings,
      availableRequisitions: [],
      selectedrecruiters: [],
      selectedRecReqObjects: [],
      isSponsorship: false
    };

    if (requisitionObject.sponsorships && requisitionObject.sponsorships.length > 0) {
      reqTempObj.assignRecruiters.isSponsorship = true;
    } else {
      reqTempObj.assignRecruiters.isSponsorship = false;
    }

    if (angular.isDefined(oldRequisitionObject) && angular.isDefined(oldRequisitionObject.assignRecruiters.availableRequisitions)) {
      if (oldRequisitionObject.assignRecruiters.availableRequisitions.length > 0) {
        reqTempObj.assignRecruiters.availableRequisitions = this.getAvailableRequisitions(requisitionObject);
        reqTempObj.assignRecruiters.selectedRecReqObjects = [];
      }
      if (oldRequisitionObject.assignRecruiters.selectedrecruiters.length > 0) {
        reqTempObj.assignRecruiters.selectedrecruiters = this.getSelectedRecruiters(requisitionObject);
      }
      if (oldRequisitionObject.assignRecruiters.selectedRecReqObjects.length > 0) {
        reqTempObj.assignRecruiters.selectedRecReqObjects = this.getSelectedRecReqObjects(requisitionObject);
        reqTempObj.assignRecruiters.availableRequisitions = [];
      }
    }
    reqTempObj.techAssesmentSelect = {};
    reqTempObj.licensePreferenceDetails = angular.copy(requisitionObject.licensePreferenceDetails);
    if (requisitionObject.techAssessmentMasterDto) {
      reqTempObj.techAssesmentSelect = {
        id: null,
        assessmentName: angular.copy(requisitionObject.techAssessmentMasterDto.assessmentName),
        testURL: null,
        companyDto: angular.copy(requisitionObject.clientorbu)
      }
    } else {
      reqTempObj.techAssesmentSelect = {};
    }
    console.log('reqTemp Obj -', reqTempObj);

    return reqTempObj;
  };

  /**
  * Update Requisition Part
  */

  changeUpdateReqObjAfterApi = (requisitionObject) => {
    var reqTempObj: any = {};

    reqTempObj = {
      id: angular.copy(requisitionObject.id),
      createdUserId: angular.copy(requisitionObject.createdUser),
      uploadReq: {},
      assignRecruiters: {},
      jobRequisitionList: angular.copy(requisitionObject.jobRequisitionList),
      //		 				uploadResumes: angular.copy(requisitionObject.resumeList),
      uploadResumes: {},
      viewResults: {},
      recruiterList: requisitionObject.recruiterList,
      canEdit: angular.copy(requisitionObject.canEdit),
      currentJobState: angular.copy(requisitionObject.currentJobState)
    };

    reqTempObj.uploadReq = {
      clientorbu: angular.copy(requisitionObject.clientorbu),
      company: angular.copy(requisitionObject.company),
      //		 				uploadedFile : angular.copy(requisitionObject.uploadReq.uploadedFile)
    };

    reqTempObj.assignRecruiters = {
      totalJobOpenings: angular.copy(requisitionObject.totalJobOpenings),
      title: angular.copy(requisitionObject.title),
      jobType: this.getJobTypeObject(angular.copy(requisitionObject)),
      jobOpeningsAvailable: angular.copy(requisitionObject.jobOpeningsAvailable),
      requisitionNumber: angular.copy(requisitionObject.requisitionNumber),
      sponsorship: this.getSponsorshipArray(angular.copy(requisitionObject)),
      jobProfile: angular.copy(requisitionObject.jobProfile),
      jobOpenings: requisitionObject.jobOpenings,
      availableRequisitions: this.getAvailableRequisitions(requisitionObject),
      selectedrecruiters: this.getSelectedRecruiters(requisitionObject),
      selectedRecReqObjects: this.getSelectedRecReqObjects(requisitionObject),
      isSponsorship: false
    };

    reqTempObj.techAssesmentSelect = {};
    reqTempObj.licensePreferenceDetails = angular.copy(requisitionObject.licensePreferenceDetails);

    if (requisitionObject.techAssessmentMasterDto !== null) {
      reqTempObj.techAssesmentSelect = {
        id: null,
        assessmentName: angular.copy(requisitionObject.techAssessmentMasterDto.assessmentName),
        testURL: null,
        companyDto: angular.copy(requisitionObject.clientorbu)
      }
    } else {
      reqTempObj.techAssesmentSelect = {};
    }

    reqTempObj.uploadResumes = {
      resumeSourceType: null,
      uploadedFiles: {}
      //uploadedFiles: reqTempObj.uploadResumes.uploadedFiles
    };

    if (requisitionObject.sponsorships && requisitionObject.sponsorships.length > 0) {
      reqTempObj.assignRecruiters.isSponsorship = true;
    } else {
      reqTempObj.assignRecruiters.isSponsorship = false;
    }
    return reqTempObj;
  };




  /**
     * This method is used to get all companies, to show in companies pop up
     */

  getCompaniesforUser(role, userid, page, size, sort, sortDir) {
    page = 0;
    size = 10000;
    sort = 'name';
    sortDir = 'asc';
    // let deferred = this.$q.defer();
    let deferred = new Promise((resolve, reject) => {
      this.genericService.getObjects(this.UTILITYSERVICECONSTANTS.COMPANY_MGMT.GET_COMPANIES + '/' + role.id + '/' + userid + '?page=' + page + '&size=' + size + '&sort=' + sort + '&sortDir=' + sortDir).subscribe((data) => {
        resolve(this.companiesBasedOnType(data.body))
      }), reject
    })
    // this.genericService.getObjects(this.UTILITYSERVICECONSTANTS.COMPANY_MGMT.GET_COMPANIES + '/' + role.id + '/' + userid + '?page=' + page + '&size=' + size + '&sort=' + sort + '&sortDir=' + sortDir).subscribe((data) => {
    //   deferred.resolve(this.companiesBasedOnType(data));
    // }, (error) => {
    //   deferred.reject(error);
    // });
    // return deferred.promise;

    return deferred;
  }

  /**
   * This is Team Members Part of Utility Service
   */

  /**
   * This will allow to add object to DB by calling the api using http POST method.
   */
  getUserProfile(user) {
    // var deferred = this.$q.defer();
    // this.genericService.getObjects(this.UTILITYSERVICECONSTANTS.TEAM_MEMBERS.GET_USER_DETAILS + '/' + user.userId).then((data) => {
    //   if (this.userRoleService.isFourDotFiveAdmin(data.userDetails.role)) {
    //     this.StorageService.set('4dot5Admin', true);
    //   } else {
    //     this.StorageService.remove('4dot5Admin');
    //   }
    //   deferred.resolve(this.changeUserObjectAfterApi(data));
    // }, (error) => {
    //   deferred.reject(error);
    // });
    // return deferred.promise;

    let deferred = new Promise((resolve, reject) => {
      this.genericService.getObjects(this.UTILITYSERVICECONSTANTS.TEAM_MEMBERS.GET_USER_DETAILS + '/' + user.userId).subscribe((data) => {
        if (this.userRoleService.isFourDotFiveAdmin(data.body.userDetails.role)) {
          this.StorageService.set('4dot5Admin', true, null);
        } else {
          this.StorageService.remove('4dot5Admin');
        }
        resolve(this.changeUserObjectAfterApi(data.body));
      }), reject
    })

    return deferred
  }

  /**
   * This is User Management Part of Utility Service
   */

  /**
   * This will allow to add object to DB by calling the api using http POST method.
   * @param url at which api is mapped
   * @param object to be added
   */
  saveUserProfileObject(url, userprofile) {
    // var deferred = this.$q.defer();
    // this.genericService.addObject(url, this.changeUserObjectBeforeApi(userprofile)).then((data) => {
    //   if (data.statusCode == "409") {
    //     deferred.reject(data);
    //   } else {
    //     deferred.resolve(this.changeUserObjectAfterApi(data));
    //   }
    // }, (error) => {
    //   deferred.reject(error);
    // });
    // return deferred.promise;

    let deferred = new Promise((resolve, reject) => {
      this.genericService.addObject(url, this.changeUserObjectBeforeApi(userprofile)).subscribe((data) => {
        if (data.status == 409 || data.body.statusCode == 409) {
          reject(data.body);
        } else {
          resolve(this.changeUserObjectAfterApi(data.body));
        }
      })
    })

    return deferred;
  }

  /**
   * This will allow to update object to DB by calling the api using http POST method.
   * @param url at which api is mapped
   * @param object to be added
   */
  updateUserProfileObject(userprofile) {
    // Function not used

    // var deferred = this.$q.defer();
    // this.genericService.addObject(USERMANAGEMENTCONSTANTS.CONTROLLER.UPDATE_USER, this.changeUserObjectBeforeApi(userprofile)).then((data) => {
    //   deferred.resolve(this.changeUserObjectAfterApi(data));
    // }, (error) => {
    //   deferred.reject(error);
    // });
    // return deferred.promise;
  }

  /**
   * This service is used to assign manager to user.
   * @param managerid tells which manager to be assigned to user
   * @param userid to which user manager to be assigned
   */
  assignManager(managerid, userid) {
    // var deferred = this.$q.defer();
    // this.genericService.addObject(this.UTILITYSERVICECONSTANTS.USER_MGMT.ASSIGN_MANAGER + '/' + managerid + '/' + userid).then((data) => {
    //   if (!angular.equals(data.userDetails.manager, null) && !angular.equals(data.userDetails.manager.lastName, null)) {
    //     data.userDetails.manager.firstName = data.userDetails.manager.firstName + ' ' + data.userDetails.manager.lastName;
    //   }
    //   deferred.resolve(data);
    // }, (error) => {
    //   deferred.reject(error);
    // });
    // return deferred.promise;

    let deferred = new Promise((resolve, reject) => {
      this.genericService.addObjectById(this.UTILITYSERVICECONSTANTS.USER_MGMT.ASSIGN_MANAGER + '/' + managerid + '/' + userid).subscribe((data) => {
        if (!angular.equals(data.body.userDetails.manager, null) && !angular.equals(data.body.userDetails.manager.lastName, null)) {
          data.body.userDetails.manager.firstName = data.body.userDetails.manager.firstName + ' ' + data.body.userDetails.manager.lastName;
        }
        resolve(data.body);
      }), reject
    })

    return deferred;

  }

  /**
   * This is used to get role entity scope details for user
   * @param userprofile to be added
   */
  getUserRoleEntityScope(userprofile) {
    // var deferred = this.$q.defer();
    // this.genericService.getObjects(this.UTILITYSERVICECONSTANTS.USER_MGMT.GET_ENTITY_SCOPE_DETAILS + '/' + userprofile.userDetails.userId + '/' + this.$rootScope.userDetails.company.companyId + '/' + userprofile.userDetails.role.id).then((data) => {
    //   // console.log('getUserRoleEntityScope : ' + angular.toJson(data));
    //   deferred.resolve(this.changeUserScopeObject(data));
    // }, (error) => {
    //   deferred.reject(error);
    // });
    // return deferred.promise;

    let deferred = new Promise((resolve, reject) => {
      this.genericService.getObjects(this.UTILITYSERVICECONSTANTS.USER_MGMT.GET_ENTITY_SCOPE_DETAILS + '/' + userprofile.userDetails.userId + '/' + this.authService.getUserDetails().company.companyId + '/' + userprofile.userDetails.role.id).subscribe((data) => {
        resolve(this.changeUserScopeObject(data.body));
      }), reject
    })

    return deferred;
  }

  /**
   * This is used to set role entity scope details for user
   * @param userprofile to be added
   */
  setUserRoleEntityScope(userprofile) {
    //  console.log('in service setUserRoleEntityScope');
    // var deferred = this.$q.defer();
    // this.genericService.addObject(this.UTILITYSERVICECONSTANTS.USER_MGMT.SET_ENTITY_SCOPE_DETAILS + '/' + userprofile.userDetails.userId + '/' + this.$rootScope.userDetails.company.companyId, this.changeUserScopeObjectToSave(userprofile.userScopeDetails)).then((data) => {
    //   deferred.resolve(this.changeUserObjectAfterApi(data));
    // }, (error) => {
    //   deferred.reject(error);
    // });
    // return deferred.promise;

    let deferred = new Promise((resolve, reject) => {
      this.genericService.addObject(this.UTILITYSERVICECONSTANTS.USER_MGMT.SET_ENTITY_SCOPE_DETAILS + '/' + userprofile.userDetails.userId + '/' + this.authService.getUserDetails().company.companyId, this.changeUserScopeObjectToSave(userprofile.userScopeDetails)).subscribe((data) => {
        resolve(this.changeUserObjectAfterApi(data.body));
      }), reject
    })

    return deferred;
  }

  /**
   * This is used to get client or BU List Related to Company
   */
  getClientsOrBU() {
    // var deferred = this.$q.defer();
    // this.genericService.getObjects(this.UTILITYSERVICECONSTANTS.CLIENT_OR_BU_MGMT.GETCLIENT_OR_BU).then((data) => {
    //   deferred.resolve(data);
    // }, (error) => {
    //   deferred.reject(error);
    // });
    // return deferred.promise;

    let deferred = new Promise((resolve, reject) => {
      this.genericService.getObjects(this.UTILITYSERVICECONSTANTS.CLIENT_OR_BU_MGMT.GETCLIENT_OR_BU).subscribe((data) => {
        resolve(data.body);
      }), reject
    })

    return deferred;
  }

  /**
   * This is used to set forward recipient details for user
   * @param userprofile to be added
   */
  setForwardRecipientDetails(url, userprofile) {
    // console.log('in service setUserRoleEntityScope');
    // var deferred = this.$q.defer();
    // this.genericService.addObject(url, this.changeUserObjectBeforeApi(userprofile)).then((data) => {
    //   deferred.resolve(this.changeUserObjectAfterApi(data));
    // }, (error) => {
    //   deferred.reject(error);
    // });
    // return deferred.promise;

    let deferred = new Promise((resolve, reject) => {
      this.genericService.addObject(url, this.changeUserObjectBeforeApi(userprofile)).subscribe((data) => {
        resolve(this.changeUserObjectAfterApi(data.body));
      }), reject
    })


  }

  /**
   * This method is used to set or update Notification preferences for particular user
   */
  saveNotificationPrefs(userid, companyid, notificationPrefsObj) {
    // var deferred = this.$q.defer();
    // this.genericService.addObject(this.UTILITYSERVICECONSTANTS.NOTIFICATION_PREFS_MGMT.SET_NOTIFICATION_PREFS + '/' + userid + '/' + companyid, notificationPrefsObj).then((data) => {
    //   deferred.resolve(data);
    // }, (error) => {
    //   deferred.reject(error);
    // });
    // return deferred.promise;

    let deferred = new Promise((resolve, reject) => {
      this.genericService.addObject(this.UTILITYSERVICECONSTANTS.NOTIFICATION_PREFS_MGMT.SET_NOTIFICATION_PREFS + '/' + userid + '/' + companyid, notificationPrefsObj).subscribe((data) => {
        resolve(data.body)
      }), reject
    })

    return deferred;
  }

  /**
   * This method is used to get or Role Entity Scope for Company
   */
  getCompanyRoleEntityScope() {
    // var deferred = this.$q.defer();
    // this.genericService.getObjects(this.UTILITYSERVICECONSTANTS.COMPANY_RES.GET_RES + '/' + this.$rootScope.userDetails.company.companyId).then((data) => {
    //   if (data.newScope) {
    //     for (var i = 0; i < data.scopeList.length; i++) {
    //       data.scopeList[i].allClientsOrBU = '';
    //       data.scopeList[i].clientOrBUList = '';
    //     }
    //   }
    //   deferred.resolve(data);
    // }, (error) => {
    //   deferred.reject(error);
    // });
    // return deferred.promise;

    let deferred = new Promise((resolve, reject) => {
      this.genericService.getObjects(this.UTILITYSERVICECONSTANTS.COMPANY_RES.GET_RES + '/' + this.authService.getUserDetails().company.companyId).subscribe((data) => {
        if (data.body && data.body.newScope) {
          for (let i = 0; i < data.body.scopeList.length; i++) {
            data.body.scopeList[i].allClientsOrBU = '';
            data.body.scopeList[i].clientOrBUList = '';
          }
        }
        resolve(data.body);
      }), reject
    })

    return deferred;

  }

  getFilterData() {
    //console.log(this.$rootScope.userDetails.company.companyId)
    // var deferred = this.$q.defer();
    // //console.log("State ",this.$rootScope.toState.name);
    // if (this.$rootScope.toState.name === 'missioncontrol.activecandidatesjobcardview') {
    //   this.genericService.getObjects(this.StorageService.get('baseurl') + this.UTILITYSERVICECONSTANTS.FILTERS.GET_FILTERS_DATA + '/' + this.$rootScope.userDetails.company.companyId + '/' + this.$rootScope.userDetails.id + '/active').then((data) => {
    //     deferred.resolve(this.changingFilterDataMethod(angular.copy(data)));
    //   }, (error) => {
    //     deferred.reject(error);
    //   });
    // } else if (this.$rootScope.toState.name === 'missioncontrol.passivecandidatesjobcardview') {
    //   this.genericService.getObjects(this.StorageService.get('baseurl') + this.UTILITYSERVICECONSTANTS.FILTERS.GET_FILTERS_DATA + '/' + this.$rootScope.userDetails.company.companyId + '/' + this.$rootScope.userDetails.id + '/passive').then((data) => {
    //     deferred.resolve(this.changingFilterDataMethod(angular.copy(data)));
    //   }, (error) => {
    //     deferred.reject(error);
    //   });
    // } else {
    //   this.genericService.getObjects(this.StorageService.get('baseurl') + this.UTILITYSERVICECONSTANTS.FILTERS.GET_FILTERS_DATA + '/' + this.$rootScope.userDetails.company.companyId + '/' + this.$rootScope.userDetails.id + '/other').then((data) => {
    //     deferred.resolve(this.changingFilterDataMethod(angular.copy(data)));
    //   }, (error) => {
    //     deferred.reject(error);
    //   });
    // }
    // return deferred.promise;


  }

  /**
   * This service is used to get Filter Data
   */
  getRoleandRecrforClient(selectedFilterObj, filterObj) {
    // var deferred = this.$q.defer();
    // this.genericService.addObject(this.StorageService.get('baseurl') + this.UTILITYSERVICECONSTANTS.FILTERS.GET_ROLEANDRECRITER_FROM_CLIENTS + this.$rootScope.userDetails.company.companyId + '/' + this.$rootScope.userDetails.id, this.getIDsfromArray(selectedFilterObj.clientOrBU)).then((data) => {
    //   //console.log('in utility: ' + angular.toJson(data));
    //   deferred.resolve(this.handlefilterObjforClientorBUChanges(angular.copy(data), filterObj));
    // }, (error) => {
    //   deferred.reject(error);
    // });
    // return deferred.promise;

    let deferred = new Promise((resolve, reject) => {
      this.genericService.addObject(this.StorageService.get('baseurl') + this.UTILITYSERVICECONSTANTS.FILTERS.GET_ROLEANDRECRITER_FROM_CLIENTS + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, this.getIDsfromArray(selectedFilterObj.clientOrBU)).subscribe((data) => {
        resolve(this.handlefilterObjforClientorBUChanges(angular.copy(data), filterObj));
      }), reject
    })

    return deferred;
  }

  /**
   * This service is used to get Filter Data
   */
  getRoleandRecrforBU(selectedFilterObj, filterObj) {
    // var deferred = this.$q.defer();
    // this.genericService.addObject(this.StorageService.get('baseurl') + this.UTILITYSERVICECONSTANTS.FILTERS.GET_ROLEANDRECRITER_FROM_CLIENTS + this.$rootScope.userDetails.company.companyId + '/' + this.$rootScope.userDetails.id, this.getIDsfromArray(selectedFilterObj.clientOrBU)).then((data) => {
    //   deferred.resolve(this.handlefilterObjforClientorBUChanges(angular.copy(data), filterObj));
    // }, (error) => {
    //   deferred.reject(error);
    // });
    // return deferred.promise;

    let deferred = new Promise((resolve, reject) => {
      this.genericService.addObject(this.StorageService.get('baseurl') + this.UTILITYSERVICECONSTANTS.FILTERS.GET_ROLEANDRECRITER_FROM_CLIENTS + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, this.getIDsfromArray(selectedFilterObj.clientOrBU)).subscribe((data) => {
        resolve(this.handlefilterObjforClientorBUChanges(angular.copy(data), filterObj));
      }), reject
    })

    return deferred;
  }

  /**
   * This service is used to get Filter Data
   */
  getClientandRoleforRecr(selectedFilterObj, filterObj) {
    // var deferred = this.$q.defer();
    // this.genericService.addObject(this.StorageService.get('baseurl') + this.UTILITYSERVICECONSTANTS.FILTERS.GET_CLIENTANDROLE_FROM_RECRUITERS + this.$rootScope.userDetails.company.companyId + '/' + this.$rootScope.userDetails.id, this.getIDsfromArray(selectedFilterObj.recruiters)).then((data) => {
    //   deferred.resolve(this.handlefilterObjforRecruiterChanges(angular.copy(data), filterObj));
    // }, (error) => {
    //   deferred.reject(error);
    // });
    // return deferred.promise;

    let deferred = new Promise((resolve, reject) => {
      this.genericService.addObject(this.StorageService.get('baseurl') + this.UTILITYSERVICECONSTANTS.FILTERS.GET_CLIENTANDROLE_FROM_RECRUITERS + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, this.getIDsfromArray(selectedFilterObj.recruiters)).subscribe((data) => {
        resolve(this.handlefilterObjforRecruiterChanges(angular.copy(data), filterObj));
      }), reject
    })

    return deferred;
  }

  /**
   * This service is used to get Filter Data
   */
  getClientandRecrforRole(selectedFilterObj, filterObj) {
    // var deferred = this.$q.defer();
    // this.genericService.addObject(this.StorageService.get('baseurl') + this.UTILITYSERVICECONSTANTS.FILTERS.GET_CLIENTANDRECRITER_FROM_ROLES + this.$rootScope.userDetails.company.companyId + '/' + this.$rootScope.userDetails.id, this.getNamesfromArray(selectedFilterObj.roleList)).then((data) => {
    //   deferred.resolve(this.handlefilterObjforRoleChanges(angular.copy(data), filterObj));
    // }, (error) => {
    //   deferred.reject(error);
    // });
    // return deferred.promise;

    let deferred = new Promise((resolve, reject) => {
      this.genericService.addObject(this.StorageService.get('baseurl') + this.UTILITYSERVICECONSTANTS.FILTERS.GET_CLIENTANDRECRITER_FROM_ROLES + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().id, this.getNamesfromArray(selectedFilterObj.roleList)).subscribe((data) => {
        resolve(this.handlefilterObjforRoleChanges(angular.copy(data), filterObj));
      }), reject
    })

    return deferred;
  }

  getrequisitionNumsAPI(recruiterNums, filterObj) {
    // var deferred = this.$q.defer();
    // this.genericService.getObjects(this.StorageService.get('baseurl') + this.UTILITYSERVICECONSTANTS.FILTERS.GET_REQ_NUMBERS + recruiterNums + '/' + this.$rootScope.userDetails.id + '/' + this.$rootScope.userDetails.company.companyId).then((data) => {
    //   var obj = {};
    //   obj.requisitionNums = [];
    //   for (var i = 0; i < data.length; i++) {
    //     obj.requisitionNums[i] = {};
    //     obj.requisitionNums[i].id = data[i];
    //   }
    //   deferred.resolve(obj);
    // }, (error) => {
    //   deferred.reject(error);
    // });
    // return deferred.promise;

    let deferred = new Promise((resolve, reject) => {
      this.genericService.getObjects(this.StorageService.get('baseurl') + this.UTILITYSERVICECONSTANTS.FILTERS.GET_REQ_NUMBERS + recruiterNums + '/' + this.authService.getUserDetails().id + '/' + this.authService.getUserDetails().company.companyId).subscribe((data) => {
        var obj:any = {};
        obj.requisitionNums = [];
        for (var i = 0; i < data.body.length; i++) {
          obj.requisitionNums[i] = {};
          obj.requisitionNums[i].id = data[i];
        }
        resolve(obj);
      }), reject
    })

    return deferred;
  }

  getRequisitionUIobject(requisitionObject, oldRequisitionObject) {
    return this.changeReqObjAfterApi(requisitionObject, oldRequisitionObject);
  }

  assignReqforRecs(requisitionObject) {
    // let deferred = this.$q.defer();
    // this.genericService.putObject(this.StorageService.get('baseurl') + 'api/requisition/jobOpeningsAndRecruiter', this.changeReqObjBeforeApi(requisitionObject)).then((data) => {
    //   //deferred.resolve(this.changeReqObjAfterApi(angular.copy(data),angular.copy(requisitionObject)));
    //   deferred.resolve(this.changeReqObjAfterApi(angular.copy(requisitionObject), angular.copy(requisitionObject)));
    // }, (error) => {
    //   deferred.reject(error);
    // });
    // return deferred.promise;

    let deferred = new Promise((resolve, reject) => {
      this.genericService.putObject(this.StorageService.get('baseurl') + 'api/requisition/jobOpeningsAndRecruiter', this.changeReqObjBeforeApi(requisitionObject)).subscribe((data) => {
        resolve(this.changeReqObjAfterApi(angular.copy(requisitionObject), angular.copy(requisitionObject)));
      }), reject
    })

    return deferred;
  }

  updateRequisitionClientName(requisitionObject) {
    // let deferred = this.$q.defer();
    // this.genericService.putObject(this.StorageService.get('baseurl') + 'api/requisitions/postrecruiter/' + requisitionObject.id, this.changeReqObjBeforeApi(requisitionObject)).then((data) => {
    //   deferred.resolve(this.changeReqObjAfterApi(angular.copy(data), angular.copy(requisitionObject)));
    // }, (error) => {
    //   deferred.reject(error);
    // });
    // return deferred.promise;

    let deferred = new Promise((resolve, reject) => {
      this.genericService.putObject(this.StorageService.get('baseurl') + 'api/requisitions/postrecruiter/' + requisitionObject.id, this.changeReqObjBeforeApi(requisitionObject)).subscribe((data) => {
        resolve(this.changeReqObjAfterApi(angular.copy(data.body), angular.copy(requisitionObject)));
      }), reject
    })

    return deferred;
  }

  saveWorkFlowSteps(requisitionObject) {
    console.log("saveWorkFlowSteps " + requisitionObject.currentJobState);
    // let deferred = this.$q.defer();
    // this.genericService.putObject(this.StorageService.get('baseurl') + 'api/requisition/setlicensepreferences/' + requisitionObject.id, this.changeReqObjBeforeApi(requisitionObject)).then((data) => {
    //   //deferred.resolve(this.changeReqObjAfterApi(angular.copy(data),angular.copy(requisitionObject)));
    //   deferred.resolve(angular.copy(requisitionObject));
    // }, (error) => {
    //   deferred.reject(error);
    // });
    // return deferred.promise;

    let deferred = new Promise((resolve, reject) => {
      this.genericService.putObject(this.StorageService.get('baseurl') + 'api/requisition/setlicensepreferences/' + requisitionObject.id, this.changeReqObjBeforeApi(requisitionObject)).subscribe((data) => {
        resolve(angular.copy(requisitionObject));
      }), reject
    })

    return deferred;
  }

  saveResumesforReq(requisitionObject) {
    console.log("saveResumesforReq " + requisitionObject.currentJobState);
    // let deferred = this.$q.defer();
    // this.genericService.putObject(this.StorageService.get('baseurl') + 'api/requisitions/setlicensepreferences/' + requisitionObject.id + '/' + this.$rootScope.userDetails.company.companyId, requisitionObject.workflowSteps).then((data) => {
    //   deferred.resolve(data);
    // }, (error) => {
    //   deferred.reject(error);
    // });
    // return deferred.promise;

    let deferred = new Promise((resolve, reject) => {
      this.genericService.putObject(this.StorageService.get('baseurl') + 'api/requisitions/setlicensepreferences/' + requisitionObject.id + '/' + this.authService.getUserDetails().company.companyId, requisitionObject.workflowSteps).subscribe((data) => {
        resolve(data.body)
      }), reject
    })

    return deferred;
  }

  removeResumesforReq(formData) {
    // let deferred = this.$q.defer();
    // this.genericService.uploadFile(this.StorageService.get('baseurl') + 'api/requisitions/removeresume', formData).then((data) => {
    //   deferred.resolve(data);
    // }, (error) => {
    //   deferred.reject(error);
    // });
    // return deferred.promise;

    let deferred = new Promise((resolve, reject) => {
      this.genericService.uploadFile(this.StorageService.get('baseurl') + 'api/requisitions/removeresume', formData).subscribe((data) => {
        resolve(data.body);
      }), reject
    })

    return deferred;
  }

  saveViewResultsData(requisitionObject) {
    // let deferred = this.$q.defer();
    // this.genericService.putObject(this.StorageService.get('baseurl') + 'api/requisitions/viewresult/' + requisitionObject.id, this.changeReqObjBeforeApi(requisitionObject)).then((data) => {
    //   //this.genericService.putObject(this.StorageService.get('baseurl') + 'api/requisitions/viewresult/' + requisitionObject.id + '?score=' + requisitionObject.viewResults.dafaultQualifyingMarkers.value).then(function (data) {
    //   //console.log('in saveViewResultsData api success : ' + angular.toJson(data));
    //   deferred.resolve(this.changeReqObjAfterApi(angular.copy(data), angular.copy(requisitionObject)));
    // }, (error) => {
    //   deferred.reject(error);
    // });
    // return deferred.promise;

    let deferred = new Promise((resolve, reject) => {
      this.genericService.putObject(this.StorageService.get('baseurl') + 'api/requisitions/viewresult/' + requisitionObject.id, this.changeReqObjBeforeApi(requisitionObject)).subscribe((data) => {
        resolve(this.changeReqObjAfterApi(angular.copy(data.body), angular.copy(requisitionObject)));
      }), reject
    })

    return deferred;
  }

  getUpdateRequisitionUIobject(requisitionObject) {
    return this.changeUpdateReqObjAfterApi(requisitionObject);
  }

  //common methods

  isContentinObjSame(wordOne, wordTwo) {
    return angular.isDefined(wordTwo) && (wordTwo !== null) && angular.equals(wordOne.toLowerCase(), wordTwo.toLowerCase());
  }

  handleLongName(longName, limitToMax) {
    let setLongName = longName;
    if (longName.length > limitToMax) {
      // longName = angular.copy(this.$filter('limitTo')(longName, (limitToMax - 3), 0).concat('...'));
      setLongName = angular.copy(longName.slice(0, (limitToMax - 3)).concat('...'));
    }
    return setLongName;
  }

  getFullName(firstName, lastName) {
    if (lastName != null) {
      return firstName + " " + lastName;
    } else {
      return firstName;
    }
  }

  isLoggedinUser(userDetails) {
    return angular.equals(userDetails.userId, this.authService.getUserDetails().id);
  }

  isInHost() {
    return angular.equals(this.authService.getUserDetails().company.companyType, MESSAGECONSTANTS.COMPANY_TYPES.FOURDOTFIVE);
  }

  //Assessments Sync message
  receiveAssessmentsSyncMessage() {
    // return this.assessmentsSyncMessageListener.promise;
    if (this.assessmentsSyncMessageListener == undefined || this.assessmentsSyncMessageListener == null) {
      return this.assessmentsSyncMessageListener = new Promise<any>((resolve, reject) => { });
    } else {
      return this.assessmentsSyncMessageListener;
    }
  }


  displayAssessmentsSyncMessage(htmlText) {
    // this.assessmentsSyncMessageListener.notify(htmlText);
    this.assessmentsSyncMessageListener = new Promise<any>((resolve, reject) => {
      return resolve(htmlText);
    })
  }

  receiveToggleHeaderNavDisplay() {
    if (this.toggleHeaderNavDisplayListener == undefined || this.toggleHeaderNavDisplayListener == null) {
      return this.toggleHeaderNavDisplayListener = new Promise<any>((resolve, reject) => { });
    } else {
      return this.toggleHeaderNavDisplayListener
    }
  }

  toggleHeaderNavDisplay(display) {
    // this.toggleHeaderNavDisplayListener.notify(display);
    this.toggleHeaderNavDisplayListener = new Promise<any>((resolve, reject) => {
      resolve(display);
    })
  }

  showLoadingModal(modalContent) {
    const loadingModalElement = document.getElementById('loading-modal');
    loadingModalElement.classList.remove('d-none');
    loadingModalElement.classList.add('d-flex');
    const loadingModalContentElement = document.getElementById('loading-modal-content');
    const loadingSpinnerIcon = ' <span class="fa fa-spinner fa-spin"></span>';
    const modalContentToDisplay = modalContent + loadingSpinnerIcon;
    loadingModalContentElement.innerHTML = modalContentToDisplay;
  }

  hideLoadingModal() {
    const loadingModalElement = document.getElementById('loading-modal');
    loadingModalElement.classList.remove('d-flex');
    loadingModalElement.classList.add('d-none');
  }
}

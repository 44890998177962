import { Component, OnInit, Inject, TemplateRef, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { GenericService } from 'src/app/core/services/generic.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { MESSAGECONSTANTS } from 'src/app/core/utils/constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Config } from 'datatables.net';
import { ModalConfig } from 'src/app/core/models/modalConfig';
import { AngularModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-client-or-bus-page',
  templateUrl: './client-or-bus-page.component.html',
  styleUrls: ['./client-or-bus-page.component.css']
})
export class ClientOrBusPageComponent implements OnInit {
  dtOptions: Config = {};
  dtTrigger: Subject<any> = new Subject();

  @ViewChild('deleteClientOrBuActionTemplate', null) deleteClientOrBuActionTemplateRef: TemplateRef<any>;
  deleteClientOrBuModal: BsModalRef;

  MESSAGECONSTANTS: any = MESSAGECONSTANTS;

  userRole: any;
  companyType: any;
  ClientOrBuList: any = [];
  clientOrBuListDetails: any;
  deleteClientOrBUObject: any;

  isAuthorizedToAddAndEdit: boolean = true;
  isLoading: boolean = true;
  isAddButtonEnabled: boolean = true;
  canAddClientOrBU: boolean = true;

  constructor(
    private authService: AuthService,
    private genericService: GenericService,
    private companyService: CompanyService,
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private storageService: StorageService,
    @Inject("$state") public $state: any,
    private bsModalService: BsModalService,
    private angularModalService: AngularModalService
  ) { }

  ngOnInit() {
    this.userRole = this.authService.getUserDetails().roleScreenRef.role.name;
    this.companyType = this.authService.getUserDetails().company.companyType;
    this._getClientsOrBus(() => {
      this._setDataTable();
    });

    if (this.userRole == 'Hiring Manager' || this.userRole == 'CorporateRecruitingManager' || this.userRole == 'StaffingRecruitingManager') {
      this.isAuthorizedToAddAndEdit = false;
    }

    if (this.authService.getUserDetails().company.companyState == 'SoftHold') {
      this.isAddButtonEnabled = false;
    }
  }

  _getClientsOrBus(successCallback) {
    this.isLoading = true;
    this.genericService.getObjects('api/company/getclientsorbu/' + this.authService.getUserDetails().company.companyId).subscribe((data) => {
      this.clientOrBuListDetails = { ...data.body };
      this.ClientOrBuList = [...this.clientOrBuListDetails.clientOrBus];
      this.canAddClientOrBU = !this.clientOrBuListDetails.createNotEnabled;
      this.isLoading = false;
      if (successCallback) {
        successCallback();
      }
    });
  }

  _setDataTable() {
    let buttonLabel = 'Add Client';
    let backGroundEmptyLabel = 'No Clients to display';
    if (this.companyType === 'Corporation') {
      buttonLabel = 'Add BU';
      backGroundEmptyLabel = 'No BUs to display';
    }

    if (this.canAddClientOrBU) {
      this.dtOptions = {
        pagingType: 'full_numbers',
        order: [[4, 'desc']],
        searching: true,
        lengthChange: false,
        info: true,
        language: { search: '', searchPlaceholder: 'Type to filter', zeroRecords: backGroundEmptyLabel },
        dom: "<'row'<'col-xs-12 text-right'Bf>><t>ip",
        buttons: [
          {
            text: buttonLabel,
            className: 'btn btn-primary datatablebutton',
            key: '2',
            action: () => {
              this.addClientOrBu();
            },
            enabled: this.isAddButtonEnabled
          }
        ],
        columnDefs: [
          { orderable: false, targets: [2, -1] }
        ]
      };
    } else {
      this.dtOptions = {
        pagingType: 'full_numbers',
        order: [[4, 'desc']],
        searching: true,
        lengthChange: false,
        info: true,
        language: { search: '', searchPlaceholder: 'Type to filter', zeroRecords: backGroundEmptyLabel },
        columnDefs: [
          { orderable: false, targets: [2, -1] }
        ]
      };
    }
  }

  deleteClientOrBU(clientOrBU) {
    this.getDeleteClientOrBUDetails(clientOrBU);
  }

  getDeleteClientOrBUDetails(clientOrBU) {
    this.companyService.getDeleteCompanyInfo(clientOrBU.company.id, (data) => {
      this.deleteClientOrBUObject = {
        clientOrBUId: clientOrBU.company.id,
        clientOrBUName: clientOrBU.company.name,
        requisitionsCount: data.numberOfRequisitions,
        usersCount: data.clientOrBUDeleteAffectedUsersCount,
        companyType: this.companyType
      }
      this.launchDeleteModal();
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  launchDeleteModal() {
    const config = new ModalConfig();
    const modalData: any = {};
    config.class = `modal-lg custom-ngx-modal`;
    config.initialState = modalData;
    config.ignoreBackdropClick = true;
    this.angularModalService.addModalOpenClassToBodyIfAnyModalIsOpen();
    this.deleteClientOrBuModal = this.bsModalService.show(this.deleteClientOrBuActionTemplateRef, config);
  }

  deleteClientOrBuFromList() {
    let deleteClientOrBuIndex;
    angular.forEach(this.ClientOrBuList, (value, key) => {
      if (value.company.id == this.deleteClientOrBUObject.clientOrBUId) {
        deleteClientOrBuIndex = key;
      }
    });
    this.ClientOrBuList.splice(deleteClientOrBuIndex, 1);
    this.deleteClientOrBuModal.hide();
  }

  updateClientOrBu(clientOrBu) {
    let isAssociatedClient = clientOrBu.company.companyType == 'Corporation' ? true : false;
    let accessMode = clientOrBu.nonEditable ? 'view' : 'edit';
    this.storageService.set('clientOrBuToBeUpdated', clientOrBu, null);
    this.$state.go('missioncontrol.companymanagement', { clientOrBuObject: clientOrBu, isAssociatedClient: isAssociatedClient, accessMode: accessMode }, { reload: true });
  }

  addClientOrBu() {
    this.storageService.set('clientOrBuCreationInProgress', true, null);
    this.$state.go('missioncontrol.companymanagement', null, { reload: true });
  }

  redirectToUrl(url, isReloadRequired) {
    this.$state.go(url, null, { reload: isReloadRequired });
  }

}

import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AngularModalService } from 'src/app/core/services/modal.service';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';

declare var $: any;

@Component({
  selector: 'app-requisition-entities-upload',
  templateUrl: './requisition-entities-upload.component.html',
  styleUrls: ['./requisition-entities-upload.component.css']
})
export class RequisitionEntitiesUploadComponent implements OnInit {

  modalData: any;
  public onClose: Subject<any>;

  uploadFileWizard: any;
  responseEntities: any;

  isFileUploaded: boolean = false;
  disableUploadButtonFlag: boolean = true;
  disableNextButtonFlag: boolean = true; // change
  fileExistsFlag: boolean = false;
  disablePreviousButtonFlag: boolean = true;

  constructor(
    public bsModalRef: BsModalRef,
    private modalService: AngularModalService,
    public bsModalService: BsModalService,
    private alertsAndNotificationsService: AlertAndNotificationsService
  ) {
    this.onClose = new Subject();
    if (this.bsModalService.config.initialState) {
      this.modalData = this.bsModalService.config.initialState;
    }
  }

  ngOnInit() {
    this.uploadFileWizard = this.createFileUploadWizard();
  }

  createFileUploadWizard() {
    let uploadFileWizard = $("#uploadFileWizard").wizard();

    $('#uploadFileWizard').on('actionclicked.fu.wizard', (evt, data) => {
      if (data.direction == "next") {
        switch (data.step) {
          case 1:
            // remove the remove button from upload dropzone the file.
            $('.dz-remove').remove();
            break;
          case 2:
            this.closeModal('close');
            break;
          default:
            break;
        }
      } else if (data.direction == "previous") {
        switch (data.step) {
          case 1:
            // do nothing
            break;
          case 2:
            this.updateActionButtons();
            break;
          default:
            break;
        }
      }
    });

    $('#uploadFileWizard').on('finished.fu.wizard', (evt, data) => {
      this.closeModal('close');
    });

    return uploadFileWizard;
  }

  updateActionButtons() {
    let wizardCurrentStep = $('#uploadFileWizard').wizard('selectedItem').step;
    switch (wizardCurrentStep) {
      case 1:
        // if resumes exist and upload completed, then the functions on this tab are disabled.
        if (this.isFileUploaded) {
          this.disableUploadButtonFlag = true;
          this.disableNextButtonFlag = false;
        } else {
          this.disableNextButtonFlag = true;
        }
        break;
      case 2:
        break;
      default:
        break;
    }
  }

  closeModal(data) {
    this.bsModalRef.hide();
  }

  saveCallback(event) {
    this.alertsAndNotificationsService.showBannerMessage(`${this.modalData.title} List uploaded successfully`, 'success');
    this.isFileUploaded = true;
    this.updateActionButtons();
    this.responseEntities = event.entityActivities[0];
    this.onClose.next('success');
  }

  cancelCallback() {
    this.closeModal(null);
  }

  wizardCancel() {
    this.cancelCallback();
  }

}

<div class="row">
    <div class="col-xs-12">
        <div class="main-box">
            <div class="main-box-header" [hidden]="isPopup">
                <h2 class="emerald" [hidden]="loadingEmployeeFlag">
                    <strong><span *ngIf="employee.id == null">Create</span><span *ngIf="employee.id != null">Edit</span>
                        Employee</strong>
                </h2>
            </div>
            <div class="main-box-body" style="padding-top: 20px;">
                <header class="main-box-header clearfix col-lg-6 col-lg-offset-2"
                    *ngIf="employeeDetailsForm.submitted && employeeDetailsForm.invalid && !isPopup">
                    <h2 class="has-error">
                        <span class="help-block"> <em>*</em>
                            {{MESSAGECONSTANTS.COMMONVALIDATIONS.ENTER_MANDATORY_FIELDS}}</span>
                    </h2>
                </header>
                <div class="row" [hidden]="loadingEmployeeFlag">
                    <div class="col-lg-12">
                        <form #employeeDetailsForm="ngForm" name="addUpdateContactForm" novalidate>
                            <div
                                [ngClass]="{'col-lg-6 col-md-6 col-sm-7 col-lg-offset-1 col-md-offset-1 col-sm-offset-1': !isPopup, 'col-xs-12': isPopup}">

                                <div class="form-group" [ngClass]="{'col-xs-6': isPopup}">
                                    <label style="padding-right: 10px" for="buSearch">
                                        Belongs to <em *ngIf="isCorporateFlag && !canAddUserAtCompanyLevel">*</em> <span
                                            *ngIf="!isCorporateFlag">Department</span> :
                                    </label>
                                    <tag-input *ngIf="!isCorporateFlag" class="add-employee-belongs-to"
                                        #departmentSearch name="departmentSearch" [identifyBy]="'id'"
                                        [displayBy]="'name'" [(ngModel)]="selectedDepartmentObjectsArray"
                                        [onlyFromAutocomplete]="true"
                                        [placeholder]="'Search for department or add new department or leave empty.'"
                                        [secondaryPlaceholder]="'Search for department or add new department or leave empty.'"
                                        [maxItems]="1"
                                        (onAdd)="departmentTypeAheadDepartmentAdded($event)"
                                        (onRemove)="departmentTypeAheadDepartmentRemoved($event)">
                                        <ng-template let-item="item" let-index="index">
                                            <span class="px-3 d-block d-flex">
                                                <span class="pr-3 d-block">{{ item.name.length >= 40 ? (item.name.substring(0, 40) + "...") : item.name }}</span>
                                                <delete-icon style="width: 12px; height: 12px;"
                                                    (click)="departmentSearch.removeItem(item, index)">
                                                </delete-icon>
                                            </span>
                                        </ng-template>
                                        <tag-input-dropdown id="departmentSearch" name="departmentSearch"
                                            [identifyBy]="'id'" [displayBy]="'name'" [showDropdownIfEmpty]="true"
                                            [autocompleteObservable]='getDepartments' [limitItemsTo]="1000">
                                            <ng-template let-item="item" let-index="index">
                                                {{ item.name }}
                                            </ng-template>
                                        </tag-input-dropdown>
                                    </tag-input>
                                    <select *ngIf="isCorporateFlag" [(ngModel)]="employee.buId"
                                        [required]="!canAddUserAtCompanyLevel" name="buSearch" id="buSearch"
                                        class="form-control access">
                                        <option *ngIf="canAddUserAtCompanyLevel" value="">Corporate</option>
                                        <option *ngFor="let bu of buList; let i = index" [value]="bu.id">{{bu.name}}
                                        </option>
                                    </select>
                                </div>

                                <div class="form-group" [ngClass]="{'col-xs-6': isPopup}">
                                    <label for="organizationalManager" class="control-label">
                                        Organisational Manager :
                                    </label>
                                    <tag-input #organizationalManager name="organizationalManager"
                                        class="make-application-user" [identifyBy]="'id'" [displayBy]="'name'"
                                        [(ngModel)]="selectedOrganizationalManagerObjectsArray"
                                        [placeholder]="'Type name to search for user.'"
                                        [secondaryPlaceholder]="'Type name to search for user.'" [maxItems]="1"
                                        [onlyFromAutocomplete]="true"
                                        (onAdd)="organizationalManagerTypeAheadManagerAdded($event)"
                                        (onRemove)="organizationalManagerTypeAheadManagerRemoved($event)">
                                        <ng-template let-item="item" let-index="index">
                                            <span class="px-3 d-block d-flex">
                                                <span class="pr-3 d-block">{{ item.name.length >= 45 ? (item.name.substring(0, 45) + "...") : item.name }}</span>
                                                <delete-icon style="width: 12px; height: 12px;"
                                                    (click)="organizationalManager.removeItem(item, index)">
                                                </delete-icon>
                                            </span>
                                        </ng-template>
                                        <tag-input-dropdown [identifyBy]="'id'" [displayBy]="'name'"
                                            [showDropdownIfEmpty]="true"
                                            [autocompleteObservable]='getOrganizationalManagers' [limitItemsTo]="1000">
                                            <ng-template let-item="item" let-index="index">
                                                {{ item.name }}
                                            </ng-template>
                                        </tag-input-dropdown>
                                    </tag-input>
                                </div>

                                <div class="form-group"
                                    [ngClass]="{'has-error':(employeeDetailsForm.submitted && firstName.invalid), 'col-xs-6': isPopup}">
                                    <label for="firstName" class="control-label">
                                        {{MESSAGECONSTANTS.LABLES.FIRST_NAME}}<em>* </em> :
                                    </label>
                                    <input #firstName="ngModel" type="text" class="form-control access" name="firstName"
                                        [(ngModel)]="employee.firstName"
                                        [pattern]="MESSAGECONSTANTS.VALIDATIONPATTERNS.NAME"
                                        [ngModelOptions]="{allowInvalid: true}" maxlength="100" required>
                                    <div style="color:red;" *ngIf="!lastName.focused && firstName.errors?.pattern">
                                        <small class="error">{{MESSAGECONSTANTS.COMMONVALIDATIONS.FIRST_NAME}}</small>
                                    </div>
                                    <div style="color:red;" *ngIf="!lastName.focused && firstName.errors?.maxlength">
                                        <small class="error">First name can have maximum 100 characters.</small>
                                    </div>
                                </div>

                                <div class="form-group"
                                    [ngClass]="{'has-error':(employeeDetailsForm.submitted && lastName.invalid), 'col-xs-6': isPopup}">
                                    <label for="lastName" class="control-label">
                                        {{MESSAGECONSTANTS.LABLES.LAST_NAME}}<em>* </em> :
                                    </label>
                                    <input #lastName="ngModel" type="text" class="form-control access" id="lastName"
                                        name="lastName" [(ngModel)]="employee.lastName"
                                        [pattern]="MESSAGECONSTANTS.VALIDATIONPATTERNS.NAME"
                                        [ngModelOptions]="{ allowInvalid: true }" [required]="true" maxlength="100">
                                    <div style="color:red;" *ngIf="!lastName.focused && lastName.errors?.pattern">
                                        <small class="error">{{MESSAGECONSTANTS.COMMONVALIDATIONS.LAST_NAME}}</small>
                                    </div>
                                    <div style="color:red;" *ngIf="!lastName.focused && lastName.errors?.maxlength">
                                        <small class="error">Last name can have maximum 100 characters.</small>
                                    </div>
                                </div>

                                <div class="form-group"
                                    [ngClass]="{'has-error':(employeeDetailsForm.submitted && title.invalid), 'col-xs-6': isPopup}">
                                    <label for="lastName" class="control-label">
                                        Title :
                                    </label>
                                    <input #title="ngModel" type="text" class="form-control access" id="title"
                                        name="title" [(ngModel)]="employee.title" (focus)="title.focused = true"
                                        (blur)="title.focused = false" maxlength="100">
                                    <div style="color:red;" *ngIf="!title.focused && title.errors?.maxlength">
                                        <small class="error">Title can have maximum 100 characters.</small>
                                    </div>
                                </div>

                                <div class="form-group"
                                    [ngClass]="{'has-error':(employeeDetailsForm.submitted && isPhoneNumberInvalid), 'col-xs-6 mb-0': isPopup}">
                                    <label for="phoneNumber" class="control-label">
                                        {{MESSAGECONSTANTS.LABLES.MOBILE_PHONE}} :
                                    </label>
                                    <angular-four-dot-five-phone-number [elementId]="'phoneNumber'"
                                        [valueField]="employee.phoneNumber"
                                        (valueFieldChange)="employee.phoneNumber=$event"
                                        (isPhoneNumberInvalid)="isPhoneNumberInvalid=$event"
                                        (phoneNumberFormat)="phoneNumberFormat=$event">
                                    </angular-four-dot-five-phone-number>
                                    <small class="error" *ngIf="isPhoneNumberInvalid">Please enter a valid phone
                                        number.</small>
                                    <span class="help-block" *ngIf="!isPhoneNumberInvalid">ex.
                                        <span>{{phoneNumberFormat}}</span></span>
                                </div>

                                <div class="form-group"
                                    [ngClass]="{'has-error':(employeeDetailsForm.submitted && email.invalid), 'col-xs-6': isPopup}">
                                    <label for="email" class="control-label">{{MESSAGECONSTANTS.LABLES.EMAIL}}<em>*
                                        </em> :</label>
                                    <input #email="ngModel" type="text" class="form-control access" id="email"
                                        name="email" placeholder="example@example.com" [(ngModel)]="employee.email"
                                        [ngModelOptions]="{ allowInvalid: true }"
                                        [pattern]="MESSAGECONSTANTS.VALIDATIONPATTERNS.EMAIL"
                                        [disabled]="mode === 'edit'" required>
                                    <span class="help-block">ex. example@example.com</span>
                                    <div style="color:red;"
                                        *ngIf="!email.focused && email.invalid && email.errors?.pattern">
                                        <small
                                            class="error">{{MESSAGECONSTANTS.COMMONVALIDATIONS.NOT_VALID_EMAIL}}</small>
                                    </div>
                                </div>

                                <div class="col-xs-12 text-right">
                                    <div class="clearfix">
                                        <button type="button" class="btn btn-danger mr-2"
                                            (click)="cancelEmployeeAddEdit()" [disabled]="savingFlag">
                                            <span>Cancel</span>
                                        </button>
                                        <button type="submit" class="btn btn-success" (click)="saveEmployeeDetails()"
                                            [disabled]="savingFlag">
                                            <span *ngIf="!savingFlag">Save</span>
                                            <span *ngIf="savingFlag">Saving..</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row" *ngIf="loadingEmployeeFlag">
                    <div class="col-xs-12 loading-spinner text-center" style="padding-top: 100px; padding-bottom:300px">
                        <span>Loading</span>
                        <span class="fa fa-spinner fa-spin"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-lg-12">
        <div id="content-header" class="clearfix">
            <div class="pull-left">
                <ol class="breadcrumb">
                    <li><a (click)="redirectToUrl('missioncontrol.dashboard', true)">Home</a></li>
                    <li class="active">Employee Management</li>
                </ol>
                <h1 class="heading-css">
                    <span *ngIf="!employeeId">Employee Management</span>
                    <span *ngIf="employeeId">Edit Employee '{{employeeName}}'</span>
                </h1>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-xs-12">
        <div *ngIf="!employeeId">
            <app-add-edit-employee></app-add-edit-employee>
        </div>
        <div *ngIf="employeeId">
            <app-add-edit-employee [employeeId]="employeeId"></app-add-edit-employee>
        </div>
    </div>
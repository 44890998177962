<div class="approw">
    <div class="row">
        <div class="col-lg-12">
            <ol class="breadcrumb">
                <li><a (click)="redirectToUrl('missioncontrol.dashboard', true)">Home</a></li>
                <li *ngIf="companyType === 'StaffingCompany'" class="active">
                    <span>{{MESSAGECONSTANTS.BREADCRUMBS.CLIENT_MANAGEMENT}}</span></li>
                <li *ngIf="companyType === 'Corporation'" class="active">
                    <span>{{MESSAGECONSTANTS.BREADCRUMBS.BU_MANAGEMENT}}</span></li>
            </ol>
            <h1 class="heading-css" *ngIf="companyType === 'StaffingCompany'">
                {{MESSAGECONSTANTS.BREADCRUMBS.CLIENT_MANAGEMENT}}</h1>
            <h1 class="heading-css" *ngIf="companyType === 'Corporation'">{{MESSAGECONSTANTS.BREADCRUMBS.BU_MANAGEMENT}}
            </h1>
        </div>
    </div>
    <div class="col-lg-12 col-sm-12 col-md-12">
        <div class="main-box clearfix">
            <div class="main-box-body clearfix table-responsive client-or-bu-table" *ngIf="!isLoading">
                <table id="clienttable" datatable [dtOptions]="dtOptions" class="table table-hover">
                    <thead>
                        <tr>
                            <th *ngIf="companyType === 'StaffingCompany'" class="headerStyling">Client Name</th>
                            <th *ngIf="companyType === 'Corporation'" class="headerStyling">BU Name</th>
                            <th class="headerStyling">Contact Name</th>
                            <th class="headerStyling">Phone</th>
                            <th class="headerStyling">Email</th>
                            <th class="headerStyling">Creation Date</th>
                            <th class="headerStyling">Updated By</th>
                            <th class="headerStyling">Updated Date</th>
                            <th class="text-center actions headerStyling">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let client of ClientOrBuList;let $index = index"
                            [ngClass]="{'associated-client-bg': client.company.companyType == 'Corporation'}">
                            <td>
                                <span>{{client.company.name}}</span>
                                <span *ngIf="client.companyState != 'Active'">({{client.companyState}})</span>
                            </td>
                            <td>
                                {{client.contactList[0].firstName}} {{client.contactList[0].lastName}}
                            </td>
                            <td>
                                <span *ngIf="client.contactList[0].workPhone != null">{{client.contactList[0].workPhone}}&nbsp;(w)</span><br>
                                <span *ngIf="client.contactList[0].workPhone != null">{{client.contactList[0].mobilePhone}}&nbsp;(m)</span>
                            </td>
                            <td>
                                <a href="mailto:{{client.contactList[0].email}}?Subject=" target="_top">{{client.contactList[0].email}}</a>
                            </td>
                            <td>
                                {{client.auditDto.createdOn | amFromUtc | amLocal | amDateFormat: 'MMM D, YYYY h:mm a'}}
                            </td>
                            <td>
                                {{client.auditDto.lastModifiedBy}}
                            </td>
                            <td>
                                {{client.auditDto.modifiedOn | amFromUtc | amLocal | amDateFormat: 'MMM D, YYYY h:mm a'}}
                            </td>
                            <td class="text-center">
                                <a (click)="updateClientOrBu(client)"
                                    [ngClass]="{'single-action': (client.company.companyType == 'Corporation' || !isAuthorizedToAddAndEdit)}"
                                    class="table-link primary cursor-pointer">
                                    <span class="fa-stack">
                                        <i class="fa fa-square fa-stack-2x"></i>
                                        <i class="fa-stack-1x fa-inverse"
                                            [ngClass]="{'far fa-eye': client.nonEditable, 'fas fa-pencil-alt': !client.nonEditable}"></i>
                                    </span>
                                </a>
                                <a style="margin : 0px;" [ngClass]="{'disabled-client-or-bu-action': client.nonEditable}"
                                    (click)="!client.nonEditable && deleteClientOrBU(client)"
                                    [hidden]="client.company.companyType == 'Corporation' || !isAuthorizedToAddAndEdit"
                                    class="table-link danger cursor-pointer">
                                    <span class="fa-stack">
                                        <i class="fa fa-square fa-stack-2x"></i>
                                        <i class="far fa-trash-alt fa-stack-1x fa-inverse"></i>
                                    </span>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="isLoading" style="height: 500px; font-size: 20px;" 
                class="d-flex justify-content-center align-items-center text-center">
                <span class="mr-2">Loading</span>
                <span class="fa fa-spinner fa-spin"></span>
            </div>
        </div>
    </div>
</div>

<ng-template #deleteClientOrBuActionTemplate>
    <div class="ngx-modal-header">
        <h4 class="modal-title pull-left">
            <span *ngIf="companyType === 'StaffingCompany'">Delete Client</span>
            <span *ngIf="companyType === 'Corporation'">Delete BU</span>
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="deleteClientOrBuModal.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="min-height: 300px;">
        <app-delete-entity entity="clientOrBU" [clientOrBuInfo]="deleteClientOrBUObject"
            (cancelCallback)="deleteClientOrBuModal.hide()" (deleteCallback)="deleteClientOrBuFromList()">
        </app-delete-entity>
    </div>
</ng-template>
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { 

  }

  isContactDetailsAvailable(candidate, context) {
    let candidateDetailsAvailable = true;
    let zipCode = null;
    let mobilePhone = null;

    if(context === 'candidateCard') {
      zipCode = candidate.address ? candidate.address.zipCode : null;
      mobilePhone = candidate.mobilePhone;
    } else {
      zipCode = candidate.address ? candidate.address.zipcode : null;
      mobilePhone = candidate.mobilephone;
    }

    if ((!candidate.email || candidate.email === '-')
      || (!mobilePhone || mobilePhone === '-')
      || !zipCode) {
      candidateDetailsAvailable = false;
    }
    return candidateDetailsAvailable;
  }

  isCandidateAdditionalDetailsAvailable(candidate) {
    let candidateAdditionalDetailsAvailable = true;

    if (!candidate.candidateAdditionalDetails) {
      candidateAdditionalDetailsAvailable = false;
    } else if ((
      (!candidate.candidateAdditionalDetails.preferredJobTypeDetails || candidate.candidateAdditionalDetails.preferredJobTypeDetails.jobType.length === 0)
      || (!candidate.candidateAdditionalDetails.sponsorships || candidate.candidateAdditionalDetails.sponsorships.length === 0)
      || (!candidate.candidateAdditionalDetails.workLocations || candidate.candidateAdditionalDetails.workLocations.length === 0)
      )) {
      candidateAdditionalDetailsAvailable = false;
    }

    return candidateAdditionalDetailsAvailable;
  }
}

<div class="container main-box py-4">
    <form #addEditNewSkillForm="ngForm" novalidate>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-12 px-0">
                    <div class="col-xs-12 col-md-6"
                        [ngClass]="{'has-error mb-0': (addEditNewSkillForm.submitted && skillName.invalid), 
                            'pointer-events-none opacity-half': isDisabled}">
                        <label for="skillName" class="control-label mb-3">
                            Skill Name<em>*</em> :
                        </label>
                        <input #skillName="ngModel" type="text" name="skillName" class="form-control"
                            [(ngModel)]="addEditNewSkillObject.skill" required>
                        <small class="error" *ngIf="(addEditNewSkillForm.submitted && skillName.invalid)">
                            Skill name is mandatory
                        </small>
                    </div>
                    <!-- Parent Skill -->
                    <div class="form-group col-xs-12 col-md-6" [ngClass]="{'pointer-events-none opacity-half': addEditNewSkillObject.alternate}">
                        <label for="parentSkills" class="control-label">
                            Parent Skills :
                            <span *ngIf="addEditNewSkillObject.isParentSkillDuplicate" class="pl-1 duplicate-skill duplicate-skill-message" [innerHTML]="addEditNewSkillObject.duplicateParentSkillMessage"></span>
                        </label>
                        <tag-input #parentInput #parentSkills="ngModel" name="parentSkills"
                            [(ngModel)]="addEditNewSkillObject.parentSkills" [onAdding]="onAddingParentSkill"
                            (onTextChange)="onParentSkillTextChange($event)" [placeholder]="'Enter Parent Skill'"
                            [secondaryPlaceholder]="'Enter Parent Skill'" [maxItems]="1"
                            (onRemove)="onParentSkillRemoved($event)"
                            (onAdd)="onParentSkillAdded($event)"
                            (keyup.enter)="addSkillOnEnter($event, 'parentSkillModal', parentInput)" 
                            (keydown.Tab)="addSkillOnEnter($event, 'parentSkillModal', parentInput)">
                            <ng-template let-item="item" let-index="index">
                                <span class="px-3 d-block" [ngClass]="{'bg-tag-exists': item.isSkillExists}">
                                    <span class="pr-2">{{ item.skill }}</span>
                                    <delete-icon style="width: 12px; height: 12px;"
                                        (click)="parentInput.removeItem(item, index)">
                                    </delete-icon>
                                </span>
                            </ng-template>
                            <tag-input-dropdown [autocompleteItems]="allParentSkillsTags">
                                <ng-template let-item="item" let-index="index">
                                    <span *ngIf="item.id != 'addTag'">{{item.skill}}</span>
                                    <span *ngIf="item.id == 'addTag'" class="d-block cursor-pointer font-bold"
                                        style="width: 100%;"
                                        (click)="openParentOrAlternateSkillModal(addParentSkillTemplate, 'parentSkillModal')">
                                        <span class="fa fa-plus mr-1"></span>
                                        <span>Add Skill</span>
                                    </span>
                                </ng-template>
                            </tag-input-dropdown>
                        </tag-input>
                    </div>
                    <div class="form-group col-xs-12 col-md-6"
                        [ngClass]="{'has-error': (addEditNewSkillForm.submitted && skillCategory.invalid), 'pointer-events-none opacity-half': isDisabled || addEditNewSkillObject.alternate}">
                        <label for="skillCategory" class="control-label mb-3">
                            Category<em>*</em> :
                        </label>
                        <select #skillCategory="ngModel" name="skillCategory" class="form-control"
                            [(ngModel)]="addEditNewSkillObject.category" required>
                            <option *ngFor="let category of skillCategoryList" [value]="category.value">{{category.name}}
                            </option>
                        </select>
                        <small class="error" *ngIf="(addEditNewSkillForm.submitted && skillCategory.invalid)">
                            Skill Category is mandatory
                        </small>
                    </div>
                    <!-- Alternate Skill -->
                    <div class="form-group col-xs-12 col-md-6"
                        [ngClass]="{'pointer-events-none opacity-half': isDisabled || addEditNewSkillObject.alternate}"
                        *ngIf="manageSkillType != 'alternateSkill'">
                        <label for="alternateSkills" class="control-label mb-0">
                            <span *ngIf="addEditNewSkillObject.alternate">Alternate Skills Of:</span>
                            <span *ngIf="!addEditNewSkillObject.alternate">Alternate Skills :
                                <span *ngIf="addEditNewSkillObject.isAlternateSkillDuplicate" class="pl-1 duplicate-skill duplicate-skill-message" [innerHTML]="addEditNewSkillObject.duplicateSkillMessage"></span>
                            </span>
                        </label>
                        <tag-input #alternateInput #alternateSkills="ngModel" name="alternateSkills"
                            [placeholder]="'Enter Alternate Skill'" [secondaryPlaceholder]="'Enter Alternate Skill'"
                            [(ngModel)]="addEditNewSkillObject.alternateSkills" [onAdding]="onAddingAlternateSkill"
                            (onAdd)="onAlternateSkillAdded($event)"
                            (onRemove)="onAlternateSkillRemoved($event)"
                            (onTextChange)="onAlternateSkillTextChange($event, 'alternateSkillModal')"
                            (keyup.enter)="addSkillOnEnter($event, 'alternateSkillModal', alternateInput)" 
                            (keydown.Tab)="addSkillOnEnter($event, 'alternateSkillModal', alternateInput)"
                            [addOnBlur]="true" [ngClass]="{'duplicate-skill': addEditNewSkillObject.isAlternateSkillDuplicate}">
                            <ng-template let-item="item" let-index="index">
                                <span class="px-3 d-block" [ngClass]="{'bg-tag-exists': item.isSkillExists}">
                                    <span class="pr-2">{{ item.skill }}</span>
                                    <delete-icon style="width: 12px; height: 12px;"
                                        (click)="alternateInput.removeItem(item, index)">
                                    </delete-icon>
                                </span>
                            </ng-template>
                            <tag-input-dropdown [autocompleteItems]="allAlternateSkillsTags | filterSkills:false">
                                <ng-template let-item="item" let-index="index">
                                    <span *ngIf="item.id != 'addTag'">{{item.skill}}</span>
                                </ng-template>
                            </tag-input-dropdown>
                        </tag-input>
                        <span class="help-block mb-0 ml-2">
                            <span>Ex. Alternate skill for 'C#'' is 'C Sharp'</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xs-12 text-right">
            <div class="clearfix">
                <button type="button" class="btn btn-danger mr-2" (click)="cancel()">
                    <span>Cancel</span>
                </button>
                <button *ngIf="manageSkillType != 'editSkill' && manageSkillType != 'moveSkill'" type="submit"
                    class="btn btn-success" (click)="addEditSkillDetails('add')">
                    Add
                </button>
                <button *ngIf="manageSkillType == 'editSkill' || manageSkillType == 'moveSkill'" type="submit"
                    class="btn btn-success" (click)="addEditSkillDetails('edit')">
                    Save
                </button>
            </div>
        </div>
    </form>
</div>

<!-- Add Parent Skill Modal Template -->
<ng-template #addParentSkillTemplate>
    <div class="ngx-modal-header">
        <h4 class="modal-title pull-left">
            Add Parent Skill
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal('parentSkill')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-xs-12">
                <form #addParentSkillForm="ngForm" (ngSubmit)="addParentSkill(newParentSkillList)" novalidate>
                    <div class="form-group col-xs-12 col-md-6"
                        [ngClass]="{'has-error mb-0': (addParentSkillForm.submitted && newParentSkillName.invalid)}">
                        <label for="skillName" class="control-label mb-3">
                            Skill Name<em>*</em> :
                        </label>
                        <input #newParentSkillName="ngModel" type="text" name="newParentSkillName" class="form-control"
                            [(ngModel)]="newParentSkillList.skill" placeholder="Enter Parent Skill" required>
                        <small class="error" *ngIf="(addParentSkillForm.submitted && newParentSkillName.invalid)">
                            Parent Skill is mandatory
                        </small>
                    </div>
                    <!-- Alternate Skill -->
                    <div class="form-group col-xs-12 col-md-6">
                        <label for="parentAlternateSkills" class="control-label mb-0">
                            Alternate Skills :
                            <span *ngIf="newParentSkillList.isAlternateSkillDuplicate" class="pl-1 duplicate-skill duplicate-skill-message" [innerHTML]="newParentSkillList.duplicateSkillMessage"></span>
                        </label>
                        <tag-input #parentAlternateInput #parentAlternateSkills="ngModel" name="parentAlternateSkills"
                            [placeholder]="'Enter Alternate Skill'" [secondaryPlaceholder]="'Enter Alternate Skill'"
                            [(ngModel)]="newParentSkillList.alternateSkills" [onAdding]="onAddingAlternateSkillFromParentModal"
                            (onTextChange)="onAlternateSkillTextChange($event, 'parentAlternateSkillModal')" 
                            (onAdd)="onAlternateSkillAdded($event)"
                            (onRemove)="onAlternateSkillRemoved($event)"
                            (keyup.enter)="addSkillOnEnter($event, 'alternateInParentSkillModal', parentAlternateInput)" 
                            (keydown.Tab)="addSkillOnEnter($event, 'alternateInParentSkillModal', parentAlternateInput)"
                            [addOnBlur]="true" [ngClass]="{'duplicate-skill': newParentSkillList.isAlternateSkillDuplicate}">
                            <ng-template let-item="item" let-index="index">
                                <span class="px-2">
                                    <span class="pr-2">{{ item.skill }}</span>
                                    <delete-icon style="width: 12px; height: 12px;"
                                        (click)="parentAlternateInput.removeItem(item, index)">
                                    </delete-icon>
                                </span>
                            </ng-template>
                            <tag-input-dropdown [autocompleteItems]="allAlternateSkillsTags | filterSkills:false">
                                <ng-template let-item="item" let-index="index">
                                    <span *ngIf="item.id != 'addTag'">{{item.skill}}</span>
                                </ng-template>
                            </tag-input-dropdown>
                        </tag-input>
                        <span class="help-block mb-0 ml-2">
                            <span>Ex. Alternate skill for 'C#'' is 'C Sharp'</span>
                        </span>
                    </div>
                    <div class="form-group col-xs-12 col-md-6"
                        [ngClass]="{'has-error mb-0': (addParentSkillForm.submitted && parentSkillCategory.invalid)}">
                        <label for="parentSkillCategory" class="control-label mb-3">
                            Category<em>*</em> :
                        </label>
                        <select #parentSkillCategory="ngModel" name="parentSkillCategory" class="form-control"
                            [(ngModel)]="newParentSkillList.category" required>
                            <option *ngFor="let category of skillCategoryList" [value]="category.value">{{category.name}}
                            </option>
                        </select>
                        <small class="error" *ngIf="(addParentSkillForm.submitted && parentSkillCategory.invalid)">
                            Category is mandatory
                        </small>
                    </div>
                    <div class="col-xs-12 text-right">
                        <div class="clearfix">
                            <button type="button" class="btn btn-danger mr-2" (click)="closeModal('parentSkill')">
                                <span>Cancel</span>
                            </button>
                            <button type="submit" class="btn btn-success">
                                Add
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>
<!-- Add Alternate Skill Template modal -->
<ng-template #addAlternateSkillTemplate>
    <div class="ngx-modal-header">
        <h4 class="modal-title pull-left">
            Add Alternate Skill for '{{typeOfAlternateSkillModal == 'parentAlternateSkillModal' ? newParentSkillList.skill : addEditNewSkillObject.skill}}'
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal('alternateSkill')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-xs-12">
                <form #addAlternateSkillForm="ngForm" (ngSubmit)="addAlternateSkill(newAlternateSkillList)" novalidate>
                    <div class="form-group col-xs-12 col-md-6"
                        [ngClass]="{'has-error mb-0': (addAlternateSkillForm.submitted && newAlternateSkillName.invalid)}">
                        <label for="newAlternateSkillName" class="control-label mb-3">
                            Skill Name<em>*</em> :
                        </label>
                        <input #newAlternateSkillName="ngModel" type="text" name="newAlternateSkillName"
                            class="form-control" [(ngModel)]="newAlternateSkillList.skill"
                            placeholder="Enter Skill Name" required>
                        <small class="error" *ngIf="(addAlternateSkillForm.submitted && newAlternateSkillName.invalid)">
                            Skill Name is mandatory
                        </small>
                    </div>
                    <div class="form-group col-xs-12 col-md-6"
                        [ngClass]="{'has-error mb-0': (addAlternateSkillForm.submitted && newAlternateSkillCategory.invalid)}">
                        <label for="newAlternateSkillCategory" class="control-label mb-3">
                            Category<em>*</em> :
                        </label>
                        <select #newAlternateSkillCategory="ngModel" name="newAlternateSkillCategory"
                            class="form-control" [(ngModel)]="newAlternateSkillList.category" [disabled]="true" required>
                            <option *ngFor="let category of skillCategoryList" [value]="category.value">{{category.name}}
                            </option>
                        </select>
                        <small class="error"
                            *ngIf="(addAlternateSkillForm.submitted && newAlternateSkillCategory.invalid)">
                            Category is mandatory
                        </small>
                    </div>
                    <!-- Parent Skill -->
                    <div class="form-group col-xs-12 col-md-6">
                        <label for="parentSkills" class="control-label mb-0">
                            Parent Skills :
                        </label>
                        <tag-input #newAlternateInput #newAlternateSkills="ngModel" name="parentSkills"
                            [(ngModel)]="newAlternateSkillList.parentSkills" [onAdding]="onAddingParentSkillFromAlternateModal"
                            (onTextChange)="onParentSkillTextChange($event)" [placeholder]="'Enter Parent Skill'"
                            [secondaryPlaceholder]="'Enter Parent Skill'"
                            (onRemove)="onParentSkillRemoved($event)"
                            (onAdd)="onParentSkillAdded($event)"
                            (keyup.enter)="addSkillOnEnter($event, 'parentSkillInAlternateSkillModal', newAlternateInput)" 
                            (keydown.Tab)="addSkillOnEnter($event, 'parentSkillInAlternateSkillModal', newAlternateInput)">
                            <ng-template let-item="item" let-index="index">
                                <span class="px-2">
                                    <span class="pr-2">{{ item.skill }}</span>
                                    <delete-icon style="width: 12px; height: 12px;"
                                        (click)="newAlternateInput.removeItem(item, index)">
                                    </delete-icon>
                                </span>
                            </ng-template>
                            <tag-input-dropdown [autocompleteItems]="allParentSkillsTags.slice(0,-1)">
                                <ng-template let-item="item" let-index="index">
                                    <span *ngIf="item.id != 'addTag'">{{item.skill}}</span>
                                    <span *ngIf="item.id == 'addTag'" class="d-block" style="width: 0; height: 0;"></span>
                                </ng-template>
                            </tag-input-dropdown>
                        </tag-input>
                    </div>
                    <div class="col-xs-12 text-right">
                        <div class="clearfix">
                            <button type="button" class="btn btn-danger mr-2" (click)="closeModal('alternateSkill')">
                                <span>Cancel</span>
                            </button>
                            <button type="submit" class="btn btn-success">
                                Add
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>
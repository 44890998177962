import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-work-locations-icon',
  templateUrl: './work-locations-icon.component.html',
  styleUrls: ['./work-locations-icon.component.css']
})
export class WorkLocationsIconComponent implements OnInit {

  @Input() workLocations:any;
  @Input() matchStatus:any;
  @Input() iconStyle:any;
  @Input() iconClass:any;
  @Input() message:any;
  @Input() context:any;

  tooltipMessage:any = '';
  buildingIconStyle:any;
  buildingCrossIconStyle:any;
  noLocationContainerStyle:any;



  constructor() { 

  }

  ngOnInit() {

    this.tooltipMessage = "Candidate's Preferred Work Locations";
        if(!this.matchStatus) {
            this.matchStatus = "available";
            this.tooltipMessage = "Job Work Locations";
        } else if(this.matchStatus === "noMatch") {
            this.tooltipMessage = "Candidate's preferred work locations doesn't match with the job";
        } else if(this.matchStatus === "match") {
            this.tooltipMessage = "Candidate's preferred work locations match with the job";
        }

        if(this.context === "jobMatchesPopover" || this.context === "jobMatches") {
            this.buildingIconStyle = "font-size:10px;";
            this.buildingCrossIconStyle = "font-size:23px";
            this.noLocationContainerStyle = "font-size:11px";
        } else {
            this.buildingIconStyle = "font-size:17px;";
            this.buildingCrossIconStyle = "font-size:28px";
            this.noLocationContainerStyle = "right:10px;margin-top:-14px;";
            if(this.context === "associateCandidates") {
                this.noLocationContainerStyle = "right:10px;margin-top:-16px;";
            }
        }

        if(!this.message) {
            this.message = "Work Locations";
        }
    }

  }

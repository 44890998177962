import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'fourDotFiveTimeStampFormatPipe'
})
export class FourDotFiveTimeStampFormatPipe implements PipeTransform {

  transform(value: any): any {
    return moment(value).format("MMM DD, YYYY hh:mm A");
  }

}

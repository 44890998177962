import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssessmentInfoComponent } from './assessment-info.component';
import { PopoverModule, TooltipModule } from 'ngx-bootstrap';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
  declarations: [AssessmentInfoComponent],
  imports: [
    CommonModule,
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    PipesModule
  ],
  entryComponents: [AssessmentInfoComponent],
  exports: [AssessmentInfoComponent]
})
export class AssessmentInfoModule { }

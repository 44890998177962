<div class="container main-box py-4">
    <form #addEntityForm="ngForm" novalidate>
        <div class="row" *ngIf="entityType === 'degrees'">
            <div class="col-xs-12">
                <div class="col-xs-12 px-0">
                    <div class="col-xs-12 col-md-6" [ngClass]="{'has-error mb-0': (addEntityForm.submitted && degreeLevel.invalid), 
                            'pointer-events-none opacity-half': isDisabled}">
                        <label for="degreeLevel" class="control-label mb-3">
                            Degree Level<em>*</em> :
                        </label>
                        <input #degreeLevel="ngModel" type="number" name="degreeLevel" class="form-control"
                            [(ngModel)]="addEditEntity.order" required>
                        <small class="error" *ngIf="(addEntityForm.submitted && addEditEntity.invalid)">
                            Degree level is mandatory
                        </small>
                    </div>
                    <div class="col-xs-12 col-md-6 mb-5" [ngClass]="{'has-error mb-0': (addEntityForm.submitted && degreeName.invalid), 
                            'pointer-events-none opacity-half': isDisabled}">
                        <label for="degreeName" class="control-label mb-3">
                            Degree Name<em>*</em> :
                        </label>
                        <input #degreeName="ngModel" type="text" name="degreeName" class="form-control"
                            [(ngModel)]="addEditEntity.degreeName" required>
                        <small class="error" *ngIf="(addEntityForm.submitted && degreeName.invalid)">
                            Degree name is mandatory
                        </small>
                    </div>
                    <!-- Alternate Name -->
                    <div class="form-group col-xs-12 col-md-6" [ngClass]="">
                        <label for="alternateNames" class="control-label mb-0">
                            <span>Alternate Names :
                                <span *ngIf="addEditEntity.isAlternateNameDuplicate"
                                    class="pl-1 duplicate-skill duplicate-skill-message"
                                    [innerHTML]="addEditEntity.duplicateNameMessage"></span>
                            </span>
                        </label>
                        <tag-input #alternateInput #alternateNames="ngModel" name="alternateNames"
                            [placeholder]="'Enter Alternate Name'" [secondaryPlaceholder]="'Enter Alternate Name'"
                            [(ngModel)]="addEditEntity.alternateName"
                            (keyup.enter)="addAlternateNameOnEnter($event, alternateInput)"
                            (keydown.Tab)="addAlternateNameOnEnter($event, alternateInput)" [addOnBlur]="true"
                            [ngClass]="{'duplicate-skill': addEditEntity.isAlternateNameDuplicate}">
                            <ng-template let-item="item" let-index="index">
                                <span class="px-3 d-block" [ngClass]="{'bg-tag-exists': item.isNameExists}">
                                    <span class="pr-2">{{ item.display }}</span>
                                    <delete-icon style="width: 12px; height: 12px;"
                                        (click)="alternateInput.removeItem(item, index)">
                                    </delete-icon>
                                </span>
                            </ng-template>
                        </tag-input>
                        <span class="help-block mb-0 ml-2">
                            <span>Ex. Alternate Name for 'MD'' is 'Directorate of Medicine'</span>
                        </span>
                    </div>
                    <div class="col-xs-12 col-md-6 form-group pb-5" [ngClass]="">
                        <label for="degreeFullName" class="control-label mb-3">
                            Full Name :
                        </label>
                        <input #degreeFullName="ngModel" type="text" name="degreeFullName" class="form-control"
                            [(ngModel)]="addEditEntity.fullName">
                    </div>
                    <div class="col-xs-12 col-md-6" [ngClass]="">
                        <div class="d-flex align-items-center">
                            <div class="checkbox-nice mr-4">
                                <input type="checkbox" [(ngModel)]="addEditEntity.enableFieldOfStudy"
                                    id="enableFieldOfStudy" name="enableFieldOfStudy" />
                                <label for="enableFieldOfStudy">
                                    Enable Field of Study
                                </label>
                            </div>
                            <div class="checkbox-nice">
                                <input type="checkbox" [(ngModel)]="addEditEntity.showInParenthesis"
                                    id="showInParenthesis" name="showInParenthesis" />
                                <label for="showInParenthesis">
                                    Show in Parenthesis
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="entityType === 'fieldOfStudy'">
            <div class="col-xs-12">
                <div class="col-xs-12 px-0">
                    <div class="col-xs-12 col-md-6" [ngClass]="{'has-error mb-0': (addEntityForm.submitted && fieldOfStudy.invalid), 
                            'pointer-events-none opacity-half': isDisabled}">
                        <label for="fieldOfStudy" class="control-label mb-3">
                            Field of Study<em>*</em> :
                        </label>
                        <input #fieldOfStudy="ngModel" type="text" name="fieldOfStudy" class="form-control"
                            [(ngModel)]="addEditEntity.fieldOfStudy" required>
                        <small class="error" *ngIf="(addEntityForm.submitted && fieldOfStudy.invalid)">
                            Field of Study is mandatory
                        </small>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="entityType === 'roles'">
            <div class="col-xs-12">
                <div class="col-xs-12 px-0">
                    <div class="col-xs-12 col-md-6" [ngClass]="{'has-error mb-0': (addEntityForm.submitted && roles.invalid), 
                            'pointer-events-none opacity-half': isDisabled}">
                        <label for="roles" class="control-label mb-3">
                            Role<em>*</em> :
                        </label>
                        <input #roles="ngModel" type="text" name="roles" class="form-control"
                            [(ngModel)]="addEditEntity.role" required>
                        <small class="error" *ngIf="(addEntityForm.submitted && roles.invalid)">
                            Role is mandatory
                        </small>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="entityType === 'certificates'">
            <div class="col-xs-12 mb-4">
                <div class="col-xs-12 px-0">
                    <div class="col-xs-12 col-md-6" [ngClass]="{'has-error mb-0': (addEntityForm.submitted && certificateName.invalid), 
                            'pointer-events-none opacity-half': isDisabled}">
                        <label for="certificateName" class="control-label mb-3">
                            Name<em>*</em> :
                        </label>
                        <input #certificateName="ngModel" type="text" name="certificateName" class="form-control"
                            [(ngModel)]="addEditEntity.name" required>
                        <small class="error" *ngIf="(addEntityForm.submitted && certificateName.invalid)">
                            Name is mandatory
                        </small>
                    </div>
                    <div class="col-xs-12 col-md-6" [ngClass]="{'has-error mb-0': (addEntityForm.submitted && certificateGroup.invalid), 
                            'pointer-events-none opacity-half': isDisabled}">
                        <label for="certificateGroup" class="control-label mb-3">
                            Group<em>*</em> :
                        </label>
                        <input #certificateGroup="ngModel" type="text" name="certificateGroup" class="form-control"
                            [(ngModel)]="addEditEntity.group" required>
                        <small class="error" *ngIf="(addEntityForm.submitted && certificateGroup.invalid)">
                            Group is mandatory
                        </small>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xs-12 text-right">
            <div class="clearfix">
                <button type="button" class="btn btn-danger mr-2" (click)="cancel()">
                    <span>Cancel</span>
                </button>
                <button *ngIf="action === 'add'" type="submit" class="btn btn-success" (click)="addEntityDetails()">
                    Add
                </button>
                <button *ngIf="action === 'edit'" type="submit" class="btn btn-success" (click)="editEntityDetails()">
                    Save
                </button>
            </div>
        </div>
    </form>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FourDotFivePhoneNumberComponent } from './four-dot-five-phone-number.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [FourDotFivePhoneNumberComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [FourDotFivePhoneNumberComponent],
  entryComponents: [FourDotFivePhoneNumberComponent]
})
export class FourDotFivePhoneNumberModule { }

<div class="row">
    <div class="col-lg-12">
        <div id="content-header" class="clearfix">
            <div class="pull-left">
                <ol class="breadcrumb">
                    <li><a (click)="redirectToUrl('missioncontrol.dashboard', true)">Home</a></li>
                    <li><a (click)="redirectToUrl('missioncontrol.candidates', true)"><span>Candidates</span></a></li>
                    <li class="active">Candidate Job Comparison</li>
                </ol>
                <h1 class="heading-css">Candidate Job Comparison</h1>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-xs-12">
        <app-candidate-job-comparison [jobId]="jobId" [jobMatchId]="jobMatchId" [candidateId]="candidateId">
        </app-candidate-job-comparison>
    </div>
</div>
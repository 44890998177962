import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { DateTimeUtilityService } from './date-time-utility.service';
import { AuthService } from './auth.service';
import { UserRoleService } from './user-role.service';
import { StorageService } from './storage.service';
import { CookieService } from 'ngx-cookie-service';
import { GenericService } from './generic.service';
import { JobService } from './job.service';
import { UserService } from './user.service';
import { CompanyService } from './company.service';
import { PlanService } from './plan.service';
import { CandidateService } from './candidate.service';
import { NotificationsService } from './notifications.service';
import { ResumeService } from './resume.service';
import { UserCommunicationService } from './user-communication.service';
import { EmployeeService } from './employee.service';
import { AssessmentService } from './assessment.service';
import { FeedbackAuthoringService } from './feedback-authoring.service';
import { BrowserService } from './browser.service';
import { DebounceService } from './debounce.service';
import { StateManagementStorageService } from './state-management-storage.service';
import { StateHistoryService } from './state-history.service';
import { AlertAndNotificationsService } from './alert-and-notifications.service';
import { DocumentService } from './document.service';
import { MeetingService } from './meeting.service';
import { AppVersionService } from './app-version.service';
import { UtilityService } from './utility.service';
import { AngularModalService } from './modal.service';
import { SharedService } from './shared.service';
import { DocumentActionService} from './document-action-service';
import { EntityActivitiesService } from './entity-activities.service';
import { SettingService } from './setting.service';
import { ManageSkillsService } from './manage-skills.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [DateTimeUtilityService, AuthService, UserRoleService, StorageService,
    GenericService, JobService, UserService, CookieService, CompanyService, PlanService, 
    CandidateService, NotificationsService, ResumeService, UserCommunicationService, EmployeeService,
    AssessmentService, FeedbackAuthoringService, BrowserService, DebounceService, StateManagementStorageService,
    StateHistoryService, AlertAndNotificationsService, DocumentService, MeetingService, AppVersionService, UtilityService, AngularModalService,
    SharedService, DocumentActionService, EntityActivitiesService, SettingService, ManageSkillsService]
})
export class ServicesModule { }

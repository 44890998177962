import { Inject, Injectable } from '@angular/core';
import { AuthService } from './auth.service';

import { MESSAGECONSTANTS } from '../utils/constants';

@Injectable({
  providedIn: 'root'
})

export class UserRoleService {
  manageUsersScreenId: any;

  constructor(@Inject(AuthService) public authService: AuthService) { }

  isLoggedInUserSuperUser() {
    return this.authService.getUserDetails().roleScreenRef.role.name == '4dot5SuperUser';
  }

  isLoggedInUser4dot5Admin() {
    return this.authService.getUserDetails().roleScreenRef.role.name == '4dot5Admin';
  }

  isLoggedInUserSuperUserOr4dot5Admin() {
    return this.authService.getUserDetails().roleScreenRef.role.name == '4dot5SuperUser' || this.authService.getUserDetails().roleScreenRef.role.name == '4dot5Admin';
  }

  isLoggedInUserCompanyAdmin() {
    return this.authService.getUserDetails().roleScreenRef.role.name == 'CorporateAdmin' || this.authService.getUserDetails().roleScreenRef.role.name == 'StaffingAdmin';
  }

  isLoggedInUserAnAdmin() {

    return this.authService.getUserDetails().roleScreenRef.role.name == '4dot5SuperUser' || this.authService.getUserDetails().roleScreenRef.role.name == '4dot5Admin' || this.authService.getUserDetails().roleScreenRef.role.name == 'CorporateAdmin' || this.authService.getUserDetails().roleScreenRef.role.name == 'StaffingAdmin';
  }

  isLoggedInUserARecruiter() {
    return this.authService.getUserDetails().roleScreenRef.role.name == 'CorporateRecruiter' || this.authService.getUserDetails().roleScreenRef.role.name == 'StaffingRecruiter';
  }

  isLoggedInUserEmployee() {
    return this.authService.getUserDetails().roleScreenRef.role.name == 'Employee' || this.authService.getUserDetails().roleScreenRef.role.name == 'ClientEmployee';
  }

  hasAccessByScreenId() {
    let accessGranted = false;
    for (let i = 0; i < this.authService.getUserDetails().roleScreenRef.screen.length; i++) {
      if (this.authService.getUserDetails().roleScreenRef.screen[i].id === this.manageUsersScreenId) {
        accessGranted = true;
        break;
      }
    }
    return accessGranted;
  }

  isSuperUser(role) {
    return angular.equals(role.id, MESSAGECONSTANTS.ROLEIDS.SUPERUSER);
  }

  isFourDotFiveAdmin(role) {
    return angular.equals(role.id, MESSAGECONSTANTS.ROLEIDS.FOURDOTFIVE_ADMIN);
  }

  isCompanyAdmin(role) {
    return angular.equals(role.id, MESSAGECONSTANTS.ROLEIDS.STAFFING_ADMIN) || angular.equals(role.id, MESSAGECONSTANTS.ROLEIDS.CORPORATE_ADMIN);
  }

  isRecruitingManager(role) {
    return angular.equals(role.id, MESSAGECONSTANTS.ROLEIDS.STAFFING_REC_MANAGER) || angular.equals(role.id, MESSAGECONSTANTS.ROLEIDS.CORPORATE_REC_MANAGER);
  }

  isHiringManager(role) {
    return role.name == "Hiring Manager";
  }

  isSeniorRecruiter(role) {
    return angular.equals(role.id, MESSAGECONSTANTS.ROLEIDS.STAFFING_SR_RECRUITER) || angular.equals(role.id, MESSAGECONSTANTS.ROLEIDS.CORPORATE_SR_RECRUITER);
  }

  isRecruiter(role) {
    return angular.equals(role.id, MESSAGECONSTANTS.ROLEIDS.STAFFING_RECRUITER) || angular.equals(role.id, MESSAGECONSTANTS.ROLEIDS.CORPORATE_RECRUITER);
  }
}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequisitionCardIconsComponent } from './requisition-card-icons.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PopoverModule, TooltipModule } from 'ngx-bootstrap';
import { WorkLocationsIconModule } from '../work-locations-icon/work-locations-icon.module';
import { InfoIconModule } from '../info-icon/info-icon.module';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
  declarations: [RequisitionCardIconsComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    WorkLocationsIconModule,
    InfoIconModule,
    PipesModule
  ],
  exports: [RequisitionCardIconsComponent],
  entryComponents: [RequisitionCardIconsComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class RequisitionCardIconsModule { }

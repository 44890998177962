<div class="ngx-modal-header">
    <h4 class="modal-title pull-left">{{modalData.title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal(null)">
        <span>&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="skill fuelux">
        <div class="">
            <div class="main-box clearfix">
                <div class="main-box-body clearfix wizardmainbox">
                    <div class="wizard" data-initialize="wizard" id="uploadFileWizard">
                        <div class="steps-container">
                            <ul class="steps">
                                <li data-step="1" data-name="upload" class="active">
                                    <span class="badge">1</span>Upload<span class="chevron"></span>
                                </li>
                                <li data-step="2" data-name="scoring">
                                    <span class="badge">2</span>
                                    <span>View Results</span>
                                    <span class="chevron"></span>
                                </li>
                            </ul>
                        </div>
                        <div class="actions">
                            <button type="button" class="btn btn-info btn-prev mr-2"
                                [disabled]="disablePreviousButtonFlag">
                                <span class="glyphicon glyphicon-arskill-left"></span>Prev
                            </button>
                            <button type="button" class="btn btn-info btn-next" data-last="Complete"
                                [disabled]="disableNextButtonFlag">
                                Next<span class="glyphicon glyphicon-arskill-right"></span>
                            </button>
                        </div>
                        <div class="step-content">
                            <div class="step-pane active" data-step="1">
                                <app-upload-files [modalData]="modalData" (saveCallback)="saveCallback($event)"
                                    (cancelCallback)="cancelCallback()">
                                </app-upload-files>
                            </div>
                            <div class="step-pane" data-step="2">
                                <div class="skill" *ngIf="responseEntities">
                                    <div class="col-xs-12">
                                        <div class="main-box p-4">
                                            <div class="mt-3">
                                                <p><b>{{modalData.title.replace('Upload ', "")}}</b> successfully
                                                    uploaded. Total <span
                                                        class="emerald font-bold">{{responseEntities.activeCount}}</span>
                                                    active and <span
                                                        class="text-danger font-bold">{{responseEntities.inactiveCount}}</span>
                                                    inactive. <span
                                                        class="text-success font-bold">{{responseEntities.addedCount}}</span>
                                                    added, <span
                                                        class="emerald font-bold">{{responseEntities.updatedCount}}</span>
                                                    modified and <span
                                                        class="text-danger font-bold">{{responseEntities.deletedCount}}</span>
                                                    deleted in this update.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeeCardComponent } from './employee-card.component';
import { BsDropdownModule, TooltipModule } from 'ngx-bootstrap';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { ConvertEmployeeToUserModule } from './convert-employee-to-user/convert-employee-to-user.module';
import { EmployeeCardActionsModalComponent } from './employee-card-actions-modal/employee-card-actions-modal.component';
import { RecentActivitiesModule } from 'src/app/shared/recent-activities/recent-activities.module';
import { EntityActivitiesModule } from 'src/app/shared/entity-activities/entity-activities.module';
import { DeleteEntityModule } from 'src/app/shared/delete-entity/delete-entity.module';

@NgModule({
  declarations: [EmployeeCardComponent, EmployeeCardActionsModalComponent],
  imports: [
    CommonModule,
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    PipesModule,
    ConvertEmployeeToUserModule,
    RecentActivitiesModule,
    EntityActivitiesModule,
    DeleteEntityModule
  ],
  exports: [EmployeeCardComponent, EmployeeCardActionsModalComponent],
  entryComponents: [EmployeeCardComponent, EmployeeCardActionsModalComponent]
})
export class EmployeeCardModule { }

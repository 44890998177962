import { downgradeComponent } from "@angular/upgrade/static";
import { NotificationsPageComponent } from './notifications-page.component';

export const NotificationsPageAjsModule = angular
  .module('notificationsPage', [])
  .directive('notificationsPage', downgradeComponent({ component: NotificationsPageComponent }))
  .config(
    ['$stateProvider', ($stateProvider) => {
      $stateProvider.state('missioncontrol.notifications', {
        url: '/notifications',
        component: NotificationsPageComponent,
        data: {
          requireLogin: true
        },
        params: {
          searchObject: null,
          context: null
        }
      });
    }
    ])
  .name;

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BulkUploadPageComponent } from './bulk-upload-page.component';
import { EmployeeFileUploadModule } from './employee-file-upload/employee-file-upload.module';

@NgModule({
  declarations: [BulkUploadPageComponent],
  imports: [
    CommonModule,
    EmployeeFileUploadModule
  ],
  exports: [BulkUploadPageComponent],
  entryComponents: [BulkUploadPageComponent]
})
export class BulkUploadPageModule { }

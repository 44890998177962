<div class="ngx-modal-header">
    <h4 class="modal-title pull-left">{{modalData.title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal(null)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div *ngIf="modalData.type === 'activities'" class="">
        <app-entity-activities [activityMap]="modalData.activityMap" context="assessments">
        </app-entity-activities>
    </div>
    <div *ngIf="modalData.type === 'deleteAssessment'" class="">
        <app-delete-assessment-credential context="{{modalData.context}}" [companyId]="modalData.companyId"
            [buId]="modalData.buId" [testOwnerEmail]="modalData.testOwnerEmail" [assessmentId]="modalData.assessmentId"
            [isDeleteCredential]="modalData.isDeleteCredential" (successCallback)="deleteAssessment()"
            (cancelCallback)="closeModal(null)">
        </app-delete-assessment-credential>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-xs-12" *ngIf="!loadingEmployeeFlag">
            <div class="row">
                <div class="col-xs-12">
                    <form #makeAppUserForm="ngForm" name="makeAppUserForm" novalidate>
                        <div class="row">
                            <div class="col-xs-12 job-match-status-statement">
                                <div style="font-size: 1.2em">
                                    Make Employee <span class="emerald">{{employeeObject.name}}</span> an Application
                                    User
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-6">
                                <div class="form-group"
                                    [ngClass]="{'has-error':(makeAppUserForm.submitted && applicationManager.invalid)}">
                                    <label for="applicationManager" class="control-label">
                                        Choose Manager<em> * </em> :
                                    </label>
                                    <tag-input [ngClass]="{'has-tags': selectedApplicationManagerObjectsArray.length}"
                                        #applicationManager="ngModel" name="applicationManager" [identifyBy]="'id'"
                                        [displayBy]="'name'" [(ngModel)]="selectedApplicationManagerObjectsArray"
                                        [placeholder]="'Type name to search for user.'"
                                        [secondaryPlaceholder]="'Type name to search for user.'" [maxItems]="1"
                                        [onlyFromAutocomplete]="true" [disabled]="makingAppUserFlag"
                                        class="make-application-user"
                                        (onAdd)="applicationManagerTypeAheadManagerAdded($event)"
                                        (onRemove)="applicationManagerTypeAheadManagerRemoved($event)">
                                        <ng-template let-item="item" let-index="index">
                                            <span class="px-3 d-block d-flex">
                                                <span class="pr-3 d-block">{{ item.name.length > 40 ? (item.name.substring(0, 40) + "...") : item.name }}</span>
                                                <delete-icon style="width: 12px; height: 12px;"
                                                    (click)="removeItem(item, index)">
                                                </delete-icon>
                                            </span>
                                        </ng-template>
                                        <tag-input-dropdown [identifyBy]="'id'" [displayBy]="'name'"
                                            [showDropdownIfEmpty]="true"
                                            [autocompleteObservable]='getApplicationManagers' [limitItemsTo]="1000">
                                            <ng-template let-item="item" let-index="index">
                                                {{ item.name }}
                                            </ng-template>
                                        </tag-input-dropdown>
                                    </tag-input>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-6">
                                <div class="form-group"
                                    [ngClass]="{'has-error':(makeAppUserForm.submitted && role.invalid)}">
                                    <label for="role" class="control-label">
                                        Role<em> * </em> :
                                    </label>
                                    <select #role="ngModel" class="form-control access" name="role"
                                        [ngClass]="{'has-form-control-error':(makeAppUserForm.submitted && role.invalid)}"
                                        [(ngModel)]="employeeToUserObject.roleId"
                                        [disabled]="(selectedApplicationManagerObjectsArray.length == 0) || makingAppUserFlag"
                                        required>
                                        <option *ngFor="let role of permissibleRolesArray; let i = index"
                                            [value]="role.id">
                                            {{role.description}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row" style="margin-top: 20px;padding: 10px;">
                            <div class="col-xs-12 text-right clearfix">
                                <button type="button" class="btn btn-danger" style="margin-right: 5px;"
                                    [disabled]="makingAppUserFlag" (click)="cancel()">
                                    <span>Cancel</span>
                                </button>
                                <button type="submit" class="btn btn-primary pull-right" [disabled]="makingAppUserFlag"
                                    (click)="convertToApplicationUser()">
                                    <span *ngIf="!makingAppUserFlag">Make Application User</span>
                                    <span *ngIf="makingAppUserFlag">Making Application User ..</span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-xs-12 d-flex justify-content-center align-items-center" *ngIf="loadingEmployeeFlag">
            <div>
                Loading.. <span class="fas fa-spin fa-spinner"></span>
            </div>
        </div>
    </div>
</div>
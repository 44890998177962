<div id="content-wrapper">
	<div class="row">
		<div class="col-lg-12">
			<div class="slide-main-container">
				<div class="container slide-main-animation">
					<div class="row">
						<div class="col-xs-12">
							<div id="login-box" style="max-width: 380px;">
								<div id="login-box-holder">
									<div class="row">
										<div class="col-xs-12">
											<header id="login-header">
												<div id="login-logo">
													<img src="assets/app-content/img/logo-symbol.png" alt="" />
												</div>
											</header>
											<div id="login-box-inner" class="with-heading">
												<!-- <h4 class="text-center">Forgot your password?</h4> -->
												<!-- <p *ngIf="message === undefined">Enter Email address to recover your password.</p> -->
												<!-- <p class="error">{{message}}</p> -->
												<form #forgotPasswordForm="ngForm" (ngSubmit)="forgotPasswordForm.form.valid && getPassword()" novalidate>
													<div class="input-group reset-pass-input mb-0 pb-0">
														<span class="input-group-addon"><i
																class="fa fa-user"></i></span> 
														<input #email="ngModel" class="form-control" type="email" placeholder="Email address" name="email" [(ngModel)]="user.email" required email>
													</div>
													<div class="error" *ngIf="forgotPasswordForm.submitted && email.errors">
														<small class="error" *ngIf="email.errors?.required">
																{{MESSAGECONSTANTS.COMMONVALIDATIONS.EMAIL_MANDATORY}}
														</small> 
														<small class="error" *ngIf="forgotPasswordForm.dirty && email.errors?.email">
																{{MESSAGECONSTANTS.COMMONVALIDATIONS.NOT_VALID_EMAIL}}
														</small>
													</div>
													<div class="error" *ngIf="forgotPasswordForm.pristine">
														<small class="error" *ngIf="errorMessage !== undefined && errorMessage !== null">
																{{errorMessage}}</small></div>
													<div class="has-success" *ngIf="forgotPasswordForm.pristine">
														<small class="help-block" *ngIf="confirmLink !== undefined && confirmLink !== null">
																{{confirmLink}}</small>
													</div>
													<div class="row">
														<div class="col-xs-12">
															<button id="resetPasswordButton" type="submit" [disabled]="isSettingPasswordRequestInProgress" class="btn btn-success">{{MESSAGECONSTANTS.BUTTONS.FORGOT_PASSWORD_PAGE.RESET_PASSWORD}}</button>
														</div>
														<div class="col-xs-12">
															<br /> <a (click)="goBackToLogin()" id="login-forgot-link" class="forgot-link">Back to login</a>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="row">
    <div class="col-lg-12">
        <div id="content-header" class="clearfix">
            <div class="pull-left">
                <ol class="breadcrumb">
                    <li><a (click)="redirectToUrl('missioncontrol.dashboard', true)">Home</a></li>
                    <li class="active">Employees</li>
                </ol>
                <h1 class="heading-css">
                    Employees
                </h1>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-xs-12">
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div style="margin: 10px;" class="text-right">
                        <div class="btn-group" *ngIf="canAddUserAtCompanyLevel || canAddUserAtDepartmentOrBULevel"
                            style="margin: 10px;">
                            <button type="button" class="btn btn-primary" data-toggle="dropdown"
                                [disabled]="companyState == 'SoftHold'">Actions</button>
                            <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false" [disabled]="companyState == 'SoftHold'">
                                <span class="caret"></span>
                                <span class="sr-only">Toggle Dropdown</span>
                            </button>
                            <ul class="dropdown-menu">
                                <li role="menuitems">
                                    <a class="action-menu-item" (click)="addEmployee()">
                                        Add Employee
                                    </a>
                                </li>
                                <li role="menuitems">
                                    <a class="action-menu-item" (click)="uploadEmployeeFile()">
                                        Upload Employee File
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <input type="text" placeholder="Type to filter" id="employeeSearchText"
                            name="employeeSearchText" [(ngModel)]="employeesContainer.employeesQueryFilter.searchText"
                            (keyup)="searchEmployees()" class="text-center filter-style" />
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-xs-12">
                    <div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="employeesContainer.getNext()"
                        [infiniteScrollDisabled]="employeesContainer.busy || employeesContainer.disableInfiniteScroll">
                        <div class="col-xs-12 col-sm-4" *ngFor="let employee of employeesContainer.employees; let i = index">
                            <app-employee-card [employeeId]="employee.id" [activityMap]="activityMap"
                                (deleteCallback)="onEmployeeDelete(employee)">
                            </app-employee-card>
                        </div>
                    </div>
                    <div class="row" *ngIf="employeesContainer.busy">
                        <div class="col-xs-12 no_candidates_found text-center">
                            <span class="pr-2">Loading</span>
                            <span class="fa fa-spinner fa-spin"></span>
                        </div>
                    </div>
                    <div class="row animate-if" *ngIf="!employeesContainer.employeesExistFlag">
                        <div class="col-xs-12 no_candidates_found text-center d-flex flex-column">
                            <span class="mb-5" style="font-size: 1.2em;">
                                No employees found!
                            </span>
                            <img src="assets/app-content/img/no_users.svg" style="height:180px" alt="No Users Found">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<style>
	.pt-0{
		padding-top: 0!important;
	}
	.error-message-container{
		position: relative;
		bottom: 20px;
	}
	
	.border-danger{
		border: 1px solid red !important;
	}
</style>
<div class="row">
	<div class="col-xs-12">
		<div id="login-box" *ngIf="showValidation">
			<div id="login-box-holder">
				<div class="row">
					<div class="col-xs-12">
						<header id="login-header">
							<div id="login-logo">
								<img src="assets/app-content/img/logo-symbol.png" alt="" />
							</div>
						</header>
						<div id="login-box-inner" class="with-heading">
							<p *ngIf="message">{{message}}</p>
                        <form #changepasswordform="ngForm" (ngSubmit)="changepasswordform.form.valid && changePassword()" novalidate>
								<label for="currentPassword">Current Password</label>
								<div class="input-group reset-pass-input pt-0">
									<span class="input-group-addon"><i class="fa fa-key"></i></span>
									<input #currentpassword="ngModel" class="form-control" type="password" placeholder="Current password" name="currentpassword" [(ngModel)]="user.currentPassword"
									   (keyup)="compareCurrentAndNewPassword()" required>
								</div>
								<div class="error error-message-container" *ngIf="currentpassword.errors">
									<small class="error"
										*ngIf="changepasswordform.submitted && currentpassword.errors?.required">
										{{MESSAGECONSTANTS.RESETPASSWORD.CURRENTPASSWORD_MANDATORY}}
									</small>
								</div>

								<!-- New Password -->
								<label for="newPassword">New Password</label>
								<i class="fas fa-info-circle text-primary cursor-pointer" 
									[ngClass]="{'red': newpassword.errors?.minlength || newpassword.errors?.maxlength || (!changepasswordform.form.pristine && newpassword.errors && newpassword.errors?.pattern)}"
									*ngIf="passwordRestrictions.lengthRestrictionEnabled || passwordRestrictions.complexityRestrictionEnabled"
									popover-title="Rules" 
									uib-popover-template="'password-restrictions.html'"
									popover-placement="top auto"
									popover-trigger="'outsideClick'"
									popover-append-to-body="true" style="display: inline-block;"
									>
								</i>
								<div class="input-group reset-pass-input pt-0">
									<span class="input-group-addon"><i class="fa fa-key"></i></span>
									<input #newpassword="ngModel" class="form-control"
										[ngClass]="{'border-danger': newpassword.errors?.minlength || newpassword.errors?.maxlength || (!changepasswordform.form.pristine && newpassword.errors && newpassword.errors?.pattern)}"
										type="password" placeholder="New password" name="newpassword" [(ngModel)]="user.newPassword" minlength="parsedRestrictions.minimumLength"
										maxlength="parsedRestrictions.maximumLength" [pattern]="validationPattern" required (keyup)="compare()">
								</div>
								<div class="error-message-container">
									<div class="error" *ngIf="newpassword.errors">
										<small class="error" *ngIf="changepasswordform.submitted && newpassword.errors?.required">
											{{MESSAGECONSTANTS.RESETPASSWORD.NEWPASSWORD_MANDATORY}}</small>
										<!-- <small class="error" *ngIf="form.changepasswordform.newpassword.$error.minlength">
											Password should have atleast {{parsedRestrictions.minimumLength}} characters.</small>
										<small class="error" *ngIf="form.changepasswordform.newpassword.$error.maxlength">
											Password should have at most {{parsedRestrictions.maximumLength}} characters.</small> -->
									</div>
									<div class="error" *ngIf="!changepasswordform.form.pristine && newpassword.errors && !newpassword.errors?.required && !newpassword.errors?.pattern && !newpassword.errors?.minlength && !newpassword.errors?.maxlength">
										<small class="error" *ngIf="newpassword.errors?.unMatched">{{MESSAGECONSTANTS.RESETPASSWORD.PASSWORDS_SAME}}</small>
									</div>
									<div class="error" *ngIf="!changepasswordform.form.pristine && newpassword.errors && (newpassword.errors?.pattern || newpassword.errors?.minlength || newpassword.errors?.maxlength)">
										<small class="error">The password entered does not follow the rules.</small>
									</div> 
								</div>

								<!-- Confirm Password -->
								<label for="confirmPassword">Confirm Password</label>
								<div class="input-group reset-pass-input pt-0">
									<span class="input-group-addon"><i class="fa fa-key"></i></span>
									<input #confirmnewpassword="ngModel" class="form-control" type="password" placeholder="Confirm new password" name="confirmnewpassword" 
										[(ngModel)]="user.verifyPassword" (keyup)="compare()" required>
								</div>
								<div class="error-message-container">
									<div class="error" *ngIf="confirmnewpassword.errors">
										<small class="error" *ngIf="changepasswordform.submitted && confirmnewpassword.errors?.required">
											{{MESSAGECONSTANTS.RESETPASSWORD.CONFIRMNEWPASSWORD_MANDATORY}}</small>
									</div>
									<!-- <div class="error" *ngIf="form.changepasswordform.$submitted  && user.verifyPassword.length>0 && !(form.changepasswordform.newpassword.$error && form.changepasswordform.newpassword.$error.pattern)"> -->
									<div class="error" *ngIf="user.verifyPassword?.length>0 && !(newpassword.errors && newpassword.errors?.pattern) && user.newPassword?.length>= parsedRestrictions.minimumLength && user.newPassword?.length <= parsedRestrictions.maximumLength ">
										<small class="error" *ngIf="!isNewAndConfirmPasswordEqual">{{MESSAGECONSTANTS.RESETPASSWORD.PASSWORDSNOTEQUAL}}</small>
									</div>								
									<div class="error" *ngIf="changepasswordform.pristine">
										<small class="error" *ngIf="errormessage !== undefined && errormessage !== null">
											{{errormessage}}</small>
									</div>
								</div>
								<div class="row">
									<div class="col-xs-12">
										<button type="submit" [disabled]="isSettingPassword || !isNewAndConfirmPasswordEqual" class="btn btn-success col-xs-12">{{MESSAGECONSTANTS.BUTTONS.RESET_PASSWORD_PAGE.RESET_PASSWORD}}</button>
									</div>
								</div>
								<!--<div class="row">
									<div class="col-xs-12">
										<br/>
										<a ui-sref-opts="{reload: true}" ui-sref="missioncontrol.dashboard" ui-sref-opts="{reload : true}"
											id="login-forget-link" class="forgot-link">{{MESSAGECONSTANTS.RESETPASSWORD.BACK_TO_DASHBOARD}}</a>
									</div>
								</div>-->
							</form>
						</div>
					</div>
				</div>
			</div>
		</div> 
		<div class="col-xs-12 loading-spinner text-center" style="padding-top:180px;" *ngIf="!showValidation">
			<span>Loading</span>
			<span class="fa fa-spinner fa-spin"></span>
		</div>
	</div>
</div>

<script type="text/ng-template" id="password-restrictions.html">
	<div class="validation-rules">
		<p>The password has to have:</p>
		<ol style="padding-left: 20px;">
			<li *ngFor="let restriction of parsedRestrictions.restrictionsList">
				{{restriction}}
			</li>
		</ol>
	</div>
</script>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DoNotHireComponent } from './do-not-hire.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ExternalNotesModule } from '../external-notes/external-notes.module';

@NgModule({
  declarations: [DoNotHireComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    ExternalNotesModule
  ],
  entryComponents: [DoNotHireComponent],
  exports: [DoNotHireComponent]
})
export class DoNotHireModule { }

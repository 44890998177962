import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserRefreshMessageComponent } from './browser-refresh-message.component';

@NgModule({
  declarations: [BrowserRefreshMessageComponent],
  imports: [
    CommonModule
  ],
  entryComponents: [BrowserRefreshMessageComponent]
})
export class BrowserRefreshMessageModule { }

import { Component, OnInit, Input, Output, EventEmitter, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CandidateService } from 'src/app/core/services/candidate.service';
import { JobService } from 'src/app/core/services/job.service';
import { MESSAGECONSTANTS } from 'src/app/core/utils/constants';

import { JobSalary } from 'src/app/core/models/jobSalary';
import { CompanyService } from 'src/app/core/services/company.service';
import { JobRate } from 'src/app/core/models/jobRate';
import { CompanyContactDetails } from 'src/app/core/models/companyContactDetails';
import { ModalConfig } from 'src/app/core/models/modalConfig';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { AngularModalService } from 'src/app/core/services/modal.service';
import { AddNewCompanyDetailsModalComponent } from './add-new-company-details/add-new-company-details-modal/add-new-company-details-modal.component';

@Component({
  selector: 'app-candidate-additional-details',
  templateUrl: './candidate-additional-details.component.html',
  styleUrls: ['./candidate-additional-details.component.css']
})
export class CandidateAdditionalDetailsComponent implements OnInit {
  @ViewChild('additionalDetailsForm', null) additionalDetailsForm: NgForm;

  @Input() context;
  @Input() additionalDetails;
  @Input() showSalary
  @Input() readOnly
  @Output() saveCallback = new EventEmitter<any>();
  @Output() cancelCallback = new EventEmitter<any>();
  @Output() refreshCandidateProfileCallback = new EventEmitter<any>();

  addNewCompanyModal: BsModalRef;
  MESSAGECONSTANTS:any = MESSAGECONSTANTS;

  // variables
  salaryDurationOptions: any = [];
  currencyCodes: any = [];
  selectedSponsorships: any = [];
  sponsorshipList: any = [];
  workLocationList: any = [];
  selectedWorkLocations: any = [];
  additionalDetailsCopy: any;
  preferredJobTypeList: any = [];
  selectedPreferredJobType: any = [];
  preferredJobTypeAdditionalList: any = [];
  selectedPreferredJobTypeAdditionalList: any;
  companiesList: any[];
  companiesSearchList: any = [];
  selectedCompany: string;
  companyDetails: any = {};
  candidateUrlSourceTypes: any = [];
  companyId:any;
  companyContactDetailsCopy:any;
  // Flags
  isPreferredJobTypeFullTime: boolean = false;
  isPreferredJobTypeNotFullTime: boolean = false;
  savingFlag: boolean;
  isWorkLocationsSet: boolean = false;
  isPreferredJobTypeSet: boolean = false;
  isAddNewCompanyDetails: boolean = false;
  isCompanySelected: boolean = false;
  isSuperUser: boolean = false;

  constructor(@Inject('$rootScope') public $rootScope, private jobService: JobService,
    private alertsAndNotificationsService: AlertAndNotificationsService, private authService: AuthService,
    private candidateService: CandidateService, private modalService: BsModalService, private companyService: CompanyService,
    public angularModalService: AngularModalService) { }

  ngOnInit() {
    this.companyId = this.authService.getUserDetails().company.companyId;
    this.isSuperUser = this.authService.getUserDetails().roleScreenRef.role.name === '4dot5SuperUser';

    if (!this.additionalDetails.workLocationIds && this.additionalDetails.workLocations) {
      this.additionalDetails.workLocationIds = this.additionalDetails.workLocations.map(location => location.id);
    }
    this.additionalDetailsCopy = _.cloneDeep(this.additionalDetails);
    this.getSalaryDurationOptions();
    this.getCurrencyCodes();
    this.getAllSponsorshipTypes(() => {
      this._setSponsorshipType();
    });
    this.getAllWorkLocations(() => {
      if (this.additionalDetails.workLocationIds) {
        this._setWorkLocations();
      } else {
        this.isWorkLocationsSet = true;
      }
    });
    this.getAllPreferredJobTypes(() => {
      this._getAllC2CCompanies(() => {
        this._getAllJobSubTypes(() => {
          this._setPreferredJobTypes();
        })
      })
    });
    this.candidateUrlSourceTypes = ["LinkedIn", "Personal", "Git", "Other"];
    this._setAdditionalUrlList();
  }

  _setSalaryAndRateType() {
    if (!this.additionalDetails.preferredJobTypeDetails.salary) {
      this.onSalaryTypeChange("SPECIFIC");
    }
    if (!this.additionalDetails.preferredJobTypeDetails.rate) {
      this.onRateTypeChange("SPECIFIC");
    }
    this.additionalDetailsCopy = _.cloneDeep(this.additionalDetails);
  }

  onSalaryTypeChange(salaryType) {
    if (!this.additionalDetails.preferredJobTypeDetails.salary || !this.additionalDetails.preferredJobTypeDetails.salary.salaryDuration) {
      this.additionalDetails.preferredJobTypeDetails.salary = {...new JobSalary()};
      this.additionalDetails.preferredJobTypeDetails.salary.currency = "USD";
      this.additionalDetails.preferredJobTypeDetails.salary.salaryType = salaryType;
    }
  }

  onRateTypeChange(rateType) {
    if (!this.additionalDetails.preferredJobTypeDetails.rate || !this.additionalDetails.preferredJobTypeDetails.rate.rateDuration) {
      this.additionalDetails.preferredJobTypeDetails.rate = {...new JobRate()};
      this.additionalDetails.preferredJobTypeDetails.rate.currency = "USD";
      this.additionalDetails.preferredJobTypeDetails.rate.rateType = rateType;
    }
  }

  async getSalaryDurationOptions() {
    await this.jobService.getSalaryDurationOptions((data) => {
      this.salaryDurationOptions = data;
      this.salaryDurationOptions = this.salaryDurationOptions.filter(item => item.value != 'PER_YEAR');
      this.salaryDurationOptions.map((item) => {
        item.name = item.name.slice(4);
      });
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  async getCurrencyCodes() {
    await this.jobService.getCurrencyCodes((data) => {
      this.currencyCodes = data;
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  async getAllSponsorshipTypes(successCallback) {
    await this.jobService.getAllSponsorshipTypes((data) => {
      this.sponsorshipList = data;
      if (successCallback) {
        successCallback();
      }
    }, (error) => {
      // Add after angular conversion
      // this.authService.isOnline
      if (this.$rootScope.isOnline) {
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      }
    });
  }

  async getAllWorkLocations(successCallback) {
    await this.jobService.getWorkLocations((data) => {
      this.workLocationList = data;

      if (successCallback) {
        successCallback();
      }
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  async getAllPreferredJobTypes(successCallback) {
    await this.jobService.getAllJobTypes((data) => {
      this.preferredJobTypeList = data;
      if (successCallback) {
        successCallback();
      }
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  _getAllJobSubTypes(successCallback) {
    this.jobService.getAllJobSubTypes((data) => {
      this.preferredJobTypeAdditionalList = data;
      if (successCallback) {
        successCallback();
      }
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    })
  }

  _getAllC2CCompanies(successCallback) {
    this.companyService.getAllC2CCompanies(this.companyId, (data) => {
      this.companiesList = data;
      this._setCompaniesSearchList(this.companiesList);
      if (successCallback) {
        successCallback();
      }
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    })
  }

  _setCompaniesSearchList(companiesList) {
    this.companiesSearchList = [];
    this.companiesSearchList = companiesList;
  }

  _setWorkLocations() {
    this.isWorkLocationsSet = false;
    this.workLocationList.map(workLocation => {
      if (this.additionalDetails.workLocationIds.includes(workLocation.id))
        this.selectedWorkLocations.push(workLocation);
    });
    this.isWorkLocationsSet = true;
  }

  _setPreferredJobTypes() {
    this.isPreferredJobTypeSet = false;

    if (!this.additionalDetails.preferredJobTypeDetails) {
      this.additionalDetails.preferredJobTypeDetails = {};
      this.additionalDetails.preferredJobTypeDetails.jobType = [];
    } else {
      this.selectedPreferredJobType = [];
      this.additionalDetails.preferredJobTypeDetails.jobType.map((jobType) => {
        this.selectedPreferredJobType.push(this.preferredJobTypeList.filter((job) => job.value === jobType.jobType)[0]);
        if((jobType.jobType === 'CONTRACTTOHIRE' || jobType.jobType === 'CONTRACTOR') && jobType.jobSubType) {
          this.selectedPreferredJobTypeAdditionalList = this.preferredJobTypeAdditionalList.filter((jobTypeAdditionalList) => jobTypeAdditionalList.value === jobType.jobSubType)[0];
        }
        if((jobType.jobType === 'CONTRACTTOHIRE' || jobType.jobType === 'CONTRACTOR')) {
          if(jobType.contact) {
            this.companyDetails.companyId = jobType.companyId ? jobType.companyId : null;
            this.isAddNewCompanyDetails = true;
            this.selectedCompany = jobType.contact.companyName;
            this.companyDetails.companyContactDetails = jobType.contact;
          } else {
            this.companyDetails.companyContactDetails = {...new CompanyContactDetails()};
            this.companyDetails.companyId = null;
          }
        }
      });
      this.onPreferredJobTypeSelected();
    }

    // Set salary
    this._setSalaryAndRateType();
    this.isPreferredJobTypeSet = true;
  }

  onWorkLocationsSelected() {
    this.additionalDetails.workLocationIds = this.selectedWorkLocations.map(workLocation => workLocation.id);
  }

  onPreferredJobTypeSelected() {
    this.isPreferredJobTypeFullTime = false;
    this.isPreferredJobTypeNotFullTime = false;
    this.selectedPreferredJobType.forEach((item) => {
      if (item.value === 'FULLTIME') {
        this.isPreferredJobTypeFullTime = true;
      } else {
        this.isPreferredJobTypeNotFullTime = true;
      }
    })

    if (this.checkPreferredJobType('CONTRACTTOHIRE') || this.checkPreferredJobType('CONTRACTOR')) {
      if (!this.selectedPreferredJobTypeAdditionalList) {
        this.selectedPreferredJobTypeAdditionalList = this.preferredJobTypeAdditionalList[0];
      }
    } else {
      this.selectedPreferredJobTypeAdditionalList = null;
    }

    if (!this.selectedPreferredJobTypeAdditionalList || !this.selectedPreferredJobType) {
      if (this.selectedCompany) {
        this.isAddNewCompanyDetails = true;
      } else {
        this.isAddNewCompanyDetails = false;
      }
    }
  }

  onPreferredJobTypeAdditionalSelected() {
    this.isAddNewCompanyDetails = false;
    if (!this.selectedPreferredJobTypeAdditionalList || this.selectedPreferredJobTypeAdditionalList.length === 0 || this.selectedPreferredJobTypeAdditionalList.value != 'CORPTOCORP') {
      this.isAddNewCompanyDetails = false;
    } else if (this.selectedCompany) {
      this.isAddNewCompanyDetails = true;
    }
  }

  setPreferredJobTypeFilter() {
    return this.selectedPreferredJobType.filter((jobType) => jobType.value != 'FULLTIME');
  }

  selectAllWorkLocations() {
    this.selectedWorkLocations = [...this.workLocationList];
    this.onWorkLocationsSelected();
  }

  selectAllPreferredJobType() {
    this.selectedPreferredJobType = [...this.preferredJobTypeList];
    this.onPreferredJobTypeSelected();
  }

  deSelectAllWorkLocations() {
    this.selectedWorkLocations = [];
    this.onWorkLocationsSelected();
  }

  deSelectAllPreferredJobType() {
    this.selectedPreferredJobType = [];
    this.onPreferredJobTypeSelected();
  }

  onSponsorshipSelected() {
    this.additionalDetails.sponsorships = [];
    if (this.selectedSponsorships) {
      this.additionalDetails.sponsorships.push(this.selectedSponsorships.value);
    }
  }

  onCompanySelected(event: TypeaheadMatch): void {
    this.isAddNewCompanyDetails = true;
    if (this.selectedCompany) {
      this.companyDetails.companyContactDetails = this.companiesList.filter((company) => {
        if (event.item.contactId) {
          return company.contactId === event.item.contactId;
        } else {
          return company.companyName === event.item.companyName;
        }
      })[0];
      this.companyDetails.companyId = null;
    } else {
      this.isAddNewCompanyDetails = false;
      this.companyDetails.companyId = null;
      this.companyDetails.companyContactDetails = {...new CompanyContactDetails()};
    }
  }

  onSelectCompanyFieldChange() {
    if (this.selectedCompany.length === 0) {
      this.isAddNewCompanyDetails = false;
      this.companyDetails.companyContactDetails = { ...new CompanyContactDetails() };
    } else {
      this.isCompanySelected = false;
    }
  }

  _setPreferredJobTypesSelected() {
    this.additionalDetails.preferredJobTypeDetails.jobType = [];
    this.selectedPreferredJobType.map((item) => {
      if (item.value === 'CONTRACTTOHIRE' || item.value === 'CONTRACTOR') {
        if (this.selectedPreferredJobTypeAdditionalList) {
          let contactObject: any = {};
          contactObject.jobType = item.value;
          contactObject.jobSubType = this.selectedPreferredJobTypeAdditionalList.value;
          contactObject.companyId = this.companyDetails.companyId;
          if(this.selectedPreferredJobTypeAdditionalList.value === 'CORPTOCORP') {
              contactObject.contact = this.companyDetails.companyContactDetails;
          } else {
            contactObject.contact = null;
          }
          this.additionalDetails.preferredJobTypeDetails.jobType.push(contactObject);
        } else {
          this.additionalDetails.preferredJobTypeDetails.jobType.push({ jobType: item.value });
        }
      } else {
        this.additionalDetails.preferredJobTypeDetails.jobType.push({ jobType: item.value });
      }
    })
  }

  _setSponsorshipType() {
    if(this.additionalDetails.sponsorships) {
      this.sponsorshipList.map((item) => {
        if (this.additionalDetails.sponsorships[0] === item.value) {
          this.selectedSponsorships = item;
        }
      })
    }
  }

  _setAdditionalUrlList() {
    if (!this.additionalDetails.urls) {
      this.additionalDetails.urls = [];
    } else {
      for (let i = 0; i < this.additionalDetails.urls.length; i++) {
        this.additionalDetails.urls[i].id = i + 1;
        this.additionalDetails.urls[i].isUrlSourceType = false;
        this.additionalDetails.urls[i].isUrlLink = false;
      }
    }
  }

  _checkIfSourceTypeDuplicate(additionalUrl) {
    let sourceType = false;
    if (additionalUrl.urlType === 'Other') {
      sourceType = false;
    } else {
      this.additionalDetails.urls.forEach((urlData) => {
        if (urlData.urlType === additionalUrl.urlType && urlData.id !== additionalUrl.id) {
          sourceType = true;
        }
      })
    }
    return sourceType;
  }

  candidateSourceTypeChanged(additionalUrl, index) {
    if (this._checkIfSourceTypeDuplicate(additionalUrl)) {
      let title = "Duplicate"
      let message = additionalUrl.urlType + " has been already added.";
      this.alertsAndNotificationsService.showAlertWithCallBack(title, message, "warning", () => {
        this.additionalDetails.urls[index].urlType = "";
        this.angularModalService.addModalOpenClassToBodyIfAnyModalIsOpen();
      });
    } else {
      additionalUrl.isUrlSourceType = false;
    }
  }

  candidateUrlLinkChanged(additionalUrl, index) {
    additionalUrl.isUrlLink = "" ? true : false;
  }

  addUrl() {
    this.additionalDetails.urls.push({
      id: this.additionalDetails.urls.length + 1,
      urlType: '',
      urlLink: ''
    })
  }

  deleteUrl(additionalUrl) {
    let title = "Warning!";
    let message = `Are you sure you want to delete ${additionalUrl.urlType}?`;
    this.alertsAndNotificationsService.showConfirm(title, message, "warning", false, () => {
      this.additionalDetails.urls = this.additionalDetails.urls.filter((urlData) => additionalUrl.id != urlData.id);
      this.angularModalService.addModalOpenClassToBodyIfAnyModalIsOpen();
    }, () => {
      this.angularModalService.addModalOpenClassToBodyIfAnyModalIsOpen();
    })
  }

  _setAdditionalUrlSavedData() {
    this.additionalDetails.urls.map((urlData) => {
      delete urlData.id;
      delete urlData.isUrlSourceType;
      delete urlData.isUrlLink;
    })
  }

  _checkIfAdditionalUrlSelected() {
    let isEmptyUrlPresent;

    if (this.additionalDetails.urls && this.additionalDetails.urls.length > 0) {
      for (let i = 0; i < this.additionalDetails.urls.length; i++) {
        if (this.additionalDetails.urls[i].urlType === "" || this.additionalDetails.urls[i].urlLink === "") {
          isEmptyUrlPresent = false;
          break;
        } else {
          isEmptyUrlPresent = true;
        }
      }

      if (!isEmptyUrlPresent) {
        this.additionalDetails.urls.map(url => {
          url.isUrlSourceType = url.urlType === "" ? true : false;
          url.isUrlLink = url.urlLink === "" ? true : false;
        })
      }
    } else {
      isEmptyUrlPresent = true;
    }

    return isEmptyUrlPresent;
  }

  _checkIfCompanySelected() {
    this.isCompanySelected = false;
    if (this.selectedPreferredJobTypeAdditionalList && this.selectedPreferredJobTypeAdditionalList.value === 'CORPTOCORP') {
      if (!this.selectedCompany || this.selectedCompany.length === 0 || !this.isAddNewCompanyDetails) {
        this.isCompanySelected = true;
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  _checkMinAndMaxSalary() {
    if(this.additionalDetails.preferredJobTypeDetails.salary.salaryType == 'RANGE') {
      if(this.additionalDetails.preferredJobTypeDetails.salary.maxSalary <= this.additionalDetails.preferredJobTypeDetails.salary.minSalary) {
        this.additionalDetailsForm.control.get('maxSalary').setErrors({invalid: true});
      }
    } else if(this.additionalDetails.preferredJobTypeDetails.salary.salaryType == 'SPECIFIC') {
      this.additionalDetails.preferredJobTypeDetails.salary.minSalary = this.additionalDetails.preferredJobTypeDetails.salary.maxSalary;
    }

    if(this.additionalDetails.preferredJobTypeDetails.rate.rateType == 'RANGE') {
      if(this.additionalDetails.preferredJobTypeDetails.rate.maxRate <= this.additionalDetails.preferredJobTypeDetails.rate.minRate) {
        this.additionalDetailsForm.control.get('maxRate').setErrors({invalid: true});
      }
    } else if(this.additionalDetails.preferredJobTypeDetails.rate.rateType == 'SPECIFIC') {
      this.additionalDetails.preferredJobTypeDetails.rate.minRate = this.additionalDetails.preferredJobTypeDetails.rate.maxRate;
    }
  }

  _checkIfUrlLinkUnique() {
    let uniqueListArray;
    let uniqueUrlExists;
    if (this._checkIfAdditionalUrlSelected()) {
      if (this.additionalDetails.urls.length > 1) {
        uniqueListArray = Array.from(new Set(this.additionalDetails.urls.map(item => { return item.urlLink })));
        uniqueUrlExists = uniqueListArray.length === this.additionalDetails.urls.length ? true : false;

        if (!uniqueUrlExists) {
          this.alertsAndNotificationsService.showBannerMessage("Duplicate Url's cannot be added", "danger");
        }

      } else {
        uniqueUrlExists = true;
      }
    } else {
      uniqueUrlExists = false;
    }

    return uniqueUrlExists;
  }

  updateAdditionalDetails() {
    this._setPreferredJobTypesSelected();
    this._checkMinAndMaxSalary();
    if (this.additionalDetailsForm.valid && this._checkIfCompanySelected() && this._checkIfUrlLinkUnique()) {
        if (this._hasAdditionalDetailsChanged()) {
          this._updateContactAdditionalDetails();
        } else {
          this.alertsAndNotificationsService.showBannerMessage("Nothing to update", "info");
        }
    }
  }

  _updateContactAdditionalDetails() {
    this._setAdditionalUrlSavedData();
    if(!this.additionalDetails.workLocationIds || this.additionalDetails.workLocationIds.length === 0) {
      this.additionalDetails.workLocations = [];
    }
    let additionalDetails = _.cloneDeep(this.additionalDetails);
    if (this.selectedPreferredJobType.length > 0) {
      additionalDetails.preferredJobTypeDetails.salary = this.isPreferredJobTypeFullTime ? additionalDetails.preferredJobTypeDetails.salary : null;
      additionalDetails.preferredJobTypeDetails.rate = this.isPreferredJobTypeNotFullTime ? additionalDetails.preferredJobTypeDetails.rate : null;
    } else {
      delete additionalDetails.preferredJobTypeDetails;
    }
    this.savingFlag = true;
    this.candidateService.addOrUpdateCandidateAdditionalDetails(additionalDetails, (data) => {
      this._setAdditionalUrlList();
      this.additionalDetailsCopy = _.cloneDeep(this.additionalDetails);
      this.savingFlag = false;
      this._setPreferredJobTypes();
      this._setWorkLocations();
      if(this.refreshCandidateProfileCallback) {
        this.refreshCandidateProfileCallback.emit();
      }
      this.$rootScope.$emit("refreshCandidateCard", { candidateId: this.additionalDetails.candidateId, type:this.context });
      this.alertsAndNotificationsService.showBannerMessage(data.message, "success");
    }, (error) => {
      this.savingFlag = false;
      this._setPreferredJobTypes();
      this._setAdditionalUrlList();
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  _hasAdditionalDetailsChanged() {
    return !_.isEqual(this.additionalDetails, this.additionalDetailsCopy);
  }

  cancel() {
    if (this.cancelCallback) {
      this.cancelCallback.emit();
    }
  }

  checkPreferredJobType(jobType) {
    let isJobTypePresent;

    for (let i = 0; i < this.selectedPreferredJobType.length; i++) {
      if (this.selectedPreferredJobType[i].value === jobType) {
        isJobTypePresent = true;
        break;
      } else {
        isJobTypePresent = false;
      }
    }

    return isJobTypePresent;
  }

  addEditCompanyDetails(state) {
    this.isAddNewCompanyDetails = false;
    const config = new ModalConfig();
    const modalData: any = {};
    modalData.state = state;

    if (state === 'add') {
      modalData.title = "Add New Company Details";
      modalData.contact = null;
    } else {
      modalData.title = "Edit Company Details";
      modalData.contact = this.companyDetails.companyContactDetails;
      this.companyContactDetailsCopy = _.cloneDeep(this.companyDetails.companyContactDetails);
    }

    config.class = "modal-lg custom-ngx-modal";
    config.initialState = modalData;
    this.addNewCompanyModal = this.modalService.show(AddNewCompanyDetailsModalComponent, config);
    this.addNewCompanyModal.content.onClose.subscribe(result => {
      if (result != null) {
        this.isAddNewCompanyDetails = true;
        if (state === 'edit') {
          if (result.contactId) {
            this.companiesList = this.companiesList.filter((company) => company.contactId != result.contactId);
          } else {
            this.companiesList = this.companiesList.filter((company) => company.companyName != this.companyDetails.companyContactDetails.companyName);
          }
        }
        this.selectedCompany = result.companyName;
        this.companyDetails.companyContactDetails = result;
        this.companiesList.push(result);
        this._setCompaniesSearchList(this.companiesList);
      } else {
        if(state === 'edit') {
          this.isAddNewCompanyDetails = true;
          this.companyDetails.companyContactDetails = this.companyContactDetailsCopy;
        }
      }
    })
  }
}

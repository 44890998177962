<style>
	#page-wrapper{
		min-height: 90vh;
	}

	.rules-icon{
		position: absolute;
		right: -20px; 
		top: 15px;
		cursor: pointer;
	}

	.pt-0{
		padding-top: 0!important;
	}

	.error-message-container{
		position: relative;
		bottom: 20px;
	}

	.border-danger{
		border: 1px solid red !important;
	}
</style>
<div id="content-wrapper">
	<div class="container">
		<div class="row">
			<div class="col-lg-12">
				<div class="slide-main-container">
					<div class="container slide-main-animation">
						<div class="row">
							<div class="col-xs-12">
								<div id="login-box" *ngIf="showValidation">
									<div id="login-box-holder">
										<div class="row">
											<div class="col-xs-12">
												<header id="login-header">
													<div id="login-logo">
														<img ng-src="assets/app-content/img/logo-symbol.png" alt="" />
													</div>
												</header>
												<div id="login-box-inner" class="with-heading">
													<p *ngIf="message">{{message}}</p>
													<form #resetPasswordForm="ngForm" role="form" (ngSubmit)="resetPasswordForm.form.valid && resetPassword()" novalidate>
														<!-- New Password -->
														<label for="newPassword">New Password</label>
														<i class="fas fa-info-circle text-primary cursor-pointer" [ngClass]="{'red': newPassword.errors?.minlength || newPassword.errors?.maxlength || (!resetPasswordForm.pristine && newPassword.errors?.pattern)}"
															*ngIf="passwordRestrictions.lengthRestrictionEnabled || passwordRestrictions.complexityRestrictionEnabled"
															popover-title="Rules" uib-popover-template="'password-restrictions.html'" popover-placement="top auto"
															popover-trigger="'outsideClick'" popover-append-to-body="true" style="display: inline-block;"></i>
														<div class="input-group reset-pass-input pt-0">
															<span class="input-group-addon"><i class="fa fa-key"></i></span>
															<input #newPassword="ngModel" class="form-control" type="password" placeholder="New Password" name="newPassword" [ngClass]="{'border-danger': newPassword.errors?.minlength || newPassword.errors?.maxlength || (!resetPasswordForm.dirty && newPassword.errors?.pattern)}"
																[(ngModel)]="user.newPassword" minlength="parsedRestrictions.minimumLength" maxlength="parsedRestrictions.maximumLength" [pattern]="validationPattern" (keyup)="compare()" required>
														</div>
														<div class="error-message-container">
															<div class="error" *ngIf="resetPasswordForm.submitted && newPassword.errors?.required">
																<small class="error">
																	{{MESSAGECONSTANTS.RESETPASSWORD.NEWPASSWORD_MANDATORY}}</small>
																<!-- <small class="error" *ngIf="form.resetPasswordForm.newpassword.$error.minlength">
																	 Password should have atleast {{parsedRestrictions.minimumLength}} characters.</small>
																<small class="error" *ngIf="form.resetPasswordForm.newpassword.$error.maxlength">
																	Password should have at most {{parsedRestrictions.maximumLength}} characters.</small> -->
															</div>
															<div class="error" *ngIf="!resetPasswordForm.dirty && !newPassword.errors?.required && !newPassword.errors?.pattern && !newPassword.errors?.minlength && !newPassword.errors?.maxlength">
																<small class="error" *ngIf="newPassword.errors?.unMatched">{{MESSAGECONSTANTS.RESETPASSWORD.PASSWORDS_SAME}}</small>
															</div>
															<div class="error" *ngIf="!resetPasswordForm.dirty && (newPassword.errors?.pattern || newPassword.errors?.minlength || newPassword.errors?.maxlength)">
																<small class="error">The password entered does not follow the rules.</small>
															</div> 
														</div>

														<!-- Confirm Password -->
														<label for="confirmNewPassword">Confirm Password</label>
														<div class="input-group reset-pass-input pt-0">
															<span class="input-group-addon"><i
																class="fa fa-key"></i></span>
                                                                <input #confirmNewPassword="ngModel" class="form-control" type="password" placeholder="Confirm Password"
																name="confirmNewPassword" [(ngModel)]="user.verifyPassword" (keyup)="compare()" required>
														</div>
														<div class="error-message-container">
															<div class="error" *ngIf="confirmNewPassword.errors">
																<small class="error" *ngIf="resetPasswordForm.submitted  && confirmNewPassword.errors?.required">
																	{{MESSAGECONSTANTS.RESETPASSWORD.CONFIRMNEWPASSWORD_MANDATORY}}</small>
															</div>
															<!-- <div class="error" *ngIf="!form.resetPasswordForm.$pristine && !isNewAndConfirmPasswordEqual && user.verifyPassword.length>0">
																<small class="error">{{MESSAGECONSTANTS.RESETPASSWORD.PASSWORDSNOTEQUAL}}</small>
															</div> -->
															<div class="error" *ngIf="user.verifyPassword && user.verifyPassword.length>0 && !newPassword.errors?.pattern && user.newPassword && user.newPassword.length>= parsedRestrictions.minimumLength && user.newPassword.length <= parsedRestrictions.maximumLength">
																<small class="error" *ngIf="!isNewAndConfirmPasswordEqual">{{MESSAGECONSTANTS.RESETPASSWORD.PASSWORDSNOTEQUAL}}</small>
															</div>	
	
															<div class="error" *ngIf="errorMessage && confirmNewPassword.invalid">
																<small class="error">
																	{{errorMessage}}</small>
															</div>
														</div>
														<!-- Submit -->
														<div class="row">
															<div class="col-xs-12">
																<button type="submit" *ngIf="passwordChangeType !== 'CREATE_PASSWORD'" [disabled] = "isSettingPassword || !isNewAndConfirmPasswordEqual" class="btn btn-success col-xs-12">{{MESSAGECONSTANTS.BUTTONS.RESET_PASSWORD_PAGE.RESET_PASSWORD}}</button>
																<button type="submit" *ngIf="passwordChangeType == 'CREATE_PASSWORD'" [disabled] = "isSettingPassword || !isNewAndConfirmPasswordEqual" class="btn btn-success col-xs-12">SET PASSWORD</button>
															</div>
														</div>
														<div class="row">
															<div class="col-xs-12">
																<br/> <a href="" (click)="goToLoginPage()" id="login-forget-link" class="forgot-link">{{MESSAGECONSTANTS.RESETPASSWORD.BACK_TO_LOGIN}}</a>
															</div>
														</div>
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-xs-12 loading-spinner text-center" style="padding-top:180px;" *ngIf="showValidation">
									<span>Loading</span>
									<span class="fa fa-spinner fa-spin"></span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<script type="text/ng-template" id="password-restrictions.html">
	<div class="validation-rules">
		<p>The password has to have:</p>
		<ol style="padding-left: 20px;">
			<li ng-repeat="restriction in parsedRestrictions.restrictionsList">
				{{restriction}}
			</li>
		</ol>
	</div>
</script>
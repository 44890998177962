import { Component, OnInit, Inject } from '@angular/core';
import { UtilityService } from 'src/app/core/services/utility.service';

@Component({
  selector: 'app-assessments-sync-message',
  templateUrl: './assessments-sync-message.component.html',
  styleUrls: ['./assessments-sync-message.component.css']
})
export class AssessmentsSyncMessageComponent implements OnInit {

  syncMessage:any;

  showSyncMessage:boolean = false;
  displayNavigationToAssessments:boolean = false;

  constructor(@Inject('$state') public $state, private utilityService:UtilityService) { }

  ngOnInit() {
    this.listenToAssessmentsSync();
  }

  listenToAssessmentsSync() {
    this.utilityService.receiveAssessmentsSyncMessage().then((message) => {
      this.displayNavigationToAssessments = !message.includes('Syncing Assessments');
      if (this.displayNavigationToAssessments) {
        this.syncMessage = message + " Go to Assessments";
      } else {
        this.syncMessage = message;
      }
      this.showSyncMessage = true;
    });
  }

  goToAssessments() {
    if (this.$state.current.name === 'missioncontrol.assessmentManagement') {
      this.$state.reload();
    } else {
      this.$state.go('missioncontrol.assessmentManagement', { reload: true });
    }
    this.showSyncMessage = false;
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoleEntityScopePageComponent } from './role-entity-scope-page.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [RoleEntityScopePageComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  entryComponents: [RoleEntityScopePageComponent]
})
export class RoleEntityScopePageModule { }

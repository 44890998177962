import { downgradeComponent } from "@angular/upgrade/static";
import { LoginPageComponent } from "./login-page.component";

export const LoginPageAjsModule = angular
    .module('loginPage', [])
    .directive('loginPage', downgradeComponent({ component: LoginPageComponent }))
    .config(
        ['$stateProvider', ($stateProvider) => {
            $stateProvider.state('login', {
                url: '/login',
                component: LoginPageComponent
            });
        }
        ])
    .name;

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageMasterListsPageComponent } from './manage-master-lists-page.component';
import { TabsModule } from 'ngx-bootstrap';
import { ManageSkillsPageModule } from '../manage-skills-page/manage-skills-page.module';
import { ManageRequisitionEntitiesPageModule } from '../manage-requisition-entities-page/manage-requisition-entities-page.module';

@NgModule({
  declarations: [ManageMasterListsPageComponent],
  imports: [
    CommonModule,
    TabsModule.forRoot(),
    ManageSkillsPageModule,
    ManageRequisitionEntitiesPageModule
  ],
  exports: [ManageMasterListsPageComponent],
  entryComponents: [ManageMasterListsPageComponent]
})
export class ManageMasterListsPageModule { }

export class NewHierarchySkill {
    id: any;
    skills: any;
    parentId: any;
    skillName: any;
    isExpanded: boolean = true;
    subSkills: any = [];
    constructor(id, parentId, skillName, skills) {
        this.id = id;
        this.skills = skills;
        this.parentId =  parentId;
        this.skillName = skillName;
    }
}
<div class="ngx-modal-header">
    <h4 class="modal-title pull-left">{{modalData.title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <app-add-new-company-details (saveCallback)="saveNewCompanyDetails($event)" (cancelCallback)="closeModal()"
        [readOnly]="false" elementId="candidateAddNewDetails" [contactDetails]="modalData.contact">
    </app-add-new-company-details>
</div>
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-external-notes',
  templateUrl: './external-notes.component.html',
  styleUrls: ['./external-notes.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ExternalNotesComponent implements OnInit {

  @Input() form;
  @Input() note;
  @Input() externalNote;
  @Input() copyNote;
  @Input() addNoteLabel;
  @Input() copyNoteLabel;
  @Input() externalNoteLabel;
  @Output() onExternalNoteChange = new EventEmitter<any>();
  @Output() onCopyNoteChange = new EventEmitter<any>();

  addExternalNotes: any;

  constructor() { }

  ngOnInit() {
    this.addExternalNotes = false;
    this.copyNote = false;

    if (this.addNoteLabel == undefined) {
      this.addNoteLabel = "Add External Notes";
    }
    if (this.copyNoteLabel == undefined) {
      this.copyNoteLabel = "Copy to External Notes";
    }
    if (this.externalNoteLabel == undefined) {
      this.externalNoteLabel = "External Notes";
    }
    if (this.externalNote !== undefined && !_.isNull(this.externalNote) && this.externalNote.length > 0) {
      this.addExternalNotes = true;
    }
  }

  ngOnChanges(changes) {
    if (changes) {
      if (this.copyNote && changes.note) {
        this.externalNote = changes.note.currentValue;
        this._externalNoteChanged();
      }
    }
  }

  toggleAddExternalNotes() {
    if (!this.addExternalNotes) {
      if (this.copyNote) {
        this.externalNote = this.note;
      } else {
        this.externalNote = "";
      }
      this._externalNoteChanged();
    }
  }

  toggleCopyToExternalNotes() {
    if (this.copyNote) {
      this.externalNote = this.note;
    } else {
      this.externalNote = "";
    }
    this._externalNoteChanged();
    this._copyNoteChanged();
  }

  onExternalNoteChanged() {
    this.copyNote = false;
    this._externalNoteChanged();
    this._copyNoteChanged();
  }

  _externalNoteChanged() {
    if (this.onExternalNoteChange) {
      this.onExternalNoteChange.emit({ externalNote: this.externalNote });
    }
  }

  _copyNoteChanged() {
    if (this.onCopyNoteChange) {
      this.onCopyNoteChange.emit({ copyNote: this.copyNote });
    }
  }

}

export class CandidateJobMatchCard {
    id:any = null;
    firstName:any;
    lastName:any;
    name:any;
    workphone:any;
    mobilephone:any;
    email:any;
    im:any;
    jobMatchTitle:any;
    jobMatchRequisitionNum:any;
    jobMatchRequisitionStatus:any;
    jobMatchScore:any;
    jobMatchStatus:any;
    recruitersList:any = [];
    primaryRecruiter:any;
    jobMatchClientName:any;
    jobMatchClientId:any;
    jobMatchCurrentState:any;
    noOfDaysSinceLastUpload:any;
    jobMatchDate:any;
    numberOfFilledOpenings:any;
    totalOpenings:any;
    numberOfOpenOpenings:any = 0;
    candidateStatus:any = "Active";
    actions:any;
    currentStep:any;
    selectedFlag:boolean = false;
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecentRequisitionsComponent } from './recent-requisitions/recent-requisitions.component';
import { RequisitionCardComponentWrapper } from './recent-requisitions/requisition-card.component.wrapper';

@NgModule({
  declarations: [RecentRequisitionsComponent, RequisitionCardComponentWrapper],
  imports: [
    CommonModule
  ],
  exports: [RecentRequisitionsComponent, RequisitionCardComponentWrapper]
})
export class RequisitionsModule { }

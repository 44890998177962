import { Component, OnInit, Input, Output, EventEmitter, Inject, ViewChild, ElementRef } from '@angular/core';
import { ActivityFilter } from 'src/app/core/models/activityFilter';
import { MeetingMode } from 'src/app/core/utils/meeting-mode';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { CandidateService } from 'src/app/core/services/candidate.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { UserService } from 'src/app/core/services/user.service';
import { RequisitionService } from 'src/app/public/app/services/requisitionService';
import { EntityActivitiesService } from 'src/app/core/services/entity-activities.service';
import { SharedAjsService } from 'src/app/public/app/services/sharedAjsService';

@Component({
  selector: 'app-recent-activities',
  templateUrl: './recent-activities.component.html',
  styleUrls: ['./recent-activities.component.css']
})
export class RecentActivitiesComponent implements OnInit {

  @Input() context;
  @Input() candidateId;
  @Input() jobMatchId;
  @Input() userId;
  @Input() requisitionId;
  @Input() isSharedRequisition;
  @Input() recentActivitiesControls;
  @Input() isActivityClicked;
  @Input() activityMap;
  @Output() showAllCallback = new EventEmitter<any>();
  @Output() activitiesLoadedCallback = new EventEmitter<any>();

  isLoading: boolean = true;
  companyId: any;
  companyName: any;
  activities: any = [];
  count: any;

  constructor(
    @Inject('$rootScope') public $rootScope, 
    private candidateService: CandidateService,
    @Inject('requisitionService') public requisitionService: RequisitionService,
    private userService: UserService, 
    private companyService: CompanyService, 
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private activitiesService: EntityActivitiesService,
    @Inject('sharedAjsService') public sharedAjsService: SharedAjsService
    ) { }

  ngOnInit() {
    this.isLoading = true;
    this.companyId = this.$rootScope.userDetails.company.companyId;
    this.companyName = this.$rootScope.userDetails.company.companyName;
    if (!this.activities) {
      this.activities = [];
    }
    if (!this.context) {
      this.context = 'global';
    }
    if (_.isUndefined(this.recentActivitiesControls)) {
      this.recentActivitiesControls = {};
    }
    this.recentActivitiesControls.activityClickCount = 0;
    this.count = 0;
  }

  ngOnChanges(changes) {
    if (this.recentActivitiesControls.activityClickCount > 0) {
      this.getActivities();
    }
    this.recentActivitiesControls.activityClickCount++;
  }

  getActivities() {
    let activityFilter = new ActivityFilter();
    activityFilter.companyId = this.companyId;
    activityFilter.page = 1;
    activityFilter.size = 5;
    this.isLoading = true;
    if (this.context === "candidateCard" || this.context === "candidateJobMatchCard") {
      activityFilter.candidateId = this.candidateId;
      if (this.context === "candidateJobMatchCard") {
        activityFilter.jobMatchId = this.jobMatchId;
      }
      this.activities = [];
      this.candidateService.getCandidateActivities(activityFilter, (data) => {
        angular.forEach(data, (val, key) => {
          val.activityDisplayValue = this.activityMap[val.activityType];
          val.miniStatement = this.activityMap[val.activityType];
          // if it final reminder activity
          if (!_.isNull(val.activityMessage)) {
            val.activityDisplayValue = val.activityMessage;
            val.miniStatement = val.activityMessage;
          }
          val.createdBy = val.createdBy.trim();
          val.statement = '';
          if (val.activityType.includes('RE_RUN_SCORE')) {
            val.miniStatement = "Score has changed"
            val.activityDisplayValue = val.miniStatement + " from '<span class='emerald'>" + val.fromState + "</span>' to '<span class='emerald'>" + val.toState + "</span>'";
            if (val.title && val.title != '' && val.requisitionNumber && val.requisitionNumber != '' && this.context === "candidateCard") {
              val.activityDisplayValue = val.activityDisplayValue + " for '<span class='emerald'>" + val.title + ' (' + val.requisitionNumber + ')' + "</span>'";
            }
          }
          if(val.activityType.includes("DOCUMENT")) {
            val.activityDisplayValue = val.miniStatement + "<span> by " + val.createdBy + "</span>";
            val.isDocumentEmailActivity = false;
            if(val.activityType == 'DOCUMENT_EMAILED' || val.activityType == 'DOCUMENT_FAILED') {
              if(val.listOfDocumentsSent || val.listOfUsersDocumentsSent) {
                val.isDocumentEmailActivity = true;
              }
            }
            val.activityType = 'document';
          }
          if (val.activityType.includes("CHANGED") || val.activityType.includes("STATE_CHANGE")) {
            if (_.isNull(val.toState)) {
              val.toState = "-";
            }
            if (val.activityType.includes("CANDIDATE_NAME_CHANGED")
              || val.activityType.includes("CANDIDATE_PHONE_NUMBER_CHANGED")
              || val.activityType.includes("CANDIDATE_EMAIL_CHANGED")
              || val.activityType.includes("CANDIDATE_LOCATION_CHANGED")
              || val.activityType.includes("CANDIDATE_SALARY_CHANGED")
              || val.activityType.includes("CANDIDATE_SPONSORSHIP_CHANGED")
              || val.activityType.includes("CANDIDATE_RELOCATION_CHANGED")
              || val.activityType.includes("CANDIDATE_SPONSORSHIP_NOTE_CHANGED")
              || val.activityType.includes("CANDIDATE_RELOCATION_NOTE_CHANGED")
              || val.activityType.includes("CANDIDATE_WORK_LOCATION_CHANGED")) {
              val.activityDisplayValue = val.miniStatement + " from '<span class='emerald'>" + val.fromState + "</span>' to '<span class='emerald'>" + val.toState + "</span>'";
            }
            val.activityType = 'change';
            if (val.fromState.includes("Assessment") || val.toState.includes("Assessment")) {
              val.activityDisplayValue = "Assessment Status Changed to <span class='emerald'>" + val.toState + "'</span> for the role <span class='emerald'>'" + val.title + "'</span>";
            }
            else if (val.fromState.includes("Phone Screen") || val.toState.includes("Phone Screen")) {
              val.activityDisplayValue = "Phone Screen Status Changed to <span class='emerald'>" + val.toState + "'</span> for the role <span class='emerald'>'" + val.title + "'</span>";
            } else if (val.fromState.includes("Interview") || val.toState.includes("Interview")) {
              val.activityDisplayValue = "Interview Status Changed to <span class='emerald'>" + val.toState + "'</span> for the role <span class='emerald'>'" + val.title + "'</span>";
            }
          }
          if (val.activityType.includes("STEP_CHANGE")) {
            val.activityType = 'change';
            val.activityDisplayValue = "Candidate Moved to <span class='emerald'>'" + val.toState + "'</span> for the role <span class='emerald'>'" + val.title + "'</span>" + " with Job ID <span class='emerald'>'" + val.requisitionNumber + "'</span>";
          } else if (val.activityType.includes("ADDED") || val.activityType.includes("REMINDER") || val.activityType.includes("COMMUNICATION")) {
            if (val.activityType.includes("CANDIDATE_NAME_ADDED")
              || val.activityType.includes("CANDIDATE_PHONE_NUMBER_ADDED")
              || val.activityType.includes("CANDIDATE_EMAIL_ADDED")
              || val.activityType.includes("CANDIDATE_LOCATION_ADDED")
              || val.activityType.includes("CANDIDATE_SALARY_ADDED")
              || val.activityType.includes("CANDIDATE_SPONSORSHIP_ADDED")
              || val.activityType.includes("CANDIDATE_RELOCATION_ADDED")
              || val.activityType.includes("CANDIDATE_SPONSORSHIP_NOTE_ADDED")
              || val.activityType.includes("CANDIDATE_RELOCATION_NOTE_ADDED")
              || val.activityType.includes("CANDIDATE_WORK_LOCATION_ADDED")) {
              val.activityType = 'add';
              val.activityDisplayValue = val.activityDisplayValue + " '<span class='emerald'>" + val.toState + "</span>'";
            } else {
              val.activityType = 'add';
              if (!_.isNull(val.meetingMode)) {
                val.activityDisplayValue = `${val.activityDisplayValue} via <b>${MeetingMode[val.meetingMode]}</b>`;
              }
              val.activityDisplayValue = `${val.activityDisplayValue} for the role <span class='emerald'>'${val.title}'</span> with Job ID <span class='emerald'>'${val.requisitionNumber}'</span>`;
              if (!_.isNull(val.count)) {
                val.activityDisplayValue = val.count + " " + val.activityDisplayValue;
              }
            }
          } else if (val.activityType.includes("ASSIGNED")) {
            val.activityType = 'assign';
            val.activityDisplayValue = val.activityDisplayValue + " for the role <span class='emerald'>'" + val.title + "'</span>" + " with Job ID <span class='emerald'>'" + val.requisitionNumber + "'</span>";
          } else if (val.activityType.includes("REMOVED") || val.activityType.includes("DELETED")) {
            val.activityType = 'remove';
            val.activityDisplayValue = val.activityDisplayValue + " for the role <span class='emerald'>'" + val.title + "'</span>" + " with Job ID <span class='emerald'>'" + val.requisitionNumber + "'</span>";
          } else if (val.activityType.includes("PHONE_SCREEN") || val.activityType.includes("INTERVIEW")) {
            const via = val.meetingMode == null ? "" : ` via <b>${MeetingMode[val.meetingMode]}</b>`;
            val.activityDisplayValue = `${val.activityDisplayValue} ${via} for the role <span class='emerald'>'${val.title}'</span>`;
          }
          // if meeting count exists and its not the final reminder activity
          if (!_.isNull(val.meetingCount) && _.isNull(val.activityMessage)) {
            val.activityDisplayValue = val.meetingCount + " " + val.activityDisplayValue;
          }
          val.activityDisplayValue = val.activityDisplayValue.replace("Candidate Note", "Note");
          val.activityDisplayValue = val.activityDisplayValue.replace("Matched Candidate Recruiter", "Recruiter");
          val.activityDisplayValue = val.activityDisplayValue.replace("Candidate Communications", "Communication");
          if (val.clientOrBuName !== null && val.activityType != 'document') {
            val.activityDisplayValue = val.activityDisplayValue + " for <span class='emerald'>'" + val.clientOrBuName + "'</span>";
            if (!_.isNull(val.jobLocation)) {
              let jobLocationText = this.companyService.getParsedCompanyLocationText(val.jobLocation, true);
              val.activityDisplayValue = val.activityDisplayValue + jobLocationText;
            }
          }

          val.activityDisplayValue = val.activityDisplayValue.replace("Matched Candidate State Changed", "Candidate State Changed");
          if (!_.isNull(val.reasonNote) && val.reasonNote.length > 0) {
            val.activityDisplayValue = val.activityDisplayValue + "<br><b>Reason: </b>" + val.reasonNote;
          }
          val.notesDisplay = "";
          if (!_.isNull(val.note) && val.note.length > 0) {
            // if(this.isSharedRequisition) {
            val.notesDisplay = "<b>Internal Note: </b><br>" + this._replaceSpecialCharacters(val.note) + "<br>";
            // } else {
            //     val.notesDisplay = "<b>Note: </b>" + candidateJobMatch.jobMatchCurrentStateInternalNote + "<br>"; 
            // }
          }
          if (!_.isNull(val.externalNote) && val.externalNote.length > 0) {
            val.notesDisplay = val.notesDisplay + "<b>External Note: </b><br>" + this._replaceSpecialCharacters(val.externalNote);
          }
          val.displayDate = new Date(val.date);
          val.showShortMessageFlag = true;
          this.activities.push(val)
        });
        this.isLoading = false;
        if (this.activitiesLoadedCallback) {
          this.activitiesLoadedCallback.emit();
        }
      }, (error) => {
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      });
    } else if (this.context === "requisitionCard") {
      let requisitionInfo = { isSharedRequisition: this.isSharedRequisition, currentCompanyName: this.companyName };
      this.requisitionService.getRequisitionActivity(this.requisitionId, 1, 5, (data) => {
        this.activities = this.activitiesService.getMassagedActivities(data, this.activityMap, 'requisition', requisitionInfo);
        for (let val of this.activities) {
          val.activityDisplayValue = val.activityDisplayValue + val.mainText;
          if (!_.isNull(val.reasonNote) && val.reasonNote.length > 0) {
            val.activityDisplayValue = val.activityDisplayValue + "<br><b>Reason: </b>" + val.reasonNote;
          }
          val.notesDisplay = "";
          if (!_.isNull(val.note) && val.note.length > 0) {
            val.notesDisplay = "<b>Internal Note: </b><br>" + this._replaceSpecialCharacters(val.note) + "<br>";
          }
          if (!_.isNull(val.externalNote) && val.externalNote.length > 0) {
            val.notesDisplay = val.notesDisplay + "<b>External Note: </b><br>" + this._replaceSpecialCharacters(val.externalNote);
          }
          val.displayDate = new Date(val.date);
          val.showShortMessageFlag = true;
        }
        this.isLoading = false;
        if (this.activitiesLoadedCallback) {
          this.activitiesLoadedCallback.emit()
        }
      }, (error) => {
        // do nothing
      });
    } else if (this.context === "userCard" || this.context === "employeeCard") {
      activityFilter.userId = this.userId;
      this.activities = [];
      this.userService.getUserActivities(activityFilter, (data) => {
        this.activities = this.activitiesService.getMassagedActivities(data, this.activityMap, this.context, null);
        for (let val of this.activities) {
          val.activityDisplayValue = val.activityDisplayValue + val.mainText;
          if (!_.isNull(val.reasonNote) && val.reasonNote.length > 0) {
            val.activityDisplayValue = val.activityDisplayValue + "<br><b>Reason: </b>" + val.reasonNote;
          }
          val.notesDisplay = "";
          if (!_.isNull(val.note) && val.note.length > 0) {
            val.notesDisplay = "<b>Internal Note: </b><br>" + this._replaceSpecialCharacters(val.note) + "<br>";
          }
          if (!_.isNull(val.externalNote) && val.externalNote.length > 0) {
            val.notesDisplay = val.notesDisplay + "<b>External Note: </b><br>" + this._replaceSpecialCharacters(val.externalNote);
          }
          val.displayDate = new Date(val.date);
          val.showShortMessageFlag = true;
        }
        this.isLoading = false;
        if (this.activitiesLoadedCallback) {
          this.activitiesLoadedCallback.emit()
        }
      }, (error) => {
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      });
    }
  }

  _replaceSpecialCharacters(text) {
    let replacedText = text;
    for (let i = 0; i < replacedText.length; i++) {
      replacedText = replacedText.replace('<', '&lt;').replace('>', '&gt;');
    }
    replacedText = replacedText.replace(/&lt;br&gt;/g, '<br>');
    return replacedText;
  }

  expandText($event, activity) {
    activity.showShortMessageFlag = !activity.showShortMessageFlag;
    $event.stopPropagation();
    setTimeout(() => {
      if (this.jobMatchId) {
        document.getElementById(this.jobMatchId + '-activity-dropdown-menu').scrollIntoView({behavior: 'smooth', block: 'end'})
      }
    }, 200);
  }

  showAllActivity() {
    if (this.showAllCallback) {
      this.showAllCallback.emit();
    }
  }

  goToCandidateDocuments(activity) {
    let actionType = 'emailNotification';
    let actionLabel = 'Details of Documents Emailed';
    let documentDtoMap = {
      listOfDocumentsSent: activity.listOfDocumentsSent,
      listOfUsersDocumentsSent: activity.listOfUsersDocumentsSent
    }
    this.sharedAjsService.goToCandidateDocuments(actionType, actionLabel, documentDtoMap);
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddEditCandidateContactComponent } from './add-edit-candidate-contact.component';
import { FormsModule } from '@angular/forms';
import { FourDotFivePhoneNumberModule } from '../four-dot-five-phone-number/four-dot-five-phone-number.module';
import { ManageEntityLocationModule } from '../manage-entity-location/manage-entity-location.module';
import { CandidateAdditionalDetailsModule } from '../candidate-additional-details/candidate-additional-details.module';

@NgModule({
  declarations: [AddEditCandidateContactComponent],
  imports: [
    CommonModule,
    FormsModule,
    FourDotFivePhoneNumberModule,
    ManageEntityLocationModule,
    CandidateAdditionalDetailsModule
  ],
  exports: [],
  entryComponents: [AddEditCandidateContactComponent]
})
export class AddEditCandidateContactModule { }

import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { data } from 'jquery';
import { throwError } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { }

  login(user) {
    // let deferred = this.$q.defer();
    // this.$http.post('auth/login', user).success((data, status, headers, config) => {
    //     // && angular.isDefined(data.token) && angular.isDefined(data.refreshToken)
    //     if(angular.equals(status, 200) && angular.isDefined(data.roleScreenRef) && angular.isDefined(data.accountState)) {
    //         deferred.resolve(data);
    //     } else {
    //         deferred.reject(data);
    //     }
    // }).error((data, status, headers, config) => {
    //     console.log('service error : ' + angular.toJson(data));
    //     deferred.reject(data);
    // });
    // return deferred.promise;

  //   return deferred;
    return this.http.post('auth/login', user, {observe: 'response'})
  }
}

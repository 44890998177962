import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExternalNotesComponent } from './external-notes.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [ExternalNotesComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  entryComponents: [ExternalNotesComponent],
  exports: [ExternalNotesComponent]
})
export class ExternalNotesModule { }

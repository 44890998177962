<div class="conversation-wrapper">
    <div class="conversation-content">
        <div class="conversation-inner" style="height: 250px;">
            <!--<div *ngIf="loadingFlag" class="animate-if text-center">-->
                <!--<span class="fa fa-2x fa-spinner fa-spin"></span>-->
            <!--</div>-->
            <!--<div *ngIf="!loadingFlag">-->
                <!-- && ( (displayType == 'Notes' && contactMethod == null) || (displayType == 'Communication' && contactMethod != null) || (displayType == 'Both') ) -->
                <!-- <div *ngIf="(contactNotes.length > 0) || (displayType == 'Both')"> -->
                    <div *ngIf="(contactNotes.length > 0)">
                    <div *ngFor="let contactNote of contactNotes; let i = index" class="conversation-item item-right clearfix">
                        <div *ngIf="( (displayType == 'Both') || (displayType == 'Notes' && contactNote.contactMethod == null) || (displayType == 'Communication' && contactNote.contactMethod != null) )">
                            <div class="conversation-user">
                                <img src="assets/app-content/img/people/4dot5-default-no-profile-pic.jpg" alt="" class="notesAndCommunicationUserImage">
                            </div>
                            <div class="conversation-body"
                                    [ngClass]="{'contactNotesBackground': (contactNote.contactMethod == null), 'contactCommunicationBackground': (contactNote.contactMethod != null)}">
                                <div class="name">
                                    <span [innerHtml]="contactNote.user.name"></span>
                                    <i *ngIf="(contactNote.contactMethod === 'Incoming Call') || (contactNote.contactMethod === 'Outgoing Call')"
                                        class="fa fa-phone contact-method-detail" tooltip="Call" placement="bottom"
                                        [ngClass]="{ 'dark-green': (contactNote.contactMethod === 'Incoming Call'), 'red' : (contactNote.contactMethod === 'Outgoing Call')}"></i>
                                    <i *ngIf="(contactNote.contactMethod === 'Incoming Email') || (contactNote.contactMethod === 'Outgoing Email')"
                                        class="fa fa-envelope contact-method-detail" tooltip="Email" placement="bottom"
                                        [ngClass]="{ 'dark-green': (contactNote.contactMethod === 'Incoming Email'), 'red' : (contactNote.contactMethod === 'Outgoing Email')}"></i>
                                    <i *ngIf="((contactNote.contactMethod === 'Incoming Email') || (contactNote.contactMethod === 'Incoming Call'))"
                                        class="fa fa-arrow-left dark-green contact-method-detail"
                                        tooltip="Incoming" placement="bottom"></i>
                                    <i *ngIf="((contactNote.contactMethod === 'Outgoing Email') || (contactNote.contactMethod === 'Outgoing Call'))"
                                        class="fa fa-arrow-right red contact-method-detail"
                                        tooltip="Outgoing" placement="bottom"></i>
                                    <span class="contact-method-detail" *ngIf="(contactNote.contactMethod === 'Outgoing Call' || contactNote.contactMethod ==='Incoming Call')">
                                        {{contactNote.duration}} Min
                                    </span>
                                </div>
                                <div class="time d-none d-sm-block">
                                    <span>{{contactNote.dateTime | fourDotFiveDateFormatPipe}}</span>
                                    <span tooltip="Delete Note" placement="bottom"
                                            class="glyphicon glyphicon-remove pointer ng-scope" *ngIf="((contactNote.contactMethod === '' ||  contactNote.contactMethod === null) && contactNote.user.id === userDetails.id)"
                                            (click)="deleteContactNote(contactNote)">
                                    </span>
                                </div>
                                <div class="text" [innerHtml]="contactNote.note">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="contactNotes.length == 0" class="text-center contact-notes-no-values">
                    Add <span *ngIf="displayType != 'Both'" [innerHtml]="displayType"></span><span *ngIf="displayType === 'Both'">Notes or Communication</span> to display !
                </div>
            <!--</div>-->
        </div>
    </div>
    <div class="conversation-new-message">
        <div class="row">
            <div class="col-xs-12">
                <div class="pull-left">
                    <div class="form-group mb-2 mt-2">
                        <div class="radio" style="display: inline;">
                            <input type="radio" name="contactNote" id="contactNote" value="contactNote" [(ngModel)]="contactType" (change)="setNotesAndCommunication('contactNote')">
                            <label for="contactNote">
                               <span class="font-bold">Notes</span>
                            </label>
                        </div>
                        <div class="radio" style="display: inline;">
                            <input type="radio" name="contactCommunication" id="contactCommunication" value="contactCommunication" [(ngModel)]="contactType" (change)="setNotesAndCommunication('contactCommunication')">
                            <label for="contactCommunication">
                                <span class="font-bold">Candidate Communication</span>
                            </label>
                        </div>
                    </div>
                </div>
                <form #contactNoteForm="ngForm" novalidate>
                    <div class="row" *ngIf="contactType == 'contactCommunication'">
                        <div class="pull-left">
                            <div class="form-group mb-0 d-flex align-items-center" [ngClass]="{'has-error': (contactNoteForm.submitted && communicationTypeSelect.invalid)}">
                                <label for="communicationType" class="mr-2 mt-1">
                                    Communication Type<em>*</em> :
                                </label>
                                <select #communicationTypeSelect="ngModel" class="form-control mr-3" style="font-size: 12px; width: fit-content;" name="communicationTypeSelect"
                                        [(ngModel)]="newContactNote.contactMethod" (change)="communicationTypeChanged()" required>
                                    <!-- <option value="" disabled></option> -->
                                    <option *ngFor="let communicationType of communicationTypesList; let i = index;" [value]="communicationType.value">{{communicationType.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="pull-left" *ngIf="durationEnabledFlag">
                            <div class="form-group mb-0 d-flex align-items-center" [ngClass]="{'has-error': (contactNoteForm.submitted && durationSelect.invalid)}">
                                <label for="duration" class="mr-2 mt-1">
                                    Duration<em>*</em> :
                                </label>
                                <select #durationSelect="ngModel" class="form-control mr-3" style="font-size: 12px; width: fit-content;" name="durationSelect" [(ngModel)]="newContactNote.duration" required>
                                    <option *ngFor="let duration of durationTypesList; let i = index;" [value]="duration.value">{{duration.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div *ngIf="durationEnabledFlag && newContactNote.duration == 'Other'">
                            <div class="form-group mb-0 d-flex align-items-center" [ngClass]="{'has-error': (contactNoteForm.submitted && otherDuration.invalid)}">
                                <label for="otherDuration" class="control-label mr-2 mt-1">
                                    Other<em>*</em> :
                                </label>
                                <input #otherDuration="ngModel" style="font-size: 12px; width: 80px;" name="otherDuration" type="number" class="form-control" [(ngModel)]="durationOtherValue" required>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12">
                            <div class="form-group" [ngClass]="{'has-error': (contactNoteForm.submitted && contactNoteForm.invalid)}">
                                <label for="notes" class="control-label">
                                    Notes<em>*</em> :
                                </label>
                                <textarea #notes="ngModel" name="notes" class="form-control" rows="3" placeholder="Enter your note.." [(ngModel)]="newContactNote.note" required></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-8 text-left">
                            <input *ngIf="contactType == 'contactNote'" name="contactNote" type="checkbox" [checked]="displayType == 'Both'" (change)="displayType = $event.target.checked ? 'Both' : 'Notes'">
                            <input *ngIf="contactType == 'contactCommunication'" name="contactCommunication" type="checkbox" [checked]="displayType == 'Both'" (change)="displayType = $event.target.checked ? 'Both' : 'Communication'">
                            <!-- <span>Display <span *ngIf="type == 'notes'">Candidate Communication</span><span *ngIf="type == 'communication'">Notes</span> as well</span> -->
                            <span style="position: relative; left:5px; bottom:1px;">Display <span *ngIf="contactType == 'contactNote'">Candidate Communication</span><span *ngIf="contactType == 'contactCommunication'">Notes</span> as well</span>
                        </div>
                        <div class="col-xs-4 text-right">
                            <div class="clearfix">
                                <button type="submit" class="btn btn-success" (click)="saveContactNote()" [disabled]="savingFlag">
                                    <span *ngIf="!savingFlag">Save</span>
                                    <span *ngIf="savingFlag">Saving..</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
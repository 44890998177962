import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertDurationInYears'
})
export class ConvertDurationInYearsPipe implements PipeTransform {
  transform(durationInYears: number): string {
    let convertedDuration = '';
    let durationType = '';

    if (durationInYears < 0.082) {
      convertedDuration = Math.round(durationInYears * 365).toString();
      durationType = convertedDuration == '1' ? 'day' : 'days';
    } else if (durationInYears < 1) {
      convertedDuration = Math.round(durationInYears * 12).toString();
      durationType = convertedDuration == '1' ? 'month' : 'months';
    } else {
      convertedDuration = (Math.round(durationInYears * 10) / 10).toString();
      durationType = convertedDuration == '1' ? 'year' : 'years';
    }

    return `${convertedDuration} ${durationType}`;
  }
}

import './polyfills.ts';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as angular from 'angular';
import { setAngularJSGlobal } from '@angular/upgrade/static';
import { UIRouter, UrlService } from '@uirouter/core';
import { NgZone } from '@angular/core';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { AppAjsBootstrapModule } from './app/public/app/app.module.ajs.bootstrap';
// import states from './app/app.config.ajs';

AppAjsBootstrapModule.config(['$urlServiceProvider', ($urlService: UrlService) => $urlService.deferIntercept()]);
 
platformBrowserDynamic().bootstrapModule(AppModule).then((platformRef) => {
const urlService: UrlService = platformRef.injector.get(UIRouter).urlService;
 
function startUIRouter() {
urlService.listen();
urlService.sync();
}
 
platformRef.injector.get<NgZone>(NgZone).run(startUIRouter);
});

// setAngularJSGlobal(angular);

// states(appAjsModule)

// if (environment.production) {
//   enableProdMode();
// }

// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.error(err));

<div class="row">
    <div class="col-xs-12">
        <p *ngIf="isDetailsFetched && requisitions.length>0">
            <span [innerHtml]="message"></span>
            <a (click)="showDetails()" class="cursor-pointer">Show Details</a>
        </p>
        <p *ngIf="isDetailsFetched && requisitions.length == 0" style="height:50px">
            {{confirmMessage}}
        </p>
    </div>
    <div class="col-xs-12" *ngIf="showDetailsFlag && isDetailsFetched" style="height: 300px; overflow-y: auto;">
        <div style="margin-top:10px; padding:0 10px">
            <table class="table table-bordered">
                <tbody>
                    <tr>
                        <th>Requisition Number</th>
                        <th>Role Name</th>
                        <th>Location</th>
                        <th>Recruiters</th>
                        <th>Hiring Managers</th>
                        <th>Technical Assessment</th>
                        <th>Value Assessment</th>
                    </tr>
                    <tr *ngFor="let requisition of requisitions; let i = index">
                        <td>{{requisition.requisitionNumber}}</td>
                        <td>{{requisition.requisitionRole}} </td>
                        <td>{{requisition.clientOrBu}} <span *ngIf="requisition.clientOrBuLocation !==null"> @
                                <span>{{requisition.clientOrBuLocation}}</span></span> </td>
                        <td>
                            <p *ngFor="let recruiter of requisition.recruiters; let i = index">{{recruiter.firstName}}
                                {{recruiter.lastName}}</p>
                        </td>
                        <td>
                            <p *ngFor="let hiringManager of requisition.hiringManagers; let i = index">
                                {{hiringManager.firstName}} {{hiringManager.lastName}}</p>
                            <p *ngIf="requisition.hiringManagers.length == 0" class="text-center">-</p>
                        </td>
                        <td>{{requisition.techAssessmentName}}</td>
                        <td>{{requisition.valueAssessmentName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="context!=='clientOrBu'">
            <p *ngIf="context=='corporation'">BUs</p>
            <p *ngIf="context=='staffingCompany'">Clients</p>
            <ol>
                <li *ngFor="let clientOrBu of clientOrBus; let i = index">
                    <strong>{{clientOrBu.clientOrBuName}}</strong>
                    <span *ngIf="clientOrBu.clientOrBuLocation !== null"> at
                        <span>{{clientOrBu.clientOrBuLocation}}</span></span>
                    <table class="table table-bordered" style="margin-top: 10px">
                        <tr>
                            <th>Requisition Number</th>
                            <th>Role Name</th>
                            <th>Candidates in {{stepName}}</th>
                        </tr>
                        <tr *ngFor="let req of clientOrBu.requisitionDetailResponseDTO; let i = index">
                            <td>{{req.requisitionNumber}}</td>
                            <td>{{req.name}}</td>
                            <td>{{req.numberOfCandidates}}</td>
                        </tr>
                    </table>
                </li>
            </ol>
        </div>
    </div>
    <div class="row" *ngIf="!isDetailsFetched" style="height:100px">
        <div class="col-xs-12 text-center" style="font-size: 20px; padding-top:20px;">
            <span>Loading</span>
            <span class="fa fa-spinner fa-spin"></span>
        </div>
    </div>
    <div class="col-xs-12 text-right" style="margin-top: 10px">
        <div class="clearfix">
            <button type="button" class="btn btn-primary mr-2" *ngIf="isDetailsFetched && requisitions.length > 0"
                (click)="close()">OK</button>
            <button type="button" class="btn btn-danger mr-2" *ngIf="isDetailsFetched && requisitions.length == 0"
                (click)="close()">No</button>
            <button type="button" class="btn btn-primary" *ngIf="isDetailsFetched && requisitions.length == 0"
                (click)="deleteCredential()">Yes</button>
        </div>
    </div>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadFilesComponent } from './upload-files.component';
import { UploadFileSampleFormatComponent } from './upload-file-sample-format/upload-file-sample-format.component';

@NgModule({
  declarations: [UploadFilesComponent, UploadFileSampleFormatComponent],
  imports: [
    CommonModule
  ],
  exports: [UploadFilesComponent, UploadFileSampleFormatComponent],
  entryComponents: [UploadFilesComponent, UploadFileSampleFormatComponent]
})
export class UploadFilesModule { }

import { Injectable } from '@angular/core';
import { DocumentService } from './document.service';
import { CompanyService } from './company.service';
import { Folder } from '../models/Folder';
import { Document } from '../models/Document';

@Injectable({
  providedIn: 'root'
})
export class DocumentActionService {

  constructor(private documentService: DocumentService, private companyService: CompanyService) { }

  _setDocumentExtension(document) {
    document.link = this.documentService.getCandidateDocumentUrl(document.id);
    document.mailTo = `mailto:?subject=${document.fileName}&body={document.link}`;
    document.showActions = false;
    let clientOrVendorText = this.companyService.isCorporateCompany() ? "Vendor(s)" : "Client(s)";
    if (document.shared && document.sharedTo && document.sharedTo.length > 0) {
      document.sharedToText = `${document.sharedTo.length} ${clientOrVendorText}`;
    }
    let docNameArray = document.fileName.split(".");
    let fileExtension = docNameArray[docNameArray.length - 1];
    if (fileExtension.includes("doc")) {
      document.icon = "assets/app-content/img/doc-file.svg";
    } else if (fileExtension.includes("pdf")) {
      document.icon = "assets/app-content/img/pdf-file.svg";
    } else if (fileExtension.includes("xl")) {
      document.icon = "assets/app-content/img/excel-file.svg";
    }
  }

  _setDocumentIcons(folders) {
    folders.forEach(folder => {
      folder.documents.forEach(document => {
        this._setDocumentExtension(document);
      });

      folder.folders.forEach(subFolder => {
        subFolder.documents.forEach(subDocument => {
          this._setDocumentExtension(subDocument);
        });
      });
    });
  }

  _setNewDocument(candidateId, companyId, folderDetails) {
    let document = new Document("", "", "", candidateId, companyId, "", folderDetails.id, null, false, "assets/app-content/img/pdf-file.svg");
    document.version = 1;
    document.isJobMatchDocument = true;
    document.jobMatch = folderDetails.jobMatch;
    return document;
  }

  _setJobMatchDocuments(folderDetails, candidateId, companyId) {
    let jobMatch = folderDetails.jobMatch;
    if (!_.isNull(jobMatch.techAssessmentPdfReportURL) && !_.isNull(jobMatch.techAssessmentScore) && jobMatch.techAssessmentScore.toString() != '') {
      let document = this._setNewDocument(candidateId, companyId, folderDetails);
      document.fileName = "Tech Assessment";
      document.id = "document-" + document.fileName + "-" + folderDetails.id;
      document.link = 'api/candidate/candidatepdfreport?filepath=' + jobMatch.techAssessmentPdfReportURL + '&testinvitationid=' + jobMatch.techAssessmentInvitationId + '&sectional=' + false;
      folderDetails.documents.push(document);
    }

    if (!_.isNull(jobMatch.valueAssessmentPdfReportURL) && !_.isNull(jobMatch.valueAssessmentScore) && jobMatch.valueAssessmentScore.toString() != '') {
      let document = this._setNewDocument(candidateId, companyId, folderDetails);
      document.fileName = "Value Assessment";
      document.id = "document-" + document.fileName + "-" + folderDetails.id;
      document.link = 'api/candidate/candidatepdfreport?filepath=' + jobMatch.valueAssessmentPdfReportURL + '&testinvitationid=' + jobMatch.valueAssessmentInvitationId + '&sectional=' + false;
      folderDetails.documents.push(document);
    }

    if (jobMatch.recruiterScreeningFeedbackReport) {
      let document = this._setNewDocument(candidateId, companyId, folderDetails);
      document.fileName = "Recruiter Screening Feedback";
      document.id = "document-" + document.fileName + "-" + folderDetails.id;
      document.link = "api/jobmatch/getscreeningfeedbackreport/" + jobMatch.jobMatchId + "?companyId=" + companyId;
      folderDetails.documents.push(document);
    }
    if (jobMatch.phoneScreenFeedbackReport) {
      let document = this._setNewDocument(candidateId, companyId, folderDetails);
      document.fileName = "Phone Screen Feedback";
      document.id = "document-" + document.fileName + "-" + folderDetails.id;
      document.link = "api/jobmatch/getphonescreenfeedbackreport/" + jobMatch.jobMatchId + "?companyId=" + companyId;
      folderDetails.documents.push(document);
    }

    if (jobMatch.interviewFeedbackReport) {
      let document = this._setNewDocument(candidateId, companyId, folderDetails);
      document.fileName = "Interview Feedback";
      document.id = "document-" + document.fileName + "-" + folderDetails.id;
      document.link = "api/jobmatch/getinterviewfeedbackreport/" + jobMatch.jobMatchId + "?companyId=" + companyId;
      folderDetails.documents.push(document);
    }
  }

  setCandidateJobMatchFolders(jobMatches, companyId, candidateId) {
    let candidateJobMatchFolders = [];
    if (jobMatches && jobMatches.length > 0) {
      jobMatches.map(jobMatch => {
        if (!jobMatch.jobMatchDisabled && !jobMatch.hasMeetingScheduleAndNoScopeForLoggedInUser) {
          let folderDetails = new Folder("", "", null, companyId, candidateId);
          folderDetails.folders = [];
          folderDetails.documents = [];
          folderDetails.id = "folder-" + jobMatch.jobMatchId,
          folderDetails.name = jobMatch.jobMatchTitle + " (" + jobMatch.jobMatchRequisitionNum + ")";
          folderDetails.isJobMatchDocument = true;
          folderDetails.jobMatch = jobMatch;
          this._setJobMatchDocuments(folderDetails, candidateId, companyId);
            candidateJobMatchFolders.push(folderDetails);
        }
      });
    }
    return candidateJobMatchFolders;
  }

  setParentAndChildFolderName(allFolders, document) {
    allFolders.map(folder => {
      if (document.folderId == folder.id) {
        document.folderName = folder.name;
        document.parentFolderId = folder.parentFolderId;
        document.parentFolderName = folder.parentFolderName;
      }
    });
  }

  setSharedFolderWithDocuments(folders, candidateId, companyId) {
    let sharedFolder:any = { ...new Folder('Shared with me', 'Shared documents', null, companyId, candidateId) };
    sharedFolder.id = 'sharedFolder';
    sharedFolder.ownFolder = true; // Make Shared folder as Own folder
    sharedFolder.documents = [];
    sharedFolder.folders = [];
    folders.map(parentFolder => {
      if (parentFolder.documents && parentFolder.documents.length > 0) {
        let sharedParentDocuments = [];
        sharedParentDocuments = parentFolder.documents.filter(document => document.shared && !document.editable);
        parentFolder.documents = parentFolder.documents.filter(document => !sharedParentDocuments.includes(document));
        sharedFolder.documents.push(...sharedParentDocuments);
      }

      if (parentFolder.folders && parentFolder.folders.length > 0) {
        parentFolder.folders.map(childFolder => {
          if (childFolder.documents && childFolder.documents.length > 0) {
            let sharedChildDocuments = [];
            sharedChildDocuments = childFolder.documents.filter(document => document.shared && !document.editable);
            childFolder.documents = childFolder.documents.filter(document => !sharedChildDocuments.includes(document));
            sharedFolder.documents.push(...sharedChildDocuments);
          }
        })
      }
    });

    if (sharedFolder.documents.length > 0) {
      folders.push(sharedFolder);
    }

    // Remove folders which has 'ownFolder' false
    folders = folders.filter(parentFolder => {
      if(parentFolder.folders && parentFolder.folders.length > 0) {
        parentFolder.folders = parentFolder.folders.filter(childFolder => childFolder.ownFolder && childFolder.id != 'sharedFolder');
      }
      return parentFolder.ownFolder || (parentFolder.ownFolder && parentFolder.id == 'sharedFolder');
    });

    return folders;
  }

}

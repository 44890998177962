import { downgradeComponent } from "@angular/upgrade/static";
import { ManageMasterListsPageComponent } from './manage-master-lists-page.component';

export const ManageMasterListsPageAjsPageModule = angular
  .module('manageMasterListsPage', [])
  .directive('manageMasterListsPage', downgradeComponent({ component: ManageMasterListsPageComponent }))
  .config(
    ['$stateProvider', ($stateProvider) => {
      $stateProvider.state('missioncontrol.masterLists', {
        url: '/masterLists',
        component: ManageMasterListsPageComponent,
        data: {
          requireLogin: true
        }
      });
    }
    ])
  .name;


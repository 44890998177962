import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Inject, NgZone } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CandidateService } from 'src/app/core/services/candidate.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { JobMatchService } from 'src/app/core/services/job-match.service';
import { JobService } from 'src/app/core/services/job.service';
import { AngularModalService } from 'src/app/core/services/modal.service';
import { UserCommunicationService } from 'src/app/core/services/user-communication.service';
import { UserRoleService } from 'src/app/core/services/user-role.service';
import { UserService } from 'src/app/core/services/user.service';
import { WebSocketsService } from 'src/app/public/app/services/webSocketsService';
import { fromEventPattern } from 'rxjs';

declare var bootbox: any;

@Component({
  selector: 'app-user-communication',
  templateUrl: './user-communication.component.html',
  styleUrls: ['./user-communication.component.css']
})
export class UserCommunicationComponent implements OnInit {
  @ViewChild('userCommunicationForm', null) userCommunicationForm: NgForm;

  @Input() candidateId;
  @Input() jobId;
  @Input() jobMatchId;
  @Input() jobMatchesFilter;
  @Input() context;
  @Output() saveCallback = new EventEmitter<any>();

  selectedSort: any = 'LATEST';
  chatThreads: any = [];
  jobMatches: any = [];
  recruiterList: any = [];
  selectedRecipients: any = [];
  messageRecipientsList: any = [];
  userDetails: any;
  chatMessageTypesList: any = [];
  companyId: any;
  companyName: any;
  vendorId: any = "";
  userName: any;
  jobMatchInfo: any = {};
  recipientsFilterObject: any;
  filterObject: any;
  selectedThread: any;
  newChatMessage: any = {};
  hiringManagerList: any;
  vendorRecruitersList: any;
  replyMessageCopy: any;
  selectedThreadUsers: any;
  //filter variables
  sentByFilterList: any = [];
  selectedFilterSentBy: any = [];
  sendsToFilterList: any = [];
  selectedFilterSendsTo: any = [];
  //flags
  loadingFlag: boolean = false;
  sendingFlag: boolean = false;
  isReplyingEnabled: boolean = false;
  isCorporate: boolean;
  isUserAdmin: boolean;
  constructor(
    @Inject('webSocketsService') public webSocketsService: WebSocketsService,
    private authService: AuthService,
    private companyService: CompanyService,
    private userRoleService: UserRoleService,
    private jobMatchService: JobMatchService,
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private candidateService: CandidateService,
    private jobService: JobService,
    private userService: UserService,
    private userCommunicationService: UserCommunicationService,
    private modalService: AngularModalService,
    private ngZone: NgZone) { }

  ngOnInit() {
    //variables
    //  this.chatThreads = [];
    this.jobMatches = [];
    this.recruiterList = [];
    this.selectedRecipients = [];
    this.messageRecipientsList = [];
    this.userDetails = null;
    this.chatMessageTypesList = [];
    this.companyId = this.authService.getUserDetails().company.companyId;
    this.companyName = this.authService.getUserDetails().company.companyName;
    this.vendorId = "";

    //filter variables
    this.sentByFilterList = [];
    this.selectedFilterSentBy = [];

    this.sendsToFilterList = [];
    this.selectedFilterSendsTo = [];

    //flags
    this.loadingFlag = false;
    this.sendingFlag = false;
    this.isReplyingEnabled = false;
    this.isCorporate = this.companyService.isCorporateCompany();


    this.userName = this.authService.getUserDetails().firstname + ' ' + this.authService.getUserDetails().lastname;
    this.isUserAdmin = (this.userRoleService.isLoggedInUserSuperUserOr4dot5Admin() || this.userRoleService.isLoggedInUserCompanyAdmin()) ? true : false;
    this.userDetails = this.authService.getUserDetails();

    // Create a custom Observable that watches for changes in the AngularJS service.
    this._initializeMessageReceive();

    this._getCandidateJobMatchCardInfo(() => {
      if (this.context == 'candidateCard') {
        this._getCandidateJobMatches(() => {
          this._setCommunicationDetails();
        });
      } else {
        this._setCommunicationDetails();
      }
    });
  }

  _getCandidateJobMatchCardInfo(successCallback) {
    this.loadingFlag = true;
    this.jobMatchService.getCandidateJobMatchCard(this.jobMatchId, this.companyId, (data) => {
      this.jobMatchInfo = this._massageCandidateJobMatchData(data);
      if (successCallback) {
        successCallback();
      }
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      // if (errorCallback) {
      //   errorCallback();
      // }
    }, undefined);
  }

  _massageCandidateJobMatchData(candidateJobMatch) {
    candidateJobMatch.name = this._setCandidateName(candidateJobMatch);
    candidateJobMatch.jobMatchDate = new Date(candidateJobMatch.jobMatchDate);
    if (_.isNull(candidateJobMatch.jobMatchRequisitionNum)) {
      candidateJobMatch.jobMatchRequisitionNum = '-';
    }
    if (candidateJobMatch.fromOtherSource) {
      candidateJobMatch.otherSourceNote = "<span>Source: Other<br>Notes: " + candidateJobMatch.otherSourceTypeNotes + "</span>";
    }
    candidateJobMatch.primaryRecruiter = {};
    candidateJobMatch.primaryRecruiterName = '';
    candidateJobMatch.corpRecruiters = [];
    candidateJobMatch.vendorRecruiters = [];
    let tempPrimaryRecruiterIndex = -1;
    if (!_.isNull(candidateJobMatch.recruitersList) && candidateJobMatch.recruitersList.length) {
      angular.forEach(candidateJobMatch.recruitersList, (val, key) => {
        val.name = val.firstName;
        if (!_.isNull(val.lastName)) {
          val.name += ' ' + val.lastName;
        }
        if (val.isPrimary) {
          candidateJobMatch.primaryRecruiter = val;
          candidateJobMatch.primaryRecruiterName = val.name;
          candidateJobMatch.isPrimaryRecruiterVendor = val.vendorRecruiter;
          tempPrimaryRecruiterIndex = key;
        } else if (val.vendorRecruiter) {
          candidateJobMatch.vendorRecruiters.push(val);
        } else {
          candidateJobMatch.corpRecruiters.push(val);
        }
      });
    }
    if (tempPrimaryRecruiterIndex != -1) {
      // remove the primary recruiter object from the recruiter list array
      candidateJobMatch.recruitersList.splice(tempPrimaryRecruiterIndex, 1);
    }
    return candidateJobMatch;
  }

  _setCandidateName(candidate) {
    if (_.isNull(candidate.firstName)) {
      candidate.firstName = "-";
    }
    if (_.isNull(candidate.lastName)) {
      candidate.lastName = "-";
    }
    if (candidate.firstName == "-" && candidate.lastName == "-") {
      candidate.nameWarning = 'First and Last Name Missing';
    } else if (candidate.firstName == "-") {
      candidate.nameWarning = "First Name Missing";
    } else if (candidate.lastName == "-") {
      candidate.nameWarning = "Last Name Missing"
    } else {
      candidate.nameWarning = "";
    }
    return candidate.firstName + ' ' + candidate.lastName;
  }

  _getCandidateJobMatches(successCallback) {
    this.jobMatchesFilter.top5Flag = false;
    this.candidateService.getCandidateJobMatches(this.jobMatchesFilter, 1, 250, (data) => {
      angular.forEach(data, (val, key) => {
        if (!_.isNull(val.jobLocation)) {
          val.jobDetailsText = this.jobService.getParsedJobLocationText(val.jobLocation, false);
        }
        if (!val.jobMatchDisabled) {
          this.jobMatches.push(val);
        }
      });
      if (successCallback) {
        successCallback();
      }
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  _setCommunicationDetails() {

    this.initializeNewChatMessage();
    if (this.jobMatchInfo.isSharedRequisition) {
      this.vendorId = this.jobMatchInfo.jobMatchCompanyId;
    }
    let vendorsIds = this.vendorId == null ? [] : [this.vendorId];
    this.recipientsFilterObject = {
      isShared: this.jobMatchInfo.isSharedRequisition,
      vendorIds: vendorsIds,
      requisitionId: this.jobId,
      clientId: this.jobMatchInfo.clientOrBUId,
      companyId: this.companyId
    }
    this._resetThreadFilters();
    this.getAllUserCommunication(() => {
      this._setFilters();
    });
    this.getMessageRecipients();
  }

  _resetThreadFilters() {
    this.filterObject = {
      dateFilter: "LATEST",
      recipients: [],
      sendBy: ""
    }
    this.sentByFilterList = [];
    this.selectedFilterSentBy = [];
    this.sendsToFilterList = [];
    this.selectedFilterSendsTo = [];
  }

  _initializeMessageReceive() {
    // Create a custom Observable that watches for notifications in the AngularJS service.
    const observable$ = fromEventPattern(
      (handler) => this.webSocketsService.receiveMessage().then(null, null, handler), // Success handler
      (handler) => {/* No-op, since there's no error handler for Promise */}
    );

    observable$.subscribe(
      (message: any) => {
        this.ngZone.run(() => {
          let isNewThread = true;
          message.sendByUserName = message.sendBy.firstName + " " + message.sendBy.lastName;
          message.isLoggedInUserSender = message.sendBy.id == this.authService.getUserDetails().id;
          message.sendAt = new Date(message.sendAt);
          message.isOpen = false;
          message.toUsersText = this._getToAndFromUsersText(message, false);
          let recipient:any = _.find(message.recipients, { id: this.userDetails.id });
          let recipientIndex = _.findIndex(message.recipients, { id: this.userDetails.id });
          recipient.chatMessageState = "UNREAD";
          message.recipients[recipientIndex] = angular.copy(recipient);
          let threadIndex = -1;
          let updatedThread = {};
          angular.forEach(this.chatThreads, (thread, threadId) => {
            if (thread[0].parentMessageId == message.parentMessageId) {
              isNewThread = false;
              threadIndex = threadId;
              thread.push(message);
              if (!thread[0].unreadMessagesCount) {
                thread[0].unreadMessagesCount = 1;
              } else {
                thread[0].unreadMessagesCount = thread[0].unreadMessagesCount + 1;
              }
              updatedThread = angular.copy(thread);
            }
          });
          if (isNewThread) {
            this.chatThreads.unshift([message]);
          } else if (this.selectedThread.length > 0 && this.selectedThread[0].parentMessageId == message.parentMessageId) {
            this.selectedThread.push(message);
          } else if (threadIndex !== -1) {
            //move the thread to the top
            this.chatThreads.splice(threadIndex, 0);
            this.chatThreads.unshift(updatedThread);
          }
          this._setFilters();
        });
      });
  }

  onNewMessageClick() {
    if (this.newChatMessage.content && this.newChatMessage.content.trim() !== "" && this.newChatMessage.parentMessageId !== "") {
      this._showUnSendMessageWarning(() => {
        this.initializeNewChatMessage();
      });
    } else {
      this.initializeNewChatMessage();
    }
  }

  _resetUserCommunicationForm(chatMessageType) {
    let tempChatMessageType = chatMessageType.slice();
    this.userCommunicationForm.resetForm();
    setTimeout(() => {
      this.newChatMessage.chatMessageType = tempChatMessageType;
    }, 50);
  }

  initializeNewChatMessage() {
    this.isReplyingEnabled = false;
    this.newChatMessage = {};
    this.newChatMessage.content = "";
    this.newChatMessage.sendBy = this.authService.getUserDetails().id;
    this.newChatMessage.sendsTo = [];
    this.newChatMessage.chatMessageType = "INTERNAL";
    this.newChatMessage.parentMessageId = "";
    this.newChatMessage.jobMatchId = this.jobMatchId;
    this.newChatMessage.candidateId = this.candidateId;
    this.selectedRecipients = [];
    this.onCommunicationTypeChange();
    angular.forEach(this.messageRecipientsList, (recruiter, key) => {
      recruiter.disabled = false;
      recruiter.selected = false;
    });
    if (this.userCommunicationForm) {
      this._resetUserCommunicationForm(this.newChatMessage.chatMessageType);
    }

    angular.forEach(this.chatThreads, (thread, threadId) => {
      thread[0].isOpen = false;
    });
    this.selectedThread = [];
  }

  getAllUserCommunication(successCallback) {
    this.loadingFlag = true;
    this.userCommunicationService.getAllChat(this.jobMatchId, this.filterObject, (data) => {
      this.chatThreads = [];
      angular.forEach(data, (thread, threadId) => {
        let unreadMessagesInThread = 0;
        angular.forEach(thread, (message, key) => {
          message.sendByUserName = message.sendBy.firstName + " " + message.sendBy.lastName;
          message.isLoggedInUserSender = message.sendBy.id == this.authService.getUserDetails().id;
          message.sendAt = new Date(message.sendAt);
          message.isOpen = false;
          message.toUsersText = this._getToAndFromUsersText(message, false);
          message.unreadMessagesCount = 0;
          let recipient = _.find(message.recipients, { id: this.userDetails.id, chatMessageState: 'UNREAD' });
          if (angular.isDefined(recipient)) {
            unreadMessagesInThread++;
          }
        });
        angular.forEach(thread, (message, key) => {
          message.unreadMessagesCount = unreadMessagesInThread;
        });
        this.chatThreads.push(thread);
      });
      this.loadingFlag = false;
      if (successCallback) {
        successCallback();
      }
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  _setFilters() {
    this.sentByFilterList = [];
    // this.selectedFilterSentBy = [];
    this.sendsToFilterList = [];
    // this.selectedFilterSendsTo = [];
    angular.forEach(this.chatThreads, (thread, threadId) => {
      angular.forEach(thread, (message, messageId) => {
        //set sent by filter
        message.sendBy.name = message.sendBy.firstName + " " + message.sendBy.lastName;
        let index = _.findIndex(this.sentByFilterList, (sentBy:any) => { return sentBy.id == message.sendBy.id; });
        if (index == -1) {
          this.sentByFilterList.push({ id: message.sendBy.id, name: message.sendBy.name });
        }
        //set recipients filter
        angular.forEach(message.recipients, (recipient, recipientId) => {
          recipient.name = recipient.firstName + " " + recipient.lastName;
          let index = _.findIndex(this.sendsToFilterList, (sendTo:any) => { return sendTo.id == recipient.id; });
          if (index == -1) {
            this.sendsToFilterList.push({ id: recipient.id, name: recipient.name });
          }
        });
      });
    });
  }

  _getToAndFromUsersText(message, isAllUsers) {
    let finalText = "";
    if (isAllUsers) {
      finalText = finalText + message.sendByUserName + ", ";
    }
    angular.forEach(message.recipients, (recipient, key) => {
      if (key == message.recipients.length - 1) {
        finalText = finalText + recipient.firstName + " " + recipient.lastName;
      } else if (key == message.recipients.length - 2) {
        finalText = finalText + recipient.firstName + " " + recipient.lastName + " and ";
      } else {
        finalText = finalText + recipient.firstName + " " + recipient.lastName + ", ";
      }
    });
    return finalText;
  }

  getMessageRecipients() {
    this.userService.getMessageRecipients(this.recipientsFilterObject, (data) => {
      this._setMessageRecipients(data);
      // if (successCallback) {
      //   successCallback();
      // }
    }, (error) => {
      // if (this.$rootScope.isOnline) {
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      // }
    });
  }

  _setMessageRecipients(recipientsData) {
    if (!this.jobMatchInfo.isSharedRequisition) {
      this.recruiterList = recipientsData.recruiters;
      this.hiringManagerList = recipientsData.hiringManagers;
      this.vendorRecruitersList = [];
      angular.forEach(this.hiringManagerList, (manager, key) => {
        this.recruiterList.push(manager);
      });
    } else if (this.isCorporate) {
      this.recruiterList = recipientsData.recruiters;
      this.hiringManagerList = recipientsData.hiringManagers;
      this.vendorRecruitersList = recipientsData.vendorRecruiters[this.jobMatchInfo.vendorName];
      angular.forEach(this.hiringManagerList, (manager, key) => {
        this.recruiterList.push(manager);
      });
    } else if (!this.isCorporate) {
      this.recruiterList = recipientsData.vendorRecruiters[this.companyName];
      this.hiringManagerList = recipientsData.hiringManagers;
      this.vendorRecruitersList = recipientsData.recruiters;
      angular.forEach(this.hiringManagerList, (manager, key) => {
        this.vendorRecruitersList.push(manager);
      });
    }

    //set recruiters name
    angular.forEach(this.recruiterList, (recruiter, key) => {
      recruiter.name = recruiter.firstName;
      if (!_.isNull(recruiter.lastName)) {
        recruiter.name = recruiter.name + " " + recruiter.lastName;
      }
      recruiter.name = recruiter.name + " (" + recruiter.roleName + ")";
    });

    //set vendors name
    angular.forEach(this.vendorRecruitersList, (recruiter, key) => {
      recruiter.name = recruiter.firstName;
      if (!_.isNull(recruiter.lastName)) {
        recruiter.name = recruiter.name + " " + recruiter.lastName;
      }
      recruiter.name = recruiter.name + " (" + recruiter.roleName + ")";
    });
    this.messageRecipientsList = angular.copy(this.recruiterList);
  }

  onCommunicationTypeChange() {
    this.selectedRecipients = [];
    this.messageRecipientsList = [];
    if (this.newChatMessage.chatMessageType == "INTERNAL") {
      this.messageRecipientsList = angular.copy(this.recruiterList);
    } else {
      this.messageRecipientsList = angular.copy(this.vendorRecruitersList);
    }
  }

  onRecruitersSelected() {
    this.newChatMessage.sendsTo = [];
    if (this.selectedRecipients.length > 0) {
      angular.forEach(this.selectedRecipients, (recruiter, key) => {
        this.newChatMessage.sendsTo.push(recruiter.userId);
      });
    }
  }

  onThreadClick(message, threadIndex) {
    if (this.newChatMessage.content && this.newChatMessage.content !== "" && this.newChatMessage.parentMessageId !== message.parentMessageId) {
      this._showUnSendMessageWarning(() => {
        this.reply(message, threadIndex)
      });
    } else {
      this.reply(message, threadIndex);
    }
  }

  _showUnSendMessageWarning(sendCancelCallback) {
    bootbox.confirm({
      closeButton: false,
      title: "<div class='alert alert-warning' style='margin-bottom: 0px;'><i class='fas fa-exclamation-triangle fa-fw fa-lg'></i><strong>Message not sent!</strong></div>",
      message: "This message hasn’t been sent yet. Do you want to cancel sending it?",
      className: "zIndex1060",
      backdrop: true,
      onEscape: false,
      buttons: {
        confirm: {
          label: 'Yes',
          className: 'btn-info'
        },
        cancel: {
          label: 'No',
          className: 'btn-danger'
        }
      },
      callback: (result) => {
        if (result) {
          sendCancelCallback();
        }
        this.modalService.addModalOpenClassToBodyIfAnyModalIsOpen();
      }
    });
  }

  reply(message, threadIndex) {
    this.isReplyingEnabled = true;
    this._setAllMessagesInThreadToStatusRead(message, threadIndex);
    this.replyMessageCopy = angular.copy(message);
    this._setSelectedThread(message);
    this._highlightSelectedThread(message);
    this._setReplyMessageDefaultValues(message);
    this._resetUserCommunicationForm(this.newChatMessage.chatMessageType);
    setTimeout(() => {
      let container = document.getElementsByClassName('user-chat-messages-container')[0];
      container.scrollTop = container.scrollHeight;
    }, 200);
  }

  _setAllMessagesInThreadToStatusRead(message, threadIndex) {
    let thread = this.chatThreads[threadIndex];
    angular.forEach(thread, (message, index) => {
      let recipient:any = _.find(message.recipients, { id: this.userDetails.id, chatMessageState: 'UNREAD' });
      let recipientIndex = _.findIndex(message.recipients, { id: this.userDetails.id, chatMessageState: 'UNREAD' });
      message.unreadMessagesCount = 0;
      if (angular.isDefined(recipient)) {
        recipient.chatMessageState = "READ";
        message.recipients[recipientIndex] = recipient;
        this.userCommunicationService.changeChatMessageState(message.chatMessageId, (data) => {
          message = angular.copy(data);
        }, (error) => {
          this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
        });
      }
    });
    this.chatThreads[threadIndex] = angular.copy(thread);
  }

  _setSelectedThread(message) {
    this.selectedThread = [];
    angular.forEach(this.chatThreads, (thread, threadId) => {
      angular.forEach(thread, (messageCurrent, messageId) => {
        if (message.parentMessageId == messageCurrent.parentMessageId) {
          this.selectedThread.push(messageCurrent);
        }
      });
    });
  }

  _highlightSelectedThread(message) {
    angular.forEach(this.chatThreads, (thread, threadId) => {
      if (thread[0].parentMessageId == message.parentMessageId) {
        thread[0].isOpen = true;
        thread[0].unreadMessagesCount = 0;
      } else {
        thread[0].isOpen = false;
      }
    });
  }

  _setReplyMessageDefaultValues(message) {
    this.newChatMessage = angular.copy(message);
    //properties for payload
    this.newChatMessage.content = "";
    this.newChatMessage.sendBy = this.authService.getUserDetails().id;
    this.newChatMessage.chatMessageType = message.chatMessageType;
    this.newChatMessage.parentMessageId = message.parentMessageId;
    this.newChatMessage.jobMatchId = this.jobMatchId;
    this.newChatMessage.candidateId = this.candidateId;

    //properties for UI
    this.newChatMessage.sendByUserName = this.authService.getUserDetails().firstname + " " + this.authService.getUserDetails().lastname;
    this.newChatMessage.isLoggedInUserSender = true;

    //set Sender and recipients
    this._setSenderAndRecipientsForReplyMessage(message);
    this.selectedThreadUsers = this._getToAndFromUsersText(this.newChatMessage, true);
  }

  _setSenderAndRecipientsForReplyMessage(message) {
    this.selectedRecipients = [];
    let recipientsIds = [];
    this.newChatMessage.recipients = [];
    if (message.sendBy.id !== this.authService.getUserDetails().id) {
      recipientsIds.push(message.sendBy.id);
      this.newChatMessage.recipients.push(message.sendBy);
    }
    angular.forEach(message.recipients, (recipient, key) => {
      if (recipient.id !== this.authService.getUserDetails().id) {
        recipientsIds.push(recipient.id);
        this.newChatMessage.recipients.push(recipient);
      }
    });
    this.newChatMessage.sendsTo = recipientsIds;
    angular.forEach(this.newChatMessage.sendsTo, (recipientId, key) => {
      angular.forEach(this.messageRecipientsList, (recruiter, recruiterKey) => {
        if (recruiter.userId == recipientId) {
          recruiter.selected = true;
          this.selectedRecipients.push(recruiter);
        }
      });
    });
  }

  sendChat() {
    if (this.userCommunicationForm.valid && this.newChatMessage.sendsTo.length !== 0) {
      this.sendingFlag = true;
      let chatThreadSaveObject = angular.copy(this.newChatMessage);
      this.userCommunicationService.saveChat(chatThreadSaveObject, (data) => {
        // reset the saving flag
        this.sendingFlag = false;
        // this._addMessageToThread();
        this._resetThreadFilters();
        this.getAllUserCommunication(() => {
          this.selectedThread = this.chatThreads[0];
          this.chatThreads[0][0].isOpen = true;
          this.reply(this.selectedThread[0], null);
          this.selectedThreadUsers = this._getToAndFromUsersText(this.selectedThread[0], true);
          this._setFilters();
        });
        this.newChatMessage.content = "";
        // reset the form submitted value
        this._resetUserCommunicationForm(this.newChatMessage.chatMessageType);
        this._setFilters();
      }, (error) => {
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      });
    }
  }

  _addMessageToThread() {
    this.isReplyingEnabled = true;

    // add message to existing thread
    angular.forEach(this.chatThreads, (thread, threadId) => {
      if (thread[0].parentMessageId == this.newChatMessage.parentMessageId) {
        thread.push(angular.copy(this.newChatMessage));
      }
      this._setFilters();
    });

    //add message to selected thread
    if (this.newChatMessage.parentMessageId !== "") {
      if (this.selectedThread[0].parentMessageId == this.newChatMessage.parentMessageId) {
        this.selectedThread.push(angular.copy(this.newChatMessage));
        this.reply(this.replyMessageCopy, null);
      }
    } else {  //add message as a new thread
      this._resetThreadFilters();
      this.getAllUserCommunication(() => {
        this.selectedThread = this.chatThreads[0];
        this.chatThreads[0][0].isOpen = true;
        this.reply(this.selectedThread[0], null);
        this.selectedThreadUsers = this._getToAndFromUsersText(this.selectedThread[0], true);
        this._setFilters();
      });
    }
    this._resetUserCommunicationForm(this.newChatMessage.chatMessageType);
  }

  onSentByFilterSelected() {
    if (this.selectedFilterSentBy.length > 0) {
      this.filterObject.sendBy = this.selectedFilterSentBy[0].id;
    } else {
      this.filterObject.sendBy = "";
    }
    this.getAllUserCommunication(() => {
      this.selectedThread = [];
      this.isReplyingEnabled = false;
    });
  }

  onSendsToFilterSelected() {
    this.filterObject.recipients = [];
    if (this.selectedFilterSendsTo.length > 0) {
      angular.forEach(this.selectedFilterSendsTo, (sendTo, key) => {
        this.filterObject.recipients.push(sendTo.id);
      });
    }
    this.getAllUserCommunication(() => {
      this.selectedThread = [];
      this.isReplyingEnabled = false;
    });
  }

  onDateSortChange(sortDirection) {
    this.selectedSort = sortDirection;
    this.filterObject.dateFilter = sortDirection;
    this.getAllUserCommunication(() => {
      this.selectedThread = [];
      this.isReplyingEnabled = false;
      this.initializeNewChatMessage();
    });
  }

  cancelMessage() {
    this.initializeNewChatMessage();
  }

  onJobMatchSelected() {
    let selectedJobMatch:any = _.filter(this.jobMatches, { jobMatchId: this.jobMatchId })[0];
    this.jobId = selectedJobMatch.jobId;
    this._getCandidateJobMatchCardInfo(() => {
      this._setCommunicationDetails();
    });
  }

  selectOrDeselectAllClients(type, action) {
    switch(type) {
      case 'recruiters':
        this.selectedRecipients = action === 'selectAll' ? [...this.messageRecipientsList] : [];
        this.onRecruitersSelected();
        break;
      case 'recruitersSentBy':
        this.selectedFilterSentBy = action === 'selectAll' ? [...this.sentByFilterList] : [];
        this.onSentByFilterSelected();
        break;
      case 'recruitersSentTo':
        this.selectedFilterSendsTo = action === 'selectAll' ? [...this.sendsToFilterList] : [];
        this.onSendsToFilterSelected();
        break;
      default:
        break;
    }
  }

}

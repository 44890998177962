import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { JobMatchService } from 'src/app/core/services/job-match.service';
import { ContactNote } from 'src/app/core/models/contactNote';

@Component({
  selector: 'app-notes-and-communication',
  templateUrl: './notes-and-communication.component.html',
  styleUrls: ['./notes-and-communication.component.css']
})
export class NotesAndCommunicationComponent implements OnInit {

  @ViewChild('contactNoteForm', null) contactNoteForm:NgForm;
  @Input() jobMatchId;
  @Input() type;
  @Input() contactMethod;
  @Output() saveCallback = new EventEmitter<any>();

  contactNotes:any = [];
  newContactNote:any = null;
  userDetails:any = null;
  communicationTypesList:any = [];
  durationTypesList:any = [];
  contactType:any;
  displayType:any;
  durationOtherValue:any;

  // Flags
  loadingFlag:boolean = true;
  savingFlag:boolean = false;
  durationEnabledFlag:boolean = false;

  constructor(private authService:AuthService, private jobMatchService:JobMatchService, private alertsAndNotificationsService:AlertAndNotificationsService) { }

  ngOnInit() {
    //variables
    // this.contactNotes = [];
    this.newContactNote = null;
    this.userDetails = null;
    this.communicationTypesList = [
        {
            "id": 1,
            "name": "Outgoing Call",
            "value": "Outgoing Call"
        },
        {
            "id": 2,
            "name": "Incoming Call",
            "value": "Incoming Call"
        },
        {
            "id": 3,
            "name": "Outgoing Email",
            "value": "Outgoing Email"
        },
        {
            "id": 4,
            "name": "Incoming Email",
            "value": "Incoming Email"
        }
    ];
    this.durationTypesList = [
        {
            "id": 1,
            "name": "1 Minute",
            "value": "1"
        },
        {
            "id": 2,
            "name": "3 Minutes",
            "value": "3"
        },
        {
            "id": 3,
            "name": "5 Minutes",
            "value": "5"
        },
        {
            "id": 4,
            "name": "10 Minutes",
            "value": "10"
        },
        {
            "id": 5,
            "name": "15 Minutes",
            "value": "15"
        },
        {
            "id": 6,
            "name": "Other",
            "value": "Other"
        }
    ];
    this.userDetails = this.authService.getUserDetails();
    this._initializeNewContactNote();
    this._createSlimScroll();
    // set the type
    if(_.isNull(this.type) || (!_.isNull(this.type) && this.type.toString().trim() == '')) {
        this.type = 'notes';
    }
    // set the contactType type
    if(this.type == 'notes') {
        this.contactType = 'contactNote';
        this.displayType = 'Notes';

    } else {
        this.contactType = 'contactCommunication';
        this.displayType = 'Communication'
    }
    // get the notes and communications
    this.getNotesAndCommunications(() => {
        //this._createSlimScroll();
    });

}

setNotesAndCommunication(contactType) {
  this.contactNoteForm.resetForm();
    if(contactType == 'contactNote') {
        this.displayType = 'Notes'
        this.type = 'notes'
    } else {
        this.displayType = 'Communication'
        this.type = 'communication'
    }
}

_createSlimScroll() {
    setTimeout(() => {
        ($('.conversation-inner') as any).slimScroll({
            start: 'bottom',
            alwaysVisible: false,
            railVisible: false,
            wheelStep: 20,
            allowPageScroll: true
        });
    }, 70);

}

_destroySlimScroll() {
    $(".conversation-inner").parent().replaceWith($(".conversation-inner"));
}

_initializeNewContactNote() {
    this.newContactNote = new ContactNote();
    this.newContactNote.jobMatchId = this.jobMatchId;
    this.newContactNote.companyId = this.authService.getUserDetails().company.companyId;
    this.newContactNote.user.id = this.authService.getUserDetails().id;
    if(this.contactMethod){
        this.newContactNote.contactMethod = this.contactMethod;
        this.communicationTypeChanged();
    }
}

getNotesAndCommunications(successCallback) {
    this.loadingFlag = true;
    // make the contact notes array empty
    this.contactNotes = [];
    this.jobMatchService.getNotesAndCommunications(this.jobMatchId, (data) => {
        angular.forEach(data, (val, key) => {
            let tempContactNote = new ContactNote();
            angular.merge(tempContactNote, val);
            // set the user's full name
            tempContactNote.user.name = tempContactNote.user.firstName + ' ' + tempContactNote.user.lastName;
            tempContactNote.dateTime = new Date(tempContactNote.dateTime);
            this.contactNotes.push(tempContactNote);
        });
        this.loadingFlag = false;
        if(successCallback) {
            successCallback();
        }
    }, (error) => {
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
}

saveContactNote() {
    if(!this.contactNoteForm.invalid) {
        this.savingFlag = true;
        let contactNoteSaveObject = angular.copy(this.newContactNote);
        // if communication is being saved with other duration, capture the value from other field
        if(this.type == "communication" && !_.isNull(contactNoteSaveObject.duration) && contactNoteSaveObject.duration == 'Other') {
            contactNoteSaveObject.duration = this.durationOtherValue;
        }
        this.jobMatchService.saveNoteOrCommunication(contactNoteSaveObject, (data) => {
            // reset the saving flag
            this.savingFlag = false;
            // refresh the view
            this.getNotesAndCommunications(() => {
                // destroy old slim scroll
                this._destroySlimScroll();
                // create slim scroll again
                this._createSlimScroll();
            });
            // reset the new contact note
            this._initializeNewContactNote();
            // reset the form submitted value
            this.contactNoteForm.resetForm();
            // reset duration other value
            if(this.type == "communication") {
                this.durationOtherValue = '';
            }
            if(this.saveCallback) {
                this.saveCallback.emit()
            }
        }, (error) => {
            this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
        });
    }
}

deleteContactNote(contactNote) {
    if(confirm('Are you sure you want to delete this note ?')) {
        this.jobMatchService.deleteNoteOrCommunication(contactNote, (data) => {
            // refresh the view
            this.getNotesAndCommunications(() => {
                // destroy old slim scroll
                this._destroySlimScroll();
                // create slim scroll again
                this._createSlimScroll();
            });
        }, (error) => {
            this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
        });
    }
}

communicationTypeChanged() {
    if(this.newContactNote.contactMethod.toString().includes('Call')) {
        this.durationEnabledFlag = true;
    } else {
        this.durationEnabledFlag = false;
    }
}
  
}

<div class="row">
    <div class="col-lg-12">
        <div id="content-header" class="clearfix">
            <div class="pull-left">
                <ol class="breadcrumb">
                    <li><a ui-sref-opts="{reload: true}" ui-sref="missioncontrol.dashboard">Home</a></li>
                    <li class="active"><span>My Dashboard</span></li>
                </ol>
                <h1 class="heading-css">My Dashboard</h1>
            </div>
        </div>
    </div>
</div>
<div class="row" [hidden]="!showDetails">
    <div class="col-xs-12">
        <!-- Total Counts -->
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <div class="main-box project-box infographic-box" id="tour-dashboard-active-candidates">
                    <i class="fa fa-users emerald-bg"></i>
                    <span class="headline">Active Candidates</span>
                    <span class="value">
                        <span *ngIf="!candidateCountsLoadingFlag">
                            <a class="cursor-pointer"
                                (click)="redirectToCandidates('Active')">{{candidateCounts.activeCandidatesCount}}</a>
                        </span>
                        <span [hidden]="!candidateCountsLoadingFlag">
                            <span class="dashboard-total-count-loading-spinner fa fa-spinner fa-spin"></span>
                        </span>
                    </span>
                    <div class="project-box-footer clearfix">
                        <div [hidden]="candidateCountsLoadingFlag">
                            <div class="dashboard-count col-xs-4"
                                *ngFor="let counts of candidateCounts.activeJobTypesCandidateCount | keyvalue">
                                <span class="value" style="text-align: center;">{{counts.value}}</span>
                                <span class="label">{{counts.key}}</span>
                            </div>
                        </div>
                        <div class="dashboard-count-place-holders text-center" [hidden]="!candidateCountsLoadingFlag">
                            <div
                                class="dashboard-count col-xs-4 dashboard-count-place-holder dashboard-count-place-holder-loading">
                            </div>
                            <div
                                class="dashboard-count col-xs-4 dashboard-count-place-holder dashboard-count-place-holder-loading">
                            </div>
                            <div
                                class="dashboard-count col-xs-4 dashboard-count-place-holder dashboard-count-place-holder-loading">
                            </div>
                            <div
                                class="dashboard-count col-xs-4 dashboard-count-place-holder dashboard-count-place-holder-loading">
                            </div>
                            <div
                                class="dashboard-count col-xs-4 dashboard-count-place-holder dashboard-count-place-holder-loading">
                            </div>
                            <div
                                class="dashboard-count col-xs-4 dashboard-count-place-holder dashboard-count-place-holder-loading">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <div class="main-box project-box infographic-box" id="tour-dashboard-open-positions">
                    <i class="fa fa-briefcase green-bg"></i>
                    <span class="headline">Open Positions</span>
                    <span class="value">
                        <span [hidden]="requisitionCountsLoadingFlag">
                            <a class="cursor-pointer" (click)="redirectToRequisitions()">{{requisitionCount.total}}</a>
                        </span>
                        <span [hidden]="!requisitionCountsLoadingFlag">
                            <span class="dashboard-total-count-loading-spinner fa fa-spinner fa-spin"></span>
                        </span>
                    </span>
                    <div class="project-box-footer clearfix">
                        <div [hidden]="requisitionCountsLoadingFlag">
                            <div class="dashboard-count col-xs-4" *ngFor="let counts of requisitionCount | keyvalue"
                                [hidden]="counts.key === 'total'">
                                <span class="value" style="text-align: center;">{{counts.value}}</span>
                                <span class="label">{{counts.key}}</span>
                            </div>
                        </div>
                        <div class="dashboard-count-place-holders text-center" [hidden]="!requisitionCountsLoadingFlag">
                            <div
                                class="dashboard-count col-xs-4 dashboard-count-place-holder dashboard-count-place-holder-loading">
                            </div>
                            <div
                                class="dashboard-count col-xs-4 dashboard-count-place-holder dashboard-count-place-holder-loading">
                            </div>
                            <div
                                class="dashboard-count col-xs-4 dashboard-count-place-holder dashboard-count-place-holder-loading">
                            </div>
                            <div
                                class="dashboard-count col-xs-4 dashboard-count-place-holder dashboard-count-place-holder-loading">
                            </div>
                            <div
                                class="dashboard-count col-xs-4 dashboard-count-place-holder dashboard-count-place-holder-loading">
                            </div>
                            <div
                                class="dashboard-count col-xs-4 dashboard-count-place-holder dashboard-count-place-holder-loading">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <div class="main-box project-box infographic-box" id="tour-dashboard-hired-or-placed-candidates">
                    <i class="fa fa-users purple-bg"></i>
                    <span class="headline" *ngIf="isCorporate">Hired Candidate(YTD)</span>
                    <span class="headline" *ngIf="!isCorporate">Placed Candidate(YTD)</span>
                    <span class="value">
                        <span *ngIf="!candidateCountsLoadingFlag">
                            <span
                                style="pointer-events: none; cursor: default;">{{candidateCounts.hiredYTDJobTypeCandidateCount.total}}</span>
                        </span>
                        <span *ngIf="candidateCountsLoadingFlag">
                            <span class="dashboard-total-count-loading-spinner fa fa-spinner fa-spin"></span>
                        </span>
                    </span>
                    <div class="project-box-footer clearfix">
                        <div [hidden]="candidateCountsLoadingFlag">
                            <div class="dashboard-count col-xs-4"
                                *ngFor="let counts of candidateCounts.hiredYTDJobTypeCandidateCount | keyvalue"
                                [hidden]="counts.key === 'total'">
                                <span class="value" style="text-align: center;">{{counts.value}}</span>
                                <span class="label">{{counts.key}}</span>
                            </div>
                        </div>
                        <div class="dashboard-count-place-holders text-center" [hidden]="!candidateCountsLoadingFlag">
                            <div
                                class="dashboard-count col-xs-4 dashboard-count-place-holder dashboard-count-place-holder-loading">
                            </div>
                            <div
                                class="dashboard-count col-xs-4 dashboard-count-place-holder dashboard-count-place-holder-loading">
                            </div>
                            <div
                                class="dashboard-count col-xs-4 dashboard-count-place-holder dashboard-count-place-holder-loading">
                            </div>
                            <div
                                class="dashboard-count col-xs-4 dashboard-count-place-holder dashboard-count-place-holder-loading">
                            </div>
                            <div
                                class="dashboard-count col-xs-4 dashboard-count-place-holder dashboard-count-place-holder-loading">
                            </div>
                            <div
                                class="dashboard-count col-xs-4 dashboard-count-place-holder dashboard-count-place-holder-loading">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Recent Requisitions -->
        <div class="row">
            <div class="col-xs-12">
                <div class="main-box">
                    <header class="main-box-header clearfix">
                        <h2>Recent Requisitions</h2>
                    </header>
                    <div class="main-box-body clearfix p-4" id="tour-dashboard-recent-requisitions">
                        <app-recent-requisitions dashboard-view="true" limit="3"></app-recent-requisitions>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
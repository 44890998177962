import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { UserRoleService } from 'src/app/core/services/user-role.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { MESSAGECONSTANTS } from 'src/app/core/utils/constants';
import { UibModalService } from 'src/app/public/app/services/uibModalService';

declare var bootbox: any;

@Component({
  selector: 'app-role-entity-scope-page',
  templateUrl: './role-entity-scope-page.component.html',
  styleUrls: ['./role-entity-scope-page.component.css']
})
export class RoleEntityScopePageComponent implements OnInit {
  @ViewChild('roleEntityForm', null) roleEntityForm:NgForm;

  MESSAGECONSTANTS:any = MESSAGECONSTANTS;

  transactionRoleEntityScope:any;
  savedRoleEntityScope:any;
  scopeList:any;
  isCompanyAdmin:any;
  companyRoleScopeData:any;

  roleEntityScopeLoading:boolean = false;
  isLocationChange:boolean = false;
  isLocationChangeInitial:boolean = false;
  showConfirmationPopup:boolean = false;

  constructor(@Inject('$rootScope') public $rootScope, @Inject('$state') public $state, @Inject('uibModalService') public uibModalService:UibModalService, private userRoleService:UserRoleService, private utilityService:UtilityService, private alertsAndNotificationsService:AlertAndNotificationsService,
    private companyService:CompanyService, private authService:AuthService) { }

  ngOnInit() {
    this._getCompanyRoleScopeData();
    // this.isLocationChange = false;
    // this.isLocationChangeInitial = false;
    // this.roleEntityForm = {};
    // this.showConfirmationPopup = false;
    this.isCompanyAdmin = this.userRoleService.isLoggedInUserCompanyAdmin();

    this._initializeBrowserEvents();
}

  _getCompanyRoleScopeData() {
    this.roleEntityScopeLoading = false;
    this.utilityService.getCompanyRoleEntityScope().then((data) => {
      this.companyRoleScopeData = data ? data : "";
      this.transactionRoleEntityScope = _.cloneDeep(this.companyRoleScopeData);
      this.savedRoleEntityScope = _.cloneDeep(this.companyRoleScopeData);
      this.scopeList = _.cloneDeep(this.companyRoleScopeData.scopeList);
      this.roleEntityScopeLoading = true;
    })
  }

changeAllForClient(index) {
    this.transactionRoleEntityScope.scopeList[index].allClientsOrBU = true;
    this.transactionRoleEntityScope.scopeList[index].clientOrBUList = false;
}

changeListForClient(index) {
    this.transactionRoleEntityScope.scopeList[index].clientOrBUList = true;
    this.transactionRoleEntityScope.scopeList[index].allClientsOrBU = false;
}

changeAllForBU(index) {
    this.transactionRoleEntityScope.scopeList[index].allBUsOfClients = true;
    this.transactionRoleEntityScope.scopeList[index].buListOfClients = false;
}

changeListForBU(index) {
    this.transactionRoleEntityScope.scopeList[index].buListOfClients = true;
    this.transactionRoleEntityScope.scopeList[index].allBUsOfClients = false;
}

cancelChanges() {
    if(this._isScopeChanged()) {
        this.transactionRoleEntityScope = _.cloneDeep(this.savedRoleEntityScope);
        this.roleEntityForm.form.markAsPristine();
    }
}

_isScopeChanged() {
    return !_.isEqual(this.transactionRoleEntityScope, this.savedRoleEntityScope);
}

saveCompanyRES() {
    if(_.cloneDeep(this.savedRoleEntityScope.newScope)) {
        if(this._isScopeChanged()) {
            this._setCompanyRes();
        } else {
            this.alertsAndNotificationsService.showBannerMessage(this.MESSAGECONSTANTS.NOTIFICATIONS.NOTHING_TO_SAVE, 'success');
        }
    } else {
        if(this._isScopeChanged()) {
            this._updateCompanyRes();
        } else {
            this.alertsAndNotificationsService.showBannerMessage(this.MESSAGECONSTANTS.NOTIFICATIONS.NOTHING_TO_SAVE, 'success');
        }
    }
}

_setCompanyRes() {
    this.companyService.createRoleEntityScope(this.authService.getUserDetails().company.companyId, this.transactionRoleEntityScope, (data) => {
        this.transactionRoleEntityScope = _.cloneDeep(data);
        this.savedRoleEntityScope = _.cloneDeep(data);
        this.roleEntityForm.form.markAsPristine();
        let message = this.MESSAGECONSTANTS.NOTIFICATIONS.COMPANY_RES.SET_SUCCESS1 +
            this.authService.getUserDetails().company.companyName +
            this.MESSAGECONSTANTS.NOTIFICATIONS.COMPANY_RES.SET_SUCCESS2;
        this.alertsAndNotificationsService.showBannerMessage(message, 'success');
    }, (error) => {
         // Replace after angular route added
        //  this.authService.isOnline
        if(this.$rootScope.isOnline) {
            this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
        }
        this.transactionRoleEntityScope = _.cloneDeep(this.savedRoleEntityScope);
    });
}

_updateCompanyRes() {
    this.companyService.updateRoleEntityScope(this.$rootScope.userDetails.company.companyId, this.transactionRoleEntityScope, (data) => {
        this.transactionRoleEntityScope = _.cloneDeep(data);
        this.savedRoleEntityScope = _.cloneDeep(data);
        this.roleEntityForm.form.markAsPristine();
        let message = this.MESSAGECONSTANTS.NOTIFICATIONS.COMPANY_RES.UPDATE_SUCCESS1 +
            this.$rootScope.userDetails.company.companyName +
            this.MESSAGECONSTANTS.NOTIFICATIONS.COMPANY_RES.UPDATE_SUCCESS2;
        this.alertsAndNotificationsService.showBannerMessage(message, 'success');
    }, (error) => {
       // Replace after angular route added
        //  this.authService.isOnline
        if(this.$rootScope.isOnline) {
            this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
        }
        this.transactionRoleEntityScope = _.cloneDeep(this.savedRoleEntityScope);
    });
}

//Handle navigating away when data is unsaved
uiCanExit(transitions) {
    let toState = transitions.to().name;
    let toParams = transitions.params();

    if(this._isScopeChanged() && toState !== "login") {
        transitions.abort();
        bootbox.confirm({
            closeButton: false,
            title: "<div class='alert alert-warning' style='margin-bottom: 0px;'><i class='fas fa-exclamation-triangle fa-fw fa-lg'></i><strong>Warning!</strong></div>",
            message: "Unsaved data on the page will be lost. Do you still want to continue?",
            buttons: {
                confirm: {
                    label: 'Yes',
                    className: 'btn-success'
                },
                cancel: {
                    label: 'Cancel',
                    className: 'btn-danger'
                }
            },
            callback: (result) => {
                if(result) {
                    this.cancelChanges();
                    // Handling data change when clicked on Switch company
                    // Replace after converting to angular
                    // this.authService.isSwitchClicked
                    if(this.$rootScope.switchClicked) {
                        this.$rootScope.switchClicked = false;
                        this.authService.setIsSwitchClicked(false);
                        // this.companyService.showAllCompanies();
                        this.uibModalService.showAllCompanies();
                    } else {
                        this.$state.go(toState, toParams, { reload: true });
                    }
                } else {
                    if(this.$rootScope.switchClicked) {
                        this.$rootScope.switchClicked = false;
                        this.authService.setIsSwitchClicked(false);
                    }
                }
            }
        });
    } else {
        // If clicked on switch company without changing data
        if(this.$rootScope.switchClicked && !this._isScopeChanged()) {
            transitions.abort();
            setTimeout(() => {
                // this.companyService.showAllCompanies();
                this.uibModalService.showAllCompanies();
            }, 150);
            this.$rootScope.switchClicked = false;
            this.authService.setIsSwitchClicked(false);
        }
    }
  }

  goToDashboard() {
    // Replace after angular route added
    this.$state.go('missioncontrol.dashboard', null, { reload: true });
  }

_initializeBrowserEvents() {
    //Handle Browser back/forward change
    // let unbindLocationChangeEvent = this.$scope.$on('$locationChangeStart', (event, toState, toParams, fromState, fromParams) => {
    //     this.isLocationChange = this.isLocationChangeInitial;
    //     this.isLocationChangeInitial = true;
    // });
}

}

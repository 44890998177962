import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ResumeService {

  urlConstants:any = {
    GET_ALL_SOURCE_TYPES: 'api/resume/resumesourcetypes',
    UPLOAD_RESUME: 'api/resume/upload',
    RUN_RESUME_FOR_SCORING: 'api/resume/runresumesforscoring',
    GET_CANDIDATE_COUNT: 'api/resume/getcandidatecountbyresumetransaction',
    GET_RESUME_SOURCE_TYPES: 'api/resume/resumesourcetypes',
    RUN_RESUMES_WITHOUT_SCORING: 'api/resume/runresumeswithoutscoring',
    RUN_RESUME_SCORING_FOR_ASSOCIATE: 'api/resume/runresumesforassociate',
    GET_RESUME_DOCUMENT: 'api/resume/getresume',
    GET_RESUME_HR_XML_DOCUMENT: 'api/resume/getresumehrxml',
    GET_RESUME_REPORT_PDF: 'api/resume',
    SINGLE_UPLOAD_RESUME: 'api/resume/upload/async',
    DOWNLOAD_ALL_RESUMES: 'api/resume/unprocessed/zip',
    RE_RUN_RESUME_SCORE: 'api/resume/score/rerun'
  };

  constructor(@Inject(StorageService) private StorageService:StorageService, @Inject(GenericService) private genericService: GenericService) { }

  getAllSourceTypes(successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_SOURCE_TYPES).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getUploadUrl() {
    return this.StorageService.get('baseurl') + this.urlConstants.UPLOAD_RESUME;
  }

  getSingleUploadUrl() {
    return this.StorageService.get('baseurl') + this.urlConstants.SINGLE_UPLOAD_RESUME;
  }

  runResumesForScoring(parsingOutputObject, jobId, successCallback, errorCallback) {
    let url = this.StorageService.get('baseurl') + this.urlConstants.RUN_RESUME_FOR_SCORING + '/' + 'ResumeTransaction';
    if (!_.isNull(jobId)) {
      url += '?jobId=' + jobId;
    }
    this.genericService.addObject(url, parsingOutputObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  runResumeScoringForAssociate(parsedResumeOutput, candidateId, jobId, isUpdate, successCallback, errorCallback) {
    let url = this.StorageService.get('baseurl') + this.urlConstants.RUN_RESUME_SCORING_FOR_ASSOCIATE + '/' + 'ResumeTransaction' + '/' + candidateId + '?updateCandidate=' + isUpdate;
    if (!_.isNull(jobId) && jobId !== undefined) {
      url += '&jobId=' + jobId;
    }
    this.genericService.addObject(url, parsedResumeOutput).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getCandidateCount(transactionId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_CANDIDATE_COUNT + '/' + transactionId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getResumeSourceTypes(companyId, buId, successCallback, errorCallback) {
    let url = this.StorageService.get('baseurl') + this.urlConstants.GET_RESUME_SOURCE_TYPES;
    if (!_.isNull(companyId) && !_.isUndefined(companyId)) {
      url += '?companyId=' + companyId;
    }
    if (!_.isNull(buId)) {
      let separator = url.includes("companyId") ? '&' : '?';
      url = url + separator + 'buId=' + buId;
    }

    this.genericService.getObjects(url).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  runResumesWithoutScoring(parsingOutputObject, jobId, successCallback, errorCallback) {
    let url = this.StorageService.get('baseurl') + this.urlConstants.RUN_RESUMES_WITHOUT_SCORING + '/' + 'ResumeTransaction';
    if (!_.isNull(jobId)) {
      url += '?jobId=' + jobId;
    }
    this.genericService.addObject(url, parsingOutputObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getResumeDocumentUrl(candidateId) {
    return this.StorageService.get('baseurl') + this.urlConstants.GET_RESUME_DOCUMENT + '/' + candidateId;
  }

  getResumeHrXmlDocumentUrl(candidateId) {
    return this.StorageService.get('baseurl') + this.urlConstants.GET_RESUME_HR_XML_DOCUMENT + '/' + candidateId;
  }

  getResumeReportPdfUrl(candidateId) {
    return this.StorageService.get('baseurl') + this.urlConstants.GET_RESUME_REPORT_PDF + '/' + candidateId + "/report";
  }

  getResumeDownloadPdfUrl(candidateId) {
    return this.StorageService.get('baseurl') + this.urlConstants.GET_RESUME_REPORT_PDF + '/' + candidateId + "/pdf_download";
  }

  async downloadAllResumes(unprocessedResumeObject, successCallback, errorCallback) {
    let url = this.StorageService.get('baseurl') + this.urlConstants.DOWNLOAD_ALL_RESUMES;

    let headers = new HttpHeaders()
    .set('accept', 'application/zip')
    .set('content-type','application/json')

    this.genericService.addObjectWithHeadersAndResponseType(url, unprocessedResumeObject, headers).subscribe((data) => {
      successCallback(data);
    }, (error) => {
      errorCallback(error);
    });
  }
  
  reRunResumeScore(object, successCallback, errorCallback) {
    let url = this.StorageService.get('baseurl') + this.urlConstants.RE_RUN_RESUME_SCORE;
    this.genericService.addObject(url, object).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

}

<div class="form-group wrap-text-in" *ngIf="isLoading">
    <div class="col-xs-12 loading-spinner add-candidate-loading-spinner text-center">
        <span>Loading</span>
        <span class="fa fa-spinner fa-spin"></span>
    </div>
</div>

<form *ngIf="!isLoading" #actionform="ngForm" (submit)="actionform.valid  && deleteEntity()" novalidate>
    <!-- Candidate -->
    <div class="form-group wrap-text-in" *ngIf="entity == 'candidate'">
        <label class="modal-body-font-size ng-scope">
            <span class="emerald">'Delete' </span>the
            candidate
            <span class="emerald "> '{{candidateInfo.candidateName}}'. </span>
        </label>
        <br>
        <p>This action cannot be <b>undone</b>.</p>
        <p>All the job matches of the candidate will be deleted. Number of Job(s) Matched is <span
                class="emerald">{{candidateInfo.jobMatchCount}}</span>.</p>
        <p>The candidate will then be deleted.</p>
        <p>Are you sure you want to continue?</p>
    </div>

    <!-- Company -->
    <div class="form-group wrap-text-in" *ngIf="entity == 'company'">
        <label class="modal-body-font-size ng-scope">
            <span class="emerald">'Delete' </span>the
            company
            <span class="emerald "> '{{companyInfo.companyName}}'. </span>
        </label>
        <br>
        <p>This action cannot be <b>undone</b>.</p>
        <p>All Candidates will be deleted. The number of Candidate(s) is <span
                class="emerald">{{companyInfo.candidatesCount}}</span>.</p>
        <p>All Requisitions will be deleted. The number of Requisition(s) is <span
                class="emerald">{{companyInfo.requisitionsCount}}</span>.</p>
        <p>All Users will be deleted. The number of User(s) is <span class="emerald">{{companyInfo.usersCount}}</span>.
        </p>
        <p *ngIf="isStaffingCompany()">All Clients will be deleted. The number of Client(s) is <span
                class="emerald">{{companyInfo.clientOrBuCount}}</span>.</p>
        <p *ngIf="!isStaffingCompany()">All BUs will be deleted. The number of Bu(s) is <span
                class="emerald">{{companyInfo.clientOrBuCount}}</span>.</p>
        <p>Are you sure you want to continue?</p>
    </div>

    <!-- Client or BU -->
    <div class="form-group wrap-text-in" *ngIf="entity == 'clientOrBU'">
        <label class="modal-body-font-size ng-scope">
            <span class="emerald">'Delete' </span>the <span *ngIf="isStaffingCompany()">Client</span><span
                *ngIf="!isStaffingCompany()">BU</span>
            <span class="emerald "> '{{clientOrBuInfo.clientOrBUName}}'. </span>
        </label>
        <br>
        <p>This action cannot be <b>undone</b>.</p>
        <p>This client will be removed from the scope of <span class="emerald">{{clientOrBuInfo.usersCount}}</span>
            user(s).</p>
        <div *ngIf="isStaffingCompany()">
            <p>The requisitions associated with this Client will be deleted.</p>
            <p> The number of requisition(s) are <span class="emerald">{{clientOrBuInfo.requisitionsCount}}</span>.</p>
            <p>The users associated with this Client will be deleted.</p>
        </div>
        <div *ngIf="!isStaffingCompany()">
            <p>The requisitions associated with this BU will be deleted.</p>
            <p>The number of requisition(s) are <span class="emerald">{{clientOrBuInfo.requisitionsCount}}</span>.</p>
        </div>
        <p>Are you sure you want to continue?</p>
    </div>

    <!-- User -->
    <div class="form-group wrap-text-in" *ngIf="entity == 'user'">
        <label class="modal-body-font-size ng-scope">
            <span class="emerald">'Delete' </span>the
            user
            <span class="emerald"> '{{userInfo.fullName}}'.</span>
        </label>
        <br>
        <p>This action cannot be <b>undone</b>.</p>
        <p>This user will be removed as the primary recruiter of candidates that he/she was handling.</p>
        <p>These candidates will need to be assigned to another recruiter.
            The number of candidate(s) that need to be re-assigned is
            <span class="emerald"> {{userInfo.numberOfCandidates}}</span>.
        </p>
        <p>The user will also be removed from requisitions that they are managing.
            The number of requisition(s) being managed by this user is
            <span class="emerald"> {{userInfo.numberOfRequisitions}}</span>.
        </p>
        <div *ngIf="userInfo.requisitionDetails.length>0">
            This user is the only user on following requisition(s).
            <br>
            <div class="table-responsive" style="margin-top:10px">
                <table class="table" id="requisitionsTable">
                    <thead>
                        <tr>
                            <th class="text-center">
                                Requisition #
                            </th>
                            <th class="text-center">
                                Title
                            </th>
                            <th class="text-center" *ngIf="isStaffingCompany()">
                                Client
                            </th>
                            <th class="text-center" *ngIf="!isStaffingCompany()">
                                BU
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let requisition of userInfo.requisitionDetails">
                            <td class="text-center">
                                <div>{{requisition.requisitionNumber}}</div>
                            </td>
                            <td class="text-center">
                                <span>{{requisition.jobTitle}}</span>
                            </td>
                            <td class="text-center">
                                {{requisition.clientOrBuName}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p>
                The user’s manager <span class="emerald ">'{{userInfo.requisitionDetails[0].managerName}}'</span> will
                be
                assigned these requisition.
            </p>
        </div>
        <p>
            Are you sure you want to continue?
        </p>
    </div>

    <!-- Employee -->

    <div class="form-group wrap-text-in" *ngIf="entity == 'employee'">
        <p class="modal-body-font-size ng-scope">
            <span class="emerald">'Delete' </span>the
            employee
            <span class="emerald"> '{{employeeInfo.name}}'.</span>
        </p>
        <span *ngIf="employeeMeetingsInfo.meetingPresent">
            <p ng-bind-html="employeeMeetingsInfo.meetingsMainMessage"></p>
            <p class="form-group"
                *ngIf="employeeMeetingsInfo.permissibleUsersToReassign !== null && employeeMeetingsInfo.permissibleUsersToReassign.length>0">
                Reassign {{employeeMeetingsInfo.existingMeetingMessage}}
                <span *ngIf="!isStaffingCompany() && employeeInfo.bu !== null"> of Employee's BU <span
                        class="emerald">'{{employeeInfo.bu.name}}'</span></span>
                to
                <select class="form-control d-inline-block" style="width:200px" name="assignToUsers" id="assignToUsers"
                    [(ngModel)]="employeeMeetingsInfo.newParticipantUserId" required>
                    <option value="{{user.userId}}"
                        *ngFor="let user of employeeMeetingsInfo.permissibleUsersToReassign">{{user.firstName}}
                        {{user.lastName}}</option>
                </select>
            </p>
            <p
                *ngIf="employeeMeetingsInfo.permissibleUsersToReassign == null || employeeMeetingsInfo.permissibleUsersToReassign.length==0">
                Since there are no permissible employees or users to whom the
                {{employeeMeetingsInfo.existingMeetingMessage}} can be assigned, they will be assigned to the user who
                is managing the scheduling of {{employeeMeetingsInfo.existingMeetingMessage}}.
            </p>
            <span *ngIf="employeeMeetingsInfo.hasMeetingToDelete && !isStaffingCompany()">
                <p>
                    Phone Screens and/or Interviews of <b>other {{belongsToBuOrDepartmentText}}s</b> assigned to this
                    Employee
                    will be assigned to the user who is managing the scheduling of Phone Screens and/or Interviews.
                </p>
            </span>
        </span>
        <p>This action cannot be <b>undone</b>.</p>
        <p>
            Are you sure you want to continue?
        </p>
    </div>

    <!-- Corp Vendor Relationship -->
    <div class="form-group wrap-text-in" *ngIf="entity == 'corpVendorRelationship'">
        <label class="modal-body-font-size ng-scope">
            <p>Do you want to disassociate from Vendor <span class="emerald "> '{{corpVendorInfo.vendorName}}'</span>?
            </p>
        </label>
        <br>
        <p>
            <span class="emerald">{{corpVendorInfo.requisitionCount}}</span> Requisition(s) <span
                *ngIf="corpVendorInfo.buCount > 0">across <span class="emerald">{{corpVendorInfo.buCount}}</span>
                BU(s)</span> will no longer be shared with the Vendor.
        </p>
        <p>Are you sure you want to continue?</p>
    </div>

    <!-- BU Vendor Relationship -->
    <div class="form-group wrap-text-in" *ngIf="entity == 'buVendorRelationship'">
        <label class="modal-body-font-size ng-scope">
            <p *ngIf="buVendorInfo.associatedVendors.length>0">
                Associating Vendor(s)
                <span *ngFor="let vendor of buVendorInfo.associatedVendors; let $index = index">
                    <span *ngIf="$index == buVendorInfo.associatedVendors.length - 1"
                        class="emerald">'{{vendor.vendorName}}'</span>
                    <span *ngIf="$index == buVendorInfo.associatedVendors.length - 2"> <span
                            class="emerald">'{{vendor.vendorName}}'</span> and</span>
                    <span
                        *ngIf="$index !== buVendorInfo.associatedVendors.length - 1 && $index !== buVendorInfo.associatedVendors.length - 2">
                        <span class="emerald">'{{vendor.vendorName}}'</span>,</span>
                </span>
            </p>
            <p>Do you want to disassociate from Vendor(s)
                <span *ngFor="let vendor of buVendorInfo.disassociatedVendors; let $index = index">
                    <span *ngIf="$index == buVendorInfo.disassociatedVendors.length - 1"
                        class="emerald">'{{vendor.vendorName}}'</span>
                    <span *ngIf="$index == buVendorInfo.disassociatedVendors.length - 2"> <span
                            class="emerald">'{{vendor.vendorName}}'</span> and</span>
                    <span
                        *ngIf="$index !== buVendorInfo.disassociatedVendors.length - 1 && $index !== buVendorInfo.disassociatedVendors.length - 2">
                        <span class="emerald">'{{vendor.vendorName}}'</span>,</span>
                </span>
                ?
            </p>
        </label>
        <br>
        <p *ngFor="let vendor of buVendorInfo.disassociatedVendors; let $index = index">
            <span class="emerald">{{vendor.requisitionCount}}</span> Requisition(s) will no longer be shared with the
            <span class="emerald">'{{vendor.vendorName}}'</span>.
        </p>
        <p>Are you sure you want to continue?</p>
    </div>
    <div class="form-group">
        <div class="row">
            <div class="col-xs-6">
                <label for="currentDate">Date</label>
                <div class="input-group">
                    <span class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                    </span>
                    <input #currentDate="ngModel" type="text"
                        class="form-control access ng-pristine ng-untouched ng-valid ng-valid-required"
                        placeholder="mm-dd-yyyy" id="currentDate" name="currentDate" [(ngModel)]="date" required
                        (focus)="currentDate.focused = true" (blur)="currentDate.focused = false" [disabled]="true">
                </div>
            </div>

            <div class="col-xs-6">
                <label for="time">Time</label>
                <div class="input-group">
                    <span class="input-group-addon">
                        <i class="far fa-clock"></i>
                    </span>
                    <input #currentTime="ngModel" type="text"
                        class="form-control access ng-untouched ng-dirty ng-valid ng-valid-required"
                        placeholder="hh:mm:ss" [(ngModel)]="time" id="currentTime" name="currentTime" required
                        [disabled]="true">
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="entity !== 'buVendorRelationship'" class="form-group"
        [ngClass]="{'has-error': (actionform.submitted && currentNote.invalid)}">
        <label>Note <em> * </em></label>
        <textarea #currentNote="ngModel" id="currentNote" name="currentNote" class="form-control"
            style="height: 100px; width: 100%; resize: none;" [maxlength]="503" [(ngModel)]="note" required
            (focus)="currentNote.focused = true" (blur)="currentNote.focused = false">
        </textarea>
        <div class="error error-gap" *ngIf="actionform.submitted">
            <small *ngIf="currentNote.errors?.required" class=""><em>*</em> Note is required</small>
        </div>
    </div>
    <div *ngIf="entity == 'buVendorRelationship'" class="form-group"
        [ngClass]="{'has-error': (actionform.submitted && currentDeleteNote.invalid)}">
        <label>Note <em> * </em></label>
        <textarea #currentDeleteNote="ngModel" id="currentDeleteNote" name="currentDeleteNote" class="form-control"
            style="height: 100px; width: 100%; resize: none;" [maxlength]="503" [(ngModel)]="deleteNote" required
            (focus)="currentDeleteNote.focused = true" (blur)="currentDeleteNote.focused = false">
        </textarea>

        <div class="error error-gap" *ngIf="actionform.submitted">
            <small *ngIf="currentDeleteNote.errors?.required" class=""><em>*</em> Note is required</small>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-12 text-right">
            <div class="clearfix">
                <button type="button" class="btn btn-danger mr-2" [disabled]="deletingFlag"
                    (click)="cancel()">Cancel</button>
                <button type="submit" class="btn btn-success" [disabled]="deletingFlag">
                    <span *ngIf="!deletingFlag && entity !== 'buVendorRelationship'">Delete</span>
                    <span *ngIf="deletingFlag && entity !== 'buVendorRelationship'">Deleting..</span>
                    <span *ngIf="!deletingFlag && entity == 'buVendorRelationship'">Disassociate</span>
                    <span *ngIf="deletingFlag && entity == 'buVendorRelationship'">Disassociating..</span>
                </button>
            </div>
        </div>
    </div>
</form>
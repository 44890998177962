import { Inject, Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { GenericService } from './generic.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  urlConstants: any = {
    GET_DEPARTMENTS: 'api/employee/getdepartments',
    SEARCH_EMPLOYEES: 'api/employee/searchemployees',
    ADD_EDIT_EMPLOYEE: 'api/employee/createorupdate',
    GET_EMPLOYEE: 'api/employee/getemployee',
    GET_USER_BUS: 'api/employee/getbusbyuserscope',
    SET_EMPLOYEE_TO_USER: 'api/employee/convertemployeetouser',
    SET_USER_TO_EMPLOYEE: 'api/employee/convertusertoemployee',
    GET_ORGANIZATIONAL_MANAGERS_FOR_EMPLOYEE: 'api/employee/getorganizationalmanagers',
    GET_PERMISSIBLE_APPLICATION_MANAGERS: 'api/employee/getPermissibleManagers',
    UPLOAD_EMPLOYEE_FILE: 'api/employee/uploademployees',
    DELETE_EMPLOYEE: 'api/employee/deleteemployee',
    GET_EMPLOYEE_MEETINGS: 'api/employee/getexistingmeetinginfos'
  };

  constructor(@Inject(GenericService) private genericService:GenericService, @Inject(StorageService) private StorageService:StorageService) { }

  getDepartments(searchObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_DEPARTMENTS, searchObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getDepartmentsPromiseForTypeAhead(searchObject) {
    return new Promise((resolve, reject) => {
      this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_DEPARTMENTS, searchObject).subscribe((data) => {
        resolve(data.body)
      })
    })
  }

  searchEmployees(searchObject, successCallback, errorCallback) {
    let promise = this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.SEARCH_EMPLOYEES, searchObject);
    promise.subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
    return promise;
  }

  addUpdateEmployee(employeeData, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_EDIT_EMPLOYEE, employeeData).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getEmployee(employeeId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_EMPLOYEE + '/' + employeeId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getPermissibleBUsForUser(searchObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_USER_BUS, searchObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  setEmployeeToUser(employeeToUserObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.SET_EMPLOYEE_TO_USER, employeeToUserObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  setUserToEmployee(userToEmployeeObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.SET_USER_TO_EMPLOYEE, userToEmployeeObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getOrganizationalManagers(searchObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_ORGANIZATIONAL_MANAGERS_FOR_EMPLOYEE, searchObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getOrganizationalManagersPromiseForTypeAhead(searchObject) {
    return new Promise((resolve, reject) => [
      this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_ORGANIZATIONAL_MANAGERS_FOR_EMPLOYEE, searchObject).subscribe((data) => {
        resolve(data.body)
      })
    ])
  }

  getPermissibleManagers(searchObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_PERMISSIBLE_APPLICATION_MANAGERS, searchObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getPermissibleManagersPromiseForTypeAhead(searchObject) {
    return new Promise((resolve, reject) => {
      this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_PERMISSIBLE_APPLICATION_MANAGERS, searchObject).subscribe((data) => {
        resolve(data.body)
      })
    })
  }

  getUploadEmployeesUrl() {
    return this.urlConstants.UPLOAD_EMPLOYEE_FILE;
  }

  deleteEmployee(employee, successCallback, errorCallback) {
    this.genericService.deleteObject(this.StorageService.get('baseurl') + this.urlConstants.DELETE_EMPLOYEE, employee).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getEmployeeMeetings(employeeId, companyId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_EMPLOYEE_MEETINGS + '/' + employeeId + '/' + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }
}

import { Component, OnInit, Input, ViewChild, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { GenericService } from 'src/app/core/services/generic.service';
import { UserRoleService } from 'src/app/core/services/user-role.service';
import { UserService } from 'src/app/core/services/user.service';
import { MESSAGECONSTANTS } from 'src/app/core/utils/constants';

@Component({
  selector: 'app-change-password-page',
  templateUrl: './change-password-page.component.html',
  styleUrls: ['./change-password-page.component.css']
})
export class ChangePasswordPageComponent implements OnInit {
  @ViewChild('changepasswordform', null) changepasswordform:NgForm;
  @Input() $transition$;

  CHANGE_PASSWORD_CONSTANTS: any = {
    CONTROLLER: {
      CHANGE_PASSWORD: 'api/access/changepassword',
      AUTHENTICATE_OTP: 'auth/verifyonetimepassword'
    }
  }
  MESSAGECONSTANTS:any = MESSAGECONSTANTS;

  stateParams:any;
  user:any;
  passwordRestrictions:any;
  currentCompanyId:any;
  message: any;
  policyChangePasswordUpdate:any;
  parsedRestrictions:any;
  validationPattern:any;
  errormessage:any;

  isSettingPassword:boolean = false;
  isNewAndConfirmPasswordEqual:boolean = true;
  showValidation:boolean = false;

  constructor(@Inject('$state') public $state:any, @Inject('$rootScope') public $rootScope:any, private authService:AuthService, private userRoleService:UserRoleService, 
    private alertsAndNotificationsService:AlertAndNotificationsService, private userService:UserService,
    private genericService:GenericService) { }

  ngOnInit() {
    this.stateParams = this.$transition$.params();
    this.user = {};
    this.passwordRestrictions = {};
    this.currentCompanyId = this.authService.getUserDetails().company.companyId;
    this.isSettingPassword = false;
    this.isNewAndConfirmPasswordEqual = true;
    this.showValidation = false;
    this.message = this.stateParams.message;
    this.policyChangePasswordUpdate = this.stateParams.policyChangePasswordUpdate;

    this.getPasswordRestrictions();
}

getPasswordRestrictions() {
    this.authService.getPasswordRestrictionsByCompanyId(this.currentCompanyId, (data) => {
        if(this.userRoleService.isLoggedInUserAnAdmin()) {
            this.passwordRestrictions = angular.copy(data[1]);
        } else {
            this.passwordRestrictions = angular.copy(data[0]);
        }
        this.setPasswordRestrictions();
    }, (error) => {
        this.alertsAndNotificationsService.showBannerMessage(error.message, "danger");
    });
}

setPasswordRestrictions() {
    this.parsedRestrictions = angular.copy(this.userService.getParsedPasswordRestrictions(this.passwordRestrictions));
    this.validationPattern = new RegExp(this.parsedRestrictions.validationPattern);
    this.showValidation = true;
}

compareCurrentAndNewPassword(){
    if(this.user.currentPassword === this.user.newPassword) {
        // this.form.changepasswordform.$setValidity('unMatched', false);
        // this.form.changepasswordform.newpassword.$setValidity('unMatched', false);
    } else {
        // this.form.changepasswordform.$setVali5dity('unMatched', true);
        // this.form.changepasswordform.newpassword.$setValidity('unMatched', true);
    }
}

compare() {
    this.isNewAndConfirmPasswordEqual = this.user.verifyPassword === this.user.newPassword;
    this.compareCurrentAndNewPassword();
}

changePassword() {
    this.isSettingPassword = true;
    // this.user.email = this.$rootScope.userDetails.emailId;
    this.user.email = this.authService.getUserDetails().emailId;
    this.user.verifyPassword = this.user.newPassword;
    this.user.policyChangePasswordUpdate = this.policyChangePasswordUpdate;
    console.log('Requested for Resetting Password : ' + angular.toJson(this.user));
    this.genericService.addObject(this.CHANGE_PASSWORD_CONSTANTS.CONTROLLER.CHANGE_PASSWORD, this.user).subscribe((data) => {
        if(data.body.statusCode === '401' || data.body.statusCode === '400') {
            this.changepasswordform.resetForm(this.user);
            this.errormessage = data.body.message;
            this.isSettingPassword = false;
        } else {
            this.alertsAndNotificationsService.showBannerMessage(data.body.message, 'success');
            this.isSettingPassword = false;
            console.log(this.authService.getUserDetails().company.companyType = this.MESSAGECONSTANTS.COMPANY_TYPES.FOURDOTFIVE);
            // Replace after angular route added
            this.$state.go('missioncontrol.dashboard', null, { reload: true });
        }
    }, (error) => {
        if(error.statusCode === '401') {
            this.changepasswordform.resetForm(this.user)
            this.errormessage = error.message;
            this.isSettingPassword = false;
             // Replace after angular route added
            //  this.authService.isOnline
        } else if(this.$rootScope.isOnline) {
            this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
        }
    });
}

}

import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AngularModalService } from 'src/app/core/services/modal.service';
import { Observable, of, EMPTY, Subject } from 'rxjs';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { ManageSkillsService } from 'src/app/core/services/manage-skills.service';
import { TagInputComponent } from 'ngx-chips';
import { ModalConfig } from 'src/app/core/models/modalConfig';
import { NgForm } from '@angular/forms';
import { NewSkill } from 'src/app/core/models/newSkill';



@Component({
  selector: 'app-update-delete-selected-skills',
  templateUrl: './update-delete-selected-skills.component.html',
  styleUrls: ['./update-delete-selected-skills.component.css']
})
export class UpdateDeleteSelectedSkillsComponent implements OnInit {

  @ViewChild('parentSkillForm', null) parentSkillForm: NgForm;

  public onClose: Subject<any>;
  parentSkillModal: BsModalRef;

  modalData: any;
  updatedSelectedSkillCategory: any;
  selectedParentSkill: any = [];
  skillsNotUpdated: any;
  newSkill: any = {};
  isMainSkillExists: boolean = false;
  isAlternateSkillExists: boolean = false;
  isLoading: boolean = false;
  isSkillsUpdated: boolean = false;

  constructor(
    public bsModalRef: BsModalRef,
    private angularModalService: AngularModalService,
    public bsModalService: BsModalService,
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private skillsService: ManageSkillsService
  ) {
    this.onClose = new Subject();
    if (this.bsModalService.config.initialState) {
      this.modalData = this.bsModalService.config.initialState;
    }
  }

  ngOnInit() {
    if (this.modalData.type == 'delete') {
      this._setSelectedDeleteSkills()
    } else {
      this._setSelectedUpdatedSkills();
    }
  }

  _setSelectedUpdatedSkills() {
    this.modalData.allSelectedSkills.map(skill => {
      if(!skill.alternate) {
        this.isMainSkillExists = true;
      } else {
        this.isAlternateSkillExists = true;
      }
    });

    this.modalData.allParentSkills.map(parentSkill => {
      parentSkill.display = parentSkill.skill;
      parentSkill.value = parentSkill.skill;
    });

    this.modalData.allParentSkills.push({ id: 'addTag', name: 'Add Parent Skill', display: 'Add Parent Skill', value: 'ADD_TAG' });
    this.newSkill = JSON.parse(JSON.stringify(new NewSkill()));
  }

  _getSkillCategoryDisplayName(category) {
    if (this.modalData.skillCategories) {
      return this.modalData.skillCategories.filter(data => (data.value.toLowerCase()) == (category.toLowerCase()))[0].name;
    }
  }

  closeModal(data) {
    this.onClose.next(data);
    this.bsModalRef.hide();
  }

  _setSelectedDeleteSkills() {
    let allDeletedSkills = this.modalData.allSelectedSkills.map(skill => {
      let tempSkill = skill.deletingSkill;
      tempSkill.effectedChildSkills = skill.effectedChildSkills;
      tempSkill.effectedPrimarySkills = skill.effectedPrimarySkills;
      tempSkill.grandParentSkill = skill.grandParentSkill;
      tempSkill.effectedChildSkillDisplayMessage = '';
      tempSkill.deletingMessage = '';
      if (tempSkill.parent && tempSkill.parentSkills && tempSkill.parentSkills.length > 0 && tempSkill.effectedChildSkills && tempSkill.effectedChildSkills.length > 0) {
        let affectedChildSkills = [];
        tempSkill.effectedChildSkills.map(skill => {
          if (!skill.alternate) {
            affectedChildSkills.push(`<b>${skill.skill}</b>`);
          }
        });
        tempSkill.effectedChildSkillDisplayMessage = `<p>This skill has '${affectedChildSkills.slice(0,3).toString()}${affectedChildSkills.length > 3 ? '...' + `<b>(${affectedChildSkills.slice(3).length})</b>` : ''}' as child skill${affectedChildSkills.length > 1 ? '(s)' : ''}.</p>`;
        tempSkill.deletingMessage = `<p>All the child skills are being assigned to the parent skill '<b>${tempSkill.parentSkills[0].skill}</b>'. </span></p>`;
      } else {
        if (((!tempSkill.effectedChildSkills || tempSkill.effectedChildSkills.length == 0) && (!tempSkill.effectedPrimarySkills || tempSkill.effectedPrimarySkills.length == 0))) {
          if (tempSkill.alternateSkills && tempSkill.alternateSkills.length > 0) {
            tempSkill.deletingMessage = `<p>All the <b>Alternate Skills</b> associated with this skill will be deleted.`
          }
        } else {
          if (tempSkill.alternate) {
            let affectedPrimarySkills = tempSkill.effectedPrimarySkills.map(skill => {
              if (!skill.alternate) {
                return `<b>${skill.skill}</b>`
              }
            });
            tempSkill.deletingMessage = `<p>Alternate skill will be removed from the '${affectedPrimarySkills.toString()}' primary skill.</p>`;
          } else if (tempSkill.parent) {
            if (!tempSkill.parentSkills || tempSkill.parentSkills.length == 0) {
              if (tempSkill.effectedChildSkills && tempSkill.effectedChildSkills.length > 0) {
                let affectedChildSkills = [];
                tempSkill.effectedChildSkills.map(skill => {
                  if (!skill.alternate) {
                    affectedChildSkills.push(`<b>${skill.skill}</b>`);
                  }
                });
                tempSkill.effectedChildSkillDisplayMessage = `<p>This skill has '${affectedChildSkills.slice(0,3).toString()}${affectedChildSkills.length > 3 ? '...' + `<b>(${affectedChildSkills.slice(3).length})</b>` : ''}' as child skill${affectedChildSkills.length > 1 ? '(s)' : ''}.</p>`;
              }
              tempSkill.deletingMessage = `<p>Deleting this skill will remove parent skill association for all its child skills.`;
            }
          }
        }
      }
      return tempSkill;
    });

    this.modalData.allSelectedSkills = [...allDeletedSkills];
  }

  updateSkills(allSkills, successCallback, errorCallback) {
    this.isLoading = true;
    this.skillsService.updateAllSkills(allSkills, (data) => {
      if(successCallback) {
        successCallback(data);
      }
      this.isLoading = false;
      this.isSkillsUpdated = true;
      this.alertsAndNotificationsService.showBannerMessage("Skills updated successfully", "success");
    }, (error) => {
      if(error) {
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
        if(errorCallback) {
          errorCallback(error);
        }
        this.isLoading = false;
      }
    });
  }

  onAddingParentSkill = (tag): Observable<string> => {
    if (tag != "" && tag.id && tag.id != 'addTag') {
      return of(tag);
    } else {
      return EMPTY;
    }
  }

  onParentSkillTextChange(event) {
    this.modalData.allParentSkills.map(parentSkill => {
      if (parentSkill.id == 'addTag') {
        parentSkill.skill = event;
        parentSkill.display = event;
      }
    });
  }

  _checkIfParentOrAlternateSkillDuplicate(allSkills, newSkill) {
    let tempAllSkills = [...(allSkills ? allSkills.filter(skill => skill.id != 'addTag') : [])];
    tempAllSkills = [...tempAllSkills, ...this.modalData.allSelectedSkills];
    return tempAllSkills.some(skill => skill.skill.toLowerCase() === newSkill.toLowerCase());
  }

  addSkillOnEnter(event: any, input: TagInputComponent): void {
    let newSkill = event.target.value;
    if (newSkill && newSkill != '') {
      if (this.updatedSelectedSkillCategory && this.updatedSelectedSkillCategory.value) {
        if (!this._checkIfParentOrAlternateSkillDuplicate(this.modalData.allParentSkills, newSkill)) {
          let tempSkill = this._setNewSkill(this.newSkill);
          tempSkill.skill = newSkill;
          tempSkill.parentSkills = null;
          tempSkill.alternateSkills = [];
          tempSkill.category = (this.updatedSelectedSkillCategory && this.updatedSelectedSkillCategory.value) ? this.updatedSelectedSkillCategory.value : 'TECHNICAL';
          this.selectedParentSkill = [tempSkill];
          event.target.value = '';
          if (input) {
            input.setInputValue('');
          }
        } else {
          this.alertsAndNotificationsService.showAlert("Duplicate", "Skill Name already exists.", "warning");
        }
      } else {
        this.alertsAndNotificationsService.showAlert("Select Category", "Please select skill category.", "warning");
      }
    }
  }

  _checkIfSkillNameDuplicate(skill, skillObj) {
    return skill.toLowerCase() == skillObj.skill.toLowerCase();
  }

  _checkIfCategorySelected(skill) {
    return (skill.category && skill.category != '');
  }

  addAlternateSkillOnEnter(event: any, input: TagInputComponent): void {
    let alternateSkill = event.target.value;
    if (alternateSkill && alternateSkill != '') {
      if (this._checkIfCategorySelected(this.newSkill)) {
        if (!(this._checkIfParentOrAlternateSkillDuplicate(this.modalData.allParentSkills, alternateSkill) || !this._checkIfSkillNameAndAlternateOrParentSame(this.newSkill, alternateSkill))) {
          let tempSkill = this._setNewSkill(new NewSkill());
          tempSkill.skill = alternateSkill;
          tempSkill.parentSkills = null;
          tempSkill.category = this.newSkill.category;
          this.newSkill.alternateSkills.push(tempSkill);
          if (input) {
            input.setInputValue('');
          }
        } else {
          this.alertsAndNotificationsService.showAlert("Duplicate", "Skill Name already exists.", "warning");
        }
      } else {
        this.alertsAndNotificationsService.showAlert("Select Category", "Please select skill category.", "warning");
      }
    }
  }

  _setNewSkill(skill) {
    return JSON.parse(JSON.stringify(skill));
  }

  openParentModal(event, template: TemplateRef<any>) {
    const config = new ModalConfig();
    config.backdrop = true;
    config.ignoreBackdropClick = true;
    config.keyboard = false;
    config.class = "modal-lg custom-ngx-modal";
    const modalData: any = {};
    config.initialState = modalData;
    this.newSkill.skill = this.modalData.allParentSkills.filter(skill => skill.id === 'addTag')[0].skill;
    this.newSkill.category = (this.updatedSelectedSkillCategory && this.updatedSelectedSkillCategory.value) ? this.updatedSelectedSkillCategory.value.slice() : "";
    this.parentSkillModal = this.bsModalService.show(template, config);
  }

  addParentSkill(parentSkill) {
    if (this._checkIfCategorySelected(this.newSkill)) {
      if (!this._checkIfParentOrAlternateSkillDuplicate(this.modalData.allParentSkills, this.newSkill.skill)) {
        this.selectedParentSkill = [parentSkill];
        this.parentSkillModal.hide();
      } else {
        this.alertsAndNotificationsService.showAlert("Duplicate", "Skill Name already exists.", "warning");
      }
    } else {
      this.alertsAndNotificationsService.showAlert("Select Category", "Please select skill category.", "warning");
    }
  }

  _checkIfSkillNameAndAlternateOrParentSame(skill, newSkill) {
    return skill.skill && skill.skill.toLowerCase() != newSkill.toLowerCase();
  }

  onAlternateSkillTextChange(event) {
    this.newSkill.isAlternateSkillDuplicate = (this._checkIfParentOrAlternateSkillDuplicate(this.modalData.allParentSkills, event) || !this._checkIfSkillNameAndAlternateOrParentSame(this.newSkill, event));
  }

  updateOrDeleteSkills() {
    if (this.modalData.type == 'update') {
      if ((this.updatedSelectedSkillCategory && this.updatedSelectedSkillCategory.value) || (this.selectedParentSkill && this.selectedParentSkill.length > 0)) {
        let message = ""
        message = message + ((this.updatedSelectedSkillCategory && this.updatedSelectedSkillCategory.value) ? `<p>The 'Category' of all selected skills will be changed to <b>${this.updatedSelectedSkillCategory.name}.</b></p>` : "");
        message = message + ((this.selectedParentSkill && this.selectedParentSkill.length > 0) ? `<p>The 'Parent Skill' of all selected skills will be changed to <b>${this.selectedParentSkill[0].skill}.</b></p>` : "");
        message = message + `<p>Are you sure you want to continue?</p>`;
        this.alertsAndNotificationsService.showConfirm('Update Skills', message, 'warning', true, () => {
          // Remove hierarchy data
          let tempUpdateSkills = {
            skills: this.modalData.allSelectedSkills.map(({ skillHierarchy, ...rest }) => rest),
            selectedCategory: (this.updatedSelectedSkillCategory && this.updatedSelectedSkillCategory.value) ? this.updatedSelectedSkillCategory.value : "",
            selectedParent: this.selectedParentSkill[0]
          };
          // Update Selected Skills
          this.updateSkills(tempUpdateSkills, (data) => {
            // Check for Error skills
            if(data && data.errorSkills && data.errorSkills.length > 0) {
              this.skillsNotUpdated = data.errorSkills;
              let errorSkillsIds = this.skillsNotUpdated.map(skill => {return skill.id});
              let updatedErrorSkills = [];
              this.modalData.allSelectedSkills.forEach(skill => {
                skill.isErrorSkill = errorSkillsIds.includes(skill.id);
                updatedErrorSkills.push(skill);
              });
              this.modalData.allSelectedSkills = [...updatedErrorSkills];
              this.angularModalService.addModalOpenClassToBodyIfAnyModalIsOpen();
            } else {
              this.closeModal(this.modalData);
            }
          }, (error) => {
            this.closeModal(this.modalData);
          });
        }, () => {
          this.angularModalService.addModalOpenClassToBodyIfAnyModalIsOpen();
        });
      } else {
        this.alertsAndNotificationsService.showAlert("Select Category Or Parent Skill", "Please select skill category or parent skill.", "warning");
        this.angularModalService.addModalOpenClassToBodyIfAnyModalIsOpen();
      }
    } else {
      this.alertsAndNotificationsService.showConfirm('Delete Skills', `<p>All the selected skills will be deleted.</p><p>Are you sure you want to continue?</p>`, 'warning', true, () => {
        this.closeModal(this.modalData);
      }, () => { 
        this.angularModalService.addModalOpenClassToBodyIfAnyModalIsOpen();
      });
    }
  }

}

export class Document {
    id: any;
    name: any;
    description: any;
    candidateId: any;
    companyId: any;
    internalNote: any;
    folderId: any;
    sharedEntityIds: any;
    shared: any;
    icon: any;
    version: any;
    fileName: any;
    jobMatch: any;
    isJobMatchDocument: boolean;
    link: any;
    constructor(id, name, description, candidateId, companyId, internalNote, folderId, sharedEntityIds, shared, icon) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.candidateId = candidateId;
        this.companyId = companyId;
        this.internalNote = internalNote;
        this.folderId = folderId;
        this.sharedEntityIds = sharedEntityIds;
        this.shared = shared;
        this.icon = icon;
    }
}
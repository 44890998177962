import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CandidateService } from 'src/app/core/services/candidate.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { JobMatchService } from 'src/app/core/services/job-match.service';
import { JobService } from 'src/app/core/services/job.service';
import { UserRoleService } from 'src/app/core/services/user-role.service';

import { JobMatchAction } from 'src/app/core/models/jobMatchAction';
import { JobMatchActionsService } from 'src/app/public/app/services/jobMatchActionsService';

@Component({
  selector: 'app-candidate-job-matches-popover',
  templateUrl: './candidate-job-matches-popover.component.html',
  styleUrls: ['./candidate-job-matches-popover.component.css']
})
export class CandidateJobMatchesPopoverComponent implements OnInit {

  @Input() jobMatchesFilter:any;
  @Input() candidateInfo:any;
  @Output() viewAllJobMatchesCallback = new EventEmitter<any>();
  @Output() viewAllPossibleJobMatchesCallback = new EventEmitter<any>();

  candidateJobMatches:any = [];
  clientOrBuLabel:any = 'BU';
  userName:any;
  salaryDurationOptions:any;
  jobTypeList:any;
  loadingFlag:boolean = false;
  isUserAdmin:any;
  isAssociateCandidates: boolean = false;
  constructor(private authService:AuthService, private userRoleService:UserRoleService, private companyService:CompanyService,
    private jobService:JobService, private candidateService:CandidateService, private jobMatchService:JobMatchService,
    private alertsAndNotificationsService:AlertAndNotificationsService, @Inject('$state') public $state, @Inject('jobMatchActionsService') private jobMatchActionsService: JobMatchActionsService) { }

  ngOnInit() {
    //variables
    this.candidateJobMatches = [];
    this.userName = this.authService.getUserDetails().firstname + ' ' + this.authService.getUserDetails().lastname;
    this.clientOrBuLabel = 'BU';

    //flags
    this.loadingFlag = true;
    this.isUserAdmin = this.userRoleService.isLoggedInUserAnAdmin();
    if (this.companyService.isStaffingCompany()) {
      this.clientOrBuLabel = 'Client';
    }
    this.getSalaryDurationOptions();
    this.getAllPreferredJobTypes(() => {
      if (this.candidateInfo.context === 'associateCandidates') {
        this.candidateJobMatches = this.candidateInfo.candidateJobMatches;
        this._massageJobMatchDetails(this.candidateInfo.candidateJobMatches);
        this.isAssociateCandidates = true;
        this.loadingFlag = false;
      } else {
        this.getCandidateJobMatches();
      }
    });
  }

  getCandidateJobMatches() {
    this.loadingFlag = true;
    this.candidateService.getCandidateJobMatches(this.jobMatchesFilter, '', '', (data) => {
      this._massageJobMatchDetails(data);
      this.loadingFlag = false;
      // if (successCallback) {
      //   successCallback();
      // }
    }, (error) => {
      this.loadingFlag = false;
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  _massageJobMatchDetails(data) {
    this.candidateJobMatches = [];
    angular.forEach(data, (val, key) => {
      val.jobMatchDate = new Date(val.jobMatchDate);
      if (_.isNull(val.fourDotFiveIntelligentScore)) {
        val.fourDotFiveIntelligentScore = 'N/A';
      }
      if (!_.isNull(val.jobLocation)) {
        val.jobDetailsText = val.company + this.jobService.getParsedJobLocationText(val.jobLocation, false);
      }
      if ((!val.recruiters.includes(this.userName) && !this.isUserAdmin) || val.requisitionStatus === 'CLOSED') {
        val.disable = true;
      }
      if (val.contractDuration) {
        // val.jobType = `${val.jobType}(${this.$filter("contractDuration")(val.contractDuration)})`;
        val.jobType = val.contractDuration !== 'Long Term' ? val.contractDuration = val.contractDuration + " months" : val.contractDuration;
      }
      val.workLocationsMatchStatus = this.jobMatchService.getJobAndCandidateWorkLocationsMatchStatus(val.jobWorkLocations, val.candidateWorkLocations);
      val.vendorName = this.candidateInfo.vendorName;
      this.candidateJobMatches.push(val);
    });
  }

  getSalaryDurationOptions() {
    this.jobService.getSalaryDurationOptions((data) => {
      this.salaryDurationOptions = data;
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  getAllPreferredJobTypes(successCallback) {
    this.jobService.getAllJobTypes((data) => {
      this.jobTypeList = data;
      if(successCallback) {
        successCallback();
      }
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    })
  }

  goToWorkflow(jobMatch, filtered) {
    if (!jobMatch.jobMatchDisabled && !jobMatch.hasMeetingScheduleAndNoScopeForLoggedInUser) {
      let searchString = this.candidateInfo.email;
      if (_.isNull(searchString)) {
        searchString = this.candidateInfo.name;
      }
      if (filtered) {
        this.$state.go('missioncontrol.candidateJobMatchesWorkflow', {
          jobId: jobMatch.jobId,
          searchString: searchString,
          jobMatchId: jobMatch.jobMatchId
        });
      } else {
        this.$state.go('missioncontrol.candidateJobMatchesWorkflow', {
          jobId: jobMatch.jobId
        });
      }
    }
  }

  viewAllJobMatches() {
    if (this.viewAllJobMatchesCallback) {
      this.viewAllJobMatchesCallback.emit();
    }
  }

  viewAllPossibleJobMatches() {
    if (this.viewAllPossibleJobMatchesCallback) {
      this.viewAllPossibleJobMatchesCallback.emit()
    }
  }

  jobCompare(jobMatch) {
    let actionObject = new JobMatchAction("Candidate Job Comparison", "CANDIDATE_JOB_COMPARE", true, null);
    jobMatch.jobMatchJobId = jobMatch.jobId;
    jobMatch.id = this.candidateInfo.id;
    let context = 'candidateJobMatchesPopover';
    if(this.isAssociateCandidates) {
      context = 'associateCandidates';
    } else {
      this.candidateInfo.jobMatchesPopoverIsOpenFlag = false;
    }
    this.jobMatchActionsService.performAction(context, actionObject, jobMatch, () => {
    }, () => {
    });
  }

}

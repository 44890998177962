import { Inject, Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  urlConstants = {
    GET_NOTIFICATIONS: 'api/notification/findall',
    SET_NOTIFICATION_STATE: 'api/notification/changenotificationstate',
    CHANGE_STATE_FOR_ALL_NOTIFICATIONS_OF_USER: 'api/notification/changestateforallnotificationsofuser',
    CHANGE_STATE_FOR_NOTIFICATIONS_BY_FILTER: 'api/notification/changestatefornotificationsbyfilter',
    SAVE_CANDIDATE_RESUME_NOTIFICATION: 'api/notification/saveCandidateResumeNotification'
  }
  constructor(@Inject(GenericService) private genericService: GenericService, @Inject(StorageService) private StorageService: StorageService) { }

  getNotifications(filterObject, successCallback, errorCallback) {
    let promise = this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_NOTIFICATIONS, filterObject);

    promise.subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });

    return promise;
  }

  setNotificationsState(stateObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.SET_NOTIFICATION_STATE, stateObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  changeStateForAllNotificationsOfUser(notificationState, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.CHANGE_STATE_FOR_ALL_NOTIFICATIONS_OF_USER + "/?state=" + notificationState).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  changeStateForNotificationsByFilter(notificationState, info, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.CHANGE_STATE_FOR_NOTIFICATIONS_BY_FILTER + "/?state=" + notificationState, info).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  saveCandidateResumeNotification(notificationId, notification, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.SAVE_CANDIDATE_RESUME_NOTIFICATION + "/" + notificationId, notification).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

}

import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { GenericService } from './generic.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AppVersionService {
  constants: any = { currentVersionHash: '{{POST_BUILD_ENTERS_HASH_HERE}}' };
  urlConstants: any = {
    GET_VERSION_FILE: 'app/public/version.json'
  };
  // Observable boolean sources
  private appVersionChangedSource = new Subject<boolean>();
  appVersionChanged$ = this.appVersionChangedSource.asObservable();
  constructor(@Inject(StorageService) private StorageService: StorageService, @Inject(GenericService) private genericService: GenericService) {

  }

  initVersionCheck() {
    setInterval(() => {
      this.checkVersion();
    }, 1000 * 60 * 15); //15 minutes
  }

  checkVersion() {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_VERSION_FILE).subscribe((data) => {
      // let hash = data.hash;
      let hash = data.body.hash;
      // Check version changed
      let hashChanged = this.hasHashChanged(hash);
      console.log('Has version changed', hashChanged);

      // If new version, do something
      if (hashChanged) {
        console.info('Show message to reload page to user');
        this.appVersionChangedSource.next(hashChanged);
      }

      // store the new hash so we wouldn't trigger versionChange again
      this.constants.currentVersionHash = hash;
    }, (error) => {
      console.log(error);
    });
  }

  hasHashChanged(newHash) {
    if (!this.constants.currentVersionHash || this.constants.currentVersionHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
      return false;
    }
    return this.constants.currentVersionHash !== newHash;
  }
}

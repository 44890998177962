import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CandidateAdditionalDetailsComponent } from './candidate-additional-details.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { TooltipModule, TypeaheadModule } from 'ngx-bootstrap';
import { AddNewCompanyDetailsModule } from './add-new-company-details/add-new-company-details.module';

@NgModule({
  declarations: [CandidateAdditionalDetailsComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    AddNewCompanyDetailsModule
  ],
  exports: [CandidateAdditionalDetailsComponent],
  entryComponents: [CandidateAdditionalDetailsComponent]
})
export class CandidateAdditionalDetailsModule { }

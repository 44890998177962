import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DoNotHireCandidateDetails } from 'src/app/core/models/doNotHireCandidateDetails';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CandidateService } from 'src/app/core/services/candidate.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { JobMatchService } from 'src/app/core/services/job-match.service';

@Component({
  selector: 'app-do-not-hire',
  templateUrl: './do-not-hire.component.html',
  styleUrls: ['./do-not-hire.component.css']
})
export class DoNotHireComponent implements OnInit {
  @ViewChild('doNotHireForm', null) doNotHireForm: NgForm;

  @Input() candidateId;
  @Input() jobId;
  @Input() nonJoinedCandidates;
  @Input() context;
  @Output() saveCallback = new EventEmitter<any>();
  @Output() cancelCallback = new EventEmitter<any>();

  companyId: any;
  companyName: any;
  jobMatches: any;
  clients: any;
  selectedClients: any;
  selectedNonJoinedCandidates: any = [];
  errorMessage: any = "";
  doNotHireDetails: any;
  candidateName: any;
  candidateCompanyId: any;
  copyNote: any;

  isStaffingCompany: boolean;
  isCorporateCompany: boolean;
  isLoading: boolean = true;
  doNotHireAtClient: boolean = false;
  savingFlag: boolean = false;
  showExternalNote: boolean = false;

  constructor(
    private authService: AuthService,
    private companyService: CompanyService,
    private candidateService: CandidateService,
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private jobMatchService: JobMatchService
  ) { }

  ngOnInit() {
    //variables
    this.companyId = this.authService.getUserDetails().company.companyId;
    this.companyName = this.authService.getUserDetails().company.companyName;
    this.isStaffingCompany = this.companyService.isStaffingCompany();
    this.isCorporateCompany = this.companyService.isCorporateCompany();
    this.jobMatches = [];
    this.clients = [];
    this.selectedClients = [];
    this.errorMessage = "";

    //flags
    this.isLoading = true;
    this.doNotHireAtClient = false;
    this.savingFlag = false;
    this.showExternalNote = false;

    if (this.context === 'requisitionCard') {
      this.candidateId = this.nonJoinedCandidates[0].candidateId;
      this.selectedNonJoinedCandidates = this.nonJoinedCandidates[0];
    }
    this.doNotHireDetails = new DoNotHireCandidateDetails(this.candidateId, this.companyId);
    this._getCandidateProfile(() => {
      this._getOtherJobMatchesOfCandidate(() => {
        this.isLoading = false;
      });
    });
    if (this.isStaffingCompany) {
      this._getClients(() => {
        // do nothing
      });
    }
  }

  _getCandidateProfile(successCallback) {
    this.candidateService.getCandidateProfile(this.doNotHireDetails.candidateId, (data) => {
      data.id = this.doNotHireDetails.candidateId;
      data.name = _.isNull(data.lastName) ? data.firstName : data.firstName + ' ' + data.lastName;
      this.candidateName = data.name;
      this.candidateCompanyId = data.candidateCompanyId;
      this._setShowExternalNote();
      if (successCallback) {
        successCallback();
      }
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  _setShowExternalNote() {
    this.showExternalNote = this.candidateCompanyId !== this.companyId;
  }

  _getOtherJobMatchesOfCandidate(successCallback) {
    this.jobMatches = [];
    let filter = {
      candidateId: this.doNotHireDetails.candidateId,
      companyId: this.companyId
    };

    this.jobMatchService.getJobMatchDetails(filter, (data) => {
      this.jobMatches = [...data];
      if (successCallback) {
        successCallback();
      }
    }, error => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }


  _getClients(successCallback) {
    this.candidateService.getAllClientsOrBu(this.authService.getUserDetails(), (data) => {
      this.clients = data;
      this.clients.forEach(client => {
        if(client.companyType == 'Corporation'){
          client.name = `<span>${client.name}<i class='fas fa-share-alt pl-2'></i></span>`;
        }
      });

      if (successCallback) {
        successCallback();
      }
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  onCandidateSelect() {
    this.doNotHireDetails.candidateId = this.selectedNonJoinedCandidates.candidateId;
    this.doNotHireDetails.note = "";
    this.doNotHireDetails.externalNote = "";
    this._getCandidateProfile(() => {
      this._getOtherJobMatchesOfCandidate(() => {
        this.isLoading = false;
      });
    });
  }

  onInternalNoteChanged() {
    this.doNotHireDetails.externalNote = this.doNotHireDetails.note;
  }

  onExternalNoteChange(event) {
    this.doNotHireDetails.externalNote = event.externalNote;
  }

  onCopyNoteChange(event) {
    this.copyNote = event.copyNote;
  }

  onDoNotHireAtChange() {
    if (!this.doNotHireAtClient) {
      this.selectedClients = [];
      this.clients.forEach(client => {
        client.ticked = false;
      });
    }
  }

  doNotHireCandidate() {
    this.errorMessage = "";
    if (this._areDetailsValid()) {
      if (this.doNotHireAtClient) {
        this.doNotHireDetails.doNotHireForCompanyIds = this.selectedClients.map(client => client.id);
      } else {
        this.doNotHireDetails.doNotHireForCompanyIds = [];
        this.doNotHireDetails.doNotHireForCompanyIds.push(this.companyId);
      }
      this.candidateService.doNotHireCandidate(this.doNotHireDetails, (data) => {
        this.savingFlag = true;
        let successMessage = "The candidate is no longer considered for hiring.";
        if (this.isStaffingCompany && this.doNotHireAtClient) {
          successMessage = "The candidate is no longer considered for hiring for the selected clients.";
        }
        this.alertsAndNotificationsService.showBannerMessage(successMessage, 'success');
        if (this.saveCallback) {
          this.saveCallback.emit();
        }
      }, (error) => {
        this.savingFlag = false;
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      });
    } else {
      this.errorMessage = "Please select atleast one Client";
    }
  }

  _areDetailsValid() {
    return !this.doNotHireAtClient || (this.doNotHireAtClient && this.selectedClients.length > 0);
  }

  cancelDoNotHire() {
    if (this.cancelCallback) {
      this.cancelCallback.emit();
    }
  }

  selectAllClients() {
    this.selectedClients = [...this.clients];
  }

  deSelectAllClients() {
    this.selectedClients = [];
  }

}

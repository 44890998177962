import { Component, OnInit, Inject, Input } from '@angular/core';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { GenericService } from 'src/app/core/services/generic.service';
import { JobService } from 'src/app/core/services/job.service';
import { StorageService } from 'src/app/core/services/storage.service';

@Component({
  selector: 'app-recent-requisitions',
  templateUrl: './recent-requisitions.component.html',
  styleUrls: ['./recent-requisitions.component.css']
})
export class RecentRequisitionsComponent implements OnInit {
  @Input() dashboardView: string;
  @Input() limit: string;
  // flags
  loadingRecentRequisitionsFlag: boolean = true;
  // variables
  salaryDurationOptions: Array<string>;
  companyType: string;
  userId: string;
  companyId: string;
  requisitions: Array<any>;
  fullActivityList: Array<any>;
  activityMap: Array<any>;

  constructor(
    @Inject("$rootScope") public $rootScope,
    @Inject("$state") public $state,
    private genericService: GenericService,
    private storageService: StorageService,
    private jobService: JobService,
    private alertsAndNotificationsService: AlertAndNotificationsService
  ) { }

  ngOnInit() {
    this.companyType = this.$rootScope.userDetails.company.companyType;
    this.userId = this.$rootScope.userDetails.id;
    this.companyId = this.$rootScope.userDetails.company.companyId;

    this.getSalaryDurationOptions(() => {
      this.getRequisitionsByLatestActivity((data) => {
        data.forEach(req => {
          req.job.jobId = req.id;
        });
        this.requisitions = data.map(req => req.job);
        this.loadingRecentRequisitionsFlag = false;
        console.log("Fetched requisitions");
      });
    });

    this.getAllActivityTypes();
  }

  getSalaryDurationOptions(successCallback) {
    this.jobService.getSalaryDurationOptions((data) => {
      this.salaryDurationOptions = data;
      console.log("Fetch salary options in recent requisitions")
      if (successCallback) {
        successCallback();
      }
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  getRequisitionsByLatestActivity(callback) {
    this.genericService.getObjects(this.storageService.get('baseurl') + 'api/job/jobsbyactivities' + '/' + this.userId + '/' + this.companyId).subscribe((data) => {
      callback(data.body);
    }, (error) => {
      callback(error);
    });
  }

  getAllActivityTypes() {
    this.jobService.getActivityTypes((data) => {
      this.fullActivityList = data;
      this.activityMap = [];
      angular.forEach(data, (val, key) => {
        this.activityMap[val.value] = val.name;
      });
    }, (error) => {
      if (this.$rootScope.isOnline) {
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      }
    });
  }

  deleteRequisition(requisition) {
    let requisitionIndex = _.findIndex(this.requisitions, { 'id': requisition.id });
    this.requisitions.splice(requisitionIndex, 1);
  }

  copyRequisition() {
    this.$state.reload();
  }
}

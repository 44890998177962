import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsPageComponent } from './notifications-page.component';
import { UserNotificationsComponentWrapper } from './user-notifications.wrapper';

@NgModule({
  declarations: [NotificationsPageComponent, UserNotificationsComponentWrapper],
  imports: [
    CommonModule
  ],
  exports: [NotificationsPageComponent, UserNotificationsComponentWrapper],
  entryComponents:[NotificationsPageComponent]
})
export class NotificationsPageModule { }

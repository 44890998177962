import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeleteAssessmentCredentialComponent } from './delete-assessment-credential.component';

@NgModule({
  declarations: [DeleteAssessmentCredentialComponent],
  imports: [
    CommonModule
  ],
  exports: [DeleteAssessmentCredentialComponent],
  entryComponents: [DeleteAssessmentCredentialComponent]
})
export class DeleteAssessmentCredentialModule { }

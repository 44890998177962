import { Component, OnInit, ViewChild, TemplateRef, ViewEncapsulation, Output, EventEmitter, Inject, ElementRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ModalConfig } from 'src/app/core/models/modalConfig';
import { Subject } from 'rxjs';
import { AngularModalService } from 'src/app/core/services/modal.service';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ManageSkillsService } from 'src/app/core/services/manage-skills.service';
import { StateManagementStorageService } from 'src/app/core/services/state-management-storage.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { NewHierarchySkill } from 'src/app/core/models/newHierarchySkill';
import { SkillFilters } from 'src/app/core/models/skillFilters';
import { NewSkill } from 'src/app/core/models/newSkill';
import { SkillTablePage } from 'src/app/core/models/skillTablePage';
import { UpdateDeleteSelectedSkillsComponent } from './update-delete-selected-skills/update-delete-selected-skills.component';
import { UploadSkillsComponent } from './upload-skills/upload-skills.component';
import { UtilityService } from 'src/app/core/services/utility.service';
import { ViewUploadedSkillsComponent } from './view-uploaded-skills/view-uploaded-skills.component';
import { JobService } from 'src/app/core/services/job.service';

declare var saveAs: any;

@Component({
  selector: 'app-manage-skills-page',
  templateUrl: './manage-skills-page.component.html',
  styleUrls: ['./manage-skills-page.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ManageSkillsPageComponent implements OnInit {

  @ViewChild('actionsSkillTemplate', null) actionsSkillTemplateRef: TemplateRef<any>;
  @ViewChild('alternateSkillTemplate', null) alternateSkillTemplateRef: TemplateRef<any>;
  @ViewChild('categorySkillTemplate', null) categorySkillTemplateRef: TemplateRef<any>;
  @ViewChild('parentSkillTemplate', null) parentSkillTemplateRef: TemplateRef<any>;
  @ViewChild('addEditSkillTemplate', null) addEditSkillTemplateRef: TemplateRef<any>;
  @ViewChild('headerSkillTemplate', null) headerSkillTemplateRef: TemplateRef<any>;
  @ViewChild('headerCategoryTemplate', null) headerCategoryTemplateRef: TemplateRef<any>;
  @ViewChild('headerParentTemplate', null) headerParentTemplateRef: TemplateRef<any>;
  @ViewChild(DatatableComponent, null) manageSkillsTable: DatatableComponent;
  @ViewChild('FamilyTreeDiv', null) elementToAlign: ElementRef;

  @Output() saveCallback = new EventEmitter<any>();
  @Output() cancelCallback = new EventEmitter<any>();

  addEditSkillModal: BsModalRef;
  uploadSkillModal: BsModalRef;
  updateMultipleSkillsModal: BsModalRef;
  downloadSkillsModal: BsModalRef;
  viewUploadedSkillsModal: BsModalRef;
  skillActivitiesModal: BsModalRef;
  showFileFormatModal
  public onClose: Subject<any>;

  allSkillsTableData: any = [];
  allSkillsTableDataOriginal: any = [];
  allSkillsTablePaginationData: any = [];
  skillColumns: any = [];
  searchSkill: any;
  selectedCategory: any = [];
  skillCategories: any;
  editSkillObject: any;
  skillsHierarchyData: any = [];
  currentSkillHierarchy: any = {};
  addSkillCount: any = 1;
  skillFilterObject: any = {};
  skillSorts: any = [];
  selectedSkillSort: any = [];
  viewAlternateSkillData: any = [];
  allFilteredTableData: any = [];
  tableFilteredSort: any = {};
  selectedSkillIds: any = [];
  allSkillsHierarchy: any = [];
  activityMap: any = [];

  manageSkillType: string = 'addSkill';
  userId: any;
  skillsDownloadUrl:any;
  page:any;
  selectedTableRowCount: any = 0;
  selectedHierarchySkillCount: any = 0;
  downloadSkillFormat: any = 'xls';
  hierarchySearchSkill: any = '';

  isLoading: boolean = true;
  isFiltersChanged: boolean = false;
  isAlternateSkillIncluded: boolean = false;
  actionToggle: boolean = false;
  isAllTableRowsInPageSelected: boolean = false;
  isHierarchySkillExists: boolean = true;
  
  constructor(
    @Inject("$state") public $state,
    private modalService: BsModalService,
    private angularModalService: AngularModalService,
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private skillsService: ManageSkillsService,
    private stateManagementStorageService: StateManagementStorageService,
    private authService:AuthService,
    private utilityService:UtilityService,
    private jobService: JobService
  ) {
    this.onClose = new Subject();
    this.setTableDefaultPage();
  }

  ngOnInit() {
    this.userId = this.authService.getUserDetails().id;
    this._setSkillsSortBy();
    this.getAllActivityTypes();
    this.getAndSetSkillCategories(() => {
      let filters = this.stateManagementStorageService.getScreenState(`manageSkillsScreen-${this.userId}`);
      if(!filters || Object.values(filters).every(value => value !== undefined && value !== null)) {
        this.skillFilterObject = new SkillFilters();
        this.stateManagementStorageService.setScreenState(`manageSkillsScreen-${this.userId}`, this.skillFilterObject);
      } else {
        this.skillFilterObject = filters;
      }
      this._setFilters();
      this.getAndSetSkillRows(() => {
      });
    });
  }

  getAllActivityTypes() {
    this.jobService.getActivityTypes((data) => {
      this.activityMap = [];
      data.forEach((val, key) => {
        this.activityMap[val.value] = val.name;
      });
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  getAndSetSkillCategories(successCallback) {
    this.skillsService.getAllCategories(data => {
      this.skillCategories = data;
      if(successCallback) {
        successCallback();
      }
    }, error => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  mergeFilteredAndAllSkillsData(allSkills, allFilteredSkills) {
    allFilteredSkills.map(mainSkill => {
      let findMatchedSkill:any;
      if(mainSkill.alternate) {
        findMatchedSkill = allSkills.find((skill => {
          if(skill.alternateSkills && skill.alternateSkills.length > 0 && skill.alternateSkills.some(alternateSkill => (alternateSkill && (alternateSkill.id == mainSkill.id)))) {
            return true;
          }
        }));
      } else {
        findMatchedSkill = allSkills.find((skill => skill.id === mainSkill.id));
      }

      if (findMatchedSkill) {
        mainSkill.skillHierarchy = findMatchedSkill.skillHierarchy;
        mainSkill.showHierarchy = findMatchedSkill.showHierarchy;
      }
    });
  }

  async setHierarchyForSkills(allSkills, filteredSkills) {
    this.allSkillsHierarchy = this.setSkillsHierarchy();
    allSkills.map(skill => {
      skill.skillHierarchy = [];
      skill.showHierarchy = false;
    });
    // Set Hierarchy to Filtered Objects
    await this.mergeFilteredAndAllSkillsData(allSkills, filteredSkills);
  }

  getAndSetSkillRows(successCallback) {
    this.isLoading = true;
    this.utilityService.showLoadingModal("Loading");
    this.tableFilteredSort.sortColumn = this.skillFilterObject.sortColumn;
    this.tableFilteredSort.sortDir = this.skillFilterObject.sortDir;
    this.selectedSkillIds = [];
    this.allFilteredTableData.forEach(skill => {
      if(skill.isSelected) {
        this.selectedSkillIds.push(skill.id);
      }
    });
    this.skillsService.getAllSkills(this.skillFilterObject, (data) => {
      this.allSkillsTableData = data ? data.allSkills.filter(skill => skill) : [];
      this.allSkillsTableData.reverse();
      this.allFilteredTableData = data ? data.filteredSkills.filter(skill => skill) : [];
      this.allSkillsTableDataOriginal = JSON.parse(JSON.stringify(this.allSkillsTableData));
      // Set Hierarchy
      this.setHierarchyForSkills(this.allSkillsTableData, this.allFilteredTableData);

      this.utilityService.hideLoadingModal();
      this.isLoading = false;
      this.isFiltersChanged = false;
      // Set Table Pagination
      this.setTablePage(this.page);
      if(successCallback){
        successCallback();
      }
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      this.isLoading = false;
    });
  }

  _getSkillCategoryDisplayName(category) {
    if(this.skillCategories) {
      return this.skillCategories.filter(data => (data.value.toLowerCase()) == (category.toLowerCase()))[0].name;
    }
  }

  setTableDefaultPage() {
    this.page = new SkillTablePage();
    this.page.pageNumber = 0;
    this.page.size = 20;
  }

  setTablePage(pageInfo) {
    this.page.pageNumber = (!pageInfo.pageNumber && pageInfo.offset) ? pageInfo.offset : (pageInfo.pageNumber ? pageInfo.pageNumber : 0);
    this.page.totalElements = this.allFilteredTableData.length;
    this.page.totalPages = this.page.totalElements / this.page.size;
    const start = this.page.pageNumber * this.page.size;
    const end = Math.min(start + this.page.size, this.page.totalElements);
    this.allSkillsTablePaginationData = [];
    for (let i = start; i < end; i++) {
      let skill = this.allFilteredTableData[i];
      this.allSkillsTablePaginationData.push(skill);
    }
    if(this.allSkillsTablePaginationData.length == 0 && this.allFilteredTableData.length > 0) {
      pageInfo.pageNumber = pageInfo.pageNumber-1;
      this.setTablePage(pageInfo);
    }
    this.setPageSkillSelection();
  }

  setPageSkillSelection() {
    let isAllSkillsSelected = true;
    for (const skill of this.allSkillsTablePaginationData) {
      if (!skill.isSelected) {
        isAllSkillsSelected = false;
        break;
      }
    }
    this.isAllTableRowsInPageSelected = this.allSkillsTablePaginationData.length > 0 ? isAllSkillsSelected : false;
    this._setTableRowCount();
  }

  selectOrDeselectAllRowsInPage() {
    this.isAllTableRowsInPageSelected = !this.isAllTableRowsInPageSelected;
    if (this.isAllTableRowsInPageSelected) {
      this.allSkillsTablePaginationData.map(skill => {
          skill.isSelected = true;
      });
    } else {
      this.allSkillsTablePaginationData.map(skill => skill.isSelected = false);
    }
    this._setTableRowCount();
    this.setTablePage(this.page);
    this.setGlobalSkillSelection();
  }

  _setTableRowCount() {
    let count = 0;
    let isAllSkillsSelected = true;
    this.allFilteredTableData.map(skill => {
      if (skill.isSelected) {
        count++;
      } else {
        isAllSkillsSelected = false;
      }
    });
    this.selectedTableRowCount = count;
  }

  selectOrDeselectRow(row) {
    row.isSelected = !row.isSelected;
    const index = this.allFilteredTableData.findIndex(skill => row.id === skill.id);
    if (index !== -1) {
      this.allFilteredTableData[index] = { ...this.allFilteredTableData[index], ...row };
    }
    this._setTableRowCount();
    this.setTableSelection();
    this.setGlobalSkillSelection();
  }

  setGlobalSkillSelection() {
    const selectedSkillIds = this.allFilteredTableData
      .filter(skill => skill.isSelected)
      .map(skill => skill.id);

    this.allSkillsTableData.forEach(skill => {
      skill.isSelected = selectedSkillIds.includes(skill.id);
    });
  }

  setTableSelection() {
    this.setTablePage(this.page);
  }

  setSelectedSkills(selectedSkills, allSkills) {
    allSkills.map(skill => {
      if(selectedSkills.includes(skill.id)) {
        skill.isSelected = true;
      }
    });
    this.setTablePage(this.page);
  }

  _getSkillHierarchy(hierarchy, skillId) {
    if(!hierarchy) {
      for (const subSkill of this.allSkillsHierarchy[0].subSkills) {
        const result = this._getSkillHierarchy(subSkill, skillId);
        if (result) {
          return result;
        }
      }
    } else {
      if (hierarchy.id === skillId) {
        return hierarchy;
      } 
      
      if(hierarchy && hierarchy.subSkills && hierarchy.subSkills.length > 0) {
        for (const subSkill of hierarchy.subSkills) {
          const result = this._getSkillHierarchy(subSkill, skillId);
          if (result) {
            return result;
          }
        }
      }
    }

    return null;
  }

  getAllSkillChildIds(childHierarchy) {
    let childIds = [childHierarchy.id];

    if (childHierarchy.subSkills && childHierarchy.subSkills.length > 0) {
      for (const child of childHierarchy.subSkills) {
        const childIdsRecursive = this.getAllSkillChildIds(child);
        childIds = childIds.concat(childIdsRecursive);
      }
    }
  
    return childIds;
  }

  _setSkillsSortBy() {
    this.skillsService.getAllSkillSortCategories((data) => {
      this.skillSorts = data;
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    })
  }

  _setFilters() {
    if(this.skillFilterObject.searchCategory && this.skillFilterObject.searchCategory != '') {
      let filteredCategories = this.skillFilterObject.searchCategory.split(',');
      this.selectedCategory = this.skillCategories.filter(skill => filteredCategories.includes(skill.value.toLowerCase()));
    }

    if(this.skillFilterObject.sortColumn && this.skillFilterObject.sortColumn != '') {
      this.selectedSkillSort = this.skillSorts.filter(sort => (sort.value == this.skillFilterObject.sortColumn) && (sort.direction  == this.skillFilterObject.sortDir))[0];
    } else {
      // Set default sort
      this.selectedSkillSort = this.skillSorts.filter(sort => (sort.value == 'skill') && (sort.direction  == 'ASC'))[0];
    }

  }

  _removeParentRootSkill(skill) {
      if(skill.parentSkills && skill.parentSkills.length > 0 && skill.parentSkills.some(skill => skill.isRootSkill)) {
        skill.parentSkills = [];
      }
  }

  addEditMoveKill(template: TemplateRef<any>, state, editSkill) {
    this.manageSkillType = state;
    this.editSkillObject = editSkill;
    // Set child skill Ids, to remove this skills from parent skill typea head to prevent infinite loop.
    if (this.editSkillObject) {
      this.editSkillObject = Object.assign({}, this.allSkillsTableData.find(skill => skill.id == editSkill.id));
      let childHierarchy = this._getSkillHierarchy(this.editSkillObject.skillHierarchy[0], editSkill.id);
      if (childHierarchy) {
        this.editSkillObject.childSkillIds = this.getAllSkillChildIds(childHierarchy);
      } else {
        this.editSkillObject.childSkillIds = [];
      }
      // Remove skill hierarchy to avoid infinite looping
      if (this.editSkillObject.skillHierarchy) {
        delete this.editSkillObject.skillHierarchy;
      }
      this._removeParentRootSkill(this.editSkillObject);
    }
    const config = new ModalConfig();
    config.ignoreBackdropClick = true;
    config.backdrop = true;
    config.keyboard = false;
    config.class = "modal-lg custom-ngx-modal";
    const modalData: any = {};
    modalData.state = state;
    config.initialState = modalData;
    this.addEditSkillModal = this.modalService.show(template, config);
  }

  closeModal() {
    this.addEditSkillModal.hide();
  }

  addOrUpdateSkill(updatedSkill, successCallback) {
    this.utilityService.showLoadingModal("Loading");
    this.skillsService.addAndUpdateAndMoveSkill(updatedSkill, (data:any) => {
      let currentSkillHierarchy = data ? data[0] : this.allSkillsTableData.length > 0 ? this.allSkillsTableData.find(skill => skill.showHierarchy): {};
      this.getAndSetSkillRows(() => {
        if(this.skillsHierarchyData.length > 0) {
          this._refreshSkillsHierarchy(currentSkillHierarchy);
        }
        if(successCallback) {
          successCallback();
        }
      });
    }, error => {
      if(error) {
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      }
    });
  }

  saveSkillDetails(event) {
    let state = event.skillState;
    if(event.skillState) {
      delete event.skillState;
    }
    // Delete alternate skills if removed from main skill 
    if(event.removeAlternateSkills && event.removeAlternateSkills.length > 0) {
      event.removeAlternateSkills.map(id => {
        let deleteSkill = this.allSkillsTableData.find(skill => skill.id == id);
        this._deleteSkill(deleteSkill, true, ()=> {});
      });
    }

    this.addOrUpdateSkill([event], () => {
      this.alertsAndNotificationsService.showBannerMessage(`Skill ${state == 'addSkill' ||  state == 'childSkill'? 'added' : 'updated'} successfully`, "success");
      this.setSelectedSkills(this.selectedSkillIds, this.allFilteredTableData);
    });
    this.onCategorySelect();
  }
  
  _deleteSkill(deleteSkill, deleteConfirmed, successCallback) {
    if(deleteConfirmed) {
      this.utilityService.showLoadingModal("Loading");
    }
    this.deleteAllSkills(deleteConfirmed, [deleteSkill], (data) => {
      this.setSelectedSkills(this.selectedSkillIds, this.allFilteredTableData);
      if (successCallback) {
        successCallback(data);
      }
    });
  }

  deleteSkill(deleteSkill) {
    let title = 'Delete Skill';
    let message = `<p>Do you want to delete the skill '<b>${deleteSkill.skill}</b>' in category '<b>${deleteSkill.category.slice(0, 1) + deleteSkill.category.substring(1).toLowerCase()}</b>'.</p>`;
    let messageEnd = `<p>Are you sure you want to continue?</p>`
    this._deleteSkill(deleteSkill, false, (data) => {
      if (deleteSkill.parent && deleteSkill.parentSkills && deleteSkill.parentSkills.length > 0 && data && data.effectedChildSkills && data.effectedChildSkills.length > 0) {
        let affectedChildSkills = [];
        data.effectedChildSkills.map(skill => { 
          if(!skill.alternate) {
            affectedChildSkills.push(`<b>${skill.skill}</b>`);
          }
         });
        message = message + `<p>This skill has '${affectedChildSkills.slice(0,3).toString()}${affectedChildSkills.length > 3 ? '...' + `<b>(${affectedChildSkills.slice(3).length})</b>` : ''}' as child skill${affectedChildSkills.length > 1 ? '(s)' : ''}.</p>`;
        message = message + `<p>All the child skills are being assigned to the parent skill '<b>${deleteSkill.parentSkills[0].skill}</b>'. </span></p>` + messageEnd;
      } else {
        if (!data || ((!data.effectedChildSkills || data.effectedChildSkills.length == 0) && (!data.effectedPrimarySkills || data.effectedPrimarySkills.length == 0))) {
          if (deleteSkill.alternateSkills && deleteSkill.alternateSkills.length > 0) {
            message = message + `<p>All the <b>Alternate Skills</b> associated with this skill will be deleted.` + messageEnd;
          }
        } else {
          if (deleteSkill.alternate) {
            let affectedPrimarySkills = data.effectedPrimarySkills.map(skill => { 
              if(!skill.alternate) {
                return `<b>${skill.skill}</b>`
              }
             });
            title = `Delete Alternate Skill`;
            message = message + `<p>Alternate skill will be removed from the '${affectedPrimarySkills.toString()}' primary skill.</p>` + messageEnd;
          } else if (deleteSkill.parent) {
            if (!deleteSkill.parentSkills || deleteSkill.parentSkills.length == 0) {
              if(data.effectedChildSkills && data.effectedChildSkills.length > 0) {
                let affectedChildSkills = [];
                  data.effectedChildSkills.map(skill => { 
                    if(!skill.alternate) {
                      affectedChildSkills.push(`<b>${skill.skill}</b>`);
                    }
                  });
                message = message + `<p>This skill has '${affectedChildSkills.slice(0,3).toString()}${affectedChildSkills.length > 3 ? '...' + `<b>(${affectedChildSkills.slice(3).length})</b>` : ''}' as child skill${affectedChildSkills.length > 1 ? '(s)' : ''}.</p>`;
              }
              message = message + `<p>Deleting this skill will remove parent skill association for all its child skills.` + messageEnd;
            }
          }
        }
      }

      this.alertsAndNotificationsService.showConfirm(title, message, 'warning', true, () => {
        this._deleteSkill(deleteSkill, true, () => {
          this.alertsAndNotificationsService.showBannerMessage("Skill deleted successfully", "success");
          let currentSkillHierarchy = this.allSkillsTableData.length > 0 ? this.allSkillsTableData.find(skill => skill.showHierarchy): {};
          currentSkillHierarchy = !currentSkillHierarchy || currentSkillHierarchy.id == deleteSkill.id ? {} : currentSkillHierarchy;
          this.getAndSetSkillRows(() => {
          this.setSelectedSkills(this.selectedSkillIds, this.allFilteredTableData);
            if (this.skillsHierarchyData.length > 0) {
              this._refreshSkillsHierarchy(currentSkillHierarchy);
            }
          });
        });
      }, () => {
        // Do Nothing
      });
    });
  }

  selectOrDeselectAllCategories(type) {
    if (type == 'selectAll') {
      this.selectedCategory = [...this.skillCategories];
      this.onCategorySelect();
    } else {
      this.selectedCategory = [];
      this.onCategorySelect();
    }
  }

  onCategorySelect() {
    this.isFiltersChanged = true;
    let filteredSkills = [];
    if (this.selectedCategory.length > 0) {
      filteredSkills = this.selectedCategory.map(skill =>{return skill.value});
      this.skillFilterObject.searchCategory = filteredSkills.toString().toLowerCase();
    } else {
      this.skillFilterObject.searchCategory = '';
    }
  }

  getRowClass(row) {
    return {
      'alternate-skill': row.alternate
    };
  }

  showAlternateSkillTooltip(skill) {
    this.viewAlternateSkillData = skill;
  }

  onSKillSortSelected() {
    this.isFiltersChanged = true;
    if (this.selectedSkillSort && this.selectedSkillSort.value) {
      this.skillFilterObject.sortColumn = this.selectedSkillSort.value;
      this.skillFilterObject.sortDir = this.selectedSkillSort.direction;
    } else {
      this.skillFilterObject.sortColumn = 'skill'
      this.skillFilterObject.sortDir = 'ASC'
    }
  }

  onIncludeAlternateSkillsChange() {
    this.isFiltersChanged = true;
  }

  onSearchTextChange(event) {
    if (event.value != '') {
      this.isFiltersChanged = true;
    }
  }

  resetFilterSkills() {
    this.isFiltersChanged = true;
    this.skillFilterObject = new SkillFilters();
    this.selectedCategory = [];
    this.selectedSkillSort = [];
    this._setFilters();
  }

  querySkillSearches() {
    this.isFiltersChanged = false;
    this.stateManagementStorageService.setScreenState(`manageSkillsScreen-${this.userId}`, this.skillFilterObject);
    this.setTableDefaultPage();
    this.setTableSelection();
    this.getAndSetSkillRows(() => { });
    this.skillsHierarchyData = [];
  }

  uploadNewSkillFile() {
    const config = new ModalConfig();
    const modalData: any = {
      type: 'uploadSkills',
      title: 'Upload Skills'
    };
    config.class = "custom-modal-65 custom-ngx-modal";
    config.initialState = modalData;
    config.ignoreBackdropClick = true;
    config.backdrop = true;
    this.uploadSkillModal = this.modalService.show(UploadSkillsComponent, config);
    this.uploadSkillModal.content.onClose.subscribe(result => {
      if(result) {
        let currentSkillHierarchy = this.allSkillsTableData.length > 0 ? this.allSkillsTableData.find(skill => skill.showHierarchy): {};
        this.getAndSetSkillRows(() => { 
          this.setSelectedSkills(this.selectedSkillIds, this.allFilteredTableData);
          if(this.skillsHierarchyData.length > 0) {
            this._refreshSkillsHierarchy(currentSkillHierarchy);
          }
        });
      }
    });
  }

  // Skill Hierarchy

  showSkillActions(subSkill, skills, parentSkill) {
    for (let i = 0; i < skills.length; i++) {
      skills[i].skills.map(skill => {
        if (skills[i].id == parentSkill.id && skill.skill == subSkill.skill && !skill.isRootSkill && !skill.isHierarchySkillSelected) {
          skill.showSkillActions = true;
        } else {
          skill.showSkillActions = false;
        }
      })
      if (skills[i].subSkills.length > 0) {
        this.showSkillActions(subSkill, skills[i].subSkills, parentSkill);
      }
    }
  }

  expandOrCollapseSkills(skill) {
    skill.isExpanded = !skill.isExpanded;
    setTimeout(() => {
      this._setSliderPositionToViewSkill(skill);
    }, 500);
  }

  _customizeSkillDisplay(skill) {
    this.allFilteredTableData.map(allSkill => {
      allSkill.showSearchedSkillHierarchy = false;
      allSkill.showSearchedAlternateSkillHierarchy = false;
      if(skill && skill.alternate) {
        allSkill.showHierarchy = skill.showHierarchy ? allSkill.id == skill.id : false;
        if(allSkill.alternateSkills && allSkill.alternateSkills.length > 0 && allSkill.alternateSkills.some(alternateSkill => (alternateSkill && (alternateSkill.id == skill.id)))) {
          allSkill.showAlternateHierarchy = skill.showHierarchy;
        } else {
          allSkill.showAlternateHierarchy = false;
        }
      } else {
        allSkill.showAlternateHierarchy = false;
        if(skill.showHierarchy) {
          allSkill.showHierarchy = allSkill.id == skill.id;
        }
      }
    });

    this.allSkillsTableData.map(allSkill => {
      allSkill.showSearchedSkillHierarchy = false;
      allSkill.showSearchedAlternateSkillHierarchy = false;
      if(skill.alternate) {
        allSkill.showHierarchy = skill.showHierarchy ? allSkill.id == skill.id : false;
        if(allSkill.alternateSkills && allSkill.alternateSkills.length > 0 && allSkill.alternateSkills.some(alternateSkill => (alternateSkill && (alternateSkill.id == skill.id)))) {
          allSkill.showAlternateHierarchy = skill.showHierarchy;
        } else {
          allSkill.showAlternateHierarchy = false;
        }
      } else {
        allSkill.showAlternateHierarchy = false;
        if(skill.showHierarchy) {
          allSkill.showHierarchy = allSkill.id == skill.id;
        }
      }
    });
    this.setTablePage(this.page);
  }
  
  showSkillsHierarchy(skill) {
    if(skill) {
      skill.showHierarchy = !skill.showHierarchy;
      this.hierarchySearchSkill = '';
      this._setHierarchySelectedSkillCount(true);
      this.isHierarchySkillExists = true;
      this._customizeSkillDisplay(skill);
      if(skill.showHierarchy) {
        this.skillsHierarchyData = this.allSkillsHierarchy;
        this.checkIfSkillHierarchyExpanded(this.skillsHierarchyData, skill);
        setTimeout(() => {
          this._setSliderPositionToViewSkill(skill);
        }, 500);
      } else {
        this.skillsHierarchyData = [];
      }
    }
  }

  _setSliderPositionToViewSkill(skill) {
    let hierarchyViewSkill = !skill.alternate ? this.allSkillsTableData.filter(allSkill => allSkill.id == skill.id)[0] : this.allSkillsTableData.filter(allSkill => allSkill.id == skill.primarySkill.id)[0];
    if (hierarchyViewSkill) {
      const element = document.getElementById(hierarchyViewSkill.skill + 'id');
      const container = document.getElementById('tree-scroll');
      container.scrollLeft = 0;
      container.scrollTop = 0;
      const containerRect = container.getBoundingClientRect();
      const elementRect = element.getBoundingClientRect();
      if (elementRect.top > containerRect.height) {
        container.scrollTop = elementRect.top - containerRect.top - 30;
      }
      if (elementRect.left < 0) {
        container.scrollLeft = containerRect.left;
      } else {
        if (elementRect.left > containerRect.width) {
          container.scrollLeft = elementRect.left - containerRect.left - containerRect.width / 2;
        }
      }
    }
  }

  setSkillsHierarchy() {
    let mainSkill = new NewHierarchySkill(null, null, null, null);
    let allSkills = [...this.allSkillsTableData.filter(skill => !skill.alternate)];
    let rootSkill:any = Object.assign({}, new NewSkill());
      rootSkill.id == 'ROOT';
      rootSkill.skill = 'ROOT';
      rootSkill.category = 'TECHNICAL';
      rootSkill.isRootSkill = true;
    allSkills.map(skill => {
      if(!skill.parentSkills || skill.parentSkills.length == 0) {
        skill.parentSkills = [rootSkill];
      }
    });

    mainSkill.id = rootSkill.id;
    mainSkill.skills = [rootSkill];
    mainSkill.skillName = rootSkill.skill;

    let skillHierarchy = this.skillHierarchy(allSkills, mainSkill);
    this._removeDuplicateSkillsFromHierarchy(skillHierarchy);
    return [skillHierarchy];
  }

  skillHierarchy(allSkills, mainSkill) {
    let filteredSkills = [];
    allSkills.forEach(allSkill => {
      if(allSkill.parentSkills && allSkill.parentSkills.length > 0 && allSkill.parentSkills[0].id == mainSkill.id) {
        let newSkillHierarchy = new NewHierarchySkill(allSkill.id, mainSkill.id, allSkill.skill, [allSkill]);
        newSkillHierarchy.id = allSkill.id;
        newSkillHierarchy.parentId = mainSkill.id;
        newSkillHierarchy.skillName = allSkill.skill;
        newSkillHierarchy.skills = [allSkill];
        mainSkill.subSkills.push(newSkillHierarchy);
      } else {
        return filteredSkills.push(allSkill);
      }
    });

    this.setChildSkill(filteredSkills, mainSkill);

    return mainSkill;
  }

  setChildSkill(allSkills, originalSkill) {
    if (originalSkill.subSkills && originalSkill.subSkills.length > 0) {
      originalSkill.subSkills.forEach(skill => {
        allSkills.forEach(allSkill => {
          if (allSkill.parentSkills && allSkill.parentSkills.length && allSkill.parentSkills[0].id == skill.id) {
            let newSkillHierarchy = new NewHierarchySkill(allSkill.id, skill.id, allSkill.skill, [allSkill]);
            if (skill.subSkills && skill.subSkills.length > 0) {
              let isSkillExists = skill.subSkills.some(skill => skill.id == newSkillHierarchy.id);
              if (!isSkillExists) {
                skill.subSkills.push(newSkillHierarchy);
              }
            } else {
              skill.subSkills.push(newSkillHierarchy);
            }
            let filteredSkills = allSkills.filter(skill => skill.id != allSkill.id);
            this.setChildSkill(filteredSkills, skill);
          }
        });
      });
    }
  }

  _removeDuplicateSkillsFromHierarchy(parentHierarchy) {
    if (parentHierarchy && parentHierarchy.subSkills && parentHierarchy.subSkills.length > 0) {
      const uniqueSubSkillIds = new Set(parentHierarchy.subSkills.map(skill => skill.id));
      parentHierarchy.subSkills = [...uniqueSubSkillIds].map(id => parentHierarchy.subSkills.find(skill => skill.id === id));
      
      parentHierarchy.subSkills.forEach(subSkill => {
        this._removeDuplicateSkillsFromHierarchy(subSkill);
      });
    }
  }

  _refreshSkillsHierarchy(updatedHierarchy) {
    if(!this.isLoading) {
      if(updatedHierarchy && updatedHierarchy.id) {
        this.skillsHierarchyData = [];

        this.allFilteredTableData.map(skill => {
          if(skill.id == updatedHierarchy.id) {
            this.skillsHierarchyData = this.allSkillsHierarchy;
            skill.showHierarchy = true;
          }
        });

        this.allSkillsTableData.map(skill => {
          if(skill.id == updatedHierarchy.id) {
            skill.showHierarchy = true;
          }
        });

        let updatedSkill = this.allSkillsTableData.filter(skill => skill.id == updatedHierarchy.id)[0];
        this._customizeSkillDisplay(updatedSkill);
        if(updatedSkill.showHierarchy) {
          this.skillsHierarchyData = this.allSkillsHierarchy;
          this._setHierarchySelectedSkillCount(true);
          setTimeout(() => {
            this._setSliderPositionToViewSkill(updatedSkill);
          }, 500);
        }
      } else {
        this.skillsHierarchyData = [];
      }
    }
  }

  navigateToHome() {
      // Navigate to the AngularJS route from Angular component
      window.location.href = '#/dashboard';
      window.location.reload();
  }

  downloadAllSkills(template: TemplateRef<any>) {
    const config = new ModalConfig();
    config.backdrop = true;
    config.ignoreBackdropClick = true;
    config.backdrop = true;
    config.class = "custom-ngx-modal";
    const modalData: any = {};
    config.initialState = modalData;
    this.downloadSkillsModal = this.modalService.show(template, config);
  }

  closeDownloadSkillsModal() {
    this.downloadSkillsModal.hide();
  }

  downloadSkillFiles(fileExtension) {
    this.skillsService.downloadSkillFile(fileExtension, (response) => {
      const headers = response.headers;
      const contentDisposition = headers.get('content-disposition');
      const fileName = contentDisposition.split(';')[1].trim().split('=')[1];
      saveAs(response.body, fileName);
      this.closeDownloadSkillsModal();
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    })
  }

  deleteAllSkills(deleteConfirmed, deletedSkills, successCallback) {
    let tempDeletedSkills = deletedSkills.map(({ skillHierarchy, ...rest }) => rest);
    if(deleteConfirmed) {
      this.utilityService.showLoadingModal("Loading");
    }
    this.skillsService.deleteAllSkills(deleteConfirmed, tempDeletedSkills, (data) => {
      if (deleteConfirmed) {
        this.alertsAndNotificationsService.showBannerMessage("Skills deleted successfully", "success");
        let currentSkillHierarchy = this.allSkillsTableData.length > 0 ? this.allSkillsTableData.find(skill => skill.showHierarchy): {};
        currentSkillHierarchy = !currentSkillHierarchy || tempDeletedSkills.some(skill => skill.id == currentSkillHierarchy.id) ? {} : currentSkillHierarchy;
        this.getAndSetSkillRows(() => {
          if (this.skillsHierarchyData.length > 0) {
            this._refreshSkillsHierarchy(currentSkillHierarchy);
          }
        });
      }
      if(successCallback) {
        successCallback(data);
      }
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  updateAllSkills(skills, successCallback) {
      this.utilityService.showLoadingModal("Loading");
      this.skillsService.updateAllSkills(skills, () => {
        if(successCallback) {
          successCallback();
        }
      }, (error) => {
        if(error) {
          this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
        }
      });
  }

  _openUpdateOrDeleteSkillsModal(config) {
    this.updateMultipleSkillsModal = this.modalService.show(UpdateDeleteSelectedSkillsComponent, config);
    this.updateMultipleSkillsModal.content.onClose.subscribe(result => {
      if (result) {
        if (result.type == 'update') {
          let tempUpdateSkills = result.allSelectedSkills.map(({ skillHierarchy, ...rest }) => rest);
          tempUpdateSkills = tempUpdateSkills.filter(skill => !skill.alternate);
          this.utilityService.showLoadingModal("Loading");
          let currentSkillHierarchy = this.allSkillsTableData.length > 0 ? this.allSkillsTableData.find(skill => skill.showHierarchy): {};
          this.getAndSetSkillRows(() => {
            if (this.skillsHierarchyData.length > 0) {
              this._refreshSkillsHierarchy(currentSkillHierarchy);
            }
            this.setTableSelection();
          });
        } else {
          this.deleteAllSkills(true, result.allSelectedSkills, () => {
            this.setTableSelection();
          });
        }
      }
    });
  }

  checkIfRootSkill(skill) {
    return skill.id === 'ROOT' || skill.skill === 'ROOT' || skill.skillName === 'ROOT';
  }

  removeRootParentSkill(allSkills) {
    allSkills.forEach(skill => {
      if (skill && skill.parentSkills && skill.parentSkills.length > 0) {
        skill.parentSkills = skill.parentSkills.filter(parentSkill => !this.checkIfRootSkill(parentSkill));
      }
    });
  }


  updateOrDeleteSelectedSkills(type, title, isHierarchy) {
    const config = new ModalConfig();
    let selectedSkills = !isHierarchy ? this.allSkillsTableData.filter(skill => skill.isSelected && !this.checkIfRootSkill(skill)) : this.allSkillsTableData.filter(skill => skill.isHierarchySkillSelected && !this.checkIfRootSkill(skill));
    let allParentSkills = !isHierarchy ? this.allSkillsTableData.filter(skill => !skill.isSelected && !skill.alternate && !this.checkIfRootSkill(skill)) : this.allSkillsTableData.filter(skill => !skill.isHierarchySkillSelected && !skill.alternate && !this.checkIfRootSkill(skill));
    this.removeRootParentSkill(selectedSkills);
    this.removeRootParentSkill(allParentSkills);
    let modalData: any = {
      type: type,
      title: title,
      allSelectedSkills: [],
      skillCategories: this.skillCategories
    };
    config.initialState = modalData;
    // Get effected child and parent details of skills
    if(type == 'delete') {
      config.class = "custom-ngx-modal modal-lg";
      this.deleteAllSkills(false, selectedSkills, (data) => {
        modalData.allSelectedSkills = [...data];
        this._openUpdateOrDeleteSkillsModal(config);
      });
    } else {
      config.class = "custom-ngx-modal modal-lg";
      modalData.allSelectedSkills = [...selectedSkills];
      modalData.allParentSkills = [...allParentSkills];
      this._openUpdateOrDeleteSkillsModal(config);
    }
  }

  deleteAllSkillsInPage() {
    let allSkills = this.allSkillsTableData.map(skill => {return skill});
    let title = 'Delete All Skills';
    let message = `<p>Are you sure you want to delete <b> ALL THE SKILLS (${allSkills.length})</b> from the system.</p>`;
    this.alertsAndNotificationsService.showConfirm(title, message, 'warning', true, () => {
      this.deleteAllSkills(true, allSkills, () => {
        this.setTableSelection();
        this.alertsAndNotificationsService.showBannerMessage("Skills deleted successfully", "success");
      }); 
    }, () => {});
  }

  viewUploadedSkills() {
    const config = new ModalConfig();
    config.ignoreBackdropClick = true;
    config.backdrop = true;
    config.keyboard = false;
    config.class = "custom-ngx-modal modal-lg";
    const modalData: any = {
      title: 'View Uploaded Skills'
    };
    config.initialState = modalData;
    this.viewUploadedSkillsModal = this.modalService.show(ViewUploadedSkillsComponent, config);
  }
  
  viewSkillActivities(skillActivitiesTemplate: TemplateRef<any>) {
    const config = new ModalConfig();
    config.ignoreBackdropClick = true;
    config.backdrop = true;
    const modalData: any = {};
    config.class = "custom-modal-xl custom-ngx-modal";
    config.initialState = modalData;
    this.angularModalService.addModalOpenClassToBodyIfAnyModalIsOpen();
    this.skillActivitiesModal = this.modalService.show(skillActivitiesTemplate, config);
  }

  setHierarchyDisplaySearchedSkill(skills) {
    let searchedSkillIds = skills.map(skill => {return skill.id});
    this.allFilteredTableData.map(allSkill => {
      this._setHierarchyDisplaySearchedSkill(allSkill, searchedSkillIds);
    });

    this.allSkillsTableData.map(allSkill => {
      this._setHierarchyDisplaySearchedSkill(allSkill, searchedSkillIds);
    });
  }

  _setHierarchyDisplaySearchedSkill(allSkill, searchedSkillIds) {
    if (allSkill.alternateSkills && allSkill.alternateSkills.length > 0 && allSkill.alternateSkills.some(alternateSkill => (alternateSkill && (searchedSkillIds.includes(alternateSkill.id))))) {
      allSkill.showSearchedAlternateSkillHierarchy = true;
    } else {
      allSkill.showSearchedAlternateSkillHierarchy = false;
    }
    
    if (allSkill.showSearchedSkillHierarchy) {
      allSkill.showSearchedSkillHierarchy = searchedSkillIds.includes(allSkill.id);
    }
  }

  async _checkIfSkillHierarchyExpanded(hierarchySkill, searchedSkill) {
    if (hierarchySkill && hierarchySkill.length > 0) {
      for (let i = 0; i < hierarchySkill.length; i++) {
        const subSkill = hierarchySkill[i];
        if (subSkill.subSkills && subSkill.subSkills.length > 0 && subSkill.subSkills.some(skill => skill.id == searchedSkill.id)) {
          subSkill.isExpanded = true;
          return subSkill;
        } else if (subSkill.subSkills && subSkill.subSkills.length > 0) {
          const foundSkill = await this._checkIfSkillHierarchyExpanded(subSkill.subSkills, searchedSkill);
          if (foundSkill) {
            return foundSkill;
          }
        }
      }
    }
  }

  async _setSkillExpandedIfCollapsed(hierarchyData, searchedSkill) {
    if (searchedSkill && (searchedSkill.id == 'ROOT' || searchedSkill.skillName == 'ROOT')) {
      console.log("Hierarchy Skills Expanded");
    } else {
      searchedSkill = await this._checkIfSkillHierarchyExpanded(hierarchyData, searchedSkill);
      if (searchedSkill) {
        await this._setSkillExpandedIfCollapsed(hierarchyData, searchedSkill);
      }
    }
  }

  async checkIfSkillHierarchyExpanded(hierarchyData, skill) {
    if (hierarchyData && hierarchyData.length > 0 && hierarchyData[0].subSkills && hierarchyData[0].subSkills.length > 0) {
      const searchedSkill = await this._checkIfSkillHierarchyExpanded(hierarchyData[0].subSkills, skill);
      if (searchedSkill) {
        await this._setSkillExpandedIfCollapsed(hierarchyData, searchedSkill);
      }
    }
  }

  querySkillSearchesInHierarchy() {
    this.isHierarchySkillExists = true;
    if(this.hierarchySearchSkill && this.hierarchySearchSkill != '') {
      let searchSkill = [];
      this.allSkillsTableData.map(allSkill => {
        if(allSkill.skill.toLowerCase().includes(this.hierarchySearchSkill.toLowerCase())) {
          allSkill.showSearchedSkillHierarchy = true;
          searchSkill.push(allSkill);
        }
      });
      if(searchSkill && searchSkill.length > 0) {
        this.setHierarchyDisplaySearchedSkill(searchSkill);
        this.skillsHierarchyData = this.allSkillsHierarchy;
        this.checkIfSkillHierarchyExpanded(this.skillsHierarchyData, searchSkill[0]);
        setTimeout(() => {
          this._setSliderPositionToViewSkill(searchSkill[0]);
        }, 500);
      } else {
        this.isHierarchySkillExists = false;
      }
    }
  }
  
  _setHierarchySelectedSkillCount(isSetCountToZero) {
    let count = 0;
    this.allSkillsTableData.map(skill => {
      if(isSetCountToZero) {
        skill.isHierarchySkillSelected = false;
      } else {
        if (skill.isHierarchySkillSelected) {
          count++;
        }
      }
    });
    this.selectedHierarchySkillCount = count;
  }

  selectOrDeselectAllRowsInHierarchy(skill) {
    skill.isHierarchySkillSelected = !skill.isHierarchySkillSelected;
    skill.showSkillActions = false;
    const index = this.allSkillsTableData.findIndex(allSKill => allSKill.id === skill.id);
    if (index !== -1) {
      this.allSkillsTableData[index].isHierarchySkillSelected = skill.isHierarchySkillSelected;
    }
    this._setHierarchySelectedSkillCount(false);
  }

}

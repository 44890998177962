<style>ng-i
    .text-work-location-match {
        color: greenyellow;
    }
</style>

<span *ngIf="workLocations && workLocations.length > 0">
    <span [tooltip]="tooltipMessage" placement="top" container="body">
        <i class="far fa-building {{iconClass}}" 
            style="display:inline-block;{{iconStyle}}"
            [ngClass]="{'red': matchStatus ==='noMatch', 'text-work-location-match': matchStatus === 'match'}"
            popoverClass="additional-details-pop-over" 
            [popoverTitle]="message" 
            container="body"
            [popover]="workLocationsTemplate" 
            placement="bottom" 
            triggers="mouseenter:mouseleave">
        </i>
    </span>
</span>

<span *ngIf="(!workLocations || workLocations.length === 0) && context != 'requisitionCard'" 
    class="fa-stack fa position-relative" 
    style="{{noLocationContainerStyle}}"
    tooltip="Candidate's Preferred Work locations not available"
    container="body">
    <i class="far fa-building fa-stack-1x" style="{{buildingIconStyle}}"></i>
    <i class="fa fa-ban fa-stack-2x red"
        [ngClass]="{'candidate-additional-details-not-available-candidate-icon': context == 'candidateCard', 'candidate-additional-details-not-available-job-match-icon': context == 'candidateJobMatchCard'}"
        style="{{buildingCrossIconStyle}}"></i>
</span>

<span class="position-relative" *ngIf="(!workLocations || workLocations.length === 0) && context == 'requisitionCard'">
    <i class="far fa-building {{iconClass}}" style="display:inline-block;{{iconStyle}}"
        popoverClass="additional-details-pop-over" container="body"
        tooltip="Job Work locations not available">
    </i>
    <span class="fa fa-times red work-locations-data-not-available-icon" style="left: 8px"></span>
</span>

<ng-template #workLocationsTemplate>
        <p class="text-left pl-2" *ngFor="let workLocation of workLocations; let i = index;">
            <span>{{i + 1}}.</span>
            <span>{{workLocation.name}}</span>
        </p>
</ng-template>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textSentenceCase'
})
export class TextSentenceCasePipe implements PipeTransform {

  transform(value: any): any {
    let str = value;
    if (!str) return str;
    str = str.toLowerCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkLocationsIconComponent } from './work-locations-icon.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TooltipModule, PopoverModule } from 'ngx-bootstrap';

@NgModule({
  declarations: [WorkLocationsIconComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    TooltipModule.forRoot(),
    PopoverModule.forRoot()
  ],
  exports: [WorkLocationsIconComponent],
  entryComponents: [WorkLocationsIconComponent]
})
export class WorkLocationsIconModule { }

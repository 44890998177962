import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConvertEmployeeToUserComponent } from './convert-employee-to-user.component';
import { FormsModule } from '@angular/forms';
import { TagInputModule } from 'ngx-chips';

@NgModule({
  declarations: [ConvertEmployeeToUserComponent],
  imports: [
    CommonModule,
    FormsModule,
    TagInputModule
  ],
  exports: [ConvertEmployeeToUserComponent],
  entryComponents: [ConvertEmployeeToUserComponent]
})
export class ConvertEmployeeToUserModule { }

import { Component, OnInit, Inject, Input, TemplateRef, ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { ModalConfig } from 'src/app/core/models/modalConfig';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { RequisitionEntitiesUploadComponent } from './requisition-entities-upload/requisition-entities-upload.component';
import { SkillTablePage } from 'src/app/core/models/skillTablePage';
import { AngularModalService } from 'src/app/core/services/modal.service';
import { ManageSkillsService } from 'src/app/core/services/manage-skills.service';
import { UtilityService } from 'src/app/core/services/utility.service';

declare var saveAs: any;
@Component({
  selector: 'app-manage-requisition-entities-page',
  templateUrl: './manage-requisition-entities-page.component.html',
  styleUrls: ['./manage-requisition-entities-page.component.css']
})
export class ManageRequisitionEntitiesPageComponent implements OnInit {
  @Input() requisitionEntity;
  @Input() entitiesData;
  @Output() successCallback = new EventEmitter<any>();
  @ViewChild('degreeTemplate', { static: true }) degreeTemplate: TemplateRef<any>;
  @ViewChild('fieldOfStudyTemplate', { static: true }) fieldOfStudyTemplate: TemplateRef<any>;
  @ViewChild('entityActionsTemplate', {static: true}) entityActionsTemplateRef: TemplateRef<any>;

  uploadRequisitionEntitiesModal: BsModalRef;
  downloadRequisitionEntitiesModal: BsModalRef;
  addEntityModal: BsModalRef;
  editEntityModal: BsModalRef;
  requisitionEntitiesActivitiesTemplateModal: BsModalRef;
  viewUploadedEntitiesModal: BsModalRef;
  page = new SkillTablePage();
  allEntitiesList: any = [];
  columns: any = [];
  requisitionEntitiesList: any = [];
  downloadSkillFormat: any = 'xls';
  searchEntity: any = '';
  addEditEntityData: any;
  allRequisitionEntitiesList: any = [];

  isEntityLoading: boolean = false;

  constructor(
    @Inject('$state') public $state: any,
    private alertAndNotificationService: AlertAndNotificationsService,
    private modalService: BsModalService,
    private angularModalService: AngularModalService,
    private cdr: ChangeDetectorRef,
    private skillsService: ManageSkillsService,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.setTemplate();
    this.setPage({ offset: 0 });
  }

  ngOnChanges(change) {
    if(change && change.entitiesData && !change.entitiesData.firstChange && (change.entitiesData.currentValue !== change.entitiesData.previousValue)) {
      this.requisitionEntitiesList = change.entitiesData ? this.setEntitiesData(change.entitiesData.currentValue.allEntities) : [];
      this.allRequisitionEntitiesList = this.setEntitiesData(this.requisitionEntitiesList);
      this.setTablePage(this.page);
    }
  }

  setEntitiesData(entity) {
    return JSON.parse(JSON.stringify(entity));
  }

  onSort(event) {
    this.isEntityLoading = true;

    setTimeout(() => {
      const rows = [...this.requisitionEntitiesList];
      const sort = event.sorts[0];
      rows.sort((a, b) => {
        const aValue = a[sort.prop] !== undefined && a[sort.prop] !== null ? a[sort.prop].toString() : '';
        const bValue = b[sort.prop] !== undefined && b[sort.prop] !== null ? b[sort.prop].toString() : '';
        return aValue.localeCompare(bValue) * (sort.dir === 'desc' ? -1 : 1);
      });

      let pageNumber = this.page.pageNumber;
      this.ngOnInit();
      this.requisitionEntitiesList = rows;
      this.setPage({ offset: pageNumber });
      this.setTablePage(this.page);
      this.isEntityLoading = false;
    }, 1000);
  }

  setPage(pageInfo) {
    this.page.pageNumber = 0;
    this.page.size = 20;
    this.page.pageNumber = pageInfo.offset;
    this.setTablePage(this.page);
  }

  setTablePage(pageInfo) {
    this.page.pageNumber = (!pageInfo.pageNumber && pageInfo.offset) ? pageInfo.offset : (pageInfo.pageNumber ? pageInfo.pageNumber : 0);
    this.page.totalElements = this.requisitionEntitiesList ? this.requisitionEntitiesList.length : 0;
    this.page.totalPages = this.page.totalElements / this.page.size;
    const start = this.page.pageNumber * this.page.size;
    const end = Math.min(start + this.page.size, this.page.totalElements);
    this.allEntitiesList = [];
    for (let i = start; i < end; i++) {
      let entity = this.requisitionEntitiesList[i];
      this.allEntitiesList.push(entity);
    }
    this.cdr.detectChanges();
  }

  setTemplate() {
    switch(this.requisitionEntity) {
      case 'degrees':
        this.columns = [{ prop: 'order', name: 'Level' }, { prop: 'degreeName', name: 'Degree Name' }, { prop: 'alternateName' }, { prop: 'fullName' }, { prop: 'enableFieldOfStudy' }, { prop: 'showInParenthesis' }, {prop: 'Actions', cellTemplate:this.entityActionsTemplateRef, sortable: false}];
        break;
      case 'fieldOfStudy':
        this.columns = [{ prop: 'fieldOfStudy', name: 'Field of Study' }, { prop: 'Actions', cellTemplate:this.entityActionsTemplateRef, sortable: false}];
        break;
      case 'roles':
        this.columns = [{ prop: 'role', name: 'Role' }, { prop: 'Actions', cellTemplate:this.entityActionsTemplateRef, sortable: false}];
        break;
      case 'certificates':
        this.columns = [{ prop: 'name', name: 'Name' }, { prop: 'group', name: 'Group' }, { prop: 'Actions', cellTemplate:this.entityActionsTemplateRef, sortable: false}];
        break;
      default:
        break;
    };
    
    this.requisitionEntitiesList = this.entitiesData ? this.setEntitiesData(this.entitiesData.allEntities) : [];
    this.allRequisitionEntitiesList = this.setEntitiesData(this.requisitionEntitiesList);
  }

  setSingleEntityTitle(type) {
    let title = '';
    switch (type) {
      case 'degrees':
        title = 'Degree';
        break;
      case 'fieldOfStudy':
        title = 'Field of Study';
        break;
      case 'roles':
        title = 'Role';
        break;
      case 'certificates':
        title = 'Certificate';
        break;
      default:
        title = 'Degrees';
        break;
    }
    return title;
  }

  setMultipleEntitiesTitle(type) {
    let title = '';
    switch (type) {
      case 'degrees':
        title = 'Degrees';
        break;
      case 'fieldOfStudy':
        title = 'Field of Study';
        break;
      case 'roles':
        title = 'Roles';
        break;
      case 'certificates':
        title = 'Certificates';
        break;
      default:
        title = 'Degrees';
        break;
    }
    return title;
  }

  uploadOrDownloadFile() {
      const config = new ModalConfig();
      const modalData: any = {
        action: this.requisitionEntity,
        type: 'uploadRequisitionEntities',
        title: `Upload ${this.setMultipleEntitiesTitle(this.requisitionEntity)}`
      };
      config.class = "custom-modal-65 custom-ngx-modal";
      config.initialState = modalData;
      config.ignoreBackdropClick = true;
      config.backdrop = true;
      this.uploadRequisitionEntitiesModal = this.modalService.show(RequisitionEntitiesUploadComponent, config);
      this.uploadRequisitionEntitiesModal.content.onClose.subscribe(result => {
        if (result && result == 'success') {
          if (this.successCallback) {
            this.successCallback.emit();
          }
        }
      });
    }

  downloadAllRequisitionEntities(template: TemplateRef<any>) {
    const config = new ModalConfig();
    config.backdrop = true;
    config.ignoreBackdropClick = true;
    config.backdrop = true;
    config.class = "custom-ngx-modal";
    const modalData: any = {};
    config.initialState = modalData;
    this.downloadRequisitionEntitiesModal = this.modalService.show(template, config);
  }

  closeDownloadRequisitionEntitiesModal() {
    this.downloadRequisitionEntitiesModal.hide();
  }

  setAndDownloadFile(response) {
    const headers = response.headers;
    const contentDisposition = headers.get('content-disposition');
    const fileName = contentDisposition.split(';')[1].trim().split('=')[1];
    saveAs(response.body, fileName);
    this.closeDownloadRequisitionEntitiesModal();
    this.utilityService.hideLoadingModal();
  }

  downloadAllRequisitionEntitiesFile(fileExtension) {
    this.utilityService.showLoadingModal("Downloading...");
    if (this.requisitionEntity === 'degrees') {
      this.skillsService.downloadDegreesFile(fileExtension, (response) => {
        this.setAndDownloadFile(response);
      }, (error) => {
        this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
        this.utilityService.hideLoadingModal();
      });
    } else if (this.requisitionEntity === 'fieldOfStudy') {
      this.skillsService.downloadFieldOfStudyFile(fileExtension, (response) => {
        this.setAndDownloadFile(response);
      }, (error) => {
        this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
        this.utilityService.hideLoadingModal();
      });
    } else if (this.requisitionEntity === 'roles') {
      this.skillsService.downloadCandidateRolesFile(fileExtension, (response) => {
        this.setAndDownloadFile(response);
      }, (error) => {
        this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
        this.utilityService.hideLoadingModal();
      });
    } else if (this.requisitionEntity === 'certificates') {
      this.skillsService.downloadCertificationsFile(fileExtension, (response) => {
        this.setAndDownloadFile(response);
      }, (error) => {
        this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
        this.utilityService.hideLoadingModal();
      });
    }
  }

  onSearchTextChange(event) {

  }

  queryEntitySearches() {
    if (this.requisitionEntity === 'degrees') {
      this.getDegreesEntities();
    } else if (this.requisitionEntity === 'fieldOfStudy') {
      this.getFieldOfStudyEntities();
    } else if (this.requisitionEntity === 'roles') {
      this.getRolesEntities();
    } else if (this.requisitionEntity === 'certificates') {
      this.getCertificatesEntities();
    }
  }

  getDegreesEntities() {
    this.isEntityLoading = true;
    let degreeFilerObj = {
      searchDegree: this.searchEntity,
      sortColumn: '',
      sortDir: ''
    }
    this.skillsService.getAllDegreesEntityLists(degreeFilerObj, (data) => {
      this.setAllEntitiesData(data);
    }, (error) => {
      this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
      this.isEntityLoading = false;
    })
  }

  getFieldOfStudyEntities() {
    this.isEntityLoading = true;
    let fieldOfStudyObj = {
      searchFieldOfStudy: this.searchEntity,
      sortColumn: '',
      sortDir: ''
    }
    this.skillsService.getAllFieldOfStudyLists(fieldOfStudyObj, (data) => {
      this.setAllEntitiesData(data);
    }, (error) => {
      this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
      this.isEntityLoading = false;
    });
  }

  getRolesEntities() {
    this.isEntityLoading = true;
    let roleObj = {
      searchRole: this.searchEntity,
      sortColumn: '',
      sortDir: ''
    }
    this.skillsService.getAllCandidateRolesLists(roleObj, (data) => {
      this.setAllEntitiesData(data);
    }, (error) => {
      this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
      this.isEntityLoading = false;
    });
  }

  getCertificatesEntities() {
    this.isEntityLoading = true;
    let roleObj = {
      searchName: this.searchEntity,
      sortColumn: '',
      sortDir: ''
    }
    this.skillsService.getAllCertificationsLists(roleObj, (data) => {
      this.setAllEntitiesData(data);
    }, (error) => {
      this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
      this.isEntityLoading = false;
    });
  }

  setAllEntitiesData(data) {
    this.requisitionEntitiesList = [];
    this.requisitionEntitiesList = this.searchEntity !== '' ? this.setEntitiesData(data.filteredEntities) : this.setEntitiesData(data.allEntities);
    this.allRequisitionEntitiesList = this.setEntitiesData(data.allEntities);
    this.isEntityLoading = false;
    if (this.successCallback) {
      this.successCallback.emit(this.allRequisitionEntitiesList.length);
    }
    this.setPage({ offset: 0 });
  }

  setEntityClass() {
    if (this.requisitionEntity === 'degrees' || this.requisitionEntity === 'certificates') {
      return "modal-lg custom-ngx-modal";
    } else if (this.requisitionEntity === 'fieldOfStudy' || this.requisitionEntity === 'roles') {
      return "custom-ngx-modal";
    } else {
      return "modal-lg custom-ngx-modal"
    }
  }

  addEntity(template: TemplateRef<any>) {
    this.addEditEntityData = null;
    const config = new ModalConfig();
    config.ignoreBackdropClick = true;
    config.backdrop = true;
    config.keyboard = false;
    config.class = this.setEntityClass();
    const modalData: any = {};
    config.initialState = modalData;
    this.addEntityModal = this.modalService.show(template, config);
  }

  addEditDegree(entity, action) {
    this.utilityService.showLoadingModal("Loading...");
    this.skillsService.bulkAddUpdateDegrees([entity], (data) => {
      this.utilityService.hideLoadingModal();
      let message = action === 'add' ? 'added' : 'updated';
      this.alertAndNotificationService.showBannerMessage(`Degree ${message} successfully`, "success");
      this.queryEntitySearches();
    }, (error) => {
      this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
      this.utilityService.hideLoadingModal();
    });
  }

  addEditFiledOfStudy(entity, action) {
    this.utilityService.showLoadingModal("Loading...");
    this.skillsService.addUpdateFieldOfStudy([entity], (data) => {
      this.utilityService.hideLoadingModal();
      let message = action === 'add' ? 'added' : 'updated';
      this.alertAndNotificationService.showBannerMessage(`Field of Study ${message} successfully`, "success");
      this.queryEntitySearches();
    }, (error) => {
      this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
      this.utilityService.hideLoadingModal();
    });
  }

  addEditCandidateRoles(entity, action) {
    this.utilityService.showLoadingModal("Loading...");
    this.skillsService.addUpdateCandidateRoles([entity], (data) => {
      this.utilityService.hideLoadingModal();
      let message = action === 'add' ? 'added' : 'updated';
      this.alertAndNotificationService.showBannerMessage(`Role ${message} successfully`, "success");
      this.queryEntitySearches();
    }, (error) => {
      this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
      this.utilityService.hideLoadingModal();
    });
  }

  addEditCertificates(entity, action) {
    this.utilityService.showLoadingModal("Loading...");
    this.skillsService.addUpdateCertifications([entity], (data) => {
      this.utilityService.hideLoadingModal();
      let message = action === 'add' ? 'added' : 'updated';
      this.alertAndNotificationService.showBannerMessage(`Role ${message} successfully`, "success");
      this.queryEntitySearches();
    }, (error) => {
      this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
      this.utilityService.hideLoadingModal();
    });
  }

  saveAddEntity(event) {
    if (this.requisitionEntity === 'degrees') {
      if (event.alternateName && event.alternateName.length > 0) {
        let alternateNames = event.alternateName.map(alternateName => { return alternateName.value });
        event.alternateName = alternateNames;
      }
      this.addEditDegree(event, 'add');
    } else if (this.requisitionEntity === 'fieldOfStudy') {
      this.addEditFiledOfStudy(event, 'add');
    } else if (this.requisitionEntity === 'roles') {
      this.addEditCandidateRoles(event, 'add');
    } else if (this.requisitionEntity === 'certificates') {
      this.addEditCertificates(event, 'add');
    }
  }

  saveEditEntity(event) {
    if (this.requisitionEntity === 'degrees') {
      if (event.alternateName && event.alternateName.length > 0) {
        let alternateNames = event.alternateName.map(alternateName => { return alternateName.value });
        event.alternateName = alternateNames;
      }
      this.addEditDegree(event, 'edit');
    } else if (this.requisitionEntity === 'fieldOfStudy') {
      this.addEditFiledOfStudy(event, 'edit');
    } else if (this.requisitionEntity === 'roles') {
      this.addEditCandidateRoles(event, 'edit');
    } else if (this.requisitionEntity === 'certificates') {
      this.addEditCertificates(event, 'edit');
    }
  }

  closeAddEntityModal() {
    this.addEntityModal.hide();
  }

  editEntity(template: TemplateRef<any>, row) {
    this.addEditEntityData = row;
    const config = new ModalConfig();
    config.ignoreBackdropClick = true;
    config.backdrop = true;
    config.keyboard = false;
    config.class = this.setEntityClass();
    const modalData: any = {};
    config.initialState = modalData;
    this.editEntityModal = this.modalService.show(template, config);
  }

  closeEditEntityModal() {
    this.editEntityModal.hide();
  }

  deleteAllEntitiesPage() {
    let title = `Delete All ${this.setMultipleEntitiesTitle(this.requisitionEntity)}`;
    let message = `Are you sure you want to delete <b>ALL THE ${this.setMultipleEntitiesTitle(this.requisitionEntity).toUpperCase()} (${this.allRequisitionEntitiesList.length})</b> from the system.`
    this.alertAndNotificationService.showConfirm(title, message, 'warning', true, () => {
      if (this.requisitionEntity === 'degrees') {
        this.deleteDegrees(this.allRequisitionEntitiesList);
      } else if (this.requisitionEntity === 'fieldOfStudy') {
        this.deleteFieldOfStudy(this.allRequisitionEntitiesList);
      } else if (this.requisitionEntity === 'roles') {
        this.deleteRoles(this.allRequisitionEntitiesList);
      } else if (this.requisitionEntity === 'certificates') {
        this.deleteCertificates(this.allRequisitionEntitiesList);
      }
    }, () => {
      // Do Nothing
    });
  }

  deleteEntity(entity) {
    let deleteEntity = this.requisitionEntity === 'degrees' ? entity.degreeName : this.requisitionEntity === 'fieldOfStudy' ? entity.fieldOfStudy : this.requisitionEntity === 'roles' ? entity.role : this.requisitionEntity === 'certificates' ? entity.name : 'Entity';
    let title = `Delete ${this.setSingleEntityTitle(this.requisitionEntity)}`;
    let message = `Do you want to delete the ${this.setSingleEntityTitle(this.requisitionEntity)} <b>'${deleteEntity}'</b>.`
    this.alertAndNotificationService.showConfirm(title, message, 'warning', true, () => {
      if (this.requisitionEntity === 'degrees') {
        this.deleteDegrees([entity]);
      } else if (this.requisitionEntity === 'fieldOfStudy') {
        this.deleteFieldOfStudy([entity]);
      } else if (this.requisitionEntity === 'roles') {
        this.deleteRoles([entity]);
      } else if (this.requisitionEntity === 'certificates') {
        this.deleteCertificates([entity]);
      }
    }, () => {
      // Do Nothing
    });
  }

  deleteDegrees(entities) {
    this.utilityService.showLoadingModal("Deleting...");
    this.skillsService.bulkDeleteDegrees(entities, (data) => {
      this.utilityService.hideLoadingModal();
      this.alertAndNotificationService.showBannerMessage("Degrees deleted successfully", "success");
      this.queryEntitySearches();
    }, (error) => {
      this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
      this.utilityService.hideLoadingModal();
    });
  }

  deleteFieldOfStudy(entities) {
    this.utilityService.showLoadingModal("Deleting...");
    this.skillsService.bulkDeleteFieldOfStudy(entities, (data) => {
      this.utilityService.hideLoadingModal();
      this.alertAndNotificationService.showBannerMessage("Field of Study deleted successfully", "success");
      this.queryEntitySearches();
    }, (error) => {
      this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
      this.utilityService.hideLoadingModal();
    });
  }

  deleteRoles(entities) {
    this.utilityService.showLoadingModal("Deleting...");
    this.skillsService.bulkDeleteCandidateRoles(entities, (data) => {
      this.utilityService.hideLoadingModal();
      this.alertAndNotificationService.showBannerMessage("Roles deleted successfully", "success");
      this.queryEntitySearches();
    }, (error) => {
      this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
      this.utilityService.hideLoadingModal();
    });
  }

  deleteCertificates(entities) {
    this.utilityService.showLoadingModal("Deleting...");
    this.skillsService.bulkDeleteCertifications(entities, (data) => {
      this.utilityService.hideLoadingModal();
      this.alertAndNotificationService.showBannerMessage("Roles deleted successfully", "success");
      this.queryEntitySearches();
    }, (error) => {
      this.alertAndNotificationService.showBannerMessage(error.message, 'danger');
      this.utilityService.hideLoadingModal();
    });
  }

  viewRequisitionEntitiesActivities(requisitionEntitiesActivitiesTemplate: TemplateRef<any>) {
    const config = new ModalConfig();
    config.ignoreBackdropClick = true;
    config.backdrop = true;
    const modalData: any = {};
    config.class = "custom-modal-xl custom-ngx-modal";
    config.initialState = modalData;
    this.angularModalService.addModalOpenClassToBodyIfAnyModalIsOpen();
    this.requisitionEntitiesActivitiesTemplateModal = this.modalService.show(requisitionEntitiesActivitiesTemplate, config);
  }

  viewUploadedRequisitionEntities(viewUploadedRequisitionEntitiesTemplate: TemplateRef<any>) {
    const config = new ModalConfig();
    config.ignoreBackdropClick = true;
    config.backdrop = true;
    config.keyboard = false;
    config.class = "custom-ngx-modal modal-lg";
    const modalData: any = {};
    config.initialState = modalData;
    this.viewUploadedEntitiesModal = this.modalService.show(viewUploadedRequisitionEntitiesTemplate, config);
  }

}

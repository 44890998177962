<div *ngIf="showSyncMessage" class="assessments-sync-message">
    <span class="assessments-sync-message-close">
        <i class="fa fa-times pointer"
            (click)="showSyncMessage = !showSyncMessage">
        </i>
    </span>
    <span [innerHtml]="syncMessage">
    </span>
    <span class="emerald cursor-pointer" *ngIf="displayNavigationToAssessments">
        <span class="pl-4" (click)="goToAssessments()">
            Yes 
        </span>
        <span class="pl-3" (click)="showSyncMessage = !showSyncMessage">
            No 
        </span>
    </span>
</div>
import { Component, OnInit, Input, SimpleChanges  } from '@angular/core';

@Component({
  selector: 'app-upload-skills-results',
  templateUrl: './upload-skills-results.component.html',
  styleUrls: ['./upload-skills-results.component.css']
})
export class UploadSkillsResultsComponent implements OnInit {
  @Input() uploadedSkillsDetails;
  uploadSkillsCount:any = {};
  allUploadedSkills: any = [];
  uploadedSkills: any = [];
  uploadedSkillsHeading: any = 'All Uploaded Skills';

  constructor() { }

  ngOnInit() {
    this._setUploadSkillsCount(0,0,0,0,0,0);
    this._setUploadSkills();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.uploadedSkillsDetails) {
      this._setUploadSkills();
    }
  }

  _setUploadSkills() {
    if(this.uploadedSkillsDetails) {
      this._setUploadSkillsCount(
        this.uploadedSkillsDetails.allSkills, 
        this.uploadedSkillsDetails.addedSkills, 
        this.uploadedSkillsDetails.updatedSkills,
        this.uploadedSkillsDetails.deletedSkills,
        this.uploadedSkillsDetails.notProcessedSkills,
        this.uploadedSkillsDetails.unchangedSkills
      );
      const uploadedSkills = this.uploadedSkillsDetails.results.map(activity => ({ ...activity}));
      this.uploadedSkills = [...uploadedSkills];
      this.uploadedSkills.forEach(skill => {
        this._setSkillStatus(skill);
      });
      this.allUploadedSkills = [...this.uploadedSkills];
    } else {
      this.uploadedSkills = [];
    }
  }

  _setUploadSkillsCount(allSkills, addedSkills, updatedSkills, deletedSkills, errorSkills, unchangedSkills) {
    this.uploadSkillsCount = {
      skillsAdded: addedSkills ? addedSkills : 0,
      skillsUpdated: updatedSkills ? updatedSkills : 0,
      skillsDeleted: deletedSkills ? deletedSkills : 0,
      skillsError: errorSkills ? errorSkills : 0,
      allSkills: allSkills ? allSkills : 0,
      skillsUnchanged: unchangedSkills ? unchangedSkills : 0
    }
  }

  _setSkillStatus(result) {
    let badge = "badge-primary";
    if (result.operation == 'added') {
      badge = "badge-success";
    } else if (result.operation == 'deleted' || result.operation == 'error') {
      badge = "badge-danger";
      if (result.activityMessage && result.activityMessage != "") {
        result.activityMessage = result.activityMessage.charAt(0).toUpperCase() + result.activityMessage.slice(1);
      }
    } else if(result.operation == 'unchanged') {
      badge = "badge-warning";
    }
    if (result.activityMessage && result.activityMessage != "") {
      result.activityMessage = result.activityMessage.replace(/"([^"]*)"/g, `<span class="badge ${badge}">$1</span>`);
    }
  }

  showUploadedSKills(type) {
    if (type == 'add') {
      this.uploadedSkills = this.allUploadedSkills.filter(skill => !skill.operation || skill.operation == 'added');
      this.uploadedSkillsHeading = 'Added Skills';
    } else if (type == 'error') {
      this.uploadedSkills = this.allUploadedSkills.filter(skill => skill.operation == 'error');
      this.uploadedSkillsHeading = 'Not Processed Skills';
    } else if (type == 'update') {
      this.uploadedSkills = this.allUploadedSkills.filter(skill => skill.operation == 'updated');
      this.uploadedSkillsHeading = 'Updated Skills';
    } else if (type == 'delete') {
      this.uploadedSkills = this.allUploadedSkills.filter(skill => skill.operation == 'deleted');
      this.uploadedSkillsHeading = 'Deleted Skills';
    } else if (type == 'unchanged') {
      this.uploadedSkills = this.allUploadedSkills.filter(skill => skill.operation == 'unchanged');
      this.uploadedSkillsHeading = 'UnChanged Skills';
    } else {
      this.uploadedSkills = [...this.allUploadedSkills];
      this.uploadedSkillsHeading = 'All Uploaded Skills';
    }
  }

}

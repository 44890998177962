<div class="container">
	<div class="row">
		<!-- Headings start -->
		<div class="col-xs-10" style="padding:15px;" *ngIf="context === 'candidate'">
			Activity details for <span class="emerald">
				{{candidateName}}
			</span>
		</div>
		<div class="col-xs-10" style="padding:15px;" *ngIf="context === 'candidateJobMatch'">
			Activity details for <span class="emerald">
				{{candidateName}}
			</span> for requisition
			<span class="emerald">
				{{jobTitle}}</span> with Job Id
			<span class="emerald">{{requisitionNumber}}</span>
			<span *ngIf="clientOrBuName !== null && clientOrBuName !== undefined">
				for <span class="emerald">{{clientOrBuName}}</span>
			</span>
		</div>
		<div class="col-xs-10" style="padding:15px;" *ngIf="context === 'user' || context === 'employee'">
			Activity details for <span class="emerald">
				{{userName}}
			</span>
		</div>
		<div class="pull-right" style="margin-right:10px" *ngIf="context != 'requisition'">
			<span title="Click to show / hide the filter" class="pull-right filter-icon">
				<i class="fa fa-filter fa-2x cursor-point" aria-hidden="true" id="open-filter"></i>
			</span>
		</div>
		<!-- Headings end -->

		<!--filter starts-->
		<div class="col-xs-12" *ngIf="context != 'requisition'">
			<div class="row">
				<div class="col-xs-12" id="search-panel" style="display: none;">
					<div class="main-box" style="padding-top: 1%;">
						<div class="main-box-body clearfix">
							<form role="form" name="" novalidate autocomplete="off">
								<div class="row">
									<div class="col-lg-12 col-sm-12 col-md-12">
										<div class="row">
											<div class="col-lg-3 col-sm-3 col-md-3" *ngIf="context === 'candidate'">
												<div class="form-group">
													<label for="activityType">Activity Type</label>
													<select class="form-control access inputBoxBorder" [(ngModel)]="activityFilter.activityType"
														    (change)="getActivities()" name="activityType" id="activityType">
														<option value="any">Any</option>
														<option *ngFor="let activityType of activityTypes; let i = index" value="{{activityType.value}}">{{activityType.name}}</option>
													</select>
												</div>
											</div>
											<div class="col-lg-3 col-sm-3 col-md-3" *ngIf="context === 'assessments'">
												<div class="form-group">
													<label for="assessmentActivityType">Assessment Name:</label>
													<input type="text" class="form-control" name="assessmentActivityType" [(ngModel)]="activityFilter.assessmentName" (keyup)="getActivities()" placeholder="Enter Assessment Name">
												</div>
											</div>
											<div class="col-lg-3 col-sm-3 col-md-3" *ngIf="context === 'candidate'">
												<div class="form-group">
													<label for="clientOrBuId" *ngIf="companyType === MESSAGECONSTANTS.COMPANY_TYPES.STAFFING">Client</label>
													<label for="clientOrBuId" *ngIf="companyType != MESSAGECONSTANTS.COMPANY_TYPES.STAFFING">BU</label>
													<select class="form-control access inputBoxBorder" [(ngModel)]="activityFilter.clientOrBuId"
															(change)="getActivities()" name="clientOrBuId" id="clientOrBuId">
														<option value="any">Any</option>
														<option *ngFor="let client of clientsOrBu; let i = index" value="{{client.id}}">{{client.val}}</option>
													</select>
												</div>
											</div>
											<div class="col-lg-3 col-sm-3 col-md-3" *ngIf="context === 'candidate'">
												<div class="form-group">
													<label for="role">Role</label>
													<select class="form-control access inputBoxBorder" [(ngModel)]="activityFilter.title"
															(change)="onRoleChange()" name="role" id="role">
														<option value="any">Any</option>
														<option *ngFor="let role of uniqueRoles; let i = index" value="{{role}}">{{role}}</option>
													</select>
												</div>
											</div>
											<div class="col-lg-3 col-sm-3 col-md-3" *ngIf="context === 'candidate'">
												<div class="form-group">
													<label for="requisitionNumber">Requisition</label>
													<select class="form-control access inputBoxBorder" [(ngModel)]="activityFilter.requisitionId"
															(change)="getActivities()" name="requisitionNumber" id="requisitionNumber">
														<option value="any">Any</option>
														<option *ngFor="let requisition of requisitionIds; let i = index" value="{{requisition.id}}">{{requisition.requisitionNumber}}</option>
													</select>
												</div>
											</div>
											<div class="col-lg-3 col-sm-3 col-md-3">
												<div class="form-group">
													<label for="startDate" class="control-label">
														Start Date:
													</label>
													<div class="input-group">
														<div class="input-group-addon">
															<div class="input-group-text">
																<i class="fa fa-calendar"></i>
															</div>
														</div>
														<input type="text" name="startDate" id="startDate" class="form-control" [(ngModel)]="startDate" placeholder="Choose Start Date to filter" autocomplete="off">
													</div>
												</div>
											</div>
											<div class="col-lg-3 col-sm-3 col-md-3">
												<div class="form-group">
													<label for="startTime" class="control-label">
														Start Time:
													</label>
													<div class="input-group">
														<div class="input-group-addon">
															<div class="input-group-text">
																<i class="far fa-clock"></i>
															</div>
														</div>
														<input type="text" name="startTime" id="startTime" class="form-control" [(ngModel)]="startTime">
													</div>
												</div>
											</div>
											<div class="col-lg-3 col-sm-3 col-md-3">
												<div class="form-group">
													<label for="endDate" class="control-label">
														End Date:
													</label>
													<div class="input-group">
														<div class="input-group-addon">
															<div class="input-group-text">
																<i class="fa fa-calendar"></i>
															</div>
														</div>
														<input type="text" name="endDate" id="endDate" class="form-control" [(ngModel)]="endDate" placeholder="Choose End Date to filter" autocomplete="off">
													</div>
												</div>
											</div>
											<div class="col-lg-3 col-sm-3 col-md-3">
												<div class="form-group">
													<label for="endTime" class="control-label">
														End Time:
													</label>
													<div class="input-group">
														<div class="input-group-addon">
															<div class="input-group-text">
																<i class="far fa-clock"></i>
															</div>
														</div>
														<input type="text" name="endTime" id="endTime" class="form-control" [(ngModel)]="endTime">
													</div>
												</div>
											</div>
											<!-- Company filter display for 4dot5 company users -->
											<div class="col-lg-3 col-sm-3 col-md-3" *ngIf="isHost && (context === 'companySettings' || context === 'assessments')">
												<div class="form-group">
													<label for="company">Company</label>
													<select class="form-control access inputBoxBorder" [(ngModel)]="activityFilter.companyId"
															(change)="getActivities()" name="company" id="company">
														<option *ngFor="let company of companies; let i = index" value="{{company.companyId}}">{{company.companyName}}</option>
													</select>
												</div>
											</div>
											<div class="col-lg-3 col-sm-3 col-md-3" *ngIf="context !== 'candidate'">
												<div class="form-group">
													<label for="activityType">Activity Type</label>
													<select class="form-control access inputBoxBorder" [(ngModel)]="activityFilter.activityType"
															(change)="getActivities()" name="activityType" id="activityType">
														<option value="any">Any</option>
														<option *ngFor="let activityType of activityTypes; let i = index" value="{{activityType.value}}">{{activityType.name}}</option>
													</select>
												</div>
											</div>
											<div class="col-lg-3 col-sm-3 col-md-3" *ngIf="context != 'companySettings' && context != 'clientOrBuSettings' && context != 'assessments'">
												<div class="form-group">
													<label for="user">Activity Performed By:</label>
													<select class="form-control access inputBoxBorder" [(ngModel)]="activityFilter.createdBy"
															(change)="getActivities()" name="user" id="user">
														<option value="any">Any</option>
														<option *ngFor="let user of users; let i = index" value="{{user.id}}">{{user.name}}</option>
													</select>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="row pull-right" style="margin-right:10px">
									<div class="col-lg-12 col-md-12 col-sm-12">
										<div class="form-group" id="resetFiltersButton">
											<button type="button" class="btn btn-danger" (click)="resetCandidateFilters()">Reset</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--filter ends-->
        <div class="col-xs-12 text-center" *ngIf="context !== 'requisition' && isFiltersEnabled">
            <span class="badge badge-primary px-3 py-2 mb-3">Filtered Results</span>
        </div>
	</div>
	<div style="height: 400px; overflow-y: scroll">
		<div *ngIf="!loadingFlag && activities.length > 0">
            <div class="col-xs-12 entity-activities-container" *ngFor="let activity of activities; let i = index">
                <div class="col-xs-10 d-flex"  (click)="expandText($event, activity)" >
                    <div class="text-right entity-activities-icon-div">
						<i class="fa fa-pencil-alt" *ngIf="(activity.activityType === 'change' || activity.activityType === 'candidateChange' || activity.activityType === 'userType' || activity.activityType === 'edit' || activity.activityType === 'candidateScoreChanged')"></i>
						<i class="fa fa-plus" *ngIf="(activity.activityType === 'add' || activity.activityType === 'candidateAdd' || activity.activityType === 'assign' || activity.activityType == 'addCandidate' || activity.activityType == 'associateCandidate' || activity.activityType == 'pullCandidate' || activity.activityType === 'pull')"></i>
						<i class="fa fa-minus" *ngIf="(activity.activityType === 'remove' || activity.activityType === 'unAssign')"></i>
						<i class="far fa-clock" *ngIf="activity.activityType === 'reminder'"></i>
						<i class="far fa-calendar-alt" *ngIf="activity.activityType === 'meeting'"></i>
						<i class="fa fa-file" *ngIf="activity.activityType === 'documentActivity'"></i>
                    </div>
                    <div style="width:95%; -webkit-box-orient: vertical" [ngClass]="{'two-line-text-overflow-ellipsis': activity.showShortMessageFlag, 'cursor-pointer': activity.isExpandable, 'cursor-default': !activity.isExpandable}">
                        <strong [innerHtml]="activity.activityDisplayValue"></strong>
                        <span *ngIf="context === 'candidate'" [innerHtml]="activity.phoneScreenOrInterviewText"></span>
                        <span [innerHtml]="activity.mainText"></span>
						<span *ngIf="activity.isDocumentEmailActivity" class="fas fa-clipboard notes-icon ml-2 cursor-pointer" tooltip="Click to View Document Details" 
							tooltip-placement="auto top-left" container="body" (click)="goToCandidateDocuments(activity)">
						</span>
                        <!-- Reason and notes -->
                        <br>	
                        <span *ngIf="activity.reasonNote !== null && activity.reasonNote.length>0" style="display: block;">
                            <span class="emerald">Reason</span>:{{activity.reasonNote}}
                        </span>
                        <span *ngIf="context === 'requisition' || context === 'candidate' || context === 'candidateJobMatch'" style="display: block;">
                            <span *ngIf="activity.activityType == 'filled' || activity.CandidateToStateDisplayText == 'Filled'">
                                <span *ngIf="activity.requisitionFilledDate != null">
                                    <span class="emerald">
                                        Fill Date
                                    </span>:<span>{{activity.requisitionFilledDate | fourDotFiveDateFormatPipe}}</span>
                                </span>
                                <span *ngIf="activity.requisitionExpectedJoinDate != null">
                                    <span class="emerald">
                                        Expected Join Date
                                    </span>:<span>{{activity.requisitionExpectedJoinDate | fourDotFiveDateFormatPipe}}</span>
                                </span>
                            </span>
                            <span *ngIf="activity.activityType == 'joined' || activity.CandidateToStateDisplayText == 'Joined'">
                                <span *ngIf="activity.requisitionJoinedDate != null">
                                    <span class="emerald">
                                        Actual Join Date
                                    </span>:<span>{{activity.requisitionJoinedDate | fourDotFiveDateFormatPipe}}</span>
                                </span>
                            </span>
                        </span>
                        <span *ngIf="activity.note !== null && activity.note.length>0" style="display: block;">
                            <span class="emerald">
                                <span *ngIf="requisitionInfo && requisitionInfo.isSharedRequisition">Internal</span> 
                                Notes
                            </span>:{{activity.note}}
                        </span>
                        <span *ngIf="context !== 'requisition'&& activity.externalNote !== null && activity.externalNote.length>0" style="display: block;">
                            <span class="emerald">External Notes</span>:{{activity.externalNote}}
                        </span>
                        <span *ngIf="context == 'requisition' && (requisitionInfo && requisitionInfo.isSharedRequisition) && activity.externalNote !== null && activity.externalNote.length>0" style="display: block;">
                            <span class="emerald">External Notes</span>:{{activity.externalNote}}
                        </span>
                    </div>
                </div>
                <div class="col-xs-2 text-left">{{activity.displayDate | fourDotFiveTimeStampFormatPipe}}</div>
            </div>
        </div>
		<div *ngIf="!loadingFlag && activities.length == 0">
			<h2 class="text-center">No activities found</h2>
		</div>
		<div class="col-xs-12 loading-spinner text-center" *ngIf="loadingFlag">
			<span>Loading</span>
			<span class="fa fa-spinner fa-spin ml-2"></span>
		</div>
	</div>
</div>
import { DatePipe } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { JobMatchService } from 'src/app/core/services/job-match.service';
import { JobService } from 'src/app/core/services/job.service';
import { UserRoleService } from 'src/app/core/services/user-role.service';

@Component({
  selector: 'app-candidate-job-comparison',
  templateUrl: './candidate-job-comparison.component.html',
  styleUrls: ['./candidate-job-comparison.component.css'],
  providers: [DatePipe]
})
export class CandidateJobComparisonComponent implements OnInit {
  @Input() jobId;
  @Input() jobMatchId;
  @Input() candidateId;

  companyId: any;
  comparisonData: any;
  isSuperUserOr4dot5Admin: any;
  jobLocationForDisplay: any;
  educationMatches: any;
  educationNonMatches: any;
  educationAdditional: any;
  educationAlmost: any;
  experienceMatches: any;
  experienceNonMatches: any;
  experienceAdditional: any;
  experienceAlmost: any;
  techSkillsMatches: any;
  techSkillsNonMatches: any;
  techSkillsAdditional: any;
  techSkillsAlmost: any;
  operationalSkillsMatches: any;
  operationalSkillsNonMatches: any;
  operationalSkillsAdditional: any;
  operationalSkillsAlmost: any;
  softSkillsMatches: any;
  softSkillsNonMatches: any;
  softSkillsAdditional: any;
  softSkillsAlmost: any;
  otherSkillsMatches: any;
  otherSkillsNonMatches: any;
  otherSkillsAdditional: any;
  otherSkillsAlmost: any;
  certificationMatches: any;
  certificationNonMatches: any;
  certificationAdditional: any;
  certificationAlmost: any;
  compensationMatches: any;
  compensationNonMatches: any;
  compensationAdditional: any;
  compensationAlmost: any;
  totalJobExperience: any;

  loadingFlag: boolean = true;
  compareByJobMatchId: boolean = true;

  constructor(
    private authService: AuthService,
    private userRoleService: UserRoleService,
    private jobMatchService: JobMatchService,
    private jobService: JobService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.companyId = this.authService.getUserDetails().company.companyId;
    this.comparisonData = [];
    this.loadingFlag = true;
    this.compareByJobMatchId = true;
    if (!this.jobMatchId) {
      this.compareByJobMatchId = false;
    }

    this.isSuperUserOr4dot5Admin = this.userRoleService.isLoggedInUserSuperUserOr4dot5Admin();

    this.getCandidateJobComparison();
  }

  _getJobProfile(){
    this.jobService.getJobProfile(this.jobId, (data) => {
      if(data.experiences && data.experiences.length > 0 && data.experiences[0].duration) {
        this.totalJobExperience = data.experiences[0].duration; 
      }
    }, (error) => {
        // do nothing.
    });
}

  getCandidateJobComparison() {
    this.loadingFlag = true;
    if (this.compareByJobMatchId) {
      this.jobMatchService.getCandidateJobComparison(this.jobMatchId, (data) => {
        this.comparisonData = data;
        this._massageComparisonData();
        if(!this.experienceMatches.length && !this.experienceNonMatches.length && !this.experienceAlmost.length && !this.experienceAdditional.length) {
          this._getJobProfile();
        }
        this.loadingFlag = false;
      }, (error) => {
        console.log('candidate job match get error - ', error);
        // do nothing.
      });
    } else {
      this.jobService.getCandidateJobComparison(this.candidateId, this.jobId, this.companyId, (data) => {
        this.comparisonData = data;
        this._massageComparisonData();
        if(!this.experienceMatches.length && !this.experienceNonMatches.length && !this.experienceAlmost.length && !this.experienceAdditional.length) {
          this._getJobProfile();
        }
        this.loadingFlag = false;
      }, (error) => {
        console.log('candidate job match get error - ', error);
        // do nothing.
      });
    }

  }

  _setDateRange(experience) {
    experience.dateRange = "(";
    if(experience.startDate) {
        experience.dateRange = `${experience.dateRange}${this.datePipe.transform(experience.startDate, 'MMM, yyyy')} - `;
    }
    if(experience.endDate) {
        experience.dateRange = `${experience.dateRange}${this.datePipe.transform(experience.endDate, 'MMM, yyyy')}`;
    } else {
        experience.dateRange = `${experience.dateRange}Current`;
    }
    experience.dateRange = `${experience.dateRange})`;
    if(!experience.startDate && !experience.endDate) {
        experience.dateRange = "";
    }
}

  _massageComparisonData() {
    this.jobLocationForDisplay = '';
    if (!_.isNull(this.comparisonData.jobLocation)) {
      if (!_.isNull(this.comparisonData.jobLocation.municipality)) {
        this.jobLocationForDisplay += this.comparisonData.jobLocation.municipality;
      }
      if (!_.isNull(this.comparisonData.jobLocation.city)) {
        this.jobLocationForDisplay += (this.jobLocationForDisplay === '' ? this.comparisonData.jobLocation.city.name : (', ' + this.comparisonData.jobLocation.city.name));
      }
      if (!_.isNull(this.comparisonData.jobLocation.region)) {
        this.jobLocationForDisplay += (this.jobLocationForDisplay === '' ? this.comparisonData.jobLocation.region : (', ' + this.comparisonData.jobLocation.region));
      }
      if (!_.isNull(this.comparisonData.jobLocation.state)) {
        this.jobLocationForDisplay += (this.jobLocationForDisplay === '' ? this.comparisonData.jobLocation.state : (', ' + this.comparisonData.jobLocation.state));
      }
    }

    this.comparisonData.educationComparisons.forEach((comparison, key) => {
      if (comparison.candidateInstituteName === null || comparison.candidateInstituteName === "") {
        comparison.candidateInstituteName = "'Unknown'";
      }
    })

    this.comparisonData.experienceComparisons.forEach((comparison, key) => {
      if (comparison.company === null || comparison.company === "") {
        comparison.company = "'Unknown'";
      }
      this._setDateRange(comparison);
    });

    this.educationMatches = _.filter(this.comparisonData.educationComparisons, (education: any) => {
      return education.status == "MATCH";
    });
    this.educationNonMatches = _.filter(this.comparisonData.educationComparisons, (education: any) => {
      return education.status == "NOT_MATCH";
    });
    this.educationAdditional = _.filter(this.comparisonData.educationComparisons, (education: any) => {
      return education.status == "ADDITIONAL";
    });
    this.educationAlmost = _.filter(this.comparisonData.educationComparisons, (education: any) => {
      return education.status == "ALMOST";
    });

    this.experienceMatches = _.filter(this.comparisonData.experienceComparisons, (experience: any) => {
      return experience.status == 'MATCH'
    });
    this.experienceNonMatches = _.filter(this.comparisonData.experienceComparisons, (experience: any) => {
      return experience.status == 'NOT_MATCH'
    })
    this.experienceAdditional = _.filter(this.comparisonData.experienceComparisons, (experience: any) => {
      return experience.status == 'ADDITIONAL'
    });
    this.experienceAlmost = _.filter(this.comparisonData.experienceComparisons, (experience: any) => {
      return experience.status == 'ALMOST'
    });

    this.techSkillsMatches = _.filter(this.comparisonData.technicalSkillComparisons, (skill: any) => {
      return skill.status == 'MATCH';
    });
    this.techSkillsNonMatches = _.filter(this.comparisonData.technicalSkillComparisons, (skill: any) => {
      return skill.status == 'NOT_MATCH';
    });
    this.techSkillsAdditional = _.filter(this.comparisonData.technicalSkillComparisons, (skill: any) => {
      return skill.status == 'ADDITIONAL';
    });
    this.techSkillsAlmost = _.filter(this.comparisonData.technicalSkillComparisons, (skill: any) => {
      return skill.status == 'ALMOST';
    });

    this.operationalSkillsMatches = _.filter(this.comparisonData.operationalSkillComparisons, (skill: any) => {
      return skill.status == 'MATCH';
    });
    this.operationalSkillsNonMatches = _.filter(this.comparisonData.operationalSkillComparisons, (skill: any) => {
      return skill.status == 'NOT_MATCH';
    });
    this.operationalSkillsAdditional = _.filter(this.comparisonData.operationalSkillComparisons, (skill: any) => {
      return skill.status == 'ADDITIONAL';
    });
    this.operationalSkillsAlmost = _.filter(this.comparisonData.operationalSkillComparisons, (skill: any) => {
      return skill.status == 'ALMOST';
    });

    this.softSkillsMatches = _.filter(this.comparisonData.softSkillComparisons, (skill: any) => {
      return skill.status == 'MATCH';
    });
    this.softSkillsNonMatches = _.filter(this.comparisonData.softSkillComparisons, (skill: any) => {
      return skill.status == 'NOT_MATCH';
    });
    this.softSkillsAdditional = _.filter(this.comparisonData.softSkillComparisons, (skill: any) => {
      return skill.status == 'ADDITIONAL';
    });
    this.softSkillsAlmost = _.filter(this.comparisonData.softSkillComparisons, (skill: any) => {
      return skill.status == 'ALMOST';
    });

    this.otherSkillsMatches = _.filter(this.comparisonData.otherSkillComparisons, (skill: any) => {
      return skill.status == 'MATCH';
    });
    this.otherSkillsNonMatches = _.filter(this.comparisonData.otherSkillComparisons, (skill: any) => {
      return skill.status == 'NOT_MATCH';
    });
    this.otherSkillsAdditional = _.filter(this.comparisonData.otherSkillComparisons, (skill: any) => {
      return skill.status == 'ADDITIONAL';
    });
    this.otherSkillsAlmost = _.filter(this.comparisonData.otherSkillComparisons, (skill: any) => {
      return skill.status == 'ALMOST';
    });

    this.certificationMatches = _.filter(this.comparisonData.certificationComparisons, (certification: any) => {
      return certification.status == 'MATCH';
    });
    this.certificationNonMatches = _.filter(this.comparisonData.certificationComparisons, (certification: any) => {
      return certification.status == 'NOT_MATCH';
    });
    this.certificationAdditional = _.filter(this.comparisonData.certificationComparisons, (certification: any) => {
      return certification.status == 'ADDITIONAL';
    });
    this.certificationAlmost = _.filter(this.comparisonData.certificationComparisons, (certification: any) => {
      return certification.status == 'ALMOST';
    });

    this.compensationMatches = _.filter(this.comparisonData.compensationComparisons, (compensation: any) => {
      return compensation.status == 'MATCH';
    });
    this.compensationNonMatches = _.filter(this.comparisonData.compensationComparisons, (compensation: any) => {
      return compensation.status == 'NOT_MATCH';
    });
    this.compensationAdditional = _.filter(this.comparisonData.compensationComparisons, (compensation: any) => {
      return compensation.status == 'ADDITIONAL';
    });
    this.compensationAlmost = _.filter(this.comparisonData.compensationComparisons, (compensation: any) => {
      return compensation.status == 'ALMOST';
    });

    // Convert Object to Arrays
    this.comparisonData.categoryScoreDTO.skillScoreDTO.individualRequiredSkillScoreInfoList = this.comparisonData.categoryScoreDTO.skillScoreDTO.individualRequiredSkillScoreInfo ? Object.entries(this.comparisonData.categoryScoreDTO.skillScoreDTO.individualRequiredSkillScoreInfo).map(([name, value]) => ({ name, value })) : [];
    this.comparisonData.categoryScoreDTO.skillScoreDTO.individualNonRequiredSkillScoreInfoList = this.comparisonData.categoryScoreDTO.skillScoreDTO.individualNonRequiredSkillScoreInfo ? Object.entries(this.comparisonData.categoryScoreDTO.skillScoreDTO.individualNonRequiredSkillScoreInfo).map(([name, value]) => ({ name, value })) : [];
  }

}

import { Component, OnInit, ViewChild, Output, EventEmitter, Input, TemplateRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable, of, EMPTY, Subject } from 'rxjs';
import { ModalConfig } from 'src/app/core/models/modalConfig';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AngularModalService } from 'src/app/core/services/modal.service';
import { TagInputComponent } from 'ngx-chips';
import { NewSkill } from 'src/app/core/models/newSkill';
import { ManageSkillsService } from 'src/app/core/services/manage-skills.service';

@Component({
  selector: 'app-add-edit-manage-skills-skill',
  templateUrl: './add-edit-manage-skills-skill.component.html',
  styleUrls: ['./add-edit-manage-skills-skill.component.css']
})
export class AddEditManageSkillsSkillComponent implements OnInit {
  @ViewChild('addEditNewSkillForm', null) addEditNewSkillForm: NgForm;
  @ViewChild('addParentSkillTemplate', null) addParentSkillTemplateRef: TemplateRef<any>;
  @ViewChild('addParentSkillForm', null) addParentSkillForm: NgForm;
  @ViewChild('addAlternateSkillForm', null) addAlternateSkillForm: NgForm;
  @ViewChild('alternateInput', { static: false }) alternateInput: TagInputComponent;
  @ViewChild('parentInput', { static: false }) parentInput: TagInputComponent;
  @ViewChild('parentAlternateInput', { static: false }) parentAlternateInputRef: TagInputComponent;
  @ViewChild('newAlternateInput', { static: false }) newAlternateInput: TagInputComponent;

  addParentSkillModal: BsModalRef;
  addNewAlternateSkillModal: BsModalRef;
  public onClose: Subject<any>;

  @Input() manageSkillType;
  @Input() editSkillObject;
  @Input() allSkills;
  @Input() skillCategories;
  @Output() saveCallback = new EventEmitter<any>();
  @Output() cancelCallback = new EventEmitter<any>();

  addEditNewSkillObject: any = {};
  skillCategoryList: any;
  selectedRelatedSkill: string;
  parentSkills: any = [];
  allAlternateSkillsTags: any = [];
  allParentSkillsTags: any = [];
  newParentSkillList: any = {};
  newAlternateSkillList: any = {};
  isDisabled: boolean = false;
  typeOfAlternateSkillModal: 'alternateSkillModal';

  constructor(
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private modalService: BsModalService,
    private angularModalService: AngularModalService,
    private skillsService: ManageSkillsService
  ) { }

  ngOnInit() {
    // Check if add or edit skill
    if (!this.editSkillObject) {
      this.addEditNewSkillObject = this._setNewSkill(new NewSkill());
    } else {
      if (this.manageSkillType == 'editSkill' || this.manageSkillType == 'moveSkill') {
        this.addEditNewSkillObject = _.cloneDeep(this.editSkillObject);
        this.addEditNewSkillObject.category = this.addEditNewSkillObject.category.toUpperCase();
        if (this.manageSkillType == 'editSkill' && this.editSkillObject.alternate) {
          // Set alternate skill of for alternate skill
          this.addEditNewSkillObject.alternateSkills = this.editSkillObject.primarySkill ? [this.editSkillObject.primarySkill] : [];
        }
      } else {
        this.addEditNewSkillObject = this._setNewSkill(this.editSkillObject);
        this.addEditNewSkillObject.id = null;
        this.addEditNewSkillObject.skill = '';
        this.addEditNewSkillObject.category = '';
        this.addEditNewSkillObject.alternateSkills = [];
        this.addEditNewSkillObject.parentSkills = [this._setNewSkill(this.editSkillObject)];
      }
    }

    if (this.manageSkillType == 'moveSkill') {
      this.isDisabled = true;
    }

    this.newParentSkillList = this._setNewSkill(new NewSkill());
    this.newAlternateSkillList = this._setNewSkill(new NewSkill());
    this.getAndSetSkillCategories();
    this._setAllAlternateAndParentSkills();
  }

  getAndSetSkillCategories() {
    if(!this.skillCategories) {
      this.skillsService.getAllCategories(data => {
        this.skillCategoryList = data;
      }, error => {
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      });
    } else {
      this.skillCategoryList = this.skillCategories;
    }
  }

  _setNewSkill(skill) {
    return JSON.parse(JSON.stringify(skill));
  }

  _setAllAlternateAndParentSkills() {
    if (this.allSkills.length > 0) {
      this.allSkills.map(skill => {
        let tempSkill = { ...skill };
          tempSkill.skill = tempSkill.skill;
          tempSkill.display = tempSkill.skill;
          tempSkill.value = tempSkill.skill;
          delete tempSkill.skillHierarchy;
        if(!skill.alternate) {
          this.allParentSkillsTags.push(tempSkill);
          this.allParentSkillsTags = this.allParentSkillsTags.filter(skill => (skill.skill != this.addEditNewSkillObject.skill));
          if(this.addEditNewSkillObject.childSkillIds) {
            this.allParentSkillsTags = this.allParentSkillsTags.filter(skill => !this.addEditNewSkillObject.childSkillIds.includes(skill.id));
          }
          if (!skill.parent) {
            if (this.manageSkillType !== 'editSkill' || (this.addEditNewSkillObject.id && this.addEditNewSkillObject.id !== skill.id)) {
              tempSkill.isAlternate = true;
              this.allAlternateSkillsTags.push(tempSkill);
            }
          }
        }
      });
    }
    this.allParentSkillsTags.push({ id: 'addTag', name: 'Add Parent Skill', display: 'Add Parent Skill', value: 'ADD_TAG' });
  }

  _setNewSkillTag(id, skill, type) {
    let newTag: any = this._setNewSkill(new NewSkill());
    newTag.id = id;
    newTag.skill = skill.skill;
    newTag.display = skill.skill;
    newTag.category = skill.category;
    if(type == 'alternateSkill') {
      newTag.parentSkills = skill.parentSkills
      newTag.alternateSkills = null
    } else {
      newTag.parentSkills = null;
      newTag.alternateSkills = skill.alternateSkills
    }

    return newTag;
  }

  onParentSkillTextChange(event) {
    this.allParentSkillsTags.map(parentSkill => {
      if (parentSkill.id == 'addTag') {
        parentSkill.skill = event;
        parentSkill.display = event;
      }
    });
    let duplicateSkill = this.checkIfParentSkillDuplicate({skill: event, skillTextChange: true});
    this.addEditNewSkillObject.isParentSkillDuplicate = duplicateSkill.isSkillDuplicate;
    this.addEditNewSkillObject.duplicateParentSkillMessage = "*" + duplicateSkill.duplicateSkillMessage.split('.')[0] + "*";
  }

  onAlternateSkillTextChange(event, type) {
    let duplicateSkill = this.checkIfAlternateSkillDuplicate({skill: event, skillTextChange: true});
    if (type == 'alternateSkillModal') {
      this.addEditNewSkillObject.isAlternateSkillDuplicate = duplicateSkill.isSkillDuplicate;
      this.addEditNewSkillObject.duplicateSkillMessage = "*" + duplicateSkill.duplicateSkillMessage.split('.')[0] + "*";
    } else {
      this.newParentSkillList.isAlternateSkillDuplicate = duplicateSkill.isSkillDuplicate;
      this.newParentSkillList.duplicateSkillMessage = "*" + duplicateSkill.duplicateSkillMessage.split('.')[0] + "*";
    }
  }

  checkIfParentSkillDuplicate(newSkill) {
    let duplicateSkill = { skill: {}, isSkillDuplicate: false, duplicateSkillMessage: '' };

    if (newSkill && newSkill.skill != '') {
      // Check if matches Parent's Alternate Skill with Primary skill name
      if (!duplicateSkill.isSkillDuplicate) {
        duplicateSkill = this.checkSkillDuplicateInNewlyAddedAlternate('add', duplicateSkill, 'parent-primary', newSkill);
      }

      if (!duplicateSkill.isSkillDuplicate) {
        // Check if matches Parent skill with Parent's Alternate Skill name
        duplicateSkill = this.checkSkillDuplicateInNewlyAddedParentAlternate('add', duplicateSkill, 'parent', newSkill);
      }

      if (!duplicateSkill.isSkillDuplicate) {
        // Check if matches Parent and Primary skill name
        if (this.checkIfSkillNameDuplicate(this.addEditNewSkillObject, newSkill)) {
          this.setDuplicateSkill(this.addEditNewSkillObject, duplicateSkill, 'parent-primary');
        }
      }

      if (!duplicateSkill.isSkillDuplicate && this.allSkills && this.allSkills.length > 0) {
        this.allSkills.forEach(skill => {
          // check only parent and alternate skill, primary skill can be alternate skills
          if (newSkill.skillTextChange) {
            if (skill.alternate && this.checkIfSkillNameDuplicate(skill, newSkill)) {
              this.setDuplicateSkill(skill, duplicateSkill, 'parent');
              return false;
            }
          } else {
            if (this.checkIfSkillNameDuplicate(skill, newSkill)) {
              this.setDuplicateSkill(skill, duplicateSkill, 'parent');
              return false;
            }
          }
        });
      }
    }

    return duplicateSkill;
  }

  checkIfAlternateSkillDuplicate(newSkill) {
    let duplicateSkill = { skill: {}, isSkillDuplicate: false, duplicateSkillMessage: '' };
    if (newSkill && newSkill.skill != '') {

      if (!duplicateSkill.isSkillDuplicate) {
        // Check if matches Alternate skill with Parent Skill name
        duplicateSkill = this.checkSkillDuplicateInNewlyAddedParent('add', duplicateSkill, 'alternate', newSkill);
      }

      if (!duplicateSkill.isSkillDuplicate) {
        // Check if matches Alternate skill matches with Parent's Alternate Skill name
        duplicateSkill = this.checkSkillDuplicateInNewlyAddedParentAlternate('add', duplicateSkill, 'alternate', newSkill);
      }

      if (!duplicateSkill.isSkillDuplicate) {
        // Check if matches Alternate Skill matches with Primary skill name
        duplicateSkill = this.checkSkillDuplicateInNewlyAddedAlternate('add', duplicateSkill, 'alternate-primary', newSkill);
      }

      if (!duplicateSkill.isSkillDuplicate) {
        // Check if matches Alternate skill matches with Parent Skill name in modal
        if (this.checkIfSkillNameDuplicate(this.newParentSkillList, newSkill)) {
          this.setDuplicateSkill(this.newParentSkillList, duplicateSkill, 'alternate-parent');
        }
      }

      if (!duplicateSkill.isSkillDuplicate) {
        // Check if matches Alternate skill matches with Primary Skill name in modal
        if (this.checkIfSkillNameDuplicate(this.addEditNewSkillObject, newSkill)) {
          this.setDuplicateSkill(this.addEditNewSkillObject, duplicateSkill, 'alternate-primary');
        }
      }

      if (!duplicateSkill.isSkillDuplicate && this.allSkills && this.allSkills.length > 0) {
        this.allSkills.forEach(skill => {
          // check only parent and alternate skill, primary skill can be alternate skills
          if (newSkill.skillTextChange) {
            if ((skill.parent || skill.alternate) && this.checkIfSkillNameDuplicate(skill, newSkill)) {
              this.setDuplicateSkill(skill, duplicateSkill, 'alternate');
              return false;
            }
          } else {
            if (this.checkIfSkillNameDuplicate(skill, newSkill)) {
              this.setDuplicateSkill(skill, duplicateSkill, 'alternate');
              return false;
            }
          }
        });
      }
    }

    return duplicateSkill;
  }

  checkIfSkillNameDuplicate(skill, skillObj) {
    return (skill.skill != '' && skill.skill.toLowerCase()) === (skillObj.skill != '' && skillObj.skill.toLowerCase());
  }

  checkDuplicateInAllSkills(state, duplicateSkill, type) {
    if (this.allSkills && this.allSkills.length > 0) {
      this.allSkills.forEach(skill => {
        if (state == 'add' && this.checkIfSkillNameDuplicate(skill, this.addEditNewSkillObject)) {
          this.setDuplicateSkill(skill, duplicateSkill, type);
          return false;
        } else {
          if (state == 'edit' && (this.editSkillObject.skill.toLowerCase() != this.addEditNewSkillObject.skill.toLowerCase())) {
            if (this.checkIfSkillNameDuplicate(skill, this.addEditNewSkillObject)) {
              this.setDuplicateSkill(skill, duplicateSkill, type);
              return false;
            }
          }
        }
      });
    }
    return duplicateSkill;
  }

  checkSkillDuplicateInNewlyAddedParentAlternate(state, duplicateSkill, type, newSkill) {
    if (this.newParentSkillList.alternateSkills && this.newParentSkillList.alternateSkills.length > 0) {
      this.newParentSkillList.alternateSkills.forEach(alternateSkill => {
        if (this.checkIfSkillNameDuplicate(alternateSkill, newSkill)) {
          this.setDuplicateSkill(alternateSkill, duplicateSkill, `${type}-alternate`);
          return false;
        }
      })
    }

    return duplicateSkill;
  }

  checkSkillDuplicateInNewlyAddedAlternate(state, duplicateSkill, type, newSkill) {
    if (this.addEditNewSkillObject.alternateSkills && this.addEditNewSkillObject.alternateSkills.length > 0) {
      this.addEditNewSkillObject.alternateSkills.forEach(alternateSkill => {
        if (this.checkIfSkillNameDuplicate(alternateSkill, newSkill)) {
          this.setDuplicateSkill(alternateSkill, duplicateSkill, `${type}-alternate`);
          return false;
        }
      })
    }

    return duplicateSkill;
  }

  checkSkillDuplicateInNewlyAddedParent(state, duplicateSkill, type, newSkill) {
    if (this.addEditNewSkillObject.parentSkills && this.addEditNewSkillObject.parentSkills.length > 0) {
      this.addEditNewSkillObject.parentSkills.forEach(parentSkill => {
        if (this.checkIfSkillNameDuplicate(parentSkill, newSkill)) {
          this.setDuplicateSkill(parentSkill, duplicateSkill, `${type}-parent`);
          return false;
        } else if (parentSkill.alternateSkills && parentSkill.alternateSkills.length > 0) {
          parentSkill.alternateSkills.forEach(alternateSkill => {
            if (this.checkIfSkillNameDuplicate(alternateSkill, newSkill)) {
              this.setDuplicateSkill(alternateSkill, duplicateSkill, `${type}-parent-alternate`);
              return false;
            }
          })
        }
      });
    }

    return duplicateSkill;
  }

  checkIfSkillDuplicate(state, type) {
    let duplicateSkill = { skill: {}, isSkillDuplicate: false, duplicateSkillMessage: '' };
    duplicateSkill = this.checkDuplicateInAllSkills(state, duplicateSkill, type);
    if (!duplicateSkill.isSkillDuplicate) {
      duplicateSkill = this.checkSkillDuplicateInNewlyAddedParent(state, duplicateSkill, type, this.addEditNewSkillObject);
    }

    if (!duplicateSkill.isSkillDuplicate) {
      duplicateSkill = this.checkSkillDuplicateInNewlyAddedAlternate(state, duplicateSkill, type, this.addEditNewSkillObject);
    }

    return duplicateSkill;
  }

  setDuplicateSkill(skill, duplicateSkill, type) {
    duplicateSkill.isSkillDuplicate = true;
    duplicateSkill.skill = skill;
    duplicateSkill.duplicateSkillMessage = this.setDuplicateSkillMessage(duplicateSkill.skill, type);
  }

  setDuplicateSkillMessage(skill, type) {
    if (skill && skill.id && (type === 'parent' || type === 'primary' || type === 'alternate')) {
      if (skill.alternate) {
        return "<b>Alternate skill</b> with this name already exists.";
      } else if (skill.parent) {
        return "<b>Parent skill</b> with this name already exists.";
      } else {
        return "<b>Primary skill</b> with this name already exists.";
      }
    }

    const messages = {
      'primary-parent': "<b>Primary skill</b> and <b>Parent skill</b> cannot have same name.",
      'primary-parent-alternate': "<b>Primary skill</b> and Parent skill's <b>Alternate skill</b> cannot have same name.",
      'primary-alternate': "<b>Primary skill</b> and <b>Alternate skill</b> cannot have same name.",
      'parent-primary-alternate': "<b>Parent skill</b> and Primary skill's <b>Alternate skill</b> cannot have same name.",
      'parent-primary': "<b>Parent skill</b> and <b>Primary skill</b> cannot have same name.",
      'parent-alternate': "<b>Parent skill</b> and <b>Alternate skill</b> cannot have same name.",
      'alternate-parent': "<b>Alternate skill</b> and <b>Parent skill</b> cannot have same name.",
      'alternate-parent-alternate': "<b>Alternate skill</b> and parent skill's <b>Alternate skill</b> cannot have same name.",
      'alternate-primary': "<b>Alternate skill</b> and <b>Primary skill</b> cannot have same name.",
      'alternate-primary-alternate': "<b>Alternate skill</b> and primary skill's <b>Alternate skill</b> cannot have same name.",
      'alternate-alternate': "<b>Alternate skill</b> and parent skill's <b>Alternate skill</b> cannot have same name."
    };

    return messages[type] || "";
  }

  addEditSkillDetails(state) {
    if (this.addEditNewSkillForm.valid) {
      let duplicateSkill = this.checkIfSkillDuplicate(state, 'primary');
      if (!duplicateSkill.isSkillDuplicate) {
        if (this.saveCallback) {
          this.addEditNewSkillObject.skillState = this.manageSkillType;
          // Reset alternate skills to null for alternate skill
          if(this.addEditNewSkillObject.alternate) {
            this.addEditNewSkillObject.alternateSkills = [];
          }
          if(this.manageSkillType == 'editSkill') {
            this.addEditNewSkillObject.removeAlternateSkills = this._checkAndSetRemovedAlternateSkills(this.addEditNewSkillObject);
          }
          this.saveCallback.emit(this.addEditNewSkillObject);
        }
        this.cancel();
      } else {
        this.alertsAndNotificationsService.showAlert("Duplicate", duplicateSkill.duplicateSkillMessage, "warning");
      }
    }
  }

  cancel() {
    this.cancelCallback.emit();
  }

  _removeParentRootSkill(skill) {
    if(skill.parentSkills && skill.parentSkills.length > 0 && skill.parentSkills.some(skill => skill.isRootSkill)) {
      skill.parentSkills = [];
    }
}

  _checkDuplicateSkillOnAddingFromAutoComplete(skillObject, skillName, type, tag) {
    let duplicateSkill = false;
    const tempAllSkills = [...(skillObject.parentSkills ? skillObject.parentSkills : []), ...(skillObject.alternateSkills ? skillObject.alternateSkills : [])];
    duplicateSkill =  tempAllSkills.some(skill => skill.skill === skillName);

    if (duplicateSkill) {
      this.alertsAndNotificationsService.showAlert("Duplicate", "Skill already exists.", "warning");
      return EMPTY;
    } else {
      this._removeParentRootSkill(tag);
      return of(tag);
    }
  }

  onAddingAlternateSkill = (tag): Observable<string> => {
    if (tag != "" && tag.id && tag.id != 'addTag') { // Open Model if Clicked on 'ADD TAG'
      return this._checkDuplicateSkillOnAddingFromAutoComplete(this.addEditNewSkillObject, tag.skill, 'alternateSkill', tag);
    } else {
      return EMPTY;
    }
  }

  onAddingAlternateSkillFromParentModal = (tag): Observable<string> => {
    if (tag != "" && tag.id && tag.id != 'addTag') { // Open Model if Clicked on 'ADD TAG'
      return this._checkDuplicateSkillOnAddingFromAutoComplete(this.newParentSkillList, tag.skill, 'alternateSkill', tag);
    } else {
      return EMPTY;
    }
  }

  onAddingParentSkillFromAlternateModal = (tag): Observable<string> => {
    if (tag != "" && tag.id && tag.id != 'addTag') { // Open Model if Clicked on 'ADD TAG'
      return this._checkDuplicateSkillOnAddingFromAutoComplete(this.newAlternateSkillList, tag.skill, 'parentSkill', tag);
    } else {
      return EMPTY;
    }
  }

  onAddingParentSkill = (tag): Observable<string> => {
    if (tag != "" && tag.id && tag.id != 'addTag') { // Open Model if Clicked on 'ADD TAG'
      return this._checkDuplicateSkillOnAddingFromAutoComplete(this.addEditNewSkillObject, tag.skill, 'parentSkill', tag);
    } else {
      return EMPTY;
    }
  }

  onAlternateSkillAdded(tag) {
    this.allAlternateSkillsTags = this.allAlternateSkillsTags.filter(skill => (skill.id != tag.id || skill.skill != tag.skill));
    this.allParentSkillsTags = this.allParentSkillsTags.filter(skill => (skill.id != tag.id || skill.skill != tag.skill));
  }

  onAlternateSkillRemoved(tag) {
    if (tag.id) {
      tag.display = tag.skill;
      this.allAlternateSkillsTags.unshift(tag);
      this.allAlternateSkillsTags = [...this.allAlternateSkillsTags];
      this.allParentSkillsTags.unshift(tag);
    } else {
      this.allAlternateSkillsTags = this.allAlternateSkillsTags.filter(skill => (skill.id && skill.skill != tag.skill));
    }
  }

  onParentSkillAdded(tag) {
    this.allParentSkillsTags = this.allParentSkillsTags.filter(skill => (skill.id != tag.id || skill.skill != tag.skill));
    this.allAlternateSkillsTags = this.allAlternateSkillsTags.filter(skill => (skill.id != tag.id || skill.skill != tag.skill));
  }

  onParentSkillRemoved(tag) {
    if (tag.id) {
      tag.display = tag.skill;
      this.allParentSkillsTags.unshift(tag);
      if (!tag.parent) {
        this.allAlternateSkillsTags.unshift(tag);
        this.allAlternateSkillsTags = [...this.allAlternateSkillsTags];
      }
    } else {
      this.allParentSkillsTags = this.allParentSkillsTags.filter(skill => skill.id);
    }
  }

  _setParentOrAlternateSkillName(type) {
    if(type == 'parentSkill') {
      let newAddingParentSkillTag = this.allParentSkillsTags.filter(parentSkill => parentSkill.id == 'addTag');
      return newAddingParentSkillTag[0].skill;
    } else {
      let newAddingAlternateSkillTag = this.allAlternateSkillsTags.filter(alternateSkill => alternateSkill.id == 'addTag');
      return newAddingAlternateSkillTag[0].skill;
    }
  }

  openParentOrAlternateSkillModal(template: TemplateRef<any>, type) {
    const config = new ModalConfig();
    config.backdrop = true;
    config.ignoreBackdropClick = true;
    config.keyboard = false;
    config.class = "modal-lg custom-ngx-modal";
    const modalData: any = {};
    config.initialState = modalData;
    this.typeOfAlternateSkillModal = type;
    if (type == 'parentSkillModal') {
      this.newParentSkillList.skill = this._setParentOrAlternateSkillName('parentSkill');
      this.newParentSkillList.category = this.addEditNewSkillObject.category.slice();
      this.addParentSkillModal = this.modalService.show(template, config);
    }
  }

  closeModal(type) {
    if (type == 'parentSkill') {
      this.addParentSkillModal.hide();
    } else {
      this.addNewAlternateSkillModal.hide();
    }
  }

  _addParentSkill(newParentSkillList) {
    let addedSkill: any = this.allParentSkillsTags.filter(skill => (skill.id != 'addTag' && skill.skill.toLowerCase()) === newParentSkillList.skill.toLowerCase());
    let newTag: any
    if (addedSkill && addedSkill.length > 0) {
      newTag = addedSkill[0];
    } else {
      newTag = this._setNewSkillTag(null, newParentSkillList, 'parentSkill');
      let allParentSkillsTags = this.allParentSkillsTags;
      allParentSkillsTags.unshift(newTag);
      this.allParentSkillsTags = [...allParentSkillsTags];
    }

    if (this.addEditNewSkillObject.parentSkills) {
      this.addEditNewSkillObject.parentSkills.push(newTag);
    } else {
      this.addEditNewSkillObject.parentSkills = [newTag];
    }
    this.onParentSkillAdded(newTag);
  }

  addParentSkill(newParentSkillList) {
    if ((this.addParentSkillForm && this.addParentSkillForm.valid) || this._checkIfSkillNameExists(newParentSkillList)) {
      let duplicateSkill = this.checkIfParentSkillDuplicate(newParentSkillList);
      if (!duplicateSkill.isSkillDuplicate) {
        // if (!this._checkIfParentOrAlternateSkillDuplicate(this.addEditNewSkillObject, newParentSkillList.skill, 'parentSkill') && this._checkIfSkillNameAndAlternateOrParentSame(this.addEditNewSkillObject, newParentSkillList.skill)) {
        this._addParentSkill(newParentSkillList);
        this.closeModal('parentSkill');
        // Reset Parent Skills
        this.newParentSkillList = this._setNewSkill(new NewSkill());
        this.parentInput.setInputValue('');
      } else {
        this.showWarningModal('duplicate', duplicateSkill.duplicateSkillMessage);
      }
    }
  }

  _addAlternateSkill(alternateSkill, type) {
    let addedSkill: any = this.allAlternateSkillsTags.filter(skill => skill.skill.toLowerCase() === alternateSkill.skill.toLowerCase());
    let newTag: any
    if (addedSkill && addedSkill.length > 0) {
      newTag = addedSkill[0];
    } else {
      newTag = this._setNewSkillTag(null, alternateSkill, 'alternateSkill');
      newTag.isAlternate = true;
      newTag.isAddedSkill = true;
      this.allAlternateSkillsTags.unshift(newTag);
    }
    if (type && type === 'alternateInParentSkillModal') {
      if (this.newParentSkillList.alternateSkills) {
        this.newParentSkillList.alternateSkills.push(newTag);
      } else {
        this.newParentSkillList.alternateSkills = [newTag];
      }
    } else {
      if (this.addEditNewSkillObject.alternateSkills) {
        this.addEditNewSkillObject.alternateSkills.push(newTag);
      } else {
        this.addEditNewSkillObject.alternateSkills = [newTag];
      }
    }
    this.onAlternateSkillAdded(newTag);
  }

  addAlternateSkill(alternateSkill) {
    if ((this.addAlternateSkillForm && this.addAlternateSkillForm.valid) || this._checkIfSkillNameExists(alternateSkill)) {
      this._addAlternateSkill(alternateSkill, null);
      this.closeModal('alternateSkill');
      // Reset alternate skills
      this.newAlternateSkillList = this._setNewSkill(new NewSkill());
      this.alternateInput.setInputValue('');
    }
  }

  _checkIfParentSkillExistsInAlternateSkillList() {
    return (this.allParentSkillsTags && this.allParentSkillsTags.length > 0 && this.allParentSkillsTags[0].id != 'addTag');
  }

  _checkIfCategorySelected(skill) {
    return (skill.category && skill.category != '');
  }

  _checkIfSkillNameExists(skill) {
    return (skill.skill && skill.skill != '');
  }

  _checkIfSkillNameAndAlternateOrParentSame(skill, newSkill) {
    return skill.skill && skill.skill.toLowerCase() != newSkill.toLowerCase();
  }

  addSkillOnEnter(event: any, type, input: TagInputComponent): void {
    let newSkill = event.target.value;
    if (newSkill && newSkill != '') {
      if(type == 'parentSkillModal' || type == 'parentSkillInAlternateSkillModal') {
        if(this._checkIfCategorySelected(this.addEditNewSkillObject)) {
          let duplicateSkill = this.checkIfParentSkillDuplicate({skill: newSkill, skillTextChange: true});
          if (!duplicateSkill.isSkillDuplicate) {
            let newParentSkill = this._setNewSkill(this.addEditNewSkillObject);
            newParentSkill.skill = newSkill;
            newParentSkill.parentSkills = null;
            newParentSkill.alternateSkills = [];
            this._addParentSkill(newParentSkill);
            event.target.value = '';
            if(input) {
              input.setInputValue('');
            }
          } else {
            this.showWarningModal('duplicate', duplicateSkill.duplicateSkillMessage);
          }
        } else {
          this.showWarningModal('category', 'Please select skill category.');
        }
      } else if(type == 'alternateSkillModal' || type == 'alternateInParentSkillModal'){
        let newAlternateSkill:any = {};
        if(type == 'alternateSkillModal') {
          newAlternateSkill = this._setNewSkill(this.addEditNewSkillObject);
        } else {
          newAlternateSkill = this._setNewSkill(this.newParentSkillList);
        }

        if(this._checkIfCategorySelected(newAlternateSkill)) {
          let duplicateSkill = this.checkIfAlternateSkillDuplicate({skill: newSkill, skillTextChange: true});
          if(!duplicateSkill.isSkillDuplicate) {
          // if(!this._checkIfParentOrAlternateSkillDuplicate(newAlternateSkill, newSkill, 'alternateSkill') && this._checkIfSkillNameAndAlternateOrParentSame(newAlternateSkill, newSkill)) {
            newAlternateSkill.skill = newSkill;
            newAlternateSkill.alternateSkills = null;
            this._addAlternateSkill(newAlternateSkill, type);
            event.target.value = '';
            if(input) {
              input.setInputValue('');
            }
          } else {
            this.showWarningModal('duplicate', duplicateSkill.duplicateSkillMessage);
          }
        } else {
          this.showWarningModal('category', 'Please select skill category.');
        }
      }
  }
}


  _checkIfParentOrAlternateSkillDuplicate(mainSkill, newSkill, type) {
    let tempAllSkills = [...(mainSkill.parentSkills ? mainSkill.parentSkills : []), 
      ...((mainSkill.parentSkills && Array.isArray(mainSkill.parentSkills) && mainSkill.parentSkills[0] && mainSkill.parentSkills[0].alternateSkills) ? mainSkill.parentSkills[0].alternateSkills : []), 
      ...(mainSkill.alternateSkills ? mainSkill.alternateSkills : []), ...this.allSkills];
    tempAllSkills = [...tempAllSkills, ...(this.addEditNewSkillObject && this.addEditNewSkillObject.alternateSkills ? this.addEditNewSkillObject.alternateSkills : [])];
    return tempAllSkills.some(skill => skill.skill.toLowerCase() === newSkill.toLowerCase());
  }

  showWarningModal(type, message) {
    if(type == 'category') {
      this.alertsAndNotificationsService.showAlert("Select Category", message, "warning");
    } else if(type == 'duplicate') {
      this.alertsAndNotificationsService.showAlert("Duplicate", message, "warning");
    }
  }

  _checkAndSetRemovedAlternateSkills(skillObj) {
    const originalAlternateSkillIds = (this.editSkillObject && this.editSkillObject.alternateSkills) ? this.editSkillObject.alternateSkills.map(skill => skill.id) : [];
    const editedAlternateSkillIds = (skillObj && skillObj.alternateSkills) ? skillObj.alternateSkills.map(skill => skill.id) : [];
    return originalAlternateSkillIds.filter(skillId => !editedAlternateSkillIds.includes(skillId));
  }

}

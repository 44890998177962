import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { LoginService } from './login.service';
import { MESSAGECONSTANTS } from 'src/app/core/utils/constants';
import { UserService } from 'src/app/core/services/user.service';
import { SharedAjsService } from 'src/app/public/app/services/sharedAjsService';
import { WebSocketsService } from 'src/app/public/app/services/webSocketsService';
import { PlanService } from 'src/app/core/services/plan.service';
import { NgForm } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.css']
})

export class LoginPageComponent implements OnInit {
    @ViewChild('loginform', null) loginform: NgForm;
    //   form:any = {};
    user: any = {};
    rememberMe: boolean = false;
    exception: any = "";
    message: any = "";
    //   $state:any;
    MESSAGECONSTANTS: any = MESSAGECONSTANTS;
    //   static $inject = ['$state', 'sharedAjsService'];
    loginParamsObject: any;

    constructor(@Inject('$state') public $state: any, @Inject('$location') public $location: any, @Inject('$rootScope') public $rootScope: any,@Inject('sharedAjsService') public sharedAjsService: SharedAjsService, @Inject('webSocketsService') public webSocketsService:WebSocketsService,
        private StorageService: StorageService, private authService: AuthService, private loginService: LoginService,
        private alertsAndNotificationsService: AlertAndNotificationsService, private userService: UserService, private planService: PlanService) { }

    ngOnInit() {
        // this.form = {};
        // this.user = {};
        this.$rootScope.userActive = false; // Remove After converting to angular
        this.$rootScope.logoutInProgress = false; // Remove After converting to angular
        // this.rememberMe = true;
        // this.exception = "";
        // this.message = "";
        // let $state = this.$state;
        this.authService.setUserActive(false);
        this.authService.setLogoutInProgress(false);

        let logOutEmail = angular.copy(this.StorageService.getCookie('logoutemail'));
        if (angular.isDefined(logOutEmail) && (logOutEmail !== null)) {
            this.user.email = logOutEmail;
        }
        this.loginParamsObject = this.sharedAjsService._setLoginParameters(this.$location.search());
    }



    /**
     * When user Gives input and initiates Login
     * This function is called after the form is validated
     */
    validateUser() {
        this.exception = "";
        this.loginService.login(this.user).subscribe((res: HttpResponse<any>) => {
            let data = res.body;
            if (res.status == 200 && data.roleScreenRef != undefined && data.accountState != undefined) {
                this.webSocketsService.connect();
                this.$rootScope.logoutDialog = undefined;
                this.authService.setLogoutDialog(undefined);
                this.StorageService.removeCookie('logoutemail', '');
                this._manageCookiesandRememberMe(data);
                this._manageUserState(data);
            } else if (data.statusCode == "401") {
                this.exception = data.message;
                this.loginform.resetForm(this.user);
            }

        }, (error) => {
            this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
        });
    }

    /**
     * This method is invoked from login success call.
     * It manages cookis storage and rememberme status
     */
    _manageCookiesandRememberMe(data) {
        if (this.rememberMe) {
            this.StorageService.setCookie('rememberme', true, { 'expires': new Date(Date.now() + this.authService.refreshtokenvalidity )});
        } else {
            this.StorageService.setCookie('rememberme', false, { 'expires': new Date(Date.now() + this.authService.refreshtokenvalidity )});
        }
        // this.StorageService.setCookie('refreshtoken', data.refreshToken, { 'expires': new Date(Date.now() + this.$rootScope.refreshtokenvalidity) });
    }

    /**
    * This method is invoked from login success call.
    * it manages the re directions based on user state
    */
    _manageUserState(data) {
        this.message = "";
        if (angular.equals(data.accountState, MESSAGECONSTANTS.USER_STATES.ACTIVE)) {
            if (data.multiFactorAuthenticationEnabled) {
                this.userService.sendOTP(data.id, (isSent) => {
                    console.log("Otp Sent: " + isSent);
                }, (error) => {
                    this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
                });
                this.$state.go('validateOtp', { userObject: data, loginParamsObject: this.loginParamsObject });
            } else {
                // this.userService.navigateUserAfterLogin(data);
                this.sharedAjsService.navigateUserAfterLogin(data, this.loginParamsObject);

            }
        } else if (angular.equals(data.accountState, MESSAGECONSTANTS.USER_STATES.UNVERIFIED) || angular.equals(data.accountState, MESSAGECONSTANTS.USER_STATES.PASSWORDRESET)) {
            this.StorageService.set('userDetails', this._createUserDetailsObject(data), { 'expires': new Date(Date.now() + this.authService.tokenvalidity) });
            var message = 'Change Password to enter into application';
            this.$state.go('resetPassword', { message: message }, { reload: false });
        } else if (angular.equals(data.accountState, MESSAGECONSTANTS.USER_STATES.INACTIVE)) {
            this.message = 'Your Account is InActive. Contact Administrator for Details';
        } else if (angular.equals(data.accountState, MESSAGECONSTANTS.USER_STATES.BLOCKED)) {
            this.message = 'Your Account is Blocked. Contact Administrator for Details';
        }
    }

    _createUserDetailsObject(user) {
        let userObj: any = {};
        userObj.company = {};
        userObj.plan = {};
        userObj.id = user.id;
        userObj.firstname = user.firstname;
        userObj.lastname = user.lastname;
        userObj.emailId = user.emailId;
        userObj.companyId = user.companyId;
        // userObj.accesstoken = user.token;
        userObj.company.companyId = user.company.companyId;
        userObj.company.companyName = user.company.companyName;
        userObj.company.companyType = user.company.companyType;
        userObj.company.companyState = user.company.companyState;
        userObj.company.companyStateLabel = user.company.companyState == 'SoftHold' ? 'Soft Hold' : user.company.companyState;
        userObj.company.message = user.company.message;
        userObj.plan.planName = user.planMinDto.planName;
        userObj.plan.planType = user.planMinDto.planType;
        userObj.plan.startDate = moment(user.planMinDto.planStartDate);
        userObj.plan.endDate = moment(user.planMinDto.planEndDate);
        userObj.plan.message = this.planService.getPlanExpiryMessage(userObj.plan.planType, userObj.plan.endDate, true);;
        userObj.accountState = user.accountState;
        userObj.shouldPopupBeDisplayed = user.shouldPopupBeDisplayed;
        userObj.roleScreenRef = this._createRoleScreenRefObj(user.roleScreenRef);
        if (user.roleScreenRef.role.name !== '4dot5SuperUser' && user.roleScreenRef.role.name !== '4dot5Admin') {
            userObj.company.companySelected = true;
        } else {
            userObj.company.companySelected = false;
        }
        return userObj;
    }

    _createRoleScreenRefObj(roleScreenRef) {
        let roleObj: any = {};
        roleObj.role = {};
        roleObj.role.id = roleScreenRef.role.id;
        roleObj.role.name = roleScreenRef.role.name;
        roleObj.screen = this._manageScreenListforRoles(roleScreenRef.screen);
        return roleObj;
    }

    _manageScreenListforRoles(screenList) {
        for (let i = 0; i < screenList.length; i++) {
            delete screenList[i].name;
        }
        return screenList;
    }

    goToForgotPasswordPage() {
        this.$state.go('forgotPassword');
    }

}

import { downgradeComponent } from "@angular/upgrade/static";
import { ResetPasswordPageComponent } from "./reset-password-page.component";

export const ResetPasswordPageAjsModule = angular
    .module('resetPasswordPage', [])
    .directive('resetPasswordPage', downgradeComponent({ component: ResetPasswordPageComponent }))
    .config(
        ['$stateProvider', ($stateProvider) => {
            $stateProvider.state('resetPassword', {
                url: '/setpassword',
                component: ResetPasswordPageComponent,
                data: {
                    requireLogin: false
                },
                params: {
                    message: null
                }
            });
        }
        ])
    .constant('RESETPASSWORDCONSTANTS', {
        CONTROLLER: {
            SET_PASSWORD: 'auth/setpassword',
            CHANGE_PASSWORD: 'auth/changepassword'
        }
    })
    .name;
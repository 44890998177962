
import { downgradeComponent } from "@angular/upgrade/static";
import { AssessmentsManagementComponent } from './assessments-management.component';

export const AssessmentsManagementAjsModule = angular
    .module('assessmentManagementPage', [])
    .directive('assessmentManagementPage', downgradeComponent({ component: AssessmentsManagementComponent }))
    .config(
        ['$stateProvider', ($stateProvider) => {
            $stateProvider.state('missioncontrol.assessmentManagement', {
                url: '/assessmentManagement',
                component: AssessmentsManagementComponent,
                data: {
                    requireLogin: true
                },
                params: {
                    jobId: null,
                    jobTitle: null,
                    jobClient: null
                }
            });
        }
        ])
    .name;

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddEditManageSkillsSkillComponent } from './add-edit-manage-skills-skill.component';
import { FormsModule } from '@angular/forms';
import { TypeaheadModule } from 'ngx-bootstrap';
import { TagInputModule } from 'ngx-chips';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
  declarations: [AddEditManageSkillsSkillComponent],
  imports: [
    CommonModule,
    FormsModule,
    TypeaheadModule.forRoot(),
    TagInputModule,
    PipesModule
  ],
  exports: [AddEditManageSkillsSkillComponent],
  entryComponents: [AddEditManageSkillsSkillComponent]
})
export class AddEditManageSkillsSkillModule { }

import { downgradeComponent } from "@angular/upgrade/static";

import { EmployeesPageComponent } from "./employees-page.component";

export const EmployeesPageAjsModule = angular
    .module('employeesPage', [])
    .directive('employeesPage', downgradeComponent({ component: EmployeesPageComponent }))
    .config(
        ['$stateProvider', '$transitionsProvider', ($stateProvider, $transitionsProvider) => {
            $stateProvider.state('missioncontrol.employees', {
                url: '/employees',
                component: EmployeesPageComponent,
                data: {
                    requireLogin: true
                },
                params: {
                    loginParamsObject: null
                }
            });
        }

        ])
    .name;
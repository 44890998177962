<div class="row fuelux">
    <div class="col-lg-12">
        <div class="main-box clearfix">
            <div class="main-box-body clearfix wizardmainbox">
                <div class="row">
                    <div class="col-xs-12 main-box-header d-flex flex-row-reverse">
                        <button type="button" class="btn btn-danger btn-cancel" (click)="wizardCancel()">
                            <i class="fas fa-times"></i> Cancel
                        </button>
                    </div>
                </div>
                <div class="wizard" data-initialize="wizard" id="uploadFileWizard">
                    <div class="steps-container">
                        <ul class="steps">
                            <li data-step="1" data-name="upload" class="active">
                                <span class="badge">1</span>Upload<span class="chevron"></span>
                            </li>
                            <li data-step="2" data-name="scoring">
                                <span class="badge">2</span>
                                <span>View Results</span>
                                <span class="chevron"></span>
                            </li>
                        </ul>
                    </div>
                    <div class="actions">
                        <button type="button" class="btn btn-info btn-prev mr-2" [disabled]="disablePreviousButtonFlag">
                            <span class="glyphicon glyphicon-arrow-left"></span>Prev
                        </button>
                        <button type="button" class="btn btn-info btn-next" data-last="Complete"
                            [disabled]="disableNextButtonFlag">
                            Next<span class="glyphicon glyphicon-arrow-right"></span>
                        </button>
                    </div>
                    <div class="step-content">
                        <div class="step-pane active" data-step="1">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="main-box clearfix" style="min-height: 200px;">
                                        <div class="main-box-body clearfix col-lg-10 col-lg-offset-1"
                                            style="margin-top:50px">
                                            <p class="text-center">Upload CSV or Excel file.
                                                <a class="cursor-pointer"
                                                    (click)="showFileFormat(viewEmployeesSampleFormat)">Click here</a>
                                                for format.
                                            </p>
                                            <div>
                                                <form name="fileUploadDropzone" id="fileUploadDropzone"
                                                    class="dropzone dz-clickable" action=""
                                                    enctype="multipart/form-data">
                                                    <div class="dz-a dz-default dz-message">
                                                        <span>
                                                            <h3>Drop file here to upload</h3>(or click)
                                                        </span>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <!-- Upload Requisition button -->
                                        <div class="row buttonrow" style="margin-bottom: 200px">
                                            <div class="col-xs-12 col-sm-offset-4 col-sm-4">
                                                <button type="button" class="btn btn-success col-xs-12" id="uploadFile"
                                                    (click)="uploadEmployeeFile()" [disabled]="disableUploadButtonFlag">
                                                    <i class="fa fa-upload pr-2"></i>
                                                    <span *ngIf="!fileUploadInProgressFlag">Upload
                                                    </span><span *ngIf="fileUploadInProgressFlag">Uploading...
                                                    </span> File
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="step-pane" data-step="2">
                            <div class="row">
                                <div class="col-xs-12">
                                    <div class="main-box clearfix">
                                        <div class="main-box-body clearfix bulk-upload-table-container">
                                            <table
                                                class="table table-bordered table-responsive user-configuration-table">
                                                <tbody>
                                                    <tr>
                                                        <th>No.</th>
                                                        <th>Name </th>
                                                        <th>Phone number</th>
                                                        <th>Email</th>
                                                        <th>
                                                            <span *ngIf="isStaffingCompany">Department</span>
                                                            <span *ngIf="!isStaffingCompany">BU Name</span>
                                                        </th>
                                                        <th>Manager</th>
                                                        <th>Status</th>
                                                    </tr>
                                                    <tr *ngFor="let employee of employeeList; let i = index">
                                                        <td class="text-center">{{$index + 1}}.</td>
                                                        <td>{{employee.employeeDTO.name}}</td>
                                                        <td>{{employee.employeeDTO.phoneNumber}}
                                                        </td>
                                                        <td>{{employee.employeeDTO.email}}</td>
                                                        <td>
                                                            <span *ngIf="isStaffingCompany">
                                                                <span
                                                                    *ngIf="employee.employeeDTO.department !==null">{{employee.employeeDTO.department.name}}</span>
                                                                <span
                                                                    *ngIf="employee.employeeDTO.department ==null">-</span>
                                                            </span>
                                                            <span *ngIf="!isStaffingCompany">
                                                                <span>{{employee.employeeDTO.buName}}</span>
                                                            </span>
                                                        </td>
                                                        <td>{{employee.employeeDTO.organizationManagerName}}</td>
                                                        <td>
                                                            <i class="fas"
                                                                [ngClass]="{'fa-check green':employee.status !== 'FAILED'}"></i>
                                                            <i class="fas"
                                                                [ngClass]="{'fa-times entity-upload-failed-icon text-danger':employee.status == 'FAILED'}"></i>
                                                            <span [innerHtml]="employee.message" class="pl-2"></span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #viewEmployeesSampleFormat>
    <div class="modal-header" style="padding: 15px !important; border-bottom: 1px solid #e5e5e5;">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
            (click)="closeFileFormateModal()">×</button>
        <h4 class="modal-title wrap-text-in ng-binding">Upload Skills Format</h4>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-sm-12">
                <h4 class="border-bottom-0 mt-0 text-center">Excel file format</h4>
                <table class="table table-bordered file-format-content">
                    <tbody>
                        <tr>
                            <th>First Name <span class="text-danger">*</span></th>
                            <th>Last Name <span class="text-danger">*</span></th>
                            <th>Email Id <span class="text-danger">*</span></th>
                            <th>Phone Number</th>
                            <th *ngIf="isStaffingCompany">Department</th>
                            <th *ngIf="!isStaffingCompany">BU Name</th>
                            <th>Manager's Email ID</th>
                        </tr>
                        <tr>
                            <td>Mark</td>
                            <td>Greg</td>
                            <td>markgreg@email.com</td>
                            <td>+12014745874</td>
                            <td>R&D</td>
                            <td>don@email.com</td>
                        </tr>
                        <tr>
                            <td>Mike</td>
                            <td>Donald</td>
                            <td>mikedonald@email.com</td>
                            <td>+12014745823</td>
                            <td>Marketing</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Mary</td>
                            <td>Johnson</td>
                            <td>maryjohnson@email.com</td>
                            <td>+12014745853</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Samantha</td>
                            <td>John</td>
                            <td>samanthajohn@email.com</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <h4 class="border-bottom-0 mt-0 text-center">CSV file format</h4>
                <div class="border p-4 file-format-content">
                    <p>
                        First Name*,Last Name*,Email ID*,Phone Number,
                        <span *ngIf="isStaffingCompany">Department/Organization</span>
                        <span *ngIf="!isStaffingCompany">BU Name</span>
                        ,Manager's Email ID
                    </p>
                    <p>
                        Mike,Donald,mikedonald@4dot5.com,+1 201-876-542,R&D,carl@email.com
                    </p>
                    <p>
                        Mary,Wilson,marywilson@email.com,+1 201-876-542,Marketing
                    </p>
                    <p>
                        Tony,Greg,tonygreg@email.com, ,Marketing
                    </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 d-flex justify-content-end">
                <a *ngIf="isStaffingCompany" class="btn btn-primary mt-3"
                    href="assets/app-content/sampleFiles/sample-employee-staffing.csv" target="_blank">
                    <i class="fas fa-download"></i>
                    Download sample file
                </a>
                <a *ngIf="!isStaffingCompany" class="btn btn-primary mt-3"
                    href="assets/app-content/sampleFiles/sample-employee-corporate.csv" target="_blank">
                    <i class="fas fa-download"></i>
                    Download sample file
                </a>
            </div>
        </div>
    </div>
</ng-template>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddNewCompanyDetailsComponent } from './add-new-company-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FourDotFivePhoneNumberModule } from '../../four-dot-five-phone-number/four-dot-five-phone-number.module';
import { ModalModule } from 'ngx-bootstrap';
import { AddNewCompanyDetailsModalComponent } from './add-new-company-details-modal/add-new-company-details-modal.component';

@NgModule({
  declarations: [AddNewCompanyDetailsComponent, AddNewCompanyDetailsModalComponent],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    FormsModule,
    FourDotFivePhoneNumberModule
  ],
  providers: [AddNewCompanyDetailsComponent, AddNewCompanyDetailsModalComponent],
  entryComponents: [AddNewCompanyDetailsComponent, AddNewCompanyDetailsModalComponent],
  exports: [AddNewCompanyDetailsComponent, AddNewCompanyDetailsModalComponent]
})
export class AddNewCompanyDetailsModule { }

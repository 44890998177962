import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { UIRouterUpgradeModule, NgHybridStateDeclaration } from "@uirouter/angular-hybrid";
import { NgSelectModule } from '@ng-select/ng-select';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { AppComponent } from './app.component';

import * as $ from 'jquery';
import { DateTimeUtilityService } from './core/services/date-time-utility.service';

// Migrated Module Components
import { AppAjsBootstrapModule } from './public/app/app.module.ajs.bootstrap';
import { FooterModule } from './features/footer/footer.module';
import { HeaderModule } from './features/header/header.module';
import { MissionControlPageModule } from './pages/mission-control-page/mission-control-page.module';
import { SharedModule } from './shared/shared.module';
import { LoginPageModule } from './pages/login-page/login-page.module';
import { ForgotPasswordPageModule } from './pages/forgot-password-page/forgot-password-page.module';
import { ResetPasswordPageModule } from './pages/reset-password-page/reset-password-page.module';
import { ValidateOtpPageModule } from './pages/validate-otp-page/validate-otp-page.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    UpgradeModule,
    UIRouterUpgradeModule.forRoot(),
    NgSelectModule,
    CoreModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-middle-right',
      preventDuplicates: true,
      tapToDismiss: false,
      closeButton: true
    }),
    FooterModule,
    HeaderModule,
    MissionControlPageModule,
    SharedModule,
    LoginPageModule,
    ForgotPasswordPageModule,
    ResetPasswordPageModule,
    ValidateOtpPageModule
  ],
  providers: [
    {
      provide: '$state',
      useFactory: ($injector: any) => $injector.get('$state'),
      deps: ['$injector']
    },
    {
      provide: 'alertsAndNotificationsServiceAjs',
      useFactory: ($injector: any) => $injector.get('alertsAndNotificationsServiceAjs'),
      deps: ['$injector']
    },
    {
      provide: 'webSocketsService',
      useFactory: ($injector: any) => $injector.get('webSocketsService'),
      deps: ['$injector']
    },
    {
      provide: 'jobMatchActionsService',
      useFactory: ($injector: any) => $injector.get('jobMatchActionsService'),
      deps: ['$injector']
    },
    {
      provide: 'requisitionService',
      useFactory: ($injector: any) => $injector.get('requisitionService'),
      deps: ['$injector']
    },
    {
      provide: 'sharedAjsService',
      useFactory: ($injector: any) => $injector.get('sharedAjsService'),
      deps: ['$injector']
    },
    {
      provide: '$cookies',
      useFactory: ($injector: any) => $injector.get('$cookies'),
      deps: ['$injector']
    },
    CoreModule],
  bootstrap: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule {
  constructor(private upgrade: UpgradeModule, private dateService: DateTimeUtilityService) {
  }
  
  ngDoBootstrap() {
    // Testing services
    // console.log(this.dateService.getDateTimeInAPIFormat(new Date()));
    console.log("Bootstrapping in Hybrid mode with Angular and AngularJS");
    this.upgrade.bootstrap(document.documentElement, ['4dot5', AppAjsBootstrapModule.name]);
  }
  
}

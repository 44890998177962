import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetCandidateAvailabilityOrJobLeftDateComponent } from './set-candidate-availability-or-job-left-date.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [SetCandidateAvailabilityOrJobLeftDateComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  entryComponents: [SetCandidateAvailabilityOrJobLeftDateComponent],
  exports: [SetCandidateAvailabilityOrJobLeftDateComponent]
})
export class SetCandidateAvailabilityOrJobLeftDateModule { }

<div class="skill" *ngIf="uploadedEntities">
    <div class="col-xs-12">
        <div class="main-box p-4">
            <div class="mt-3">
                <p><b>{{multiEntityHeading}}</b> Total <span class="emerald font-bold">{{uploadedEntities.activeCount}}</span>
                    active and <span class="text-danger font-bold">{{uploadedEntities.inactiveCount}}</span>
                    inactive. <span class="text-success font-bold">{{uploadedEntities.addedCount}}</span>
                    added, <span class="emerald font-bold">{{uploadedEntities.updatedCount}}</span>
                    modified and <span class="text-danger font-bold">{{uploadedEntities.deletedCount}}</span>
                    deleted in this update.</p>
            </div>
        </div>
    </div>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionLessHeaderComponent } from './action-less-header.component';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
  declarations: [ActionLessHeaderComponent],
  imports: [
    CommonModule,
    PipesModule
  ],
  exports: [ActionLessHeaderComponent]
})
export class ActionLessHeaderModule { }

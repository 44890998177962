export class DoNotHireCandidateDetails {
    candidateId: any;
    companyId: any;
    doNotHireForCompanyIds: any;
    note : any = "";
    externalNote: any;
    constructor(candidateId, companyId) {
        this.candidateId = candidateId;
        this.companyId = companyId;
    }
}
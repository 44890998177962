import { downgradeComponent } from "@angular/upgrade/static";

import { ManageSkillsPageComponent } from "./manage-skills-page.component";

export const ManageSkillsPageAjsModule = angular
    .module('manageSkillsPage', [])
    .directive('manageSkillsPage', downgradeComponent({ component: ManageSkillsPageComponent }))
    .config(
        ['$stateProvider', ($stateProvider) => {
            $stateProvider.state('missioncontrol.manageSkills', {
                url: '/manageSkills',
                component: ManageSkillsPageComponent,
                data: {
                    requireLogin: true
                }
            });
        }
        ])
    .name;
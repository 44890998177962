import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoIconComponent } from './info-icon.component';
import { PopoverModule } from 'ngx-bootstrap';

@NgModule({
  declarations: [InfoIconComponent],
  imports: [
    CommonModule,
    PopoverModule.forRoot()
  ],
  exports: [InfoIconComponent],
  entryComponents: [InfoIconComponent]
})
export class InfoIconModule { }

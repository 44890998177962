<div class="container">
    <diV class="row">
        <div class="col-xs-12" *ngIf="!isLocationLoading" style="height: 320px;"></div>
        <div class="col-xs-12" *ngIf="isLocationLoading">
            <form #locationForm="ngForm" name="locationForm" novalidate>
                <div class="row">
                    <div class="col-xs-6">
                        <div class="form-group" [ngClass]="{'has-error':(locationForm.submitted && address1.invalid)}">
                            <label for="address1" class="control-label">Address1 : </label>
                            <input #address1="ngModel" [(ngModel)]="location.address1" type="text" class="form-control"
                                name="address1" (change)="changeLocationDetails()">
                        </div>
                    </div>
                    <div class="col-xs-6">
                        <div class="form-group">
                            <label for="address2">Address2 : </label>
                            <input #address2="ngModel" [(ngModel)]="location.address2" type="text" class="form-control"
                                name="address2" (change)="changeLocationDetails()">
                        </div>
                        <!--<div class="error form-group" *ngIf="locationForm.address2.$dirty && location.address2 !== '' && ((location.address2 | uppercase) === (location.address1 | uppercase))">-->
                        <!--<small class="error"> {{MESSAGECONSTANTS.COMMONVALIDATIONS.ADDRESS_CANNOT_BE_SAME}} </small>-->
                        <!--</div>-->
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-6">
                        <div class="form-group mb-1 position-relative"
                            [ngClass]="{'has-error':(zipCode.dirty || locationForm.submitted) && (zipCode.invalid || !isZipCodeValidFlag)}">
                            <label for="zipCode" class="control-label">Zip Code<em *ngIf="isRequired"> * </em> :</label>
                            <input #zipCode="ngModel" [(ngModel)]="location.zipCode" minlength="5" type="text"
                                class="form-control" name="zipCode" [required]="isRequired" (blur)="getZipCodeDetails()"
                                (change)="location.zipCode.length>=5 && getZipCodeDetails()">
                            <span class="help-block">ex. 75056 </span>
                            <span *ngIf="context === 'candidateJobMatchCard'
                                && (!originalLocationObject || !originalLocationObject.zipCode || originalLocationObject.zipCode == '')" 
                                class="fa fa-exclamation-circle red position-absolute ml-2" style="top: 35px; right: 10px; font-size: 1.125em;"
                                placement="top" tooltip="Update Missing Zip code" container="body">
                            </span>
                        </div>
                        <div class="error form-group"
                            *ngIf="(zipCode.dirty || locationForm.submitted) && zipCode.valid && (!isZipCodeValidFlag)">
                            <small class="error">
                                {{MESSAGECONSTANTS.COMMONVALIDATIONS.NO_LOCATION_FOUND}}
                            </small>
                        </div>
                        <div class="error form-group"
                            *ngIf="(zipCode.dirty || locationForm.submitted) && zipCode.invalid">
                            <small class="error" *ngIf="zipCode.errors?.pattern">
                                {{MESSAGECONSTANTS.COMMONVALIDATIONS.NOT_VALID_ZIPCODE}}
                            </small>
                            <small class="error" *ngIf="zipCode.errors?.minlength">
                                {{MESSAGECONSTANTS.COMMONVALIDATIONS.ZIPCODE_MINLENGTH}}
                            </small>
                            <small class="error" *ngIf="zipCode.errors?.maxlength">
                                {{MESSAGECONSTANTS.COMMONVALIDATIONS.ZIPCODE_MAXLENGTH}}
                            </small>
                        </div>
                    </div>
                    <div class="col-xs-6">
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-6">
                        <div class="form-group" [ngClass]="{'has-error':(locationForm.submitted && city.invalid)}">
                            <label for="city" class="control-label">City <em *ngIf="isRequired"> * </em>: </label>
                            <select #city="ngModel" class="form-control access companyaddresscopyenabled" name="city"
                                [(ngModel)]="location.city" [required]="isRequired" (change)="changeLocationDetails()">
                                <option *ngFor="let geolocation of geoLocations | unique:'city'" [value]="geolocation.city">
                                    {{geolocation.city}}</option>
                                <option value="" *ngIf="false"></option>
                            </select>
                        </div>
                    </div>
                    <div class="col-xs-6">
                        <div class="form-group" [ngClass]="{'has-error':(locationForm.submitted && state.invalid)}">
                            <label for="state" class="control-label">State <em *ngIf="isRequired"> * </em>: </label>
                            <select #state="ngModel" class="form-control access companyaddresscopyenabled" name="state"
                                [(ngModel)]="location.state" [required]="isRequired">
                                <option *ngFor="let geolocation of geoLocations | unique:'state'" [value]="geolocation.state">
                                    {{geolocation.state}}</option>
                                <option value="" *ngIf="false"></option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-6">
                        <div class="form-group" *ngIf="!doesCountyExistFlag">
                            <label for="county">County : </label>
                            <input #county="ngModel" [(ngModel)]="location.county" type="text"
                                class="form-control access" name="county">
                        </div>

                        <div class="form-group" *ngIf="doesCountyExistFlag">
                            <label for="county">County : </label>
                            <select #county="ngModel" class="form-control access" name="county"
                                [(ngModel)]="location.county">
                                <option *ngFor="let geolocation of geoLocations | unique:'county'" [value]="geolocation.county">
                                    {{geolocation.county}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-xs-6">
                        <div class="form-group"
                            [ngClass]="{'has-error':(locationForm.submitted && country.invalid)}">
                            <label for="country" class="control-label">Country <em *ngIf="isRequired"> * </em>: </label>
                            <select #country="ngModel" class="form-control access companyaddresscopyenabled"
                                name="country" [(ngModel)]="location.country" ng-required="isRequired">
                                <option *ngFor="let geolocation of geoLocations | unique:'country'" [value]="geolocation.country">
                                    {{geolocation.countryFullName}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="showSave">
                    <div class="col-xs-12 text-right">
                        <div class="clearfix">
                            <button type="button" class="btn btn-danger mr-2" (click)="cancelLocationUpdate()"
                                [disabled]="savingFlag || isLocationCleared">
                                <span *ngIf="!cancellingFlag">Cancel</span>
                                <span *ngIf="cancellingFlag">Canceling..</span>
                            </button>
                            <button type="button" *ngIf="entityType ==='job'" class="btn btn-danger  mr-2"
                                (click)="clearLocation()" [disabled]="savingFlag || isLocationCleared">
                                <span *ngIf="!isLocationCleared">Clear Location</span>
                                <span *ngIf="isLocationCleared">Clearing Location..</span>
                            </button>
                            <button type="submit" class="btn btn-success  mr-2" (click)="saveLocation()"
                                [disabled]="savingFlag || isLocationCleared">
                                <span *ngIf="!savingFlag">Update Location</span>
                                <span *ngIf="savingFlag">Updating..</span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </diV>
</div>
import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/core/services/storage.service';
import { MESSAGECONSTANTS } from 'src/app/core/utils/constants';

@Component({
  selector: 'app-action-less-header',
  templateUrl: './action-less-header.component.html',
  styleUrls: ['./action-less-header.component.css']
})
export class ActionLessHeaderComponent implements OnInit {

  userDetails:any;
  MESSAGECONSTANTS:any = MESSAGECONSTANTS;
  constructor(private StorageService:StorageService) { }

  ngOnInit() {
    // this.imageUrl = this.$rootScope.imageUrl;
    this.userDetails = _.cloneDeep(this.StorageService.get('userDetails'));
  }

}

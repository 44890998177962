import { downgradeComponent } from "@angular/upgrade/static";
import { RoleEntityScopePageComponent } from "./role-entity-scope-page.component";

export const RoleEntityScopePageAjsModule = angular
    .module('roleEntityScopePage', [])
    .directive('roleEntityScopePage', downgradeComponent({ component: RoleEntityScopePageComponent }))
    .config(
        ['$stateProvider', ($stateProvider) => {
            $stateProvider.state('missioncontrol.roleentityscope', {
                url: '/roleentityscope',
                component: RoleEntityScopePageComponent,
                data: {
                    requireLogin: true
                },
                resolve: {
                    companyRoleScopeData: ['utilityService', function(utilityService) {
                        return utilityService.getCompanyRoleEntityScope().then((data) => {
                            return data;
                        })
                    }]
                }
            });
        }
        ])
    .name;
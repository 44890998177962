import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'companyNameSettingPipe'
})
export class CompanyNameSettingPipe implements PipeTransform {

  transform(value: any): any {
    let output = !_.isUndefined(value) ? value : "";
    if (output.length > 32) {
      return output.substring(0, 32) + "...";
    } else {
      return output;
    }
  }

}


<div class="row" [hidden]="!loadingRecentRequisitionsFlag">
    <div class="col-xs-12 recent-requisitions-loading text-center">
        <span class="fa fa-spinner fa-spin"></span>
    </div>
</div>
<div class="row" [hidden]="loadingRecentRequisitionsFlag">
    <div class="col-lg-4 col-md-6 col-sm-6"  *ngFor="let req of requisitions">
        <requisition-card 
            [requisitionInfo]="req" 
            [activityMap]="activityMap" 
            [salaryDurationOptions]="salaryDurationOptions" 
            (deleteCallback)="deleteRequisition(req)" 
            (copyCallback)="copyRequisition()">
        </requisition-card>
    </div>
</div>
<div class="requisition_not_found d-flex flex-column" [hidden]="loadingRecentRequisitionsFlag"
    *ngIf="requisitions?.length === 0">
    <span class="mb-3">
        No requisitions available!
    </span>
    <img src="assets/app-content/img/no_requisitions.svg" style="height:180px" alt="No Candidates Found">
</div>
<div id="content-wrapper">
    <div class="row">
        <div class="col-lg-12">
            <div class="slide-main-container">
                <div class="container slide-main-animation">
                    <div class="row">
                        <div class="col-xs-12" style="display: flex; justify-content: center; align-items: center;">
                            <div id="" style="width:400px; margin: 100px auto">
                                <div id="login-box-holder">
                                    <div class="row">
                                        <div class="col-xs-12 text-center">
                                            <header id="login-header">
                                                <div id="login-logo" style="padding: 17px 0;">
                                                    <img class="lock-image" src="assets/app-content/img/lock.svg"
                                                        width="28%" />
                                                </div>
                                            </header>
                                            <div id="login-box-inner" class="with-heading">
                                                <p>Please enter the 6 digit code sent to your email.
                                                    <span class="timer">
                                                        {{formattedTimeLeft}}
                                                    </span>
                                                </p>
                                                <form #validateOtpForm="ngForm"
                                                    (ngSubmit)="validateOtpForm.form.valid && validate()" novalidate>
                                                    <input class="otp-input" type="text" name="otp" [(ngModel)]="otp"
                                                        maxlength="6" (change)="onOtpEnter($event)" required>
                                                    <div class="otp-placeholder"></div>
                                                    <span class="text-danger" style="display:block;padding-top:10px"
                                                        *ngIf="timeLeft == 0">
                                                        OTP has expired
                                                    </span>
                                                    <span style="position: relative;top: 4px;" class="text-danger"
                                                        *ngIf="showErrorMessage">{{errorMessage}}</span>
                                                    <div class="row" style="margin-top:10px">
                                                        <div class="col-xs-12">
                                                            <button id="validate" type="submit" class="btn btn-success"
                                                                [disabled]="otp.length!==6 || timeLeft == 0"
                                                                style="margin-top: 8px;padding: 10px 20px 10px 20px;">
                                                                SUBMIT
                                                            </button>
                                                        </div>
                                                        <a class="cursor-pointer" (click)="resendOtp()"
                                                            *ngIf="timeLeft<280"
                                                            style="display: inline-block;margin-top:5px;">
                                                            Resend OTP
                                                        </a>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit, Input, ViewChild, Output, EventEmitter, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StateService } from '@uirouter/angular';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { EmployeeService } from 'src/app/core/services/employee.service';
import { UserService } from 'src/app/core/services/user.service';
import { MESSAGECONSTANTS } from 'src/app/core/utils/constants';

@Component({
  selector: 'app-add-edit-employee',
  templateUrl: './add-edit-employee.component.html',
  styleUrls: ['./add-edit-employee.component.css']
})
export class AddEditEmployeeComponent implements OnInit {

  @ViewChild('employeeDetailsForm', null) employeeDetailsForm: NgForm;

  @Input() employeeId;
  @Input() isPopup;
  @Output() saveCallback = new EventEmitter<any>();

  MESSAGECONSTANTS: any = MESSAGECONSTANTS;

  currentCompanyId: any;
  employee: any = {};
  employeeCopy: any = {};
  mode: any = 'add';
  departmentList: Array<any> = [];
  buList: Array<any> = [];
  selectedDepartmentObjectsArray: Array<any> = [];
  selectedOrganizationalManagerObjectsArray: Array<any> = [];
  organizationalManagersArray: Array<any> = [];
  phoneNumberFormat: any = '';

  isPhoneNumberInvalid: boolean = false;
  isCorporateFlag: boolean;
  loadingEmployeeFlag: boolean = true;
  savingFlag: boolean = false;
  canAddUserAtCompanyLevel: boolean;
  canAddUserAtDepartmentOrBULevel: boolean;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private employeeService: EmployeeService,
    private alertsAndNotificationsService: AlertAndNotificationsService,
    @Inject('$state') private $state: StateService
  ) { }

  ngOnInit() {
    this.currentCompanyId = this.authService.getUserDetails().company.companyId;
    // flags
    this.isCorporateFlag = (this.authService.getUserDetails().company.companyType == 'Corporation');

    if (!this.employeeId) {
      this.employee = this._getEmployeeObject();
      this.employeeCopy = this._getEmployeeObject();
      this._getAndSetDataBasedOnUserRole(() => {
        this.loadingEmployeeFlag = false;
      });
    } else {
      this.mode = 'edit';
      this.loadingEmployeeFlag = true;
      this.getEmployeeDetails(() => {
        this.employee.companyId = this.currentCompanyId;
        if (!this.isCorporateFlag) {
          if (!_.isNull(this.employee.department)) {
            this.selectedDepartmentObjectsArray.push(this.employee.department);
          }
        } else {
          if (!_.isNull(this.employee.bu)) {
            this.employee.buId = this.employee.bu.id;
          } else {
            this.employee.buId = "";
          }
        }
        this.employeeCopy = angular.copy(this.employee);
        this._getAndSetDataBasedOnUserRole(() => {
          this.loadingEmployeeFlag = false;
        });
      });
    }
  }

  _getAndSetDataBasedOnUserRole(successCallback) {
    // if it is corporation, check bu permissibility
    if (this.isCorporateFlag) {
      // for non admin user
      this.userService.getUserRoleEntityScope((data) => {
        let searchObject = {
          'searchText': '',
          'companyId': this.currentCompanyId
        };
        this.employeeService.getPermissibleBUsForUser(searchObject, (data) => {
          this.buList = [...data.buDTOs];
          if (data.userBelongToBu) {
            this.canAddUserAtCompanyLevel = false;
          } else {
            this.canAddUserAtCompanyLevel = true;
          }
          this._addEmployeeBuToBusList();
          if (successCallback) {
            successCallback();
          }
        }, (error) => {
          this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
          if (successCallback) {
            successCallback();
          }
        });
      }, (error) => {
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
        if (successCallback) {
          successCallback();
        }
      });
    } else {
      // for staffing, as of now employees can be added at all levels by all users
      this.canAddUserAtCompanyLevel = true;
      this.canAddUserAtDepartmentOrBULevel = true;
      if (successCallback) {
        successCallback();
      }
    }
  }

  _addEmployeeBuToBusList() {
    if (this.mode == 'edit') {
      this.employee.buId = "";
      if (!_.isNull(this.employee.bu) && this.employee.bu !== undefined) {
        this.employee.buId = this.employee.bu.id;
        if (_.findIndex(this.buList, { 'id': this.employee.buId }) == -1) {
          this.buList.push({ id: this.employee.buId, name: this.employee.bu.name });
        }
      } else if (!this.canAddUserAtCompanyLevel) {
        this.buList.push({ id: "", name: "Corporate" });
      }
    }
  }

  _getEmployeeObject() {
    let employee: any = {};
    employee.id = null;
    employee.firstName = '';
    employee.lastName = '';
    employee.email = '';
    employee.phoneNumber = '';
    employee.title = '';
    employee.organizationManagerId = null;
    employee.department = null;
    employee.buId = '';
    employee.companyId = this.currentCompanyId;
    return employee;
  }

  getEmployeeDetails(successCallback) {
    this.employeeService.getEmployee(this.employeeId, (data) => {
      if (!_.isNull(data.organizationManager)) {
        data.organizationManagerId = data.organizationManager.id;
        let selectedOrganizationManagerObject = data.organizationManager;
        selectedOrganizationManagerObject.name = selectedOrganizationManagerObject.firstName;
        selectedOrganizationManagerObject.name = _.isNull(selectedOrganizationManagerObject.lastName) ? selectedOrganizationManagerObject.name : selectedOrganizationManagerObject.name + ' ' + selectedOrganizationManagerObject.lastName;
        this.selectedOrganizationalManagerObjectsArray.push(selectedOrganizationManagerObject);
        delete data.organizationManager;
      }
      this.employee = angular.copy(data);
      successCallback();
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      this.loadingEmployeeFlag = false;
    });
  }

  public getDepartments = (searchTerm: string): Observable<any[]> => {
    let searchObject = {
      'searchText': searchTerm,
      'companyId': this.currentCompanyId
    };

    return from(
      this.employeeService.getDepartmentsPromiseForTypeAhead(searchObject)
        .then((data: any[]) => {
          data.forEach(employee => {
            employee.display = employee.name;
          });
          return data;
        })
    );
  }

  public getOrganizationalManagers = (searchTerm: String): Observable<any[]> => {
    let searchObject = {
      'searchText': searchTerm,
      'page': 1,
      'size': 10,
      'companyId': this.currentCompanyId,
      'employeeId': this.employeeId,
      'sortColumn': 'firstName',
      'sortDirection': 'ASC'
    };

    return from(
      this.employeeService.getOrganizationalManagersPromiseForTypeAhead(searchObject)
        .then((data: any[]) => {
          data.forEach((val: any) => {
            val.name = val.firstName + (_.isNull(val.lastName) ? '' : ' ' + val.lastName);
          });
          return data;
        })
    );

  }

  saveEmployeeDetails() {
    if (!this.employeeDetailsForm.invalid) {
      if (this.employee.buId == '') {
        this.employee.buId = null;
      }
      if (!_.isEqual(this.employee, this.employeeCopy)) {
        this.savingFlag = true;
        this.employeeService.addUpdateEmployee(this.employee, (data) => {
          if (_.isNull(this.employee.id)) {
            this.alertsAndNotificationsService.showBannerMessage('Employee added successfully.', 'success');
          } else {
            this.alertsAndNotificationsService.showBannerMessage('Employee updated successfully.', 'success');
          }
          this.savingFlag = false;
          // go to employees screen after successful save
          if (this.isPopup) {
            if (this.saveCallback) {
              this.saveCallback.emit({ employee: data });
            }
          } else {
            this.$state.go('missioncontrol.employees', null, { reload: true });
          }
        }, (error) => {
          this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
          this.savingFlag = false;
        });
      } else {
        this.alertsAndNotificationsService.showBannerMessage('Nothing to update', 'success');
        this.savingFlag = false;
      }
    } else {
      this.employeeDetailsForm.onSubmit(undefined);
    }
  }

  cancelEmployeeAddEdit() {
    if (_.isEqual(this.employee, this.employeeCopy)) {
      if (this.isPopup) {
        this.saveCallback.emit();
      } else {
        this.$state.go('missioncontrol.employees', null, { reload: true });
      }
    } else {
      let title = "Warning!";
      let message = "Unsaved data on the page will be lost. Do you still want to continue?"
      this.alertsAndNotificationsService.showConfirm(title, message, "warning", true, () => {
        this.$state.go('missioncontrol.employees', null, { reload: true });
      }, () => {
        // Do Nothing
      });
    }
  }

  departmentTypeAheadDepartmentAdded(tag) {
    this.employee.department = {};
    if (!_.isUndefined(tag.id)) {
      this.employee.department.id = tag.id;
    }
    this.employee.department.name = tag.name;
    this.employee.department.staffingCompanyId = this.currentCompanyId;
  }

  departmentTypeAheadDepartmentRemoved(tag) {
    this.employee.department = null;
  }

  organizationalManagerTypeAheadManagerAdded(tag) {
    this.employee.organizationManagerId = tag.id;
  }

  organizationalManagerTypeAheadManagerRemoved(tag) {
    this.employee.organizationManagerId = null;
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CompanyService } from 'src/app/core/services/company.service';
import { UserRoleService } from 'src/app/core/services/user-role.service';

@Component({
    selector: 'app-candidate-card-icons',
    templateUrl: './candidate-card-icons.component.html',
    styleUrls: ['./candidate-card-icons.component.css']
})
export class CandidateCardIconsComponent implements OnInit {

    @Input() context;
    @Input() candidate;
    @Input() candidateAdditionalDetails;
    @Output() launchModal = new EventEmitter<any>();
    @Input() candidateSalaryDurationOptions;
    @Input() requisitionSponsorships;
    @Input() jobTypeList;
    @Input() isRowViewActive;

    workLocationTooltipMessage: any;
    candidateRelocationMessage: any;
    sponsorshipTooltipMessage: any;
    preferredJobTypeTooltipMessage: any;
    salaryTooltipMessage: any;
    candidatePreferredJobType: any = [];
    requisitionRecruiterList: any = [];
    popoverPlacement = "top";
    tooltipPlacement = "bottom";
    candidatePreferredJobTypeMessage: any;
    candidateSponsorshipsMessage: any;
    candidateWorkLocationsMessage: any;

    isCandidateSponsorshipExists: boolean;
    isCandidateSalaryExists: boolean;
    isCandidatePreferredJobTypeExists: boolean;
    isCandidateWorkLocationsExists: boolean;
    isCandidateAdditionalUrlsExists: boolean;
    isSuperUser: boolean = false;
    isUserAdmin: boolean = false;
    isVendorCandidate: boolean = false;
    isSharedRequisition: boolean = false;
    isStaffingCompany: boolean = false;
    isCorporateCompany: boolean = false;
    isManualOrAutomatedMatchEnabled: boolean = false;
    isCandidateAutomatedMatch: boolean = false;

    constructor(private userRoleService: UserRoleService, private companyService:CompanyService) { }

    ngOnInit() {
        this.isSuperUser = this.userRoleService.isLoggedInUserSuperUser();
        this.isStaffingCompany = this.companyService.isStaffingCompany();
        this.isCorporateCompany = this.companyService.isCorporateCompany();

        if(this.isSuperUser) {
            this.isVendorCandidate = false;
        } else {
            if(this.candidate.vendorName || (this.context === 'candidateCurrentJobMatches' && this.candidate.candidateType === 'VENDOR_CANDIDATES')) {
                this.isVendorCandidate = true;
            }
        }

        if (!this.candidateAdditionalDetails) {
            this.candidateAdditionalDetails = {};
        }
        this._setTooltipAndPopoverPlacements();
        this._setCandidateSalaryMessage();
        this._setCandidateRelocation();

        if (this.context == 'candidateJobMatchCard') {
            this._setRequisitionSponsorship();
        } else {
            this._setCandidatesSponsorship();
        }

        this._setCandidatePreferredJobTypes();
        this._setWorkLocations();
        this._setRequisitionSalaryMessage();
        this._setRequisitionJobType();
        this._setCandidateAdditionalUrls()
        
        if (this.context === 'candidatePossibleJobMatchesPopover') {
            this.isSharedRequisition = this.candidate.sharedJob;
        }

        this._setRequisitionRecruiterList();
        this._setManualOrAutomatedMatch()
    }

    _setTooltipAndPopoverPlacements() {
        if (this.context == 'candidateCard' || this.context == 'candidateJobMatchCard' || this.context == 'associateCandidates') {
            this.popoverPlacement = "bottom";
            this.tooltipPlacement = "top";
        } 
    }

    _setCandidateRelocation() {
        if (this.candidateAdditionalDetails.willingToRelocate) {
            if (this.candidateAdditionalDetails.willingToRelocate === 'YES') {
                this.candidateRelocationMessage = "Candidate Willing to Relocate";
            } else if (this.candidateAdditionalDetails.willingToRelocate === 'NO') {
                this.candidateRelocationMessage = "Candidate Not Willing to Relocate";
            } else if (this.candidateAdditionalDetails.willingToRelocate === 'NOT_APPLICABLE') {
                this.candidateRelocationMessage = "Not Applicable";
            }
        }
    }

    _setCandidatesSponsorship() {
        if (_.isNull(this.candidateAdditionalDetails.sponsorships) || _.isUndefined(this.candidateAdditionalDetails.sponsorships) || this.candidateAdditionalDetails.sponsorships.length === 0) {
            this.isCandidateSponsorshipExists = false;
            this.sponsorshipTooltipMessage = "Candidate's Preferred Sponsorships not available";
        } else {
            this.isCandidateSponsorshipExists = true;
            if (this.candidateAdditionalDetails.sponsorshipIcon == 'GREEN') {
                this.sponsorshipTooltipMessage = "Candidate's Preferred Sponsorships matches with the job";
            } else if (this.candidateAdditionalDetails.sponsorshipIcon == 'RED') {
                // if ((this.candidate.sponsorshipProvided == true) && (this.candidateAdditionalDetails.sponsorships[0] == 'Citizen' || this.candidateAdditionalDetails.sponsorships[0] == 'Permanent Resident/Green Card')) {
                //     this.candidateAdditionalDetails.sponsorshipIcon = 'GREEN'
                // }
                this.sponsorshipTooltipMessage = "Candidate's Preferred Sponsorships doesn't match with the job";
            } else if (this.candidateAdditionalDetails.sponsorshipIcon == 'WHITE' || _.isNull(this.candidateAdditionalDetails.sponsorshipIcon)) {
                this.sponsorshipTooltipMessage = "Candidate's Preferred Sponsorships";
            } else if (this.candidateAdditionalDetails.sponsorshipIcon == 'UNAVAILABLE') {
                this.isCandidateSponsorshipExists = false;
                this.sponsorshipTooltipMessage = "Candidate's Preferred Sponsorships not available";
            }
        }

        if(this.isVendorCandidate) {
            this.isCandidateSponsorshipExists = true;
            this.sponsorshipTooltipMessage = "";
            this.candidateAdditionalDetails.sponsorshipIcon = 'WHITE';
        }

        // Set Sponsorship as string
        this.candidateSponsorshipsMessage = "";
        if (this.isCandidateSponsorshipExists && !this.isVendorCandidate) {
            let sponsorshipMessage = [];
            this.candidateAdditionalDetails.sponsorships.map((sponsorship, key) => {
                sponsorshipMessage.push(`${key != 0 ? " " : ''}${sponsorship}`);
            });
            this.candidateSponsorshipsMessage = sponsorshipMessage.toString();
        } else {
            this.candidateSponsorshipsMessage = 'N/A';
        }
    }

    _setRequisitionSponsorship() {

        if (!_.isNull(this.candidate.sponsorshipProvided) && !_.isUndefined(this.candidate.sponsorshipProvided)) {
            if (_.isNull(this.requisitionSponsorships) || _.isUndefined(this.requisitionSponsorships) || this.requisitionSponsorships.length == 0) {
                if (this.candidate.sponsorshipProvided == false) {
                    if (_.isNull(this.candidateAdditionalDetails.sponsorships) || _.isUndefined(this.candidateAdditionalDetails.sponsorships) || this.candidateAdditionalDetails.sponsorships.length === 0) {
                        this.isCandidateSponsorshipExists = false;
                    } else {
                        this.isCandidateSponsorshipExists = true;
                        // if (this.candidateAdditionalDetails.sponsorships[0] == 'Citizen' || this.candidateAdditionalDetails.sponsorships[0] == 'Permanent Resident/Green Card') {
                        //     this.candidateAdditionalDetails.sponsorshipIcon = 'GREEN';
                        // } else {
                        //     this.candidateAdditionalDetails.sponsorshipIcon = 'RED';
                        // }
                    }

                } else if (this.candidate.sponsorshipProvided == true) {
                    this.sponsorshipTooltipMessage = "";
                    if (_.isNull(this.candidateAdditionalDetails.sponsorships) || _.isUndefined(this.candidateAdditionalDetails.sponsorships) || this.candidateAdditionalDetails.sponsorships.length === 0) {
                        this.isCandidateSponsorshipExists = false;
                    } else {
                        this.isCandidateSponsorshipExists = true;
                        // this.candidateAdditionalDetails.sponsorshipIcon = 'GREEN';
                    }
                }
            } else {
                this._setCandidatesSponsorship();
            }
        } else {
            if (_.isNull(this.candidateAdditionalDetails.sponsorships) || _.isUndefined(this.candidateAdditionalDetails.sponsorships) || this.candidateAdditionalDetails.sponsorships.length === 0) {
                this.isCandidateSponsorshipExists = false;
            } else {
                this.isCandidateSponsorshipExists = true;
                this.candidateAdditionalDetails.sponsorshipIcon = 'WHITE';
            }
        }

        if (!this.isCandidateSponsorshipExists) {
            this.sponsorshipTooltipMessage = "Candidate's Preferred Sponsorships not available";
        }

        if(this.isVendorCandidate) {
            this.isCandidateSponsorshipExists = true;
            this.sponsorshipTooltipMessage = "";
            this.candidateAdditionalDetails.sponsorshipIcon = 'WHITE';
        }

    }

    _setCandidateSalaryMessage() {
        this.salaryTooltipMessage = "Candidate's Preferred Salary";

        if (!this.candidateAdditionalDetails.preferredJobTypeDetails || (!this.candidateAdditionalDetails.preferredJobTypeDetails.rate && !this.candidateAdditionalDetails.preferredJobTypeDetails.salary)) {
            this.isCandidateSalaryExists = false;
            this.salaryTooltipMessage = "Candidate's Preferred Salary not available";
        } else {
            this.isCandidateSalaryExists = true;
            if (this.candidateAdditionalDetails.preferredJobTypeDetails.salary) {
                this.candidateAdditionalDetails.salaryMessage = this._getSalaryDetailsDisplayMessage(this.candidateAdditionalDetails.preferredJobTypeDetails.salary);
            }
            if (this.candidateAdditionalDetails.preferredJobTypeDetails.rate) {
                this.candidateAdditionalDetails.rateMessage = this._getRateDetailsDisplayMessage(this.candidateAdditionalDetails.preferredJobTypeDetails.rate);
            }
            if (this.candidateAdditionalDetails.salaryMessage === 'Not Available' && this.candidateAdditionalDetails.rateMessage === 'Not Available') {
                this.isCandidateSalaryExists = false;
            }

            if (this.candidateAdditionalDetails.salaryIcon == 'GREEN') {
                this.salaryTooltipMessage = "Candidate's Preferred Salary matches with the job";
            } else if (this.candidateAdditionalDetails.salaryIcon == 'RED') {
                this.salaryTooltipMessage = "Candidate's Preferred Salary doesn't match with the job";
            } else if (this.candidateAdditionalDetails.salaryIcon == 'REDCROSS') {
                this.salaryTooltipMessage = "Candidate's Preferred Job Type doesn't match with the job";
            } else if (!this.isCandidateSalaryExists) {
                this.salaryTooltipMessage = "Candidate's Preferred Salary not available";
            }
        }

        if(this.isVendorCandidate) {
            this.isCandidateSalaryExists = true;
            this.salaryTooltipMessage = "";
            this.candidateAdditionalDetails.salaryIcon = 'WHITE';
        }

    }

    _getUOM(salaryDuration) {
        let uom: any = _.find(this.candidateSalaryDurationOptions, { 'value': salaryDuration });
        // return uom.name;
        return uom ? uom.name : "";
    }

    _getRateDetailsDisplayMessage(rate) {
        let message = "";
        if (_.isNull(rate.rateType)) {
            message = message + "Not Available";
        } else if (rate.rateType == "SPECIFIC") {
            message = message + rate.maxRate + " " + rate.currency + " " + this._getUOM(rate.rateDuration);
        } else if (rate.rateType == "RANGE") {
            if (rate.minRate === rate.MaxRate) {
                return "Not Available";
            }
            message = message + rate.minRate + " to " + rate.maxRate + " " + rate.currency + " " + this._getUOM(rate.rateDuration);
        }
        return message;
    }

    _getSalaryDetailsDisplayMessage(salary) {
        let message = "";
        if (_.isNull(salary.salaryType)) {
            message = message + "Not Available";
        } else if (salary.salaryType == "SPECIFIC") {
            message = message + salary.maxSalary + " " + salary.currency + " " + this._getUOM(salary.salaryDuration);
        } else if (salary.salaryType == "RANGE") {
            if (salary.minSalary === salary.maxSalary) {
                return "Not Available";
            }
            message = message + salary.minSalary + " to " + salary.maxSalary + " " + salary.currency + " " + this._getUOM(salary.salaryDuration);
        }
        return message;
    }

    _setCandidatePreferredJobTypes() {
        this.candidatePreferredJobType = [];
        this.preferredJobTypeTooltipMessage = "Candidate's Preferred Job Type";
        this.isCandidatePreferredJobTypeExists = false;
        if (this.candidateAdditionalDetails.preferredJobTypeDetails) {
            if (this.candidateAdditionalDetails.preferredJobTypeDetails.jobType) {
                this.candidatePreferredJobType = _.cloneDeep(this.candidateAdditionalDetails.preferredJobTypeDetails.jobType);
                if (this.jobTypeList) {
                    this.candidatePreferredJobType.map(item => {
                        item.jobType = this.jobTypeList.filter(jobType => jobType.value === item.jobType)[0].name;
                        if (item.jobType === 'Contractor' || item.jobType === 'Contract to Hire') {
                            item.jobSubType = this._setSubJobType(item.jobSubType)
                        }
                    })
                }
                this.isCandidatePreferredJobTypeExists = true;
            }
        }

        if (this.candidateAdditionalDetails.preferredJobIcon) {
            if (this.candidateAdditionalDetails.preferredJobIcon == 'GREEN') {
                this.preferredJobTypeTooltipMessage = "Candidate's Preferred Job Type matches with the job";
            } else if (this.candidateAdditionalDetails.preferredJobIcon == 'RED') {
                this.preferredJobTypeTooltipMessage = "Candidate's Preferred Job Type doesn't match with the job";
            } else if (!this.isCandidatePreferredJobTypeExists || this.candidateAdditionalDetails.preferredJobIcon == 'UNAVAILABLE') {
                this.preferredJobTypeTooltipMessage = "Candidate's Preferred Job Type not available";
            }
        }

        if(this.isVendorCandidate) {
            this.isCandidatePreferredJobTypeExists = true;
            this.preferredJobTypeTooltipMessage = "";
            this.candidateAdditionalDetails.preferredJobIcon = 'WHITE';
        }

        // Set Preferred job types as string
        this.candidatePreferredJobTypeMessage = "";
        if (this.candidatePreferredJobType.length > 0 && !this.isVendorCandidate) {
            let jobTypeMessage = [];
            this.candidatePreferredJobType.map((jobType, key) => {
                jobTypeMessage.push(`${key != 0 ? " " : ''}${jobType.jobType}${jobType.jobSubType ? `(${jobType.jobSubType})` : ''}`);
            });
            this.candidatePreferredJobTypeMessage = jobTypeMessage.toString();
        } else {
            this.candidatePreferredJobTypeMessage = 'N/A';
        }

    }

    _setSubJobType(jobSubType) {
        let jobAdditionalSubType = "";
        if (jobSubType === 'INDEPENDENT1099') {
            jobAdditionalSubType = "Independent/1099";
        } else if(jobSubType === 'CORPTOCORP'){
            jobAdditionalSubType = "Corp to Corp";
        } else if(jobSubType === 'W2') {
            jobAdditionalSubType = "W2";
        }

        return jobAdditionalSubType;
    }

    _setWorkLocations() {
        this.workLocationTooltipMessage = "Candidate's Preferred Work Locations";
        this.isCandidateWorkLocationsExists = false;
        if (this.candidate) {
            if (this.context === 'candidateCurrentJobMatches' || this.context === 'candidatePossibleJobMatches' || this.context === 'candidatePossibleJobMatchesPopover') {
                this.candidate.workLocations = this.candidate.candidateWorkLocations;
            }
            if (!this.candidate.workLocationsMatchStatus) {
                this.candidate.workLocationsMatchStatus = "available";
                this.workLocationTooltipMessage = "Job Work Locations";
            } else if (this.candidate.workLocationsMatchStatus === "noMatch") {
                this.workLocationTooltipMessage = "Candidate's preferred work locations doesn't match with the job";
            } else if (this.candidate.workLocationsMatchStatus === "match") {
                this.workLocationTooltipMessage = "Candidate's preferred work locations matches with the job";
            }
        } else {
            this.candidate = {};
            this.candidate.workLocationsMatchStatus = "available";
            this.workLocationTooltipMessage = "Job Work Locations";
            this.candidate.workLocations = [];
        }

        this.candidateWorkLocationsMessage = 'N/A';
        if (!this.candidate.workLocations || this.candidate.workLocations.length === 0) {
            this.workLocationTooltipMessage = "Candidate's Preferred Work locations not available";
            this.isCandidateWorkLocationsExists = false;
        } else {
            this.isCandidateWorkLocationsExists = true;
            if(!this.isVendorCandidate) {
                let workLocationMessage = [];
                this.candidate.workLocations.map((workLocation, key) => {
                    workLocationMessage.push(`${key != 0 ? " " : ''}${workLocation.name}`);
                });
                this.candidateWorkLocationsMessage = workLocationMessage.toString();
            }
        }

        if(this.isVendorCandidate) {
            this.isCandidateWorkLocationsExists = true;
            this.workLocationTooltipMessage = "";
            this.candidate.workLocationsMatchStatus = 'available';
        }

    }

    _setRequisitionSalaryMessage() {
        if (this.candidateAdditionalDetails && this.candidateAdditionalDetails.requisitionSalary) {
            this.candidateAdditionalDetails.requisitionSalaryMessage = this._getSalaryDetailsDisplayMessage(this.candidateAdditionalDetails.requisitionSalary);
        }
    }

    _setRequisitionJobType() {
        if (this.candidateAdditionalDetails.requisitionJobType) {
            if (this.jobTypeList) {
                this.candidateAdditionalDetails.requisitionJobTypeMessage = this.jobTypeList.filter(jobType => jobType.value === this.candidateAdditionalDetails.requisitionJobType)[0].name;
            }
        }
    }

    _setCandidateAdditionalUrls() {
        this.isCandidateAdditionalUrlsExists = false;
        if(!this.candidateAdditionalDetails.urls || this.candidateAdditionalDetails.urls.length == 0) {
            this.isCandidateAdditionalUrlsExists = false;
        } else {
            this.isCandidateAdditionalUrlsExists = true;
        }

        if(this.isVendorCandidate) {
            this.isCandidateAdditionalUrlsExists = true;
        }

    }

    _setRequisitionRecruiterList() {
        if (this.candidate.recruitersInfo && this.candidate.recruitersInfo.length > 0) {
            this.candidate.recruitersInfo.map(recruiter => {
                recruiter.name = recruiter.firstName + " " + recruiter.lastName;
                if(recruiter.name == this.candidate.poc) {
                    recruiter.isPoc = true;
                }
                this.requisitionRecruiterList.push(recruiter);
            })
            let staffingRecruiters = this.requisitionRecruiterList.filter(recruiter => recruiter.vendorRecruiter);
            let corporateRecruiters = this.requisitionRecruiterList.filter(recruiter => !recruiter.vendorRecruiter);
            if(this.isCorporateCompany) {
                this.requisitionRecruiterList = _.union(corporateRecruiters, staffingRecruiters);
            }
        }
    }

    _setManualOrAutomatedMatch() {
        if (this.context === 'candidateCurrentJobMatches' ||
            this.context === 'candidatePossibleJobMatchesPopover') {
            this.isManualOrAutomatedMatchEnabled = true;
            this.isCandidateAutomatedMatch = this.candidate.autoJobMatch;
        }
    }

    _setUrlLink(url) {
        if (url.startsWith('https:') || url.startsWith('http:')) {
            return url;
        } else {
            return `https://${url}`;
        }
    }

    launchUpdateModal() {
        this.launchModal.emit();
    }

}

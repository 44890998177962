import { Component, OnInit, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AngularModalService } from 'src/app/core/services/modal.service';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { ModalConfig } from 'src/app/core/models/modalConfig';
import { ManageSkillsService } from 'src/app/core/services/manage-skills.service';
import { SkillActivityFilter } from 'src/app/core/models/skillActivityFilter';
import { UtilityService } from 'src/app/core/services/utility.service';

declare var saveAs: any;

@Component({
  selector: 'app-view-uploaded-skills',
  templateUrl: './view-uploaded-skills.component.html',
  styleUrls: ['./view-uploaded-skills.component.css']
})
export class ViewUploadedSkillsComponent implements OnInit {

  viewSkillModal: BsModalRef;
  public onClose: Subject<any>;
  modalData: any;

  uploadedSkillsDetails: any = [];
  uploadedSkills: any;

  isLoading: Boolean = true;
  isSkillsLoading: Boolean = true;

  constructor(
    public bsModalRef: BsModalRef,
    private modalService: AngularModalService,
    public bsModalService: BsModalService,
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private skillsService: ManageSkillsService,
    private utilityService: UtilityService
  ) {
    this.onClose = new Subject();
    if (this.bsModalService.config.initialState) {
      this.modalData = this.bsModalService.config.initialState;
    }
  }

  ngOnInit() {
    this.getAllUploadedFiles();
  }

  getAllUploadedFiles() {
    this.isLoading = true;
    let activityFilter = new SkillActivityFilter();
    activityFilter.activityOriginatorType = 'FILE';
    this.skillsService.getAllActivities(activityFilter, (data) => {
        this.isLoading = false;
        this.uploadedSkillsDetails = data && data.skillActivities ? data.skillActivities : [];
    }, (error) => {
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
        this.isLoading = false;
    })
  }

  closeModal(data) {
    this.onClose.next(data);
    this.bsModalRef.hide();
  }

  viewSkillResults(uploadedSkills, template:TemplateRef<any>) {
    this.uploadedSkills = {...uploadedSkills};
    const config = new ModalConfig();
    const modalData: any = {};
    config.class = "custom-modal-xl custom-ngx-modal";
    config.initialState = modalData;
    config.ignoreBackdropClick = true;
    this.viewSkillModal = this.bsModalService.show(template, config);
  }

  downloadSkillFile(skill) {
    this.skillsService.downloadUploadedFile(skill.activityId, (data) => {
      const blob = new Blob([data.body], { type: 'application/octet-stream' });
      saveAs(blob, skill.originalFileName);
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

}

import { Inject, Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  urlConstants: any = {
    ADD_CANDIDATE_DOCUMENT_FOLDER: "api/document/createFolder",
    DELETE_CANDIDATE_DOCUMENT_FOLDER: "api/document/deleteFolder",
    UPDATE_CANDIDATE_DOCUMENT_FOLDER: "api/document/updateFolder",
    GET_ALL_CANDIDATE_DOCUMENT_FOLDERS: "api/document/getAllFolders",
    GET_CANDIDATE_DOCUMENT: "api/document/getCandidateDocument",
    GET_CANDIDATE_DOCUMENT_REPORT: "api/document/candidateDocument",
    DOCUMENT_UPLOAD_URL: "api/document/uploadDocuments",
    DELETE_CANDIDATE_DOCUMENT: "api/document/deleteDocument",
    UPDATE_CANDIDATE_DOCUMENT_DATA: "api/document/updateCandidateDocumentData",
    EMAIL_DOCUMENTS: "api/document/sendDocumentEmails"
  }

  constructor(@Inject(StorageService) private StorageService:StorageService, @Inject(GenericService) private genericService:GenericService,
  ) { }

  addCandidateDocumentFolder(folder, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.ADD_CANDIDATE_DOCUMENT_FOLDER, folder).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  updateCandidateDocumentFolder(folder, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.UPDATE_CANDIDATE_DOCUMENT_FOLDER, folder).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  deleteCandidateDocumentFolder(folderId, successCallback, errorCallback) {
    this.genericService.addObjectById(this.StorageService.get('baseurl') + this.urlConstants.DELETE_CANDIDATE_DOCUMENT_FOLDER + '/' + folderId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }
 
  getAllCandidateFolders(candidateId, companyId, candidateType, successCallback, errorCallback) {
    let payLoad = {
      candidateId: candidateId,
      companyId: companyId,
      candidateType: candidateType
    };

    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_CANDIDATE_DOCUMENT_FOLDERS, payLoad).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getCandidateDocument(documentId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_CANDIDATE_DOCUMENT + '/' + documentId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  updateCandidateDocumentData(document, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.UPDATE_CANDIDATE_DOCUMENT_DATA, document).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getUploadUrl() {
    return this.StorageService.get('baseurl') + this.urlConstants.DOCUMENT_UPLOAD_URL;
  }

  deleteCandidateDocument(document, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.DELETE_CANDIDATE_DOCUMENT, document).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getCandidateDocumentUrl(documentId) {
    return this.StorageService.get('baseurl') + this.urlConstants.GET_CANDIDATE_DOCUMENT + '/' + documentId;
  }

  getCandidateDocumentReportUrl(documentId) {
    return this.StorageService.get('baseurl') + this.urlConstants.GET_CANDIDATE_DOCUMENT_REPORT + '/' + documentId;
  }

  emailDocuments(emailDetails, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.EMAIL_DOCUMENTS, emailDetails).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

}

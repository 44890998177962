import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewAssessmentReportComponent } from './view-assessment-report.component';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
  declarations: [ViewAssessmentReportComponent],
  imports: [
    CommonModule,
    PipesModule
  ],
  entryComponents: [ViewAssessmentReportComponent],
  exports: [ViewAssessmentReportComponent]
})
export class ViewAssessmentReportModule { }

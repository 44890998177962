import { Inject, Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { GenericService } from './generic.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class JobMatchService {

  urlConstants:any = {
  SAVE_NOTES: 'api/jobmatch/notesandcommunications/save',
  GET_NOTES: 'api/jobmatch/contactandnotes',
  DELETE_NOTES: 'api/jobmatch/notesandcommunications/delete',
  GET_CANDIDATE_JOB_COMPARISON: 'api/job/candidateprofilecomparison',
  GET_JOB_MATCH_CANDIDATES: 'api/jobmatch/jobmatchcardview',
  GET_CANDIDATE_JOB_MATCH_CARD: 'api/jobmatch/candidateworkflowcard',
  GET_DETAILED_CANDIDATE_JOB_MATCHES: 'api/jobmatch/getdetailedjobmatchbycandidate',
  SET_NOT_INTERESTED: 'api/candidate/setnotinterested',
  SET_RELEASED: 'api/candidate/setreleased',
  MOVE_STEP_BACKWARD: 'api/candidate/movebackward',
  MOVE_STEP_FORWARD: 'api/candidate/moveforward',
  SEND_TECH_ASSESSMENT: 'api/candidate/sendtechassessment',
  SEND_VALUE_ASSESSMENT: 'api/candidate/sendvalueassessment',
  RESEND_ASSESSMENT: 'api/candidate/resendassessment',
  CANCEL_TECH_ASSESSMENT: 'api/candidate/canceltechassessment',
  CANCEL_VALUE_ASSESSMENT: 'api/candidate/cancelvalueassessment',
  SCHEDULE_PHONE_SCREEN: 'api/candidate/schedulephonescreen',
  SCHEDULE_INTERVIEW: 'api/candidate/scheduleinterview',
  GET_PHONE_SCREENERS: 'api/jobmatch/getphonescreeners',
  GET_INTERVIEWERS: 'api/jobmatch/getinterviewers',
  GET_MEETING_DETAILS: 'api/jobmatch/getmeetingscheduledetails',
  RESCHEDULE_MEETING: 'api/candidate/reschedulemeeting',
  CANCEL_MEETING: 'api/candidate/cancelscheduledmeeting',
  JOB_MATCH_RECRUITERS: 'api/candidate/recruiters',
  ASSIGN_RECRUITER: 'api/candidate/setrecruiter',
  CREATE_JOB_MATCHES: 'api/jobmatch/createjobmatches',
  GET_ALL_JOB_MATCH_STATES: 'api/jobmatch/getallcandidateworkflowcardstatus',
  UPDATE_JOB_MATCH_STATE: 'api/jobmatch/updatejobmatchstate',
  GET_PENDING_MEETINGS_AND_ASSESSMENTS: 'api/candidate/getallpendingmeetingsandassessments',
  UPDATE_PENDING_MEETINGS_AND_ASSESSMENTS: 'api/candidate/updatependingmeetingsandassessments',
  GET_FEEDBACK_REPORTS_AVAILABILITY: 'api/jobmatch/getreportavailability',
  GET_REASON_CODES: 'api/jobmatch/getreasoncodes',
  GET_RECRUITER_SCREENING_FEEDBACK_REPORT: 'api/jobmatch/getscreeningfeedbackreport',
  GET_PHONE_SCREEN_FEEDBACK_REPORT: 'api/jobmatch/getphonescreenfeedbackreport',
  GET_INTERVIEW_FEEDBACK_REPORT: 'api/jobmatch/getinterviewfeedbackreport',
  DELETE_JOB_MATCHES: 'api/jobmatch/deletejobmatchesbyids',
  GET_POSSIBLE_JOB_MATCHES_OF_CANDIDATE: 'api/jobmatch/getPossibleJobMatchesByCandidate',
  GET_OTHER_JOB_MATCHES: 'api/jobmatch/getotherjobmatchdetails',
  GET_JOB_MATCH_DETAILS: 'api/jobmatch/getJobmatchDetails',
  UPDATE_PHONE_SCREEN_OR_INTERVIEW_IN_PROGRESS: 'api/candidate/meeting/progress'
};

constructor(@Inject(StorageService) private StorageService:StorageService,
  @Inject(GenericService) private genericService:GenericService, @Inject(AuthService) private authService:AuthService) { 
  // this.setRefreshJobMatchesListener();
}

getDetailedCandidateJobMatches(jobMatchesObject, companyId, page, size, successCallback, errorCallback) {
  let queryString = "";
  if(size !== '' && page !== '') {
      queryString = "?size=" + size + "&page=" + page;
  }
  this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_DETAILED_CANDIDATE_JOB_MATCHES + "/" + companyId + queryString, jobMatchesObject).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

getNotesAndCommunications(jobMatchId, successCallback, errorCallback) {
  this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_NOTES + '/' + jobMatchId + '?companyId=' + this.authService.getUserDetails().company.companyId).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

saveNoteOrCommunication(noteOrCommunication, successCallback, errorCallback) {
  this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.SAVE_NOTES + '/' + noteOrCommunication.jobMatchId, noteOrCommunication).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

deleteNoteOrCommunication(noteOrCommunication, successCallback, errorCallback) {
  this.genericService.deleteObjectById(this.StorageService.get('baseurl') + this.urlConstants.DELETE_NOTES + '/' + noteOrCommunication.jobMatchId + '/' + noteOrCommunication.noteId + '?companyId=' + this.authService.getUserDetails().company.companyId).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

getCandidateJobComparison(jobMatchId, successCallback, errorCallback) {
  this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_CANDIDATE_JOB_COMPARISON + '/' + jobMatchId + '?companyId=' + this.authService.getUserDetails().company.companyId).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

getJobMatchCandidates(userId, companyId, page, size, requisitionId, filterObject, successCallback, errorCallback) {
  page = (_.isNull(page) || _.isUndefined(page)) ? 1 : page;
  size = (_.isNull(size) || _.isUndefined(size)) ? 5 : size;
  requisitionId = (_.isNull(requisitionId) || _.isUndefined(requisitionId)) ? '' : requisitionId;
  let urlParams = "?userId=" + userId + "&companyId=" + companyId + "&page=" + page + "&size=" + size + "&requisitionId=" + requisitionId;
  let promise = this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_JOB_MATCH_CANDIDATES + urlParams, filterObject);
  promise.subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
  return promise;
}

getCandidateJobMatchCard(jobMatchId, companyId, successCallback, errorCallback, userId) {
  let url = this.StorageService.get('baseurl') + this.urlConstants.GET_CANDIDATE_JOB_MATCH_CARD + '/' + jobMatchId + '/' + companyId;
  if(userId !== undefined) {
      url = url + "?loggedInUserId=" + userId;
  }
  this.genericService.getObjects(url).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

setJobMatchStatus(status, jobMatchesDataArray, successCallback, errorCallback) {
  let url = this.StorageService.get('baseurl');
  switch(status) {
      case 'Release':
          url += this.urlConstants.SET_RELEASED;
          break;
      case 'Not Interested':
          url += this.urlConstants.SET_NOT_INTERESTED;
          break;
      default:
          break;
  }
  this.genericService.addObject(url, jobMatchesDataArray).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

moveStepBackward(moveData, successCallback, errorCallback) {
  this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.MOVE_STEP_BACKWARD, moveData).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

moveStepForward(moveData, successCallback, errorCallback) {
  this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.MOVE_STEP_FORWARD, moveData).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

sendAssessment(assessmentType, assessmentData, successCallback, errorCallback) {
  let url = this.urlConstants.SEND_TECH_ASSESSMENT;
  if(assessmentType == 'Value') {
      url = this.urlConstants.SEND_VALUE_ASSESSMENT;
  }
  this.genericService.addObject(this.StorageService.get('baseurl') + url, assessmentData).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

resendAssessment(assessmentData, successCallback, errorCallback) {
  this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.RESEND_ASSESSMENT, assessmentData).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

cancelAssessment(assessmentType, assessmentData, successCallback, errorCallback) {
  let url = this.urlConstants.CANCEL_TECH_ASSESSMENT;
  if(assessmentType == 'Value') {
      url = this.urlConstants.CANCEL_VALUE_ASSESSMENT;
  }
  this.genericService.addObject(this.StorageService.get('baseurl') + url, assessmentData).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

// schedulePhoneScreenOrInterview(type, scheduleData, successCallback, errorCallback) {
//   let url = '';
//   if(type == 'phoneScreen') {
//       url = this.urlConstants.SCHEDULE_PHONE_SCREEN;
//   } else if(type == 'interview') {
//       url = this.urlConstants.SCHEDULE_INTERVIEW;
//   }
//   this.genericService.addObject(this.StorageService.get('baseurl') + url, scheduleData).subscribe((data) => {
//       successCallback(data.body);
//   }, (error) => {
//       errorCallback(error.error);
//   });
// }

getPhoneScreeners(jobMatchId, companyId, userId, getCancelledMeetingsFlag, successCallback, errorCallback) {
  getCancelledMeetingsFlag = _.isUndefined(getCancelledMeetingsFlag) ? false : getCancelledMeetingsFlag;
  let url = this.StorageService.get('baseurl') + this.urlConstants.GET_PHONE_SCREENERS + '/' + jobMatchId + '/' + companyId + '?isCancelledMeetingScheduleRequired=' + getCancelledMeetingsFlag;
  if(!_.isNull(userId)) {
      url = url + "&loggedInUserId=" + userId;
  }
  this.genericService.getObjects(url).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

getInterviewers(jobMatchId, companyId, userId, getCancelledMeetingsFlag, successCallback, errorCallback) {
  getCancelledMeetingsFlag = _.isUndefined(getCancelledMeetingsFlag) ? false : getCancelledMeetingsFlag;
  let url = this.StorageService.get('baseurl') + this.urlConstants.GET_INTERVIEWERS + '/' + jobMatchId + '/' + companyId + '?isCancelledMeetingScheduleRequired=' + getCancelledMeetingsFlag;
  if(!_.isNull(userId)) {
      url = url + "&loggedInUserId=" + userId;
  }
  this.genericService.getObjects(url).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

getMeetingDetails(meetingId, successCallback, errorCallback) {
  this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_MEETING_DETAILS + '/' + meetingId + '/' + this.authService.getUserDetails().company.companyId).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

// rescheduleMeeting(meetingId, meetingObject, successCallback, errorCallback) {
//   this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.RESCHEDULE_MEETING + '/' + meetingId, meetingObject).subscribe((data) => {
//       successCallback(data.body);
//   }, (error) => {
//       errorCallback(error.error);
//   });
// }

cancelMeeting(meetingId, meetingObject, successCallback, errorCallback) {
  this.genericService.deleteObject(this.StorageService.get('baseurl') + this.urlConstants.CANCEL_MEETING + '/' + meetingId, meetingObject).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

getJobMatchRecruiters(jobMatchIdsArray, companyId, successCallback, errorCallback) {
  this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.JOB_MATCH_RECRUITERS + '/' + companyId, jobMatchIdsArray).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

assignRecruiter(jobMatchIdToRecruiterIdMap, successCallback, errorCallback) {
  this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.ASSIGN_RECRUITER, jobMatchIdToRecruiterIdMap).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

createJobMatches(createJobMatchesObject, successCallback, errorCallback) {
  this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.CREATE_JOB_MATCHES, createJobMatchesObject).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

getAllJobMatchStates(successCallback, errorCallback) {
  this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_JOB_MATCH_STATES).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

updateJobMatchState(jobId, companyId, successCallback, errorCallback) {
  this.genericService.addObjectById(this.StorageService.get('baseurl') + this.urlConstants.UPDATE_JOB_MATCH_STATE + '/' + jobId + '/' + companyId).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

getPendingItems(jobMatchIdsArray, successCallback, errorCallback) {
  this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_PENDING_MEETINGS_AND_ASSESSMENTS, jobMatchIdsArray).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

updatePendingItems(jobMatchIdsArray, successCallback, errorCallback) {
  this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.UPDATE_PENDING_MEETINGS_AND_ASSESSMENTS, jobMatchIdsArray).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

getFeedbackReportsAvailability(jobMatchId, companyId, successCallback, errorCallback) {
  let url = this.urlConstants.GET_FEEDBACK_REPORTS_AVAILABILITY + '/' + jobMatchId + "?companyId=" + companyId;
  this.genericService.getObjects(this.StorageService.get('baseurl') + url).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

getReasonCodes(actionTypes, successCallback, errorCallback) {
  this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_REASON_CODES, actionTypes).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

getRecruiterScreeningFeedbackReport(jobMatchId, successCallback, errorCallback) {
  this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_RECRUITER_SCREENING_FEEDBACK_REPORT + '/' + jobMatchId).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

getPhoneScreenFeedbackReport(jobMatchId, successCallback, errorCallback) {
  this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_PHONE_SCREEN_FEEDBACK_REPORT + '/' + jobMatchId).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

getInterviewFeedbackReport(jobMatchId, successCallback, errorCallback) {
  this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_INTERVIEW_FEEDBACK_REPORT + '/' + jobMatchId).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

deleteJobMatches(jobMatches, successCallback, errorCallback) {
  this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.DELETE_JOB_MATCHES, jobMatches).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

getPossibleJobMatchesOfCandidate(filter, successCallback, errorCallback) {
  let url = this.urlConstants.GET_POSSIBLE_JOB_MATCHES_OF_CANDIDATE;
  this.genericService.addObject(this.StorageService.get('baseurl') + url, filter).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

getOtherJobMatchesOfCandidate(filter, successCallback, errorCallback) {
  let url = this.urlConstants.GET_OTHER_JOB_MATCHES;
  this.genericService.addObject(this.StorageService.get('baseurl') + url, filter).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

getJobMatchDetails(candidateInfo, successCallback, errorCallback) {
  this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_JOB_MATCH_DETAILS, candidateInfo).subscribe((data) => {
      successCallback(data.body);
  }, (error) => {
      errorCallback(error.error);
  });
}

getJobAndCandidateWorkLocationsMatchStatus(jobWorkLocations, candidateWorkLocations){
  if(!jobWorkLocations || jobWorkLocations.length === 0){
      return "available";
  }
  if(candidateWorkLocations && candidateWorkLocations.length>0 && jobWorkLocations && jobWorkLocations.length>0) {
      for (let i = 0; i < jobWorkLocations.length; i++) {
          const location = jobWorkLocations[i];
          if(candidateWorkLocations.map(loc => loc.id).includes(location.id)){
              return "match";
              break;
          }
      }
  }
  return "noMatch";
}

updatePhoneScreenOrInterviewInProgress(updateInProgressStatusObj, successCallback, errorCallback) {
    this.genericService.putObject(this.StorageService.get('baseurl') + this.urlConstants.UPDATE_PHONE_SCREEN_OR_INTERVIEW_IN_PROGRESS, updateInProgressStatusObj).subscribe((data) => {
        successCallback(data.body);
    }, (error) => {
        errorCallback(error.error);
    });
}

//shared methods
// onAssociateToJobClick(jobMatch, createJobMatchesObject, candidateName, canAssociateCallback, cannotAssociateCallback) {
//   if(jobMatch.showOverride) {
//       this._confirmOverrideSuspension(jobMatch, createJobMatchesObject, candidateName, canAssociateCallback, cannotAssociateCallback);
//   } else if(jobMatch.allowManualMatch) {
//       if(canAssociateCallback) {
//           canAssociateCallback();
//       }
//   } else {
//       this._showBootboxWarning(jobMatch.reasonForNoManualMatch);
//   }
// } 

// _showBootboxWarning(message) {
//   bootbox.alert({
//       closeButton: false,
//       title: "<div class='alert alert-warning' style='margin-bottom: 0px;'><i class='fas fa-exclamation-triangle fa-fw fa-lg'></i><strong>Cannot associate candidate</strong></div>",
//       message: message,
//       className: "zIndex1060",
//       backdrop: true,
//       onEscape: false,
//       buttons: {
//           ok: {
//               label: 'Ok',
//               className: 'btn-info'
//           }
//       },callback: () => {
//           this.modalService.addModalOpenClassToBodyIfAnyModalIsOpen();
//       }
//   });
// }

// _confirmOverrideSuspension(candidate, createJobMatchesObject, candidateName, canAssociateCallback, cannotAssociateCallback){
//   let message = candidate.reasonForNoManualMatch;
  
//   let type = "release";
//   let allowOverride = candidate.allowOverride;
//   let suspendedMonths = 0;
//   let releaseDate = '';
//   let releasedJobDetails = {};

//   if(candidate.overrideFor === "JOB_LEFT"){
//       type = "jobLeft";   
//       suspendedMonths = candidate.jobLeftDetail.numberOfMonthsToWait;
//       releaseDate = this.$filter('fourdotfiveDateFormat')(candidate.jobLeftDetail.leftAt); 
//       releasedJobDetails = candidate.jobLeftDetail;
//   } else {
//       suspendedMonths = candidate.releasedCandidateDetail.numberOfMonthsToWait;
//       releaseDate = this.$filter('fourdotfiveDateFormat')(candidate.releasedCandidateDetail.releasedAt);
//       releasedJobDetails = candidate.releasedCandidateDetail;
//   }
//   let candidateId = releasedJobDetails.candidateId;
//   // if(type == 'release') {
//   //     message = "Candidate '<span class='emerald'>" + candidateName + "</span>' cannot apply for any position as it is within '<span class='emerald'>" + suspendedMonths + "</span>' month(s) of candidate's previous release. ";
//   //     message = message + "Candidate was previously released on '<span class='emerald'>" + $filter('fourdotfiveDateFormat')(releaseDate) + "</span>' from the role '<span class='emerald'>" + releasedJobDetails.role + "</span>' "; 
//   //     message = message + "with Requisition Number '<span class='emerald'>" + releasedJobDetails.requisitionNumber + "</span>'."
//   // } else {
//   //     message = "Candidate <span class='emerald'>'" + candidateName + "'</span> cannot apply for any position as it is within '<span class='emerald'>" + suspendedMonths + "</span>' month(s) of leaving previous job. ";
//   //     message = message + "Candidate left previous job on '<span class='emerald'>" + $filter('fourdotfiveDateFormat')(releaseDate) + "</span>' from the role '<span class='emerald'>" + releasedJobDetails.role + "</span>' "; 
//   //     message = message + "with Requisition Number '<span class='emerald'>" + releasedJobDetails.requisitionNumber + "</span>'."
//   // }
//   // message = message + "<br>Would you like to override?";
//   if(allowOverride) {

//       bootbox.confirm({
//           closeButton: false,
//           title: "<div class='alert alert-warning' style='margin-bottom: 0px;'><i class='fas fa-exclamation-triangle fa-fw fa-lg'></i><strong>Confirm</strong></div>",
//           message: message,
//           className: "zIndex1060",
//           backdrop: true,
//           onEscape: false,
//           buttons: {
//               confirm: {
//                   label: 'Override',
//                   className: 'btn-info'
//               },
//               cancel: {
//                   label: 'Cancel',
//                   className: 'btn-danger'
//               }
//           },
//           callback: (result) => {
//               if(result) {
//                   this.modalService.addModalOpenClassToBodyIfAnyModalIsOpen();
//                   let warningModal = this.$uibModal.open({
//                       animation: true,
//                       ariaLabelledBy: 'modal-title',
//                       ariaDescribedBy: 'modal-body',
//                       backdrop: 'static',
//                       windowClass: 'four-dot-five-modal-medium',
//                       templateUrl: 'app/partials/missioncontrol/candidateJobMatchesWorkflow/candidateJobMatchesWorkflow/associateCandidates/associate-candidates-action-modal.html',
//                       backdrop: false,
//                       controller: ['$uibModalInstance', 'candidateId', 'candidateName', 'suspendedMonths', 'releaseDate', 'allowOverride', 'releasedJobDetails', 'type', function($uibModalInstance, candidateId, candidateName, suspendedMonths, releaseDate, allowOverride, releasedJobDetails, type) {
//                           var ctrl = this;
//                           ctrl.candidateId = candidateId;
//                           ctrl.candidateName = candidateName;
//                           ctrl.suspendedMonths = suspendedMonths;
//                           ctrl.releaseDate = releaseDate;
//                           ctrl.allowOverride = allowOverride;
//                           ctrl.recruiterDetails = new OverrideNoteDetail(ctrl.candidateId);
//                           ctrl.releasedJobDetails = releasedJobDetails;
//                           ctrl.type = type;
//                           ctrl.modalTitle = "Override";
//                           ctrl.saveCallback = () => {
//                               if(type === 'release') {
//                                   candidate.releasedCandidateDetail.releasedAt = null;
//                               } else {
//                                   candidate.jobLeftDetail.leftAt = null;
//                               }
//                               createJobMatchesObject.overrideNoteDetails.push(ctrl.recruiterDetails);
//                               if(canAssociateCallback) {
//                                   canAssociateCallback();
//                               }
//                               $uibModalInstance.close('cancel');
//                           };

//                           ctrl.closeModal = () => {
//                               // vm.removeCandidate(candidate);
//                               if(cannotAssociateCallback) {
//                                   cannotAssociateCallback();
//                               }
//                               $uibModalInstance.close('cancel');
//                           };
//                       }],
//                       controllerAs: 'associateCandidatesActionModal',
//                       size: 'md',
//                       resolve: {
//                           candidateId: () =>  {
//                               return candidateId;
//                           },
//                           candidateName: () =>  {
//                               return candidateName;
//                           },
//                           suspendedMonths: () =>  {
//                               return suspendedMonths;
//                           },
//                           releaseDate: () =>  {
//                               return releaseDate;
//                           },
//                           allowOverride: () =>  {
//                               return allowOverride;
//                           },
//                           releasedJobDetails: () =>  {
//                               return releasedJobDetails;
//                           },
//                           type: () =>  {
//                               return type;
//                           }
//                       }
//                   });
//               } else {
//                   this.modalService.addModalOpenClassToBodyIfAnyModalIsOpen();
//               }
//           }
//       });
//   } else {
//       let displayHtmlText = "";
//       if(type == 'release') {
//           displayHtmlText = "Candidate '<span class='emerald'>" + candidateName + "</span>' cannot apply for any position as it is within '<span class='emerald'>" + suspendedMonths + "</span>' month(s) of candidate's previous release. ";
//           displayHtmlText = displayHtmlText + "Candidate was previously released on '<span class='emerald'>" + this.$filter('fourdotfiveDateFormat')(releaseDate) + "</span>' from the role '<span class='emerald'>" + releasedJobDetails.role + "</span>' "; 
//           displayHtmlText = displayHtmlText + "with Requisition Number '<span class='emerald'>" + releasedJobDetails.requisitionNumber + "</span>'."
//       } else {
//           displayHtmlText = "Candidate <span class='emerald'>'" + candidateName + "'</span> cannot apply for any position as it is within '<span class='emerald'>" + suspendedMonths + "</span>' month(s) of leaving previous job. ";
//           displayHtmlText = displayHtmlText + "Candidate left previous job on '<span class='emerald'>" + this.$filter('fourdotfiveDateFormat')(releaseDate) + "</span>' from the role '<span class='emerald'>" + releasedJobDetails.role + "</span>' "; 
//           displayHtmlText = displayHtmlText + "with Requisition Number '<span class='emerald'>" + releasedJobDetails.requisitionNumber + "</span>'."
//       }

//       bootbox.alert({
//           closeButton: false,
//           title: "<div class='alert alert-warning' style='margin-bottom: 0px;'><i class='fas fa-exclamation-triangle fa-fw fa-lg'></i><strong>Cannot associate candidate</strong></div>",
//           message:  displayHtmlText,
//           className: "zIndex1060",
//           buttons: {
//               ok: {
//                   label: 'Ok',
//                   className: 'btn-info'
//               }
//           },
//           callback: function () {
//               if(cannotAssociateCallback) {
//                   cannotAssociateCallback();
//               }
//               this.modalService.addModalOpenClassToBodyIfAnyModalIsOpen();
//           }
//       });
//   }
// }

// setRefreshJobMatchesListener(){
//   this.refreshJobMatchesListener = this.$q.defer();
// }

// raiseRefreshJobMatchesEvent(candidateId){
//   this.refreshJobMatchesListener.notify(candidateId);
// }

// receiveRefreshJobMatchesEvent(){
//   return this.refreshJobMatchesListener.promise;
// } 

// resetRefreshJobMatchesListener(){
//   this.refreshJobMatchesListener.resolve();
//   this.setRefreshJobMatchesListener();
// }


}

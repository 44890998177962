<div class="container">
    <div class="row">
        <div class="col-xs-12">
            <form #cancelAssessmentForm="ngForm" novalidate>
                <div class="row">
                    <div class="col-xs-12 cancel-assessment-statement">
                        <div class="main-box" *ngIf="multipleJobMatchesFlag && loadingMultipleJobMatchesDataFlag">
                            <div class="main-box-body">
                                <div class="text-center" style="padding: 20px;">
                                    Loading candidate information <i class="fa fa-spinner fa-spin"></i>
                                </div>
                            </div>
                        </div>
                        <div class="main-box" *ngIf="multipleJobMatchesFlag && !loadingMultipleJobMatchesDataFlag && jobMatchAssessmentsThatCanBeCancelled.length > 0">
                            <div class="main-box-header">
                                '<span class="emerald">Cancel</span>' <span>{{cancelAssessmentLabel}}</span> '<span class="emerald">{{assessmentName}}</span>' for the following Candidates for role of
                                '<span class="emerald">{{jobMatchAssessmentsThatCanBeCancelled[0].jobMatchData.jobMatchTitle}}</span>' <span *ngIf="jobMatchAssessmentsThatCanBeCancelled[0].jobMatchData.jobMatchClientName">at '<span class="emerald">{{ jobMatchAssessmentsThatCanBeCancelled[0].jobMatchData.jobMatchClientName }}</span>'</span>
                            </div>
                            <div class="main-box-body" style="max-height: 120px; overflow-y: scroll">
                                <div style="display:grid; grid-template-columns: 1fr 1fr 1fr;">
                                    <div *ngFor="let jobMatch of jobMatchAssessmentsThatCanBeCancelled">
                                        <div class="bg-info text-overflow-ellipsis" style="margin: 3px; padding: 3px; padding-left: 8px; border-radius: 5px;">
                                            <strong class="emerald">{{jobMatch.jobMatchData.name}}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="main-box" *ngIf="multipleJobMatchesFlag && !loadingMultipleJobMatchesDataFlag && jobMatchAssessmentsThatCannotBeCancelled.length > 0">
                            <div class="main-box-header">
                                '<span class="red">Cannot Cancel</span>' <span>{{cancelAssessmentLabel}}</span> '<span class="emerald">{{assessmentName}}</span>' for the following Candidates as -
                            </div>
                            <div class="main-box-body" style="max-height: 120px; overflow-y: scroll">
                                <div class="row">
                                    <div class="col-xs-12" *ngFor="let jobMatch of jobMatchAssessmentsThatCannotBeCancelled">
                                        <div class="bg-danger text-overflow-ellipsis" style="margin: 3px; padding: 3px; padding-left: 8px; border-radius: 5px;">
                                            <strong class="red">{{jobMatch.jobMatchData.name}}</strong>
                                            <span>{{jobMatch.cancelErrorMessage}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!multipleJobMatchesFlag && jobMatchAssessmentsThatCanBeCancelled.length > 0">
                            '<span class="emerald">Cancel</span>' <span>{{cancelAssessmentLabel}}</span> '<span class="emerald">{{assessmentName}}</span>' for candidate '<span class="emerald">{{jobMatchAssessmentsThatCanBeCancelled[0].jobMatchData.name}}</span>'
                            for role of '<span class="emerald">{{jobMatchAssessmentsThatCanBeCancelled[0].jobMatchData.jobMatchTitle}}</span>'
                            <span *ngIf="jobMatchAssessmentsThatCanBeCancelled[0].jobMatchData.jobMatchClientName">at '<span class="emerald">{{ jobMatchAssessmentsThatCanBeCancelled[0].jobMatchData.jobMatchClientName }}</span>'</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12">
                        <div class="form-group" [ngClass]="{'has-error': (cancelAssessmentForm.submitted && notes.invalid)}">
                            <label for="notes" class="control-label">
                                Internal Notes<em>*</em> :
                            </label>
                            <textarea #notes="ngModel" name="notes" class="form-control" rows="3"
                                placeholder="Enter your note.." [(ngModel)]="assessmentData.notes"
                                (change)="isSharedRequisition && copyNote && onInternalNoteChanged()"
                                required>
                            </textarea>
                        </div>
                    </div>
                    <div class="col-xs-12" *ngIf="isSharedRequisition">
                        <app-external-notes
                            [form]="cancelAssessmentForm" 
                            [note]="assessmentData.notes" 
                            [copyNote]="copyNote"
                            [externalNote]="assessmentData.externalNote"
                            (onExternalNoteChange)="onExternalNoteChange($event)"
                            (onCopyNoteChange)="onCopyNoteChange($event)">
                        </app-external-notes>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 text-right">
                        <div class="clearfix">
                            <button type="submit" class="btn btn-success" (click)="cancelAssessment(cancelAssessmentResultsModal)" [disabled]="(multipleJobMatchesFlag && jobMatchAssessmentsThatCanBeCancelled.length == 0) || savingFlag">
                                <span *ngIf="!savingFlag">Cancel Assessment</span>
                                <span *ngIf="savingFlag">Cancelling..</span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<ng-template #cancelAssessmentResultsModal>
    <div class="ngx-modal-header">
        <h4 class="modal-title pull-left">Cancel Assessment</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="row">
                        <div class="col-xs-12">
                            <div class="main-box">
                                <div class="main-box-header text-center">
                                    <h4>Assessment 'Cancel' Status by Candidate</h4>
                                </div>
                                <div class="main-box-body" style="max-height: 250px; overflow-y: scroll;">
                                    <div class="row">
                                        <div class="col-xs-12" *ngFor="let jobMatch of cancelResultsArray">
                                            <div [ngClass]="{'bg-success': jobMatch.isSuccess, 'bg-danger': !jobMatch.isSuccess}" style="margin: 3px; padding: 3px; padding-left: 8px; border-radius: 5px;">
                                                <span class="fa fa-2x" [ngClass]="{'fa-check green': jobMatch.isSuccess, 'fa-times red': !jobMatch.isSuccess}"></span>
                                                <strong [ngClass]="{'green': jobMatch.isSuccess, 'red': !jobMatch.isSuccess}">{{jobMatch.name}}</strong> -
                                                <span>{{jobMatch.status}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 text-right">
                            <div class="clearfix">
                                <button type="button" class="btn btn-info pull-right" style="margin-left: 5px" (click)="closeModal()">
                                    <span>Close</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>


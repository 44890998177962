import { Inject, Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class FeedbackAuthoringService {

  urlConstants: any = {
    GET_FEEDBACK_QUESTIONS: 'api/feedback/getfeedback',
    SAVE_FEEDBACK_QUESTIONS: 'api/feedback/savefeedBack'
  };
  constructor(@Inject(StorageService) private StorageService: StorageService, @Inject(GenericService) private genericService:GenericService) { }

  getFeedbackQuestions(currentCompanyId, companyId, feedbackType, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_FEEDBACK_QUESTIONS + '/' + companyId + '/' + feedbackType + '?currentCompanyId=' + currentCompanyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  saveQuestions(companyId, feedbackType, questionsArray, currentCompanyId, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.SAVE_FEEDBACK_QUESTIONS + '/' + companyId + '/' + feedbackType + '?currentCompanyId=' + currentCompanyId, questionsArray).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }
}

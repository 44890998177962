<style>
    .modal-backdrop.fade {
        opacity: 0;
    }

    .modal-backdrop {
        position: initial;
    }
</style>
<header class="navbar navbar-default navbar-fixed-top" id="header-navbar">
    <div class="container p-0 m-0">
        <a href="" id="logo" class="navbar-brand">
            <img src="assets/app-content/img/logo-old.png" alt="" class="normal-logo logo-white" >
            <img src="assets/app-content/img/logo-black.png" alt="" class="normal-logo logo-black" >
            <img src="assets/app-content/img/logo-small.png" alt="" class="small-logo hidden-xs hidden-sm hidden" >
        </a>
    </div>
</header>
<app-browser-refresh-message></app-browser-refresh-message>
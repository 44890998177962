import { Directive, Input, Output, ElementRef, Injector, SimpleChanges, EventEmitter } from '@angular/core';
import { UpgradeComponent } from "@angular/upgrade/static";

// This Angular directive will act as an interface to the "upgraded" AngularJS component
@Directive({ selector: 'user-notifications' })
export class UserNotificationsComponentWrapper extends UpgradeComponent {
    // The names of the input and output properties here must match the names of the
    // `<` and `&` bindings in the AngularJS component that is being wrapped
    @Input() height: any;
    @Input() width: any;
    @Input() numberOfNewNotifications: any;
    @Input() searchObject: any;
    @Input() viewAllFlag: any;
    @Input() viewAllMode: any;
    @Input() context: any;
    @Input() userNotificationControls: any;
    @Input() showFilters: any;
    @Input() showShortNotification: any;
    @Input() notificationClicked: any;
    @Input() fullView: any;

    constructor(elementRef: ElementRef, injector: Injector) {
        // We must pass the name of the directive as used by AngularJS to the super
        super('userNotifications', elementRef, injector);
    }
    ngOnInit() {
        super.ngOnInit();
    }
    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
    }
    ngDoCheck() { super.ngDoCheck(); }
    ngOnDestroy() { super.ngOnDestroy(); }
}
<form #addUpdateContactForm="ngForm" name="addUpdateContactForm" novalidate *ngIf="!loadingContactInfoFlag">
    <div class="row p-3">
        <div class="col-xs-12">
            <div class="form-group col-xs-6"
                [ngClass]="{'has-error': (addUpdateContactForm.submitted && firstName.invalid), 'col-xs-4': showNoCountryCodeAvailableOptionFlag}">
                <label for="firstName" class="control-label">
                    First Name<em>*</em> :
                </label>
                <input #firstName="ngModel" type="text" name="firstName" class="form-control"
                    [(ngModel)]="contactObject.firstName" [pattern]="MESSAGECONSTANTS.VALIDATIONPATTERNS.NAME" required>
                <small class="error"
                    *ngIf="addUpdateContactForm.submitted && (firstName.invalid || firstName.errors?.pattern) && !firstName.errors?.required">
                    {{MESSAGECONSTANTS.COMMONVALIDATIONS.FIRST_NAME}}
                </small>
                <small class="error" *ngIf="firstName.errors?.required">
                    First Name is mandatory.
                </small>
            </div>
            <div class="form-group col-xs-6"
                [ngClass]="{'has-error': (addUpdateContactForm.submitted && lastName.invalid), 'col-xs-4': showNoCountryCodeAvailableOptionFlag }">
                <label for="lastName" class="control-label">
                    Last Name<em>*</em> :
                </label>
                <input #lastName="ngModel" type="text" name="lastName" class="form-control"
                    [(ngModel)]="contactObject.lastName" [pattern]="MESSAGECONSTANTS.VALIDATIONPATTERNS.NAME" required>
                <small class="error"
                    *ngIf="addUpdateContactForm.submitted && (lastName.invalid || lastName.errors?.pattern ) && !lastName.errors?.required">
                    {{MESSAGECONSTANTS.COMMONVALIDATIONS.LAST_NAME}}
                </small>
                <small class="error" *ngIf="lastName.errors?.required">
                    Last Name is mandatory.
                </small>
            </div>
            <div class="form-group position-relative mb-0 col-xs-4" *ngIf="showNoCountryCodeAvailableOptionFlag"
                [ngClass]="{'has-error': (addUpdateContactForm.submitted && email.invalid)}">
                <label for="email" class="control-label">
                    Email<em>*</em> :
                </label>
                <input #email="ngModel" type="email" name="email" class="form-control" placeholder="example@example.com"
                    [(ngModel)]="contactObject.emailId" [pattern]="MESSAGECONSTANTS.VALIDATIONPATTERNS.EMAIL" required>
                <span class="help-block mb-0">ex. example@example.com</span>
                <span *ngIf="!originalContactObject || !originalContactObject.emailId" 
                    class="fa fa-exclamation-circle red candidate-missing-icon ml-2"
                    placement="top" tooltip="Update Missing Email" container="body">
                </span>
                <small class="error"
                    *ngIf="addUpdateContactForm.submitted && (email.invalid || email.errors?.pattern )">
                    {{MESSAGECONSTANTS.COMMONVALIDATIONS.NOT_VALID_EMAIL}}
                </small>
            </div>
        </div>
        <div class="col-xs-12">
            <div class="form-group mb-0 col-xs-12" style="position: absolute; left: 125px; top:-2px; z-index: 1;"
                *ngIf="showNoCountryCodeAvailableOptionFlag">
                <div class="radio" style="display: inline;">
                    <input type="radio" name="countryCode" [value]="true" id="withCountryCode"
                        [(ngModel)]="contactObject.mobilePhoneCountryCodePresent"
                        (change)="onMobilePhoneCountryCodePresentChange()">
                    <label for="withCountryCode">
                        With Country Code
                    </label>
                </div>
                <div class="radio" style="display: inline;">
                    <input type="radio" name="countryCode" [value]="false" id="withoutCountryCode"
                        [(ngModel)]="contactObject.mobilePhoneCountryCodePresent"
                        (change)="onMobilePhoneCountryCodePresentChange()">
                    <label for="withoutCountryCode">
                        Without Country Code
                    </label>
                </div>
            </div>
            <div class="form-group mb-0 col-xs-6"
                *ngIf="!showNoCountryCodeAvailableOptionFlag || contactObject.mobilePhoneCountryCodePresent"
                [ngClass]="{'has-error': (addUpdateContactForm.submitted && isPhoneNumberInvalid)}">
                <label for="candidateContactPhoneNumber" class="control-label">
                    Phone Number<em>*</em> :
                </label>
                <!-- <input
                    type="tel" 
                    id="candidateContactPhoneNumber{{}}" 
                    name="candidateContactPhoneNumber" 
                    class="form-control access" 
                    [(ngModel)]="contactObject.phoneNumber"
                    (change)="phoneNumberChanged()" required>
                <small class="error" *ngIf="isPhoneNumberInvalid">Please enter a valid phone number.</small>
                <span class="help-block" *ngIf="!isPhoneNumberInvalid">ex. <span>{{phoneNumberFormat}}</span></span> -->

                <angular-four-dot-five-phone-number [elementId]="candidateContactPhoneNumber"
                    [valueField]="contactObject.phoneNumber" (valueFieldChange)="contactObject.phoneNumber=$event"
                    (isPhoneNumberInvalid)="isPhoneNumberInvalid=$event" (phoneNumberFormat)="phoneNumberFormat=$event">
                </angular-four-dot-five-phone-number>
                <small class="error" *ngIf="isPhoneNumberInvalid">Please enter a valid phone number.</small>
                <span class="help-block" *ngIf="!isPhoneNumberInvalid">ex. <span>{{phoneNumberFormat}}</span></span>
                <span *ngIf="!originalContactObject || !originalContactObject.phoneNumber || originalContactObject.phoneNumber == ''" 
                    class="fa fa-exclamation-circle red candidate-missing-icon ml-2"
                    placement="top" tooltip="Update Missing Phone number" container="body">
                </span>
                <!-- <small class="error" *ngIf="(candidateContactPhoneNumber.dirty || addUpdateContactForm.submitted) && candidateContactPhoneNumber.invalid">Please enter a valid phone number.</small> -->
            </div>
            <div class="form-group col-xs-6"
                *ngIf="showNoCountryCodeAvailableOptionFlag && !contactObject.mobilePhoneCountryCodePresent"
                [ngClass]="{'has-error': (addUpdateContactForm.submitted && candidateContactPhoneNumberNoCountryCode.invalid)}">
                <label class="control-label">
                    Phone Number<em>*</em> :
                </label>
                <input id=candidateContactPhoneNumberNoCountryCode type="text" style="display: block"
                    class="form-control" [(ngModel)]="contactObject.phoneNumber"
                    placeholder="Phone number without country code" name="candidateContactPhoneNumberNoCountryCode"
                    maxlength="15" required>
                <span *ngIf="!originalContactObject || !originalContactObject.phoneNumber || originalContactObject.phoneNumber == ''" 
                    class="fa fa-exclamation-circle red candidate-missing-icon ml-2"
                    placement="top" tooltip="Update Missing Phone number" container="body">
                </span>
            </div>


            <!-- <div class="error form-group mb-0 col-xs-12" *ngIf="(addUpdateContactForm.candidateContactPhoneNumber.$dirty || addUpdateContactForm.$submitted) && addUpdateContactForm.candidateContactPhoneNumber.$invalid">
                <small class="error">Please enter a valid phone number.</small>
            </div> -->
            <div class="form-group mb-0 col-xs-6" *ngIf="!showNoCountryCodeAvailableOptionFlag"
                [ngClass]="{'has-error': (addUpdateContactForm.submitted && email.invalid)}">
                <label for="email" class="control-label">
                    Email<em>*</em> :
                </label>
                <input #email="ngModel" type="email" name="email" class="form-control" placeholder="example@example.com"
                    [(ngModel)]="contactObject.emailId" [pattern]="MESSAGECONSTANTS.VALIDATIONPATTERNS.EMAIL" required>
                <span class="help-block">ex. example@example.com</span>
                <span *ngIf="!originalContactObject || !originalContactObject.emailId" 
                    class="fa fa-exclamation-circle red candidate-missing-icon ml-2"
                    placement="top" tooltip="Update Missing Email" container="body">
                </span>
                <small class="error"
                    *ngIf="addUpdateContactForm.submitted && ( email.invalid || email.errors?.pattern )">
                    {{MESSAGECONSTANTS.COMMONVALIDATIONS.NOT_VALID_EMAIL}}
                </small>
            </div>
        </div>
        <div class="col-xs-12">
            <manage-entity-location *ngIf="showAdditionalDetailFlag" entityType="candidateId" [showSave]="false"
                [isRequired]="false" [location]="contactObject.address" context="candidateJobMatchCard"
                (saveCallback)="saveLocationCallback($event)" (cancelCallback)="cancelCallback()">
            </manage-entity-location>
        </div>
        <div class="col-xs-12 text-right">
            <div class="clearfix">
                <button type="button" class="btn btn-danger mr-2" (click)="cancelCallback.emit()" [disabled]="savingFlag">
                    <span>Cancel</span>
                </button>
                <button type="submit" class="btn btn-success" (click)="updateContactDetails()" [disabled]="savingFlag">
                    <span *ngIf="!savingFlag">
                        Update
                        <span *ngIf="updateContactType == 'phone'">Phone Number</span>
                        <span *ngIf="updateContactType == 'email'">Email</span>
                    </span>
                    <span *ngIf="savingFlag">Updating..</span>
                </button>
            </div>
        </div>
    </div>
</form>

<div class="row main-box" *ngIf="loadingContactInfoFlag">
    <div class="col-xs-12 loading-spinner text-center" style="height:400px">
        <span>Loading</span>
        <span class="fa fa-spinner fa-spin"></span>
    </div>
</div>
export class ActivityFilter {
    userId: any = null;
    candidateId: any = null;
    jobMatchId: any = null;
    companyId: any = null;
    clientOrBuId: any = null;
    title: any = null;
    assessmentName: any = null;
    activityType: any = null;
    startDate: any = null;
    endDate: any = null;
    currentStep: any = null;
    createdBy: any = null;
    page: any = null;
    size: any = null;
    column: any = null;
    sortDir: any = null;
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CandidateJobMatchesPopoverComponent } from './candidate-job-matches-popover/candidate-job-matches-popover.component';
import { WorkLocationsIconModule } from '../work-locations-icon/work-locations-icon.module';
import { TooltipModule } from 'ngx-bootstrap';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { NotesAndCommunicationModule } from './notes-and-communication/notes-and-communication.module';
import { CandidateCardIconsModule } from '../candidate-card-icons/candidate-card-icons.module';

@NgModule({
  declarations: [CandidateJobMatchesPopoverComponent],
  imports: [
    CommonModule,
    WorkLocationsIconModule,
    TooltipModule,
    PipesModule,
    NotesAndCommunicationModule,
    CandidateCardIconsModule
  ],
  exports: [CandidateJobMatchesPopoverComponent],
  entryComponents: [CandidateJobMatchesPopoverComponent]
})
export class CandidateActionsModule { }

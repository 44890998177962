<style> 
    /* .radio input[type=radio]:hover+label:before{
        border : 2px solid #e7ebee;
    }
    .radio input[type=radio]:checked+label:before{
        border : 2px solid #03a9f4;
    } */
</style>
<div class="approw">
<div class="row">
   <div class="col-lg-12">
       <ol class="breadcrumb">
           <li> <a (click)="goToDashboard()">{{MESSAGECONSTANTS.BREADCRUMBS.HOME}}</a></li>
           <li class="active"><span>Role Entity Scope</span></li>
       </ol>
       <h1 class="heading-css">Role Entity Scope</h1>
   </div>
   <div class="col-lg-12" *ngIf="transactionRoleEntityScope != undefined && transactionRoleEntityScope.disabled">
       <span class="help-block res-message">No BU has been setup till now !</span>
   </div>

   <div class="col-lg-12 has-error">
       <span class="help-block res-error-message" *ngIf="roleEntityForm.submitted && roleEntityForm.invalid">
           <em>*</em> {{MESSAGECONSTANTS.COMMONVALIDATIONS.ENTER_MANDATORY_FIELDS}}
       </span>
   </div>
</div>
<div class="row">
   <div role="dialog" class="res-container" aria-hidden="false">
       <form  #roleEntityForm="ngForm" name="roleEntityForm" (ngSubmit)="roleEntityForm.form.valid && saveCompanyRES()" novalidate>
           <div class="wizard-cards">
               <div class="wizard-card-container" *ngIf="roleEntityScopeLoading">
                   <div class="col-lg-12" style="height: 150px; display: block;" *ngFor="let key of transactionRoleEntityScope.scopeList; let i = index">
                       <div *ngIf="!((key.role.description == 'Staffing Admin' || key.role.description == 'Corporate Admin') && isCompanyAdmin)">
                        <h3 style="padding-bottom: 0.5%;">{{key.role.description}}</h3>
                       <div class="form-group" *ngIf="transactionRoleEntityScope.companyType === 'StaffingCompany'">
                           <div class="row">
                               <div class="col-md-12">
                                   <div class="col-md-6">
                                       <h5>Clients <em>*</em> :</h5>
                                   </div>
                                   <div class="col-md-6">
                                       <div class="radio">
                                           <input id="clientsall_{{key.role.id}}" type="radio" name="clientsall_{{key.role.id}}" (click)="changeAllForClient(i)"
                                               [(ngModel)]="key.allClientsOrBU" [value]="true" required>
                                           <label for="clientsall_{{key.role.id}}">All</label>
                                           <input id="clientslist_{{key.role.id}}" type="radio" name="clientslist_{{key.role.id}}" (click)="changeListForClient(i)"
                                               [(ngModel)]="key.clientOrBUList" [value]="true" required>
                                           <label for="clientslist_{{key.role.id}}">List</label>
                                       </div>
                                   </div>
                               </div>
                               <div class="col-md-12">
                                   <div class="col-md-6">
                                       <h5>Client's BU <small>(If present)</small> <em>*</em> :</h5>
                                   </div>
                                   <div class="col-md-6">
                                       <div class="radio">
                                           <input id="busall_{{key.role.id}}" type="radio" name="busall_{{key.role.id}}" (click)="changeAllForBU(i)"
                                               [(ngModel)]="key.allBUsOfClients" [value]="true" required>
                                           <label for="busall_{{key.role.id}}">All</label>
                                           <input id="buslist_{{key.role.id}}" type="radio" name="buslist_{{key.role.id}}" (click)="changeListForBU(i)"
                                               [(ngModel)]="key.buListOfClients" [value]="true" required>
                                           <label for="buslist_{{key.role.id}}">List</label>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div class="form-group" *ngIf="transactionRoleEntityScope.companyType === 'Corporation'">
                           <div class="col-md-12">
                               <div class="col-md-6">
                                   <h5>BU <em *ngIf="transactionRoleEntityScope.disabled!= undefined && !transactionRoleEntityScope.disabled">*</em> :</h5>
                               </div>
                               <div class="col-md-6">
                                   <div class="radio" [ngClass]="{'nopointerevents' : transactionRoleEntityScope.disabled}">
                                       <input id="buall_{{key.role.id}}" type="radio" name="buall_{{key.role.id}}" [required]="!transactionRoleEntityScope.disabled"
                                           [readonly]="transactionRoleEntityScope.disabled" [disabled]="transactionRoleEntityScope.disabled"
                                           (click)="changeAllForClient(i)" [(ngModel)]="key.allClientsOrBU"
                                           [value]="true">

                                       <label for="buall_{{key.role.id}}" [ngClass]="{'res-disable':transactionRoleEntityScope.disabled}">All</label>

                                       <input id="bulist_{{key.role.id}}" type="radio" name="bulist_{{key.role.id}}" [required]="!transactionRoleEntityScope.disabled"
                                           [readonly]="transactionRoleEntityScope.disabled" [disabled]="transactionRoleEntityScope.disabled"
                                           (click)="changeListForClient(i)" [(ngModel)]="key.clientOrBUList"
                                           [value]="true">

                                       <label for="bulist_{{key.role.id}}" [ngClass]="{'res-disable':transactionRoleEntityScope.disabled}">List</label>
                                   </div>
                               </div>
                           </div>
                       </div>
                       </div>
                   </div>
               </div>
           </div>

           <div class="wizard-buttons-container">
               <div class="btn-group-single pull-right" *ngIf="roleEntityScopeLoading">
                   <button class="btn btn-danger btn-cancel mr-4" (click)="cancelChanges()" type="button" [disabled]="transactionRoleEntityScope != undefined && transactionRoleEntityScope.disabled">Cancel</button>
                   <!--<button class="btn wizard-next btn-primary" (click)="save()" type="button">Save</button>-->
                   <input class="btn wizard-next btn-primary" type="submit" [disabled]="transactionRoleEntityScope != undefined && transactionRoleEntityScope.disabled" value="Save" />
               </div>
           </div>
       </form>
   </div>
</div>
</div>
export class JobMatchAction {
    action: any;
    actionType: any;
    allowed: any;
    message: any;
    constructor(action, actionType, allowed, message) {
        this.action = action;
        this.actionType = actionType;
        this.allowed = allowed;
        this.message = message;
    }
}
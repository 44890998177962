import { Component, OnInit, Input, Output, EventEmitter, TemplateRef, Inject } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ModalConfig } from 'src/app/core/models/modalConfig';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { EmployeeService } from 'src/app/core/services/employee.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { UserRoleService } from 'src/app/core/services/user-role.service';
import { EmployeeCardActionsModalComponent } from './employee-card-actions-modal/employee-card-actions-modal.component';
import { AngularModalService } from 'src/app/core/services/modal.service';
import { StateService } from '@uirouter/angular';

declare var libphonenumber: any;

@Component({
  selector: 'app-employee-card',
  templateUrl: './employee-card.component.html',
  styleUrls: ['./employee-card.component.css']
})
export class EmployeeCardComponent implements OnInit {

  @Input() employeeId;
  @Input() activityMap;
  @Output() deleteCallback = new EventEmitter<any>();

  employeeCardActionsModal: BsModalRef

  recentActivitiesControls: any = {};
  employeeInfo: any = null;
  currentCompanyName: String = '';
  companyState: String = '';


  loadingFlag: boolean = false;
  showMakeApplicationUserAction: boolean = true;
  showRecentActivitiesFlag: boolean = true;
  isActivityClicked: boolean = false;
  isCorporateFlag: boolean;

  constructor(
    private authService: AuthService,
    private employeeService: EmployeeService,
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private userRoleService: UserRoleService,
    private sharedService: SharedService,
    private angularModalService: AngularModalService,
    private modalService: BsModalService,
    @Inject('$state') private $state: StateService) {

  }


  ngOnInit() {
    this.recentActivitiesControls.getActivities = null;
    this.recentActivitiesControls.activityClickCount = 0;

    this.isCorporateFlag = (this.authService.getUserDetails().company.companyType == 'Corporation');
    this.currentCompanyName = this.authService.getUserDetails().company.companyName;
    this.companyState = this.authService.getUserDetails().company.companyState;
    this.getEmployeeCardInfo();
    this._setCardActions();
    if (_.isUndefined(this.activityMap)) {
      this.activityMap = [];
    }
  }

  getEmployeeCardInfo() {
    this.loadingFlag = true;
    this.employeeService.getEmployee(this.employeeId, (data) => {
      data.name = data.firstName;
      data.name += ' ' + (_.isNull(data.lastName) ? '' : data.lastName);
      data.organizationManagerName = null;
      if (!_.isNull(data.organizationManager)) {
        data.organizationManagerName = data.organizationManager.firstName;
        data.organizationManagerName = _.isNull(data.organizationManager.lastName) ? data.organizationManagerName : data.organizationManagerName + ' ' + data.organizationManager.lastName;
      }

      if (data.phoneNumber.startsWith("+")) {
        let phoneNumberObject = libphonenumber.parse(data.phoneNumber.toString());
        if (phoneNumberObject.phone !== undefined) {
          data.phoneNumber = libphonenumber.format(phoneNumberObject.phone, phoneNumberObject.country, 'International');
        }
      }
      data.displayActions = !data.canNotMakeApplicationUser && !data.canNoDelete && !data.canNotEdit;

      this.employeeInfo = data;
      this.loadingFlag = false;
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  _setCardActions() {
    this.showMakeApplicationUserAction = this.userRoleService.hasAccessByScreenId();
  }

  editEmployee() {
    this.$state.go('missioncontrol.employeeManagement', {
      employeeId: this.employeeInfo.id,
      employeeName: this.employeeInfo.name
    }, { reload: true });
  }

  makeApplicationUser() {
    if (this.employeeInfo.organizationalManager) {
      let title: String = "Make Application User";
      let message: String = "There are other Employees reporting to this Employee. Please re-assign or delete those Employees before attempting to make this Employee an Application User.";
      this.alertsAndNotificationsService.showAlertWithCallBack(title, message, 'warning', () => {
        // this.angularModalService.addModalOpenClassToBodyIfAnyModalIsOpen();
      });
    } else {
      this.performAction('makeApplicationUser', 'Make Application User');
    }
  }

  performAction(type, title) {
    const config = new ModalConfig();
    const modalData: any = {
      type: type,
      title: title,
      employeeId: this.employeeId,
      employeeInfo: this.employeeInfo,
      context: 'employee',
      activityMap: this.activityMap
    };
    let modalClass = (type === 'activities') ? 'custom-modal-xl' : 'modal-lg';
    config.class = `${modalClass} custom-ngx-modal`;
    config.initialState = modalData;
    config.ignoreBackdropClick = true;
    this.angularModalService.addModalOpenClassToBodyIfAnyModalIsOpen();
    this.employeeCardActionsModal = this.modalService.show(EmployeeCardActionsModalComponent, config);
    this.employeeCardActionsModal.content.onClose.subscribe(result => {
      if (result) {
        if (type === "deleteEmployee") {
          if (this.deleteCallback) {
            this.deleteCallback.emit();
          }
        } else if (type === "makeApplicationUser") {
          this.$state.go('missioncontrol.usermanagement', { userId: result.userObject.userId, accessMode: 'edit' }, { reload: true });
        }
      }
    });
  }

  deleteEmployee() {
    this._launchDeleteEmployeeModal();
  }

  _launchDeleteEmployeeModal() {
    this.performAction('deleteEmployee', 'Delete Employee');
  }

  showRecentActivities() {
    this.recentActivitiesControls.activityClickCount++;
    this.isActivityClicked = !this.isActivityClicked;
  }

  showAllActivityModal() {
    this.performAction('activities', 'Activities');
  }

  cardMenuToggled(openFlag, id) {
    if (openFlag) {
      setTimeout(() => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
      }, 200);
    }
  }

}

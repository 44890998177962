import {Inject, Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class StateManagementStorageService {

  constructor(@Inject(StorageService) private StorageService: StorageService) { }


  setStorage(key, value) {
    let json;
    json = (_.isUndefined(value) || _.isNull(value)) ? null : JSON.stringify(value);
    return localStorage.setItem(key, json);
  }

  getStorage(key) {
    return JSON.parse(localStorage.getItem(key));
  }

  clear() {
    let key, _results;
    _results = [];
    for (key in localStorage) {
      let stringPosition = parseInt(key.search('-'));
      let requiredScreenToRetain = key.slice(0, stringPosition);
      if (requiredScreenToRetain == 'requisitionsScreen' || requiredScreenToRetain == 'candidatesScreen' || requiredScreenToRetain == 'baseurl') {
        // Retain the Screen Filter Data
      } else {
        _results.push(this.setStorage(key, null));
      }
    }
    this.StorageService.set('baseurl', '', null);
    return _results;
  }

  stateHistory(value) {
    if (_.isUndefined(value) || _.isNull(value)) {
      value = null;
    }
    return this.accessor('stateHistory', value);
  }

  accessor(name, value) {
    if (_.isUndefined(value) || _.isNull(value)) {
      return this.getStorage(name);
    }
    return this.setStorage(name, value);
  }

  setScreenState(screen, state) {
    return this.setStorage(screen, state);
  }

  getScreenState(screen) {
    return this.getStorage(screen);
  }

  clearStatesOfScreens() {
    // localStorage.removeItem('requisitionsScreen');
    // localStorage.removeItem('candidatesScreen');
    localStorage.removeItem('requisitionPerformanceReportForAll');
    localStorage.removeItem('requisitionPerformanceReportForClient');
    localStorage.removeItem('requisitionPerformanceReportForInternalRecruitment');
    localStorage.removeItem('requisitionPerformanceReportForVendor');
  }

  clearStateScreen(screen) {
    localStorage.removeItem(screen);
  }
  
}

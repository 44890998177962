import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationPreferencePageComponent } from './notification-preference-page.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [NotificationPreferencePageComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [NotificationPreferencePageComponent],
  entryComponents: [NotificationPreferencePageComponent]
})
export class NotificationPreferencePageModule { }

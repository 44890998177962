import { Component, OnInit, Input, Inject } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ModalConfig } from 'src/app/core/models/modalConfig';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AssessmentService } from 'src/app/core/services/assessment.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { JobService } from 'src/app/core/services/job.service';
import { AngularModalService } from 'src/app/core/services/modal.service';
import { AssessmentsActionsModalComponent } from '../assessments-management/assessments-actions-modal/assessments-actions-modal.component';
import { SharedAjsService } from 'src/app/public/app/services/sharedAjsService';

import 'datatables.net';
import 'datatables.net-dt';

@Component({
  selector: 'app-assessments-management',
  templateUrl: './assessments-management.component.html',
  styleUrls: ['./assessments-management.component.css']
})
export class AssessmentsManagementComponent implements OnInit {

  @Input() $transition$: any;

  assessmentsActionsModal: BsModalRef;

  stateParams: any;
  assignedAssessments: any = [];
  allUnAssignedAssessments: any = [];
  unAssignedAssessments: any = [];
  assessmentsToAssign: any = [];
  assessmentsToRemove: any = [];
  companyId: any;
  activityMap: any = [];
  unAssignedAssessmentsColumns: any = [];
  page: any;

  allowSyncAssessments: boolean = false;
  assignInProgressFlag: boolean = false;
  removeInProgressFlag: boolean = false;
  syncInProgressFlag: boolean = false;
  assignedSyncInProgressFlag: boolean = true;
  unAssignedSyncInProgressFlag: boolean = true;
  disableAssignAssessmentsButtonFlag: boolean = false;
  disableRemoveAssessmentsButtonFlag: boolean = false;
  disableSyncAssessmentsButtonFlag: boolean = false;
  isStaffingCompany: boolean;


  constructor(
    private authService: AuthService,
    private companyService: CompanyService,
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private assessmentService: AssessmentService,
    private jobService: JobService,
    private angularModalService: AngularModalService,
    public bsModalService: BsModalService,
    @Inject("$state") public $state: any,
    @Inject('sharedAjsService') public sharedAjsService: SharedAjsService
  ) { }

  ngOnInit() {
    this.stateParams = this.$transition$.params();
    this.companyId = this.authService.getUserDetails().company.companyId
    //flags
    this.allowSyncAssessments = false;
    this.assignInProgressFlag = false;
    this.removeInProgressFlag = false;
    this.syncInProgressFlag = false;
    this.assignedSyncInProgressFlag = true;
    this.unAssignedSyncInProgressFlag = true;
    this.disableAssignAssessmentsButtonFlag = true;
    this.disableRemoveAssessmentsButtonFlag = false;
    this.disableSyncAssessmentsButtonFlag = false;
    this.isStaffingCompany = this.companyService.isStaffingCompany();

    this._setIsSyncAssessmentsAllowed();
    this.getAllUnassignedAssessments();
    this.getAllAssignedAssessments();
    this.listenToAssessmentsSync();
    this._getAllActivityTypes();
    this.unAssignedAssessmentsColumns = [
      { prop: 'testId', name: 'Id' },
      { prop: 'testType', name: 'Type' },
      { prop: 'testName', name: 'Name' },
      { prop: 'duration', name: 'Duration' },
      { prop: 'questions', name: 'Questions' }
    ];
  }

  setDefaultAssessmentTablePage() {
    let page: any = {
      size: 0,
      totalElements: 0,
      totalPages: 0,
      pageNumber: 0
    }
    this.page = page;
    this.page.pageNumber = 0;
    this.page.size = 20;
  }

  private _setUnAssignedAssessmentsDataTable() {
    $(document).ready(function () {
      ($('#unAssignedAssessmentsTable') as any).DataTable({
        search: {
          smart: false
        }
      });
    });
  }

  ngAfterViewInit() {
    this._setUnAssignedAssessmentsDataTable();
    this._setAssignedAssessmentsDataTable();
  }

  private _setAssignedAssessmentsDataTable() {
    $(document).ready(function () {
      ($('#assignedAssessmentsTable') as any).DataTable({
        search: {
          smart: false
        }
      });
    });
  }

  _setIsSyncAssessmentsAllowed() {
    this.companyService.isSyncAssessmentsAvailable(this.companyId, (data) => {
      this.allowSyncAssessments = data;
    }, error => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  getAllUnassignedAssessments() {
    this.unAssignedAssessments = [];
    this.unAssignedSyncInProgressFlag = true;
    this.assessmentService.getAllUnassignedAssessments(this.companyId, (data) => {
      angular.forEach(data, (val, key) => {
        val.addFlag = false;
        val.testName = val.assessmentAliasName;
      });
      this.unAssignedAssessments = data;
      this.unAssignedSyncInProgressFlag = false;
      this._setUnAssignedAssessmentsDataTable();
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  getAllAssignedAssessments() {
    let counter = 0;
    this.assignedAssessments = [];
    let techAssessmentsArray = [];
    let valueAssessmentsArray = [];
    let clientOrBuId = null;
    let isJobWorkflow = false;
    this.assignedSyncInProgressFlag = true;
    this.assessmentService.getAllValueAssessments(this.companyId, clientOrBuId, isJobWorkflow, (data) => {
      angular.forEach(data, (val, key) => {
        val.testName = val.assessmentAliasName;
        val.removeFlag = false;
        if (this.isStaffingCompany) {
          val.companyName = 'Client: ' + val.companyName;
        } else {
          val.companyName = 'BU: ' + val.companyName;
        }
        valueAssessmentsArray.push(val);
      });
      counter++;
      if (counter == 2) {
        this.assignedAssessments = techAssessmentsArray;
        angular.forEach(valueAssessmentsArray, (val, key) => {
          this.assignedAssessments.push(val);
        });
      }
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
    setTimeout(() => {
      this.assessmentService.getAllTechAssessments(this.companyId, clientOrBuId, isJobWorkflow, (data) => {
        angular.forEach(data, (val, key) => {
          val.testName = val.assessmentAliasName;
          val.removeFlag = false;
          if (this.isStaffingCompany) {
            val.companyName = 'Client: ' + val.companyName;
          } else {
            val.companyName = 'BU: ' + val.companyName;
          }
          techAssessmentsArray.push(val);
        });
        counter++;
        if (counter == 2) {
          this.assignedAssessments = valueAssessmentsArray;
          angular.forEach(techAssessmentsArray, (val, key) => {
            this.assignedAssessments.push(val);
          });
        }
        this.assignedSyncInProgressFlag = false;
        this._setAssignedAssessmentsDataTable();
      }, (error) => {
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      });
    }, 250);
  }

  listenToAssessmentsSync() {
    this.sharedAjsService.receiveAssessmentsSyncMessage().then(null, null, (message) => {
      let isSyncComplete = message.includes('completed') ? true : false;
      if (isSyncComplete) {
        this.syncInProgressFlag = false;
        this.disableSyncAssessmentsButtonFlag = false;
      }
    });
  }

  addAssessment(assessment) {
    assessment.addFlag = !assessment.addFlag;
    if (assessment.addFlag) {
      this.assessmentsToAssign.push(assessment.assessmentId);
      this.disableAssignAssessmentsButtonFlag = false;
    } else {
      _.remove(this.assessmentsToAssign, (assessmentId) => {
        return assessmentId == assessment.assessmentId;
      });
      if (this.assessmentsToAssign.length == 0) {
        this.disableAssignAssessmentsButtonFlag = true;
      }
    }
  }

  removeAssessment(assessment) {
    this.assessmentsToRemove.push(assessment);
  }

  openDeleteAssessmentModal(assessment) {
    assessment.removeFlag = !assessment.removeFlag;
    if (assessment.removeFlag) {
      this.performAction('deleteAssessment', 'Remove Assessment', assessment);
    } else {
      _.remove(this.assessmentsToRemove, (assessmentInfo: any) => {
        return assessmentInfo.assessmentId == assessment.assessmentId;
      });
    }
  }

  assignAssessments() {
    let assignObject = {};
    assignObject[this.companyId] = this.assessmentsToAssign;
    this.assignInProgressFlag = true;
    this.disableAssignAssessmentsButtonFlag = true;
    this.assessmentService.assignAssessments(assignObject, (data) => {
      this.getAllUnassignedAssessments();
      this.getAllAssignedAssessments();
      this.assessmentsToAssign = [];
      this.assignInProgressFlag = false;
    }, (error) => {
      this.disableAssignAssessmentsButtonFlag = false;
      this.assignInProgressFlag = false;
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  removeAssessments() {
    if (this.assessmentsToRemove.length == 0) {
      let title = "No Assessments Selected";
      let message = "Please select assessments that have to be removed."
      this.alertsAndNotificationsService.showAlert(title, message, 'warning');
    } else {
      this.removeInProgressFlag = true;
      this.disableRemoveAssessmentsButtonFlag = true;
      let counter = 0;
      angular.forEach(this.assessmentsToRemove, (assessment, key) => {
        let buId = assessment.clientOrBu ? assessment.clientOrBuId : null;
        this.assessmentService.removeAssignment(this.companyId, assessment.assessmentId, buId, (data) => {
          counter++;
          if (counter == this.assessmentsToRemove.length) {
            this.getAllUnassignedAssessments();
            this.getAllAssignedAssessments();
            this.assessmentsToRemove = [];
            this.removeInProgressFlag = false;
            this.disableRemoveAssessmentsButtonFlag = false;
          }
        }, (error) => {
          this.disableRemoveAssessmentsButtonFlag = false;
          this.removeInProgressFlag = false;
          this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
        });
      });
    }
  }

  syncAssessments() {
    this.disableSyncAssessmentsButtonFlag = true;
    this.syncInProgressFlag = true;
    this.assessmentService.syncAssessments(this.companyId, (data) => {
      this._showSyncStartAlert(data.message);
    }, (error) => {
      this.disableSyncAssessmentsButtonFlag = false;
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  _showSyncStartAlert(message) {
    let title = "Sync Started!";
    this.alertsAndNotificationsService.showAlert(title, message, 'success');
  }

  _getAllActivityTypes() {
    this.jobService.getActivityTypes((data) => {
      this.activityMap = [];
      angular.forEach(data, (val, key) => {
        this.activityMap[val.value] = val.name;
      });
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  viewActivities() {
    this.performAction('activities', 'Activities', null);
  }

  performAction(type, title, assessment) {
    const config = new ModalConfig();
    let context = "company";
    let buId = "";
    if (type === 'deleteAssessment') {
      if (assessment.clientOrBu) {
        context = "BU";
        buId = assessment.clientOrBuId;
      }
    }
    const modalData: any = {
      type: type,
      title: title,
      activityMap: this.activityMap,
      companyId: this.companyId,
      buId: buId,
      testOwnerEmail: assessment ? assessment.testOwner : '',
      context: context,
      assessmentId: assessment ? assessment.assessmentId : '',
      isDeleteCredential: false
    };
    let modalClass = (type === 'activities') ? 'custom-modal-xl' : 'modal-lg';
    config.class = `${modalClass} custom-ngx-modal`;
    config.initialState = modalData;
    config.ignoreBackdropClick = true;
    this.assessmentsActionsModal = this.bsModalService.show(AssessmentsActionsModalComponent, config);
    this.assessmentsActionsModal.content.onClose.subscribe(result => {
      if (result) {
        if (type === 'deleteAssessment') {
          if (result.callbackType == 'delete') {
            assessment.removeFlag = true;
            this.removeAssessment(assessment);
          } else {
            assessment.removeFlag = false;
          }
        }
      } else {
        if (type === 'deleteAssessment') {
          assessment.removeFlag = false;
        }
      }
    });
  }

  redirectToUrl(url, isReloadRequired) {
    this.$state.go(url, null, { reload: isReloadRequired });
  }

}

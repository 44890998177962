<div class="container">
    <div class="row main-box">
        <div class="col-xs-12 main-box-body">
            <div class="row" style="margin-top: 10px; margin-bottom: 10px">
                <div class="col-xs-12" style="padding: 10px; padding-top: 5px;">
                    <span>
                        <span>{{assessmentType}} Info of </span>
                        <span *ngIf="loadingJobMatchDetailsFlag" style="padding-left: 10px;">
                            <span class="fa fa-spinner fa-spin"></span>
                        </span>
                        <span *ngIf="!loadingJobMatchDetailsFlag">
                            '<span class="emerald">{{jobMatchData.name}}</span>' for the role of '<span
                                class="emerald">{{jobMatchData.jobMatchTitle}}</span>'
                            <span *ngIf="jobMatchData.jobMatchClientName != null">at '<span
                                    class="emerald mr-1">{{jobMatchData.jobMatchClientName}}</span>'</span>
                        </span>
                    </span>
                </div>
            </div>
            <div class="row" *ngIf="!loadingFlag">
                <div class="col-xs-12">
                    <div class="main-box" *ngFor="let assessmentDetail of assessmentInfoDetails; let i = index;">
                        <div class="main-box-body"
                            [ngClass]="{'assessment-info-main-box-body-margin': assessmentInfoDetails.length == 1}">
                            <div class="row">
                                <div class="col-xs-12" *ngIf="assessmentDetail.attemptText != null">
                                    <h4 style="display:inline-block" class="mr-2">{{assessmentDetail.attemptText}}</h4>
                                    <ng-template #assessmentNoteTemplate><span
                                            [innerHtml]="assessmentDetail.assessmentNote"></span></ng-template>
                                    <a *ngIf="assessmentDetail.assessmentNote.length>0" class="cursor-pointer"
                                        [popover]="assessmentNoteTemplate" container="body"
                                        containerClass="notes-popover assessment-info-notes-icon" [outsideClick]="true"
                                        placement="top">
                                        <span class="fas fa-clipboard notes-icon"></span>
                                    </a>
                                </div>
                                <div class="col-xs-12">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-4 text-overflow-ellipsis">
                                            <strong>Candidate Email Id:</strong> <span class="mr-1">
                                                {{assessmentDetail.candidateEmail}}</span>
                                        </div>
                                        <div class="col-xs-12 col-sm-4 text-overflow-ellipsis">
                                            <strong>{{assessmentType}}: </strong> <span>
                                                {{assessmentDetail.assessmentName}}</span>
                                        </div>
                                        <div class="col-xs-12 col-sm-4 d-flex">
                                            <div class="text-overflow-ellipsis mr-1">
                                                <strong>Email Delivery Status: </strong>
                                                <span *ngIf="assessmentDetail.isAllMailFailedToDeliver">Not
                                                    Delivered</span>
                                                <span *ngIf="assessmentDetail.isSomeMailFailedToDeliver">Partially
                                                    Delivered</span>
                                                <span *ngIf="assessmentDetail.isNoMailFailedToDeliver">Delivered</span>
                                            </div>
                                            <div class="text-nowrap">
                                                <span class="fas fa-check dark-green ml-1 mr-2"
                                                    *ngIf="assessmentDetail.isNoMailFailedToDeliver"></span>
                                                <ng-template #emailDeliveryTemplate><span
                                                        [innerHtml]="assessmentDetail.emailDeliveryHtmlContent"></span></ng-template>
                                                <span popoverTitle="Email Delivery Details"
                                                    [popover]="emailDeliveryTemplate" placement="left auto"
                                                    containerClass="assessment-info-email-delivery-popover"
                                                    container="body" [outsideClick]="true"
                                                    triggers="mouseenter:mouseleave">
                                                    <span class="fas fa-info-circle text-muted display-block"></span>
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-xs-12">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-4 text-overflow-ellipsis">
                                            <strong>Access Time:</strong> <span class="mr-1">
                                                {{assessmentDetail.assessmentAccessTime}}</span>
                                        </div>
                                        <div class="col-xs-12 col-sm-4 text-overflow-ellipsis">
                                            <strong>Status:</strong> <span class="mr-1">
                                                {{assessmentDetail.candidateCurrentJobState}}</span>
                                        </div>
                                        <div class="col-xs-12 col-sm-4 text-overflow-ellipsis">
                                            <strong>Score:</strong> <span class="mr-1">
                                                {{assessmentDetail.score}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-4 text-overflow-ellipsis" [tooltip]="timeZone"
                                            [adaptivePosition]="false" container="body">
                                            <strong>Timezone:</strong> <span class="mr-1"> {{assessmentDetail.timeZone
                                                }}</span>
                                        </div>
                                        <div class="col-xs-12 col-sm-4 text-overflow-ellipsis">
                                            <strong>Start Time:</strong>
                                            <span class="mr-1"> {{assessmentDetail.startDateTimeDisplayValue }}</span>
                                        </div>
                                        <div class="col-xs-12 col-sm-4 text-overflow-ellipsis">
                                            <strong>End Time:</strong> <span class="mr-1">
                                                {{assessmentDetail.endDateTimeDisplayValue }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12"
                                    *ngIf="assessmentDetail.timeZone != '-' && assessmentDetail.timeZone != browserTimeZone">
                                    <div class="row assessment-info-browser-time-zone-row">
                                        <div class="col-xs-12 col-sm-4 text-overflow-ellipsis"
                                            [tooltip]="browserTimeZone" [adaptivePosition]="false" container="body">
                                            <strong class="assessment-info-browser-time-zone-label">Your Computer's
                                                Timezone:</strong> <span class="mr-1"> {{browserTimeZone}}</span>
                                        </div>
                                        <div class="col-xs-12 col-sm-4 text-overflow-ellipsis">
                                            <strong>Start Time:</strong> <span class="mr-1">
                                                {{assessmentDetail.startDateTimeInBrowserTimezoneDisplayValue}}</span>
                                        </div>
                                        <div class="col-xs-12 col-sm-4 text-overflow-ellipsis">
                                            <strong>End Time:</strong> <span class="mr-1">
                                                {{assessmentDetail.endDateTimeInBrowserTimezoneDisplayValue}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-4 text-overflow-ellipsis">
                                            <strong>No. of Reminders:</strong> <span class="mr-1">
                                                {{assessmentDetail.numberOfRemindersSet}}</span>
                                        </div>
                                        <div class="col-xs-12 col-sm-4 text-overflow-ellipsis">
                                            <strong>Reminders Sent:</strong> <span class="mr-1">
                                                {{assessmentDetail.numberOfReminderSent}}</span>
                                        </div>
                                        <div class="col-xs-12 col-sm-4 text-overflow-ellipsis">
                                            <strong>Last Reminder Sent On:</strong>
                                            <span *ngIf="assessmentDetail.mostRecentReminder == '-'" class="mr-1">
                                                {{assessmentDetail.mostRecentReminder}}</span>
                                            <ng-template #mostRecentRememberTemplate>Here we go: <div>
                                                    {{assessmentDetail.mostRecentReminder |
                                                    fourDotFiveTimeStampFormatPipe}}</div></ng-template>
                                            <span *ngIf="assessmentDetail.mostRecentReminder !== '-'" class="mr-1"
                                                [tooltip]="mostRecentRememberTemplate" [adaptivePosition]="false"
                                                container="body"> {{assessmentDetail.mostRecentReminder |
                                                fourDotFiveTimeStampFormatPipe}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-4 text-overflow-ellipsis" [tooltip]="timeZone"
                                            [adaptivePosition]="false" container="body">
                                            <strong>Timezone:</strong> <span class="mr-1">
                                                {{assessmentDetail.timeZone}}</span>
                                        </div>
                                        <div class="col-xs-12 col-sm-4 text-overflow-ellipsis">
                                            <strong>Assessment Sent On:</strong>
                                            <span class="mr-1"> {{assessmentDetail.sentDateTimeDisplayValue}}</span>
                                        </div>
                                        <div class="col-xs-12 col-sm-4 text-overflow-ellipsis">
                                            <strong>Assessment Completed On:</strong> <span class="mr-1">
                                                {{assessmentDetail.completedDateTimeDisplayValue}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12"
                                    *ngIf="assessmentDetail.timeZone != '-' && assessmentDetail.timeZone != browserTimeZone">
                                    <div class="row assessment-info-browser-time-zone-row">
                                        <div class="col-xs-12 col-sm-4 text-overflow-ellipsis"
                                            [tooltip]="browserTimeZone" [adaptivePosition]="false" container="body">
                                            <strong class="assessment-info-browser-time-zone-label">Your Computer's
                                                Timezone:</strong> <span class="mr-1"> {{browserTimeZone}}</span>
                                        </div>
                                        <div class="col-xs-12 col-sm-4 text-overflow-ellipsis">
                                            <strong>Assessment Sent On:</strong> <span class="mr-1">
                                                {{assessmentDetail.sentDateTimeInBrowserTimezoneDisplayValue}}</span>
                                        </div>
                                        <div class="col-xs-12 col-sm-4 text-overflow-ellipsis">
                                            <strong>Assessment Completed On:</strong> <span class="mr-1">
                                                {{assessmentDetail.completedDateTimeInBrowserTimezoneDisplayValue}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="loadingFlag">
                <div class="col-xs-12 loading-spinner text-center">
                    <span>Loading</span>
                    <span class="fa fa-spinner fa-spin"></span>
                </div>
            </div>
        </div>
    </div>
</div>
import { downgradeComponent } from "@angular/upgrade/static";
import { ValidateOtpPageComponent } from './validate-otp-page.component';

export const ValidateOtpPageAjsModule = angular
    .module('validateOtpPage', [])
    .directive('validateOtpPage', downgradeComponent({ component: ValidateOtpPageComponent }))
    .config(
        ['$stateProvider', ($stateProvider) => {
            $stateProvider.state('validateOtp', {
                url: '/validateOtp',
                component: ValidateOtpPageComponent,
                data: {
                    requireLogin: false
                },
                params: {
                    message: null,
                    userObject: null,
                    loginParamsObject: null
                }
            });
        }
        ])
    .name;
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssessmentsManagementComponent } from './assessments-management.component';
import { TooltipModule } from 'ngx-bootstrap';
import { FormsModule } from '@angular/forms';
import { AssessmentsActionsModalComponent } from './assessments-actions-modal/assessments-actions-modal.component';
import { EntityActivitiesModule } from 'src/app/shared/entity-activities/entity-activities.module';
import { DeleteAssessmentCredentialModule } from './delete-assessment-credential/delete-assessment-credential.module';

@NgModule({
  declarations: [AssessmentsManagementComponent, AssessmentsActionsModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    TooltipModule,
    EntityActivitiesModule,
    DeleteAssessmentCredentialModule
  ],
  exports: [AssessmentsManagementComponent, AssessmentsActionsModalComponent],
  entryComponents: [AssessmentsManagementComponent, AssessmentsActionsModalComponent]
})
export class AssessmentsManagementModule { }

<div class="container main-box py-4">
    <form #addNewCompanyModalForm="ngForm" novalidate>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-12 px-0">
                    <div class="form-group col-xs-6"
                        [ngClass]="{'has-error': (addNewCompanyModalForm.submitted && companyName.invalid) || (!isCompanyName)}">
                        <label for="companyName" class="control-label">
                            Company Name<em>*</em> :
                        </label>
                        <input #companyName="ngModel" type="text" name="companyName" class="form-control"
                            [(ngModel)]="addEditCompanyObject.companyName"
                            [pattern]="MESSAGECONSTANTS.VALIDATIONPATTERNS.NAME" [disabled]="readOnly">
                        <small class="error"
                            *ngIf="(addNewCompanyModalForm.submitted && (companyName.invalid || companyName.errors?.pattern) && !companyName.errors?.required) || (!isCompanyName)">
                            Company name is mandatory
                        </small>
                    </div>
                </div>
                <div class="form-group col-xs-6"
                    [ngClass]="{'has-error': addNewCompanyModalForm.submitted && firstname.invalid}">
                    <label for="firstname" class="control-label">
                        Contact First Name :
                    </label>
                    <input #firstname="ngModel" type="text" name="firstname" class="form-control"
                        [(ngModel)]="addEditCompanyObject.firstname" [pattern]="MESSAGECONSTANTS.VALIDATIONPATTERNS.NAME"
                        [disabled]="readOnly">
                    <small class="error"
                        *ngIf="addNewCompanyModalForm.submitted && (firstname.invalid || firstname.errors?.pattern) && !firstname.errors?.required">
                        {{MESSAGECONSTANTS.COMMONVALIDATIONS.FIRST_NAME}}
                    </small>
                </div>
                <div class="form-group col-xs-6"
                    [ngClass]="{'has-error': addNewCompanyModalForm.submitted && lastname.invalid}">
                    <label for="lastname" class="control-label">
                        Contact Last Name :
                    </label>
                    <input #lastname="ngModel" type="text" name="lastname" class="form-control"
                        [(ngModel)]="addEditCompanyObject.lastname" [pattern]="MESSAGECONSTANTS.VALIDATIONPATTERNS.NAME"
                        [disabled]="readOnly">
                    <small class="error"
                        *ngIf="addNewCompanyModalForm.submitted && (lastname.invalid || lastname.errors?.pattern) && !lastname.errors?.required">
                        {{MESSAGECONSTANTS.COMMONVALIDATIONS.LAST_NAME}}
                    </small>
                </div>
                <div class="form-group mb-0 col-xs-6"
                    [ngClass]="{'has-error': addNewCompanyModalForm.submitted && email.invalid}">
                    <label for="email" class="control-label">
                        Contact Email :
                    </label>
                    <input #email="ngModel" type="email" name="email" class="form-control"
                        [(ngModel)]="addEditCompanyObject.email" [placeholder]="!readOnly ? 'example@example.com' : ''"
                        [pattern]="MESSAGECONSTANTS.VALIDATIONPATTERNS.EMAIL" [disabled]="readOnly">
                    <span class="help-block mb-0"
                        *ngIf="(!addNewCompanyModalForm.submitted || !email.invalid) && !readOnly">
                        ex. example@example.com</span>
                    <small class="error" *ngIf="addNewCompanyModalForm.submitted && email.invalid">
                        {{MESSAGECONSTANTS.COMMONVALIDATIONS.NOT_VALID_EMAIL}}
                    </small>
                </div>
                <div class="form-group mb-0 col-xs-6"
                    [ngClass]="{'': (addNewCompanyModalForm.submitted && (isPhoneNumberInvalid || addEditCompanyObject.phonenumber === undefined || addEditCompanyObject.phonenumber === null))}">
                    <label for="contactPhoneNumber" class="control-label">
                        Contact Phone Number :
                    </label>
                    <angular-four-dot-five-phone-number [elementId]="elementId"
                        (valueFieldChange)="addEditCompanyObject.phonenumber=$event"
                        [valueField]="addEditCompanyObject.phonenumber"
                        (isPhoneNumberInvalid)="isPhoneNumberInvalid=$event"
                        (phoneNumberFormat)="phoneNumberFormat=$event" [readOnly]="readOnly">
                    </angular-four-dot-five-phone-number>
                    <small class="error" *ngIf="isPhoneNumberInvalid">Please enter a valid phone number.</small>
                    <span class="help-block" *ngIf="!isPhoneNumberInvalid && !readOnly">ex.
                        <span>{{phoneNumberFormat}}</span></span>
                </div>
            </div>
        </div>

        <div class="col-xs-12 text-right" *ngIf="!readOnly">
            <div class="clearfix">
                <button type="button" class="btn btn-danger mr-2" (click)="cancel()">
                    <span>Cancel</span>
                </button>
                <button type="submit" class="btn btn-success"
                    (click)="AddEditCompanyDetails('add')" *ngIf="!contactDetails || !contactDetails.companyName">
                        Add Contact
                </button>
                <button type="submit" class="btn btn-success"
                    (click)="AddEditCompanyDetails('edit')" *ngIf="contactDetails && contactDetails.companyName">
                        Ok
                </button>
            </div>
        </div>
    </form>
</div>
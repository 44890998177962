<div class="approw">
    <div class="row">
        <div class="col-lg-12">
            <div id="content-header" class="clearfix">
                <div class="pull-left">
                    <ol class="breadcrumb">
                        <li><a (click)="redirectToUrl('missioncontrol.dashboard', true)">Home</a></li>
                        <li class="active"><span>File Upload</span></li>
                        <li class="active"><span>{{uploadLabel}}</span></li>
                    </ol>
                    <h1 class="heading-css">{{uploadLabel}}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12">
            <app-employee-file-upload *ngIf="uploadType == 'employees'" (cancelCallback)="saveCallback()"
                (completeCallback)="saveCallback()">
            </app-employee-file-upload>
        </div>
    </div>
</div>
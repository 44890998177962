import { Component, OnInit, Inject } from '@angular/core';
import { EmployeesInfiniteScrollContainer } from './employee-infinite-scroll-container';
import { AuthService } from 'src/app/core/services/auth.service';
import { EmployeeService } from 'src/app/core/services/employee.service';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { UserRoleService } from 'src/app/core/services/user-role.service';
import { UserService } from 'src/app/core/services/user.service';
import { JobService } from 'src/app/core/services/job.service';
import { GenericService } from 'src/app/core/services/generic.service';

@Component({
    selector: 'app-employees-page',
    templateUrl: './employees-page.component.html',
    styleUrls: ['./employees-page.component.css']
})
export class EmployeesPageComponent implements OnInit {


    employees: any[];
    searchObject: any = {};
    activityMap: any[];
    currentCompanyId: String = '';
    companyState: String = '';
    employeesContainer: any;

    isCorporateFlag: boolean = false;
    canAddUserAtCompanyLevel: boolean = false;
    canAddUserAtDepartmentOrBULevel: boolean = false;

    constructor(
        private authService: AuthService,
        private employeeService: EmployeeService,
        private alertsAndNotificationsService: AlertAndNotificationsService,
        private userRoleService: UserRoleService,
        private userService: UserService,
        private jobService: JobService,
        private genericService: GenericService,
        @Inject("$state") public $state: any
    ) {

    }

    ngOnInit() {
        //set debounce 
        this.searchEmployees = _.debounce(this.searchEmployees, 500);

        this.searchObject = {
            searchText: '',
            page: 1,
            size: 9,
            companyId: null
        };

        this.currentCompanyId = this.authService.getUserDetails().company.companyId;
        this.isCorporateFlag = (this.authService.getUserDetails().company.companyType == 'Corporation');
        this.companyState = this.authService.getUserDetails().company.companyState;
        this.searchObject.companyId = this.currentCompanyId;
        this.employeesContainer = new EmployeesInfiniteScrollContainer(this.currentCompanyId, this.employeeService, this.alertsAndNotificationsService);
        this._getUserActivityTypes();

        // determine if add user option is allowed for this user
        if (this.userRoleService.isLoggedInUserAnAdmin()) {
            // admin user, add user is allowed in all cases
            this.canAddUserAtCompanyLevel = true;
            this.canAddUserAtDepartmentOrBULevel = true;
        } else {
            // for non admin user
            if (this.isCorporateFlag) {
                // if it is a corporate context
                this.userService.getUserRoleEntityScope((data) => {
                    if (data.allclientOrBU) {
                        // if scope is BU 'All' - can add at company and BU level
                        this.canAddUserAtCompanyLevel = true;
                        this.canAddUserAtDepartmentOrBULevel = true;
                    } else if (data.clientOrBUList.length) {
                        // if scope is BU 'List' - can add only at BU level
                        this.canAddUserAtCompanyLevel = false;
                        this.canAddUserAtDepartmentOrBULevel = true;
                    } else {
                        // cannot add at Company or BU level
                        this.canAddUserAtCompanyLevel = false;
                        this.canAddUserAtDepartmentOrBULevel = false;
                    }
                }, (error) => {
                    this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
                });
            } else {
                // for staffing, as of now employees can be added at all levels by all users
                this.canAddUserAtCompanyLevel = true;
                this.canAddUserAtDepartmentOrBULevel = true;
            }
        }
        this.employeesContainer.getNext();
    }

    _getUserActivityTypes() {
        this.jobService.getActivityTypes((data) => {
            this.activityMap = [];
            angular.forEach(data, (val, key) => {
                this.activityMap[val.value] = val.name;
            });
        }, (error) => {
            this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
        });
    }

    searchEmployees() {
        // clear any existing query requests
        // check for an existing promise and see if it has not been fulfilled yet. If it has not been fulfilled yet, cancel the promise.
        if (!_.isNull(this.employeesContainer.currentPromise) && this.employeesContainer.currentPromise.$$state && this.employeesContainer.currentPromise.$$state.status == 0) {
            this.genericService.cancelRequest(this.employeesContainer.currentPromise);
        }
        // reset the initial value of employees exist flag to true
        this.employeesContainer.employeesExistFlag = true;
        // reset the page number to 1 as this is a new query
        this.employeesContainer.employeesQueryFilter.page = 1;
        // clear the existing employees
        this.employeesContainer.employees = [];
        this.employeesContainer.disableInfiniteScroll = false;
        // wait for the cancel promise to be fulfilled, if it was called.
        setTimeout(() => {
            this.employeesContainer.getNext();
        }, 200);

    }

    addEmployee() {
        this.userService.checkRoleEntityScope((data) => {
            if (data == true) {
                this.$state.go('missioncontrol.employeeManagement', {});
            } else {
                let message = "Please setup the 'Role Scope Definition' for the Company before creating employees."
                this.alertsAndNotificationsService.showCustomConfirm("Setup Role Scope", "Cancel", "Setup Role Scope Definition", message, "warning", () => {
                    this.$state.go('missioncontrol.roleentityscope');
                }, () => { });
            }
        }, (error) => {
            this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
        });
    }

    uploadEmployeeFile() {
        this.userService.checkRoleEntityScope((data) => {
            if (data == true) {
                this.$state.go('missioncontrol.bulkUpload', { uploadType: 'employees' });
            } else {
                let message = "Please setup the 'Role Scope Definition' for the Company before creating employees."
                this.alertsAndNotificationsService.showCustomConfirm("Setup Role Scope", "Cancel", "Setup Role Scope Definition", message, "warning", () => {
                    this.$state.go('missioncontrol.roleentityscope');
                }, () => { });
            }
        }, (error) => {
            this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
        });
    }

    onEmployeeDelete(employee) {
        var employeeIndex = _.findIndex(this.employeesContainer.employees, { 'id': employee.id });
        this.employeesContainer.employees.splice(employeeIndex, 1);
    }

    redirectToUrl(url, isReloadRequired) {
        this.$state.go(url, null, { reload: isReloadRequired });
    }

}

import { Component, OnInit, ViewChild, TemplateRef, Input, Output, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { ModalConfig } from 'src/app/core/models/modalConfig';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { ManageSkillsService } from 'src/app/core/services/manage-skills.service';

declare var Dropzone: any;
declare var $: any;

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.css']
})
export class UploadFilesComponent implements OnInit {
  @Input() modalData;
  @Output() saveCallback = new EventEmitter<any>();
  @Output() cancelCallback = new EventEmitter<any>();

  @ViewChild('viewSkillsSampleFormat', null) viewSkillsSampleFormatRef: TemplateRef<any>;

  showFileFormatModal: BsModalRef;

  fileUploadDropZone: any;
  uploadUrl: any;
  fileExistsFlag: boolean = false;
  disableUploadButtonFlag: boolean = false;
  isFileUploaded: boolean = false;
  fileUploadInProgressFlag: boolean = false;

  constructor(
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private skillsService: ManageSkillsService,
    public bsModalService: BsModalService) { }

  ngOnInit() {
    setTimeout(() => {
      this.fileUploadDropZone = this.createFileUploadDropZone();
    }, 200);
    this.getUploadUrl();
  }

  getUploadUrl() {
    if(this.modalData.type === 'uploadRequisitionEntities') {
      if(this.modalData.action === 'degrees') {
        this.uploadUrl = this.skillsService.getRequisitionEntitiesDegreesUploadUrl();
      } else if(this.modalData.action === 'fieldOfStudy') {
        this.uploadUrl = this.skillsService.getFieldOfStudyUploadUrl();
      } else if(this.modalData.action === 'roles') {
        this.uploadUrl = this.skillsService.getCandidateRolesUploadUrl();
      } else if(this.modalData.action === 'certificates') {
        this.uploadUrl = this.skillsService.getCertificationsUploadUrl();
      }
    } else if(this.modalData.type === 'uploadSkills') {
      this.uploadUrl = this.skillsService.getSkillFileUploadUrl();
    }
  }

  createFileUploadDropZone() {
    let dropZoneSettings = this._getDropZoneSettings();
    let fileUploadDropzone = new Dropzone("form#fileUploadDropzone", dropZoneSettings);
    let vm: any = this;

    // add event handlers for the drop zone
    fileUploadDropzone.on('addedfile', (file, response) => {
      vm.fileExistsFlag = true;
      $('.dz-progress').css({ top: '70%' });
    });

    fileUploadDropzone.on('removedfile', (file, response) => {
      if (vm.fileUploadDropZone.files.length === 0) {
        vm.fileExistsFlag = false;
        vm.fileUploadInProgressFlag = false;
        vm.disableUploadButtonFlag = false;
        // enable adding of further files if it was disabled
        $(".dz-hidden-input").prop("disabled", false);
      }
    });

    fileUploadDropzone.on('sending', (file, xhr, formData) => {
      // disable the upload button to avoid multiple clicks
      vm.disableUploadButtonFlag = true;
      vm.isFileUploaded = false;
      // indicate that file upload is in progress
      vm.fileUploadInProgressFlag = true;
    });

    fileUploadDropzone.on('success', (file, response) => {
      // remove file upload in progress flag
      vm.fileUploadInProgressFlag = false;
      vm.isFileUploaded = true;
      fileUploadDropzone.clickable = false;
      $(".dz-hidden-input").prop("disabled", true);
      if(this.saveCallback) {
        this.saveCallback.emit(response);
      }
    });

    fileUploadDropzone.on("error", (file, error) => {
      let elements = document.querySelectorAll(".dz-file-preview");
      angular.forEach(elements, (element, index) => {
        let filename = element.querySelectorAll(".dz-filename span")[0].textContent;
        let errorMessage = element.querySelectorAll(".dz-error-message span")[0];
        if (filename === file.name) {
          errorMessage.textContent = error.message == undefined ? error : error.message;
        }
      });
      vm.fileUploadInProgressFlag = false;
    });

    return fileUploadDropzone;
  }

  _getDropZoneSettings() {
    let dropZoneSettings = {};
    dropZoneSettings = {
      url: this.uploadUrl,
      paramName: () => {
        return "files";
      },
      acceptedFiles: ".csv,.xlsx,.xls",
      maxFiles: 1,
      maxFilesize: 10,
      maxThumbnailFilesize: 10,
      timeout: 300000,
      addRemoveLinks: true,
      autoProcessQueue: false,
      uploadMultiple: false
    }
    return dropZoneSettings;
  }

  uploadFile() {
    this.fileUploadDropZone.processQueue();
  }

  showFileFormat(template: TemplateRef<any>) {
    const config = new ModalConfig();
    const modalData: any = {};
    config.class = "modal-lg custom-ngx-modal";
    config.initialState = modalData;
    if (this.modalData.type === 'uploadSkills' || this.modalData.type === 'uploadRequisitionEntities') {
      this.showFileFormatModal = this.bsModalService.show(template, config);
    }
  }

  closeFileFormateModal() {
    if (this.showFileFormatModal) {
      this.showFileFormatModal.hide();
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeesPageComponent } from './employees-page.component';
import { FormsModule } from '@angular/forms';
import { EmployeeCardModule } from './employee-card/employee-card.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [EmployeesPageComponent],
  imports: [
    CommonModule,
    FormsModule,
    EmployeeCardModule,
    InfiniteScrollModule
  ],
  exports: [EmployeesPageComponent],
  entryComponents: [EmployeesPageComponent]
})
export class EmployeesPageModule { }

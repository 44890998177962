import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientOrBusPageComponent } from './client-or-bus-page.component';
import { FormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { MomentModule } from 'ngx-moment';
import { DeleteEntityModule } from 'src/app/shared/delete-entity/delete-entity.module';

@NgModule({
  declarations: [ClientOrBusPageComponent],
  imports: [
    CommonModule,
    FormsModule,
    DataTablesModule,
    DeleteEntityModule,
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        'm': 59
      }
    })
  ],
  exports: [ClientOrBusPageComponent],
  entryComponents: [ClientOrBusPageComponent]
})
export class ClientOrBusPageModule { }

<div class="row">
    <div class="col-xs-10 modal-body-font-size ng-scope">
       <p style="margin-left:20px">
            Communications about candidate
            '<span class="emerald ">{{jobMatchInfo.firstName}} {{jobMatchInfo.lastName}}</span>'
            <span >
                for the role 
                <span *ngIf="context == 'candidateJobMatchCard'">
                    '<span class="emerald">{{jobMatchInfo.jobMatchTitle}}</span>'
                    <span *ngIf="jobMatchInfo && jobMatchInfo.jobMatchClientName != null">at '
                        <span class="emerald">{{jobMatchInfo.jobMatchClientName}}</span>'.
                    </span>
                </span>
                <select class="form-control" *ngIf="context == 'candidateCard'" style="display:inline; max-width:400px" name="jobMatch" id="jobMatch" [(ngModel)]="jobMatchId"
                    (change)="onJobMatchSelected()">
                    <option *ngFor="let jobMatch of jobMatches; let i = index" value="{{jobMatch.jobMatchId}}">
                        {{jobMatch.title}} ({{jobMatch.requisitionNumber}}) at <span *ngIf="jobMatch.company !== null">{{jobMatch.company}}</span>
                    </option>
                </select>
            </span>
       </p>
    </div>
    <div class="col-xs-2 text-right">
        <button class="btn btn-primary" (click)="onNewMessageClick()">
            <i class="fa fa-plus"></i>
            New Message
        </button>
    </div>
</div>

<div class="user-chat-container">
    <div class="conversation-wrapper">
        <!-- Filters -->
        <div class="row">
            <!-- From Filter -->
            <div class="col-xs-4 user-chat-filter">
                <div class="form-group">
                    <label for="sentByFilter">
                      Message From:
                    </label>
                    <ng-select class="user-communication-custom-ng-select"
                        name="sentByFilter" 
                        [items]="sentByFilterList" 
                        dropdownPosition="bottom" 
                        bindLabel="name"
                        placeholder="None selected" 
                        [(ngModel)]="selectedFilterSentBy" 
                        [closeOnSelect]="false"
                        [multiple]="true"
                        (change)="onSentByFilterSelected()">
                        <ng-template ng-header-tmp>
                            <div class="d-flex">
                                <div class="mr-2 custom-ng-select-btn" (click)="selectOrDeselectAllClients('recruitersSentBy', 'selectAll')">Select All</div>
                                <div class="custom-ng-select-btn" (click)="selectOrDeselectAllClients('recruitersSentBy', 'deSelectAll')">Select None</div>
                            </div>
                        </ng-template>
                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                            <div class="ng-value d-flex" *ngFor="let item of items | slice:0:1">
                                <span class="ng-value-label">{{item.name.length > 25 ? (item.name.slice(0,25) + '...') : item.name}}</span>
                                <span class="ng-value-icon right"  style="height: fit-content;" (click)="clear(item)" aria-hidden="true">×</span>
                            </div>
                            <div class="ng-value" *ngIf="items.length > 1">
                                <span class="ng-value-label">{{items.length - 1}} more...</span>
                            </div>
                        </ng-template>
                    </ng-select>
                    <div>

                    </div>
                </div>
            </div>
            <!-- To Filter -->
            <div class="col-xs-4 user-chat-filter">
                <div class="form-group">
                    <label for="recipientsFilter">
                        Recipients :
                    </label>
                    <ng-select class="user-communication-custom-ng-select"
                        name="recipientsFilter" 
                        [items]="sendsToFilterList" 
                        dropdownPosition="bottom" 
                        bindLabel="name"
                        placeholder="None selected" 
                        [(ngModel)]="selectedFilterSendsTo" 
                        [closeOnSelect]="false"
                        [multiple]="true"
                        (change)="onSendsToFilterSelected()">
                        <ng-template ng-header-tmp>
                            <div class="d-flex">
                                <div class="mr-2 custom-ng-select-btn" (click)="selectOrDeselectAllClients('recruitersSentTo', 'selectAll')">Select All
                                </div>
                                <div class="custom-ng-select-btn" (click)="selectOrDeselectAllClients('recruitersSentTo', 'deSelectAll')">Select None
                                </div>
                            </div>
                        </ng-template>
                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                            <div class="ng-value d-flex" *ngFor="let item of items | slice:0:1">
                                <span class="ng-value-label">{{item.name.length > 25 ? (item.name.slice(0,25) + '...') : item.name}}</span>
                                <span class="ng-value-icon right"  style="height: fit-content;" (click)="clear(item)" aria-hidden="true">×</span>
                            </div>
                            <div class="ng-value" *ngIf="items.length > 1">
                                <span class="ng-value-label">{{items.length - 1}} more...</span>
                            </div>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <!-- Sort Filter -->
            <div class="col-xs-4 user-chat-filter">
                <div class="form-group">
                    <label for="sortBy" style="margin-bottom: 3px;">Sort By:</label>
                    <div class="multiSelect dropdown" id="sortBy">
                        <button class="dropdown-toggle form-control access inputBoxBorder" type="button"
                            data-toggle="dropdown">
                            <span *ngIf="selectedSort == 'LATEST'">
                                <span>Latest</span>
                            </span>
                            <span *ngIf="selectedSort == 'OLDEST'">
                                <span>Oldest</span>
                            </span>
                            <span class="caret"></span>
                        </button>
                        <ul class="dropdown-menu dropdown-width">
                            <li (click)="onDateSortChange('LATEST')">
                                <a href="javascript:void(0)">
                                    Latest
                                    <span class="fa fa-arrow-up"></span>
                                </a>
                            </li>
                            <li (click)="onDateSortChange('OLDEST')">
                                <a href="javascript:void(0)">
                                    Oldest
                                    <span class="fa fa-arrow-down"></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="conversation-content user-chat-thread-container">
            <div class="conversation-inner" *ngIf="!loadingFlag">
                <div *ngFor="let thread of chatThreads; index as threadIndex" style="margin-top:10px"
                    class="cursor-pointer" id="{{threadIndex + message && message.parentMessageId ? message.parentMessageId : ''}}">
                    <div class="conversation-item user-chat-message"
                        (click)="onThreadClick(thread[0], threadIndex)"
                        [ngClass]="{'item-right': thread[0].isLoggedInUserSender, 'item-left': !thread[0].isLoggedInUserSender, 'item-left': !thread[0].isLoggedInUserSender, 'user-chat-internal': thread[0].chatMessageType == 'INTERNAL', 'user-chat-external': thread[0].chatMessageType == 'EXTERNAL', 'user-chat-active-thread':thread[0].isOpen == true}">
                        <div class="conversation-user">
                            <img src="assets/app-content/img/people/4dot5-default-no-profile-pic.jpg" alt="User"
                                class="user-chat-image">
                        </div>
                        <div class="conversation-body"
                            [ngClass]="{'user-chat-sender': thread[0].isLoggedInUserSender, 'user-chat-receiver': !thread[0].isLoggedInUserSender}">
                            <div style="position: relative;">
                                <div style="width: 80%;">
                                    <b style="display:block; max-width:150px"
                                        class="text-overflow-ellipsis">{{thread[0].sendByUserName}}</b>
                                    <span class="text-overflow-ellipsis" style="display:inline-block;max-width: 200px;">
                                        to
                                        <b class="text-danger ">
                                            {{thread[0].recipients[0].firstName}} {{thread[0].recipients[0].lastName}}
                                        </b>
                                    </span>
                                    <i *ngIf="thread[0].recipients.length>1" class="fa fa-users text-danger user-chat-thread-recepients-icon"
                                        [tooltip]="thread[0].toUsersText" container="body">
                                    </i>
                                </div>
                                <div class="time d-none d-sm-block user-chat-thread-time">
                                    <span>{{thread[0].sendAt | fourDotFiveTimeStampFormatPipe}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-9">
                                    <span class="text-overflow-ellipsis user-chat-thread-message">
                                        {{thread[0].content}}
                                    </span>
                                </div>
                                <div class="col-xs-3 user-chat-message-counts-container" [ngClass]="{'user-chat-message-counts-container-without-unread-messages': thread[0].unreadMessagesCount == undefined || thread[0].unreadMessagesCount == 0}">
                                    <span *ngIf="thread[0].unreadMessagesCount && thread[0].unreadMessagesCount !== 0"
                                        class="badge badge-danger text-danger" tooltip="Unread Messages" container="body">
                                        {{thread[0].unreadMessagesCount}}
                                    </span>
                                    <span class="badge badge-primary text-danger" tooltip="All Messages" container="body">
                                        {{thread.length}}
                                    </span>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="chatThreads.length == 0" class="text-center user-chat-no-threads">
                    No Messages Available!
                </div>
            </div>
            <div class="col-xs-12 user-chat-loading text-center" *ngIf="loadingFlag">
                <span class="mr-2">Loading</span>
                <span class="fa fa-spinner fa-spin"></span>
            </div>
        </div>
    </div>

    <div class="conversation-new-message user-chat-messages-wrapper"
        [ngClass]="{'user-chat-internal': selectedThread && selectedThread.length>0 && selectedThread[0].chatMessageType == 'INTERNAL', 
            'user-chat-external': selectedThread && selectedThread.length>0 && selectedThread[0].chatMessageType == 'EXTERNAL'}">
        <div class="row user-chat-messages-header" *ngIf="isReplyingEnabled">
            <div class="col-xs-12">
                <h5>Members: <b class="text-danger">{{selectedThreadUsers}}</b></h5>
            </div>
        </div>
        <div>
            <div class="row">
                <div class="conversation-wrapper user-chat-messages-container">
                    <div class="conversation-content">
                        <div class="conversation-inner">
                            <div *ngIf="selectedThread && selectedThread.length==0"
                                class="text-center user-chat-no-threads">
                                <span *ngIf="chatThreads.length == 0">
                                    No Messages Available!
                                </span>
                                <span *ngIf="chatThreads.length !== 0">
                                    Click on a conversation to view or respond <b>OR</b> click on <b>'New Message'</b> to
                                    create a new message
                                </span>
                            </div>
                            <div *ngIf="selectedThread && selectedThread.length>0">
                                <div *ngFor="let message of selectedThread; index as messageIndex"
                                    id="{{messageIndex + message.parentMessageId}}" class="conversation-item clearfix"
                                    [ngClass]="{'item-right': message.isLoggedInUserSender, 'item-left': !message.isLoggedInUserSender}">
                                    <div class="conversation-user">
                                        <img src="assets/app-content/img/people/4dot5-default-no-profile-pic.jpg" alt=""
                                            class="user-chat-image">
                                    </div>
                                    <div class="conversation-body"
                                        [ngClass]="{'user-chat-sender': message.isLoggedInUserSender, 'user-chat-receiver': !message.isLoggedInUserSender}">
                                        <div style="width:80%">
                                                <span>
                                                    <b>{{message.sendByUserName}}</b>
                                                </span>
                                            </div>
                                            <div class="time d-none d-sm-block">
                                                <span class="ml-1">{{message.sendAt | fourDotFiveTimeStampFormatPipe}}</span>
                                            </div>
                                        <div class="row">
                                            <div class="col-xs-10">
                                                <span class="wrap-word">
                                                    {{message.content}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12">
                    <form #userCommunicationForm="ngForm" novalidate>
                        <div class="row">
                            <!-- Communication types -->
                            <div class="col-xs-5"
                                *ngIf="!isReplyingEnabled && jobMatchInfo && jobMatchInfo.isSharedRequisition">
                                <div class="form-group"
                                    [ngClass]="{'has-error': (userCommunicationForm.submitted && newChatMessage.chatMessageType == null)}">
                                    <label>
                                        Communication Type<em>*</em> :
                                    </label><br>
                                    <div class="radio" style="display: inline;"
                                        [ngClass]="{'disable-communication-type': isReplyingEnabled || (jobMatchInfo && !jobMatchInfo.isSharedRequisition)}">
                                        <input type="radio" name="one" id="two" value="INTERNAL"
                                            (change)="onCommunicationTypeChange()"
                                            [disabled]="isReplyingEnabled || (jobMatchInfo && !jobMatchInfo.isSharedRequisition)"
                                            [(ngModel)]="newChatMessage.chatMessageType">
                                        <label for="two">
                                            Internal
                                        </label>
                                    </div>
                                    <div class="radio" style="display: inline;"
                                        [ngClass]="{'disable-communication-type': isReplyingEnabled || (jobMatchInfo && !jobMatchInfo.isSharedRequisition)}">
                                        <input type="radio" name="one" id="three" value="EXTERNAL"
                                            (change)="onCommunicationTypeChange()"
                                            [disabled]="isReplyingEnabled || (jobMatchInfo && !jobMatchInfo.isSharedRequisition)"
                                            [(ngModel)]="newChatMessage.chatMessageType">
                                        <label for="three">
                                            External
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <!-- Recruiters dropdown -->
                            <div class="col-xs-5" *ngIf="!isReplyingEnabled" style="margin-top:-10px">
                                <div class="form-group" *ngIf="!isReplyingEnabled"
                                    [ngClass]="{'has-error': (userCommunicationForm.submitted && newChatMessage.sendsTo.length == 0)}">
                                    <label for="recruiter" class="control-label">
                                        Send To<em>*</em> :
                                    </label>
                                    <ng-select class="user-communication-custom-ng-select" 
                                        [ngClass]="{'multi-select-error': (userCommunicationForm.submitted && (newChatMessage.sendsTo.length == 0))}"
                                        name="recruiter" 
                                        [items]="messageRecipientsList" 
                                        dropdownPosition="bottom" 
                                        bindLabel="name"
                                        placeholder="None selected" 
                                        [(ngModel)]="selectedRecipients" 
                                        [closeOnSelect]="false"
                                        [multiple]="true"
                                        (change)="onRecruitersSelected()">
                                        <ng-template ng-header-tmp>
                                            <div class="d-flex">
                                                <div class="mr-2 custom-ng-select-btn" (click)="selectOrDeselectAllClients('recruiters', 'selectAll')">Select All
                                                </div>
                                                <div class="custom-ng-select-btn" (click)="selectOrDeselectAllClients('recruiters', 'deSelectAll')">Select None
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                            <div class="ng-value d-flex" *ngFor="let item of items | slice:0:1">
                                                <span class="ng-value-label">{{item.name}}</span>
                                                <span class="ng-value-icon right" style="height: fit-content;" (click)="clear(item)" aria-hidden="true">×</span>
                                            </div>
                                            <div class="ng-value" *ngIf="items.length > 1">
                                                <span class="ng-value-label">{{items.length - 1}} more...</span>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <!-- Message -->
                        <div class="row" style="margin-top:15px">
                            <div class="col-xs-12">
                                <div class="form-group"
                                    [ngClass]="{'has-error': (userCommunicationForm.submitted && userCommunicationForm.invalid)}">
                                    <label for="notes" class="control-label">
                                        Message<em>*</em> :
                                    </label>
                                    <textarea id="message" name="message" class="form-control" rows="3"
                                        placeholder="Enter your message.."
                                        [(ngModel)]="newChatMessage.content" required>
                                    </textarea>
                                </div>
                            </div>
                        </div>

                        <!-- Footer -->
                        <div class="row">
                            <div class="col-xs-12 text-right">
                                <div class="clearfix">
                                    <button class="btn btn-danger mr-2" 
                                        (click)="cancelMessage()"
                                        [disabled]="sendingFlag">
                                        Cancel
                                    </button>
                                    <button type="submit" class="btn btn-success"
                                        [disabled]="sendingFlag" (click)="sendChat()">
                                        <span *ngIf="!sendingFlag">Send</span>
                                        <span *ngIf="sendingFlag">Sending..</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
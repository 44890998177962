
<span class="fas fa-info-circle {{iconClass}} cursor-pointer" [popoverTitle]="infoTitle" [popover]="popTemplate"
    popoverClass="info-pop-over" placement="auto bottom" container="body" [outsideClick]="true" [adaptivePosition]="false">
</span>

<ng-template #popTemplate>
    <span *ngIf="!isAddEditUser" style="white-space: pre-wrap" [innerHTML]="info"></span>
    <span *ngIf="isAddEditUser">
        <span *ngFor="let information of info; let i = index;" class="label label-primary scope-badge mr-2 mb-2" style="font-size: 13px;">
            {{information}}
        </span>
    </span>
</ng-template>
import { Inject, Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { GenericService } from './generic.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  urlConstants: any = {
    GET_LOGGED_IN_USER_DETAILS: 'api/user/getloggedinuserdetails',
    GET_ALL_USERS: 'api/user/getteammembers',
    GET_FILTERED_USERS: 'api/user/getfiltereduserdetails',
    GET_ENTITY_SCOPE_DETAILS: 'api/user/getuserentityscope',
    DELETE_USER: 'api/user/delete',
    GET_DELETE_USER_INFO: 'api/user/deleteinfos',
    RESEND_INVITE: 'api/user/resendinvite',
    GET_CLIENTS_OR_BUS: 'api/user/getuserclientsorbu',
    GET_USERS_ONLY_CLIENTS_OR_BUS: 'api/user/getUserClientsOrBus',
    GET_POSSIBLE_MANAGERS_FOR_HR: 'api/user/getpossiblemanagersforhiringmanager',
    GET_MESSAGE_RECIPIENTS: 'api/user/job/hiringmanagers/recruiters',
    GET_USER_CREATE_ROLES: 'api/user/getcreateroles',
    GET_USER_UPDATE_ROLES: 'api/user/getupdateroles',
    GET_USER_DETAILS: 'api/user/getuserdetails',
    GET_REPORTEES: 'api/user/getreportees',
    CHECK_ROLE_ENTITY_SCOPE: 'api/user/checkroleentityscope',
    GET_COMPANY_USERS_FOR_ACTIVITIES: 'api/user/getcompanyusersforactivities',
    GET_SUBORDINATES_BY_BU: 'api/user/getsubordinatesbybu',
    GET_USER_BELONGS_TO: 'api/user/getbelongsto',
    SEND_OTP: 'api/user/sendOtp',
    VERIFY_OTP: 'api/user/verifyOtp',
    GET_MANAGER_LIST: 'api/user/getmanagers',
    GET_MANAGER_LIST_WHILE_DEMOTING_ADMIN: 'api/user/getmanagerswhiledemotingcompanyadmin',
    GET_USER_ACTIVITIES: 'api/user/useractivity',
    CHECK_USER_ASSIGNED_FOR_CLIENT_OR_BU: 'api/user/checkuserassignedforclientorbu',
    GET_COMPANIES_TO_SHARE_CANDIDATE_DOCUMENTS: 'api/user/getPermissibleCompaniesToShareCandidateDocuments',
    GET_USER_TO_EMAIL: 'api/user/getUsersToEmail',
    GET_USER_TO_PRE_POPULATE: 'api/user/usersToPrePopulate',
    GET_USER_HIERARCHY: 'api/user/hierarchy',
    USER_CACHE: 'api/user/cache'
  }

  constructor(@Inject(GenericService) private genericService: GenericService,
    @Inject(StorageService) private StorageService: StorageService, @Inject(AuthService) private authService: AuthService) { }

  getLoggedInUserDetails(successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_LOGGED_IN_USER_DETAILS).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getAllUsers(companyId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_USERS + '/' + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getFilteredUsers(userFilterObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_FILTERED_USERS, userFilterObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getUserRoleEntityScope(successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ENTITY_SCOPE_DETAILS + '/' + this.authService.getUserDetails().id + '/' + this.authService.getUserDetails().company.companyId + '/' + this.authService.getUserDetails().roleScreenRef.role.id).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getUserRoleEntityScopeById(userId, companyId, roleId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_ENTITY_SCOPE_DETAILS + '/' + userId + '/' + companyId + '/' + roleId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getFilteredUsersPromiseForTypeAhead(userFilterObject) {
    return new Promise((resolve, reject) => {
      this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_FILTERED_USERS, userFilterObject).subscribe((data) => {
        resolve(data.body)
      })
    })
  }

  getFilteredUsersToEmail(userFilterObject) {
    return new Promise((resolve, reject) => {
      this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_USER_TO_EMAIL, userFilterObject).subscribe((data) => {
        resolve(data.body);
      }, (error) => {
        reject(error);
      })
    })
  }

  deleteUser(deleteUserObject, successCallback, errorCallback) {
    this.genericService.deleteObject(this.StorageService.get('baseurl') + this.urlConstants.DELETE_USER, deleteUserObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getDeleteUserInfo(userId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_DELETE_USER_INFO + '/' + userId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  resendInviteToUser(userId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.RESEND_INVITE + '/' + userId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getUserClientsOrBus(userId, companyId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_CLIENTS_OR_BUS + '/' + userId + '/' + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getUsersOnlyClientsOrBus(companyId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_USERS_ONLY_CLIENTS_OR_BUS + '/' + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getPossibleManagersForHr(companyId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_POSSIBLE_MANAGERS_FOR_HR + '/' + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getMessageRecipients(filterObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_MESSAGE_RECIPIENTS, filterObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getUserCreateRoles(roleId, companyType, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_USER_CREATE_ROLES + '/' + roleId + '/' + companyType).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getUserUpdateRoles(roleId, companyType, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_USER_UPDATE_ROLES + '/' + roleId + '/' + companyType).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getUserDetails(userId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_USER_DETAILS + '/' + userId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getReportees(managerId, successCallback, errorCallback) {
    this.genericService.getObjectById(this.StorageService.get('baseurl') + this.urlConstants.GET_REPORTEES + '/' + managerId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  checkRoleEntityScope(successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.CHECK_ROLE_ENTITY_SCOPE + '/' + this.authService.getUserDetails().company.companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error)
    });
  }

  getCompanyUsersForActivities(successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_COMPANY_USERS_FOR_ACTIVITIES + '/' + this.authService.getUserDetails().company.companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error)
    });
  }

  getSubordinatesByBu(userId, buId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_SUBORDINATES_BY_BU + '/' + userId + '/' + buId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getUserBelongsTo(companyId, managerId, userId, successCallback, errorCallback) {
    let url = `${this.StorageService.get('baseurl')}${this.urlConstants.GET_USER_BELONGS_TO}/${companyId}`;
    if (managerId.length > 0) {
      url = `${url}?managerId=${managerId}`;
    }

    if (userId.length > 0) {
      let separator = url.includes('managerId') ? '&' : '?';
      url = `${url}${separator}userId=${userId}`;
    }
    this.genericService.getObjects(url).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  sendOTP(userId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.SEND_OTP + '/' + userId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error)
    });
  }

  verifyOTP(otp, userId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.VERIFY_OTP + '/' + otp + '/' + userId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error)
    });
  }

  getManagers(userId, roleId, buId, successCallback, errorCallback) {
    let url = this.StorageService.get('baseurl') + this.urlConstants.GET_MANAGER_LIST + '/' + userId + '/' + roleId;
    if (!_.isNull(buId)) {
      url = url + "?buId=" + buId;
    }
    this.genericService.getObjects(url).subscribe(function (data) {
      successCallback(data.body);
    }, function (error) {
      errorCallback(error.error)
    });
  }

  getManagersWhileDemotingAdmin(userId, companyId, roleId, buId, successCallback, errorCallback) {
    let url = this.StorageService.get('baseurl') + this.urlConstants.GET_MANAGER_LIST_WHILE_DEMOTING_ADMIN + '/' + userId + '/' + companyId + '/' + roleId;
    if (!_.isNull(buId)) {
      url = url + "?buId=" + buId;
    }
    this.genericService.getObjects(url).subscribe(function (data) {
      successCallback(data.body);
    }, function (error) {
      errorCallback(error.error);
    });
  }

  getUserActivities(activityFilters, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_USER_ACTIVITIES, activityFilters).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  checkIfUserHasRequisitionsInClientOrBu(companyId, userId, clientOrBuId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.CHECK_USER_ASSIGNED_FOR_CLIENT_OR_BU + '/' + companyId + '/' + userId + '/' + clientOrBuId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error)
    });
  }

  getCompaniesToShareCandidateDocuments(companyId, userId, candidateId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_COMPANIES_TO_SHARE_CANDIDATE_DOCUMENTS + '/' + companyId + '/' + userId + '/' + candidateId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error)
    });
  }

  getUsersToPrePopulate(loggedInCompanyId, onlyRequisitionUsers, jobMatchIds) {
    return new Promise((resolve, reject) => {
      this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_USER_TO_PRE_POPULATE + '/' + loggedInCompanyId + '?onlyRequisitionUsers=' + onlyRequisitionUsers + '&jobMatchIds=' + jobMatchIds).subscribe((data) => {
        resolve(data.body);
      }, (error) => {
        reject(error);
      })
    })
  }

  getAllUsersHierarchy(companyId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_USER_HIERARCHY + '/' + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  userCache(userObject, successCallback, errorCallback) {
    if (!userObject) {
      userObject = {
        userId: this.authService.getUserDetails().id,
        loggedInCompanyId: this.authService.getUserDetails().company.companyId
      }
    }
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.USER_CACHE, userObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  userCacheWithToken(successCallback, errorCallback) {
    let userObject = {
      userId: this.authService.getUserDetails().id,
      loggedInCompanyId: this.authService.getUserDetails().company.companyId
    }
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.USER_CACHE + "?activeToken=true", userObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  //common methods
  // Shifted to Shared Ajs service after migrating routes replace it
  navigateUserAfterLogin(userObject) {
  //   this.$rootScope.userActive = true;
  //   this.StorageService.set('userDetails', this._createUserDetailsObject(userObject), { 'expires': new Date(Date.now() + this.$rootScope.tokenvalidity) });
  //   this.$rootScope.userDetails = this.StorageService.get('userDetails');
  //   if (this.authService.getUserDetails().userDetails.roleScreenRef.role.name === '4dot5SuperUser') {
  //     this.$state.go('missioncontrol.teamMembers');
  //   } else if (this.authService.getUserDetails().userDetails.policyChanged) {
  //     this.$state.go("missioncontrol.changePassword", { message: "Password restrictions have been changed. Please change the password to proceed.", policyChangePasswordUpdate: true });
  //   } else if (this.authService.getUserDetails().userDetails.plan.planName == 'Vendor Only') {
  //     this.$state.go('missioncontrol.requisitions', null, { reload: false });
  //   } else {
  //     this.$state.go('missioncontrol.dashboard', null, { reload: false });
  //   }
  //   this.webSocketsService.connect();
  }


  _createUserDetailsObject(data) {
    let obj:any = {};
    obj.company = {};
    obj.plan = {};
    obj.id = data.id;
    obj.firstname = data.firstname;
    obj.lastname = data.lastname;
    obj.emailId = data.emailId;
    obj.companyId = data.companyId;
    obj.policyChanged = data.policyChanged;
    // obj.accesstoken = data.token;
    obj.company.companyId = data.company.companyId;
    obj.company.companyName = data.company.companyName;
    obj.company.companyType = data.company.companyType;
    obj.company.companyState = data.company.companyState;
    obj.company.companyStateLabel = data.company.companyState == 'SoftHold' ? 'Soft Hold' : data.company.companyState;
    obj.company.message = data.company.message;
    obj.plan.planName = data.planMinDto.planName;
    obj.plan.planType = data.planMinDto.planType;
    obj.plan.startDate = moment(data.planMinDto.planStartDate);
    obj.plan.endDate = moment(data.planMinDto.planEndDate);
    // obj.plan.message = this.planService.getPlanExpiryMessage(obj.plan.planType, obj.plan.endDate, true);
    obj.accountState = data.accountState;
    obj.shouldPopupBeDisplayed = data.shouldPopupBeDisplayed;
    obj.roleScreenRef = this._createRoleScreenRefObj(data.roleScreenRef);
    if (data.roleScreenRef.role.name !== '4dot5SuperUser' && data.roleScreenRef.role.name !== '4dot5Admin') {
      obj.company.companySelected = true;
    } else {
      obj.company.companySelected = false;
    }
    return obj;
  }

  _createRoleScreenRefObj(roleScreenRef) {
    let roleObj:any = {};
    roleObj.role = {};
    roleObj.role.id = roleScreenRef.role.id;
    roleObj.role.name = roleScreenRef.role.name;
    roleObj.screen = this._manageScreenListForRoles(roleScreenRef.screen);
    return roleObj;
  }

  _manageScreenListForRoles(screenlist) {
    for (let i = 0; i < screenlist.length; i++) {
      delete screenlist[i].name;
    }
    return screenlist;
  }

  navigateToHomeScreen() {
    // if (this.authService.getUserDetails().userDetails.roleScreenRef.role.name === '4dot5SuperUser' && this.authService.getUserDetails().userDetails.company.companyType == "Host") {
    //   this.$state.go('missioncontrol.teamMembers');
    // } else if (this.authService.getUserDetails().userDetails.policyChanged) {
    //   this.$state.go("missioncontrol.changePassword", { message: "Password restrictions have been changed. Please change the password to proceed.", policyChangePasswordUpdate: true });
    // } else if (this.authService.getUserDetails().userDetails.plan.planName == 'Vendor Only') {
    //   this.$state.go('missioncontrol.requisitions', null, { reload: false });
    // } else {
    //   this.$state.go('missioncontrol.dashboard', null, { reload: false });
    // }
  }

  getParsedPasswordRestrictions(passwordRestrictions) {
    let restrictionObject = {
      restrictionsList: [],
      validationPattern: "",
      minimumLength: 1,
      maximumLength: 1000
    };
    if (passwordRestrictions.lengthRestrictionEnabled) {
      restrictionObject.restrictionsList.push("Minimum length: " + passwordRestrictions.minimumLength);
      restrictionObject.minimumLength = passwordRestrictions.minimumLength;
      if (passwordRestrictions.maximumLength !== 'unlimited' && passwordRestrictions.maximumLength !== null) {
        restrictionObject.maximumLength = passwordRestrictions.maximumLength;
        restrictionObject.restrictionsList.push("Maximum length: " + passwordRestrictions.maximumLength);
      }
    }
    let regex = "";
    if (passwordRestrictions.complexityRestrictionEnabled) {
      angular.forEach(passwordRestrictions.complexityRestrictions, (restriction, key) => {
        if (restriction.enabled) {
          restrictionObject.restrictionsList.push(restriction.restriction);
          if (restriction.restriction == "Upper case letter") {
            regex = regex + "(?=.*[A-Z])";
          }
          if (restriction.restriction == "Lower case letter") {
            regex = regex + "(?=.*[a-z])";
          }
          if (restriction.restriction == "A Numeric") {
            regex = regex + "(?=.*\\d)";
          }
          if (restriction.restriction == "Special character") {
            regex = regex + "(?=.*\\W)";
          }
        }
      });
    }
    restrictionObject.validationPattern = regex;
    return restrictionObject;
  }

  isScreenWithLoginNotRequired(url) {
    let screensWithLoginNotRequired = [
      "login",
      "forgotPassword",
      "setpassword",
      "tokenLogin",
      "validateOtp"
    ];
    for (let i = 0; i < screensWithLoginNotRequired.length; i++) {
      if (url.includes(screensWithLoginNotRequired[i])) {
        return true;
      }
    }
    return false;
  }

}

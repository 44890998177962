<div class="ngx-modal-header">
    <h4 class="modal-title pull-left">{{modalData.title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal(null)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="">
        <div class="main-box clearfix">
            <div class="main-box-body p-4">
                <div style="max-height: 300px; overflow: auto;">
                    <div *ngIf="!isLoading && uploadedSkillsDetails.length > 0">
                        <table class="table table-bordered table-responsive">
                            <thead>
                                <tr>
                                    <th>File Name</th>
                                    <th>Download Skills</th>
                                    <th>View Upload Skills</th>
                                    <th>Date Uploaded</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let skill of uploadedSkillsDetails;">
                                    <td>{{skill.originalFileName}}</td>
                                    <td><a (click)="downloadSkillFile(skill)">Download File</a></td>
                                    <td><a (click)="viewSkillResults(skill, viewUploadedSkillsResultsTemplate)">View Results</a></td>
                                    <td>{{skill.createdDate |fourDotFiveTimeStampFormatPipe}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div *ngIf="!isLoading && (!uploadedSkillsDetails || uploadedSkillsDetails.length == 0)">
                        No Uploaded Skills
                    </div>
                    <div *ngIf="isLoading" class="col-xs-12 loading-spinner text-center">
                        <span class="mr-2">Loading</span>
                        <span class="fa fa-spinner fa-spin"></span>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<ng-template #viewUploadedSkillsResultsTemplate>
    <div class="ngx-modal-header">
        <h4 class="modal-title pull-left">{{modalData.title}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="viewSkillModal.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="">
            <div class="main-box clearfix">
                <div class="main-box-body p-4">
                    <div class="main-box-body p-4">
                        <app-upload-skills-results 
                            [uploadedSkillsDetails]="uploadedSkills">
                        </app-upload-skills-results>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
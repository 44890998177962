import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserCommunicationComponent } from './user-communication.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { TooltipModule } from 'ngx-bootstrap';

@NgModule({
  declarations: [UserCommunicationComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    PipesModule,
    TooltipModule.forRoot()
  ],
  entryComponents: [UserCommunicationComponent],
  exports: [UserCommunicationComponent]
})
export class UserCommunicationModule { }

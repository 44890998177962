import { Component, OnInit, Input } from '@angular/core';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { JobMatchService } from 'src/app/core/services/job-match.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-view-assessment-report',
  templateUrl: './view-assessment-report.component.html',
  styleUrls: ['./view-assessment-report.component.css']
})
export class ViewAssessmentReportComponent implements OnInit {

  @Input() jobMatchId;
  @Input() testInvitationId;
  @Input() assessmentType;
  @Input() isSectionalReport;
  @Input() context;

  availabilityDate: any;
  availabilityObject: any = {};
  jobMatch: any = {};
  companyId: any;
  url: any = '';
  embedUrl: any = '';
  reportTitle: any;

  loadingFlag: boolean = false;
  reportLoadingFlag: boolean = true;
  isStaffingCompany: boolean;


  constructor(
    private authService: AuthService,
    private companyService: CompanyService,
    private jobMatchService: JobMatchService,
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.companyId = this.authService.getUserDetails().company.companyId;
    this.reportTitle = this.assessmentType == 'Technical' ? 'Technical Assessment Report' : 'Value Assessment Report';

    //flags
    this.loadingFlag = true;
    this.reportLoadingFlag = true;
    this.isStaffingCompany = this.companyService.isStaffingCompany();

    this._getJobMatchCardInfo();
  }
  
  _getJobMatchCardInfo() {
    this.loadingFlag = true;
    this.reportLoadingFlag = true;
    this.jobMatchService.getCandidateJobMatchCard(this.jobMatchId, this.companyId, (data) => {
      this.jobMatch = data;
      this.jobMatch.name = this.jobMatch.firstName + ' ' + this.jobMatch.lastName;
      this._getReport();
      this.loadingFlag = false;
      setTimeout(() => {
        this.reportLoadingFlag = false;
      }, 3000);
    }, (error) => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    }, undefined);

  }

  _getReport() {
    let url = this.assessmentType == 'Technical' ? this.jobMatch.techAssessmentPdfReportURL : this.jobMatch.valueAssessmentPdfReportURL;
    if (this.isSectionalReport == undefined && this.context == "notification") {
      this.isSectionalReport = this.isStaffingCompany && this.jobMatch.isSharedRequisition;
    }
    this.url = 'api/candidate/candidatepdfreport?filepath=' + url + '&testinvitationid=' + this.testInvitationId + '&sectional=' + this.isSectionalReport;
    this.embedUrl = this.getSafeUrl(this.url + '#view=fitH');
    this.url = this.getSafeUrl(this.url);
  }

  getSafeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}

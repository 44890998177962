import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidateOtpPageComponent } from './validate-otp-page.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [ValidateOtpPageComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  entryComponents: [ValidateOtpPageComponent]
})
export class ValidateOtpPageModule { }

export class Folder {
    id: any;
    name: any;
    description: any;
    parentFolderId: any;
    companyId: any;
    candidateId: any;
    folders: any;
    documents: any;
    jobMatch: any;
    isJobMatchDocument: boolean;
    constructor(name, description, parentFolderId, companyId, candidateId) {
        this.name = name;
        this.description = description;
        this.parentFolderId = parentFolderId;
        this.companyId = companyId;
        this.candidateId = candidateId;
    }
}
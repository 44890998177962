import { OnInit, Injectable } from '@angular/core';
import { EmployeeService } from 'src/app/core/services/employee.service';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { EmployeesQueryFilter } from 'src/app/core/models/employees-query-filter';

// @Injectable()
export class EmployeesInfiniteScrollContainer {
    employees: any[] = [];
    busy: boolean = false;
    employeesExistFlag: boolean = true;
    employeesQueryFilter = new EmployeesQueryFilter(this.companyId);
    currentPromise: any = null;
    disableInfiniteScroll: boolean = false;

    constructor(
        private companyId: any,
        private employeeService: EmployeeService,
        private alertsAndNotificationsService: AlertAndNotificationsService
    ) {}

    getNext() {
        if (this.busy) return;
        this.busy = true;

        if (!this.employeesQueryFilter.page) {
            this.employeesQueryFilter.page = 1;
        }

        this.currentPromise = this.employeeService.searchEmployees(this.employeesQueryFilter, 
                (data: any[]) => {
                    if (data && data.length > 0) {
                        this.employees.push(...data);

                        if (data.length < this.employeesQueryFilter.size) {
                            this.disableInfiniteScroll = true;
                        } else {
                            this.employeesQueryFilter.page++;
                        }
                    } else {
                        this.disableInfiniteScroll = true;
                    }

                    this.employeesExistFlag = this.employees.length > 0;
                    this.busy = false;
                },
                (error) => {
                    this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
                    this.busy = false;
                }
            );
    }
}

import { downgradeComponent } from "@angular/upgrade/static";

import { EmployeeManagementPageComponent } from "./employee-management-page.component";

export const EmployeeManagementPageModuleAjsModule = angular
    .module('employeeManagementPage', [])
    .directive('employeeManagementPage', downgradeComponent({ component: EmployeeManagementPageComponent }))
    .config(
        ['$stateProvider', ($stateProvider) => {
            $stateProvider.state('missioncontrol.employeeManagement', {
                url: '/employeeManagement',
                component: EmployeeManagementPageComponent,
                data: {
                    requireLogin: true
                },
                params: {
                    employeeId: null,
                    employeeName: null
                }
            });
        }
        ])
    .name;
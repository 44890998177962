export class ContactNote {
        noteId: any = null;
        jobMatchId: any = null;
        companyId: any = null;
        contactMethod: any = null;
        duration: any = null;
        dateTime: any = null;
        note: any = '';
        externalNote: any = '';
        user: any = {};
}
<div class="container">
    <div class="row">
        <div class="col-xs-12">
            <form #setAvailabilityDateForm="ngForm" novalidate>
                <div class="row">
                    <div class="col-xs-6">
                        <div class="form-group"
                            [ngClass]="{'has-error': (setAvailabilityDateForm.submitted && formAvailabilityDate.invalid)}">
                            <label for="availabilityDate" class="control-label">
                                <span *ngIf="availabilityType == 'availability'">Availability Date<em>*</em> :</span>
                                <span *ngIf="availabilityType == 'jobLeft'">Job Left Date<em>*</em> :</span>
                            </label>
                            <div class="input-group">
                                <div class="input-group-addon">
                                    <div class="input-group-text">
                                        <i class="fa fa-calendar"></i>
                                    </div>
                                </div>
                                <input #formAvailabilityDate="ngModel" type="text" name="formAvailabilityDate"
                                    id="formAvailabilityDate" class="form-control" [(ngModel)]="availabilityDate"
                                    required>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-6" *ngIf="availabilityType == 'jobLeft'">
                        <div class="form-group"
                            [ngClass]="{'has-error': (setAvailabilityDateForm.submitted && formReason.invalid)}">
                            <label for="reason" class="control-label">
                                Reason<em>*</em>:
                            </label>
                            <select #formReason="ngModel" class="form-control" name="formReason" id="formReason"
                                required [(ngModel)]="reasonCode">
                                <option *ngFor="let reason of reasonCodes; let i = index;" [value]="reason.id">
                                    {{reason.reason}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 text-right">
                        <div class="clearfix">
                            <button type="submit" class="btn btn-success" (click)="setCandidateAvailability()"
                                [disabled]="savingFlag">
                                <span *ngIf="!savingFlag">Set Date</span>
                                <span *ngIf="savingFlag">Setting..</span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
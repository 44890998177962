import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecentActivitiesComponent } from './recent-activities.component';
import { TooltipModule } from 'ngx-bootstrap';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
  declarations: [RecentActivitiesComponent],
  imports: [
    CommonModule,
    TooltipModule.forRoot(),
    PipesModule
  ],
  exports: [RecentActivitiesComponent],
  entryComponents: [RecentActivitiesComponent]
})
export class RecentActivitiesModule { }

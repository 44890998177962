import { Component, OnInit, Input, Inject } from '@angular/core';

@Component({
  selector: 'app-employee-management-page',
  templateUrl: './employee-management-page.component.html',
  styleUrls: ['./employee-management-page.component.css']
})
export class EmployeeManagementPageComponent implements OnInit {

  @Input() $transition$: any;

  stateParams: any;
  employeeId: any;
  employeeName: any;

  constructor(
    @Inject("$state") public $state: any
  ) { }

  ngOnInit() {
    this.stateParams = this.$transition$.params();
    this.employeeId = this.stateParams.employeeId;
    this.employeeName = this.stateParams.employeeName;
  }

  redirectToUrl(url, isReloadRequired) {
    this.$state.go(url, null, { reload: isReloadRequired });
}

}

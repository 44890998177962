import { Component, OnInit, Inject, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { GenericService } from 'src/app/core/services/generic.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { MESSAGECONSTANTS } from 'src/app/core/utils/constants';
import { UibModalService } from 'src/app/public/app/services/uibModalService';

@Component({
    selector: 'app-notification-preference-page',
    templateUrl: './notification-preference-page.component.html',
    styleUrls: ['./notification-preference-page.component.css']
})
export class NotificationPreferencePageComponent implements OnInit {
    @ViewChild('notificationPrefsForm', null) notificationPrefsForm: NgForm;

    @Input() $transition$: any;

    MESSAGECONSTANTS: any = MESSAGECONSTANTS;

    stateParams: any;
    transactionNotificationPreferences: any;
    notificationPreferencesSavedObj: any;

    showConfirmationPopup: boolean = false;
    isLocationChange: boolean = false;
    isLocationChangeInitial: boolean = false;
    isLoading: boolean = true;

    constructor(
        @Inject('$state') public $state: any,
        @Inject('$rootScope') public $rootScope: any,
        @Inject('$scope') public $scope: any,
        private genericService: GenericService,
        private storageService: StorageService,
        private authService: AuthService,
        private utilityService: UtilityService,
        private alertsAndNotificationsService: AlertAndNotificationsService,
        @Inject('uibModalService') public uibModalService: UibModalService
    ) { }

    ngOnInit() {
        this.stateParams = this.$transition$.params();
        this._getNotificationPreferences();
        this._handleBrowserEvents();
    }

    _getNotificationPreferences() {
        this.isLoading = true;
        this.genericService.getObjects(this.storageService.get('baseurl') + 'api/notification/getnotificationprefs' + '/' + _.clone(this.stateParams.userId) + '/' + this.authService.getUserDetails().company.companyId).subscribe((data) => {
            this.transactionNotificationPreferences = this.copy(data.body);
            this.notificationPreferencesSavedObj = this.copy(data.body);
            this.isLoading = false;
        })
    }

    saveNotificationPreferences() {
        if (this._hasPreferencesChanged()) {
            this.utilityService.showLoadingModal("Loading...");
            this.utilityService.saveNotificationPrefs(_.clone(this.stateParams.userId), this.authService.getUserDetails().company.companyId, this.transactionNotificationPreferences).then((data: any) => {
                console.log('success : ' + JSON.stringify(data));
                if (this.isLoggedInUser(data.user.userDetails)) {
                    this.alertsAndNotificationsService.showBannerMessage(this.MESSAGECONSTANTS.NOTIFICATIONS.NOTIFICATION_PREFS.OWN_NP_UPDATE, 'success');
                } else {
                    this.alertsAndNotificationsService.showBannerMessage(this.MESSAGECONSTANTS.NOTIFICATIONS.NOTIFICATION_PREFS.NP_FOR_USER +
                        " '<b><i class='wrap-word'>" +
                        this.handleLongName(this.getFullName(data.user.userDetails.firstName, data.user.userDetails.lastName), 50) +
                        "</i></b>' " + this.MESSAGECONSTANTS.NOTIFICATIONS.NOTIFICATION_PREFS.UPDATESUCCESS, 'success');
                }
                this.notificationPreferencesSavedObj = this.copy(data);
                this.transactionNotificationPreferences = this.copy(data);
                this.notificationPrefsForm.resetForm(); // set form
                this.utilityService.hideLoadingModal();
            }, (error) => {
                this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
                this.transactionNotificationPreferences = this.copy(this.notificationPreferencesSavedObj);
            });
        } else {
            this.alertsAndNotificationsService.showBannerMessage(this.MESSAGECONSTANTS.NOTIFICATIONS.NOTHING_TO_UPDATE, 'success');
        }
    }

    _hasPreferencesChanged() {
        return !_.isEqual(this.transactionNotificationPreferences, this.notificationPreferencesSavedObj);
    }

    isLoggedInUser(userDetails) {
        if (!_.isUndefined(userDetails)) {
            return _.isEqual(userDetails.userId, this.authService.getUserDetails().id);
        }
    }

    handleLongName(longName, limitToMax) {
        if (!_.isUndefined(longName)) {
            return this.utilityService.handleLongName(longName, limitToMax);
        }
    }

    getFullName(firstName, lastName) {
        return this.utilityService.getFullName(firstName, lastName);
    }

    cancelChanges() {
        this.showConfirmationPopup = false;
        if (this._hasPreferencesChanged()) {
            this.transactionNotificationPreferences = this.copy(this.notificationPreferencesSavedObj);
            this.notificationPrefsForm.resetForm();
        }
    }

    copy(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    //Handle navigating away when data is unsaved. Migrate to angular UI can exit after completing all migration
    uiCanExit(transitions) {
        let toState = transitions.to().name;
        let toParams = transitions.params();
        if (this._hasPreferencesChanged() && toState !== "login") {
            transitions.abort();
            let message = "Unsaved data on the page will be lost. Do you still want to continue?"
            this.alertsAndNotificationsService.showConfirm('Warning!', message, 'warning', true, () => {
                this.cancelChanges();
                // Handling data change when clicked on Switch company 
                if (this.$rootScope.switchClicked) {
                    this.$rootScope.switchClicked = false;
                    // this.companyService.showAllCompanies();
                    this.uibModalService.showAllCompanies();
                } else {
                    this.$state.go(toState, toParams, { reload: true });
                }
            }, () => {
                // Do Nothing 
            });
        } else {
            // If clicked on switch company without changing data
            if (this.$rootScope.switchClicked && !this._hasPreferencesChanged()) {
                transitions.abort();
                setTimeout(() => {
                    // this.companyService.showAllCompanies();
                    this.uibModalService.showAllCompanies();
                }, 150);
                this.$rootScope.switchClicked = false;
            }
        }
    }

    _handleBrowserEvents() {
        //Handle Browser back/forward change
        let unbindLocationChangeEvent = this.$scope.$on('$locationChangeStart', (event, toState, toParams, fromState, fromParams) => {
            this.isLocationChange = this.isLocationChangeInitial;
            this.isLocationChangeInitial = true;
        });
    }

    redirectToUrl(url, isReloadRequired) {
        this.$state.go(url, null, { reload: isReloadRequired });
    }

}

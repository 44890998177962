import { ForgotPasswordPageComponent } from "./forgot-password-page.component";
import { downgradeComponent } from "@angular/upgrade/static";

export const ForgotPasswordPageAjsModule = angular
    .module('forgotPasswordPage', [])
    .directive('forgotPasswordPage', downgradeComponent({ component: ForgotPasswordPageComponent }))
    .config(
        ['$stateProvider', ($stateProvider) => {
            $stateProvider.state('forgotPassword', {
                url: '/forgotPassword',
                component: ForgotPasswordPageComponent,
                data: {
                    requireLogin: false
                },
                params: {
                    message: null
                }
            });
        }
        ])
    .name;
import { Inject, Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { GenericService } from './generic.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserCommunicationService {

  urlConstants = {
    SAVE_CHAT: "api/chat/savechat",
    GET_ALL_CHAT: "api/chat/getallchatsbyuserid",
    CHANGE_CHAT_MESSAGE_STATE: "api/chat/changechatmessagestate"
  };

  constructor(@Inject(GenericService) private genericService:GenericService, @Inject(StorageService) private StorageService:StorageService,
  @Inject(AuthService) private authService:AuthService) { }

  getAllChat(jobMatchId, filterObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_ALL_CHAT + '/' + this.authService.getUserDetails().id + '/' + jobMatchId, filterObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  saveChat(userCommunicationObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.SAVE_CHAT, userCommunicationObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  changeChatMessageState(messageId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.CHANGE_CHAT_MESSAGE_STATE + '/' + messageId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }
}

<style>
    .do-not-hire-for-clients {
        position: relative;
        bottom: 10px;
        left: 26px;
    }
</style>
<div class="container">
    <div class="row" *ngIf="!isLoading">
        <div class="col-xs-12">
            <p class="pl-3">Do not hire candidate
                <span *ngIf="context !== 'requisitionCard'" class="emerald">'{{candidateName}}'.</span>
            </p>
        </div>

        <!-- Do not hire for Clients/Vendor -->
        <div class="col-xs-12">
            <form #doNotHireForm="ngForm" novalidate (ngSubmit)="doNotHireForm.valid && doNotHireCandidate()">
                <!-- Non Joined Candidates -->
                <div class="col-xs-6" *ngIf="context == 'requisitionCard'">

                    <div class="col-xs-6 form-group d-inline-block">
                        <ng-select name="nonJoinedCandidates" [items]="nonJoinedCandidates"
                            bindLabel="candidateFullName" placeholder="None selected"
                            [(ngModel)]="selectedNonJoinedCandidates" [clearable]="false"
                            (change)="onCandidateSelect()">
                        </ng-select>
                    </div>
                </div>

                <!-- Job matches of candidate -->
                <div class="col-xs-12" *ngIf="jobMatches.length > 0">
                    <div class="panel panel-info">
                        <div class="panel-heading">
                            Current Job Matches of Candidate
                        </div>
                        <div class="panel-body p-0" style="max-height: 300px; overflow-y: auto;">
                            <table class="table table-bordered table-responsive mb-0">
                                <tbody>
                                    <tr>
                                        <th>Req #</th>
                                        <th>Title</th>
                                        <th>
                                            <span *ngIf="isStaffingCompany">
                                                Client
                                            </span>
                                            <span *ngIf="!isStaffingCompany">
                                                BU
                                            </span>
                                        </th>
                                        <th>Recruiter(s)</th>
                                        <th>Current Step</th>
                                        <th>Current State</th>
                                    </tr>
                                    <tr *ngFor="let jobMatch of jobMatches; let i = index">
                                        <td>
                                            <span>{{jobMatch.requisitionNumber}}</span>
                                        </td>
                                        <td>
                                            <span>{{jobMatch.title}}</span>
                                        </td>
                                        <td>
                                            <span>{{jobMatch.clientOrBuOrCorporateName}}</span>
                                        </td>
                                        <td>
                                            <span class="d-block"
                                                *ngFor="let recruiterName of jobMatch.recruiterNames; let i = index">
                                                {{recruiterName}}
                                            </span>
                                        </td>
                                        <td>
                                            <span>{{jobMatch.currentStep}}</span>
                                        </td>
                                        <td>
                                            <span>{{jobMatch.currentState}}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="form-group mb-0 col-xs-12" *ngIf="isStaffingCompany">
                    <div class="radio col-xs-6 col-md-4">
                        <input type="radio" name="doNotHireAt" id="doNotHireAtClient" [value]="true"
                            [(ngModel)]="doNotHireAtClient" (change)="onDoNotHireAtChange()">
                        <label for="doNotHireAtClient">
                            Clients:
                        </label>
                    </div>
                    <div class="radio col-xs-6 col-md-4 mt-4">
                        <input type="radio" name="doNotHireAt" id="doNotHireAtVendor" [value]="false"
                            [(ngModel)]="doNotHireAtClient" (change)="onDoNotHireAtChange()">
                        <label for="doNotHireAtVendor">
                            {{companyName}}
                        </label>
                    </div>
                    <div class="col-xs-6">
                        <div class="form-group d-inline-block do-not-hire-for-clients" style="width: 250px;">
                            <ng-select
                                [ngClass]="{'disable-element': !doNotHireAtClient, 'multi-select-error': doNotHireForm.submitted && doNotHireAtClient && selectedClients.length == 0}"
                                name="clients" [items]="clients" dropdownPosition="bottom"
                                placeholder="None selected" [(ngModel)]="selectedClients" [closeOnSelect]="false"
                                [multiple]="true">
                                <ng-template ng-option-tmp let-item="item">
                                    <span [innerHtml]="item.name"></span>
                                </ng-template>
                                <ng-template ng-header-tmp>
                                    <div class="d-flex">
                                        <div class="mr-2 custom-ng-select-btn" (click)="selectAllClients()">Select All
                                        </div>
                                        <div class="custom-ng-select-btn" (click)="deSelectAllClients()">Select None
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                    <div class="ng-value" *ngFor="let item of items | slice:0:1">
                                        <span class="ng-value-label" [innerHtml]="item.name"></span>
                                        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                    </div>
                                    <div class="ng-value" *ngIf="items.length > 1">
                                        <span class="ng-value-label">...({{items.length - 1}})</span>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </div>

                <!-- Note -->
                <div class="col-xs-12">
                    <div class="form-group" [ngClass]="{'has-error': (doNotHireForm.submitted && note.invalid)}">
                        <label for="note" class="control-label">
                            Notes<em>*</em> :
                        </label>
                        <textarea #note="ngModel" id="note" name="note" class="form-control" rows="3"
                            placeholder="Enter your note.." [(ngModel)]="doNotHireDetails.note"
                            (change)="showExternalNote && copyNote && onInternalNoteChanged()" required>
                        </textarea>
                        <div class="error error-gap" *ngIf="doNotHireForm.submitted">
                            <small *ngIf="note.errors?.required" class=""><em>*</em>
                                Note is required
                            </small>
                        </div>
                    </div>
                    <app-external-notes 
                        *ngIf="showExternalNote"
                        [form]="doNotHireForm" [note]="doNotHireDetails.note" [copyNote]="copyNote"
                        [externalNote]="doNotHireDetails.externalNote"
                        (onExternalNoteChange)="onExternalNoteChange($event)"
                        (onCopyNoteChange)="onCopyNoteChange($event)">
                    </app-external-notes>
                </div>

                <div class="col-xs-12 d-flex justify-content-end">
                    <button class="btn btn-danger" type="button" (click)="cancelDoNotHire()"
                        [disabled]="savingFlag">Cancel</button>
                    <button class="btn btn-primary ml-1" type="submit" [disabled]="savingFlag">Do not hire</button>
                </div>
            </form>
        </div>
    </div>
    <div class="row" *ngIf="isLoading">
        <div class="col-xs-12 loading-spinner text-center" style="height:350px">
            <span>Loading</span>
            <span class="fa fa-spinner fa-spin"></span>
        </div>
    </div>
</div>
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericService } from './generic.service';
import { StateManagementStorageService } from './state-management-storage.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userDetails: any;
  isUserActive:any;
  isLogoutInProgress:any;
  logoutDialog:any;
  isOnline: boolean;
  isSwitchClicked:boolean;

  refreshtokenvalidity:any = 21 * 24 * 60 * 60 * 1000; // Twenty One days Validity;
  tokenvalidity = 24 * 60 * 60 * 1000; //One day validity;


  urlConstants: any = {
    AUTHENTICATE_TOKEN: 'auth/tokenlogin',
    FORGOT_PASSWORD: 'auth/forgotpassword',
    GET_TOKEN_PAYLOAD: 'api/user/gettokenpayload',
    VALIDATE_OTP: 'auth/login',
    GET_USER_ID_BY_TOKEN: 'auth/getuseridbytoken',
    GET_PASSWORD_RESTRICTIONS_BY_USER_TOKEN: 'auth/getPasswordRestrictions',
    GET_PASSWORD_RESTRICTIONS_BY_COMPANY_ID: 'auth/getPasswordRestrictionsOnCompanyId',
    LOG_OUT: 'auth/logout'
  };

  constructor(@Inject('$state') public $state:any, @Inject('$rootScope') public $rootScope:any, @Inject(StorageService) private StorageService:StorageService, @Inject(GenericService) private genericService:GenericService,
  @Inject(StateManagementStorageService) private stateManagementStorageService:StateManagementStorageService, private http: HttpClient) { }

  getUserDetails() {
    this.userDetails = JSON.parse(localStorage.getItem('myStorage-userDetails'));
    if (!_.isNull(this.userDetails) && !_.isUndefined(this.userDetails) && !_.isUndefined(this.userDetails.accountState)) {
      this.userDetails.userActive = true;
    }
    return this.userDetails;
  }

  setIsOnline(value) {
    this.isOnline = value;
  }

  setCompanyStateLabel(value) {
    this.userDetails.company.companyStateLabel = value;
  }

  setUserActive(value) {
    this.isUserActive = value;
  }

  setLogoutInProgress(value) {
    this.isLogoutInProgress = value;
  }

  setLogoutDialog(value) {
    this.logoutDialog = value;
  }

  setIsSwitchClicked(value) {
    this.isSwitchClicked = value;
  }


  authenticateToken(tokenObject, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.AUTHENTICATE_TOKEN, tokenObject).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getTokenPayload(token, userId, successCallback, errorCallback) {
    let url = this.StorageService.get('baseurl') + this.urlConstants.GET_TOKEN_PAYLOAD + '/' + token;
    if (!_.isNull(userId)) {
      url = url + "?loggedInUserId=" + userId;
    }
    this.genericService.getObjects(url).subscribe((data) => {
      if (successCallback) {
        successCallback(data.body);
      }
    }, (error) => {
      if (errorCallback) {
        errorCallback(error.error);
      }
    });
  }

  async logout(successCallback, errorCallback) {
    let userDetails = this.StorageService.get('userDetails');
    this.stateManagementStorageService.clearStatesOfScreens();
    if (successCallback) {
      successCallback(true);
    }
    //call logout only if user is not already logged out
    if ((!this.$rootScope.logoutInProgress || this.currentScreenHasSaveOption(this.$state.current.name)) && userDetails) {
      let url = this.StorageService.get('baseurl') + this.urlConstants.LOG_OUT + '/' + userDetails.id;
      await this.http.post(url, null, {observe: 'events'}).subscribe((data) => {
        this.stateManagementStorageService.clearStatesOfScreens();
      }, (error) => {
        if (errorCallback) {
          errorCallback(error.error);
        }
      });
    }
  }

  forgotPassword(emailId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.FORGOT_PASSWORD + "/?emailId=" + emailId).subscribe((data) => {
      if (successCallback) {
        successCallback(data.body);
      }
    }, (error) => {
      if (errorCallback) {
        errorCallback(error.error);
      }
    });
  }

  validateOtp(userObj, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.VALIDATE_OTP, userObj).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getUserIdByToken(token, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_USER_ID_BY_TOKEN + '/' + token).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getPasswordRestrictionsByCompanyId(companyId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_PASSWORD_RESTRICTIONS_BY_COMPANY_ID + '/' + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error)
    });
  }

  getPasswordRestrictionsByUserToken(userToken, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_PASSWORD_RESTRICTIONS_BY_USER_TOKEN + '/' + userToken).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error)
    });
  }

  //common methods
  currentScreenHasSaveOption(stateName) {
    return stateName == 'missioncontrol.companymanagement' || this.$state.current.name == 'missioncontrol.usermanagement' || this.$state.current.name == 'missioncontrol.uploadreq' || this.$state.current.name == 'missioncontrol.editSharedReq' || this.$state.current.name == 'missioncontrol.settings' || this.$state.current.name == 'missioncontrol.roleentityscope' || this.$state.current.name == 'missioncontrol.feedbackforms' || this.$state.current.name == 'missioncontrol.notificationpreference';
  }


}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'categoryInHierarchyPipe'
})
export class CategoryInHierarchyPipe implements PipeTransform {

  transform(value: any): any {
    if(value.toLowerCase() == 'technical') {
      return 'T';
    } else if(value.toLowerCase() == 'operational') {
      return 'Op';
    } else if(value.toLowerCase() == 'soft') {
      return 'S';
    } else if(value.toLowerCase() == 'other') {
      return 'Ot';
    }
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadSkillsComponent } from './upload-skills.component';
import { UploadFilesModule } from 'src/app/shared/upload-files/upload-files.module';
import { UploadSkillsResultsComponent } from './upload-skills-results/upload-skills-results.component';

@NgModule({
  declarations: [UploadSkillsComponent, UploadSkillsResultsComponent],
  imports: [
    CommonModule,
    UploadFilesModule
  ],
  exports: [UploadSkillsComponent, UploadSkillsResultsComponent],
  entryComponents: [UploadSkillsComponent, UploadSkillsResultsComponent]
})
export class UploadSkillsModule { }

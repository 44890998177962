import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CandidateJobComparisonPageComponent } from './candidate-job-comparison-page.component';
import { CandidateJobComparisonComponent } from './candidate-job-comparison/candidate-job-comparison.component';
import { PopoverModule, TooltipModule } from 'ngx-bootstrap';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
  declarations: [CandidateJobComparisonPageComponent, CandidateJobComparisonComponent],
  imports: [
    CommonModule,
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    PipesModule
  ],
  exports: [CandidateJobComparisonPageComponent, CandidateJobComparisonComponent],
  entryComponents: [CandidateJobComparisonPageComponent, CandidateJobComparisonComponent]
})
export class CandidateJobComparisonPageModule { }

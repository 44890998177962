<span style="font-size: 0.9em;" *ngIf="!isRowViewActive">
    <span class="ml-1 pr-2 mr-1" style="position: relative;" *ngIf="isAdditionalDetails">
        <i *ngIf="requisitionInfo.requisitionSalaryDisplayMessage != 'Not Available'" class="fas fa-dollar-sign"
            containerClass="sponsorships-pop-over" popoverTitle="{{requisitionInfo.salaryLabel}}" [popover]="reqSalary"
            placement="bottom" container="body" triggers="mouseenter:mouseleave">
        </i>
        <i *ngIf="requisitionInfo.requisitionSalaryDisplayMessage == 'Not Available'" class="fas fa-dollar-sign"
            tooltip="Job Salary not available" container="body"></i>
        <span *ngIf="requisitionInfo.requisitionSalaryDisplayMessage == 'Not Available'"
            class="fa fa-times red requisition-card-data-not-available-icon" style="left:6px;"></span>
    </span>
    <span class="pr-2 mr-1" style="position: relative;" *ngIf="isAdditionalDetails">
        <i *ngIf="requisitionInfo.sponsorships && requisitionInfo.sponsorships.length > 0" class="fas fa-award"
            containerClass="sponsorships-pop-over" popoverTitle="Sponsorships" [popover]="reqSponsorship"
            placement="bottom" container="body" triggers="mouseenter:mouseleave">
        </i>
        <i *ngIf="!requisitionInfo.sponsorships || requisitionInfo.sponsorships.length == 0" class="fas fa-award"
            tooltip="Job Sponsorships not available" container="body"></i>
        <span *ngIf="!requisitionInfo.sponsorships || requisitionInfo.sponsorships.length == 0"
            class="fa fa-times red requisition-card-data-not-available-icon" style="left: 8px"></span>
    </span>
    <span class="pr-2 mr-1" style="position: relative;bottom: 1px;" *ngIf="requisitionInfo.workflowSteps.length>0">
        <img *ngIf="isRowViewActive" src="assets/app-content/img/staircase_black.png" alt="workflow-steps" width="17px"
            height="13px" style="margin-bottom: 2px;" containerClass="additional-details-pop-over"
            popoverTitle="Workflow Steps" [popover]="reqWorkflowSteps" placement="bottom" container="body"
            triggers="mouseenter:mouseleave">
        <img *ngIf="!isRowViewActive" src="assets/app-content/img/staircase.png" alt="workflow-steps" height="19px"
            style="margin-bottom: 2px;" containerClass="additional-details-pop-over" popoverTitle="Workflow Steps"
            [popover]="reqWorkflowSteps" placement="bottom" container="body" triggers="mouseenter:mouseleave">
    </span>
    <span class="pr-2" style="position: relative;"
        *ngIf="isAdditionalDetails && requisitionInfo.sharedRequisition && isCorporateCompany">
        <i class="fas fa-network-wired" containerClass="additional-details-pop-over" placement="bottom"
            popoverTitle="Vendors" [popover]="reqVendors" container="body" triggers="mouseenter:mouseleave">
        </i>
    </span>
    <span class="pointer mr-1" style="position: relative;" *ngIf="isTechAssessmentInfoExists">
        <i class="requisition-card-tech-assessment-icon"
            placement="auto bottom" popoverTitle="Technical Assessment Details" [popover]="reqTechAssessmentInfo"
            (mouseover)="_getAssessmentDetails()" container="body" triggers="mouseenter:mouseleave">T
        </i>
    </span>
    <span class="pointer pr-2 mr-1" *ngIf="isValueAssessmentInfoExists">
        <i class="fab fa-vimeo-v" placement="auto bottom"
            popoverTitle="Value Assessment Details" [popover]="reqValueAssessmentInfo"
            (mouseover)="_getValueAssessmentDetails()" container="body" triggers="mouseenter:mouseleave">
        </i>
    </span>
    <app-work-locations-icon iconClass="pr-2 mr-1" iconStyle="bottom:2px" context="requisitionCard"
        [workLocations]="requisitionInfo.workLocations">
    </app-work-locations-icon>
    <span tooltip="Manual Matching" container="body" class="">
        <i class="fas fa-hand-paper"
            *ngIf="!requisitionInfo.autoMatedMatchSelected || !requisitionInfo.autoMatedMatchEnabled"></i>
    </span>
    <span tooltip="Automated Candidate Matching" container="body" class="">
        <i class="fa fa-cogs fa-sm"
            *ngIf="requisitionInfo.autoMatedMatchSelected && requisitionInfo.autoMatedMatchEnabled"></i>
    </span>
</span>

<div *ngIf="isRowViewActive" class="text-overflow-ellipsis" [ngClass]="{'d-flex flex-row flex-nowrap align-items-center' : !showSalarySponsorshipWorkLocations}">
    <div class="col-xs-12 px-0 d-flex flex-row" *ngIf="showSalarySponsorshipWorkLocations">
        <div class="d-flex flex-column" style="width: 25%; max-width: 500px;">
            <!-- Job Type -->
            <div *ngIf="isAdditionalDetails" class="d-flex flex-row text-overflow-ellipsis requisition-card-icon-row-view max-width-fit-content">
                <div class="requisition-row-view-brick-heading">
                    <span tooltip="Job Type" container="body" placement="top" [adaptivePosition]="false">Job Type:</span>
                </div>
                <div class="ml-2 pr-2 text-overflow-ellipsis font-bold">
                    <span *ngIf="requisitionInfo.jobType">
                        <span>{{requisitionInfo.jobType}}</span>
                        <span *ngIf="requisitionInfo.contractDuration" class="ml-1">({{requisitionInfo.contractDuration | contractDurationPipe}})</span>
                    </span>
                    <span *ngIf="!requisitionInfo.jobType">N/A</span>
                </div>
            </div>
            <!-- Company -->
            <div *ngIf="isAdditionalDetails" class="d-flex flex-row text-overflow-ellipsis requisition-card-icon-row-view max-width-fit-content">
                <div class="requisition-row-view-brick-heading">
                    <span *ngIf="isCorporateCompany" tooltip="BU" container="body" [adaptivePosition]="false"
                        placement="top">BU:</span>
                    <span *ngIf="!isCorporateCompany" tooltip="Client" container="body" [adaptivePosition]="false"
                        placement="top">Client:</span>
                </div>
                <div class="ml-2 pr-2 text-overflow-ellipsis font-bold">
                    <div class="name d-flex flex-row flex-nowrap">
                        <!-- Company name -->
                        <div class="d-flex flex-row mr-2 text-overflow-ellipsis">
                            <div class="text-overflow-ellipsis">
                                <span class="" [tooltip]="requisitionInfo.companyLocationLabel" [adaptivePosition]="false"
                                    placement="top">{{requisitionInfo.companyLabel}}</span>
                            </div>
                            <div *ngIf="requisitionInfo.clientNote" class="pl-2">
                                <app-info-icon [info]="requisitionInfo.clientNote" info-title="Info"></app-info-icon>
                            </div>
                        </div>
                        <!-- Company location -->
                        <div class="text-overflow-ellipsis">
                            <span class="" *ngIf="requisitionInfo.locationLabel">
                                <span class="" [tooltip]="requisitionInfo.companyLocationLabel" [adaptivePosition]="false"
                                    placement="top">@ {{requisitionInfo.locationLabel}}</span>
                            </span>
                            <span class="" placement="top" [tooltip]="requisitionInfo.corporateBU" [adaptivePosition]="false"
                                *ngIf="requisitionInfo.sharedRequisition && !isCorporateCompany && requisitionInfo.corporateBU != null">
                                <span class="requisition-card-bu-name" ng-bind="requisitionInfo.corporateBU"></span>
                            </span>
                        </div>
                        <span *ngIf="!requisitionInfo.companyLocationLabel && !requisitionInfo.companyLabel 
                            && !requisitionInfo.clientNote && !requisitionInfo.locationLabel">N/A</span>
                    </div>
                </div>
            </div>
            <!-- End Client -->
            <div class="d-flex flex-row text-overflow-ellipsis requisition-card-row-view-items max-width-fit-content"
                *ngIf="!isCorporateCompany && !requisitionInfo.sharedRequisition && requisitionInfo.endClient">
                <div class="requisition-row-view-brick-heading">
                    <span tooltip="End Client" container="body" placement="top" [adaptivePosition]="false">End Client:</span>
                </div>
                <div class="ml-2 pr-2 text-overflow-ellipsis font-bold">
                    <span [tooltip]="requisitionInfo.endClient.name" container="body" [adaptivePosition]="false"
                        placement="top">{{requisitionInfo.endClient.name}}</span>
                </div>
            </div>
        </div>

        <div class="d-flex flex-column" style="width: 25%; max-width: 500px;">
            <!-- Salary Icon -->
            <div *ngIf="isAdditionalDetails" class="d-flex align-items-center requisition-card-icon-row-view text-overflow-ellipsis max-width-fit-content" >
                <div class="test-nowrap">
                    <span class="position-relative mr-1">
                        <span class="ml-1" style="position: relative;">
                            <i class="fas fa-dollar-sign" [tooltip]="requisitionCardSalaryTooltipDisplayMessage"
                                container="body" [adaptivePosition]="false"></i>
                            <span *ngIf="requisitionInfo.requisitionSalaryDisplayMessage == 'Not Available'"
                                class="fa fa-times red requisition-card-ban-icon-row-view" style="left: 3px"></span>
                        </span>
                        <span><b class="text-muted"> Salary:</b></span>
                    </span>
                </div>
                <div class="text-overflow-ellipsis font-bold" [tooltip]="reqSalary" container="body" placement="top" container="body"
                    [adaptivePosition]="false">
                    <ng-container *ngTemplateOutlet="reqSalary"></ng-container>
                </div>
            </div>
            <!-- Work Locations Icon -->
            <div *ngIf="isAdditionalDetails" class="d-flex align-items-center requisition-card-icon-row-view text-overflow-ellipsis max-width-fit-content">
                <div class="test-nowrap text-overflow-ellipsis">
                    <span class="mx-1" style="position: relative;">
                        <i class="far fa-building" [tooltip]="requisitionCardWorkLocationTooltipDisplayMessage"
                            container="body" [adaptivePosition]="false"></i>
                        <span *ngIf="!requisitionInfo.workLocations || requisitionInfo.workLocations.length == 0"
                            class="fa fa-times red requisition-card-ban-icon-row-view"></span>
                    </span>
                    <span><b class="text-muted" tooltip="Work Locations" container="body" [adaptivePosition]="false"> Work Locations:</b></span>
                </div>
                <div class="text-overflow-ellipsis font-bold ml-1" [tooltip]="reqWorkLocations" container="body" placement="top"
                    container="body" [adaptivePosition]="false">
                    <ng-container *ngTemplateOutlet="reqWorkLocations"></ng-container>
                </div>
            </div>
        </div>

        <div class="d-flex flex-column">
            <!-- Sponsorship Icon -->
            <div *ngIf="isAdditionalDetails" class="d-flex align-items-center requisition-card-icon-row-view text-overflow-ellipsis max-width-fit-content">
                <div class="test-nowrap text-overflow-ellipsis">
                    <span class="position-relative mr-1">
                        <span class="ml-1" style="position: relative;">
                            <i class="fas fa-award" [tooltip]="requisitionCardSponsorshipTooltipDisplayMessage"
                                container="body" [adaptivePosition]="false"></i>
                            <span *ngIf="!requisitionInfo.sponsorships || requisitionInfo.sponsorships.length == 0"
                                class="fa fa-times red requisition-card-ban-icon-row-view"></span>
                        </span>
                        <span><b class="text-muted" tooltip="Sponsorship" container="body" [adaptivePosition]="false"> Sponsorship:</b></span>
                    </span>
                </div>
                <div class="text-overflow-ellipsis font-bold" [tooltip]="reqSponsorship" container="body" placement="top"
                    container="body" [adaptivePosition]="false">
                    <ng-container *ngTemplateOutlet="reqSponsorship"></ng-container>
                </div>
            </div>
            <!-- Sponsorship Provided -->
            <div *ngIf="isAdditionalDetails" class="d-flex align-items-center requisition-card-icon-row-view text-overflow-ellipsis max-width-fit-content">
                <div class="test-nowrap text-overflow-ellipsis">
                    <span class="position-relative mr-1" style="position: relative;" tooltip="Sponsorship Provided"
                        container="body" [adaptivePosition]="false">
                        <span><b class="text-muted"> Sponsorship Provided:</b></span>
                    </span>
                </div>
                <div class="text-overflow-ellipsis font-bold">
                    <span *ngIf="requisitionInfo.sponsorshipProvided">Yes</span>
                    <span *ngIf="!requisitionInfo.sponsorshipProvided">No</span>
                </div>
            </div>
        </div>
    </div>

    <!-- Work flow steps Icon -->
    <div *ngIf="requisitionInfo.workflowSteps.length>0 && !showSalarySponsorshipWorkLocations" class="d-flex align-items-center requisition-card-icon-row-view text-overflow-ellipsis">
        <div class="test-nowrap">
            <span class="position-relative mr-1">
                <img src="assets/app-content/img/staircase_black.png" alt="workflow-steps" height="11px"
                    style="margin-bottom: 2px;" tooltip="Work flow steps" container="body" placement="top"
                    container="body" [adaptivePosition]="false">
                <span><b class="text-muted"> Work flow steps:</b></span>
            </span>
        </div>
        <div class="text-overflow-ellipsis font-bold" [tooltip]="reqWorkflowSteps" container="body" placement="top"
            container="body" [adaptivePosition]="false">
            <ng-container *ngTemplateOutlet="reqWorkflowSteps"></ng-container>
        </div>
    </div>
    <!-- Vendor Icon -->
    <div *ngIf="isAdditionalDetails && requisitionInfo.sharedRequisition && isCorporateCompany && !showSalarySponsorshipWorkLocations"
        class="d-flex align-items-center requisition-card-icon-row-view text-overflow-ellipsis">
        <div class="test-nowrap">
            <span class="position-relative mr-1">
                <i class="fas fa-network-wired" tooltip="Vendors" container="body" placement="top" container="body"
                    [adaptivePosition]="false"></i>
                <span><b class="text-muted"> Vendors:</b></span>
            </span>
        </div>
        <div class="text-overflow-ellipsis font-bold" [tooltip]="reqVendors" container="body" placement="top" container="body"
            [adaptivePosition]="false">
            <ng-container *ngTemplateOutlet="reqVendors"></ng-container>
        </div>
    </div>
    <!-- Tech Assessment Icon -->
    <div *ngIf="isTechAssessmentInfoExists && !showSalarySponsorshipWorkLocations" class="d-flex align-items-center requisition-card-icon-row-view">
        <div class="test-nowrap">
            <span class="mx-1" style="position: relative;">
                <i class="requisition-card-tech-assessment-icon" tooltip="Tech Assessment"
                    [adaptivePosition]="false" placement="top" container="body">T
                </i>
            </span>
            <span><b class="text-muted"> Tech Assessment:</b></span>
        </div>
        <div class="text-overflow-ellipsis font-bold ml-1">
            <span containerClass="requisition-assessment-details-pop-over" placement="auto bottom"
                popoverTitle="Technical Assessment Details" [popover]="reqTechAssessmentInfo"
                container="body" [outsideClick]="true" [adaptivePosition]="false">
                <i class="fa fa-file-text text-muted ml-1 mr-2" style="font-size: 10px;"></i>
                <a (click)="_getAssessmentDetails()">view</a>
            </span>
        </div>
    </div>
    <!-- Value Assessment Icon -->
    <div *ngIf="isValueAssessmentInfoExists && !showSalarySponsorshipWorkLocations" class="d-flex align-items-center requisition-card-icon-row-view">
        <div class="test-nowrap">
            <span class="mx-1" style="position: relative;">
                <i class="fab fa-vimeo-v" tooltip="Value Assessment" [adaptivePosition]="false" placement="top" container="body"></i>
            </span>
            <span><b class="text-muted"> Value Assessment:</b></span>
        </div>
        <div class="text-overflow-ellipsis font-bold ml-1">
            <span containerClass="requisition-assessment-details-pop-over" placement="auto bottom"
                popoverTitle="Value Assessment Details" [popover]="reqTechAssessmentInfo" container="body" [outsideClick]="true"
                [adaptivePosition]="false">
                <i class="fa fa-file-text text-muted ml-1 mr-2" style="font-size: 10px;"></i>
                <a (click)="_getValueAssessmentDetails()">view</a>
            </span>
        </div>
    </div>
</div>

<ng-template #reqSalary>
    <p [ngClass]="{'mb-0': isRowViewActive}" class="text-overflow-ellipsis">
        <span *ngIf="isRowViewActive">{{requisitionInfo.requisitionSalaryDisplayMessage == 'Not Available' ? 'N/A' : requisitionInfo.requisitionSalaryDisplayMessage}}</span>
        <span *ngIf="!isRowViewActive">{{requisitionInfo.requisitionSalaryDisplayMessage}}</span>
    </p>
</ng-template>

<ng-template #reqSponsorship>
    <p *ngIf="requisitionInfo.sponsorshipsDisplayMessage == 'Not Available' || requisitionInfo.sponsorshipsDisplayMessage == 'Sponsorship information not specified'"
        [ngClass]="{'mb-0 text-overflow-ellipsis': isRowViewActive}">
        <span *ngIf="!isRowViewActive">{{requisitionInfo.sponsorshipsDisplayMessage}}</span>
        <span *ngIf="isRowViewActive">N/A</span>
    </p>
    <p *ngIf="!isRowViewActive"> Sponsorship Provided :
        <span *ngIf="requisitionInfo.sponsorshipProvided"><strong>Yes</strong></span>
        <span *ngIf="!requisitionInfo.sponsorshipProvided"><strong>No</strong></span>
    </p>
    <p *ngIf="requisitionInfo.sponsorshipsDisplayMessage !== 'Not Available' && requisitionInfo.sponsorshipsDisplayMessage !== 'Sponsorship information not specified'"
        [ngClass]="{'mb-0': isRowViewActive}" class="text-overflow-ellipsis">
        <span *ngFor="let sponsorship of requisitionInfo.sponsorships; let i = index">
            <span *ngIf="isRowViewActive">{{i>0 ? ", " : ""}}</span>
            <span *ngIf="!isRowViewActive">{{i + 1}}. </span>
            {{sponsorship}}
        </span>
    </p>
</ng-template>

<ng-template #reqWorkflowSteps>
    <span *ngIf="!isRowViewActive">
        <p *ngIf="requisitionInfo.fourDotFiveIntelligentThresholdScore">
            <b>Threshold Score:</b> {{requisitionInfo.fourDotFiveIntelligentThresholdScore}}
        </p>
        <p class="text-left" *ngFor="let stepName of requisitionInfo.workflowSteps; let i = index">
            {{i + 1}}. {{stepName}}
        </p>
    </span>
    <span *ngIf="isRowViewActive">
        <span *ngFor="let stepName of requisitionInfo.workflowSteps; let i = index">
            {{i>0 ? ", " : ""}}{{stepName}}
        </span>
    </span>
</ng-template>

<ng-template #reqVendors>
    <span *ngIf="!isRowViewActive">
        <p *ngFor="let vendor of requisitionInfo.vendorsDetails; let i = index">{{vendor.name}}</p>
    </span>
    <span *ngIf="isRowViewActive">
        <span *ngFor="let vendor of requisitionInfo.vendorsDetails; let i = index">{{i>0 ? ", " : ""}}{{vendor.name}}</span>
    </span>
</ng-template>

<ng-template #reqWorkLocations>
    <span *ngIf="requisitionInfo.workLocations && requisitionInfo.workLocations.length > 0">
        <span *ngFor="let workLocation of requisitionInfo.workLocations; let i = index">{{i>0 ? ", " : ""}}{{workLocation.name}}</span>
    </span>
    <span *ngIf="!requisitionInfo.workLocations || requisitionInfo.workLocations.length == 0">N/A</span>
</ng-template>

<ng-template #reqTechAssessmentInfo>
    <div class="" style="white-space: normal;">
        <div class="row">
            <div *ngIf="techAssessmentInfoLoading" class="col-xs-12 requisition-card-icons-loader-div text-center">
                <span class="fa fa-2x fa-spinner fa-spin"></span>
            </div>
            <div class="col-xs-12" *ngIf="!techAssessmentInfoLoading">
                <p class="mb-2">Assessment Details for <span class="pr-1">'<span
                            class="emerald font-bold">{{assessmentDetails.testAliasName}}</span>'.</span>
                    <span class="d-inline-block">Duration: {{assessmentDetails.totalDuration}} minutes</span>
                </p>
                <p *ngIf="requisitionInfo.fourDotFiveIntelligentThresholdScore">
                    <b>Threshold Score:</b> {{requisitionInfo.fourDotFiveIntelligentThresholdScore}}
                </p>
                <table class="table table-bordered table-responsive">
                    <thead>
                        <tr class="requisition-icon-tech-assessment-table-heading">
                            <th>Section Name</th>
                            <th>Section Type</th>
                            <th>Number of Questions</th>
                            <th>Duration</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let section of assessmentDetails.sections; let i = index">
                            <td>{{section.sectionName}}</td>
                            <td>{{section.sectionType}}</td>
                            <td>{{section.questions}}</td>
                            <td>{{section.duration}} minutes</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #reqValueAssessmentInfo>
    <div class="" style="white-space: normal;">
        <div class="row">
            <div *ngIf="valueAssessmentInfoLoading" class="col-xs-12 requisition-card-icons-loader-div text-center">
                <span class="fa fa-2x fa-spinner fa-spin"></span>
            </div>
            <div class="col-xs-12" *ngIf="!valueAssessmentInfoLoading">
                <p>Assessment Details for <span class="pr-1">'<span
                            class="emerald font-bold">{{valueAssessmentDetails.testAliasName}}</span>'.</span>
                    <span class="d-inline-block">Duration: {{valueAssessmentDetails.totalDuration}} minutes</span>
                </p>
                <table class="table table-bordered table-responsive">
                    <thead>
                        <tr class="requisition-icon-tech-assessment-table-heading">
                            <th>Section Name</th>
                            <th>Section Type</th>
                            <th>Number of Questions</th>
                            <th>Duration</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let section of valueAssessmentDetails.sections; let i = index">
                            <td>{{section.sectionName}}</td>
                            <td>{{section.sectionType}}</td>
                            <td>{{section.questions}}</td>
                            <td>{{section.duration}} minutes</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>
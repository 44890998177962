import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'filterSkills'
})

export class FIlterSkillsPipe implements PipeTransform {

  transform(skills: any[], include: boolean): any[] {
    if (!skills) return [];
    if(!include) {
      return skills.filter(skill => !skill.isAddedSkill || (skill.isAddedSkill === include));
    } else {
      return skills.filter(skill => skill.isAddedSkill && (skill.isAddedSkill === include));
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { AngularModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-add-new-company-details-modal',
  templateUrl: './add-new-company-details-modal.component.html',
  styleUrls: ['./add-new-company-details-modal.component.css']
})
export class AddNewCompanyDetailsModalComponent implements OnInit {
  public onClose: Subject<any>;
  modalData:any;
  constructor(public bsModalRef: BsModalRef, private modalService: AngularModalService, public bsModalService: BsModalService) { }

  ngOnInit() {
    this.onClose = new Subject();
    if(this.bsModalService.config.initialState) {
      this.modalData = this.bsModalService.config.initialState;
    }
  }

  closeModal() {
    this.onClose.next(null);
    this.bsModalRef.hide();
    this.modalService.addModalOpenClassToBodyIfAnyModalIsOpen();
  }

  saveNewCompanyDetails(event) {
    this.onClose.next(event);
    this.bsModalRef.hide();
  }

}

<div class="employee-card-container">
    <div *ngIf="!loadingFlag" class="main-box clearfix profile-box-menu">
        <div class="main-box-body clearfix">
            <div class="profile-box-header employee-card-header clearfix emerald-bg">
                <div class="clearfix">
                    <div class="pull-left" style="width:90%;">
                        <div class="cursor-pointer job-position">
                            <span style="font-size: 1.2em; font-weight: 600;width:95%; display:inline-block"
                                class="text-overflow-ellipsis">
                                <span placement="bottom" tooltip="Name of Employee - {{employeeInfo.name}}"
                                    container="body" [adaptivePosition]="false"
                                    id="employeeName">{{employeeInfo.name}}</span>
                            </span>
                        </div>
                    </div>
                    <div class="">
                        <div dropdown class="position-relative activities-dropdown pull-right pr-1">
                            <a id="basic-link" dropdownToggle (click)="showRecentActivities()"
                                aria-controls="basic-link-dropdown" tooltip="Activity Monitor" placement="bottom"
                                container="body">
                                <i class="fa fa-list text-white"></i>
                            </a>
                            <ul *dropdownMenu class="dropdown-menu candidate-job-match-activity-list" role="menu"
                                aria-labelledby="basic-link">
                                <app-recent-activities *ngIf="showRecentActivitiesFlag" [activityMap]="activityMap"
                                    [recentActivitiesControls]="recentActivitiesControls"
                                    [isActivityClicked]="isActivityClicked" context="employeeCard"
                                    userId="{{employeeId}}"
                                    (activitiesLoadedCallback)="cardMenuToggled(true, employeeId + '-activity-dropdown-menu')"
                                    (showAllCallback)="showAllActivityModal()">
                                </app-recent-activities>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="clearfix" style="padding: 2px;">
                    <div class="pull-left employee-card-detail-div" tooltip="Phone Number of Employee"
                        placement="bottom" container="body" [adaptivePosition]="false">
                        <i class="fa fa-phone fa-lg"></i>
                        <span class="employee-card-detail-div">{{employeeInfo.phoneNumber}}</span>
                    </div>
                    <div class="pull-right employee-card-detail-div" tooltip="Email {{employeeInfo.email}}"
                        placement="bottom" container="body" [adaptivePosition]="false">
                        <a href="mailto:{{employeeInfo.email}}">
                            <i class="fa fa-envelope fa-lg"></i>
                        </a>
                    </div>
                </div>
                <div class="clearfix" style="padding: 2px;">
                    <div class="employee-card-detail-div pull-left" tooltip="Title of Employee" placement="top"
                        container="body" [adaptivePosition]="false">
                        <span *ngIf="employeeInfo.title == null">-</span>
                        <span *ngIf="employeeInfo.title != null">{{employeeInfo.title | companyNameSettingPipe}}</span>
                    </div>
                    <div class="employee-card-detail-div pull-right" tooltip="Organization Manager of Employee"
                        placement="top" container="body" [adaptivePosition]="false">
                        <span *ngIf="employeeInfo.organizationManagerName == null">-</span>
                        <span
                            *ngIf="employeeInfo.organizationManagerName != null">{{employeeInfo.organizationManagerName
                            | companyNameSettingPipe}}</span>
                    </div>
                </div>
            </div>
            <div class="profile-box-content clearfix">
                <ul class="menu-items">
                    <li>
                        <div class="row">
                            <div class="col-xs-8">
                                <div class="text-left employee-card-footer-section text-overflow-ellipsis"
                                    style="margin-left: 5px; min-height:34px;">
                                    <span *ngIf="isCorporateFlag">
                                        <span tooltip="BU of Employee" placement="top-left" container="body"
                                            [adaptivePosition]="false" *ngIf="employeeInfo.bu != null">
                                            {{employeeInfo.bu.name}}
                                        </span>
                                        <span tooltip="Company of Employee" placement="top-left" container="body"
                                            [adaptivePosition]="false" *ngIf="employeeInfo.bu == null">
                                            {{currentCompanyName}}
                                        </span>
                                    </span>
                                    <span *ngIf="!isCorporateFlag">
                                        <span tooltip="Department of Employee" placement="top-left" container="body"
                                            [adaptivePosition]="false" *ngIf="employeeInfo.department != null">
                                            {{employeeInfo.department.name}}
                                        </span>
                                        <span tooltip="Company of Employee" placement="top-left" container="body"
                                            [adaptivePosition]="false" *ngIf="employeeInfo.department == null">
                                            {{currentCompanyName}}
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-xs-4" *ngIf="employeeInfo.displayActions">
                                <div class="text-right">

                                    <div class="btn-group" style="margin: 10px;">
                                        <button type="button" class="btn btn-primary"
                                            data-toggle="dropdown">Actions</button>
                                        <button type="button" class="btn btn-primary dropdown-toggle"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span class="caret"></span>
                                            <span class="sr-only">Toggle Dropdown</span>
                                        </button>
                                        <ul class="dropdown-menu employee-card-dropdown">
                                            <li role="menuitem" [hidden]="employeeInfo.canNotEdit"
                                                class="dropdown-option" (click)="editEmployee()">
                                                Edit
                                            </li>
                                            <li class="divider" [hidden]="employeeInfo.canNotMakeApplicationUser"></li>
                                            <li role="menuitem" [hidden]="employeeInfo.canNotMakeApplicationUser"
                                                class="dropdown-option"
                                                [ngClass]="{'disable-element': companyState == 'SoftHold'}"
                                                (click)="makeApplicationUser()">
                                                Make Application User
                                            </li>
                                            <li class="divider" [hidden]="employeeInfo.canNoDelete"></li>
                                            <li role="menuitem" [hidden]="employeeInfo.canNoDelete"
                                                class="dropdown-option" (click)="deleteEmployee()">
                                                Delete
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div *ngIf="loadingFlag" class="employee-card-loader-div text-center">
        <span class="fa fa-2x fa-spinner fa-spin"></span>
    </div>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssessmentsSyncMessageComponent } from './assessments-sync-message.component';

@NgModule({
  declarations: [AssessmentsSyncMessageComponent],
  imports: [
    CommonModule
  ],
  exports: [AssessmentsSyncMessageComponent],
  entryComponents: [AssessmentsSyncMessageComponent]
})
export class AssessmentsSyncMessageModule { }

import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  urlConstants: any = {
    GET_FOUR_DOT_FIVE_SETTINGS: 'api/setting/getfordotfivesettings',
    GET_CORPORATE_OR_STAFFING_SETTINGS: 'api/setting/getstaffingorcorpsettings',
    GET_CLIENT_OR_BU_SETTINGS: 'api/setting/getclientorbusettings',
    DELETE_CLIENT_OR_BU_SETTINGS: 'api/setting/deleteclientorbusettings',
    DELETE_STAFFING_OR_CORP_SETTINGS: 'api/setting/deletestaffingorcorpsettings',
    SET_COMPANY_SETTINGS: 'api/setting/savesettings',
    SET_CLIENT_OR_BU_SETTINGS: 'api/setting/savesettingsforclientorbu',
    GET_PARENT_COMPANY_SETTINGS: 'api/setting/getparentsettings',
    GET_SETTINGS_ACTIVITY: 'api/setting/getsettingsactivity'
  };

  constructor(
    private genericService: GenericService,
    private StorageService: StorageService
  ) { }

  getFourDotFiveSettings(successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_FOUR_DOT_FIVE_SETTINGS).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getCorporateOrStaffingCompanySettings(companyId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_CORPORATE_OR_STAFFING_SETTINGS + '/' + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getClientOrBuSettings(companyId, clientOrBuId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_CLIENT_OR_BU_SETTINGS + '/' + companyId + '/' + clientOrBuId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getParentCompanySettings(companyId, successCallback, errorCallback) {
    this.genericService.getObjects(this.StorageService.get('baseurl') + this.urlConstants.GET_PARENT_COMPANY_SETTINGS + '/' + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  deleteClientOrBuSettings(companyId, clientOrBuId, clientDetails, successCallback, errorCallback) {
    this.genericService.deleteObject(this.StorageService.get('baseurl') + this.urlConstants.DELETE_CLIENT_OR_BU_SETTINGS + '/' + companyId + '/' + clientOrBuId, clientDetails).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  deleteStaffingOrCorpSettings(companyId, successCallback, errorCallback) {
    this.genericService.deleteObjectById(this.StorageService.get('baseurl') + this.urlConstants.DELETE_STAFFING_OR_CORP_SETTINGS + '/' + companyId).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  setCompanySettings(companyId, settings, successCallback, errorCallback) {
    this.genericService.putObject(this.StorageService.get('baseurl') + this.urlConstants.SET_COMPANY_SETTINGS + '/' + companyId, settings).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  setClientOrBuSettings(companyId, clientOrBuId, settings, successCallback, errorCallback) {
    this.genericService.putObject(this.StorageService.get('baseurl') + this.urlConstants.SET_CLIENT_OR_BU_SETTINGS + '/' + companyId + '/' + clientOrBuId, settings).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }

  getSettingsActivity(filter, isSharedClient, successCallback, errorCallback) {
    this.genericService.addObject(this.StorageService.get('baseurl') + this.urlConstants.GET_SETTINGS_ACTIVITY + '?isClientCompanySettingsActivity=' + isSharedClient, filter).subscribe((data) => {
      successCallback(data.body);
    }, (error) => {
      errorCallback(error.error);
    });
  }
}

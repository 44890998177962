import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CandidateService } from 'src/app/core/services/candidate.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { CandidateAdditionalDetails } from 'src/app/core/models/candidateAdditionalDetails';
import { MESSAGECONSTANTS } from 'src/app/core/utils/constants';
import { UserRoleService } from 'src/app/core/services/user-role.service';

@Component({
  selector: 'app-add-edit-candidate-contact',
  templateUrl: './add-edit-candidate-contact.component.html',
  styleUrls: ['./add-edit-candidate-contact.component.css']
})
export class AddEditCandidateContactComponent implements OnInit {
  @ViewChild('addUpdateContactForm', null) addUpdateContactForm:NgForm;
  @Input() candidateId;
  @Output() saveCallback = new EventEmitter<any>();
  @Output() cancelCallback = new EventEmitter<any>();

  MESSAGECONSTANTS:any = MESSAGECONSTANTS;

  // variables
  originalLocation:any = null;
  updateContactType:any = '';
  contactObject:any = {};
  originalContactObject:any ={};
  candidateName:any = '';
  phoneNumberElementId:any;
  profileUrlText:any;
  profileUrl:any;

  // flags
  countryCodePrepended:boolean = false;
  showProfileUrlFlag:boolean = false;
  showAdditionalDetailFlag:boolean = false;
  savingFlag:boolean = false;
  loadingContactInfoFlag:boolean = false;
  showNoCountryCodeAvailableOptionFlag:boolean = false;
  showSalary:boolean = false;
  isPhoneNumberInvalid: boolean = false;

  constructor(private authService:AuthService, private candidateService:CandidateService, private alertsAndNotificationsService:AlertAndNotificationsService,
    private companyService:CompanyService, private userRoleService:UserRoleService) { }

  ngOnInit() {
    this.phoneNumberElementId = 'candidateContactPhoneNumber' + new Date().getUTCMilliseconds();

    this._setDefaultUpdateSelectionAsContactDetails();
    this._initContactObject();
    this.getCandidateContactDetails(() => {
        this.showAdditionalDetailFlag = true;
    });
}

_setDefaultUpdateSelectionAsContactDetails() {
    this.updateContactType = 'contactDetails';
}

_initContactObject() {
    this.contactObject.additionalDetails = {...new CandidateAdditionalDetails()};
    this.contactObject.candidateId = this.candidateId;
    this.contactObject.companyId = this.authService.getUserDetails().company.companyId;
}

getCandidateContactDetails(successCallback) {
    this.loadingContactInfoFlag = true;
    this.candidateService.getCandidateProfile(this.candidateId, (data) => {
        this.candidateName = _.isNull(data.lastName) ? data.firstName : data.firstName + ' ' + data.lastName;
        this._setProfileUrl(data);
        this._setContactObject(data);
        this._setUnchangedContactObject();
        this._setShowSalary(data);
        this.loadingContactInfoFlag = false;
        if(successCallback) {
            successCallback();
        }
    }, (error) => {
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
}

_setProfileUrl(contactResponse) {
    this.profileUrlText = '';
    this.profileUrl = contactResponse.profileUrl;
    if(!_.isNull(this.profileUrl)) {
        this.showProfileUrlFlag = true;
        if(contactResponse.profileType != 'Other') {
            this.profileUrlText = "View " + this.candidateName + "'s " + contactResponse.profileType + " Profile";
        } else {
            this.profileUrlText = "View " + this.candidateName + "'s Profile";
        }
    }
}

_setContactObject(contactResponse) {
    this.contactObject.id = this.candidateId;
    this.contactObject.firstName = _.isNull(contactResponse.firstName) ? "" : contactResponse.firstName;
    this.contactObject.lastName = _.isNull(contactResponse.lastName) ? "" : contactResponse.lastName;
    this.contactObject.emailId = contactResponse.email;
    this.contactObject.mobilePhoneCountryCodePresent = contactResponse.mobilePhoneCountryCodePresent;
    this.contactObject.phoneNumber = contactResponse.mobilePhone;
    this.contactObject.phoneNumberCopy = contactResponse.mobilePhone;

    this._setContactAddress(contactResponse.address);
    this.contactObject.additionalDetails = contactResponse.candidateAdditionalDetails;
    if(_.isNull(this.contactObject.additionalDetails)) {
        this.contactObject.additionalDetails = {...new CandidateAdditionalDetails()};
        // this.contactObject.additionalDetails.salary = new JobSalary();
    }
    this.contactObject.additionalDetails.candidateId = this.candidateId;
    if(!this.contactObject.mobilePhoneCountryCodePresent && this.contactObject.phoneNumber !== null) {
        this.showNoCountryCodeAvailableOptionFlag = true;
    }
}

_setContactAddress(address) {
    if(_.isNull(address)) {
        address = {
            address1: null,
            address2: null,
            city: null,
            county: null,
            country: null,
            countryFullName: null,
            countryCode: null,
            region: null,
            municipality: null,
            state: null,
            zipCode: null
        }
    }
    this.originalLocation = _.cloneDeep(address);
    this.contactObject.address = _.cloneDeep(address);
}

_setUnchangedContactObject() {
    this.originalContactObject = _.cloneDeep(this.contactObject);
    this.originalContactObject.address = _.cloneDeep(this.originalLocation);
}

onMobilePhoneCountryCodePresentChange() {
    if(this.contactObject.mobilePhoneCountryCodePresent) {
        if(this._invalidPhoneNumber()) {
            this.contactObject.phoneNumber = this.contactObject.phoneNumberCopy;
        }
        this.contactObject.phoneNumber = this.contactObject.phoneNumber.replace('(', '').replace(')', '');
        if(!this.contactObject.phoneNumber.startsWith('+')) {
            this.contactObject.phoneNumber = '+1' + this.contactObject.phoneNumber;
            this.countryCodePrepended = true;
            setTimeout(() => {
                // this.addUpdateContactForm.candidateContactPhoneNumber.$dirty = true;
                this.addUpdateContactForm.control.get('candidateContactPhoneNumber').markAsDirty();
            }, 500);
        }
    }
}

_invalidPhoneNumber() {
    return this.contactObject.phoneNumber === undefined;
}

updateContactDetails() {
    if(!this.addUpdateContactForm.invalid && !this.isPhoneNumberInvalid) {
        if(this.updateContactType == 'contactDetails' && this._hasContactDetailsChanged()) {
            this._updateContactDetails();
        } else if(this.updateContactType == 'additionalDetails' && this._hasAdditionalDetailsChanged()) {
            this._updateContactAdditionalDetails();
        } else {
            this.alertsAndNotificationsService.showBannerMessage("Nothing to update.", "info");
        }
    } else {
      this.addUpdateContactForm.onSubmit(undefined);
        // this.addUpdateContactForm["manageLocation.locationForm"].$submitted = true;
    }
}

_hasContactDetailsChanged() {
    return this._hasNameChanged() || this._hasPhoneNumberChanged() || this._hasEmailChanged() || this._hasAddressChanged();
}

_hasNameChanged() {
    return !_.isEqual(this.contactObject.firstName, this.originalContactObject.firstName) || !_.isEqual(this.contactObject.lastName, this.originalContactObject.lastName)
}

_updateContactDetails() {
    this.savingFlag = true;
    let contact = _.cloneDeep(this.contactObject);
    this.candidateService.updateCandidateContactDetails(this.candidateId, contact, (data) => {
        this.originalContactObject = _.cloneDeep(this.contactObject);
        this.savingFlag = false;
        this.alertsAndNotificationsService.showBannerMessage("Candidate's contact details updated successfully.", "success");
        if(this.saveCallback) {
            this.saveCallback.emit();
        }
    }, (error) => {
        this.savingFlag = false;
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    })
}

_updateContactName() {
    this.savingFlag = true;
    this.candidateService.addUpdateCandidateName(this.contactObject, (data) => {
        this.originalContactObject.firstName = _.cloneDeep(this.contactObject.firstName);
        this.originalContactObject.lastName = _.cloneDeep(this.contactObject.lastName);
        this.savingFlag = false;
        this.alertsAndNotificationsService.showBannerMessage("Candidate's Name updated successfully.", "success");
        if(this.saveCallback) {
            this.saveCallback.emit();
        }
    }, (error) => {
        this.savingFlag = false;
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
}

_hasPhoneNumberChanged() {
    return !_.isEqual(this.contactObject.phoneNumber, this.originalContactObject.phoneNumber)
}

_updateContactPhone() {
    this.savingFlag = true;
    this.candidateService.addUpdateCandidatePhone(this.contactObject, (data) => {
        this.originalContactObject.phoneNumber = _.cloneDeep(this.contactObject.phoneNumber);
        this.savingFlag = false;
        this.alertsAndNotificationsService.showBannerMessage("Candidate's Phone Number updated successfully.", "success");
        if(this.saveCallback) {
            this.saveCallback.emit();
        }
    }, (error) => {
        this.savingFlag = false;
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
}

_hasEmailChanged() {
    return !_.isEqual(this.contactObject.emailId, this.originalContactObject.emailId)
}

_updateContactEmail() {
    this.savingFlag = true;
    this.candidateService.addUpdateCandidateEmail(this.contactObject, (data) => {
        this.originalContactObject.emailId = _.cloneDeep(this.contactObject.emailId);
        this.savingFlag = false;
        this.alertsAndNotificationsService.showBannerMessage("Candidate's Email updated successfully.", "success");
        if(this.saveCallback) {
            this.saveCallback.emit();
        }
    }, (error) => {
        this.savingFlag = false;
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
}

_hasAddressChanged() {
    if(this.contactObject.address.city === "") {
        this.contactObject.address.city = null;
    }
    if(this.contactObject.address.county === "") {
        this.contactObject.address.county = null;
    }
    if(this.contactObject.address.state === "") {
        this.contactObject.address.state = null;
    }
    if(this.contactObject.address.country === "") {
        this.contactObject.address.country = null;
    }
    delete this.contactObject.address.countryFullName;
    delete this.originalContactObject.address.countryFullName;

    return !_.isEqual(this.contactObject.address, this.originalContactObject.address)
}

_hasAdditionalDetailsChanged() {
    return !_.isEqual(this.contactObject.additionalDetails, this.originalContactObject.additionalDetails);
}

_updateContactAdditionalDetails() {
    this.savingFlag = true;
    this.candidateService.addOrUpdateCandidateAdditionalDetails(this.contactObject.additionalDetails, (data) => {
        this.originalContactObject.additionalDetails = _.cloneDeep(this.contactObject.additionalDetails);
        this.savingFlag = false;
        this.alertsAndNotificationsService.showBannerMessage(data.message, "success");
        if(this.saveCallback) {
            this.saveCallback.emit();
        }
    }, (error) => {
        this.savingFlag = false;
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
}

saveLocationCallback(address) {
    this.contactObject.address = address;
}

_setShowSalary(data){
    if(data.candidateCompanyId === this.authService.getUserDetails().company.companyId && !this.userRoleService.isLoggedInUserEmployee()) {
        this.showSalary = true;
    }
}

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageSkillsPageComponent } from './manage-skills-page.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddEditManageSkillsSkillModule } from './add-edit-manage-skills-skill/add-edit-manage-skills-skill.module';
import { PopoverModule, TooltipModule } from 'ngx-bootstrap';
import { RouterModule } from '@angular/router';
import { UpdateDeleteSelectedSkillsComponent } from './update-delete-selected-skills/update-delete-selected-skills.component';
import { UploadSkillsModule } from './upload-skills/upload-skills.module';
import { ViewUploadedSkillsComponent } from './view-uploaded-skills/view-uploaded-skills.component';
import { EntityActivitiesModule } from 'src/app/shared/entity-activities/entity-activities.module';
import { SkillsEntityActivitiesComponent } from './skills-entity-activities/skills-entity-activities.component';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { TagInputModule } from 'ngx-chips';

@NgModule({
  declarations: [ManageSkillsPageComponent, UpdateDeleteSelectedSkillsComponent, ViewUploadedSkillsComponent, SkillsEntityActivitiesComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgxDatatableModule,
    NgSelectModule,
    AddEditManageSkillsSkillModule,
    UploadSkillsModule,
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    EntityActivitiesModule,
    PipesModule,
    TagInputModule
  ],
  entryComponents: [ManageSkillsPageComponent, UpdateDeleteSelectedSkillsComponent, ViewUploadedSkillsComponent, SkillsEntityActivitiesComponent],
  exports: [ManageSkillsPageComponent, UpdateDeleteSelectedSkillsComponent, ViewUploadedSkillsComponent, SkillsEntityActivitiesComponent]
})
export class ManageSkillsPageModule { }

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contractDurationPipe'
})
export class ContractDurationPipe implements PipeTransform {

  transform(value: any): any {
    return value !== 'Long Term' ? value = value + " months" : value;
  }

}
